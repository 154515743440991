var render = function render(){
  var _vm$item, _vm$item2, _vm$item3, _vm$item4, _vm$item5, _vm$item6, _vm$item7;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "main-content"
  }, [_c('section', {
    staticClass: "inner-header-bg-techHub"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "section-breadcrumb"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12 justify-content-center align-self-center pb-2"
  }, [_c('div', {
    staticClass: "breadcrumb-content"
  }, [_vm._m(0), _c('div', {
    staticClass: "breadcrumb-nav"
  }, [_c('ul', {
    staticClass: "breadcrumb-link text-center list-unstyled mb-0"
  }, [_vm._m(1), _c('li', {
    staticClass: "text-white"
  }, [_c('router-link', {
    attrs: {
      "to": {
        name: 'white-papers'
      }
    }
  }, [_c('a', {
    attrs: {
      "href": "#"
    }
  }, [_vm._v("White Papers")])])], 1), _c('li', {
    staticClass: "theme-light-color active"
  }, [_vm._v("Details")])])])])])])])])])]), _c('section', {
    staticClass: "section section-blog"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row justify-content-lg-center"
  }, [_c('div', {
    staticClass: "col-lg-4 order-lg-2 wow fadeIn d-none",
    attrs: {
      "data-wow-delay": "0.1s",
      "data-wow-duration": "1.5s"
    }
  }, [_c('aside', {
    staticClass: "sidebar sidebar-padding border"
  }, [_c('h6', {
    staticClass: "post-news-title search-title"
  }, [_vm._v("Search")]), _c('div', {
    staticClass: "input-group"
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Search..."
    },
    model: {
      value: _vm.query,
      callback: function ($$v) {
        _vm.query = $$v;
      },
      expression: "query"
    }
  }), _c('router-link', {
    staticClass: "btn btn-search p-0",
    attrs: {
      "to": {
        name: 'white-papers',
        query: {
          query: _vm.query
        }
      }
    }
  }, [_c('span', {
    staticClass: "mdi mdi-magnify"
  })])], 1), _c('div', {
    staticClass: "pb-2 mt-5 mb-3"
  }, [_vm._m(2), _c('div', {
    staticClass: "blog-news-content"
  }, [_c('div', {
    staticClass: "blog-post-list"
  }, [_c('ul', {
    staticClass: "list-unstyled"
  }, _vm._l(_vm.categories, function (item, index) {
    return _c('li', {
      key: index
    }, [_c('div', {
      staticClass: "blog-news-info"
    }, [_c('router-link', {
      attrs: {
        "to": {
          name: 'white-papers',
          query: {
            query: item.name
          }
        }
      }
    }, [_c('a', {
      staticClass: "text-dark",
      attrs: {
        "href": "#"
      }
    }, [_vm._v(" " + _vm._s(item.name) + " "), _c('span', {
      staticClass: "float-right"
    }, [_vm._v(_vm._s(item.articles))])])])], 1)]);
  }), 0)])])]), _c('div', {
    staticClass: "pb-2 mb-3"
  }, [_vm._m(3), _c('div', {
    staticClass: "blog-news-content"
  }, [_c('div', {
    staticClass: "blog-post-list"
  }, [_c('ul', {
    staticClass: "list-unstyled"
  }, _vm._l(_vm.archives, function (item, index) {
    return _c('li', {
      key: index
    }, [_c('div', {
      staticClass: "blog-news-info"
    }, [_c('a', {
      staticClass: "text-dark",
      attrs: {
        "href": "#"
      }
    }, [_vm._v(_vm._s(item.name) + " "), _c('span', {
      staticClass: "float-right"
    }, [_vm._v(_vm._s(item.articles))])])])]);
  }), 0)])])]), _c('div', {
    staticClass: "tags-post"
  }, [_vm._m(4), _c('div', {
    staticClass: "blog-news-content"
  }, [_c('div', {
    staticClass: "blog-post-list"
  }, [_c('ul', {
    staticClass: "list-unstyled list-inline mb-0"
  }, _vm._l(_vm.tags, function (item, index) {
    return _c('li', {
      key: index,
      staticClass: "list-inline-item"
    }, [_c('router-link', {
      staticClass: "blog-news-info text-dark",
      attrs: {
        "to": {
          name: 'white-papers',
          query: {
            query: item.name
          }
        }
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")])], 1);
  }), 0)])])])])]), _c('div', {
    staticClass: "col-lg-8 wow fadeIn",
    attrs: {
      "data-wow-delay": "0.1s",
      "data-wow-duration": "1.5s"
    }
  }, [_c('article', {
    staticClass: "blog-post-left border"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-12"
  }, [_c('div', {
    staticClass: "post-image"
  }, [_c('a', {
    attrs: {
      "href": "#"
    }
  }, [_c('img', {
    staticClass: "img-fluid img-thumbnail border-0",
    attrs: {
      "src": (_vm$item = _vm.item) === null || _vm$item === void 0 ? void 0 : _vm$item.imageURL,
      "alt": (_vm$item2 = _vm.item) === null || _vm$item2 === void 0 ? void 0 : _vm$item2.photoDescription
    }
  })])]), _c('div', {
    staticClass: "blog-post-content text-left mt-3"
  }, [_c('h4', {
    staticClass: "blog-post-title"
  }, [_c('a', {
    staticClass: "text-left text-dark",
    attrs: {
      "href": "#"
    }
  }, [_vm._v(" " + _vm._s((_vm$item3 = _vm.item) === null || _vm$item3 === void 0 ? void 0 : _vm$item3.title) + " ")])]), _c('div', {
    staticClass: "post-event d-flex align-items-center mt-3"
  }, [_c('a', {
    staticClass: "blog-info-meta pr-1",
    attrs: {
      "href": "#"
    }
  }, [_vm._v(_vm._s((_vm$item4 = _vm.item) === null || _vm$item4 === void 0 ? void 0 : _vm$item4.author))]), _c('SocialShareBookmark', {
    attrs: {
      "title": _vm.title,
      "description": _vm.description,
      "type": "news"
    }
  })], 1), _c('div', {
    staticClass: "post-event d-flex align-items-center my-3"
  }, [_c('p', {
    staticClass: "blog-info-meta"
  }, [_vm._v(_vm._s(_vm.getFormattedDate((_vm$item5 = _vm.item) === null || _vm$item5 === void 0 ? void 0 : _vm$item5.date)))])])]), _c('div', {
    staticClass: "post-content"
  }, [_c('span', {
    domProps: {
      "innerHTML": _vm._s((_vm$item6 = _vm.item) === null || _vm$item6 === void 0 ? void 0 : _vm$item6.article)
    }
  })]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-sm mb-1"
  }, [_c('a', {
    staticClass: "btn theme-btn w-100 rounded centered-link",
    attrs: {
      "href": (_vm$item7 = _vm.item) === null || _vm$item7 === void 0 ? void 0 : _vm$item7.whitepaperLink,
      "variant": "link",
      "target": "_blank"
    }
  }, [_vm._v(" View Whitepaper ")])])])])])])])])])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "title-section"
  }, [_c('h1', {
    staticClass: "title text-center text-white"
  }, [_vm._v(" Cyber Circuits Details ")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('li', {
    staticClass: "text-white"
  }, [_c('i', {
    staticClass: "mdi mdi-home theme-light-color pr-2"
  }), _c('a', {
    attrs: {
      "href": "/#/"
    }
  }, [_vm._v("Home")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ul', {
    staticClass: "blog-news list-unstyled"
  }, [_c('li', {
    staticClass: "blog-item"
  }, [_c('h6', {
    staticClass: "post-news-title"
  }, [_vm._v("Categories")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ul', {
    staticClass: "blog-news list-unstyled"
  }, [_c('li', {
    staticClass: "blog-item"
  }, [_c('h6', {
    staticClass: "post-news-title"
  }, [_vm._v("Archives")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ul', {
    staticClass: "blog-news list-unstyled"
  }, [_c('li', {
    staticClass: "blog-item"
  }, [_c('h6', {
    staticClass: "post-news-title"
  }, [_vm._v("Tags")])])]);

}]

export { render, staticRenderFns }