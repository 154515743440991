var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "main-content"
  }, [_vm._m(0), _c('section', {
    staticClass: "section"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row justify-content-center"
  }, [_c('div', {
    staticClass: "col-12 py-2"
  }, [_c('div', {
    staticClass: "mt-4 text-lg-left text-center"
  }, [_vm._m(1), _vm._m(2), _c('a', {
    attrs: {
      "href": "https://www.projectspectrum.io"
    }
  }, [_c('b-button', {
    staticClass: "btn theme-btn rounded login-reg-btn"
  }, [_vm._v("Learn More")])], 1)])])])])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "inner-header-bg-partner"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "section-breadcrumb"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12 justify-content-center align-self-center pb-2"
  }, [_c('div', {
    staticClass: "breadcrumb-content"
  }, [_c('div', {
    staticClass: "title-section"
  }, [_c('h1', {
    staticClass: "title text-center text-white"
  }, [_vm._v(" Project Spectrum ")])]), _c('div', {
    staticClass: "breadcrumb-nav"
  }, [_c('ul', {
    staticClass: "breadcrumb-link text-center list-unstyled mb-0"
  }, [_c('li', {
    staticClass: "text-white"
  }, [_c('i', {
    staticClass: "mdi mdi-home theme-light-color pr-2"
  }), _c('a', {
    attrs: {
      "href": "/#/"
    }
  }, [_vm._v("Home")])]), _c('li', {
    staticClass: "theme-light-color active"
  }, [_vm._v(" Project Spectrum ")])])])])])])])])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mt-4 text-lg-left text-center"
  }, [_c('div', {
    staticClass: "section-title"
  }, [_c('span', [_vm._v("PROJECT SPECTRUM")]), _c('h2', [_vm._v(" Project Spectrum – A Comprehensive Resource for Small Businesses ")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_vm._v(" To assist small businesses achieve the cybersecurity maturity levels needed to become or remain part of the defense supply chain, the DoD Office of Small Business Programs (OSBP) developed a comprehensive platform to provide the tools and training needed to increase cybersecurity awareness and maintain compliance in accordance with DoD contracting requirements. "), _c('br'), _c('br'), _vm._v(" Project Spectrum offers heightened, free-to-the-user cybersecurity awareness tools and training resources. This platform is an extremely valuable asset for small businesses that may be particularly susceptible to cyber threats due to funding and other resource limitations. "), _c('br'), _c('br'), _vm._v(" The Project Spectrum platform provides up-to-date cybersecurity compliance and policy best practices and educates users on relevant topics that can affect business risk management. Their team of cyber advisors and outreach specialists share the tools and information small businesses need to thwart exploitation of various vulnerabilities like phishing, malware, and other cybersecurity threats. "), _c('br'), _c('br'), _vm._v(" If your small business wants to become part of the Federal supply chain, Project Spectrum can help you implement or bolster your cybersecurity hygiene and compliance processes and programs. "), _c('br'), _c('br'), _c('strong', [_vm._v(" Learn more about Project Spectrum and CMMC accreditation, or complete a self-assessment of your company’s cyber readiness. ")])]);

}]

export { render, staticRenderFns }