var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "main-content"
  }, [_vm._m(0), _c('section', {
    staticClass: "section"
  }, [_vm._m(1), !_vm.isLoggedIn ? _c('div', {
    staticClass: "my-3 container text-center"
  }, [_vm._v(" Tool Listings and Reviews are available."), _c('br'), _vm._v("Please "), _c('router-link', {
    attrs: {
      "to": {
        name: 'login'
      }
    }
  }, [_vm._v("Login ")]), _vm._v(" or "), _c('router-link', {
    attrs: {
      "to": {
        name: 'signup'
      }
    }
  }, [_vm._v("Register ")]), _vm._v(" to view the list of tools."), _c('br'), _c('br'), _c('div', {
    staticClass: "login-register-btn-container mt-2"
  }, [_c('router-link', {
    attrs: {
      "to": {
        name: 'login'
      }
    }
  }, [_c('b-button', {
    staticClass: "btn theme-btn rounded login-reg-btn"
  }, [_vm._v("Login")])], 1), _c('router-link', {
    attrs: {
      "to": {
        name: 'signup'
      }
    }
  }, [_c('b-button', {
    staticClass: "btn theme-btn rounded login-reg-btn",
    attrs: {
      "variant": "none"
    }
  }, [_vm._v("Register")])], 1)], 1)], 1) : _vm._e(), _vm.isLoggedIn ? _c('div', {
    staticClass: "container-fluid px-xs-1 px-md-5"
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "4",
      "xl": "3"
    }
  }, [_c('h5', [_vm._v("Search and Filter")]), _c('div', {
    staticClass: "p-3 px-4 my-2 mb-4 border border-secondary rounded"
  }, [_c('b-form-group', {
    attrs: {
      "label": "Search by Name:"
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Type to search...",
      "trim": ""
    },
    model: {
      value: _vm.searchQuery,
      callback: function ($$v) {
        _vm.searchQuery = $$v;
      },
      expression: "searchQuery"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Catagories:"
    }
  }, [_c('b-form-select', {
    attrs: {
      "options": _vm.optionsCategories
    },
    model: {
      value: _vm.searchCategory,
      callback: function ($$v) {
        _vm.searchCategory = $$v;
      },
      expression: "searchCategory"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Tool Created By:"
    }
  }, [_c('b-form-select', {
    attrs: {
      "options": _vm.optionsCreatedBy
    },
    model: {
      value: _vm.searchCreatedBy,
      callback: function ($$v) {
        _vm.searchCreatedBy = $$v;
      },
      expression: "searchCreatedBy"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Training Resource Type:"
    }
  }, [_c('b-form-checkbox-group', {
    model: {
      value: _vm.searchPaidFree,
      callback: function ($$v) {
        _vm.searchPaidFree = $$v;
      },
      expression: "searchPaidFree"
    }
  }, [_c('b-form-checkbox', {
    attrs: {
      "value": "paid"
    }
  }, [_vm._v(" Paid ")]), _c('b-form-checkbox', {
    attrs: {
      "value": "free"
    }
  }, [_vm._v(" Free ")])], 1)], 1), _c('b-form-group', {
    attrs: {
      "label": "Price Range:"
    }
  }, [_c('b-form-select', {
    attrs: {
      "options": _vm.optionsPriceRanges
    },
    model: {
      value: _vm.searchPriceRange,
      callback: function ($$v) {
        _vm.searchPriceRange = $$v;
      },
      expression: "searchPriceRange"
    }
  })], 1)], 1)]), _c('b-col', {
    attrs: {
      "lg": "8",
      "xl": "9"
    }
  }, [_c('div', [_c('h5', [_vm._v("Products")]), _c('p', [_vm._v(" The Project Spectrum Tools Review section offers an independent, third-party assessment of various cybersecurity-related platforms prepared by our cyber advisors. These reviews are vendor-agnostic and should not be interpreted as an endorsement for any product or platform. If you have questions or concerns, please contact our "), _c('a', {
    attrs: {
      "href": "mailto:outreach@projectspectrum.io"
    }
  }, [_vm._v("Outreach Team")]), _vm._v(" for assistance. ")]), _c('b-form-group', {
    attrs: {
      "id": "fieldset-1",
      "description": "Select up to 5 tools to compare.",
      "label-for": "input-1",
      "valid-feedback": "Thank you!"
    }
  }, [_c('router-link', {
    attrs: {
      "to": {
        name: 'tool-compare',
        query: {
          ids: _vm.compareArray
        }
      }
    }
  }, [_c('b-button', {
    key: "",
    staticClass: "btn-compare-selected",
    attrs: {
      "id": "input-1",
      "variant": "secondary",
      "disabled": _vm.compareArray.length === 0,
      "size": "sm"
    }
  }, [_vm._v("Compare Products")])], 1)], 1)], 1), _vm.items.length === 0 ? _c('p', {
    staticClass: "pb-2"
  }, [_vm._v(" Check back soon for tool reviews... ")]) : _vm._e(), _vm.displayedItems === 0 && _vm.items.length !== 0 ? _c('p', {
    staticClass: "pb-2"
  }, [_vm._v(" No items match the search criteria... ")]) : _vm._e(), _c('b-table', {
    staticClass: "mt-3",
    attrs: {
      "id": "tool-table",
      "fields": _vm.fields,
      "items": _vm.displayedItems,
      "busy": _vm.isLoading,
      "responsive": "",
      "sortable": "true",
      "outlined": "",
      "hover": ""
    },
    scopedSlots: _vm._u([{
      key: "table-busy",
      fn: function () {
        return [_c('div', {
          staticClass: "text-center text-secondary my-2"
        }, [_c('b-spinner', {
          staticClass: "align-middle"
        }), _c('strong', {
          staticClass: "pl-2"
        }, [_vm._v("Loading...")])], 1)];
      },
      proxy: true
    }, {
      key: "cell(title)",
      fn: function (data) {
        return [_c('div', {
          staticClass: "image-and-tool d-flex"
        }, [_c('div', {
          staticClass: "image-container"
        }, [_c('b-img', {
          staticClass: "tool-icon",
          attrs: {
            "src": data.item.mainImage,
            "rounded": "",
            "alt": "Rounded image"
          }
        })], 1), _c('p', {
          staticClass: "clipped-text m-0"
        }, [_vm._v(_vm._s(data.item.title))])])];
      }
    }, {
      key: "cell(more)",
      fn: function (data) {
        return [_c('div', {
          staticClass: "image-and-tool d-flex"
        }, [_c('router-link', {
          attrs: {
            "to": {
              name: 'tool-details',
              query: {
                id: data.item.id
              }
            }
          }
        }, [_c('b-button', {
          staticClass: "btn-more",
          attrs: {
            "variant": "primary",
            "size": "sm"
          }
        }, [_vm._v("Read More")])], 1)], 1)];
      }
    }, {
      key: "cell(compare)",
      fn: function (data) {
        return [_c('div', {
          staticClass: "image-and-tool d-flex"
        }, [_c('b-button', {
          staticClass: "btn-compare",
          attrs: {
            "variant": _vm.compareArray.includes(data.item.id) ? 'secondary' : 'outline-secondary',
            "size": "sm"
          },
          on: {
            "click": function ($event) {
              return _vm.moreButtonSelection(data.item.id);
            }
          }
        }, [_vm._v("Compare")])], 1)];
      }
    }], null, false, 1328762070)
  })], 1)], 1), _c('div', [_c('b-pagination', {
    attrs: {
      "id": "blog-pagination",
      "total-rows": _vm.totalRows,
      "per-page": _vm.perPage,
      "align": "center"
    },
    on: {
      "page-click": _vm.pageClicked
    },
    model: {
      value: _vm.currentPage,
      callback: function ($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1) : _vm._e()])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "inner-header-bg"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "section-breadcrumb"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12 justify-content-center align-self-center pb-2"
  }, [_c('div', {
    staticClass: "breadcrumb-content"
  }, [_c('div', {
    staticClass: "title-section"
  }, [_c('h1', {
    staticClass: "title text-center text-white"
  }, [_vm._v("Useful Tools")])]), _c('div', {
    staticClass: "breadcrumb-nav"
  }, [_c('ul', {
    staticClass: "breadcrumb-link text-center list-unstyled mb-0"
  }, [_c('li', {
    staticClass: "text-white"
  }, [_c('i', {
    staticClass: "mdi mdi-home theme-light-color pr-2"
  }), _c('a', {
    attrs: {
      "href": "/#/"
    }
  }, [_vm._v("Home")])]), _c('li', {
    staticClass: "theme-light-color active"
  }, [_vm._v("Useful Tools")])])])])])])])])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container px-xs-1 px-md-5"
  }, [_c('p', [_vm._v(" The Project Spectrum Useful Tools section offers an independent, third-party assessment of various cybersecurity-related platforms prepared by our cyber advisors. These reviews are vendor-agnostic and should not be interpreted as an endorsement for any product or platform. If you have questions or concerns, please contact our "), _c('a', {
    attrs: {
      "href": "mailto:outreach@projectspectrum.io"
    }
  }, [_vm._v("Outreach Team")]), _vm._v(" for assistance. ")])]);

}]

export { render, staticRenderFns }