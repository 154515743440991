import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

/**
 * Main Vuex Store
 */
const store = new Vuex.Store({
  state: {
    // Access Token
    accessToken: '',
    idToken: '',
    MFA: false,
    refreshToken: '',
    user : null,
    areAccountRequiredFieldsFilled : true,
    migratedAdmin : false,
	isLoggedIn: false
  },
  mutations: {
	setLoggedIn: (state, value) => {
		state.isLoggedIn = value
	},
    setAccessToken: (state, value) => {
      state.accessToken = value
    },
    setIdToken: (state, value) => {
      state.idToken = value
    },
    setMFA: (state, value) => {
      state.MFA = value
    },
    setRefreshToken: (state, value) => {
      state.refreshToken = value
    },
    setUser: (state, value) => {
      state.user = value
    },
    setAreAccountRequiredFieldsFilled: (state, value) => {
      state.areAccountRequiredFieldsFilled = value
    },
    setMigratedAdmin : (state, value) =>{
      state.migratedAdmin = value
    }
  },
  getters: {
    getAccessToken: (state) => state.accessToken,
    getIdToken: (state) => state.idToken,
    getMFA: (state) => state.MFA,
    getRefreshToken: (state) => state.refreshToken,
    getUser: (state) => state.user,
    getAreAccountRequiredFieldsFilled: (state) => state.areAccountRequiredFieldsFilled,
    getMigratedAdmin: (state) => state.migratedAdmin,
	getLoggedIn: (state) => state.isLoggedIn
  },
  actions: {
    // Asynchronous
    setAccessToken: ({commit, state}, value) => {
      commit('setAccessToken', value)
      return state.accessToken
    },
    setIdToken: ({commit, state}, value) => {
      commit('setIdToken', value)
      return state.idToken
    },
    setMFA: ({commit, state}, value) => {
      commit('setMFA', value)
      return state.MFA
    },
    setRefreshToken: ({commit, state}, value) => {
      commit('setRefreshToken', value)
      return state.refreshToken
    },
    setUser: ({commit, state}, value) => {
      commit('setUser', value)
      return state.user
    },
    setAreAccountRequiredFieldsFilled: ({commit, state}, value) => {
      commit('setAreAccountRequiredFieldsFilled', value)
      return state.setAreAccountRequiredFieldsFilled
    },
    setMigratedAdmin : ({commit, state}, value) =>{
      commit('setMigratedAdmin', value)
      return state.migratedAdmin
    },
	setLoggedIn : ({commit, state}, value) =>{
      commit('setLoggedIn', value)
      return state.isLoggedIn
    }
  },
  plugins: [createPersistedState()],
})

export default store
