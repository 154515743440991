var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "main-content"
  }, [_vm._m(0), _c('section', {
    staticClass: "section"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('p', [_vm._v(" APEX Accelerators can use the following resources to serve as critical enablers to opening the defense industrial base to small, medium, large, and new entrant companies. ")]), _vm.trainingCategoriesFilteredVisible.length === 0 && _vm.searchQuery ? _c('p', {
    staticClass: "pb-2"
  }, [_vm._v(" No items match the search criteria... ")]) : _vm._e(), _vm.trainingCategoriesFilteredVisible.length === 0 && !_vm.searchQuery ? _c('p', {
    staticClass: "pb-2"
  }, [_vm._v(" Check back soon for documents.. ")]) : _vm._e(), _c('div', {
    staticClass: "rows"
  }, [_c('div', {
    staticClass: "col-md-6"
  }, [_vm._m(1), _c('div', {
    staticClass: "row pb-2 text-center"
  }, [_c('div', {
    staticClass: "col-md-3"
  }, [_c('img', {
    staticClass: "img-fluid overall-category-img",
    attrs: {
      "src": "assets/images/trainings.png",
      "alt": "Trainings"
    },
    on: {
      "click": function ($event) {
        return _vm.setOverallCategory('trainings');
      }
    }
  })]), _c('div', {
    staticClass: "col-md-3"
  }, [_c('img', {
    staticClass: "img-fluid overall-category-img",
    attrs: {
      "src": "assets/images/resources.png",
      "alt": "Resources"
    },
    on: {
      "click": function ($event) {
        return _vm.setOverallCategory('resources');
      }
    }
  })])])])]), _c('div', {
    staticClass: "row mt-4"
  }, [_c('div', {
    staticClass: "col-md-4 order-lg-2"
  }, [_c('aside', {
    staticClass: "sidebar sidebar-padding border"
  }, [_c('h6', {
    staticClass: "post-news-title search-title"
  }, [_vm._v("Search")]), _c('div', {
    staticClass: "pb-4"
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Search All Training..."
    },
    model: {
      value: _vm.queryCategory,
      callback: function ($$v) {
        _vm.queryCategory = $$v;
      },
      expression: "queryCategory"
    }
  })], 1), _c('div', {
    staticClass: "pb-2 mt-2 mb-3 tags-post"
  }, [_c('div', {
    staticClass: "blog-news-content"
  }, [_c('div', {
    staticClass: "blog-post-list"
  }, [_c('ul', {
    staticClass: "list-unstyled ml-0"
  }, [_vm._m(2), _vm._l(_vm.trainingCategories, function (cat) {
    return _c('li', {
      key: cat.id,
      staticClass: "list-inline-item"
    }, [_c('div', {
      staticClass: "blog-news-info"
    }, [_c('a', {
      staticClass: "text-dark text-small",
      attrs: {
        "href": "javascript:void(0)"
      },
      on: {
        "click": function ($event) {
          return _vm.setTag(cat.tag);
        }
      }
    }, [_vm._v(" " + _vm._s(cat.tag) + " ")])])]);
  })], 2)])])])])]), _c('div', {
    staticClass: "col-md-8 order-lg-1"
  }, [_c('aside', {
    staticClass: "sidebar sidebar-padding border"
  }, [_c('div', {
    staticClass: "blog-post-grid"
  }, [_c('div', {
    staticClass: "row mt-1 mb-4"
  }, [_c('ul', {
    staticClass: "list-group"
  }, _vm._l(_vm.trainingCategoriesFilteredVisible, function (item) {
    return _c('li', {
      key: item.id,
      staticClass: "list-group-item list-group-item-action"
    }, [_c('div', {
      staticClass: "list-group-image-wrapper"
    }, [_c('div', {
      staticClass: "list-group-image"
    }, [item.imageURL == undefined ? _c('img', {
      staticClass: "img-fluid",
      attrs: {
        "alt": "training document icon",
        "src": require("../../assets/images/logo/apex-logo-light.png")
      }
    }) : _c('img', {
      staticClass: "img-fluid",
      attrs: {
        "alt": "training document icon",
        "src": item.imageURL
      }
    })])]), _c('a', {
      staticClass: "text-dark"
    }, [_c('router-link', {
      staticClass: "text-dark",
      attrs: {
        "to": {
          name: 'trainingDetail',
          query: {
            query: item.category
          }
        }
      }
    }, [_c('h4', {
      staticClass: "blog-post-title training-category"
    }, [_vm._v(" " + _vm._s(item.category) + " ")])])], 1)]);
  }), 0)])]), _c('div', [_c('b-pagination', {
    attrs: {
      "id": "blog-pagination",
      "total-rows": _vm.trainingCategoriesFilteredCount,
      "per-page": _vm.perPage,
      "align": "center"
    },
    on: {
      "page-click": _vm.pageClicked
    },
    model: {
      value: _vm.currentPage,
      callback: function ($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)])])])])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "inner-header-bg"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "section-breadcrumb"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12 justify-content-center align-self-center pb-2"
  }, [_c('div', {
    staticClass: "breadcrumb-content"
  }, [_c('div', {
    staticClass: "title-section"
  }, [_c('h1', {
    staticClass: "title text-center text-white"
  }, [_vm._v(" Training ")])]), _c('div', {
    staticClass: "breadcrumb-nav"
  }, [_c('ul', {
    staticClass: "breadcrumb-link text-center list-unstyled mb-0"
  }, [_c('li', {
    staticClass: "text-white"
  }, [_c('i', {
    staticClass: "mdi mdi-home theme-light-color pr-2"
  }), _c('a', {
    attrs: {
      "href": "/#/"
    }
  }, [_vm._v("Home")])]), _c('li', {
    staticClass: "theme-light-color active"
  }, [_vm._v("training")])])])])])])])])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "pb-2 text-left"
  }, [_c('p', {
    staticClass: "text-left"
  }, [_c('strong', [_vm._v("Choose a Document Type")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('li', {
    staticClass: "blog-item"
  }, [_c('h6', {
    staticClass: "post-news-title"
  }, [_vm._v("Tags")])]);

}]

export { render, staticRenderFns }