<template>
  <div class="main-content">
    <!-- BradCrumb header Start-->
    <section class="inner-header-bg">
      <div class="container">
        <!-- Breadcrumb Start-->
        <div class="section-breadcrumb">
          <div class="container">
            <div class="row">
              <div
                class="col-md-12 justify-content-center align-self-center pb-2"
              >
                <div class="breadcrumb-content">
                  <div class="title-section">
                    <h1 class="title text-center text-white">
                      Cyber Readiness Check
                    </h1>
                  </div>
                  <div class="breadcrumb-nav">
                    <ul class="breadcrumb-link text-center list-unstyled mb-0">
                      <li class="text-white">
                        <i class="mdi mdi-home theme-light-color pr-2"></i>
                        <a href="/#/">Home</a>
                      </li>
                      <li class="theme-light-color active">
                        Cyber Readiness Check
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Breadcrumb End -->
      </div>
    </section>
    <!-- BradCrumb header End -->
    <!-- Body Area Start-->
    <section class="section container">
      <p class="px-3">
        If you are handling Controlled Unclassified Information (CUI) or Federal
        Contract Information (FCI) on your network or information systems, there
        are compliance standards you are required to meet. Taking one of our
        Cyber Readiness Checks will help you determine your current level of
        security based on NIST 800-171, CMMC Level 1 and CMMC Level 2
        requirements. These readiness checks serve as a great first step in your
        cybersecurity journey. Need more help? Please reach out to our Cyber
        Advisors through our <router-link to="/contact">Contact Us</router-link> page.
      </p>
      <div v-if="!isLoggedIn" class="my-3 text-center">
        <br />Please
        <router-link :to="{ name: 'login' }">Login </router-link>
        or <router-link :to="{ name: 'signup' }">Register </router-link> as
        organization to take the Cyber Readiness Check.<br />
        <br />
        <div class="login-register-btn-container mt-2">
          <router-link :to="{ name: 'login' }"
            ><b-button class="btn theme-btn rounded login-reg-btn"
              >Login</b-button
            ></router-link
          >
          <router-link :to="{ name: 'signup' }"
            ><b-button
              variant="none"
              class="btn theme-btn rounded login-reg-btn"
              >Register</b-button
            ></router-link
          >
        </div>
      </div>
      <div v-if="isLoggedIn">
        <div class="col-12 py-4">
          <div class="section-title">
            <span>CYBER READINESS CHECK</span>
            <h2>How secure is your organization?</h2>
          </div>
          <div class="mt-4 text-lg-left">
            <p>
              Select the NIST 800-171 or CMMC Readiness Checks to assess your readiness to meet the requirements.
            </p>
            <div class="row">
              <div class="col-md-9">
                <div
                  v-for="asmt in assessments"
                  :key="asmt.key"
                  class="d-flex col-md-12 m-0"
                >
                  <div class="row d-flex align-items-center">
                    <div class="col-md-8 col-lg-9 my-0 pl-0">
                      <h3>{{ asmt.title }}</h3>
                      <p>{{ asmt.description }}.</p>
                    </div>
                    <span class="col-md-4 col-lg-3"
                      ><router-link
                        :to="{
                          name: 'cyber-readiness-check-tool',
                          params: { toolKey: asmt.key },
                        }"
                        ><b-button
                          class="btn w-100 rounded btn-primary theme-btn-secondary"
                        >
                          {{ asmt.title }}
                        </b-button>
                      </router-link></span
                    >
                  </div>
                </div>
                <p class="pt-4">
                  By using these tools you agree to our
                  <span
                    v-b-modal.modal-disclaimer
                    class="my-2 privacy-link-footer"
                    variant="btn w-100 rounded centered-link theme-btn-secondary"
                    >disclaimer</span
                  >.
                </p>
              </div>
              <div class="col-md-3">
                <img
                  src="../../assets/images/cmmc-badge.png"
                  alt=""
                  class="img-fluid"
                  style="position: relative; top: 30%; margin-bottom: 20%"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <b-modal
        id="modal-disclaimer"
        ref="modal"
        title="Disclaimer"
        size="lg"
        ok-only
        no-stacking
      >
        <p>
          This website is offered to you, the user ("User"), conditioned on
          acceptance of the terms, conditions and notices contained herein,
          without modification. &nbsp;User access and use of this site
          constitutes acceptance of these terms and conditions.
        </p>
        <p>&nbsp;</p>
        <p>
          Project Spectrum is a Web Portal providing access to various
          educational and training videos and other tools, as well as an online
          community, company dashboard, and Cyber Readiness Check.&nbsp; These
          items are provided as resources to assist the User in its effort to
          become more cybersecure. &nbsp;The various resources made available at
          the Project Spectrum Web Portal are not meant to be used to certify
          compliance with any government standard or with that any contractual
          obligations a User may have.&nbsp; The resources and data made
          available at the Project Spectrum Web Portal are provided "as is"
          without warranties of any kind, express or implied, including, but not
          limited to, warranties of performance, merchantability and fitness for
          a particular purpose. Project Spectrum makes no representations or
          warranties regarding the condition or functionality of this website,
          its suitability for use, or that this site will be uninterrupted or
          error-free.&nbsp; Project Spectrum expressly disclaims any and all
          liability arising from use of any of the resources on the Project
          Spectrum Web Portal.
        </p>
        <p>&nbsp;</p>
        <p>
          The authors and publishers of the information on this site are not
          herein engaged in rendering legal or other professional advice or
          services. &nbsp;Consequently, such information should not be used as a
          substitute for consultation with professional legal or other competent
          advisors.
        </p>
        <p>&nbsp;</p>
        <p>
          Project Spectrum has made every attempt to ensure that the information
          contained in this site is correct.&nbsp; However, Project Spectrum is
          not responsible for any errors or omissions, or for the results
          obtained from the use of this information.&nbsp; All information on
          this site is provided “as is” with no guarantee of completeness,
          accuracy, timeliness or of the results obtained from the use of this
          information.&nbsp; Additionally, this information is provided without
          warranty of any kind, express or implied, including, but not limited
          to, warranties of performance, merchantability and fitness for a
          particular purpose.&nbsp; In no event will Project Spectrum, its
          constituent partners, or the officers, members, agents or employees
          thereof be liable to User or anyone else for any decision made or
          action taken (or not taken) in reliance on the information in this
          site or for any indirect, consequential, special or similar damages,
          even if advised of the possibility of such damages.
        </p>
        <p>&nbsp;</p>
        <p>
          There are links to third-party Internet websites contained within the
          Project Spectrum Web Portal. &nbsp;The websites referenced or linked
          from the Project Spectrum Web Portal are not under Project Spectrum
          control. Project Spectrum includes these links solely as a convenience
          to you, and the presence of such a link is not a referral or an
          endorsement of the linked site, its operator, or its contents.
          &nbsp;Project Spectrum does not assume any responsibility or liability
          for any information, communications or materials available at such
          linked sites, or at any link contained in a linked site. &nbsp;User
          assumes sole responsibility for use of third-party websites.
        </p>
      </b-modal>
    </section>
  </div>
</template>
<script>
import axios from 'axios';
import { isLoggedIn } from '../../utils/authentication';
export default {
  data() {
    return {
      assessments: [],
    };
  },
  mounted() {},
  computed: {
    isLoggedIn() {
      return isLoggedIn();
    },
  },
  created() {
    this.loadAssessments();
  },
  methods: {
    init() {
      /* The footer height no longer needs to be set */
      // this.initFooterHeight();
    },
    initFooterHeight() {
      var footerHeight = $('footer').outerHeight();
      $('.main-content').css('margin-bottom', footerHeight);
    },
    async loadAssessments(tool) {
      /*  API call to assessment/schema */
      axios.get('/api/assessment/schema/').then((response) => {
        this.assessments = response.data.Items;
      });
    },
  },
};
</script>
<style scoped>
.privacy-link-footer {
  color: #007bff;
}

.inner-header-bg {
  background-image: url('~@/assets/images/headers/resources.png');
  background-position: center center;
}

h3 {
  font-size: 1.2rem;
  margin-top: 1rem;
}
</style>
