<template>
  <div class="main-content bg-light">
    <!-- BradCrumb header Start-->
    <section class="inner-header-bg">
      <div class="container">
        <!-- Breadcrumb Start-->
        <div class="section-breadcrumb">
          <div class="container">
            <div class="row">
              <div
                class="col-md-12 justify-content-center align-self-center pb-2"
              >
                <div class="breadcrumb-content">
                  <div class="title-section">
                    <h1 class="title text-center text-white">My Account</h1>
                  </div>
                  <div class="breadcrumb-nav">
                    <ul class="breadcrumb-link text-center list-unstyled mb-0">
                      <li class="text-white">
                        <i class="mdi mdi-home theme-light-color pr-2"></i>
                        <a href="/#/">Home</a>
                      </li>
                      <li class="theme-light-color active">My Account</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Breadcrumb End -->
      </div>
    </section>
    <!-- BradCrumb header End -->
    <!-- Register Area Start-->
    <!-- Overlay -->
    <b-overlay :show="busy" no-wrap class="position-fixed">
      <template #overlay>
        <b-spinner label="Loading..."></b-spinner>
      </template>
    </b-overlay>
    <section class="login-form section bg-light">
      <div class="container">
        <!-- My Account Container -->
        <div class="login-container">
          <div class="row justify-content-center">
            <div class="mt-1 w-100">
              <b-alert variant="danger" show v-if="requiredFieldsError">
                <p class="my-2">
                  You have required fields in you Account that are empty.
                  <b
                    >Please fill in all required fields and click "Update Account" at the bottom of the page, before continuing to
                    other site content.</b
                  >
                </p>
              </b-alert>
              <form-wizard
                ref="form"
                @onComplete="onComplete"
                @onCannotCompleteFromErrors="onCannotCompleteFromErrors"
                :customButtonLabel="customButtonLabel"
                :errorMessage="errorMessage"
                :successMessage="successMessage"
                class="width-override form-floating"
              >
                <tab-content title="My Account" :selected="true">
                  <b-form-checkbox
                    v-model="formData.checkedMFA"
                    name="check-button"
                    size="md"
                    :disabled="!phoneNumberSaved"
                    @change="selectionChangedMFA"
                    switch
                  >
                    Multifactor Authentication (MFA) is
                    <b>{{ formData.checkedMFA ? 'Enabled' : 'Disabled' }}</b>
                  </b-form-checkbox>
                  <small
                    class="form-text text-muted"
                    v-if="!hasPhoneNumberSaved"
                  >
                    Phone number required. Please add one under the Contact
                    Information, then 'Update Account'.
                  </small>
                  <small
                    class="form-text text-muted"
                    v-if="!!hasPhoneNumberSaved"
                  >
                    Select to enable MFA for +1{{ phoneNumberSaved }}
                  </small>

                  <h5 class="py-4">Account Details</h5>
                  <b-row>
                    <!-- First Name -->
                    <b-col class="py-1" sm="12" md="6">
                      <div class="form-label-group">
                        <label for="firstName">First Name*</label>
                        <input
                          type="text"
                          id="firstName"
                          class="form-control"
                          :class="hasError('firstName') ? 'is-invalid' : ''"
                          placeholder="First Name"
                          v-model="formData.firstName"
                        />
                        <div
                          v-if="hasError('firstName')"
                          class="invalid-feedback"
                        >
                          <div
                            class="error"
                            v-if="!$v.formData.firstName.required"
                          >
                            Please provide your first name
                          </div>
                        </div>
                      </div>
                    </b-col>
                    <!-- Last Name -->
                    <b-col class="py-1" sm="12" md="6">
                      <div class="form-group">
                        <label for="lastName">Last Name*</label>
                        <input
                          type="text"
                          class="form-control"
                          :class="hasError('lastName') ? 'is-invalid' : ''"
                          placeholder="Last Name"
                          v-model="formData.lastName"
                        />
                        <div
                          v-if="hasError('lastName')"
                          class="invalid-feedback"
                        >
                          <div
                            class="error"
                            v-if="!$v.formData.lastName.required"
                          >
                            Please provide your last name
                          </div>
                        </div>
                      </div>
                    </b-col>
                    <!-- Email Address -->
                    <b-col class="py-1" sm="12" md="6">
                      <div class="form-group">
                        <label for="email">Your Email*</label>
                        <input
                          type="email"
                          class="form-control"
                          :class="hasError('email') ? 'is-invalid' : ''"
                          placeholder="Email Address"
                          v-model="formData.email"
                          disabled="true"
                        />
                        <div v-if="hasError('email')" class="invalid-feedback">
                          <div class="error" v-if="!$v.formData.email.required">
                            Email address is required
                          </div>
                          <div class="error" v-if="!$v.formData.email.email">
                            Should be in email format
                          </div>
                        </div>
                      </div>
                    </b-col>
                    <!-- Forum Screen Name -->
                    <b-col class="py-1" sm="12" md="6">
                      <div class="form-group">
                        <label for="forumScreenName">Forum Username*</label>
                        <input
                          type="text"
                          class="form-control"
                          :class="
                            hasError('forumScreenName') ? 'is-invalid' : ''
                          "
                          placeholder="Username"
                          v-model="formData.forumScreenName"
                        />
                        <div
                          v-if="hasError('forumScreenName')"
                          class="invalid-feedback"
                        >
                          <div
                            class="error"
                            v-if="!$v.formData.forumScreenName.required"
                          >
                            Please provide a screen name
                          </div>
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                  <hr class="col-sm-12" />
                  <h5 class="py-4">Contact Information</h5>
                  <b-row>
                    <!-- Telephone -->
                    <b-col class="py-1" sm="12" md="6">
                      <div class="form-group">
                        <label for="telephone">Mobile Phone</label>
                        <input
                          type="tel"
                          pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                          class="form-control"
                          :class="hasError('telephone') ? 'is-invalid' : ''"
                          :disabled="formData.checkedMFA"
                          placeholder="Phone Number"
                          v-model="formData.telephone"
                        />
                        <div
                          v-if="hasError('telephone')"
                          class="invalid-feedback"
                        >
                          <div
                            class="error"
                            v-if="!$v.formData.telephone.goodPhone"
                          >
                            Please provide a 10-digit U.S. phone number
                          </div>
                        </div>
                        <small v-if="formData.checkedMFA" class="text-muted">
                          To edit phone number, please turn off MFA at top of
                          this page.
                        </small>
                        <small v-if="!formData.checkedMFA" class="text-muted">
                          This phone number will be used for account
                          multi-factor authentication.
                        </small>
                      </div>
                    </b-col>
                    <!-- Role in Organization -->
                    <b-col
                      class="py-1"
                      sm="12"
                      md="6"
                      v-if="isOrganizationUser"
                    >
                      <div class="form-group">
                        <label for="organizationRole"
                          >Role in Organization</label
                        >
                        <select
                          :class="
                            hasError('organizationRole') ? 'is-invalid' : ''
                          "
                          class="form-control"
                          v-model="formData.organizationRole"
                        >
                          <option value="" selected="selected">
                            Select Organization Role
                          </option>
                          <option value="Executive or leadership">
                            Executive or leadership
                          </option>
                          <option
                            value="Chief Information Officer (CIO) or Chief Information Security Officer (CISO)"
                          >
                            Chief Information Officer (CIO) or Chief Information
                            Security Officer (CISO)
                          </option>
                          <option value="Operations Manager">
                            Operations Manager
                          </option>
                          <option value="Technical program manager">
                            Technical program manager
                          </option>
                          <option value="Sales">Sales</option>
                          <option value="Manufacturing">Manufacturing</option>
                          <option value="Research or engineering">
                            Research or engineering
                          </option>
                          <option value="Marketing or communications">
                            Marketing or communications
                          </option>
                          <option value="Financial">Financial</option>
                          <option value="Human resources">
                            Human resources
                          </option>
                          <option value="Analyst">Analyst</option>
                          <option value="IT support">IT support</option>
                          <option value="Other">Other</option>
                        </select>
                      </div>
                    </b-col>

                    <!-- Organization -->
                    <b-col
                      class="py-1"
                      sm="12"
                      md="6"
                      v-if="isOrganizationUser"
                    >
                      <div class="form-group">
                        <label for="organization"> Organization* </label>
                        <input
                          type="text"
                          class="form-control"
                          :class="hasError('organization') ? 'is-invalid' : ''"
                          placeholder="Organization"
                          :disabled="isOrganizationUser && !isOrganizationAdmin"
                          v-model="formData.organization"
                        />
                        <div
                          v-if="hasError('organization')"
                          class="invalid-feedback"
                        >
                          <div
                            class="error"
                            v-if="!$v.formData.organization.required"
                          >
                            Enter the name of your organization
                          </div>
                        </div>
                      </div>
                    </b-col>
                    <!-- Website -->
                    <b-col
                      class="py-1"
                      sm="12"
                      md="6"
                      v-if="isOrganizationUser"
                    >
                      <div class="form-group">
                        <label for="website">Website</label>
                        <input
                          type="text"
                          class="form-control"
                          :class="hasError('website') ? 'is-invalid' : ''"
                          placeholder="Website URL"
                          :disabled="isOrganizationUser && !isOrganizationAdmin"
                          v-model="formData.website"
                        />
                      </div>
                    </b-col>

                    <!-- Street Address 1 -->
                    <b-col class="py-1" sm="12" md="6">
                      <div class="form-group">
                        <label for="address1">Street Address 1*</label>
                        <input
                          type="text"
                          class="form-control"
                          :disabled="isOrganizationUser && !isOrganizationAdmin"
                          :class="hasError('address1') ? 'is-invalid' : ''"
                          placeholder="Address"
                          v-model="formData.address1"
                        />
                        <small
                          id="passwordHelpBlock"
                          class="form-text text-muted"
                          v-if="!hasError('address1')"
                        >
                          Street address, P.O. box, company name
                        </small>
                        <div
                          v-if="hasError('address1')"
                          class="invalid-feedback"
                        >
                          <div
                            class="error"
                            v-if="!$v.formData.address1.required"
                          >
                            Please provide organization street address
                          </div>
                        </div>
                      </div>
                    </b-col>
                    <!-- Street Address 2 -->
                    <b-col class="py-1" sm="12" md="6">
                      <div class="form-group">
                        <label for="address2">Street Address 2</label>
                        <input
                          type="text"
                          class="form-control"
                          :disabled="isOrganizationUser && !isOrganizationAdmin"
                          :class="hasError('address2') ? 'is-invalid' : ''"
                          placeholder="Address Continued..."
                          v-model="formData.address2"
                        />
                        <small
                          id="passwordHelpBlock"
                          class="form-text text-muted"
                          v-if="!hasError('address2')"
                        >
                          Apartment, suite, unit, building, floor, etc.
                        </small>
                      </div>
                    </b-col>
                    <!-- City -->
                    <b-col class="py-1" sm="12" md="6">
                      <div class="form-group">
                        <label for="city">City*</label>
                        <input
                          type="text"
                          class="form-control"
                          :disabled="isOrganizationUser && !isOrganizationAdmin"
                          :class="hasError('city') ? 'is-invalid' : ''"
                          placeholder="City"
                          v-model="formData.city"
                        />
                        <div v-if="hasError('city')" class="invalid-feedback">
                          <div class="error" v-if="!$v.formData.city.required">
                            Please provide organization city
                          </div>
                        </div>
                      </div>
                    </b-col>
                    <!-- State -->
                    <b-col class="py-1" sm="12" md="6">
                      <div class="form-group">
                        <label for="state">State*</label>
                        <select
                          :class="hasError('state') ? 'is-invalid' : ''"
                          class="form-control"
                          :disabled="isOrganizationUser && !isOrganizationAdmin"
                          v-model="formData.state"
                        >
                          <option value="">Select State</option>
                          <option value="AL">Alabama</option>
                          <option value="AK">Alaska</option>
                          <option value="AZ">Arizona</option>
                          <option value="AR">Arkansas</option>
                          <option value="CA">California</option>
                          <option value="CO">Colorado</option>
                          <option value="CT">Connecticut</option>
                          <option value="DE">Delaware</option>
                          <option value="DC">District Of Columbia</option>
                          <option value="FL">Florida</option>
                          <option value="GA">Georgia</option>
                          <option value="HI">Hawaii</option>
                          <option value="ID">Idaho</option>
                          <option value="IL">Illinois</option>
                          <option value="IN">Indiana</option>
                          <option value="IA">Iowa</option>
                          <option value="KS">Kansas</option>
                          <option value="KY">Kentucky</option>
                          <option value="LA">Louisiana</option>
                          <option value="ME">Maine</option>
                          <option value="MD">Maryland</option>
                          <option value="MA">Massachusetts</option>
                          <option value="MI">Michigan</option>
                          <option value="MN">Minnesota</option>
                          <option value="MS">Mississippi</option>
                          <option value="MO">Missouri</option>
                          <option value="MT">Montana</option>
                          <option value="NE">Nebraska</option>
                          <option value="NV">Nevada</option>
                          <option value="NH">New Hampshire</option>
                          <option value="NJ">New Jersey</option>
                          <option value="NM">New Mexico</option>
                          <option value="NY">New York</option>
                          <option value="NC">North Carolina</option>
                          <option value="ND">North Dakota</option>
                          <option value="OH">Ohio</option>
                          <option value="OK">Oklahoma</option>
                          <option value="OR">Oregon</option>
                          <option value="PA">Pennsylvania</option>
                          <option value="RI">Rhode Island</option>
                          <option value="SC">South Carolina</option>
                          <option value="SD">South Dakota</option>
                          <option value="TN">Tennessee</option>
                          <option value="TX">Texas</option>
                          <option value="UT">Utah</option>
                          <option value="VT">Vermont</option>
                          <option value="VA">Virginia</option>
                          <option value="WA">Washington</option>
                          <option value="WV">West Virginia</option>
                          <option value="WI">Wisconsin</option>
                          <option value="WY">Wyoming</option>
                        </select>
                        <div v-if="hasError('state')" class="invalid-feedback">
                          <div class="error" v-if="!$v.formData.state.required">
                            Please provide organization state
                          </div>
                        </div>
                      </div>
                    </b-col>
                    <!-- Zip Code -->
                    <b-col class="py-1" sm="12" md="6">
                      <div class="form-group">
                        <label for="zipCode">Zip Code*</label>
                        <input
                          type="number"
                          class="form-control"
                          :disabled="isOrganizationUser && !isOrganizationAdmin"
                          :class="hasError('zipCode') ? 'is-invalid' : ''"
                          placeholder="Zip Code"
                          v-model="formData.zipCode"
                        />
                        <div
                          v-if="hasError('zipCode')"
                          class="invalid-feedback"
                        >
                          <div
                            class="error"
                            v-if="!$v.formData.zipCode.required"
                          >
                            Please provide your organization zip code
                          </div>
                        </div>
                      </div>
                    </b-col>
                    <!-- Country -->
                    <b-col class="py-1" sm="12" md="6">
                      <div class="form-group">
                        <label for="country">Country*</label>
                        <input
                          type="text"
                          class="form-control"
                          :disabled="isOrganizationUser && !isOrganizationAdmin"
                          :class="hasError('country') ? 'is-invalid' : ''"
                          placeholder="Country"
                          v-model="formData.country"
                        />
                         <div
                          v-if="hasError('country')"
                          class="invalid-feedback"
                        >
                          <div
                            class="error"
                            v-if="!$v.formData.country.required"
                          >
                            Please provide your organization country
                          </div>
                        </div>
                      </div>
                    </b-col>
                    <!-- Organization Type -->
                    <hr class="col-sm-12" v-if="isOrganizationUser" />
                    <b-row class="p-0 m-0" v-if="isOrganizationUser">
                      <b-col class="py-1" sm="12">
                        <h5 class="py-4">Organization Details</h5>
                        <div class="form-group">
                          <label for="organizationType"
                            >Organization Type*</label
                          >
                          <select
                            :class="
                              hasError('organizationType') ? 'is-invalid' : ''
                            "
                            :disabled="
                              isOrganizationUser && !isOrganizationAdmin
                            "
                            v-model="formData.organizationType"
                            multiple
                            class="form-control h-auto"
                            id="organizationType"
                            style="min-height: 290px"
                          >
                            <option value="Small Business (SB)">
                              Small Business (SB)
                            </option>
                            <option value="Small Disadvantaged Business (SDB)">
                              Small Disadvantaged Business (SDB)
                            </option>
                            <option value="8(a) Business">8(a) Business</option>
                            <option value="Women Owned Small Business (WOSB)">
                              Women Owned Small Business (WOSB)
                            </option>
                            <option value="Veteran Owned Small Business (VOSB)">
                              Veteran Owned Small Business (VOSB)
                            </option>
                            <option
                              value="Service Disabled Veteran Owned Small Business (SDVOSB)"
                            >
                              Service Disabled Veteran Owned Small Business
                              (SDVOSB)
                            </option>
                            <option value="HUBZone Business">
                              HUBZone Business
                            </option>
                            <option
                              value="Economically Disadvantaged Women-Owned Small Business (EDWOSB)"
                            >
                              Economically Disadvantaged Women-Owned Small
                              Business (EDWOSB)
                            </option>
                            <option value="Large Business">
                              Large Business
                            </option>
                            <option value="Academic Institution">
                              Academic Institution
                            </option>
                            <option value="Non-Profit">Non-Profit</option>
                          </select>
                          <div
                            v-if="hasError('organizationType')"
                            class="invalid-feedback"
                          >
                            <div
                              class="error"
                              v-if="!$v.formData.organizationType.required"
                            >
                              Please provide your organization type(s)
                            </div>
                          </div>
                          <small
                            class="form-text text-muted"
                            v-if="!hasError('organizationType')"
                          >
                            Please note that you may choose more than one
                            organization type.
                          </small>
                        </div>
                      </b-col>
                      <!-- CAGE Code -->
                      <b-col class="py-1" sm="12" md="6">
                        <div class="form-group">
                          <label for="codeCAGE">CAGE Code</label>
                          <input
                            type="text"
                            class="form-control"
                            :disabled="
                              isOrganizationUser && !isOrganizationAdmin
                            "
                            :class="hasError('codeCAGE') ? 'is-invalid' : ''"
                            placeholder="CAGE code"
                            v-model="formData.codeCAGE"
                          />
                          <small
                            id="passwordHelpBlock"
                            class="form-text text-muted"
                            v-if="!hasError('codeCAGE')"
                          >
                            5 character alpha-numeric identifier
                          </small>
                        </div>
                      </b-col>
                      <!-- NAICS Codes -->
                      <b-col class="py-1" sm="12" md="6">
                        <div class="form-group">
                          <label for="codesNAICS">NAICS Codes</label>
                          <input
                            type="text"
                            class="form-control"
                            :disabled="
                              isOrganizationUser && !isOrganizationAdmin
                            "
                            :class="hasError('codesNAICS') ? 'is-invalid' : ''"
                            placeholder="NAICS codes (511120,...)"
                            v-model="formData.codesNAICS"
                          />
                          <small
                            id="passwordHelpBlock"
                            class="form-text text-muted"
                            v-if="!hasError('codesNAICS')"
                          >
                            Numerics separated by space or comma.
                          </small>
                        </div>
                      </b-col>
                      <!-- Number of Employees -->
                      <b-col class="py-1" sm="12" md="6">
                        <div class="form-group">
                          <label for="numberOfEmployees"
                            >Number of Employees</label
                          >
                          <select
                            :class="
                              hasError('numberOfEmployees') ? 'is-invalid' : ''
                            "
                            :disabled="
                              isOrganizationUser && !isOrganizationAdmin
                            "
                            class="form-control"
                            v-model="formData.numberOfEmployees"
                          >
                            <option value="">Select Number of Employees</option>
                            <option value="1-10">1-10</option>
                            <option value="11-50">11-50</option>
                            <option value="51-500">51-500</option>
                            <option value=" 501-2,000">501-2,000</option>
                            <option value="2,001-10,000">2,001-10,000</option>
                            <option value=">10,001">&gt;10,001</option>
                          </select>
                        </div>
                      </b-col>
                      <!-- Annual Revenues -->
                      <b-col class="py-1" sm="12" md="6">
                        <div class="form-group">
                          <label for="annualRevenue">Annual Revenues</label>
                          <select
                            id="annualRevenue"
                            :class="
                              hasError('annualRevenue') ? 'is-invalid' : ''
                            "
                            :disabled="
                              isOrganizationUser && !isOrganizationAdmin
                            "
                            class="form-control"
                            v-model="formData.annualRevenue"
                          >
                            <option value="">Select Annual Revenue</option>
                            <option value="<$200K">&lt;$200K</option>
                            <option value="$200K-$1M">$200K-$1M</option>
                            <option value="$1M-$10M">$1M-$10M</option>
                            <option value="$10M-$50M">$10M-$50M</option>
                          </select>
                        </div>
                      </b-col>
                      <!-- DUNS Number -->
                      <b-col class="py-1" sm="12" md="6">
                        <div class="form-group">
                          <label for="numberDUNS">DUNS Number</label>
                          <input
                            type="text"
                            class="form-control"
                            :class="hasError('numberDUNS') ? 'is-invalid' : ''"
                            placeholder="DUNS Number"
                            :disabled="
                              isOrganizationUser && !isOrganizationAdmin
                            "
                            v-model="formData.numberDUNS"
                          />
                          <small
                            id="passwordHelpBlock"
                            class="form-text text-muted"
                            v-if="!hasError('numberDUNS')"
                          >
                            9 character numeric identifier
                          </small>
                        </div>
                      </b-col>
                    </b-row>
                  </b-row>
                </tab-content>
              </form-wizard>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Register Area End-->
    <!-- Invitaiton Modal -->
    <InvitationModal ref="invitation-modal" @invite-accepted="getAccountData" />
    <JoinRequestModal ref="join-request-modal" @request-accepted="getAccountData" />
    <MigrateModal
      ref="migrate-modal"
      @account-migrated="getAccountData"
      :organization="this.formData.organization"
    />
    <RequiredFieldsModal ref="required-fields-modal" />
    <WelcomeAdminModal ref="welcome-admin-modal" />
  </div>
</template>
<script>
import ValidationHelper from './vue-step-wizard/ValidationHelper.vue';
import TabContent from './vue-step-wizard/TabContent.vue';
import FormWizard from './vue-step-wizard/FormWizard.vue';
import {
  handleApiError,
  isPhoneNumberVerified,
  getUserEmail,
  storeAuthTokens,
  isLoggedIn,
  getAreAccountRequiredFieldsFilled,
  setUser,
  getMigratedAdmin
} from '../utils/authentication';
import axios from 'axios';
import InvitationModal from './invitation-modal.vue';
import JoinRequestModal from './join-request-modal.vue';
import RequiredFieldsModal from './required-fields-modal.vue';
import MigrateModal from './migrate-modal.vue';
import WelcomeAdminModal from './welcome-admin-modal.vue';

import { required, requiredIf, email } from 'vuelidate/lib/validators';
const UserGroup = {
  INDIVIDUAL: 'Individual',
  ORG_ADMIN: 'OrganizationAdmin',
  ORG_MEMBER: 'OrganizationMember',
};

function isOrganizationUser(value) {
  return (
    value.userGroup === UserGroup.ORG_MEMBER ||
    value.userGroup === UserGroup.ORG_ADMIN
  );
}
function isOrganizationAdmin(value) {
  return value.userGroup === UserGroup.ORG_ADMIN;
}

//component code
export default {
  name: 'my-account',
  components: {
    FormWizard,
    TabContent,
    InvitationModal,
    RequiredFieldsModal,
    MigrateModal,
    JoinRequestModal
  },
  mixins: [ValidationHelper],
  data() {
    return {
      busy: false,
      requiredFieldsError: false,
      errorMessage: '',
      successMessage: '',
      // invitations: [],
      customButtonLabel: 'Update Account',
      hasPhoneNumberSaved: false,
      phoneNumberSaved: '',
      formData: {
        userGroup: UserGroup.INDIVIDUAL,
        checkedMFA: false,
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: '',
        forumScreenName: '',
        knowCompanyCode: false,
        companyCode: '',
        organization: '',
        organizationRole: '',
        website: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zipCode: '',
        country: '',
        telephone: '',
        organizationType: [],
        codeCAGE: '',
        codesNAICS: '',
        numberOfEmployees: '',
        annualRevenue: '',
        numberDUNS: '',
      },
      validationRules: [
        {
          firstName: { required },
          lastName: { required },
          email: { required, email },
          forumScreenName: { required },
          organization: {
            required: requiredIf(function (organizationType) {
              return isOrganizationAdmin(organizationType);
            }),
          },
          organizationRole: {},
          website: {},
          address1: { required },
          address2: {},
          city: { required },
          state: { required },
          zipCode: { required },
          country: { required },
          telephone: {
            goodPhone: (telephone) => {
              return !telephone || telephone.length === 10;
            },
          },
          organizationType: {
            required: requiredIf(function (organizationType) {
              return (
                isOrganizationAdmin(organizationType) ||
                organizationType.length > 0
              );
            }),
          },
          codeCAGE: {},
          codesNAICS: {},
          numberOfEmployees: {},
          annualRevenue: {},
          numberDUNS: {},
        },
      ],
      UserGroup,
    };
  },
  beforeMount() {
    if (isLoggedIn()) {
      this.getAccountData();
    } else {
      this.$router.push({ path: 'login' });
    }
  },
  computed: {
    isOrganizationUser() {
      return (
        this.formData.userGroup === UserGroup.ORG_MEMBER ||
        this.formData.userGroup === UserGroup.ORG_ADMIN
      );
    },
    isOrganizationAdmin() {
      return this.formData.userGroup === UserGroup.ORG_ADMIN;
    },
  },
  methods: {
    initFooterHeight() {
      var footerHeight = $('footer').outerHeight();
      $('.main-content').css('margin-bottom', footerHeight);
      $('.main-content').css('min-height', '100vh');
    },
    getAccountData() {
      this.busy = true;
      axios
        .get(`/api/user/`)
        .then((response) => {
          /** Set User in Store */
          setUser(response.data);

          /** Populate the form fields from the user data */
          this.populateFormDataFromResponse(response);

          /** Check if the account needs migrated */
          if (response.data.memberOption) {
            this.$refs['migrate-modal'].show();
          }
          /** Check if required fields need filled */
          if (!getAreAccountRequiredFieldsFilled()) {
            this.$refs['required-fields-modal'].show();
            this.$refs['form']._validateCurrentTab();
            this.requiredFieldsError = true;
          } else {
            //console.log('False');
            this.requiredFieldsError = false;
          }

          /** Check if there are Org invitations */
          // if(response.data.invitations.length > 0){
          //   console.log('Pending Invitations', response.data.invitations.length)
          //   this.invitations = response.data.invitations
          //   this.$refs['invitation-modal'].show();
          // }
        })
        .catch((error) => {
          handleApiError(error.response, this.$router);
        })
        .finally(() => {
          this.busy = false;
        });
    },
    async populateFormDataFromResponse(response) {
      /** Map response json to the form data */
      //console.log('User Data Response', response);
      try {
        // Set User Group
        this.formData.userGroup = response.data.user_group;

        // Populate Form data
        this.formData.checkedMFA = response.data.mfa;
        this.formData.firstName = response.data.name?.split(' ')[0];
        this.formData.lastName = response.data.name?.split(' ')[1];
        this.formData.forumScreenName = response.data.forumScreenName;
        this.formData.email = response.data.email;
        this.formData.organization = response.data.organization;
        this.formData.organizationRole = response.data.titleInOrganization;
        this.formData.organizationType = response.data.organizationType
          ? response.data.organizationType.split(';')
          : '';
        this.formData.address1 = response.data.address1;
        this.formData.address2 = response.data.address2;
        this.formData.city = response.data.city;
        this.formData.state = response.data.state;
        this.formData.zipCode = response.data.zipcode;
        this.formData.country = response.data.country;
        this.formData.website = response.data.website;
        this.formData.telephone = response.data.phone_number?.replace('+1', '');
        this.formData.codeCAGE = response.data.CAGECode;
        this.formData.numberDUNS = response.data.DUNSNumber;
        this.formData.codesNAICS = response.data.NAICSCodes;
        this.formData.numberOfEmployees = response.data.numberOfEmployees
          ? response.data.numberOfEmployees
          : '';
        this.formData.annualRevenue = response.data.annualRevenues
          ? response.data.annualRevenues
          : '';

        /**
         * Does the user have a phone number saved?
         * This will determine if he can enable MFA
         */
        if (this.formData.telephone) {
          this.hasPhoneNumberSaved = true;
          this.phoneNumberSaved = this.formData.telephone;
        } else {
          this.hasPhoneNumberSaved = false;
          this.phoneNumberSaved = null;
        }
      } catch (error) {
        console.error('Error parsing user account data', error);
      }
    },
    onCannotCompleteFromErrors() {
      this.errorMessage = 'Please correct all form errors before submitting';
      this.successMessage = '';
    },
    selectionChangedMFA(enabled) {
      console.log('MFA Changed', enabled);
      var isPhoneVerified = isPhoneNumberVerified();
      if (enabled) {
        if (isPhoneVerified) {
          /** Phone number already verified, enable MFA*/
          this.enableMFA();
        } else {
          /** Need to verify phone number */
          this.$router.push({
            path: 'verify-phone',
            query: { phone: this.phoneNumberSaved },
          });
        }
      } else {
        this.disableMFA();
      }
    },
    enableMFA() {
      this.busy = true;
      axios
        .post('/api/user/phone/mfa/enable', {
          enabled: true,
        })
        .then((response) => {
          //console.log('MFA Enabled', response);
          this.refreshSession();
        })
        .catch((error) => {
          this.error = error.response.data.message;
          handleApiError(error, this.$router);
        })
        .finally(() => {
          this.busy = false;
        });
    },
    disableMFA() {
      this.busy = true;
      axios
        .post('/api/user/phone/mfa/enable', {
          enabled: false,
        })
        .then((response) => {
          //console.log('MFA Disabled', response);
          this.refreshSession();
        })
        .catch((error) => {
          this.error = error.response.data.message;
          handleApiError(error, this.$router);
        })
        .finally(() => {
          this.busy = false;
        });
    },
    refreshSession() {
      this.busy = true;
      axios
        .post('/api/user/session/refresh', {
          email: getUserEmail(),
        })
        .then((response) => {
          //console.log('Session Refreshed', response);
          storeAuthTokens(response.data);
          this.getAccountData();
        })
        .catch((error) => {
          handleApiError(error, this.$router);
          this.error = error.response.data.message;
        })
        .finally(() => {
          this.busy = false;
        });
    },
    onComplete() {
      //console.log('OnComplete');
      this.errorMessage = '';
      this.successMessage = '';
      const body = {};
      body.firstName = this.formData.firstName;
      body.lastName = this.formData.lastName;
      body.email = this.formData.email;
      body.password = this.formData.password;
      body.forumScreenName = this.formData.forumScreenName;
      body.streetAddress1 = this.formData.address1;
      body.streetAddress2 = this.formData.address2;
      body.city = this.formData.city;
      body.state = this.formData.state;
      body.zipcode = this.formData.zipCode;
      body.country = this.formData.country;
      body.telephone = this.formData.telephone;
      // Only updated by Organization Admin
      if (this.isOrganizationUser) {
        body.titleInOrganization = this.formData.organizationRole;
      }
      if (this.isOrganizationAdmin) {
        body.organization = this.formData.organization;
        body.website = this.formData.website;
        body.organizationType = this.formData.organizationType.join(';');
        body.CAGECode = this.formData.codeCAGE;
        body.NAICSCodes = this.formData.codesNAICS;
        body.numberOfEmployees = this.formData.numberOfEmployees;
        body.annualRevenues = this.formData.annualRevenue;
        body.DUNSNumber = this.formData.numberDUNS;
      }

      this.busy = true;
      axios
        .put('/api/user/', body)
        .then((response) => {
          //console.log('User Data Updated', response);
          /** Account updated successfully */
          this.errorMessage = '';
          this.successMessage = 'Account information updated successfully';
          this.refreshSession();
        })
        .catch((error) => {
          this.successMessage = '';
          /** Error creating account */
          this.errorMessage = error.response.data.message;
          console.error(error.response.data.message);
        })
        .finally(() => {
          this.busy = false;
        });
    },
  },
};
</script>
<style scoped>
.inner-header-bg {
  background-image: url('~@/assets/images/headers/myaccount.png');
  background-position: top center;
}
.form-control {
  height: 45px;
}
hr {
  margin-top: 2rem;
  padding: 0px;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
</style>
