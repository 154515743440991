<template>
  <div class="main-content">
    <!-- BradCrumb header Start-->
    <section class="inner-header-bg">
      <div class="container">
        <!-- Breadcrumb Start-->
        <div class="section-breadcrumb">
          <div class="container">
            <div class="row">
              <div class="col-md-12 justify-content-center align-self-center pb-2">
                <div class="breadcrumb-content">
                  <div class="title-section">
                    <h1 class="title text-center text-white">
                      Training
                    </h1>
                  </div>
                  <div class="breadcrumb-nav">
                    <ul class="breadcrumb-link text-center list-unstyled mb-0">
                      <li class="text-white">
                        <i class="mdi mdi-home theme-light-color pr-2"></i>
                        <a href="/#/">Home</a>
                      </li>
                      <li class="theme-light-color active">training</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Breadcrumb End -->
      </div>
    </section>
    <!-- BradCrumb header End -->
    <!-- Blog Grid Section Start -->
    <section class="section">
      <div class="container">
        <p>
          APEX Accelerators can use the following resources to serve as critical enablers to opening the defense
          industrial base to small, medium, large, and new entrant companies.
        </p>
        <!-- <div class="row">

          <div class="col-md-8">

          
          </div>
        </div> -->


        <p class="pb-2" v-if="displayedBlogItems.length === 0 && searchQuery">
          No items match the search criteria...
        </p>
        <p class="pb-2" v-if="displayedBlogItems.length === 0 && !searchQuery">
          Check back soon for documents..
        </p>
        <div class="row mt-4">
          <div class="col-md-8">
            <div class="blog-post-grid">
              <div class="row">
                <!-- Start Blog Item -->
                <div class="col-12 mb-3" v-for="item in displayedBlogItems" :key="item.id">
                  <!-- Start Blog Item -->
                  <div class="col-12 mb-3">
                    <div class="row blog-item border d-flex">
                     
                      <a v-if="item.type === 'powerpoint'" class="col-2 col-sm-3 col-xl-2 blog-post-image center-image-vertically" target="_blank">
                        <img src="https://assets.apexaccelerators.us/documents/powerpoint.png" alt="powerpoint" class="thumbnail-cover" />
                      </a>
                      <a v-else-if="item.type === 'webinar'" class="col-2 col-sm-3 col-xl-2 blog-post-image center-image-vertically" target="_blank">
                        <img src="https://assets.apexaccelerators.us/documents/webinar.png" alt="webinar" class="thumbnail-cover" />
                      </a>
                      <a v-else-if="item.type === 'handout'" class="col-2 col-sm-3 col-xl-2 blog-post-image center-image-vertically" target="_blank">
                        <img src="https://assets.apexaccelerators.us/documents/handout.png" alt="handout" class="thumbnail-cover" />
                      </a>
                      <a v-else-if="item.type === 'weblink'" class="col-2 col-sm-3 col-xl-2 blog-post-image center-image-vertically" target="_blank">
                        <img src="https://assets.apexaccelerators.us/documents/Web Link.png" alt="Web link" class="thumbnail-cover" />
                      </a>
                      <a v-else class="col-2 col-sm-3 col-xl-2 blog-post-image center-image-vertically" target="_blank">
                        <img :src="item.thumbnail" :alt="item.thumbnailDescription" class="thumbnail-cover" />
                      </a>
                      <div class="col-10 col-sm-9 col-xl-10 useful-link-info">
                        <a class="text-dark">
                          <!-- <div class="post-event d-inline-block align-items-center pt-2">
                            <a class="blog-info-meta text-dark">{{
                              item.category
                            }}
                            </a>
                          </div> -->
                          <h4 class="blog-post-title mt-1">
                            {{ item.title }}
                          </h4>
                          
                          <a v-if="item.type === 'webinar'" class="limit-lines-1" target="_blank" :href="item.url">
                            View Webinar
                          </a>
                          <a v-else-if="item.type === 'powerpoint'" class="limit-lines-1" target="_blank" :href="item.url">
                            View Document
                          </a>
                          <a v-else-if="item.type === 'weblink'" class="limit-lines-1" target="_blank" :href="item.url">
                            View Link
                          </a>
                          <a v-else class="limit-lines-1" target="_blank" :href="item.url">
                            View/Download
                          </a>
                        </a>
                      </div>
                    </div>
                  </div>

                </div>

                <!-- End Blog Item -->
              </div>
            </div>
            <div>
              <b-pagination id="blog-pagination" v-model="currentPage" :total-rows="totalRows" :per-page="perPage"
                align="center" @page-click="pageClicked"></b-pagination>
            </div>
          </div>
          <!-- <div class="col-4 mb-3">
            <aside class="sidebar sidebar-padding border">
              <h6 class="post-news-title search-title">Search</h6>
              <div class="pb-4">
                <b-form-input v-model="searchQuery" placeholder="Search All Training..."></b-form-input>
              </div>
              <div class="pb-2 mt-2 mb-3">
                <div class="blog-news-content">
                  <div class="blog-post-list">
                    <ul class="list-unstyled ml-0">
                      <li class="blog-item">
                        <h6 class="post-news-title">Categories</h6>
                      </li>
                      <li v-for="cat in trainingCategories" :key="cat.id">
                        <div class="blog-news-info"><a href="#" class="text-dark text-small"  v-on:click="setCategory(cat.category)"> {{ cat.category }}
                          
                          </a></div>
                      </li>

                    </ul>
                  </div>
                </div>
              </div>
            </aside>
          </div> -->

        </div>

      </div>
    </section>
    <!-- Blog Grid Section End -->
  </div>
</template>
<script>
import axios from 'axios';
import { BPagination, BFormInput } from 'bootstrap-vue';
export default {
  name: 'blog',
  components: {
    BPagination,
    BFormInput,
  },
  props: {
    query: String,
  },
  data() {
    return {
      searchQuery: this.query,
      items: [],
      itemCount: 0,
      currentPage: 1,
      perPage: 6,
      bucket: '',
      selectedFolder: '',
      trainingCategories: [],
      trainingCategoriesCount: 0
    };
  },
  computed: {
    blogItemsFromSearchQuery() {

      if (this.searchQuery) {
        return this.items.filter((item) => {
          return this.searchQuery
            .toLowerCase() === item.category.toLowerCase()
        });
      } else {
      
          return this.items;
        


      }
    },
    displayedBlogItems() {
      return this.blogItemsFromSearchQuery.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
      );
    },
    totalRows() {
      return this.blogItemsFromSearchQuery.length;
    },
  },
  mounted: async function () {
    this.init();
    this.getTrainingCategories();
    this.getItems(); // async
  },
  methods: {
    legal() {
      this.searchQuery = "";
      this.selectedFolder = "legal";
    },
    branding() {
      this.searchQuery = "";
      this.selectedFolder = "branding";
    },
    training() {
      this.searchQuery = "";
      this.selectedFolder = "training";
    },
    init() {
      /* The footer height no longer needs to be set */
      // this.initFooterHeight();
    },
    initFooterHeight() {
      var footerHeight = $('footer').outerHeight();
      $('.main-content').css('margin-bottom', footerHeight);
    },
    setCategory : function(cat){
      this.searchQuery =  cat;
    },
    async getItems() {
      axios
        .get('/api/resources/training')
        .then((response) => {
          this.items = response.data;
          this.itemCount = response.data.length;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async getTrainingCategories() {
      axios
        .get('/api/resources/training/categories')
        .then((response) => {
          this.trainingCategories = response.data;
          this.trainingCategoriesCount = response.data.length;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    pageClicked() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
  },
};
</script>
<style scoped>
#blog-pagination li {
  margin: 0px;
}

.useful-link-info {
  padding: 10px 5px 25px 0;
}

.thumbnail-cover {
  object-fit: cover;
  margin: auto;
  max-width: 100%;
  max-height: 100%;
  /* padding: 10px; */
}

.center-image-vertically {
  display: flex;
  justify-content: center;
}

.img-fluid {
  cursor: pointer;
}
</style>
<!-- /response.data.Items.filter(x => x.taggedCategory[0]?.text.toUpperCase() == "LEGAL");
  <div class="main-content"> -->