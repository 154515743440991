var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    ref: "my-modal",
    attrs: {
      "size": "md",
      "title": "Migrate Account",
      "ok-title": "Organization Member",
      "cancel-title": "Individual User",
      "ok-variant": "primary",
      "cancel-variant": "primary",
      "no-close-on-backdrop": "",
      "no-close-on-esc": "",
      "no-close-on-x": "",
      "hide-header-close": "",
      "busy": _vm.busy
    },
    on: {
      "ok": _vm.handleChooseOrganization,
      "cancel": _vm.handleChooseIndividual
    }
  }, [_c('p', {
    staticClass: "my-2"
  }, [_vm._v(" Do you want to continue as a Organization Member for "), _c('b', [_vm._v(_vm._s(_vm.organization))]), _vm._v(", or become an "), _c('b', [_vm._v("Individual User")]), _vm._v("? ")]), _vm.apiErrorMessage ? _c('b-alert', {
    attrs: {
      "variant": "danger",
      "show": ""
    }
  }, [_vm._v("Error migrating account. Please reach out to the Project Spectrum Support Team "), _c('a', {
    attrs: {
      "href": "mailto:support@projectspectrum.io"
    }
  }, [_vm._v("support@projectspectrum.io")]), _vm._v(".")]) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }