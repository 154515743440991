<template>
  <b-modal
    size="md"
    ref="my-modal"
    title="Welcome"
    ok-title="ok"
    ok-variant="primary"
    cancel-variant="primary"
    no-close-on-backdrop
    no-close-on-esc
    no-close-on-x
    hide-header-close
    :busy="busy"
  >
    <p class="my-2">
      We are happy to welcome you as the newest organization within Project Spectrum community! As an organization admin, you will be able to leverage the plethora of tools and services offered by Project Spectrum, including: Cyber Readiness Checks, advisory services, and assistance with every step of your customized cybersecurity journey. Additionally, you will have the ability to invite new members of your organization and manage them accordingly to gain insights into their individual training progress. Please feel free to take advantage of all of the resources at your disposal as well. We offer Cyber Readiness Checks for your organization, personalized cybersecurity training, virtual labs, informational webinars, Cyber Circuits Sessions (hear from industry experts), white papers, blogs, compliance tool reviews, and much more!
    </p>

  </b-modal>
</template>
<script>
export default {
  name: 'welcome-admin-modal',
};
</script>
<style scoped>
.modal-header .close {
  display: none !important;
}
</style>
