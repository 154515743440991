<template>
  <div class="main-content">
    <!-- BradCrumb header Start-->
    <section class="inner-header-bg-partner">
      <div class="container">
        <!-- Breadcrumb Start-->
        <div class="section-breadcrumb">
          <div class="container">
            <div class="row">
              <div
                class="col-md-12 justify-content-center align-self-center pb-2"
              >
                <div class="breadcrumb-content">
                  <div class="title-section">
                    <h1 class="title text-center text-white">
                      Partners
                    </h1>
                  </div>
                  <div class="breadcrumb-nav">
                    <ul class="breadcrumb-link text-center list-unstyled mb-0">
                      <li class="text-white">
                        <i class="mdi mdi-home theme-light-color pr-2"></i>
                        <a href="/#/">Home</a>
                      </li>
                      <li class="theme-light-color active">
                        Partners
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Breadcrumb End -->
      </div>
    </section>
    <!-- BradCrumb header End -->
    <!-- Body Area Start-->
    <section class="section">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 py-4">
          </div>
		   <div class="col-12 py-4">
            <div class="section-title">
              <h2>MEP</h2>
            </div>
			<div class="row">
			<div class="col-8">
			 <blockquote class="blockquote">
                <p> 
					The Manufacturing Extension Partnership (MEP), based at the National Institute of Standards and Technology (NIST), is a public-private partnership with Centers in all 50 states and Puerto Rico dedicated to serving small and medium-sized manufacturers. MEP Centers and partners have developed various services to enable manufacturers to identify opportunities that will accelerate their growth and improve competitiveness in the global marketplace. The program provides the federal government funding for the MEP National Network™ 
					which was designed to advance U.S. manufacturing.
				</p>
              </blockquote>
			 <p style="padding-top:0px">&#9658; <a href="https://nist.gov/mep" target="_blank"><strong> LEARN MORE </strong> </a> </p>
			</div>
			<div class="col-4">
				<img src="assets/images/MEP.png" alt="" class="img-fluid w-200">
			</div>
			</div>
		  </div>
		   <hr class="col-sm-12" />
		     <!-- /* CMMC Level 1 Compliance Journey for Small Business */ -->
          <div class="col-12 py-4">
            <div class="section-title">
              <h2>Mentor-Protégé Program </h2>
            </div>
			<div class="row">
			<div class="col-8">
			 <blockquote class="blockquote">
                <p>
					The Mentor-Protégé Program helps eligible small businesses (protégés) gain capacity and win government contracts through partnerships with more experienced companies (mentors). DoD’s MPP is the oldest continuously operating federal MPP in existence, which has assisted approximately 200 small businesses fill unique niches and become part of the military’s supply chain in the past five years. The program helps protégés expand their footprint in the defense industrial base. 
				</p>
              </blockquote>
			 <p style="padding-top:0px">&#9658; <a href="https://business.defense.gov/Programs/Mentor-Protege-Program/" target="_blank"><strong> LEARN MORE </strong> </a> </p>
			</div>
			<div class="col-4">
				<img src="assets/images/MPP.png" alt="" class="img-fluid w-100">
			</div>
			</div>
		  </div>
		 <hr class="col-sm-12" />
		      <div class="col-12 py-4">
            <div class="section-title">
              <h2>Project Spectrum </h2>
            </div>
			<div class="row">
			<div class="col-8">
			 <blockquote class="blockquote">
                <p>
					Project Spectrum is a comprehensive, proven, and cost-effective digital platform that provides cybersecurity information, tools, resources, and training to organizations including small businesses in the defense industrial base. The program mission is to improve cybersecurity readiness, resiliency, and compliance for small and medium-sized businesses and the federal manufacturing supply chain.
				</p>
              </blockquote>
			 <p style="padding-top:0px">&#9658; <a href="https://www.projectspectrum.io/#/" target="_blank"><strong> LEARN MORE </strong> </a> </p>
			</div>
			<div class="col-4">
				<img src="assets/images/logo/logo-dark-small.png" alt="" class="img-fluid w-50">
			</div>
			</div>
		  </div>
		  <hr class="col-sm-12" />
          <div class="col-12 py-4">
            <div class="section-title">
              <h2>SBA</h2>
            </div>
			<div class="row">
			<div class="col-8">
			 <blockquote class="blockquote">
                <p>
					The U.S. Small Business Administration (SBA) is a cabinet-level federal agency that helps Americans start, grow, and build resilient businesses. SBA is dedicated to small business and provides counseling, capital, and contracting expertise. Since it was founded in 1953, SBA has delivered millions of loans, contracts, counseling sessions, and other forms of assistance to innumerable small businesses as the nation’s go-to resource and voice for small businesses. 	
				</p>
              </blockquote>
			 <p style="padding-top:0px">&#9658; <a href="https://www.sba.gov" target="_blank"><strong> LEARN MORE </strong> </a> </p>
			</div>
			<div class="col-4">
				<img src="assets/images/SBA.png" alt="" class="img-fluid w-100">
			</div>
			</div>
		  </div>
		   <hr class="col-sm-12" />
        </div>
      </div>
    </section>
    <!-- Body Area End-->
  </div>
</template>
<script>
export default {
  name: 'placeholder',
  mounted() {
    this.init();
  },
  methods: {
    init() {
      /* The footer height no longer needs to be set */
      // this.initFooterHeight();
    },
    initFooterHeight() {
      var footerHeight = $('footer').outerHeight();
      $('.main-content').css('margin-bottom', footerHeight);
    },
  },
};
</script>
<style scoped>
.singup-button-desktop {
  background-color: #096cce;
  border-radius: 5px;
  border: none;
  color: white;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin: -5px;
  cursor: pointer;
}
.sba_tooltip {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: #008cba;
}
.centered-text {
  color: white;
  font-size: min(1.75vw, 1.5em);
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  padding: 0.25em;
  line-height: min(2.0vw, 1.25em);;
}
.sba_tooltip:hover {
  opacity: 1;
}
.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-padding {
  padding-right: 0;
  padding-left: 0;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

@media only screen and (min-width: 992px) {
  .arrow-height {
    height: 8em;
  }
}

@media only screen and (min-width: 576px) and (max-width: 991px) {
  .arrow-height {
    height: 4em;
  }
}

@media only screen and (max-width: 575px) {
  .arrow-height {
    height: 3em;
  }
}
blockquote {
    border-left: 2px solid #f2b636;
	padding: 0px 0 0px 8px;
}
hr {
  margin-top: 0.01em !important;
  margin-bottom: 0.01em !important;
  border-width: 1px;
}
.padding
{
padding: 0px 30px 0px 0px;
}


</style>
