<template>
  <div class="main-content">
    <!-- BradCrumb header Start-->
    <section class="inner-header-bg-techHub">
      <div class="container">
        <!-- Breadcrumb Start-->
        <div class="section-breadcrumb">
          <div class="container">
            <div class="row">
              <div
                class="col-md-12 justify-content-center align-self-center pb-2"
              >
                <div class="breadcrumb-content">
                  <div class="title-section">
                    <h1 class="title text-center text-white">Latest News</h1>
                  </div>
                  <div class="breadcrumb-nav">
                    <ul class="breadcrumb-link text-center list-unstyled mb-0">
                      <li class="text-white">
                        <i class="mdi mdi-home theme-light-color pr-2"></i>
                        <a href="/#/">Home</a>
                      </li>
                      <li class="theme-light-color active">Latest News</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Breadcrumb End -->
      </div>
    </section>
    <!-- BradCrumb header End -->
    <!-- Blog Grid Section Start -->
    <section class="section">
      <div class="container">
        <p>
          What’s in the news? Project Spectrum keeps an eye on the latest
          cybersecurity tech trends, emerging technologies, compliance
          standards, cyber threats, and much more! Check out the latest news
          from around the web.
        </p>
        <div class="pb-4">
          <b-form-input
            v-model="searchQuery"
            placeholder="Search News Articles..."
          ></b-form-input>
        </div>
        <p class="pb-2" v-if="displayedNewsItems.length === 0 && searchQuery">
          No items match the search criteria...
        </p>
        <p class="pb-2" v-if="displayedNewsItems.length === 0 && !searchQuery">
          Check back soon for news posts...
        </p>
        <div class="blog-post-grid">
          <div class="row">
            <!-- Start Blog Item -->
            <div
              class="col-md-6 col-lg-4 mb-5"
              v-for="item in displayedNewsItems"
              :key="item.id"
            >
              <div class="blog-item border h-100">
                <router-link
                  class="blog-post-image"
                  :to="{ name: 'newsdetail', query: { id: item.id } }"
                >
                  <img
                    :src="item.article.largeImageURL"
                    :alt="item.photoDescription"
                    class="cover"
                  />
                </router-link>
                <div class="blog-post-info">
                  <router-link
                    class="text-dark"
                    :to="{ name: 'newsdetail', query: { id: item.id } }"
                  >
                    <h4 class="blog-post-title">
                      {{ item.article.title }}
                    </h4>
                    <p class="limit-lines-3">{{ item.article.headLine }}</p>
                    <div class="post-event-bottom d-flex align-items-center">
                      <a class="blog-info-meta text-dark">{{
                        getFormattedDate(item.article.date)
                      }}</a>
                      <a class="blog-category text-dark">News</a>
                    </div>
                  </router-link>
                  <router-link
                    class="blog-read-more"
                    :to="{ name: 'newsdetail', query: { id: item.id } }"
                  >
                    <i class="mdi mdi-arrow-right"></i>
                  </router-link>
                </div>
              </div>
            </div>
            <!-- End Blog Item -->
          </div>
        </div>
        <div>
          <b-pagination
            id="news-pagination"
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="center"
            @page-click="pageClicked"
          ></b-pagination>
        </div>
      </div>
    </section>
    <!-- Blog Grid Section End -->
  </div>
</template>
<script>
import axios from 'axios';
import { BPagination, BFormInput } from 'bootstrap-vue';
export default {
  name: 'news',
  components: {
    BPagination,
    BFormInput,
  },
  props: {
    query: String,
  },
  data() {
    return {
      searchQuery: this.query,
      newsItems: [],
      newsItemCount: 0,
      currentPage: 1,
      perPage: 6,
    };
  },
  computed: {
    newsItemsFromSearchQuery() {
      if (this.searchQuery) {
        return this.newsItems.filter((item) => {
          return this.searchQuery
            .toLowerCase()
            .split(' ')
            .every(
              (v) =>
                item.article.title.toLowerCase().includes(v) ||
                item.article.headLine.toLowerCase().includes(v)
            );
        });
      } else {
        return this.newsItems;
      }
    },
    displayedNewsItems() {
      return this.newsItemsFromSearchQuery.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
      );
    },
    totalRows() {
      return this.newsItemsFromSearchQuery.length;
    },
  },
  mounted: async function () {
    this.init();
    this.getNewsPosts(); // async
  },
  methods: {
    init() {
      /* The footer height no longer needs to be set */
      // this.initFooterHeight();
    },
    initFooterHeight() {
      var footerHeight = $('footer').outerHeight();
      $('.main-content').css('margin-bottom', footerHeight);
    },
    async getNewsPosts() {
      /*  API call to server: GET /api/resources/news  */
      axios
        .get('/api/resources/news')
        .then((response) => {
          this.newsItems = response.data.Items;
          this.newsItemCount = response.data.Count;
          //console.log(this.newsItems);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    pageClicked() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
  },
};
</script>
<style scoped>
#news-pagination li {
  margin: 0px;
}

.cover {
  object-fit: cover;
  height: 225px;
  width: 100%;
}
</style>
