<template>
    <div class="main-content">
        <section class="inner-header-bg">
      <div class="container">
        <!-- Breadcrumb Start-->
        <div class="section-breadcrumb">
          <div class="container">
            <div class="row">
              <div class="col-md-12 justify-content-center align-self-center pb-2">
                <div class="breadcrumb-content">
                  <div class="title-section">
                    <h1 class="title text-center text-white">
                      Leadership
                    </h1>
                  </div>
                  <div class="breadcrumb-nav">
                    <ul class="breadcrumb-link text-center list-unstyled mb-0">
                      <li class="text-white">
                        <i class="mdi mdi-home theme-light-color pr-2"></i>
                        <a href="/#/">Home</a>
                      </li>
                      <li class="theme-light-color active">Leadership</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Breadcrumb End -->
      </div>
      
    </section>
    <section class="about-area section" id="aboutus">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="about-title section-title">
                            <span>ABOUT US</span>
                            <h2>APEX Accelerators</h2>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="about-text pl-4">
							<p>We provide critical assistance to new and existing businesses interested in government contracting to enhance the defense industrial base.</p>
                        </div>
                    </div>
                </div>
               
            </div>
        </section>
        <!-- Start Services Area -->
        <section class="section parallax-bg" id="services">
            <div class="container">
                <div class="section-title text-center z-index9">
                    <!-- <span>OUR SERVICES</span> -->
                    <h2 class="text-white">Our Leadership</h2>
                </div>
                <div class="service-boxes-area">
                    <div class="row">
                        <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-2">
                            <a href='#leader1' style="color:#212529">
                                <div class="single-service-box mt-30 p-4 bg-white wow fadeIn card h-100" data-wow-delay="0.2s" data-wow-duration="1s">
                                <div class="about-inner-img mt-lg-0 mt-4">
                                    <img src="assets/images/leadership/Farooq_A_Mitha.jpg" alt="" class="img-fluid w-100">
                                </div>
                                <h3 class="mt-4">Farooq A Mitha</h3>
                                <p>Director 
                                    <br/>Office of Small Business Programs
                                    <br/>U.S. Department of Defense
                                </p>
                            </div>
                            </a>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <a href='#leader2' style="color:#212529">
                                <div class="single-service-box mt-30 p-4 bg-white wow fadeIn card h-100" data-wow-delay="0.4s" data-wow-duration="1s">
                                <div class="about-inner-img mt-lg-0 mt-4">
                                    <img src="assets/images/leadership/Khalil_R_Mack.png" alt="" class="img-fluid w-100">
                                </div>
                                <h3 class="mt-4">Khalil R. Mack</h3>
                                <p>Director 
                                    <br/> APEX Accelerators
                                    <br/> U.S. Department of Defense
                                </p>
                            </div> </a>
                        </div>
                      
                    </div>
                </div>
            </div>
        </section>
        <section class="about-area section border-bottom" id="leader1">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-4 col-md-12"  style="min-height: 760px">
                        <div class="about-inner-img mt-lg-0 mt-4">
                                    <img src="assets/images/leadership/Farooq_A_Mitha.jpg" alt="" class="img-fluid w-100">
                                </div>
                        <div class="section-title">
                            
                            <h3 class="mt-4">Farooq A Mitha</h3>
                                <p>Director 
                                    <br/>Office of Small Business Programs
                                    <br/>U.S. Department of Defense
                                </p>
                        </div>
                    </div>
                    <div class="col-lg-8 col-md-12" style="min-height: 600px">
                        <div class="about-text pl-4">
                            <p>
                                Mr. Mitha currently serves in the Biden administration as the Director of the Department of Defense (DoD) Office of Small Business Programs (OSBP). As Director, Mr. Mitha oversees more than $140 billion of annual awards to small business. In addition, he assists the Joint Chiefs of Staff, Secretaries of Military Departments, Under Secretaries of Defense, Directors of Defense Agencies and Major Commands in including small business planning into the readiness of the Department. These efforts aim to modernize and restore the nation’s industrial commons through focusing on advanced manufacturing, applied research, and innovative programs that align small business capabilities with the DoD’s current and future needs. <br/><br/>
                                Prior to his appointment Mr. Mitha served on the Biden-Harris transition team as a member of the DoD Agency Review Team. Mr. Mitha also served in the Obama administration as the Senior Advisor to the Director of the DoD Office of Small Business Programs and the Deputy Assistant Secretary of Defense for Manufacturing and Industrial Base Policy. <br/><br/>
                                Mr. Mitha served as the President and CEO of Imbue Group, Inc., which provided the subject matter expertise to DoD’s industrial base and innovation programs on small business programs, supply chain risk, defense procurement policy, utilization of other transactional authorities, engaging small businesses within the national security technology and industrial base, and advanced manufacturing. <br/><br/>
                                In his previous time at the Department of Defense, Mr. Mitha led several programs and initiatives that have increased the visibility of small business within DoD, modernized programs to infuse small business capability into major defense acquisition programs and increased effective dialogue with industry. <br/><br/>
                                Mr. Mitha has a strong background in international policy and national security. He is fluent in Arabic and published opinion-editorials offering commentary on issues concerning the Middle East and the American political system. In 2009, Mr. Mitha was a Fulbright Fellow in Amman, Jordan where he coordinated a Track II diplomacy conference and conducted research on economic reform in the Middle East. Mr. Mitha has also lectured at domestic and international universities on economic reform in the Middle East, U.S. policy in the Middle East, and civic engagement. <br/>
                                Mr. Mitha obtained his law degree from the University of Florida Levin College of Law and holds a B.S. degree in Interdisciplinary Health Sciences from the University of South Florida

                            </p>
                        </div>
                    </div>
                </div>
                
            </div>
        </section>
        <section class="about-area section border-bottom" id="leader2">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-4 col-md-12">
                        <div class="about-inner-img mt-lg-0 mt-4">
                                    <img src="assets/images/leadership/Khalil_R_Mack.png" alt="" class="img-fluid w-100">
                                </div>
                        <div class="section-title">
                            
                            <h3 class="mt-4">Khalil R. Mack</h3>
                                <p>Director 
                                    <br/>APEX Accelerators
                                    <br/>U.S. Department of Defense
                                </p>
                        </div>
                    </div>
                    <div class="col-lg-8 col-md-12" style="min-height: 600px">
                        <div class="about-text pl-4">
                            <p>
                                Mr. Mack currently serves as Deputy Director of the Office of Small Business Programs (OSBP) and Director of APEX Accelerators. As part of the OSBP leadership team, he oversees Industry Engagement, Strategic Communications, Policy, Industrial Cybersecurity, Small Business Risk, and the APEX Accelerators program.
                            </p>
                            <p>
                                With 97 offices across 49 states and territories, APEX Accelerators provide a critical front door for business and industry to help to expand suppliers to the federal, state, and local government marketplaces and provide opportunities for companies new to government contracting to acquire the skills and know-how to compete.
                            </p>
                            <p>
                                The work Accelerators do with businesses, in concert with their partners, strengthens the defense industrial base by accelerating innovation, fostering ingenuity, and establishing resilient and diverse supply chains.  The result is the creation of a domestic industrial base that can deliver preeminent solutions to the military and other government users.
                            </p>
                            <p>
                                Mr. Mack previously held the position of Associate Director of Policy, where he was the principal advisor to the OSBP Director on small business industrial base related policy, regulation, and legislation. His experience also includes serving as Branch Chief for the Rapid Prototyping Acquisition Branch at the Washington Headquarters Services Acquisition Directorate, where he planned and executed non-FAR based acquisitions, including Other Transactions (OTs), and in various contracting roles for the Army Intelligence and Security Command (INSCOM). 
                            </p>
                            <p>
                                Prior to becoming a federal civilian, Mr. Mack was a consultant, specializing in acquisition and finance, for several federal agencies including the Department of Homeland Security and the Federal Bureau of Investigation.
                            </p>
                            <p>
                                Mr. Mack earned a Bachelor of Science in Business Management from Hampton University, and a Master of Business Administration from the George Washington University. He is also a graduate of the Procurement and Contracts Management Certificate program at the University of Virginia.  
                            </p>
                        </div>
                    </div>
                </div>
                
            </div>
        </section>
       
        <!-- End Services Area -->
     
    </div>
</template>
<script>
    //WOW JS
    import { WOW } from 'wowjs';

    // import Swiper styles
    import '../../assets/css/swiper.min.css';
    // core version + navigation, pagination modules:
    import Swiper, { Navigation, Pagination } from 'swiper';
    // configure Swiper to use modules
    Swiper.use([Navigation, Pagination]);
    //ISOTOP JS
    import '../../assets/js/jquery.isotope.min.js';
    import * as ScrollMagic from "scrollmagic"; // Or use scrollmagic-with-ssr to avoid server rendering problems
    import { TweenMax, TimelineMax } from "gsap"; // Also works with TweenLite and TimelineLite
    import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
    ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);

    //MAGNIFIC POPUP JS
    import 'magnific-popup/dist/jquery.magnific-popup.min.js';
    import 'magnific-popup/dist/magnific-popup.css';
    export default {
        name: 'index', mounted() {
            this.init();
        },
        methods: {
            init() {
                this.initMainSlider();
                this.initBackgroundImage();
                this.initWowAnimation();
                this.initAccordian();
                this.windowScroll();
                this.initTestimonialSlider();
                // this.initFooterHeight();
                this.initIsotop();
                this.initMagnificPopup();
                this.initScaleImage();
            },
            /*----MAIN ANIMATION-----*/
            initMainSlider() {
                var swiperOptions = {
                    loop: true,
                    speed: 1000,
                    grabCursor: true,
                    watchSlidesProgress: true,
                    mousewheelControl: true,
                    keyboardControl: true,
                    navigation: {
                        nextEl: ".swiper-button-next",
                        prevEl: ".swiper-button-prev"
                    }
                };
                var swiper = new Swiper(".main-slider", swiperOptions);
            },
            /*----BACKGROUND IMAGE-----*/
            initBackgroundImage() {
                $(".bg-image").css('background', function () {
                    var bg = ('url(' + $(this).data("image-src") + ') no-repeat center center');
                    return bg;
                });
            },
            initFooterHeight() {
                var footerHeight = $("footer").outerHeight();
                $('.main-content').css('margin-bottom', footerHeight);
            },
            /*----WOW ANIMATION-----*/
            initWowAnimation() {
                var length = $('.wow').length;
                if (length >= 1) {
                    const wow = new WOW({
                        boxClass: 'wow',
                        animateClass: 'animated',
                        offset: 0,
                    });
                    wow.init();
                }
            },
            /*----ONSCROLL JS-----*/
            windowScroll() {
                var self = this;
                $(window).on("scroll", function () {
                    //self.initCounter();
                });
            },
            /*----ACCORDIAN-----*/
            initAccordian() {
                $(".question-card").on('click', function () {
                    if ($(this).hasClass('active')) {
                        $('.question-card').removeClass('active');
                        $(this).removeClass('active')
                    } else {
                        $('.question-card').removeClass('active');
                        $(this).addClass('active')
                    }
                });
            },
            /*----TESTIMONIAL SLIDER-----*/
            initTestimonialSlider() {
                if ($('.swiper-container').length > 0) {
                    var swiper = new Swiper('.client-container', {
                        slidesPerView: 1,
                        draggable: true,
                        spaceBetween: 10,
                        // init: false,
                        pagination: {
                            el: '.swiper-pagination',
                            clickable: true,
                        },
                        breakpoints: {
                            640: {
                                slidesPerView: 1,
                                spaceBetween: 20,
                            },
                            768: {
                                slidesPerView: 2,
                                spaceBetween: 40,
                            },
                            1024: {
                                slidesPerView: 3,
                                spaceBetween: 50,
                            },
                        }
                    });
                }
            },
            /*----ISOTOP JS-----*/
            initIsotop() {
                var $container = $('.portfolio-items');
                $container.imagesLoaded(function () {
                    $container.isotope({
                        filter: '*',
                        animationOptions: {
                            duration: 750,
                            easing: 'linear',
                            queue: false
                        }
                    });
                });

                $('.portfolio-filter li a').click(function () {
                    $('.portfolio-filter .current').removeClass('current');
                    $(this).addClass('current');
                    var selector = $(this).attr('data-filter');
                    $container.isotope({
                        filter: selector,
                        animationOptions: {
                            duration: 750,
                            easing: 'linear',
                            queue: false
                        }
                    });
                    return false;
                });
            },
            /*----MAGNIFIC POPUP JS-----*/
            initMagnificPopup() {
                if (('.portfolio-items').length > 0) {
                    $('.portfolio-items').each(function () {
                        $(this).magnificPopup({
                            delegate: '.js-zoom-gallery',
                            type: 'image',
                            gallery: {
                                enabled: true
                            }
                        });
                    });
                }
            },
            initScaleImage() {
                var controller = new ScrollMagic.Controller();
                var slides = $('.scale-image');
                for (var i = 0; i < slides.length; i++) {
                    var image = $('img', slides[i]);
                    var tween = TweenMax.to(image, 1, { scale: 1.2 });
                    var scene = new ScrollMagic
                        .Scene({
                            triggerElement: slides[i],
                            duration: "200%",
                            triggerHook: 'onEnter'
                        })
                        .setTween(tween)
                        .addTo(controller);
                }
            }
        }
    }
</script>