var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "main-content"
  }, [_vm._m(0), _c('section', {
    staticClass: "section container"
  }, [_vm.isLoggedIn ? _c('p', [_vm._v(" A primary goal of the Project Spectrum Team is to provide comprehensive training courses for our partners. To that end, our training curriculum is a living and ever-evolving platform that keeps pace with policy, technology, and the tradecraft of offensive cyber actors. We aim to increase the cyber knowledge base of all users to raise the overall level of cybersecurity hygiene of every partner. Login to our training platform to view our current offerings and be sure to check back often as we regularly develop new material to help us remain one-step ahead of our adversaries in cyberspace. ")]) : _vm._e(), !_vm.isLoggedIn ? _c('div', {
    staticClass: "my-2 text-left"
  }, [_c('p', [_vm._v("A primary goal of the Project Spectrum Team is to provide state-of-the-art training courses for our partners. To that end, our training curriculum is a living and ever-evolving platform that keeps pace with policy, technology, and the tradecraft of offensive cyber actors. We aim to increase the cyber knowledge base of all users to raise the overall level of cybersecurity hygiene of every partner. "), _c('router-link', {
    attrs: {
      "to": {
        name: 'login'
      }
    }
  }, [_c('b', [_vm._v("Login")])]), _vm._v(" or "), _c('router-link', {
    attrs: {
      "to": {
        name: 'signup'
      }
    }
  }, [_c('b', [_vm._v("Register ")])]), _vm._v(" to our training platform to view our current offerings and be sure to check back often as we regularly develop new material to help us remain one-step ahead of our adversaries in cyberspace. ")], 1), _c('br'), _c('div', {
    staticClass: "login-register-btn-container mt-4"
  }, [_c('router-link', {
    attrs: {
      "to": {
        name: 'login'
      }
    }
  }, [_c('b-button', {
    staticClass: "btn theme-btn rounded login-reg-btn"
  }, [_vm._v("Login")])], 1), _c('router-link', {
    attrs: {
      "to": {
        name: 'signup'
      }
    }
  }, [_c('b-button', {
    staticClass: "btn theme-btn rounded login-reg-btn",
    attrs: {
      "variant": "none"
    }
  }, [_vm._v("Register")])], 1)], 1)]) : _vm._e(), _vm.isLoggedIn ? _c('div', [_c('div', [_c('p', [_vm._v(" To access Project Spectrum’s online courses, you must log into the Encite learning management platform. Because you are a registered Project Spectrum user, we have automatically generated your login credentials, which you can see below. ")]), _vm._m(1), _vm._m(2), _c('div', {
    staticClass: "p-3 px-4 my-2 mb-4 border border-secondary rounded"
  }, [_c('div', [_c('b', [_vm._v("Username")]), _vm._v(" : " + _vm._s(_vm.username))]), _c('div', [_c('b', [_vm._v("Password")]), _vm._v(" : " + _vm._s(_vm.showPassword ? _vm.password : '*********************************') + " ")]), _c('b-form-checkbox', {
    model: {
      value: _vm.showPassword,
      callback: function ($$v) {
        _vm.showPassword = $$v;
      },
      expression: "showPassword"
    }
  }, [_vm._v(" Show Password ")]), _c('b-alert', {
    staticClass: "mt-2 mb-1",
    attrs: {
      "show": _vm.enciteCredentialError,
      "variant": "danger"
    }
  }, [_vm._v("Issue retrieving Encite credentials. Contact us at "), _c('a', {
    attrs: {
      "href": "mailto:outreach@projectspectrum.io"
    }
  }, [_vm._v("outreach@projectspectrum.io")]), _vm._v(" for assistance. ")])], 1)]), _c('div', {
    staticClass: "pb-4"
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Search Courses..."
    },
    model: {
      value: _vm.searchQuery,
      callback: function ($$v) {
        _vm.searchQuery = $$v;
      },
      expression: "searchQuery"
    }
  })], 1), _vm.displayedItems.length === 0 && _vm.searchQuery ? _c('p', {
    staticClass: "pb-2"
  }, [_vm._v(" No items match the search criteria... ")]) : _vm._e(), _vm.displayedItems.length === 0 && !_vm.searchQuery ? _c('p', {
    staticClass: "pb-2"
  }, [_vm._v(" Check back soon for online courses... ")]) : _vm._e(), _c('div', {
    staticClass: "blog-post-grid"
  }, [_c('div', {
    staticClass: "row"
  }, _vm._l(_vm.displayedItems, function (item) {
    return _c('div', {
      key: item.id,
      staticClass: "col-md-6 col-lg-4 mb-5"
    }, [_c('div', {
      staticClass: "blog-item border h-100"
    }, [_c('a', {
      staticClass: "blog-post-image",
      attrs: {
        "href": item.link
      }
    }, [_c('img', {
      staticClass: "cover",
      attrs: {
        "src": item.image
      }
    })]), _c('div', {
      staticClass: "blog-post-info"
    }, [_c('a', {
      staticClass: "text-dark",
      attrs: {
        "href": item.link
      }
    }, [_c('h4', {
      staticClass: "blog-post-title"
    }, [_vm._v(" " + _vm._s(item.name) + " ")]), _c('p', {
      staticClass: "limit-lines-course"
    }, [_vm._v(_vm._s(item.description))])]), _c('a', {
      staticClass: "blog-read-more",
      attrs: {
        "href": item.link
      }
    }, [_c('i', {
      staticClass: "mdi mdi-link-variant"
    })])])])]);
  }), 0)]), _c('div', [_c('b-pagination', {
    attrs: {
      "id": "blog-pagination",
      "total-rows": _vm.totalRows,
      "per-page": _vm.perPage,
      "align": "center"
    },
    on: {
      "page-click": _vm.pageClicked
    },
    model: {
      value: _vm.currentPage,
      callback: function ($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)]) : _vm._e()])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "inner-header-bg"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "section-breadcrumb"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12 justify-content-center align-self-center pb-2"
  }, [_c('div', {
    staticClass: "breadcrumb-content"
  }, [_c('div', {
    staticClass: "title-section"
  }, [_c('h1', {
    staticClass: "title text-center text-white"
  }, [_vm._v("Online Courses")])]), _c('div', {
    staticClass: "breadcrumb-nav"
  }, [_c('ul', {
    staticClass: "breadcrumb-link text-center list-unstyled mb-0"
  }, [_c('li', {
    staticClass: "text-white"
  }, [_c('i', {
    staticClass: "mdi mdi-home theme-light-color pr-2"
  }), _c('a', {
    attrs: {
      "href": "/#/"
    }
  }, [_vm._v("Home")])]), _c('li', {
    staticClass: "theme-light-color active"
  }, [_vm._v("Online Courses")])])])])])])])])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_vm._v(" Clicking the course link will take you to the Encite login screen. "), _c('b', [_vm._v(" You will need to copy/paste the system-generated password and log into the learning management platform.")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_vm._v(" If you have issues or concerns, please contact us at "), _c('a', {
    attrs: {
      "href": "mailto:outreach@projectspectrum.io"
    }
  }, [_vm._v("outreach@projectspectrum.io")]), _vm._v(" for assistance. ")]);

}]

export { render, staticRenderFns }