<template>
  <b-modal
    size="lg"
    ref="my-modal"
    :title="title"
    @ok="handleAccept"
    @cancel="handleDecline"
    ok-title="Accept"
    cancel-title="Decline"
    ok-variant="success"
    cancel-variant="danger"
    no-close-on-backdrop
    no-close-on-esc
    :busy="busy"
  >
    <p class="my-2">You have Join Requests from the following users:</p>
    <li
      v-for="item in orgs"
      :key="item"
      class="font-weight-bold px-2 py-0"
    >
      {{ item }}
    </li>
    <p class="my-2">
      Do you want to accept below user to be part of your Organization?
       <br/><b>{{ currentJoinRequest?.requesterName }} ({{currentJoinRequest?.requesterEmail}})</b
      >
    </p>
  </b-modal>
</template>
<script>
import axios from 'axios';
import { isLoggedIn, handleApiError, getUserEmail, setUser, storeAuthTokens} from '../utils/authentication';
export default {
  name: 'join-request-modal',
  data() {
    return {
      joinRequests: [],
      busy: false,
    };
  },
  mounted() {
    this.getJoinRequests();
  },
  computed: {
    title() {
      return this.joinRequests.length === 1
        ? 'Pending Join Request'
        : `${this.joinRequests.length} Pending Join Requests`;
    },
    orgs() {
      const orgs = [];
      for (let i = 0; i < this.joinRequests.length; i++) {
        orgs.push(this.joinRequests[i].requesterName + " (" + this.joinRequests[i].requesterEmail + ")");
      }
      return orgs;
    },
    currentJoinRequest() {
      return this.joinRequests[0];
    },
  },
  methods: {
    show() {
      this.$refs['my-modal'].show();
    },
    hide() {
      this.$refs['my-modal'].hide();
    },
    getJoinRequests() {
      this.busy = true;
      axios
        .get(`/api/user/`)
        .then((response) => {
          
          /** Set User in Store */
          setUser(response.data);
          if (response.data.joinRequests.length > 0) {
            /*console.log(
              'Pending Join Requests',
              response.data.joinRequests.length
            );*/
            this.joinRequests = response.data.joinRequests;
            this.show();
          }else{
            this.hide();
          }
        })
        .catch((error) => {
          handleApiError(error.response, this.$router);
        })
        .finally(() => {
          this.busy = false;
      });
    },
    async handleAccept(bvModalEvt) {
      // Prevent Modal from closing on button press
      bvModalEvt.preventDefault();
      // Accept current invitaiton, and decline all others
      await this.acceptJoinRequest(this.joinRequests[0]);
      this.getJoinRequests();
    },
    async handleDecline(bvModalEvt) {
      bvModalEvt.preventDefault();
      // Decline current joinRequest, and ask about next invitaiton
      await this.declineJoinRequest(this.currentJoinRequest);
      this.getJoinRequests();
    },
    async acceptJoinRequest(joinRequest) {
      /*  API call to server: POST /api/organization/joinRequest/accept */
      //console.log('Accepting JoinRequest', joinRequest);
      axios
        .post(`/api/organization/joinRequest/accept`, {
          joinRequestID: joinRequest.joinRequestID,
          accepted: true,
        })
        .then((response) => {
          //console.log('Accept JoinRequest Response', response);
          this.refreshSession()
        })
        .catch((error) => {
          handleApiError(error, this.$router);
        });
    },
    async declineJoinRequest(joinRequest) {
      /*  API call to server: POST /api/organization/joinRequest/accept */
      //console.log('Declining JoinRequest', joinRequest);
      axios
        .post(`/api/organization/joinRequest/accept`, {
          joinRequestID: joinRequest.joinRequestID,
          accepted: false,
        })
        .then((response) => {
          console.log('Decline JoinRequest Response', response);
        })
        .catch((error) => {
          handleApiError(error, this.$router);
        });
    },
    getAccountData(){
       axios.get(`/api/user/`).then((response) => {
              setUser(response.data);
            });
    },
    refreshSession() {
        this.busy = true;
        axios
          .post('/api/user/session/refresh', {
            email: getUserEmail(),
          })
          .then((response) => {
            //console.log('Session Refreshed', response);
            storeAuthTokens(response.data);
            this.getAccountData();
          })
          .catch((error) => {
            handleApiError(error, this.$router);
            this.error = error.response.data.message;
          })
          .finally(() => {
            this.busy = false;
          });
      },
  },
};
</script>
<style scoped></style>
