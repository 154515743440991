<template>
  <!-- Start Navbar -->
  <nav class="navbar navbar-expand-lg fixed-top custom-nav sticky">
    <div class="container py-1">
      <!-- LOGO -->
      <router-link class="navbar-brand brand-logo mr-4" to="/">
        <img
          src="../assets/images/logo/apex-logo-light.png"
          class="img-fluid logo-small-screen"
          alt=""
        />
        <img
          src="../assets/images/logo/apex_logo_light_1.png"
          class="img-fluid logo-light"
          alt=""
        />
        <img
          src="../assets/images/logo/apex-logo-light.png"
          class="img-fluid logo-dark"
          alt=""
        />
      </router-link>

      <!-- Sidebar Nav for Mobile -->
      <b-sidebar
        id="sidebar-backdrop"
        sidebar-class="sidebar-background"
        bg-variant="dark"
        text-variant="light"
        backdrop
        shadow
      >
        <div class="px-3 py-2">
          <div>
            <ul class="navbar-nav navbar-center" id="mySidenav">
              <!-- Home -->
              <li class="nav-item">
                <router-link class="nav-link" to="/">Home</router-link>
              </li>
              <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" data-toggle="dropdown"
                  >About Us</a
                >
                <ul class="nav-bar submenu dropdown-menu list-unstyled p-0">
                  <li class="nav-item">
                    <router-link class="nav-link" to="/leadership"
                      >Leadership
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link class="nav-link" to="/about-us"
                      >What We Do
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link class="nav-link" to="/partners"
                      >Partners
                    </router-link>
                  </li>
                </ul>
              </li>
              <!-- <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" data-toggle="dropdown"
                  >Resources</a
                >
                <ul class="nav-bar submenu dropdown-menu list-unstyled p-0">
                  <li class="nav-item">
                    <router-link class="nav-link" to="/documents"
                      >Documents
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link class="nav-link" to="/documents"
                      >Training
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link class="nav-link" to="/events"
                      >Events
                    </router-link>
                  </li>
                 
                </ul>
              </li> -->
              <!-- <li class="nav-item dropdown">
                                                              <a class="nav-link dropdown-toggle" data-toggle="dropdown"
                                                                >Partners</a
                                                              >
                                                              <ul class="nav-bar submenu dropdown-menu list-unstyled p-0">
                                                                <li class="nav-item">
                                                                  <router-link class="nav-link" to="/sba"
                                                                    >Project Spectrum</router-link
                                                                  >
                                                                </li>
                                                          <li class="nav-item">
                                                                  <router-link class="nav-link" to="/small-business-administration"
                                                                    >SBA</router-link
                                                                  >
                                                                </li>
                                                          <li class="nav-item">
                                                                  <router-link class="nav-link" to="/pilot-program"
                                                                    >DoD MPP</router-link
                                                                  >
                                                                </li>
                                                          <li class="nav-item">
                                                                  <router-link class="nav-link" to="/mep"
                                                                    >MEP</router-link
                                                                  >
                                                                </li>
                                                              </ul>
                                                            </li> -->

              <li class="nav-item">
                <router-link class="nav-link" to="/feedback"
                  >Feedback
                </router-link>
              </li>
              <!-- Contact Us -->
                <!-- Contact Us -->
                <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" data-toggle="dropdown"
                  >Contact Us</a
                >
                <ul class="nav-bar submenu dropdown-menu list-unstyled p-0">
                  <li class="nav-item">
                    <router-link
                      class="nav-link"
                      to="/contact"
                      >Let’s Connect</router-link
                    >
                  </li>
                  <li class="nav-item">
                    <router-link
                      class="nav-link"
                      to="/speaker-request"
                      >Speaker Request</router-link
                    >
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </b-sidebar>
      <!-- End Sidebar Nav for Mobile -->
      <!-- Full Width Top Nav Bar -->
      <div
        class="navbar-collapse collapse justify-content-end"
        id="navbarCollapse"
      >
        <ul class="navbar-nav navbar-center" id="mySidenav">
          <!-- Home -->
          <li class="nav-item">
            <router-link class="nav-link" to="/">Home</router-link>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" data-toggle="dropdown"
              >About Us</a
            >
            <ul class="nav-bar submenu dropdown-menu list-unstyled p-0">
              <li class="nav-item">
                <router-link class="nav-link" to="/leadership"
                  >Leadership
                </router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/about-us"
                  >What We Do
                </router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/service-area-locator"
                  >Service Area Locator
                </router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/partners"
                  >Partners
                </router-link>
              </li>
            </ul>
          </li>
          <!-- <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" data-toggle="dropdown"
              >Resources</a
            >
            <ul class="nav-bar submenu dropdown-menu list-unstyled p-0">
              <li class="nav-item">
                <router-link class="nav-link" to="/documents"
                  >Documents
                </router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/training">Training</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/events">Events</router-link>
              </li>
            </ul>
          </li> -->
          <!--  <li class="nav-item dropdown">
                                              <a class="nav-link dropdown-toggle" data-toggle="dropdown"
                                                >Partners</a
                                              >
                                              <ul class="nav-bar submenu dropdown-menu list-unstyled p-0">
                                                <li class="nav-item">
                                                  <router-link class="nav-link" to="/project-spectrum"
                                                    >Project Spectrum</router-link
                                                  >
                                                </li>
                                          <li class="nav-item">
                                                  <router-link class="nav-link" to="/small-business-administration"
                                                    >SBA</router-link
                                                  >
                                                </li>
                                          <li class="nav-item">
                                                  <router-link class="nav-link" to="/pilot-program"
                                                    >DoD MPP</router-link
                                                  >
                                                </li>
                                          <li class="nav-item">
                                                  <router-link class="nav-link" to="/mep"
                                                    >MEP</router-link
                                                  >
                                                </li>
                                              </ul>
                                            </li> -->
          <li class="nav-item">
            <router-link class="nav-link" to="/feedback">Feedback</router-link>
          </li>
          <!-- <li class="nav-item">
                                          <router-link class="nav-link" to="/events">About</router-link>
                                        </li> -->
          <!-- <li class="nav-item">
                                              <router-link class="nav-link" to="/events">Centers</router-link>
                                            </li> -->
          <!-- Contact Us -->
          <!-- Resources-->

            <!-- Contact Us -->
            <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" data-toggle="dropdown"
                  >Contact Us</a
                >
                <ul class="nav-bar submenu dropdown-menu list-unstyled p-0">
                  <li class="nav-item">
                    <router-link
                      class="nav-link"
                      to="/contact"
                      >Let’s Connect</router-link
                    >
                  </li>
                  <li class="nav-item">
                    <router-link
                      class="nav-link"
                      to="/speaker-request"
                      >Speaker Request</router-link
                    >
                  </li>
                </ul>
              </li>
        </ul>
        <div class="menu-close-btn">
          <i class="mdi mdi-close-circle-outline"></i>
        </div>
      </div>
      <!-- End Full Width Top Nav Bar -->

      <!-- Hamburger Button - Mobile -->
      <div class="header_btn">
        <button
          v-b-toggle.sidebar-backdrop
          class="navbar-toggler ml-2 p-0"
          type="button"
        >
          <i class="mdi mdi-menu"></i>
        </button>
      </div>
      <!-- End Hamburger Button - Mobile -->
    </div>
  </nav>
  <!-- End Navbar -->
</template>
<script>
import {
  isInOrganization,
  isOrganizationMember,
} from "../utils/authentication";

export default {
  name: "template_header",
  data() {
    return {
      isIndividual: false,
    };
  },
  mounted() {},
  computed: {
    isLoggedIn() {
      if (this.$store.state.isLoggedIn) {
        if (!isInOrganization()) {
          this.isIndividual = true;
        } else {
          this.isIndividual = false;
        }
      } else {
        this.isIndividual = false;
      }
      return this.$store.state.isLoggedIn;
    },
  },
};
</script>

<style scoped>
.dropdown-menu {
  min-width: 10rem;
  width: max-content;
}

.btn-primary {
  font-weight: 400;
  color: #314584;
  background-color: #f2b636;
  border-color: #f2b636;
}

.custom-nav.sticky-header .navbar-nav li button {
  color: #fff;
  background-color: #314584;
  border-color: #314584;
}

.navbar-expand-lg .navbar-nav {
  align-items: flex-start;
}

.dropdown-toggle {
  cursor: pointer;
}
</style>