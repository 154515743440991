<template>
  <div class="main-content">
    <!-- BradCrumb header Start-->
    <section class="inner-header-bg-partner">
      <div class="container">
        <!-- Breadcrumb Start-->
        <div class="section-breadcrumb">
          <div class="container">
            <div class="row">
              <div
                class="col-md-12 justify-content-center align-self-center pb-2"
              >
                <div class="breadcrumb-content">
                  <div class="title-section">
                    <h1 class="title text-center text-white">
                      Manufacturing Extension Partnership
                    </h1>
                  </div>
                  <div class="breadcrumb-nav">
                    <ul class="breadcrumb-link text-center list-unstyled mb-0">
                      <li class="text-white">
                        <i class="mdi mdi-home theme-light-color pr-2"></i>
                        <a href="/#/">Home</a>
                      </li>
                      <li class="theme-light-color active">MEP</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Breadcrumb End -->
      </div>
    </section>
    <!-- BradCrumb header End -->
    <!-- Body Area Start-->
    <section class="section">
      <div class="container">
        <div class="row justify-content-center">
          <!-- Start About Area -->
          <section class="about-area" id="aboutus">
            <div class="container">
              <!-- Not Logged In Message -->
              <div class="row align-items-center" v-if="!isLoggedIn">
                <div class="col-lg-6 col-md-12">
                  <div class="about-title section-title">
                    <span>APEX ACCELERATORS</span>
                    <h2>Manufacturing Extension Partnership</h2>
                  </div>
                </div>
                <!-- <div class="col-lg-6 col-md-12">
                  <div class="about-text pl-4">
                    <p>
                      To access the additional information and resources
                      available on this page, please
                      <router-link
                        :to="{
                          name: 'login',
                          query: { redirect: getRedirectRoute },
                        }"
                      >
                        <a> LOGIN</a>
                      </router-link>
                      or
                      <router-link :to="{ name: 'signup' }">
                        <a>SIGN UP</a>
                      </router-link>
                      for a free Project Spectrum account.
                    </p>
                    <div class="login-register-btn-container mb-2">
                      <router-link :to="{ name: 'login' }"
                        ><b-button class="btn theme-btn rounded login-reg-btn"
                          >Login</b-button
                        ></router-link
                      >
                      <router-link :to="{ name: 'signup' }"
                        ><b-button
                          variant="none"
                          class="btn theme-btn rounded login-reg-btn"
                          >Register</b-button
                        ></router-link
                      >
                    </div>
                  </div>
                </div> -->
              </div> 
              <!-- Header -->
              <!-- <div
                class="row align-items-center pb-4"
                v-if="isLoggedIn && isSignedUpMEP"
              > -->
			  <div
                class="row align-items-center pb-4"
              >
                <!--<div class="col-md-12">
                  <div class="about-title section-title">
                    <span>PROJECT SPECTRUM</span>
                    <h2>Manufacturing Extension Partnership</h2>
                  </div>
                </div> -->
              </div>
              <!-- MEP Video - Always Shown -->
              <div class="row align-items-center mt-0">
                <div class="col-lg-5">
                  <div
                    class="download-img scale-image wow fadeInUp container-iframe"
                    data-wow-duration="1s"
                  >
                    <iframe
                      src="https://iframe.videodelivery.net/0039ac3112a7e38165a6ba3854365614?poster=https%3A%2F%2Fvideodelivery.net%2F0039ac3112a7e38165a6ba3854365614%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D3s%26height%3D230"
                      class="responsive-iframe"
                      style="border: none"
                      width="100%"
                      allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                      allowfullscreen="true"
                    ></iframe>
                  </div>
                </div>
                <div class="col-lg-7">
                  <div
                    class="feature-contents section-heading wow fadeInUp"
                    data-wow-duration="1s"
                  >
                    <h1 class="font-18">
                      Let the MEP and Project Spectrum help your business with
                      cybersecurity compliance
                    </h1>
                    <p>
                      Welcome to an exciting collaboration between Project
                      Spectrum and the Manufacturing Extension Partnership
                      (MEP). We’re working together to provide manufacturers
                      with access to a self-directed platform for cutting edge
                      cybersecurity compliance training and tools.
                    </p>
                    <p>
                      Please watch the video to learn more about how the MEP and
                      Project Spectrum can assist you with your cybersecurity
                      compliance journey.
                    </p>
                  </div>
                </div>
              </div>
              <!-- MEP Info - Logged In and MEP Registered ONLY -->
              <!-- <b-row class="p-0 m-0" v-if="isLoggedIn && isSignedUpMEP"> -->
				<b-row class="p-0 m-0">
                <hr class="col-sm-12" />
                <!-- About MEP -->
                <b-row class="row align-items-center mt-0">
                  <div class="col-lg-7">
                    <div
                      class="feature-contents section-heading wow fadeInUp"
                      data-wow-duration="1s"
                    >
                      <h1 class="font-18">About MEP</h1>
                      <p>
                        The MEP is a unique public-private partnership that
                        delivers comprehensive, proven solutions to U.S.
                        manufacturers, fueling growth and advancing U.S.
                        manufacturing.
                      </p>
                      <p>
                        The MEP National Network™ comprises MEP Centers located
                        in all 50 states and Puerto Rico providing any U.S.
                        manufacturer with access to resources they need to
                        succeed. MEP Centers have helped thousands of
                        manufacturers improve operations, increase profits,
                        create or maintain jobs, and establish a foundation for
                        long-term business growth and productivity.
                      </p>
                      <p>
                        Please watch the video to learn what the MEP can do for
                        you.
                      </p>
                    </div>
                  </div>
                  <div class="col-lg-5">
                    <div
                      class="download-img scale-image wow fadeInUp container-iframe"
                      data-wow-duration="1s"
                    >
                      <iframe
                        src="https://iframe.videodelivery.net/1ddbea4d25538df2c9f35386bb2e256e?poster=https%3A%2F%2Fvideodelivery.net%2F1ddbea4d25538df2c9f35386bb2e256e%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D4s%26height%3D250"
                        class="responsive-iframe"
                        style="border: none"
                        width="100%"
                        allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                        allowfullscreen="true"
                      ></iframe>
                    </div>
                  </div>
                </b-row>
                <!-- The Manufacturer Path to Cybersecurity Compliance -->
                <hr class="col-sm-12" />
                <b-row class="row align-items-center mt-0">
                  <div class="col-sm-12">
                    <div
                      class="feature-contents section-heading wow fadeInUp"
                      data-wow-duration="1s"
                    >
                      <h1 class="font-18">
                        The Manufacturer Path to Cybersecurity Compliance
                      </h1>
                      <p>
                        Below are the recommended process steps for using
                        Project Spectrum’s educational tools and resources to
                        initiate or strengthen your company’s cybersecurity.
                        <span class="d-none d-lg-block font-weight-bold">
                          Hover your mouse above each step to learn more.
                        </span>
                      </p>
                      <!-- <p>
                        TO CONTACT YOUR LOCAL MEP OFFICE, CLICK THE BUTTON IN
                        THE MIDDLE OF THE GRAPHIC.
                      </p> -->
                    </div>
                  </div>
                  <div
                    class="col-md-12 mt-5 mb-5 text-center d-block d-lg-none"
                  >
                    <img
                      src="../../assets/images/mep/MEPHeaderFull.png"
                      class="img-fluid"
                    />
                  </div>
                  <div
                    id="big-screen"
                    class="col-md-12 mt-5 mb-5 text-center d-none d-lg-block"
                  >
                    <img
                      id="MEPHEADER"
                      src="../../assets/images/mep/MEPHeader2.png"
                      usemap="#image-map"
                    />
                    <map name="image-map" id="MEPHEADERMAP">
                      <area
                        alt="Understand your network"
                        title=""
                        href="javascript:void(0)"
                        shape="poly"
                        coords="192,136,266,85,378,198,357,257,210,260,13,255,5,134,98,137"
                        style="outline: none"
                        target=""
                        onmouseover="if(document.images) document.getElementById('MEPHEADER').src= '../../assets/images/mep/understand.png';"
                        onmouseout="if(document.images) document.getElementById('MEPHEADER').src= '../../assets/images/mep/MEPHeader2.png';"
                      />
                      <area
                        alt="Conduct a risk assessment"
                        title=""
                        href="javascript:void(0)"
                        shape="poly"
                        coords="10,278,357,274,377,328,278,432,5,414"
                        style="outline: none"
                        target=""
                        onmouseover="if(document.images) document.getElementById('MEPHEADER').src= '../../assets/images/mep/conduct.png';"
                        onmouseout="if(document.images) document.getElementById('MEPHEADER').src= '../../assets/images/mep/MEPHeader2.png';"
                      />
                      <area
                        alt="Education and outreach"
                        title=""
                        href="javascript:void(0)"
                        shape="poly"
                        coords="9,430,276,443,391,339,436,363,441,535,3,542"
                        style="outline: none"
                        target=""
                        onmouseover="if(document.images) document.getElementById('MEPHEADER').src= '../../assets/images/mep/education.png';"
                        onmouseout="if(document.images) document.getElementById('MEPHEADER').src= '../../assets/images/mep/MEPHeader2.png';"
                      />
                      <area
                        alt="Continuous Monitoring"
                        title=""
                        href="javascript:void(0)"
                        shape="poly"
                        coords="460,370,457,542,897,553,895,469,615,475,616,461,514,353"
                        style="outline: none"
                        target=""
                        onmouseover="if(document.images) document.getElementById('MEPHEADER').src= '../../assets/images/mep/continous.png';"
                        onmouseout="if(document.images) document.getElementById('MEPHEADER').src= '../../assets/images/mep/MEPHeader2.png';"
                      />
                      <area
                        alt="Remedation"
                        title=""
                        href="javascript:void(0)"
                        shape="poly"
                        coords="531,344,648,461,899,431,889,297,565,288"
                        style="outline: none"
                        target=""
                        onmouseover="if(document.images) document.getElementById('MEPHEADER').src= '../../assets/images/mep/remedation.png';"
                        onmouseout="if(document.images) document.getElementById('MEPHEADER').src= '../../assets/images/mep/MEPHeader2.png';"
                      />
                      <area
                        alt="Develop POA"
                        title=""
                        href="javascript:void(0)"
                        shape="poly"
                        coords="545,206,641,117,896,161,895,277,724,275,565,271"
                        style="outline: none"
                        target=""
                        onmouseover="if(document.images) document.getElementById('MEPHEADER').src= '../../assets/images/mep/develop.png';"
                        onmouseout="if(document.images) document.getElementById('MEPHEADER').src= '../../assets/images/mep/MEPHeader2.png';"
                      />
                      <area
                        alt="SSP"
                        title=""
                        href="javascript:void(0)"
                        shape="poly"
                        coords="474,157,473,27,898,32,893,141,647,101,539,190"
                        style="outline: none"
                        target=""
                        onmouseover="if(document.images) document.getElementById('MEPHEADER').src= '../../assets/images/mep/create.png';"
                        onmouseout="if(document.images) document.getElementById('MEPHEADER').src= '../../assets/images/mep/MEPHeader2.png';"
                      />
                      <area
                        alt="Register"
                        title=""
                        href="javascript:void(0)"
                        shape="poly"
                        coords="9,102,275,82,391,185,457,160,455,8,9,11"
                        style="outline: none"
                        target=""
                        onmouseover="if(document.images) document.getElementById('MEPHEADER').src= '../../assets/images/mep/register.png';"
                        onmouseout="if(document.images) document.getElementById('MEPHEADER').src= '../../assets/images/mep/MEPHeader2.png';"
                      />
                      <area
                        alt="Register"
                        title=""
                        href="javascript:void(0)"
                        shape="circle"
                        coords="462,265,99"
                        style="outline: none"
                        onclick=""
                        target=""
                      />
                    </map>
                  </div>
                </b-row>

                <!-- How a Manufacturer Can Utilize Project Spectrum -->
                <hr class="col-sm-12" />
                <b-row class="row align-items-center mt-0">
                  <div class="col-sm-12">
                    <div
                      class="feature-contents section-heading wow fadeInUp"
                      data-wow-duration="1s"
                    >
                      <h1 class="font-18">
                        How a Manufacturer Can Utilize Project Spectrum
                      </h1>
                      <p>
                        NIST MEP and the U.S. Department of Defense Office of
                        Small Business Programs signed a Memorandum of
                        Understanding in May 2021 to collaborate and provide
                        cybersecurity awareness resources for
                        small-to-medium-sized manufacturers.
                      </p>
                      <p>
                        Every company’s cybersecurity experience will be
                        different. The path to compliance will comprise
                        different steps depending upon the company’s current
                        state and the compliance level required.
                      </p>
                      <p>
                        Here are two real-world scenarios that demonstrate how
                        Project Spectrum’s tools and resources can guide a
                        manufacturer’s cybersecurity compliance journey.
                      </p>
                      <p>
                        This is a self-directed platform, you can stop at
                        anypoint, save your progress, and finish later.
                      </p>
                      <p>
                        <strong>Scenario #1: </strong
                        ><a
                          style="text-decoration: underline"
                          href="../../assets/images/mep/scenario-1.pdf"
                          target="_blank"
                        >
                          Company seeking DFARS conformity </a
                        ><br /><strong>Scenario #2: </strong
                        ><a
                          style="text-decoration: underline"
                          href="../../assets/images/mep/scenario-2.pdf"
                          target="_blank"
                        >
                          Company reviewing policies and procedures to ensure
                          continued compliance
                        </a>
                      </p>
                    </div>
                  </div>
                </b-row>
                <!-- How a Manufacturer Can Utilize Project Spectrum -->
                <hr class="col-sm-12" />
                <b-row class="row align-items-center mt-0"> </b-row>

                <b-col sm="3">
                  <ul class="blog-news list-unstyled">
                    <li class="blog-item">
                      <h6 class="post-news-title">Learn More</h6>
                    </li>
                  </ul>

                  <div class="blog-news-content">
                    <div class="blog-post-list">
                      <ul class="list-unstyled">
                        <li class="blog-news-info">
                          <a
                            class="text-dark"
                            href="https://www.nist.gov/mep/mep-national-network"
                          >
                            MEP National Network
                          </a>
                        </li>
                        <li class="blog-news-info">
                          <a
                            class="text-dark"
                            href="https://www.projectspectrum.io/#!/abtcmmc"
                          >
                            About CMMC
                          </a>
                        </li>
                        <li class="blog-news-info">
                          <a
                            class="text-dark"
                            href="https://www.projectspectrum.io/#!/newsAndEvents"
                          >
                            Cybersecurity News &amp; Articles
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </b-col>

                <b-col sm="3">
                  <ul class="blog-news list-unstyled">
                    <li class="blog-item">
                      <h6 class="post-news-title">Education Topics</h6>
                    </li>
                  </ul>

                  <div class="blog-news-content">
                    <div class="blog-post-list">
                      <ul class="list-unstyled">
                        <li class="blog-news-info">
                          <a
                            class="text-dark"
                            href="https://www.projectspectrum.io/#!/blog/blog-post/c18d9bf7-cae9-4cbc-b692-bfbce789c5a6"
                          >
                            VPN Vulnerabilities
                          </a>
                        </li>
                        <li class="blog-news-info">
                          <a
                            class="text-dark"
                            href="https://www.projectspectrum.io/#!/blog/blog-post/6c019bd9-bdbf-4778-9c64-ec7feb1db872"
                          >
                            Common Vulnerabilities</a
                          >
                        </li>
                        <li class="blog-news-info">
                          <a
                            class="text-dark"
                            href="https://www.projectspectrum.io/#!/cybercircuit/item/15da1d0e-48eb-4616-a508-c2bad5dc3d05"
                          >
                            Cybersecurity Costs
                          </a>
                        </li>
                        <li class="blog-news-info">
                          <a
                            class="text-dark"
                            href="https://www.projectspectrum.io/#!/whitepaper/item/048933f4-ab70-458e-a50d-2339cc1ce49b"
                          >
                            DoS and DDoS Attacks
                          </a>
                        </li>
                        <li class="blog-news-info">
                          <a
                            class="text-dark"
                            href="https://www.projectspectrum.io/#!/whitepaper/item/ceef8fff-693b-47ad-9bbf-074761c4d0ed"
                          >
                            Cybersecurity Saving
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </b-col>

                <b-col sm="6">
                  <ul class="blog-news list-unstyled">
                    <li class="blog-item">
                      <h6 class="post-news-title">Tools and Checklists</h6>
                    </li>
                  </ul>

                  <div class="blog-news-content">
                    <div class="blog-post-list">
                      <ul class="list-unstyled">
                        <li class="blog-news-info">
                          <a
                            class="text-dark"
                            href="https://www.endpointprotector.com/blog/nist-800-171-compliance-checklist/"
                          >
                            NIST SP 800-171 Compliance Checklist
                          </a>
                        </li>
                        <li class="blog-news-info">
                          <a
                            class="text-dark"
                            href="https://www.acq.osd.mil/dpap/pdi/cyber/docs/NIST%20SP%20800-171%20Assessment%20Methodology%20Version%201.2.1%20%206.24.2020.pdf"
                          >
                            NIST SP 800-171 DoD Assessment Methodology
                          </a>
                        </li>
                        <li class="blog-news-info">
                          <a
                            class="text-dark"
                            href="https://www.projectspectrum.io/#!/pilotProgram"
                          >
                            Mentor-Protégé Program Cybersecurity Pilot
                          </a>
                        </li>
                        <li class="blog-news-info">
                          <a
                            class="text-dark"
                            href="https://www.nist.gov/system/files/documents/2019/11/14/mepnn_cybersecurity_guide_10919-508.pdf"
                          >
                            Small- and Medium-Sized Manufacturers’ Guide to
                            Cybersecurity
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </b-col>
                <!-- Header -->
                <!-- <div class="row align-items-center pb-4" v-if="isLoggedIn">
                  <div class="col-md-12">
                    <div class="about-title section-title">
                      <span>CONTACT</span>
                      <h2>
                        Get in touch with your State’s Local MEP Cyber Expert
                      </h2>
                      <b-alert variant="success" show v-if="messageSent"
                        >Your message had been sent to your local MEP Cyber
                        Expert</b-alert
                      >
                      <b-alert variant="danger" show v-if="errorSendingMessage"
                        >Something went wrong while attempting to contact your
                        local MEP Cyber Expert. Please reach out to
                        <a href="mailto:outreach@projectspectrum.io"
                          >outreach@projectspectrum.io</a
                        >
                        for assistance</b-alert
                      >
                      <b-button
                        v-b-modal.modal-prevent-closing
                        class="my-2"
                        variant="btn w-100 rounded centered-link theme-btn-secondary"
                        >Contact Local Expert</b-button
                      >
                    </div>
                  </div>
                </div> -->
              </b-row>
            </div>
          </section>
          <!-- End About Area -->
        </div>
      </div>
      <!-- Contact Expert Modal -->
      <b-modal
        id="modal-prevent-closing"
        ref="modal"
        title="Contact Local MEP Cyber Expert"
        size="lg"
        @show="resetModal"
        @hidden="resetModal"
        @ok="handleModalOk"
      >
        <form ref="form" @submit.stop.prevent="handleModalSubmit">
          <!-- State -->
          <b-col class="py-1 px-0" sm="12">
            <div class="form-group">
              <label for="state-select">Select State*</label>
              <select
                id="state-select"
                :class="modal.stateError ? 'is-invalid' : ''"
                class="form-control"
                v-model="modal.state"
              >
                <option value="">Select State</option>
                <option value="AL">Alabama</option>
                <option value="AK">Alaska</option>
                <option value="AZ">Arizona</option>
                <option value="AR">Arkansas</option>
                <option value="CA">California</option>
                <option value="CO">Colorado</option>
                <option value="CT">Connecticut</option>
                <option value="DE">Delaware</option>
                <option value="DC">District Of Columbia</option>
                <option value="FL">Florida</option>
                <option value="GA">Georgia</option>
                <option value="HI">Hawaii</option>
                <option value="ID">Idaho</option>
                <option value="IL">Illinois</option>
                <option value="IN">Indiana</option>
                <option value="IA">Iowa</option>
                <option value="KS">Kansas</option>
                <option value="KY">Kentucky</option>
                <option value="LA">Louisiana</option>
                <option value="ME">Maine</option>
                <option value="MD">Maryland</option>
                <option value="MA">Massachusetts</option>
                <option value="MI">Michigan</option>
                <option value="MN">Minnesota</option>
                <option value="MS">Mississippi</option>
                <option value="MO">Missouri</option>
                <option value="MT">Montana</option>
                <option value="NE">Nebraska</option>
                <option value="NV">Nevada</option>
                <option value="NH">New Hampshire</option>
                <option value="NJ">New Jersey</option>
                <option value="NM">New Mexico</option>
                <option value="NY">New York</option>
                <option value="NC">North Carolina</option>
                <option value="ND">North Dakota</option>
                <option value="OH">Ohio</option>
                <option value="OK">Oklahoma</option>
                <option value="OR">Oregon</option>
                <option value="PA">Pennsylvania</option>
                <option value="RI">Rhode Island</option>
                <option value="SC">South Carolina</option>
                <option value="SD">South Dakota</option>
                <option value="TN">Tennessee</option>
                <option value="TX">Texas</option>
                <option value="UT">Utah</option>
                <option value="VT">Vermont</option>
                <option value="VA">Virginia</option>
                <option value="WA">Washington</option>
                <option value="WV">West Virginia</option>
                <option value="WI">Wisconsin</option>
                <option value="WY">Wyoming</option>
              </select>
              <div v-if="modal.stateError" class="invalid-feedback">
                State is required
              </div>
            </div>
          </b-col>
          <b-form-group
            label="Message"
            label-for="message-input"
            invalid-feedback="Message is required"
            :state="!modal.messageError"
          >
            <b-form-textarea
              id="message-input"
              v-model="modal.message"
              :state="modal.messageError ? false : undefined"
              rows="6"
              max-rows="6"
              required
            ></b-form-textarea>
          </b-form-group>
        </form>
      </b-modal>
    </section>
    <!-- Body Area End-->
  </div>
</template>
<script>
import axios from 'axios';
import {
  isLoggedIn,
  isEnabledMFA,
  handleApiError,
} from '../../utils/authentication';
export default {
  name: 'mep',
  async mounted() {
    this.init();
    /* If logged in, check to see if and MEP registration has been submitted for the user */
    /*if (isLoggedIn()) {
      await this.getSubmissionMEP();
    }*/
    /* If logged in but not registered, redirect the user to the MEP registration page */
    /*if (isLoggedIn() && !this.isSignedUpMEP) {
      console.warn('Logged in but not registered. Redirecting to MEP form.');
      this.$router.push({ path: 'mep-register' });
    }*/
  },
  data() {
    return {
      isSignedUpMEP: false,
      messageSent: false,
      errorSendingMessage: false,
      modal: {
        state: '',
        stateError: false,
        message: '',
        messageError: false,
        isSubmitted: '',
      },
    };
  },
  computed: {
    isLoggedIn() {
      return isLoggedIn();
    },
    isEnabledMFA() {
      return isEnabledMFA();
    },
    getRedirectRoute() {
      return `/mep`;
    },
  },
  methods: {
    init() {
      /* The footer height no longer needs to be set */
      // this.initFooterHeight();
    },
    initFooterHeight() {
      var footerHeight = $('footer').outerHeight();
      $('.main-content').css('margin-bottom', footerHeight);
    },
    /**
     * Check if the user has submitted their MEP registration
     */
    async getSubmissionMEP() {
      await axios
        .get(`/api/mep/`)
        .then((response) => {
          this.handleMEPGetResponce(response);
        })
        .catch((error) => {
          handleApiError(error.response, this.$router);
        });
    },
    /**
     * Handle response for MEP submission GET
     */
    handleMEPGetResponce(response) {
      /** Map response json to the form data */
      if (response.data?.isMEP) {
        this.isSignedUpMEP = true;
      } else {
        this.isSignedUpMEP = false;
      }
      //console.log('MEP Status received', response);
    },
    submitContactMEP(state, msg) {
      axios
        .post(`/api/mep/contact`, {
          toAddress: state,
          message: msg,
        })
        .then((response) => {
          this.messageSent = true;
          this.errorSendingMessage = false;
          //console.log(response);
        })
        .catch((error) => {
          this.messageSent = false;
          this.errorSendingMessage = true;
          handleApiError(error.response, this.$router);
        });
    },
    /**
     * Modal Methods
     */
    resetModal() {
      this.message = '';
      this.messageError = null;
      this.state = '';
      this.stateError = null;
    },
    handleModalOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleModalSubmit();
    },
    handleModalSubmit() {
      // Exit when the form isn't valid
      this.modal.stateError = !this.modal.state;
      this.modal.messageError = !this.modal.message;

      if (this.modal.stateError || this.modal.messageError) {
        return;
      }

      this.submitContactMEP(this.modal.state, this.modal.message);

      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide('modal-prevent-closing');
      });
    },
  },
};
</script>
<style scoped>
.container-iframe {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

/* Then style the iframe to fit in the container div with full height and width */
.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

hr {
  margin-top: 2rem;
  margin-bottom: 0.5rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

@media only screen and (max-width: 1023px) {
  #small-screen {
    display: block;
  }
}
</style>
