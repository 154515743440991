<template>
  <div class="main-content">
    <!-- BradCrumb header Start-->
    <section class="inner-header-bg">
      <div class="container">
        <!-- Breadcrumb Start-->
        <div class="section-breadcrumb">
          <div class="container">
            <div class="row">
              <div
                class="col-md-12 justify-content-center align-self-center pb-2"
              >
                <div class="breadcrumb-content">
                  <div class="title-section">
                    <h1 class="title text-center text-white">
                      Cyber Readiness Check Training
                    </h1>
                  </div>
                  <div class="breadcrumb-nav">
                    <ul class="breadcrumb-link text-center list-unstyled mb-0">
                      <li class="text-white">
                        <i class="mdi mdi-home theme-light-color pr-2"></i>
                        <a href="/#/">Home</a>
                      </li>
                      <li class="theme-light-color active">
                        Cyber Readiness Check Training
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Breadcrumb End -->
      </div>
    </section>
    <!-- BradCrumb header End -->
    <!-- Body Area Start-->
    <section class="section">
      <div class="container">
        <p>
            Our Cyber Readiness Training videos cover all NIST 800-171, CMMC Level 1 and CMMC Level 2 controls in a fun and engaging way.  Prior to taking one of our Cyber Readiness Checks, please take some time to enjoy these videos and prepare yourself to conduct your self assessments.  For your convenience, these videos are also available within the readiness checks for direct access.
          </p>
        <div v-if="!isLoggedIn" class="my-3 text-center">
        <br />Please
        <router-link :to="{ name: 'login' }">Login </router-link>
        or <router-link :to="{ name: 'signup' }">Register </router-link> as
        organization to view the Cyber Readiness Check Videos.<br />
        <br />
        <div class="login-register-btn-container mt-2">
          <router-link :to="{ name: 'login' }"
            ><b-button class="btn theme-btn rounded login-reg-btn"
              >Login</b-button
            ></router-link
          >
          <router-link :to="{ name: 'signup' }"
            ><b-button
              variant="none"
              class="btn theme-btn rounded login-reg-btn"
              >Register</b-button
            ></router-link
          >
        </div>
      </div>
      <div v-if="isLoggedIn">
        <div>
          <b-modal size="lg" ref="video-modal" hide-footer hide-header no-close-on-backdrop body-class="p-0">
            <img src="../../assets/images/trainingVideo.png" style="width:100%" />
            <div class="modal-body-c" v-if="showModal">
                        <!--
                        <div style="position: relative; padding-top: 56.25%;"><iframe src="https://customer-vqtiuw508mb7y2yt.cloudflarestream.com/f51ffa427ced0bf4d02e63be7250a97d/iframe?autoplay=true&poster=https%3A%2F%2Fcustomer-vqtiuw508mb7y2yt.cloudflarestream.com%2Ff51ffa427ced0bf4d02e63be7250a97d%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600&startTime=1m55s" style="border: none; position: absolute; top: 0; left: 0; height: 100%; width: 100%;" allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;" allowfullscreen="true"></iframe></div>
                         -->
                         <!-- <div style="position: relative; padding-top: 56.25%;"><iframe :src=showingVideo.VideoLink style="border: none; position: absolute; top: 0; left: 0; height: 100%; width: 100%;" allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;" allowfullscreen="true"></iframe></div>
                          -->
                          <button type="button" class="close" data-dismiss="modal" aria-label="Close" style="position:absolute;right:.2em;top:.2em">
                          <span aria-hidden="true" @click="closeModal">&times;</span>
                        </button>
                          <h5> {{showingVideo.Title}} </h5>
                          <p>{{showingVideo.Intro}}
                          </p>
                          <ul>
                            <li
                              v-for="vid in showingVideo.Videos"
                              :key="vid"
                            >
                            {{vid.Control}}
                          </li>
                          </ul>

                          <button
                            class="btn theme-btn wide-button" style="color:black" @click="openLink(showingVideo.VideoLink)"
                          >
                          <font-awesome-icon
                            class="pl-1 pr-1"
                            size="lg"
                            :icon="['fa', 'play']"
                          />
                          Play
                          </button>
                          <div
                            class="pt-2 d-flex align-items-center mt-auto"
                          >
                          <p class="blog-info-meta"
                            ><i class="mdi pr-1"></i
                            >{{ showingVideo.Videos.length }} Chapters</p
                          >

                          <p class="blog-category"
                            ><i class="mdi mdi-clock-outline pr-1"></i
                            >{{ showingVideo.Duration }}</p
                          >
                          </div>
                            <!-- Bookmark Page -->
                            <!-- <div class="float-right-bottom">
                                                <font-awesome-icon
                                                  id="tooltip-bookmark"
                                                  :icon="['far', 'bookmark']"
                                                  v-on:click="onBookmarkPage(item)"
                                                />
                              <b-tooltip target="tooltip-bookmark" variant="primary"
                                >Click to bookmark video</b-tooltip
                              > 
                            </div>-->
                          <!-- <ul>
                          <li v-for="(subVideo, index) in showingVideo.Videos" v-bind:key="index">
                            {{subVideo.Control}} {{subVideo.Time}}
                          <div style="position: relative; padding-top: 56.25%;"><iframe :src=subVideo.Link style="border: none; position: absolute; top: 0; left: 0; height: 100%; width: 100%;" allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;" allowfullscreen="true"></iframe></div>
                          </li>
                        </ul> -->
                        <h6 class="pt-5">Chapters</h6>
                          <b-table
                          id="video-table"
                          :items="showingVideo.Videos"
                          :fields="fields"
                          responsive
                          sortable="true"
                          class="mt-3"
                          borderless
                          hover
                        >
                        <template #cell(Link)="data">
                            <div class="image-and-tool d-flex">
                              <a :href= "data.item.Link" target="_blank" style="color:white" >
                                <font-awesome-icon
                                  class="pl-1 pr-1"
                                  size="2x"
                                  :icon="['fa', 'play-circle']"
                                />
                              </a>
                            </div>
                          </template>
                        </b-table>
                      </div>
          </b-modal>
          </div>
        <div class="pb-4">
          <b-form-input
            v-model="searchQuery"
            placeholder="Search Videos..."
          ></b-form-input>
        </div>
        <p class="pb-2" v-if="displayedBlogItems.length === 0 && searchQuery">
          No items match the search criteria...
        </p>
        <p class="pb-2" v-if="displayedBlogItems.length === 0 && !searchQuery">
          Check back soon for Videos...
        </p>
        <div class="blog-post-grid">
          <div class="row">
            <!-- Start Blog Item -->
            <div
              class="col-md-6 col-lg-4 mb-5"
              v-for="item in displayedBlogItems"
              :key="item.id"
            >
              <div class="blog-item border h-100 cursor-item"  @click="openModal(item)">
                <div style="display:none" class="videoLink">{{ item.VideoLink }}</div>
                <a
                  class="blog-post-image crc-video"
                >
                  <img
                    :src="item.Thumbnail"
                    :alt="item.photoDescription"
                    class="cover"
                  />
              </a>
                <div class="blog-post-info">
                  <a
                    class="text-dark crc-video"
                  >
                    <h4 class="blog-post-title">
                      {{ item.Title }}
                    </h4>
                  </a>
                  <div
                    class="text-dark crc-video pb-2"
                  >
                  <a class="blog-info-meta text-dark"
                      ><p class="limit-lines-4">{{ item.Intro }}</p
                      > </a>
                  </div>
                  <div
                    class="post-event-bottom d-flex align-items-center mt-auto"
                  >
                    <a class="blog-info-meta text-dark"
                      ><i class="mdi pr-1"></i
                      >{{ item.Videos.length }} Chapters</a
                    >

                    <a class="blog-category text-dark"
                      ><i class="mdi mdi-clock-outline pr-1"></i
                      >{{ item.Duration }}</a
                    >
                  </div>
                  <a
                    class="blog-read-more crc-video"
                  >
                    <i class="mdi mdi-arrow-right"></i>
                </a>
                </div>
              </div>
            </div>
            <!-- End Blog Item -->
          </div>
        </div>
        <div>
          <b-pagination
            id="blog-pagination"
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="center"
            @page-click="pageClicked"
          ></b-pagination>
        </div>
      </div>
     </div>
    </section>
  </div>
</template>
<script>
import axios from 'axios';
import { isLoggedIn } from '../../utils/authentication';
import { BPagination, BFormInput } from 'bootstrap-vue';
export default {
  name: 'blog',
  components: {
    BPagination,
    BFormInput,
  },
  props: {
    query: String,
  },
  data() {
    return {
      searchQuery: this.query,
      blogItems: [],
      blogItemCount: 0,
      currentPage: 1,
      perPage: 6,
      showModal: false,
      fields: [
        // A virtual column that doesn't exist in items
        {
          key: 'Time',
          label: 'Time',
          tdClass: 'align-middle',
          sortable: false,
          tdClass: 'text-white',
          thClass: 'text-white'
        },
        {
          key: 'Control',
          label: 'Title',
          tdClass: 'align-middle',
          sortable: false,
          tdClass: 'text-white',
          thClass: 'text-white'
        },
        {
          key: 'Link',
          label: 'Play',
          tdClass: 'align-middle',
          sortable: false,
          tdClass: 'text-white',
          thClass: 'text-white'
        }]
    };
  },
  computed: {
    isLoggedIn() {
      return isLoggedIn();
    },
    blogItemsFromSearchQuery() {
      if (this.searchQuery) {
        return this.blogItems.filter((item) => {
          return this.searchQuery
            .toLowerCase()
            .split(' ')
            .every(
              (v) =>
                item.Title.toLowerCase().includes(v)
            );
        });
      } else {
        return this.blogItems;
      }
    },
    displayedBlogItems() {
      return this.blogItemsFromSearchQuery.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
      );
    },
    totalRows() {
      return this.blogItemsFromSearchQuery.length;
    },
  },
  mounted: async function () {
    this.init();
    this.getBlogPosts(); // async
    
    // Add a native event listener to the Vue element.
    // this.$el.addEventListener("click", this.onHtmlClick)
  },
  methods: {
    init() {
      /* The footer height no longer needs to be set */
      // this.initFooterHeight();
    },
    initFooterHeight() {
      var footerHeight = $('footer').outerHeight();
      $('.main-content').css('margin-bottom', footerHeight);
    },
    closeModal() {
      this.$refs['video-modal'].hide();
      this.showModal = false;
    },
    openModal(video){
      debugger;
      // Check to make sure this is from our v-html because
      // we don't want to handle clicks from other things in
      // the Vue
      // if (!event.target.classList.contains("crc-video") && !event.target.parentElement.classList.contains("crc-video"))
      //   return;
      // let  videoDiv = event.target.parentElement;
      // this.videoURL = videoDiv.getAttribute("src");
      // event.stopPropagation();
      // event.preventDefault();
      this.showingVideo = video;
      this.showModal = true;
      this.$refs['video-modal'].show();
    },
    openLink (link){
      window.open(link, "_blank");
    },
    async getBlogPosts() {
      /*  API call to server: GET /api/resources/blogs  */
      axios
        .get('/api/assessment/videos')
        .then((response) => {
          this.blogItems = response.data;
          this.blogItemCount = response.data.length;;
          //console.log(this.blogItems);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    pageClicked() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
  },
};
</script>
<style scoped>
#blog-pagination li {
  margin: 0px;
}
.cursor-item {
  cursor: pointer;
}

.cover {
  object-fit: cover;
  height: 225px;
  width: 100%;
}
.modal-body-c {
  background-color:#031143;color: white;
  padding:2rem
}
.modal {
  overflow-y: auto !important; 
}
.text-white{
  color:white;
}
</style>