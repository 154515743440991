<template>
    <div>
        <div class="row">
            <!-- <div class="col-lg-3">
                <button
                    @click='addMarker'
                >
                    Add
                </button>
            </div> -->
            <div class="col-lg-12">
                <div class="about-title section-title">
                    <span> LOCATIONS</span>
                </div>
                <h2 class="pt-2 pb-2">FIND AN APEX ACCELERATOR NEAR YOU </h2>
                <p>Enter your business address, city, or ZIP code in the search box to find the location that serves
                    you.</p>
                <p>If you are a Native American business, or if you wish to explore the potential of selling to Tribal
                    governments, we invite you to contact the Native American Accelerator that serves your area. <a
                        href="#NativeAmerican"  @click="scrollToElement">Click
                        here</a> for the Native American APEX Accelerators map for contact information.</p>
                <div class="searchBarWrapper">
                    <div class="row no-gutters">
                        <div class="col-lg-10 col-xs-8 col-md-8">
                            <div class="searchBarInputWrapper">
                                <!-- diable location services for now -->
                                <!-- <div class="searchBarInputWrapperIcon">
                                    <font-awesome-icon :icon="['fas', 'location-arrow']" @click="locatorButtonPressed" />
                                </div> -->
                                <div style="width:100%">
                                    <GmapAutocomplete ref="autocomplete" @place_changed='setZoom'
                                        placeholder="Enter address, city or ZIP code" />
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 col-xs-4 col-md-4">
                            <div class="searchIcon" @click="searchButtonClicked">
                                <div class="searchIconLabel">
                                    <font-awesome-icon :icon="['fas', 'search']" />
                                    <span>SEARCH</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <GmapMap ref="mapRef" :center='center' :zoom='mapZoom' style='width:100%;  height: 420px;'>
            <GmapMarker :key="index" v-for="(m, index) in markers" :clickable="true" :draggable="false"
                :position="m.position" :icon="{
                                        url: require('../assets/images/icon-image/marker.png'),
                                        // size: {width: 60, height: 90, f: 'px', b: 'px',},
                                        // scaledSize: {width: 30, height: 45, f: 'px', b: 'px',},
                                        size: { width: 35, height: 50, f: 'px', b: 'px', },
                                        scaledSize: { width: 35, height: 50, f: 'px', b: 'px', },
                                    }" @click="openInfoWindowTemplate(index)" />
            <gmap-info-window :options="{
                                        maxWidth: 300,
                                        pixelOffset: { width: 0, height: -35 }
                                    }" :position="infoWindow.position" :opened="infoWindow.open"
                @closeclick="infoWindow.open = false">
                <div v-html="infoWindow.template"></div>
            </gmap-info-window>
        </GmapMap>
        <div id="NativeAmerican"></div>
        <h3 class="mt-5 pt-5 map-subtitle">NATIVE AMERICAN APEX ACCELERATORS</h3>
        <p>There are six specific APEX Accelerators designated to serve Native-owned businesses doing business
            with federal, state, local and tribal governments. These Accelerators bring expert knowledge of laws
            and certifications specific to Native business and an understanding of the cultures and communities
            to help Native and non-Native firms be successful.</p>
        <p class="mb-5">For assistance, please contact the Native American APEX Accelerator that serves your area.</p>
        <img class="img-fluid" src="../assets/images//native-american/Native_APEX_Map.png" />
    </div>
</template>

<script>
import { acceleratorLocations } from '../data/acceleratorLocations';
import ClickOutside from 'vue-click-outside';
import MenuIcon from 'vue-material-design-icons/Menu.vue';
import axios from 'axios';

export default {
    name: 'google_locator',
    components: {
        MenuIcon
    },
    data() {
        return {
            center: { lat: 39.50, lng: -98.35 },
            currentPlace: null,
            markers: [],
            markersFound: [],
            places: [],
            infoWindow: {
                position: { lat: 0, lng: 0 },
                open: false,
                template: ''
            },
            citySearch: "",
            mapZoom: 4,
            address: '',
            closest_markers: [],
            autocompletelatlng: ''
        }
    },
    watch: {
        citySearch(newValue, oldValue) {
            let found = this.markers.filter((marker) => {
                if (newValue && marker.info.City.toLowerCase().includes(newValue.toLowerCase())) {
                    return marker;
                }
            });
            this.markersFound = found;
        }
    },
    mounted() {
        // this.geolocate();
        this.initMarkers()
        // diable location services for now
        //this.locatorButtonPressed()
    },
    computed: {
        // diable location services for now
        // canGeolocate() {
        //     return !!navigator.geolocation
        // }
    },
    methods: {
        scrollToElement() {
            setTimeout(function(){
            history.replaceState("", document.title, window.location.pathname);
        }, 100);
        },
        searchButtonClicked() {
            if (this.autocompletelatlng != '') {
                this.$refs.mapRef.$mapPromise.then((map) => {
                    map.panTo({ lat: this.autocompletelatlng.geometry.location.lat(), lng: this.autocompletelatlng.geometry.location.lng() })
                    // this.find_closest_marker(position.coords.latitude, position.coords.longitude)
                })
                if (this.autocompletelatlng.types.includes("administrative_area_level_1")) {
                    this.mapZoom = 8;
                } else {
                    this.mapZoom = 11;
                }
            }

        },
        // diable location services for now
        // locatorButtonPressed() {
        //     navigator.geolocation.getCurrentPosition(
        //         position => {
        //             this.infoWindow.open = false;
        //             this.$refs.mapRef.$mapPromise.then((map) => {
        //                 map.panTo({ lat: position.coords.latitude, lng: position.coords.longitude })
        //                 this.mapZoom = 9;
        //                 this.find_closest_marker(position.coords.latitude, position.coords.longitude)
        //             })
        //             this.getStreetAddressFrom(position.coords.latitude, position.coords.longitude)
        //         },
        //         error => {
        //             console.log(error.message);
        //         }
        //     );
        // },
        async getStreetAddressFrom(lat, long) {
            try {
                var { data } = await axios.get(
                    "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
                    lat +
                    "," +
                    long +
                    "&key=AIzaSyCMnAObfj4pNs2ljwkKzJoapi5joIX8LiY"
                );
                if (data.error_message) {
                    console.log(data.error_message)
                } else {
                    this.address = data.results[0].formatted_address;
                }
            } catch (error) {
                console.log(error.message);
            }
        },
        find_closest_marker(lat1, lon1) {
            var pi = Math.PI;
            var R = 6371; //equatorial radius
            var distances = [];
            var closest = -1;
            var i = 0;
            var mrks = acceleratorLocations;
            for (i = 0; i < mrks.length; i++) {
                try {
                    var lat2 = mrks[i].position.lat;
                    var lon2 = mrks[i].position.lng;

                    var chLat = lat2 - lat1;
                    var chLon = lon2 - lon1;

                    var dLat = chLat * (pi / 180);
                    var dLon = chLon * (pi / 180);

                    var rLat1 = lat1 * (pi / 180);
                    var rLat2 = lat2 * (pi / 180);

                    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                        Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(rLat1) * Math.cos(rLat2);
                    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
                    var d = R * c;

                    distances[i] = mrks[i];
                    distances[i].distance = d;
                    if (closest == -1 || d < distances[closest].distance) {
                        closest = i;
                    }

                }
                catch {
                    console.log("failed marker", mrks[i])
                }
            }
            this.closest_markers = distances.sort((a, b) => { return a.distance - b.distance }).slice(0, 2)

        },
        setPlace(place) {
            this.currentPlace = place;
        },
        setZoom(location) {
            this.autocompletelatlng = location;
            this.center = {
                lat: location.geometry.location.lat(),
                lng: location.geometry.location.lng()
            }
            if (location.types.includes("administrative_area_level_1")) {
                this.mapZoom = 8;
            } 
            else if (location.formatted_address == '201 N Broadway, Billings, MT 59101, USA') {
  		                 this.mapZoom = 14;
            }
            else {
                this.mapZoom = 11;
            }

        },
        displayInfo(marker) {
            console.log(marker);
        },
        initMarkers() {
            this.markers = acceleratorLocations;
        },
        addMarker() {
            if (this.currentPlace) {
                const marker = {
                    lat: this.currentPlace.geometry.location.lat(),
                    lng: this.currentPlace.geometry.location.lng(),
                };
                this.markers.push({ position: marker });
                this.places.push(this.currentPlace);
                this.center = marker;
                this.currentPlace = null;
            }
        },
        getTemplateForWindow(name, address, city, state, zipcode, phone, email, serviceArea, website) {

            let imgSrc = require('../assets/images/icon-image/marker.png');

            let markup = `<div>`;
            markup += `<div class="row no-gutters marginb">`; // row
            markup += `<div class="col-2">`;
            markup += `<img src="${imgSrc}" class="windowIcon" alt="location icon" />`;
            markup += `</div>`;
            markup += `<div class="col-10" >`;
            markup += `<p class="h6">${name}</p>`;
            markup += `</div>`;
            markup += `</div>`; // row
            markup += `<div class="windowLineSeparator">`;
            markup += `<p class="windowLabel">Address</p>`;
            markup += `<p class="windowLine">${address}<br />`;
            markup += `${city}, ${state} ${zipcode}`;
            markup += `</p>`;
            markup += `</div>`;
            markup += `<div class="windowLineSeparator">`;
            markup += `<p class="windowLabel">Counties Served: </p>`;
            markup += `<p class="windowLine">${serviceArea}</p>`;
            markup += `</div>`;
            markup += `<div class="windowLineSeparator">`;
            markup += `<p class="windowLabel">Phone</p>`;
            markup += `<p class="windowLine">${phone}</p>`;
            markup += `</div>`;
            markup += `<div class="windowLineSeparator">`;
            markup += `<p class="windowLabel">Website</p>`;
            markup += `<p class="windowLine"> <a href="${website}" target="_blank"> ${website} </a></p>`;
            markup += `</div>`;
            markup += `<div class="windowLineSeparator">`;
            markup += `<p class="windowLabel">Email</p>`;
            markup += `<p class="windowLine"> <a href="mailto:${email}"> ${email}</a></p>`;
            markup += `<div class="windowLineSeparator">`;
            return markup;
        },
        hideMenu() {
            this.citySearch = ""
        },
        openInfoWindowTemplate(index) {
            const { info, position } = this.markers[index];
            this.center = position
            this.infoWindow.position = { lat: position.lat, lng: position.lng }
            this.infoWindow.template = this.getTemplateForWindow(
                info.Name,
                info["Address Line 1"],
                info["City"],
                info["State"],
                info["Postal Code"],
                info["Phone Number"],
                info["email for references"],
                info["Organization Service Area"],
                info["website"]
            );
            this.infoWindow.open = true;
        },
        selectSearchedLocation(index) {
            this.infoWindow.open = false;
            const { info, position } = this.markersFound[index];
            this.center = position;
            this.infoWindow.position = { lat: position.lat, lng: position.lng };
            this.infoWindow.template = this.getTemplateForWindow(
                info.Name,
                info["Address Line 1"],
                info["City"],
                info["State"],
                info["Postal Code"],
                info["Phone Number"],
                info["email for references"]
            );
            this.infoWindow.open = true;
            this.citySearch = "";
        }
    },
    directives: {
        ClickOutside
    }
};
</script>

<style>
.single-service-box-map {
    border: 1px solid grey;
    height: 100%;
    box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
}

.windowTitle {
    font-size: 18px;
    font-weight: 600;
    padding-left: 15px;
}

.windowLineSeparator {
    margin-bottom: 10px;
    margin-right: 10px;
}

.windowLabel {
    font-weight: 800;
    font-size: 14px;
    margin: 0;
    line-height: 12px;
    margin-bottom: 5px;
}

.windowLine {
    font-size: 16px;
    margin: 0;
}

.searchArea {
    position: relative;
    margin-bottom: 15px;
}

.searchAreaMenu {
    max-height: 150px;
    max-width: 225px;
    border-radius: 10px;
    box-shadow: 0px 5px 25px -10px rgba(0, 0, 0, 0.75);
    padding: 10px;
    overflow: auto;
    position: absolute;
    z-index: 100;
    background-color: #ffffff;
    top: 100px;
}

.searchAreaMenu::-webkit-scrollbar {
    display: none;
}

.searchAreaMenu {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.searchMenuOption {
    margin-bottom: 5px;
    cursor: pointer;
}

.searchMenuOption:hover {
    opacity: .66;
}

.searchMenuOption:hover {
    opacity: .33;
}

.searchMenuOption h4 {
    font-size: 16px;
    margin: 0;
    margin-bottom: 3px;
}

.searchMenuOption p {
    font-size: 12px;
    margin: 0;
}

.searchBarLabel {
    font-size: 14px;
    margin: 0;
    margin-bottom: 5px;
}

.pac-target-input {
    width: 100%;
    /* border: 1px solid #e2e2e2; */
    height: 50px;
    border-radius: 5px;
    padding-left: 10px;
    padding-right: 10px;
    min-width: 300px;
    font-size: 22px;
    font-weight: 600;
    outline: none;
}

.searchBarWrapper {
    border: 1px solid #e2e2e2;
    border-radius: 5px;
    margin-bottom: 10px;
    overflow: hidden;
}

.searchIcon {
    height: 100%;
    background-color: #8878c3;
    padding-left: 30px;
    padding-right: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.searchIconLabel {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    font-size: 20px;
    cursor: pointer
}

.searchIconLabel>span:first-of-type {
    font-size: 23px;
}

.searchBarInputWrapper {
    display: inline-block;
    display: flex;
    width: 100%;
}

.searchBarInputWrapperIcon {
    height: 50px;
    padding-left: 18px;
    padding-right: 12px;
    font-size: 25px;
    color: #8878c3;
    display: flex;
    align-items: center;
    justify-content: center;
}

.windowIcon {
    display: block;
    max-width: 35px !important;
}

.noPaddingleft {
    padding-left: 0px !important;
}

.marginb {
    margin-bottom: 15px !important;
}

.scrollable {
    overflow-y: auto;
    max-height: 275px;
    margin-right: 20px;
}

input[type="text"] {
    width: 100%
}

.service-box-header {
    display: flex;
    margin-bottom: 15px;
}

.service-box-header>div:first-of-type {
    margin-right: 10px;
    display: flex;
}

.map-subtitle {
    font-size: 22px !important;
}
</style>