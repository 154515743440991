var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "main-content"
  }, [_vm._m(0), _c('section', {
    staticClass: "section"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row justify-content-center"
  }, [_c('div', {
    staticClass: "col-12 row py-4"
  }, [_vm._m(1), _c('b-col', {
    staticClass: "px-0 mt-4",
    attrs: {
      "sm": "12",
      "lg": "7",
      "xl": "8"
    }
  }, [_c('blockquote', {
    staticClass: "blockquote mb-4"
  }, [_c('span', [_vm._v("Project Spectrum’s Cybersecurity for Defense Industrial Base Mentor-Protégé Program (MPP) Pilot helps eligible small businesses improve cybersecurity compliance and cyber incident reporting and hygiene.")])]), _c('p', [_vm._v(" The primary goal of the "), _c('i', [_vm._v("MPP Pilot")]), _vm._v(" is to allow "), _c('a', {
    attrs: {
      "href": "https://business.defense.gov/Programs/Mentor-Protege-Program/",
      "target": "_blank"
    }
  }, [_vm._v("Department of Defense (DoD) Mentor-Protégé Program (MPP)")]), _vm._v(" participants and other select companies in the Manufacturing, Research and Development, and Knowledge-based service sectors to expand their footprint in the defense industrial base (DIB). DoD recognizes Project Spectrum as a premier resource for the tools and training needed to increase cybersecurity awareness and maintain compliance in accordance with DoD contracting requirements. ")])]), _c('b-col', {
    staticClass: "d-none d-lg-block px-4 mt-4",
    attrs: {
      "sm": "12",
      "lg": "5",
      "xl": "4"
    }
  }, [_c('img', {
    staticClass: "img-fluid",
    attrs: {
      "src": require('../../assets/images/mpp/mpp_image.png')
    }
  })]), _vm._m(2)], 1), _c('hr', {
    staticClass: "col-sm-12"
  }), _c('div', {
    staticClass: "phase-container"
  }, [_c('div', {
    staticClass: "col-12 py-4 pt-0I"
  }, [_c('span', {
    staticClass: "dot dot-1"
  }), _c('img', {
    staticClass: "phase-icon",
    attrs: {
      "src": require('../../assets/images/mpp/phase1.png')
    }
  }), _vm._m(3), _c('h3', [_vm._v("STEP 1: REGISTER FOR PROJECT SPECTRUM")]), _c('p', {
    staticClass: "m-0"
  }, [_vm.isLoggedIn ? _c('span', {
    staticClass: "mdi mdi-check-circle text-success"
  }) : _c('span', {
    staticClass: "mdi mdi-checkbox-blank-circle-outline text-secondary"
  }), _c('a', {
    attrs: {
      "href": "https://www.projectspectrum.io/#/login"
    }
  }, [_vm._v(" Login")]), _vm._v(" or "), _c('a', {
    attrs: {
      "href": "https://www.projectspectrum.io/#/signup"
    }
  }, [_vm._v("Create Account")]), _vm._v(" for Project Spectrum."), _c('br')]), _c('p', {
    staticClass: "m-0"
  }, [_vm.isLoggedIn && _vm.isEnabledMFA ? _c('span', {
    staticClass: "mdi mdi-check-circle text-success"
  }) : _vm.isLoggedIn && !_vm.isEnabledMFA ? _c('span', {
    staticClass: "mdi mdi-minus-circle text-warning"
  }) : _c('span', {
    staticClass: "mdi mdi-checkbox-blank-circle-outline text-secondary"
  }), _vm._v(" Enable "), _c('b', [_vm._v("multifactor authentication (MFA)")]), _vm._v(" in your "), _c('a', {
    attrs: {
      "href": "https://www.projectspectrum.io/#/my-account"
    }
  }, [_vm._v("Account Settings")]), _vm._v(" . ")]), _c('h3', [_vm._v(" STEP 2: APPLY TO THE CYBERSECURITY FOR DEFENSE INDUSTRIAL BASE MPP PILOT ")]), _vm.isLoggedIn && _vm.isEnabledMFA ? _c('p', [_vm._v(" After completing registration for Project Spectrum, "), _c('a', {
    attrs: {
      "href": "https://www.projectspectrum.io/#/pilot-register"
    }
  }, [_vm._v("start your application")]), _vm._v(". Applicants will be asked additional qualification questions for the "), _c('i', [_vm._v("MPP Pilot Program")]), _vm._v(". ")]) : _c('p', [_vm._v(" After completing registration for Project Spectrum, applicants can start their application here. Applicants will be asked additional qualification questions for the "), _c('i', [_vm._v("MPP Pilot Program")]), _vm._v(". ")]), _c('h3', [_vm._v("STEP 3: TAKE THE CYBER READINESS CHECK")]), _vm._m(4)]), _c('hr', {
    staticClass: "col-sm-12"
  }), _c('div', {
    staticClass: "col-12 py-4"
  }, [_c('div', {
    staticClass: "mt-0 text-lg-left"
  }, [_c('span', {
    staticClass: "dot dot-2"
  }), _c('img', {
    staticClass: "phase-icon",
    attrs: {
      "src": require('../../assets/images/mpp/phase2.png')
    }
  }), _vm._m(5), _c('h3', [_vm._v("STEP 4: TAKE THE READINESS TRAINING")]), _c('p', [_vm._v(" MPP Pilot participants will take basic training as needed, and that training will be specific to the results of the readiness check. For those that need the basic training foundational to navigating cybersecurity compliance, Project Spectrum strongly recommends the following offerings: ")]), _vm._m(6), _c('p', [_vm._v(" Project Spectrum cyber advisors will create a training plan and improvement strategy for each participating company based on readiness check scores and other key information. ")])])]), _c('hr', {
    staticClass: "col-12"
  }), _c('div', {
    staticClass: "col-12 py-2"
  }, [_c('div', {
    staticClass: "mt-4 text-lg-left"
  }, [_c('div', {
    staticClass: "mt-4 text-lg-left"
  }, [_c('span', {
    staticClass: "dot dot-3"
  }), _c('img', {
    staticClass: "phase-icon",
    attrs: {
      "src": require('../../assets/images/mpp/phase3.png')
    }
  }), _vm._m(7)]), _c('p', [_vm._v(" Project Spectrum previously defined these phases and outlined specific steps for both Phase 3 and Phase 4. Following the recently announced changes to CMMC (Version 2.0), we are reassessing these phases to ensure they follow the new criteria and will re-launch them in the near future. ")])])]), _c('hr', {
    staticClass: "col-12"
  })])])])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "inner-header-bg-partner"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "section-breadcrumb"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12 justify-content-center align-self-center pb-2"
  }, [_c('div', {
    staticClass: "breadcrumb-content"
  }, [_c('div', {
    staticClass: "title-section"
  }, [_c('h1', {
    staticClass: "title text-center text-white"
  }, [_vm._v(" MPP Pilot Program ")])]), _c('div', {
    staticClass: "breadcrumb-nav"
  }, [_c('ul', {
    staticClass: "breadcrumb-link text-center list-unstyled mb-0"
  }, [_c('li', {
    staticClass: "text-white"
  }, [_c('i', {
    staticClass: "mdi mdi-home theme-light-color pr-2"
  }), _c('a', {
    attrs: {
      "href": "/#/"
    }
  }, [_vm._v("Home")])]), _c('li', {
    staticClass: "theme-light-color active"
  }, [_vm._v("MPP Pilot")])])])])])])])])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "section-title"
  }, [_c('span', [_vm._v("PROGRAM")]), _c('h2', [_vm._v(" Cybersecurity for Defense Industrial Base MPP Pilot Program ")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-lg-left"
  }, [_c('p', [_vm._v(" To be eligible to participate in the MPP Pilot, a company must have a recent, current, or new DoD MPP agreement. MPP Pilot participants must meet the following expectations: ")]), _c('ul', [_c('li', [_vm._v(" Devote one (1) senior level representative and one (1) IT representative to the project. ")]), _c('li', [_vm._v(" Dedicate approximately ten (10) hours per week of staff time to maintain their responsibility to the partnership. ")]), _c('li', [_vm._v("Agree to a 24-month collaboration.")])]), _c('p', [_vm._v(" By the conclusion of the MPP Pilot, participants should be able to: ")]), _c('ul', [_c('li', [_vm._v(" Show increased awareness of, and compliance with, "), _c('a', {
    attrs: {
      "href": "https://csrc.nist.gov/publications/detail/sp/800-171/rev-2/final",
      "target": "_blank"
    }
  }, [_vm._v("NIST SP 800-171")]), _vm._v(" and "), _c('a', {
    attrs: {
      "href": "https://csrc.nist.gov/publications/detail/sp/800-172/final",
      "target": "_blank"
    }
  }, [_vm._v("NIST SP 800-172")]), _vm._v(". ")]), _c('li', [_vm._v(" Demonstrate readiness for "), _c('a', {
    attrs: {
      "href": "https://www.acq.osd.mil/cmmc/",
      "target": "_blank"
    }
  }, [_vm._v("Cybersecurity Maturity Model Certification (CMMC) ")]), _vm._v(" accreditation. ")]), _c('li', [_vm._v(" Share best practices among the DoD small business community. ")]), _c('li', [_vm._v("Engage with DoD small business for valued services.")])]), _c('b', [_vm._v("There are currently four phases for the Project Spectrum MPP Pilot")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "section-title"
  }, [_c('span', [_vm._v("PHASE 1")]), _c('h2', [_vm._v("REGISTRATION")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_vm._v(" Initiate the readiness check process for CMMC. "), _c('a', {
    attrs: {
      "href": "https://www.projectspectrum.io/#/cyber-readiness-check"
    }
  }, [_vm._v("Start your Cyber Readiness Check evaluation now")]), _vm._v("."), _c('br'), _c('i', [_vm._v(" Participants can stop at any point, save their progress, and finish later. ")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "section-title"
  }, [_c('span', [_vm._v("PHASE 2")]), _c('h2', [_vm._v("TRAINING")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ul', [_c('li', [_c('a', {
    attrs: {
      "href": "https://www.projectspectrum.io/#/courses-encite?query=CUI%20for%20Contractors",
      "target": "_blank"
    }
  }, [_vm._v(" Controlled Unclassified Information (CUI) online course. ")])]), _c('li', [_c('a', {
    attrs: {
      "href": "https://www.projectspectrum.io/#/courses-encite?query=CMMC%20Level%201%20Course",
      "target": "_blank"
    }
  }, [_vm._v(" CMMC Level 1 online course. ")])]), _c('li', [_c('a', {
    attrs: {
      "href": "https://www.projectspectrum.io/#/courses-encite?query=Systems%20Security%20Plan%20Fundamentals",
      "target": "_blank"
    }
  }, [_vm._v(" Systems Security Plan Fundamentals ")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "section-title"
  }, [_c('span', [_vm._v("PHASE 3 & 4")]), _c('h2', [_vm._v("COMPLIANCE MENTORSHIP & CONTINUOUS COMPLIANCE")])]);

}]

export { render, staticRenderFns }