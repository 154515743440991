var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "main-content"
  }, [_vm._m(0), _c('section', {
    staticClass: "section"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('p', [_vm._v(" APEX Accelerators can use the following resources to serve as critical enablers to opening the defense industrial base to small, medium, large, and new entrant companies. ")]), _vm.displayedBlogItems.length === 0 && _vm.searchQuery ? _c('p', {
    staticClass: "pb-2"
  }, [_vm._v(" No items match the search criteria... ")]) : _vm._e(), _vm.displayedBlogItems.length === 0 && !_vm.searchQuery ? _c('p', {
    staticClass: "pb-2"
  }, [_vm._v(" Check back soon for documents.. ")]) : _vm._e(), _c('div', {
    staticClass: "row mt-4"
  }, [_c('div', {
    staticClass: "col-md-8"
  }, [_c('div', {
    staticClass: "blog-post-grid"
  }, [_c('div', {
    staticClass: "row"
  }, _vm._l(_vm.displayedBlogItems, function (item) {
    return _c('div', {
      key: item.id,
      staticClass: "col-12 mb-3"
    }, [_c('div', {
      staticClass: "col-12 mb-3"
    }, [_c('div', {
      staticClass: "row blog-item border d-flex"
    }, [item.type === 'powerpoint' ? _c('a', {
      staticClass: "col-2 col-sm-3 col-xl-2 blog-post-image center-image-vertically",
      attrs: {
        "target": "_blank"
      }
    }, [_c('img', {
      staticClass: "thumbnail-cover",
      attrs: {
        "src": "https://assets.apexaccelerators.us/documents/powerpoint.png",
        "alt": "powerpoint"
      }
    })]) : item.type === 'webinar' ? _c('a', {
      staticClass: "col-2 col-sm-3 col-xl-2 blog-post-image center-image-vertically",
      attrs: {
        "target": "_blank"
      }
    }, [_c('img', {
      staticClass: "thumbnail-cover",
      attrs: {
        "src": "https://assets.apexaccelerators.us/documents/webinar.png",
        "alt": "webinar"
      }
    })]) : item.type === 'handout' ? _c('a', {
      staticClass: "col-2 col-sm-3 col-xl-2 blog-post-image center-image-vertically",
      attrs: {
        "target": "_blank"
      }
    }, [_c('img', {
      staticClass: "thumbnail-cover",
      attrs: {
        "src": "https://assets.apexaccelerators.us/documents/handout.png",
        "alt": "handout"
      }
    })]) : item.type === 'weblink' ? _c('a', {
      staticClass: "col-2 col-sm-3 col-xl-2 blog-post-image center-image-vertically",
      attrs: {
        "target": "_blank"
      }
    }, [_c('img', {
      staticClass: "thumbnail-cover",
      attrs: {
        "src": "https://assets.apexaccelerators.us/documents/Web Link.png",
        "alt": "Web link"
      }
    })]) : _c('a', {
      staticClass: "col-2 col-sm-3 col-xl-2 blog-post-image center-image-vertically",
      attrs: {
        "target": "_blank"
      }
    }, [_c('img', {
      staticClass: "thumbnail-cover",
      attrs: {
        "src": item.thumbnail,
        "alt": item.thumbnailDescription
      }
    })]), _c('div', {
      staticClass: "col-10 col-sm-9 col-xl-10 useful-link-info"
    }, [_c('a', {
      staticClass: "text-dark"
    }, [_c('h4', {
      staticClass: "blog-post-title mt-1"
    }, [_vm._v(" " + _vm._s(item.title) + " ")]), item.type === 'webinar' ? _c('a', {
      staticClass: "limit-lines-1",
      attrs: {
        "target": "_blank",
        "href": item.url
      }
    }, [_vm._v(" View Webinar ")]) : item.type === 'powerpoint' ? _c('a', {
      staticClass: "limit-lines-1",
      attrs: {
        "target": "_blank",
        "href": item.url
      }
    }, [_vm._v(" View Document ")]) : item.type === 'weblink' ? _c('a', {
      staticClass: "limit-lines-1",
      attrs: {
        "target": "_blank",
        "href": item.url
      }
    }, [_vm._v(" View Link ")]) : _c('a', {
      staticClass: "limit-lines-1",
      attrs: {
        "target": "_blank",
        "href": item.url
      }
    }, [_vm._v(" View/Download ")])])])])])]);
  }), 0)]), _c('div', [_c('b-pagination', {
    attrs: {
      "id": "blog-pagination",
      "total-rows": _vm.totalRows,
      "per-page": _vm.perPage,
      "align": "center"
    },
    on: {
      "page-click": _vm.pageClicked
    },
    model: {
      value: _vm.currentPage,
      callback: function ($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)])])])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "inner-header-bg"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "section-breadcrumb"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12 justify-content-center align-self-center pb-2"
  }, [_c('div', {
    staticClass: "breadcrumb-content"
  }, [_c('div', {
    staticClass: "title-section"
  }, [_c('h1', {
    staticClass: "title text-center text-white"
  }, [_vm._v(" Training ")])]), _c('div', {
    staticClass: "breadcrumb-nav"
  }, [_c('ul', {
    staticClass: "breadcrumb-link text-center list-unstyled mb-0"
  }, [_c('li', {
    staticClass: "text-white"
  }, [_c('i', {
    staticClass: "mdi mdi-home theme-light-color pr-2"
  }), _c('a', {
    attrs: {
      "href": "/#/"
    }
  }, [_vm._v("Home")])]), _c('li', {
    staticClass: "theme-light-color active"
  }, [_vm._v("training")])])])])])])])])])]);

}]

export { render, staticRenderFns }