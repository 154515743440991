var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "main-content"
  }, [_vm._m(0), _c('section', {
    staticClass: "section"
  }, [_c('div', {
    staticClass: "container"
  }, [_vm._m(1), _c('p', [_vm._v(" Our team's primary goal is to help educate our partners on all events related to the APEX Accelerators. ")]), _c('div', {
    staticClass: "pb-4"
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Search Events..."
    },
    model: {
      value: _vm.searchQuery,
      callback: function ($$v) {
        _vm.searchQuery = $$v;
      },
      expression: "searchQuery"
    }
  })], 1), _vm.displayedItemsUpcoming.length === 0 && !_vm.searchQuery ? _c('p', {
    staticClass: "pb-2"
  }, [_vm._v(" Check back soon for upcoming events... ")]) : _vm._e(), _vm.displayedItemsUpcoming.length === 0 && _vm.searchQuery ? _c('p', {
    staticClass: "pb-2"
  }, [_vm._v(" No items match the search criteria... ")]) : _vm._e(), _c('div', {
    staticClass: "blog-post-grid"
  }, [_c('div', {
    staticClass: "row"
  }, _vm._l(_vm.displayedItemsUpcoming, function (item) {
    return _c('div', {
      key: item.id,
      staticClass: "col-md-6 col-lg-4 mb-5"
    }, [item.type === 'events' ? _c('div', {
      staticClass: "blog-item border h-100"
    }, ['imageURL' in item ? _c('div', [_c('router-link', {
      staticClass: "blog-post-image",
      attrs: {
        "to": {
          name: 'event-detail',
          query: {
            id: item.id
          }
        }
      }
    }, ['imageURL' in item ? _c('img', {
      staticClass: "cover",
      attrs: {
        "src": item.imageURL
      }
    }) : _c('img', {
      staticClass: "cover",
      attrs: {
        "src": require("../../assets/images/services/webinar.jpeg")
      }
    })])], 1) : _vm._e(), _c('div', {
      staticClass: "blog-post-info"
    }, [_c('router-link', {
      staticClass: "text-dark",
      attrs: {
        "to": {
          name: 'event-detail',
          query: {
            id: item.id
          }
        }
      }
    }, [_c('h4', {
      staticClass: "blog-post-title"
    }, [_vm._v(_vm._s(item.eventTitle))]), item.registrationRequired ? _c('b-badge', {
      staticClass: "mb-3 badge-required"
    }, [_vm._v("Registration Required")]) : _vm._e(), !item.registrationRequired ? _c('b-badge', {
      staticClass: "mb-3 badge-not-required"
    }, [_vm._v("Learn More")]) : _vm._e(), _c('div', {
      staticClass: "post-event-bottom d-flex align-items-center"
    }, [_c('p', {
      staticClass: "blog-info-meta text-dark"
    }, [_vm._v(_vm._s(_vm.getFormattedDate(item.startAt)))]), _c('p', {
      staticClass: "blog-category text-dark"
    }, [_vm._v("Event")])])], 1), _c('router-link', {
      staticClass: "blog-read-more",
      attrs: {
        "to": {
          name: 'event-detail',
          query: {
            id: item.id
          }
        }
      }
    }, [_c('i', {
      staticClass: "mdi mdi-arrow-right"
    })])], 1)]) : _vm._e()]);
  }), 0)]), _c('h3', {
    staticClass: "pb-2"
  }, [_vm._v("Past Events")]), _vm.displayedItemsPastEvents.length === 0 ? _c('p', {
    staticClass: "pb-2"
  }, [_vm._v(" No items match the search criteria... ")]) : _vm._e(), _c('b-table', {
    staticClass: "mt-3 events-table",
    attrs: {
      "id": "table-webinar-events",
      "fields": _vm.events.fields,
      "items": _vm.displayedItemsPastEvents,
      "busy": _vm.isLoading,
      "responsive": "",
      "borderless": "",
      "no-border-collapse": "",
      "small": "",
      "sortable": "true",
      "hover": "",
      "tdClass": ['events-cell']
    },
    scopedSlots: _vm._u([{
      key: "table-busy",
      fn: function () {
        return [_c('div', {
          staticClass: "text-center text-secondary my-2"
        }, [_c('b-spinner', {
          staticClass: "align-middle"
        }), _c('strong', {
          staticClass: "pl-2"
        }, [_vm._v("Loading...")])], 1)];
      },
      proxy: true
    }, {
      key: "cell(eventTitle)",
      fn: function (data) {
        return [_c('div', {
          staticClass: "image-and-tool d-flex"
        }, [_c('div', {
          staticClass: "image-container"
        }, ['imageURL' in data.item ? _c('b-img', {
          staticClass: "tool-icon",
          attrs: {
            "src": data.item.imageURL,
            "rounded": "",
            "alt": "Rounded image"
          }
        }) : _c('b-img', {
          staticClass: "tool-icon",
          attrs: {
            "src": require('../../assets/images/services/webinar.jpeg'),
            "rounded": "",
            "alt": "Rounded image"
          }
        })], 1), _c('p', {
          staticClass: "clipped-text m-0 font-weight-bold"
        }, [_vm._v(" " + _vm._s(data.item.eventTitle) + " ")])])];
      }
    }])
  }), _c('div', [_c('b-pagination', {
    attrs: {
      "id": "blog-pagination",
      "total-rows": _vm.totalRowsPastEvents,
      "per-page": _vm.events.past.perPage,
      "align": "center"
    },
    on: {
      "page-click": _vm.pageClicked
    },
    model: {
      value: _vm.events.past.currentPage,
      callback: function ($$v) {
        _vm.$set(_vm.events.past, "currentPage", $$v);
      },
      expression: "events.past.currentPage"
    }
  })], 1)], 1)])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "inner-header-bg"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "section-breadcrumb"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12 justify-content-center align-self-center pb-2"
  }, [_c('div', {
    staticClass: "breadcrumb-content"
  }, [_c('div', {
    staticClass: "title-section"
  }, [_c('h1', {
    staticClass: "title text-center text-white"
  }, [_vm._v("Events")])]), _c('div', {
    staticClass: "breadcrumb-nav"
  }, [_c('ul', {
    staticClass: "breadcrumb-link text-center list-unstyled mb-0"
  }, [_c('li', {
    staticClass: "text-white"
  }, [_c('i', {
    staticClass: "mdi mdi-home theme-light-color pr-2"
  }), _c('a', {
    attrs: {
      "href": "/#/"
    }
  }, [_vm._v("Home")])]), _c('li', {
    staticClass: "theme-light-color active"
  }, [_vm._v("Events")])])])])])])])])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-center events-caption-area"
  }, [_c('img', {
    staticClass: "img-fluid",
    attrs: {
      "alt": "group",
      "src": require("../../assets/images/events/eventsgroupphoto.jpg")
    }
  }), _c('p', [_vm._v("Image captured on 29 March 2023 at DoD Mentor Protégé Summit 2023 (March 27 – 30 in Orlando, FL)")]), _c('div', {
    staticClass: "bottom-color-separator"
  }), _c('div', {
    staticClass: "caption-lines"
  }, [_c('p', [_vm._v("Khalil Mack (center, Director of APEX Accelerators) with his panelists.")]), _c('p', [_vm._v("From left: Lonye Ford (CEO, Arlo Solutions), Arlene Wube (President & COO, Arlo Solutions), ")]), _c('p', [_vm._v("Katherine Thompson (Division Chief, U.S. Army Contracting Command), Darryl Thomas (Program ")]), _c('p', [_vm._v("Manager, Illinois APEX Accelerator), and Don Zavesky (Program Manager, Florida APEX Accelerator). ")])])]);

}]

export { render, staticRenderFns }