<template>
   <VueBotUI
      :messages="messageData"
      :options="botOptions"
      :bot-typing="botTyping"
      :input-disable="inputDisable"
      @init="botStart"
      @msg-send="msgSend"
    />	
</template>
<script>
import { VueBotUI } from 'vue-bot-ui';
import BotIcon from '../assets/images/logo/logo-dark-small.png';
import axios from 'axios';


export default {
name: "chatbot",
components: {
	VueBotUI
},
data() {
  return {
	  messageData: [],
      botTyping: false,
      inputDisable: false,
	  botOptions: {
        botAvatarImg: BotIcon,
        botTitle: 'Project Spectrum Bot',
        boardContentBg: '#f4f4f4',
        msgBubbleBgBot: '#fff',
        inputPlaceholder: 'Type query here ...',
        inputDisableBg: '#fff',
        inputDisablePlaceholder: 'Hit the buttons above to respond',
        bubbleBtnSize: 50,
        client: null,
      }
  }
},
methods: {
 botStart() {
      // Get token if you want to build a private bot
      // Request first message here
      // Fake typing for the first message
	  //console.log("In Bot Start")
      this.botTyping = true;
      setTimeout(() => {
        this.botTyping = false;
        this.messageData.push({
          agent: 'bot',
          type: 'text',
          text: 'Hello, My name is PS Bot. Do you have any questions regarding Project Spectrum?',
        });
      }, 1000);
    },
    msgSend(value) {
      // Push the user's message to board
      this.messageData.push({
        agent: 'user',
        type: 'text',
        text: value.text,
      });
      this.getResponse(value.text);
    },
    // Submit the message from user to bot API, then get the response from Bot
    getResponse(txt) {
      // Loading
      this.botTyping = true;
      axios
        .get(`/api/chatbot/`, { params: { query: txt } })
        .then((response) => {
          //console.log(response);
          if (response.status === 200) {
            const replyMessage = {
              agent: 'bot',
              text: response.data,
            };
            this.inputDisable = false;
            this.messageData.push(replyMessage);
            // finish
            this.botTyping = false;
          }
        });
    },
}
}
</script>
