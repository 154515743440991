var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "native-american-location-badge"
  }, [_c('div', {
    staticClass: "row g-2"
  }, [_c('div', {
    staticClass: "col-4"
  }, [_c('div', {
    class: ['location-label-box', _vm.large ? 'location-label-box-lg' : ''],
    style: {
      backgroundColor: _vm.boxColor
    }
  }, [_c('img', {
    class: ['img-fluid', _vm.large ? 'minimgheight' : ''],
    attrs: {
      "src": '/assets/images/native-american/' + _vm.line1 + ' ' + _vm.line2 + '.png'
    }
  })])]), _c('div', {
    staticClass: "col-8"
  }, [_c('div', {
    staticClass: "address-box"
  }, [_vm.address1 ? _c('div', {
    staticClass: "address-info"
  }, [_c('h4', [_vm._v(_vm._s(_vm.address1.title))]), _c('p', [_vm._v(_vm._s(_vm.address1.street))]), _c('p', [_vm._v(_vm._s(_vm.address1.cityStateZip))]), _c('p', [_vm._v(_vm._s(_vm.address1.phone))]), _c('p', [_c('a', {
    attrs: {
      "href": _vm.address1.website,
      "target": "_blank"
    }
  }, [_vm._v(_vm._s(_vm.address1.website))])]), _c('p', [_c('a', {
    attrs: {
      "href": `mailto:${_vm.address1.email}`
    }
  }, [_vm._v(_vm._s(_vm.address1.email))])])]) : _vm._e(), _vm.address2 ? _c('div', {
    staticClass: "address-info mt-3"
  }, [_c('h4', [_vm._v(_vm._s(_vm.address2.title))]), _c('p', [_vm._v(_vm._s(_vm.address2.street))]), _c('p', [_vm._v(_vm._s(_vm.address2.cityStateZip))]), _c('p', [_vm._v(_vm._s(_vm.address2.phone))]), _c('p', [_c('a', {
    attrs: {
      "href": _vm.address2.website,
      "target": "_blank"
    }
  }, [_vm._v(_vm._s(_vm.address2.website))])]), _c('p', [_c('a', {
    attrs: {
      "href": `mailto:${_vm.address1.email}`
    }
  }, [_vm._v(_vm._s(_vm.address2.email))])])]) : _vm._e()])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }