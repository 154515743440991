var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "main-content bg-light"
  }, [_vm._m(0), _c('section', {
    staticClass: "login-form section bg-light"
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.busy,
      "no-wrap": ""
    },
    scopedSlots: _vm._u([{
      key: "overlay",
      fn: function () {
        return [_c('b-spinner', {
          attrs: {
            "label": "Loading..."
          }
        })];
      },
      proxy: true
    }])
  }), _c('div', {
    staticClass: "container bg-white"
  }, [_c('div', {
    staticClass: "login-container"
  }, [_c('div', {
    staticClass: "row justify-content-center"
  }, [_vm._m(1), _vm.form.currentLoginState == _vm.LoginState.LOGIN ? _c('div', {
    staticClass: "col-lg-6"
  }, [_c('form', {
    staticClass: "form-body",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
      }
    }
  }, [_vm._m(2), _c('div', {
    staticClass: "mt-4"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('b-form-input', {
    attrs: {
      "type": "text",
      "state": _vm.validateState('email'),
      "placeholder": "Enter Email",
      "required": "required",
      "autocomplete": "username"
    },
    model: {
      value: _vm.form.email,
      callback: function ($$v) {
        _vm.$set(_vm.form, "email", $$v);
      },
      expression: "form.email"
    }
  }), _c('b-form-invalid-feedback', {
    attrs: {
      "id": "input-phone-feedback"
    }
  }, [_vm._v(" Email is required ")])], 1)]), _c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('b-form-input', {
    attrs: {
      "type": "password",
      "state": _vm.validateState('password'),
      "name": "password",
      "placeholder": "Enter Password",
      "required": "required",
      "autocomplete": "current-password"
    },
    model: {
      value: _vm.form.password,
      callback: function ($$v) {
        _vm.$set(_vm.form, "password", $$v);
      },
      expression: "form.password"
    }
  }), _c('b-form-invalid-feedback', {
    attrs: {
      "id": "input-phone-feedback"
    }
  }, [_vm._v(" Password is required ")])], 1)])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-sm-12 text-center"
  }, [!!_vm.error ? _c('div', {
    staticClass: "alert alert-danger"
  }, [_vm._v(" " + _vm._s(_vm.error) + " ")]) : _vm._e(), _c('button', {
    staticClass: "btn theme-btn rounded w-100",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.login
    }
  }, [_vm._v(" Login ")])]), _c('div', {
    staticClass: "col-sm-12 mt-5"
  }, [_c('div', {
    staticClass: "lost-password text-center"
  }, [_c('span', [_c('a', {
    staticClass: "theme-light-color",
    attrs: {
      "href": "#"
    },
    on: {
      "click": _vm.onPasswdReset
    }
  }, [_vm._v(" Forgot username or password?")])]), _c('router-link', {
    attrs: {
      "to": {
        name: 'signup'
      }
    }
  }, [_c('a', {
    staticClass: "d-block mt-2 theme-light-color"
  }, [_vm._v("Create Account")])])], 1)])])])])]) : _vm._e(), _vm.form.currentLoginState == _vm.LoginState.SEND_EMAIL_CODE ? _c('div', {
    staticClass: "col-lg-6"
  }, [_c('form', {
    staticClass: "form-body",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
      }
    }
  }, [_vm._m(3), _c('div', {
    staticClass: "mt-4"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('b-form-input', {
    attrs: {
      "type": "text",
      "state": _vm.validateState('email'),
      "placeholder": "Enter Email",
      "required": "required",
      "autocomplete": "username"
    },
    model: {
      value: _vm.form.email,
      callback: function ($$v) {
        _vm.$set(_vm.form, "email", $$v);
      },
      expression: "form.email"
    }
  }), _c('b-form-invalid-feedback', {
    attrs: {
      "id": "input-phone-feedback"
    }
  }, [_vm._v(" Email is required ")])], 1)])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-sm-12 text-center"
  }, [!!_vm.error ? _c('div', {
    staticClass: "alert alert-danger"
  }, [_vm._v(" " + _vm._s(_vm.error) + " ")]) : _vm._e(), _c('button', {
    staticClass: "btn theme-btn rounded w-100",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.sendResetCode
    }
  }, [_vm._v(" Email Code ")])])])])])]) : _vm._e(), _vm.form.currentLoginState == _vm.LoginState.RESET_PASSWORD ? _c('div', {
    staticClass: "col-lg-6"
  }, [_c('form', {
    staticClass: "form-body",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
      }
    }
  }, [_vm._m(4), _c('div', {
    staticClass: "mt-4"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('b-form-input', {
    attrs: {
      "type": "text",
      "state": _vm.validateState('email'),
      "placeholder": "Enter username",
      "required": "required",
      "autocomplete": "username"
    },
    model: {
      value: _vm.form.email,
      callback: function ($$v) {
        _vm.$set(_vm.form, "email", $$v);
      },
      expression: "form.email"
    }
  }), _c('b-form-invalid-feedback', {
    attrs: {
      "id": "input-phone-feedback"
    }
  }, [_vm._v(" Email is Required ")])], 1)]), _c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('b-form-input', {
    attrs: {
      "type": "number",
      "state": _vm.validateState('code'),
      "placeholder": "Enter Code",
      "required": "required",
      "autocomplete": "username"
    },
    model: {
      value: _vm.form.code,
      callback: function ($$v) {
        _vm.$set(_vm.form, "code", $$v);
      },
      expression: "form.code"
    }
  }), _c('b-form-invalid-feedback', {
    attrs: {
      "id": "input-phone-feedback"
    }
  }, [_vm._v(" Verification Code is Required (sent to your email) ")])], 1)]), _c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('b-form-input', {
    attrs: {
      "type": "password",
      "state": _vm.validateState('password'),
      "name": "password",
      "placeholder": "Enter Password",
      "required": "required",
      "autocomplete": "current-password"
    },
    model: {
      value: _vm.form.password,
      callback: function ($$v) {
        _vm.$set(_vm.form, "password", $$v);
      },
      expression: "form.password"
    }
  }), !_vm.validateState('password') ? _c('div', {
    staticClass: "invalid-feedback"
  }, [!_vm.$v.form.password.required ? _c('div', {
    staticClass: "error"
  }, [_vm._v(" Password is required ")]) : _vm._e(), !_vm.$v.form.password.goodPassword ? _c('div', {
    staticClass: "error"
  }, [_vm._v(" Must be at least 8 characters and contain a lowercase, uppercase, number and special character. ")]) : _vm._e()]) : _vm._e(), _vm.$v.form.password.goodPassword ? _c('small', {
    staticClass: "form-text text-muted",
    attrs: {
      "id": "passwordHelpBlock"
    }
  }, [_vm._v(" Must be at least 8 characters and contain a lowercase, uppercase, number and special character. ")]) : _vm._e()], 1)])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-sm-12 text-center"
  }, [!!_vm.error ? _c('div', {
    staticClass: "alert alert-danger"
  }, [_vm._v(" " + _vm._s(_vm.error) + " ")]) : _vm._e(), _c('button', {
    staticClass: "btn theme-btn rounded w-100",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.passwordreset
    }
  }, [_vm._v(" Reset Password ")]), _c('b-alert', {
    staticClass: "mt-1",
    attrs: {
      "variant": _vm.dismissableAlertVariant,
      "dismissible": "",
      "fade": "",
      "show": _vm.showDismissibleAlert
    },
    on: {
      "dismissed": function ($event) {
        _vm.showDismissibleAlert = false;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.dismissableAlertText) + " ")])], 1)])])])]) : _vm._e()])])])], 1)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "inner-header-bg"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "section-breadcrumb"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12 justify-content-center align-self-center pb-2"
  }, [_c('div', {
    staticClass: "breadcrumb-content"
  }, [_c('div', {
    staticClass: "title-section"
  }, [_c('h1', {
    staticClass: "title text-center text-white"
  }, [_vm._v("Login")])]), _c('div', {
    staticClass: "breadcrumb-nav"
  }, [_c('ul', {
    staticClass: "breadcrumb-link text-center list-unstyled mb-0"
  }, [_c('li', {
    staticClass: "text-white"
  }, [_c('i', {
    staticClass: "mdi mdi-home theme-light-color pr-2"
  }), _c('a', {
    attrs: {
      "href": "/#/"
    }
  }, [_vm._v("Home")])]), _c('li', {
    staticClass: "theme-light-color active"
  }, [_vm._v("Login")])])])])])])])])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-none d-lg-flex col-lg-6 form-thumbnail p-5"
  }, [_c('div', {
    staticClass: "thumboverlay"
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-center section-title"
  }, [_c('h2', {
    staticClass: "text-center"
  }, [_vm._v("Login")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-center section-title"
  }, [_c('h2', {
    staticClass: "text-center"
  }, [_vm._v("Password Reset")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-center section-title"
  }, [_c('h2', {
    staticClass: "text-center"
  }, [_vm._v("Password Reset")])]);

}]

export { render, staticRenderFns }