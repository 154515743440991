var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "main-content bg-light"
  }, [_vm._m(0), _c('section', {
    staticClass: "login-form section bg-light"
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.busy,
      "no-wrap": ""
    },
    scopedSlots: _vm._u([{
      key: "overlay",
      fn: function () {
        return [_c('b-spinner', {
          attrs: {
            "label": "Loading..."
          }
        })];
      },
      proxy: true
    }])
  }), _c('div', {
    staticClass: "container bg-white"
  }, [_c('div', {
    staticClass: "login-container"
  }, [_c('div', {
    staticClass: "row justify-content-center"
  }, [_vm._m(1), _c('div', {
    staticClass: "col-lg-6"
  }, [_c('form', {
    staticClass: "form-body",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
      }
    }
  }, [_vm._m(2), _c('div', {
    staticClass: "mt-4"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('p', [_vm._v(" Please update your account password. Enter your old and new passwords below. ")]), _c('b-col', {
    staticClass: "p-0",
    attrs: {
      "sm": "12"
    }
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "email"
    }
  }, [_vm._v("Your Email")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.email,
      expression: "form.email"
    }],
    staticClass: "form-control",
    class: _vm.hasError('email') ? 'is-invalid' : '',
    attrs: {
      "type": "email",
      "placeholder": "Email Address"
    },
    domProps: {
      "value": _vm.form.email
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.form, "email", $event.target.value);
      }
    }
  }), _vm.hasError('email') ? _c('div', {
    staticClass: "invalid-feedback"
  }, [!_vm.$v.form.email.required ? _c('div', {
    staticClass: "error"
  }, [_vm._v(" Email address is required ")]) : _vm._e(), !_vm.$v.form.email.email ? _c('div', {
    staticClass: "error"
  }, [_vm._v(" Should be in email format ")]) : _vm._e()]) : _vm._e()])]), _c('b-col', {
    staticClass: "p-0",
    attrs: {
      "sm": "12"
    }
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "oldPassword"
    }
  }, [_vm._v("Current Password")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.oldPassword,
      expression: "form.oldPassword"
    }],
    staticClass: "form-control",
    class: _vm.hasError('oldPassword') ? 'is-invalid' : '',
    attrs: {
      "type": "password",
      "placeholder": "Current Password",
      "autocomplete": "password"
    },
    domProps: {
      "value": _vm.form.oldPassword
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.form, "oldPassword", $event.target.value);
      }
    }
  }), _vm.hasError('oldPassword') ? _c('div', {
    staticClass: "invalid-feedback"
  }, [!_vm.$v.form.oldPassword.required ? _c('div', {
    staticClass: "error"
  }, [_vm._v(" Your current password is required ")]) : _vm._e()]) : _vm._e()])]), _c('b-col', {
    staticClass: "p-0",
    attrs: {
      "sm": "12"
    }
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "password"
    }
  }, [_vm._v("Password")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.password,
      expression: "form.password"
    }],
    staticClass: "form-control",
    class: _vm.hasError('password') ? 'is-invalid' : '',
    attrs: {
      "type": "password",
      "placeholder": "Password",
      "autocomplete": "password"
    },
    domProps: {
      "value": _vm.form.password
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.form, "password", $event.target.value);
      }
    }
  }), _vm.hasError('password') ? _c('div', {
    staticClass: "invalid-feedback"
  }, [!_vm.$v.form.password.required ? _c('div', {
    staticClass: "error"
  }, [_vm._v(" A new password is required ")]) : _vm._e(), !_vm.$v.form.password.goodPassword ? _c('div', {
    staticClass: "error"
  }, [_vm._v(" Must be at least 8 characters and contain a lowercase, uppercase, number and special character. ")]) : _vm._e()]) : _vm._e(), !_vm.hasError('password') ? _c('small', {
    staticClass: "form-text text-muted",
    attrs: {
      "id": "passwordHelpBlock"
    }
  }, [_vm._v(" Must be at least 8 characters and contain a lowercase, uppercase, number and special character. ")]) : _vm._e()])]), _c('b-col', {
    staticClass: "p-0",
    attrs: {
      "sm": "12"
    }
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "confirmPassword"
    }
  }, [_vm._v("Confirm Password")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.confirmPassword,
      expression: "form.confirmPassword"
    }],
    staticClass: "form-control",
    class: _vm.hasError('confirmPassword') ? 'is-invalid' : '',
    attrs: {
      "type": "password",
      "placeholder": "Confirm Password",
      "autocomplete": "password"
    },
    domProps: {
      "value": _vm.form.confirmPassword
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.form, "confirmPassword", $event.target.value);
      }
    }
  }), _vm.hasError('confirmPassword') ? _c('div', {
    staticClass: "invalid-feedback"
  }, [!_vm.$v.form.confirmPassword.required ? _c('div', {
    staticClass: "error"
  }, [_vm._v(" Please confirm your new password ")]) : _vm._e(), _vm.$v.form.confirmPassword.required && !_vm.$v.form.confirmPassword.sameAs ? _c('div', {
    staticClass: "error"
  }, [_vm._v(" Please re-enter the password given above ")]) : _vm._e()]) : _vm._e()])])], 1)])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-sm-12 text-center"
  }, [!!_vm.error ? _c('div', {
    staticClass: "alert alert-danger"
  }, [_vm._v(" " + _vm._s(_vm.error) + " ")]) : _vm._e(), !!_vm.info & !_vm.error ? _c('div', {
    staticClass: "alert alert-success"
  }, [_vm._v(" " + _vm._s(_vm.info) + " ")]) : _vm._e(), _c('button', {
    staticClass: "btn theme-btn rounded w-100",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v(" Update Password ")])]), _vm._m(3)])])])])])])])], 1)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "inner-header-bg"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "section-breadcrumb"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12 justify-content-center align-self-center pb-2"
  }, [_c('div', {
    staticClass: "breadcrumb-content"
  }, [_c('div', {
    staticClass: "title-section"
  }, [_c('h1', {
    staticClass: "title text-center text-white"
  }, [_vm._v(" Update Password ")])]), _c('div', {
    staticClass: "breadcrumb-nav"
  }, [_c('ul', {
    staticClass: "breadcrumb-link text-center list-unstyled mb-0"
  }, [_c('li', {
    staticClass: "text-white"
  }, [_c('i', {
    staticClass: "mdi mdi-home theme-light-color pr-2"
  }), _c('a', {
    attrs: {
      "href": "index.html"
    }
  }, [_vm._v("Home")])]), _c('li', {
    staticClass: "text-white"
  }, [_c('a', {
    attrs: {
      "href": "/login"
    }
  }, [_vm._v("Login")])]), _c('li', {
    staticClass: "theme-light-color active"
  }, [_vm._v("Update Password")])])])])])])])])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-none d-lg-block col-lg-6 form-thumbnail"
  }, [_c('div', {
    staticClass: "thumboverlay"
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-center section-title"
  }, [_c('h2', {
    staticClass: "text-center"
  }, [_vm._v("Update Password")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-sm-12 mt-5"
  }, [_c('div', {
    staticClass: "lost-password text-center"
  }, [_c('span', [_c('a', {
    staticClass: "theme-light-color",
    attrs: {
      "href": "#"
    }
  }, [_vm._v(" Don't have an account? Create one")])])])]);

}]

export { render, staticRenderFns }