<template>
  <div class="main-content">
    <!-- BradCrumb header Start-->
    <section class="inner-header-bg">
      <div class="container">
        <!-- Breadcrumb Start-->
        <div class="section-breadcrumb contact-breadcrumb">
          <div class="container">
            <div class="row">
              <div
                class="col-md-12 justify-content-center align-self-center pb-2"
              >
                <div class="breadcrumb-content">
                  <div class="title-section">
                    <h1 class="title text-center text-white">Feedback</h1>
                  </div>
                  <div class="breadcrumb-nav">
                    <ul class="breadcrumb-link text-center list-unstyled mb-0">
                      <li class="text-white">
                        <i class="mdi mdi-home theme-light-color pr-2"></i>
                        <a href="/#/">Home</a>
                      </li>
                      <li class="theme-light-color active">Feedback</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Breadcrumb End -->
      </div>
    </section>
    <!-- BradCrumb header End -->
    <!-- Body Area Start-->
    <section class="section pt-5">
      <div class="container" style="min-height: 700px">
        <!-- surveySet - used to track when survey is ready to render -->
        <!-- <h5>PTAP Feedback</h5> -->
        <p>
          APEX Accelerators are non-profit, government, or tribal entities who received a DoD cooperative agreement award. Congress appropriates funding for this program to assist businesses in obtaining information and training related to contracting and subcontracting opportunities with DoD, other Federal agencies, and State and local governments. APEX Accelerators are expected to help businesses participate in government contracting but they are not allowed to do the work on behalf of the business. APEX Accelerators will keep assisting in the form of such services as helping prepare bids/proposals, marketing to potential buyers, setting up or improving quality assurance and accounting systems, complying with cybersecurity requirements, and facilitating education of small businesses on the Foreign Ownership, Control, and Influence (FOCI) risks and mitigation strategy. APEX Accelerators welcome your feedback on our services.
        </p>
        <div id="surveyContainer" class="row surveyDiv">
          <!-- If you want to hide survey, comment the lines below -->
          <survey :survey="survey"></survey>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import * as SurveyVue from "survey-vue";
import "survey-vue/survey.min.css";
import axios from "axios";
var Survey = SurveyVue.Survey;
export default {
  components: {
    Survey,
  },
  data() {
    return {
      survey: {},
    };
  },
  mounted() {
    this.setSurvey();
  },
  methods: {
    init() {
      /* The footer height no longer needs to be set */
      this.initFooterHeight();
    },
    setSurvey() {
      var surveyJSON = {
        title: "Feedback Form",
        logoPosition: "right",
        pages: [
          {
            name: "page1",
            elements: [
              {
                type: "radiogroup",
                name: "receivedPTACService",
                title:
                  "Do you receive the services of an APEX Accelerator?",
                choices: [
                  {
                    value: "Yes",
                    text: "Yes",
                  },
                  {
                    value: "No",
                    text: "No",
                  },
                ],
                isRequired: true,
              },
              {
                type: "dropdown",
                name: "location",
                title:
                  "Please select the location of the APEX Accelerator where you requested services from the dropdown box.",
                choices: [
                "Alaska-Alaska APEX Accelerator-Anchorage Center",
"Alaska-Alaska APEX Accelerator-Fairbanks Center",
"Alabama-Jacksonville State University APEX Accelerator, Jacksonville",
"Alabama-Alabama State University APEX Acclerator, Montgomery",
"Alabama-Mobile APEX Accelerator",
"Alabama-Northwest Alabama APEX Accelerator, Florence",
"Alabama-University of Alabama in Huntsville APEX Accelerator, Huntsville",
"Alabama-Wiregrass APEX Accelerator, Troy",
"Alabama-APEX Accelerator of Alabama",
"Arkansas-Arkansas APEX Accelerator",
"Arizona-American Indian Chamber Education Fund APEX Accelerator Subcenter",
"Arizona-Arizona APEX Accelerator/Glendale Community College",
"Arizona-Arizona APEX Accelerator-Phoenix",
"Arizona-Arizona APEX Accelerator-Sierra Vista",
"Arizona-Arizona APEX Accelerator-Yuma",
"Arizona-The National Center American Indian APEX Accelerator Arizona / Navajo Nation BIA Region", 
"California-American Indian Chamber Education Fund APEX Accelerator",
"California-California Capital APEX Accelerator",
"California-Golden State APEX Accelerator",
"California-Los Angeles County APEX Accelerator",
"California-Monterey Bay APEX Accelerator",
"California-Norcal APEX Accelerator",
"California-Riverside Community College District APEX Accelerator",
"California-San Diego, Orange, Imperial APEX Accelerator",
"Colorado-Colorado APEX Accelerator",
"Colorado-Colorado APEX Accelerator - Aurora Satellite Office",
"Colorado-Colorado APEX Accelerator - Golden Office",
"Colorado-Colorado APEX Accelerator - Larimer Workforce Center",
"Colorado-Colorado APEX Accelerator - Westminster Satellite Office",
"Colorado-The National Center American Indian APEX Accelerator - Colorado",
"Connecticut-Connecticut APEX Accelerator",
"Connecticut-Connecticut APEX Accelerator - Rocky Hill Office",
"Connecticut-Connecticut APEX Accelerator - Stamford Office",
"DC-DC Department of Small and Local Business Development",
"Delaware-University of Delaware APEX Accelerator",
"Florida-Florida APEX Accelerator - Manager's Office",
"Florida-Florida APEX Accelerator at Florida A&M University - Tallahassee Office",
"Florida-Florida APEX Accelerator at Florida Atlantic University - Boca Raton Office",
"Florida-Florida APEX Accelerator at Florida Gulf Coast University - Fort Myers Office",
"Florida-Florida APEX Accelerator at Florida International University - Miami Office",
"Florida-Florida APEX Accelerator at Indian River State College - Fort Pierce Office",
"Florida-Florida APEX Accelerator at University of Central Florida - Orlando Office",
"Florida-Florida APEX Accelerator at University of North Florida - Jacksonville Office",
"Florida-Florida APEX Accelerator at University of South Florida - Clearwater Office",
"Florida-Florida APEX Accelerator at University of South Florida - Tampa Office",
"Florida-Florida APEX Accelerator at University of West Florida - Pensacola Office",
"Florida-Florida APEX Accelerator, Assisting Florida-Based Businesses Since 1986",
"Georgia-Georgia Tech Procurement Assistance Center - Albany Office",
"Georgia-Georgia Tech Procurement Assistance Center - Augusta Office",
"Georgia-Georgia Tech Procurement Assistance Center - Carrollton Office",
"Georgia-Georgia Tech Procurement Assistance Center - Columbus Office",
"Georgia-Georgia Tech Procurement Assistance Center - Savannah Office",
"Georgia-Georgia Tech Procurement Assistance Center - Warner Robins Office",
"Georgia-Georgia Tech Procurement Assistance Center - Athens Office",
"Georgia-Georgia Tech Procurement Assistance Center - Atlanta Office",
"Georgia-Georgia Tech Procurement Assistance Center - Gainesville Office",
"Georgia-The National Center American Indian APEX Accelerator Georgia / Eastern BIA Region",
"Hawaii-Hawaii APEX Accelerator",
"Iowa-Ames Office",
"Iowa-Cedar Rapids Office",
"Iowa-Des Moines Office",
"Iowa-Iowa City Office",
"Iowa-Iowa State University APEX Accelerator",
"Iowa-The PEDCO APEX Accelerator",
"Idaho-Idaho APEX Accelerator",
"Illinois-Illinois APEX Accelerator @ Bradley University",
"Illinois-Illinois APEX Accelerator @ College of DuPage",
"Illinois-Illinois APEX Accelerator @ Far South Community Development Corp.",
"Illinois-Illinois APEX Accelerator @ Greater Southwest Development Corp.",
"Illinois-Illinois APEX Accelerator @ Illinois Hispanic Chamber of Commerce",
"Illinois-Illinois APEX Accelerator @ Joseph Center",
"Illinois-Illinois APEX Accelerator @ Southern Illinois University - Carbondale",
"Illinois-Illinois APEX Accelerator @ Western Illinois University",
"Illinois-Illinois APEX Accelerator @ Women's Business Development Center",
"Illinois-Illinois APEX Accelerator",
"Indiana-Indiana APEX Accelerator",
"Indiana-Indiana APEX Accelerator - Northeast",
"Indiana-Indiana APEX Accelerator - Northwest",
"Indiana-Indiana APEX Accelerator - South Central",
"Indiana-Indiana APEX Accelerator - Southwest",
"Kansas-Kansas City Area Subcenter APEX Accelerator",
"Kansas-Kansas APEX Accelerator",
"Kentucky-Kentucky APEX Accelerator",
"Kentucky-Kentucky APEX Accelerator - Bowling Green Office",
"Kentucky-Kentucky APEX Accelerator - Paducah Office",
"Kentucky-Kentucky APEX Accelerator - Somerset Office",
"Louisiana-Louisiana APEX Accelerator",
"Louisiana-NW Louisiana APEX Accelerator",
"Louisiana-The National Center American Indian APEX Accelerator - Louisiana",
"Massachusetts-Boston Area APEX Accelerator",
"Massachusetts-Central Massachusetts Area APEX Accelerator",
"Massachusetts-Fall River - Southeastern Cape & Islands Area APEX Accelerator",
"Massachusetts-Lowell - Merrimack Valley Area APEX Accelerator",
"Massachusetts-Massachusetts APEX Accelerator",
"Massachusetts-Salem - North Shore Area APEX Accelerator",
"Massachusetts-Springfield - APEX Accelerator Program Manager Office",
"Maryland-Maryland APEX Accelerator",
"Maine APEX Accelerator - Central c/o Eastern Maine Development Corporation",
"Maine APEX Accelerator / Downeast c/o Sunrise County Economic Council (SCEC)",
"Maine APEX Accelerator - Midstate c/o Eastern Maine Development Corporation",
"Maine-Maine APEX Accelerator - North c/o Northern Maine Development Commission",
"Maine APEX Accelerator - Southwest c/o Eastern Maine Development Corporation",
"Michigan-Flint & Genesee APEX Accelerator",
"Michigan-Macomb Regional APEX Accelerator",
"Michigan-Michigan - Saginaw Future Inc",
"Michigan-Michigan - Wayne State University",
"Michigan-Muskegon Area First  APEX Accelerator",
"Michigan-Networks Northwest APEX Accelerator",
"Michigan-Northeast Michigan Consortium APEX Accelerator",
"Michigan-APEX Accelerator of South Central Michigan",
"Michigan-Schoolcraft College APEX Accelerator",
"Michigan-SW Michigan APEX Accelerator",
"Minnesota-Minnesota APEX Accelerator",
"Missouri-Missouri APEX Accelerator",
"Missouri-Missouri APEX Accelerator Columbia 1",
"Missouri-Missouri APEX Accelerator Columbia 2",
"Missouri-Missouri APEX Accelerator Kansas City",
"Missouri-Missouri APEX Accelerator Springfield",
"Missouri-Missouri APEX Accelerator St. Louis",
"Missouri-Missouri APEX Accelerator St. Louis County",
"Missouri-Missouri APEX Accelerator West Plains",
"Mississippi-Mississippi APEX Accelerator",
"Mississippi-NE Mississippi Contract Procurement Center",
"Mississippi-Northwest Mississippi Contract Procurement Center",
"Mississippi-South Mississippi Contract Procurement Center",
"Montana-Great Falls Development Authority",
"Montana-Montana Procurement Technical Assistance Program -Big Sky Economic Development Authority",
"Montana-Montana West and Flathead County Economic Development",
"Montana-Native American Development Corporation APEX Accelerator",
"Montana-Snowy Mountain Development Corporation",
"Montana-Southwest Montana Big Sky EDA",
"Montana-University of Montana, Office of Research and Creative Scholarship",
"North Carolina-Appalachian State University",
"North Carolina-Fayetteville State University",
"North Carolina-North Carolina State University",
"North Carolina-Statewide North Carolina Government Contracting Assistance Program (GCAP) - A Non-DLA Funded APEX Accelerator Program",
"North Carolina-University of North Carolina - Charlotte",
"North Carolina-Western Carolina University",
"North Dakota-Bismarck Office",
"North Dakota-Fargo Office",
// "North Dakota-Minot Office",
"North Dakota-North Dakota APEX Accelerator",
"Nebraska-NBDC APEX Accelerator",
"Nebraska-NBDC APEX Accelerator Norfolk Chamber",
"Nebraska-NBDC APEX Accelerator Southeast Community College",
"Nebraska-NBDC APEX Accelerator UNK College of Business",
"Nebraska-Nebraska Indian Community College APEX Accelerator",
"New Hampshire-New Hampshire APEX Accelerator",
"New Jersey-NJIT  APEX Accelerator",
"New Jersey-Union County Economic Development Corp. APEX Accelerator",
"New Mexico-Albuquerque APEX Accelerator",
"New Mexico-Clovis APEX Accelerator",
"New Mexico-Las Cruces APEX Accelerator",
"New Mexico-New Mexico APEX Accelerator",
"New Mexico-Santa Fe APEX Accelerator",
"New Mexico-The National Center American Indian APEX Accelerator New Mexico / Southwest BIA Region",
"Nevada-Nevada APEX Accelerator, Procurement Outreach Program",
"New York-Cattaraugus County APEX Accelerator",
"New York-City of New York - Department of Small Business Services APEX Accelerator",
"New York-IgniteU New York APEX Accelerator",
"New York-LaGuardia Community College APEX Accelerator",
"New York-Monroe County Finger Lakes APEX Accelerator",
"New York-South Bronx Overall Economic Development Corporation (SOBRO) APEX Accelerator",
"New York-The National Center American Indian APEX Accelerator - New York",
"Ohio-North West Ohio APEX Accelerator at Rhodes State College",
"Ohio-Ohio APEX Accelerator at Youngstown State University",
"Ohio-Ohio University APEX Accelerator at Akron",
"Ohio-Ohio University APEX Accelerator at Athens",
"Ohio-Ohio University APEX Accelerator at Cincinnati",
"Ohio-Ohio University APEX Accelerator at Cleveland",
"Ohio-Ohio University APEX Accelerator at Columbus",
"Ohio-Ohio University APEX Accelerator at Dayton",
"Ohio-Ohio University APEX Accelerator at Lakeland Community College",
"Ohio-Ohio University APEX Accelerator at Pickerington",
"Ohio-Southern Ohio APEX Accelerator",
"Ohio-State of Ohio APEX Accelerator Program",
"Oklahoma-Autry Technology Center",
"Oklahoma-Indian Capital Technology Center",
"Oklahoma-Oklahoma - Eastern Oklahoma County Technology Center",
"Oklahoma-Oklahoma - Gordon Cooper Technology Center",
"Oklahoma-Oklahoma - Great Plains Technology Center",
"Oklahoma-Oklahoma - Kiamichi Technology Center",
"Oklahoma-Oklahoma - Metro Tech Centers",
"Oklahoma-Oklahoma - Pioneer Technology Center",
"Oklahoma-Oklahoma - Red River Technology Center",
"Oklahoma-Oklahoma - Southwest Technology  Center",
"Oklahoma-Oklahoma - Tulsa Technology Center - BIS Training Center Lemley West",
"Oklahoma-Oklahoma APEX Accelerator",
"Oklahoma-Tribal Government Institute APEX Accelerator",
"Oregon-American Indian Chamber Education Fund APEX Accelerator Subcenter - Oregon Office",
"Oregon-Government Contract Assistance Program (GCAP)  - Springfield",
"Oregon-Government Contract Assistance Program (GCAP) - Central/Eastern Oregon",
"Oregon-Government Contract Assistance Program (GCAP) - Portland",
"Oregon-Government Contract Assistance Program (GCAP) - Southern Oregon",
"Oregon-Government Contract Assistance Program (GCAP) - Southwestern Oregon",
"Oregon-Northwest Native APEX Accelerator",
"Oregon-Oregon APEX Accelerator",
"Pennsylvania-GCAC Slippery Rock University",
"Pennsylvania-Government Agency Coordination Office (GACO) PennWest University",
"Pennsylvania-JARI APEX Accelerator",
"Pennsylvania-Kutztown University -  Philadelphia",
"Pennsylvania-Kutztown University - Berks",
"Pennsylvania-Lehigh University (Northampton, Upper Bucks, Upper Montgomery, Lehigh)",
"Pennsylvania-North Central Pennsylvania Regional Planning & Development Commission",
"Pennsylvania-Northeastern Pennsylvania Alliance APEX Accelerator",
"Pennsylvania-Northern Tier Regional Planning & Development Commission",
"Pennsylvania-Northwest Commission APEX Accelerator",
"Pennsylvania-SEDA-COG APEX Accelerator",
"Pennsylvania-Southeast Pennsylvania APEX Accelerator",
"Pennsylvania-Southern Alleghenies Planning & Development Commission APEX Accelerator",
"Pennsylvania-Southwest PA Commission",
"Pennsylvania-Widener University (Chester, Delaware)",
"Puerto Rico-Puerto Rico Federal Contracting Center",
"PT-Guam APEX Accelerator",
"Rhode Island-Rhode Island APEX Accelerator",
"South Carolina-South Carolina APEX Accelerator",
"South Dakota-South Dakota  APEX Accelerator (East River)",
"South Dakota-South Dakota APEX Accelerator",
"South Dakota-South Dakota APEX Accelerator (Pierre)",
"South Dakota-South Dakota APEX Accelerator (West River)",
"South Dakota-South Dakota APEX Accelerator (Yankton)",
"Tennessee-Association of APEX Accelerators",
"Tennessee-University of Tennessee APEX Accelerator",
"Tennessee-UT APEX Accelerator Chattanooga",
"Tennessee-UT APEX Accelerator Jackson",
"Tennessee-UT APEX Accelerator Oak Ridge",
"Texas-Del Mar College APEX Accelerator",
"Texas-El Paso Community College/Contract Opportunities Center",
"Texas-NW Texas APEX Accelerator",
"Texas-Rio South Texas Regional Procurement Assistance Center",
"Texas-University of Houston APEX Accelerator",
"Texas-University of Texas at San Antonio APEX Accelerator",
"Texas-UTA  Cross Timbers Procurement Center",
"Texas-UTSA Institute for Economic Development",
"Utah-Bridgerland Technical College",
"Utah-Davis Technical College",
"Utah-Dixie Technical College",
"Utah-Salt Lake Community College",
"Utah-SUU Business Resource Center",
"Utah-Utah APEX Accelerator",
"Utah-Utah APEX Accelerator - USU Eastern",
"Utah-Utah APEX Accelerator @ Governor's Office of Economic Opportunity",
"Utah-UVU Business Resource Center",
"Virginia-Association of APEX Accelerators - Satellite",
"Virginia-Crater Planning District Commission",
"Virginia-Procurement Counselors",
"Virginia-Southwest VA Community College APEX Accelerator",
"Virginia-Virginia APEX Accelerator",
"Virginia-Virginia APEX Accelerator - Central Virginia Office",
"Virginia-Virginia APEX Accelerator - Hampton Roads Office",
"Virgin Islands-Virgin Islands APEX Accelerator",
"Vermont-Vermont APEX Accelerator - Burlington Office",
"Vermont-Vermont APEX Accelerator - Montpelier Office",
"Vermont-Vermont APEX Accelerator - Newport Office",
"Vermont-Vermont APEX Accelerator - Rutland Office",
"Vermont-Vermont APEX Accelerator - Springfield Office",
"Vermont-VT APEX Accelerator",
"Washington-American Indian Chamber Education Fund APEX Accelerator Subcenter - Washington Office",
"Washington-Economic Alliance Snohomish County: Serving: Snohomish, Skagit & Whatcom Counties",
"Washington-Greater Spokane Inc: Serving Adams, Asotin, Douglas, Ferry, Garfield, Grant, Lincoln, Okanogan, Pend Oreille, Stevens, Spokane, & Whitman Counties",
"Washington-Green River College: Serving King & Pierce Counties",
"Washington-Kitsap Economic Development Alliance: Serving Clallam, Jefferson, Kitsap, & Mason Counties",
"Washington-North Olympic Peninsula APEX Accelerator",
"Washington-SW Washington Vancouver Office: Serving Clark, Cowlitz, & Skamania Counties",
"Washington-Tri City Regional Chamber of Commerce: Serving Benton, Columbia, Franklin, Klickitat, Walla Walla, & Yakima Counties",
"Washington-Washington State APEX Accelerator",
"Wisconsin-Wisconsin Procurement Institute",
"West Virginia-RCAC Eastern Counties",
"West Virginia-RCAC Northern Counties",
"West Virginia-Regional Contracting Assistance Center Inc.",
"West Virginia-Regional Contracting Assistance Center, Inc.-APEX Accelerator",
"Wyoming-Wyoming APEX Accelerator",
"Wyoming-Wyoming APEX Accelerator - Cheyenne",
"Wyoming-Wyoming APEX Accelerator - Laramie"                  
				],
                isRequired: true,
              },
              {
                type: "radiogroup",
                name: "hoursPTAC",
                title:
                  "Approximately how many hours of one-on-one counseling have you received from this APEX Accelerator?",
                isRequired: true,
                choices: [
                  {
                    value: "0",
                    text: "0",
                  },
                  {
                    value: "1-5",
                    text: "1-5",
                  },
                  {
                    value: "6-15",
                    text: "6-15",
                  },
                  {
                    value: "16-80",
                    text: "16-80",
                  },
                  {
                    value: "81+",
                    text: "81+",
                  },
                ],
              },
              {
                type: "radiogroup",
                name: "eventsAttended",
                title:
                  "Approximately how many of this APEX Accelerator’s events have you attended?",
                isRequired: true,
                choices: [
                  {
                    value: "0",
                    text: "0",
                  },
                  {
                    value: "1-5",
                    text: "1-5",
                  },
                  {
                    value: "6-9",
                    text: "6-9",
                  },
                  {
                    value: "10+",
                    text: "10+",
                  },
                ],
              },
              {
                type: "radiogroup",
                name: "satisfaction",
                title:
                  "How would you rate your overall satisfaction with this APEX Accelerator?",
                isRequired: true,
                choices: [
                  {
                    value: "Very Satisfied",
                    text: "Very Satisfied",
                  },
                  {
                    value: "Somewhat Satisfied",
                    text: "Somewhat Satisfied",
                  },
                  {
                    value: "Neutral",
                    text: "Neutral",
                  },
                  {
                    value: "Somewhat Dissatisfied",
                    text: "Somewhat Dissatisfied",
                  },
                  {
                    value: "Very Dissatisfied",
                    text: "Very Dissatisfied",
                  },
                ],
              },
              {
                type: "radiogroup",
                name: "isEmployeeKnowledgeable",
                title:
                  "Were the APEX Acclerator employee(s) you interacted with knowledgeable about government contracting processes and procedures?",
                isRequired: true,
                choices: [
                  {
                    value: "Yes",
                    text: "Yes",
                  },
                  {
                    value: "I did not interact with an APEX Accelerator's employee.",
                    text: "I did not interact with an APEX Accelerator's employee.",
                  },
                ],
                showOtherItem: true,
                otherText:
                  "No (please provide additional feedback in the text box below)",
              },
              {
                type: "radiogroup",
                name: "one-on-one",
                title:
                  "Do you prefer to receive one-on-one counseling face-to-face or virtually via electronic means such as telephone or videochat?",
                isRequired: true,
                choices: [
                  {
                    value: "face-to-face (in person)",
                    text: "face-to-face (in person)",
                  },
                  {
                    value: "virtual",
                    text: "virtual",
                  },
                  {
                    value: "telephone counseling",
                    text: "telephone counseling",
                  },
                ],
              },
              {
                type: "comment",
                name: "feedback",
                title:
                  "Add any other feedback to share with APEX Accelerators in the comment block below:",
              },
              {
              "type": "panel",
              "name": "contact-information",
              "title": "Contact Information",
              "state": "expanded",
              "elements": [
              {
                name: "name",
                title: "Name ",
                type: "text",
                isRequired: true,
                startWithNewLine: false
              },
              {
                name: "emailAddress",
                title: "Email address",
                type: "text",
                inputType: "email",
                isRequired: true,
                startWithNewLine: false
              },
              {
                name: "companyName",
                title: "Company Name (if applicable)",
                type: "text"
              },
              {
                name: "UIENumber",
                title: "UIE Number",
                type: "text",
                startWithNewLine: false
              },
              {
                name: "city",
                title: "City",
                type: "text"
              },
              {
                name: "state",
                title: "State",
                type: "text",
                startWithNewLine: false
              },
             
              {
                name: "phoneNumber",
                title: "Phone number",
                inputType: "tel",
                type: "text",
                startWithNewLine: false
              },
                
              ]
            }
             
            ],
          },
        ],
        completedHtml: "<h3>Thank you for submitting your feedback.</h3>",
      };
      
      var survey = new SurveyVue.Model(surveyJSON);
      this.survey = survey;
      survey.onComplete.add(this.sendDataToServer);
    },
    sendDataToServer(survey) {
      //send Ajax request to your web server
      //alert("The results are: " + JSON.stringify(survey.data));
      axios.post("/api/feedback/", survey.data).then((response) => {
        //alert ("done posting")
      });
    },
  },
};
</script>

<style>
.sv_main {
    font-family: Rajdhani,sans-serif !important;
}
</style>