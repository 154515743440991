<template>
    <div class="main-content">
      <!-- BradCrumb header Start-->
      <section class="inner-header-bg">
        <div class="container">
          <!-- Breadcrumb Start-->
          <div class="section-breadcrumb contact-breadcrumb">
            <div class="container">
              <div class="row">
                <div
                  class="col-md-12 justify-content-center align-self-center pb-2"
                >
                  <div class="breadcrumb-content">
                    <div class="title-section">
                      <h1 class="title text-center text-white">Knowledge Check Questions: HHS</h1>
                    </div>
                    <div class="breadcrumb-nav">
                      <ul class="breadcrumb-link text-center list-unstyled mb-0">
                        <li class="text-white">
                          <i class="mdi mdi-home theme-light-color pr-2"></i>
                          <a href="/#/">Home</a>
                        </li>
                        <li class="theme-light-color active">Knowledge check</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Breadcrumb End -->
        </div>
      </section>
      <!-- BradCrumb header End -->
      <!-- Body Area Start-->
      <section class="section pt-5">
        <div class="container" style="min-height: 700px">
          <!-- surveySet - used to track when survey is ready to render -->
          <!-- <h5>PTAP Feedback</h5> -->
          <!-- <p v-if="captionVisible">Thank you for attending an APEX Accelerators Training! We value your input so that we can make our training effective and valuable. Please complete the questions below to let us know how we did.</p> -->
          <div id="surveyContainer" class="row surveyDiv">
            <!-- If you want to hide survey, comment the lines below -->
            <h3>Knowledge Check Questions: How to Do Business with the Department of Health and Human Services.</h3>
            <survey :survey="survey"></survey>
          </div>
        </div>
      </section>
    </div>
  </template>
  
  <script>
  import * as SurveyVue from "survey-vue";
  import "survey-vue/survey.min.css";
  import axios from "axios";
  var Survey = SurveyVue.Survey;
  export default {
    components: {
      Survey,
    },
    data() {
      return {
        survey: {},
        captionVisible: true
      };
    },
    mounted() {
      this.setSurvey();
    },
    methods: {
      init() {
        /* The footer height no longer needs to be set */
        this.initFooterHeight();
      },
      setSurvey() {
        var surveyJSON = {
          title: "Survey",
          logoPosition: "right",
          pages: [
            {
              name: "page1",
              elements: [
                {
                  type: "radiogroup",
                  name: "NumberOfOperatingDivisions",
                  title: "How many operating divisions does HHS have?",
                  choices: [
                    {
                      value: "10",
                      text: "10",
                    },
                    {
                      value: "9",
                      text: "9",
                    },
                    {
                      value: "13",
                      text: "13",
                    },
                    {
                      value: "14",
                      text: "14",
                    }
                  ],
                  isRequired: true,
                },
                {
                  type: "radiogroup",
                  name: "HHS_OSDBUDivision",
                  title: "Which OSDBU Division are the HHS OSDBU small business professionals under?",
                  choices: [
                    {
                      value: "Operations",
                      text: "Operations",
                    },
                    {
                      value: "Policy",
                      text: "Policy",
                    },
                    {
                      value: "Administration",
                      text: "Administration",
                    },
                    {
                      value: "Outreach",
                      text: "Outreach",
                    },
                  ],
                  isRequired: true,
                },
                {
                  type: "radiogroup",
                  name: "TopNAICSCode2023",
                  title: "What was the top Small Business NAICS code in Fiscal Year 2023?",
                  choices: [
                    {
                      value: "541714",
                      text: "541714",
                    },
                    {
                      value: "236220",
                      text: "236220",
                    },
                    {
                      value: "325413",
                      text: "325413",
                    },
                    {
                      value: "541512",
                      text: "541512",
                    }
                  ],
                  isRequired: true,
                },
                {
                  type: "radiogroup",
                  name: "LargestSpendSocioEconomicCategory",
                  title: "Which small business socioeconomic category had the largest amount of spend in Fiscal Year 2023?",
                  choices: [
                    {
                      value: "SDB",
                      text: "SDB",
                    },
                    {
                      value: "WOSB",
                      text: "WOSB",
                    },
                    {
                      value: "SDVOSB",
                      text: "SDVOSB"
                    }, {
                      value: "HUBZone",
                      text: "HUBZone"
                    }
                  ],
                  isRequired: true,
                },
                {
                  type: "radiogroup",
                  name: "NewPortalName",
                  title: "What is the name of the new portal on the Small Business Customer Experience focused on subcontracting opportunities?",
                  choices: [
                    {
                      value: "Subcontracting Success",
                      text: "Subcontracting Success",
                    },
                    {
                      value: "Partnership Place",
                      text: "Partnership Place",
                    },
                    {
                      value: "Teaming Partner",
                      text: "Teaming Partner",
                    },
                    {
                      value: "Teaming Zone",
                      text: "Teaming Zone",
                    },
                  ],
                  isRequired: true,
                },
                {
                  type: "radiogroup",
                  name: "NewEntrantsOutreachEventName",
                  title: "What is the name of the brand-new outreach event hosted by the HHS OSDBU specifically for new entrants?",
                  choices: [
                    {
                      value: "Office Hours",
                      text: "Office Hours",
                    },
                    {
                      value: "Vendor Engagement Sessions",
                      text: "Vendor Engagement Sessions",
                    },
                    {
                      value: "HHS 101",
                      text: "HHS 101",
                    },
                    {
                      value: "G.R.O.W with HHS",
                      text: "G.R.O.W with HHS",
                    }
                  ],
                  isRequired: true,
                },
                {
                  type: "radiogroup",
                  name: "CPRStandsFor",
                  title: "What does C-P-R stand for?",
                  choices: [
                    {
                      value: "Commitment, Pricing, Respect",
                      text: "Commitment, Pricing, Respect",
                    },
                    {
                      value: "Commitment, Persistence, Resilience",
                      text: "Commitment, Persistence, Resilience",
                    },
                    {
                      value: "Cooperation, Procedures, Resilience",
                      text: "Cooperation, Procedures, Resilience",
                    },
                    {
                      value: "Continuous, Persistence, Regulations",
                      text: "Continuous, Persistence, Regulations",
                    }
                  ],
                  isRequired: true,
                },
                {
                  type: "radiogroup",
                  name: "HHSFirstStop",
                  title: "What is the first stop for businesses looking to do business with HHS?",
                  choices: [
                    {
                      value: "The HHS Administrator",
                      text: "The HHS Administrator",
                    },
                    {
                      value: "OSDBU Division Director",
                      text: "OSDBU Division Director",
                    },
                    {
                      value: "Small Business Customer Experience (SBCX)",
                      text: "Small Business Customer Experience (SBCX)",
                    },
                    {
                      value: "OSDBU Executive Director",
                      text: "OSDBU Executive Director",
                    }
                  ],
                  isRequired: true,
                }
              ],
            },
          ],
          completedHtml: "<h3>Thank you for your time! We will share and discuss the survey results at a future meeting.</h3>",
        };
        
        var survey = new SurveyVue.Model(surveyJSON);
        this.survey = survey;
        survey.onComplete.add(this.sendDataToServer);
      },
      sendDataToServer(survey) {
        //send Ajax request to your web servera
        //alert("The results are: " + JSON.stringify(survey.data));
        axios.post("/api/survey/apex-knowledge-check-review-feedback", { surveyType: "HHS", ...survey.data}).then((response) => {
          this.captionVisible = false;
        });
      },
    },
  };
  </script>
  
  <style>
  .sv_main {
      font-family: Rajdhani,sans-serif !important;
  }
  
  </style>