var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "main-content bg-light"
  }, [_vm._m(0), _c('section', {
    staticClass: "login-form section bg-light"
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.busy,
      "no-wrap": ""
    },
    scopedSlots: _vm._u([{
      key: "overlay",
      fn: function () {
        return [_c('b-spinner', {
          attrs: {
            "label": "Loading..."
          }
        })];
      },
      proxy: true
    }])
  }), _c('div', {
    staticClass: "container bg-white"
  }, [_c('div', {
    staticClass: "login-container"
  }, [_c('div', {
    staticClass: "row justify-content-center"
  }, [_vm._m(1), _c('div', {
    staticClass: "col-lg-6"
  }, [_c('form', {
    staticClass: "form-body",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
      }
    }
  }, [_vm._m(2), _c('div', {
    staticClass: "mt-4"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('p', [_vm._v(" An authentication code has been sent to your registered phone. "), _c('b', [_vm._v(_vm._s(_vm.phone))])]), _c('b-form-input', {
    attrs: {
      "type": "text",
      "state": _vm.validateState('code'),
      "name": "code",
      "placeholder": "Enter verification code",
      "required": "required"
    },
    model: {
      value: _vm.form.code,
      callback: function ($$v) {
        _vm.$set(_vm.form, "code", $$v);
      },
      expression: "form.code"
    }
  }), _c('b-form-invalid-feedback', {
    attrs: {
      "id": "input-phone-feedback"
    }
  }, [_vm._v(" Verification code is required. Please check your phone. ")])], 1)])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-sm-12 text-center"
  }, [!!_vm.error ? _c('div', {
    staticClass: "alert alert-danger"
  }, [_vm._v(" " + _vm._s(_vm.error) + " ")]) : _vm._e(), !!_vm.info & !_vm.error ? _c('div', {
    staticClass: "alert alert-success"
  }, [_vm._v(" " + _vm._s(_vm.info) + " ")]) : _vm._e(), _c('button', {
    staticClass: "btn theme-btn rounded w-100",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v(" Submit Code ")])]), _c('div', {
    staticClass: "col-sm-12 mt-5"
  }, [_c('div', {
    staticClass: "lost-password text-center"
  }, [_c('span', [_c('a', {
    staticClass: "theme-light-color",
    attrs: {
      "href": "#"
    },
    on: {
      "click": _vm.resendAuthenticationCode
    }
  }, [_vm._v(" Re-send MFA Verification Code")])])])])])])])])])])])], 1)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "inner-header-bg"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "section-breadcrumb"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12 justify-content-center align-self-center pb-2"
  }, [_c('div', {
    staticClass: "breadcrumb-content"
  }, [_c('div', {
    staticClass: "title-section"
  }, [_c('h1', {
    staticClass: "title text-center text-white"
  }, [_vm._v(" Verify Phone Number ")])]), _c('div', {
    staticClass: "breadcrumb-nav"
  }, [_c('ul', {
    staticClass: "breadcrumb-link text-center list-unstyled mb-0"
  }, [_c('li', {
    staticClass: "text-white"
  }, [_c('i', {
    staticClass: "mdi mdi-home theme-light-color pr-2"
  }), _c('a', {
    attrs: {
      "href": "/#/"
    }
  }, [_vm._v("Home")])]), _c('li', {
    staticClass: "theme-light-color active"
  }, [_vm._v(" Verify Phone Number ")])])])])])])])])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-lg-6 form-thumbnail"
  }, [_c('div', {
    staticClass: "thumboverlay"
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-center section-title"
  }, [_c('h2', {
    staticClass: "text-center"
  }, [_vm._v(" Please verify your phone number to enable MFA ")])]);

}]

export { render, staticRenderFns }