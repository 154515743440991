<template>
  <div class="main-content">
    <!-- BradCrumb header Start-->
    <section class="inner-header-bg">
      <div class="container">
        <!-- Breadcrumb Start-->
        <div class="section-breadcrumb">
          <div class="container">
            <div class="row">
              <div class="col-md-12 justify-content-center align-self-center pb-2">
                <div class="breadcrumb-content">
                  <div class="title-section">
                    <h1 class="title text-center text-white">
                      Surveys
                    </h1>
                  </div>
                  <div class="breadcrumb-nav">
                    <ul class="breadcrumb-link text-center list-unstyled mb-0">
                      <li class="text-white">
                        <i class="mdi mdi-home theme-light-color pr-2"></i>
                        <a href="/#/">Home</a>
                      </li>
                      <li class="theme-light-color active">Surveys</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Breadcrumb End -->
      </div>
    </section>
    <!-- BradCrumb header End -->
    <!-- Blog Grid Section Start -->
    <section class="section">
      <div class="container">
        <p class="pb-2" v-if="displayedBlogItems.length === 0 && searchQuery">
          No items match the search criteria...
        </p>
        <p class="pb-2" v-if="displayedBlogItems.length === 0 && !searchQuery">
          Check back soon for documents..
        </p>
        <div class="blog-post-grid">
          <div>

                <div class="row blog-item border d-flex mb-4">
                  <div class="col-10 col-sm-9 col-xl-10 useful-link-info pr-3 pl-3">
                    <a class="text-dark">
                      <div class="post-event d-inline-block align-items-center pt-2">
                        <a class="blog-info-meta text-dark">SURVEY</a>
                      </div>
                      <h4 class="blog-post-title mt-1">National Security Innovation Network Training Feedback</h4>
                      <p v-show="false" class="limit-lines-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus dapibus turpis non nisi auctor suscipit. Nullam vitae suscipit tortor. Praesent id egestas ex, vitae porttitor metus.</p>
                      <a class="limit-lines-1 link" target="_blank" @click="$router.replace('/surveys/national-security-innovation-network-training-feedback')">
                        Take survey
                      </a>
                    </a>
                  </div>
                </div>

                <div class="row blog-item border d-flex mb-4">
                  <div class="col-10 col-sm-9 col-xl-10 useful-link-info pr-3 pl-3">
                    <a class="text-dark">
                      <div class="post-event d-inline-block align-items-center pt-2">
                        <a class="blog-info-meta text-dark">SURVEY</a>
                      </div>
                      <h4 class="blog-post-title mt-1">Introduction to Project Spectrum Feedback</h4>
                      <p v-show="false" class="limit-lines-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus dapibus turpis non nisi auctor suscipit. Nullam vitae suscipit tortor. Praesent id egestas ex, vitae porttitor metus.</p>
                      <a class="limit-lines-1 link" target="_blank" @click="$router.replace('/surveys/introduction-to-project-spectrum-training-feedback')">
                        Take survey
                      </a>
                    </a>
                  </div>
                </div>

          </div>
        </div>
        <!-- <div>
          <b-pagination id="blog-pagination" v-model="currentPage" :total-rows="totalRows" :per-page="perPage"
            align="center" @page-click="pageClicked"></b-pagination>
        </div> -->
      </div>
    </section>
    <!-- Blog Grid Section End -->
  </div>
</template>
<script>
import axios from 'axios';
import { BPagination, BFormInput } from 'bootstrap-vue';

export default {
  name: 'blog',
  components: {
    BPagination,
    BFormInput,
  },
  props: {
    query: String,
  },
  data() {
    return {
      searchQuery: this.query,
      items: [],
      itemCount: 0,
      currentPage: 1,
      perPage: 6,
      bucket: '',
      selectedFolder: ''
    };
  },
  computed: {
    blogItemsFromSearchQuery() {

      if (this.searchQuery) {
        return this.items.filter((item) => {
          return this.searchQuery
            .toLowerCase()
            .split(' ')
            .every(
              (v) =>
                item.title.toLowerCase().includes(v) ||
                item.thumbnailDescription.toLowerCase().includes(v) ||
                item.url.toLowerCase().includes(v) ||
                item.taggedCategory[0]?.text.toLowerCase().includes(v)
            );
        });
      } else {
        if (this.selectedFolder != '') {
          return this.items.filter((item) => {
            return this.selectedFolder
              .toLowerCase()
              .split(' ')
              .every(
                (v) =>
                  item.taggedCategory[0]?.text.toLowerCase().includes(v)
              );
          });
        }
        else {
          return this.items;
        }

      }
    },
    displayedBlogItems() {
      return this.blogItemsFromSearchQuery.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
      );
    },
    totalRows() {
      return this.blogItemsFromSearchQuery.length;
    },
  },
  mounted: async function () {
    this.init();
    this.getItems(); // async
  },
  methods: {
    legal() {
      this.searchQuery = "";
      this.selectedFolder = "legal";
    },
    training() {
      this.searchQuery = "";
      this.selectedFolder = "training";
    },
    branding() {
      this.searchQuery = "";
      this.selectedFolder = "branding";
    },
    init() {
      /* The footer height no longer needs to be set */
      // this.initFooterHeight();
    },
    initFooterHeight() {
      var footerHeight = $('footer').outerHeight();
      $('.main-content').css('margin-bottom', footerHeight);
    },
    async getItems() {
      axios
        .get('/api/resources/documents')
        .then((response) => {
          this.items = response.data.Items;
          this.itemCount = response.data.Count;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    pageClicked() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
  },
};
</script>
<style scoped>
#blog-pagination li {
  margin: 0px;
}

.useful-link-info {
  padding: 10px 5px 25px 0;
}

.thumbnail-cover {
  object-fit: cover;
  margin: auto;
  max-width: 100%;
  max-height: 100%;
  padding: 10px;
}

.center-image-vertically {
  display: flex;
  justify-content: center;
}

.img-fluid {
  cursor: pointer;
}


.link {
  cursor: pointer;
}
</style>
<!-- /response.data.Items.filter(x => x.taggedCategory[0]?.text.toUpperCase() == "LEGAL");
  <div class="main-content"> -->