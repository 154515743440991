<template>
  <div class="main-content">
    <!-- BreadCrumb header Start-->
    <section class="inner-header-bg-partner">
      <div class="container">
        <!-- Breadcrumb Start-->
        <div class="section-breadcrumb">
          <div class="container">
            <div class="row">
              <div
                class="col-md-12 justify-content-center align-self-center pb-2"
              >
                <div class="breadcrumb-content">
                  <div class="title-section">
                    <h1 class="title text-center text-white">What We Do</h1>
                  </div>
                  <div class="breadcrumb-nav">
                    <ul class="breadcrumb-link text-center list-unstyled mb-0">
                      <li class="text-white">
                        <i class="mdi mdi-home theme-light-color pr-2"></i>
                        <a href="#">Home</a>
                      </li>
                      <li class="theme-light-color active">What We Do</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Breadcrumb End -->
      </div>
    </section>

    <!-- Body Area Start-->
    <section class="section portfolio-section" id="portfolio">
      <div class="container">
        <!-- CMMC Level 1 -->
        <div class="col-12 py-4">
		<div>
			<img src="../../assets/images/aboutus.jpg" alt="" class="img-fluid w-100">
		</div>
		<br> <br>
		<div>
		  <p>
			The APEX Accelerators, formerly known as the Procurement Technical Assistance Program (PTAP), was authorized by Congress in 1985 to expand the number of businesses capable of participating in government contracts . The National Defense Authorization Act (NDAA) for the FY 2020 ordered the PTAP to move to Under Secretary of Defense for Acquisition and Sustainment (USD(A&S)); and the DoD Office of Small Business Programs (OSBP) began to manage and operate PTAP with a new name, APEX Accelerators, effective FY 2023. The APEX Accelerators program focuses on building a strong, sustainable, and resilient U.S. supply chains by assisting a wide range of businesses that pursue and perform under contracts with the DoD, 
			other federal agencies, state and local governments and with government prime contractors. 
		  </p>
		</div>
		<div class="col-12">
			<h2>APEX Accelerators help businesses</h2>
			<blockquote class="blockquote">
                <p>
					<ul>
						<li>Complete registration with a wide range of databases necessary for them to participate in the government marketplace (e.g., SAM).</li>
						<li>Identify which agencies and offices may need their products or services and how connect with buying agencies and offices.</li>
						<li>Determine whether they are ready for government opportunities and how to position themselves to succeed.</li>
						<li>Navigate solicitations and potential funding opportunities.</li>
						<li>Receive notifications of government contract opportunities on a regular basis.</li>
						<li>Network with buying officers, prime contractors, and other businesses.</li>
						<li>Resolve performance issues and prepare for audit, only if the service is needed, after receiving an award.</li>
					</ul>
				</p>
             </blockquote>
			 <p>
				Today, APEX Accelerators are placing an emphasis on helping underserved businesses that are eligible for certain government solicitations designed for the promotion of diversity, inclusion, and equity, and guiding them throughout the entire process. APEX Accelerators will continue increasing the number of Defense Industrial Base (DIB)-ready and Government Industrial Base (GIB, including local and state governments)-ready businesses including:
				<ul>
					<li>Disadvantaged Businesses (SDBs) </li>
					<li>Historically Under-utilized Business Zones (HUBZones) </li>
					<li>Service-Disabled Veteran-Owned Businesses (SDVOSBs) </li>
					<li>Women-Owned Small Businesses (WOSBs), and </li>
					<li>Other underserved small businesses </li>
				</ul>
			 </p>
		</div>
		</div>
	  </div>
    </section>
    <!-- Body Area End-->

    <!-- Compliance Section -->
    <!-- <section class="section pt-0 portfolio-section" id="portfolio">
      <br /><br />
      <div class="container">
        <div class="section-title text-center z-index9">
          <h2>Compliance Standards</h2>
        </div>
        <div
          class="d-flex justify-content-center wow fadeIn"
          data-wow-duration="1s"
        >
          <ul id="portfolio-filter" class="portfolio-filter filters mt-4 p-0">
            <li class="button-border list-inline-item">
              <a href="#" data-filter="*" class="pill-button">All</a>
            </li>
            <li class="button-border list-inline-item">
              <a href="#" data-filter=".nist" class="pill-button">NIST</a>
            </li>
            <li class="button-border list-inline-item">
              <a href="#" data-filter=".dfars" class="pill-button">DFARS</a>
            </li>
          </ul>
        </div>
        <div
          class="portfolio-items row no-gutters mt-4 wow fadeIn"
          data-wow-duration="1s"
        >
          <div class="row align-items-center mt-4 nist">
            <div class="col-lg-5">
              <img
                src="../../assets/images/cyberimages/NIST-mmg.png"
                alt=""
                class="img-fluid w-100"
              />
            </div>
            <div class="col-lg-7">
              <div
                class="feature-contents section-heading wow fadeInUp"
                data-wow-duration="1s"
              >
                <h1 class="font-18">
                  National Institute of Standards and Technology
                </h1>
                <p>
                  <strong> About NIST </strong>
                </p>
                <p>
                  The National Institute of Standards and Technology is a
                  physical sciences laboratory and non-regulatory agency of the
                  United States Department of Commerce. Its mission is to
                  promote American innovation and industrial competitiveness.
                </p>
                <ul>
                  <li>
                    <a href="https://www.nist.gov/cyberframework"
                      ><strong>NIST Cybersecurity Framework</strong></a
                    >
                  </li>
                  <li>
                    <a
                      href="https://nvlpubs.nist.gov/nistpubs/SpecialPublications/NIST.SP.800-171r2.pdf"
                      ><strong>NIST SP 800-171</strong></a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="row align-items-center mt-4 dfars">
            <div class="col-lg-5">
              <img
                src="../../assets/images/cyberimages/DFARS-NIST.jpg"
                alt=""
                class="img-fluid w-100"
              />
            </div>
            <div class="col-lg-7">
              <div
                class="feature-contents section-heading wow fadeInUp"
                data-wow-duration="1s"
              >
                <h1 class="font-18">Defense Acquisition Regulations System</h1>
                <p>
                  The Defense Federal Acquisition Regulation Supplement (DFARS)
                  to the Federal Acquisition Regulation (FAR) is administered by
                  the Department of Defense (DoD). The DFARS implements and
                  supplements the FAR. The DFARS contains requirements of law,
                  DoD-wide policies, delegations of FAR authorities, deviations
                  from FAR requirements, and policies/procedures that have a
                  significant effect on the public. The DFARS should be read in
                  conjunction with the primary set of rules in the FAR. See also
                  the suggested search for "Government Contracts."
                </p>
                <ul>
                  <li>
                    <a href="https://www.acquisition.gov/dfars"
                      ><strong> DFARS website </strong></a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> -->

    <!-- CMMC Videos Section -->
    <!-- <section class="section portfolio-section" id="portfolio">
      <div class="container">
        <div class="section-title text-center z-index9">
          <h1>CMMC Videos</h1>
          <p>
            Watch the CMMC-AB and Project Spectrum National Conversation videos
            about the upcoming CMMC and how it impacts you as a member of the
            industrial base.
          </p>
        </div>
        <b-row>
          <b-col sm="12" lg="6">
            <div class="video_wrapper">
              <iframe
                class="video d-block"
                controls
                src="https://www.youtube.com/embed/sey4aWuqtvk"
              /></div
          ></b-col>
          <b-col sm="12" lg="6">
            <div class="video_wrapper">
              <iframe
                class="video d-block"
                controls
                src="https://www.youtube.com/embed/GbQenucsehg"
              /></div
          ></b-col>
        </b-row>

        <b-row>
          <b-col sm="12" lg="6">
            <div class="video_wrapper">
              <iframe
                class="video d-block"
                controls
                src="https://www.youtube.com/embed/oN1DC6NsXXE"
              /></div
          ></b-col>
          <b-col sm="12" lg="6">
            <div class="video_wrapper">
              <iframe
                class="video d-block"
                controls
                src="https://www.youtube.com/embed/lwqd4IOHXuk"
              /></div
          ></b-col>
        </b-row>
        <b-row></b-row>
      </div>
    </section> -->
  </div>
</template>
<script>
//WOW JS
import { WOW } from 'wowjs';

// import Swiper styles
import '../../assets/css/swiper.min.css';
// core version + navigation, pagination modules:
import Swiper, { Navigation, Pagination } from 'swiper';
// configure Swiper to use modules
Swiper.use([Navigation, Pagination]);

//ISOTOP JS
import '../../assets/js/jquery.isotope.min.js';
import * as ScrollMagic from 'scrollmagic'; // Or use scrollmagic-with-ssr to avoid server rendering problems
import { TweenMax, TimelineMax } from 'gsap'; // Also works with TweenLite and TimelineLite
import { ScrollMagicPluginGsap } from 'scrollmagic-plugin-gsap';
ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);

//MAGNIFIC POPUP JS
import 'magnific-popup/dist/jquery.magnific-popup.min.js';
import 'magnific-popup/dist/magnific-popup.css';
import axios from 'axios';
export default {
  name: 'index',
  components: {},
  created() {},
  mounted() {
    this.init();
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    init() {
      this.initMainSlider();
      this.initBackgroundImage();
      this.initWowAnimation();
      this.initAccordian();
      this.windowScroll();
      this.initTestimonialSlider();
      /* The footer height no longer needs to be set */
      // this.initFooterHeight();
      this.initIsotop();
      this.initMagnificPopup();
      this.initScaleImage();
    },
    async getNews() {
      /*  API call to resources/news */
      axios
        .get('/api/resources/news')
        .then((response) => {
          /* choose the 3 latest news items to display */
          this.newsItems = response.data.Items.slice(0, 3);
          console.log(this.newsItems);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async getEvents() {
      /*  API call to resources/news */
      axios
        .get('/api/resources/events')
        .then((response) => {
          /* choose the 2 latest event items to display */
          this.webinarItems = response.data.Items.slice(0, 3);
          console.log(this.webinarItems);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async getCybercircuits() {
      /*  API call to resources/news */
      axios
        .get('/api/resources/cybercircuits')
        .then((response) => {
          /* choose the 2 cybercircuits items to display */
          this.cybercircuitItems = response.data.Items.slice(0, 3);
          console.log(this.cybercircuitItems);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    /*----MAIN ANIMATION-----*/
    initMainSlider() {
      var swiperOptions = {
        loop: true,
        speed: 1000,
        grabCursor: true,
        watchSlidesProgress: true,
        mousewheelControl: true,
        keyboardControl: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      };
      var swiper = new Swiper('.main-slider', swiperOptions);
    },
    /*----BACKGROUND IMAGE-----*/
    initBackgroundImage() {
      $('.bg-image').css('background', function () {
        var bg =
          'url(' + $(this).data('image-src') + ') no-repeat center center';
        return bg;
      });
    },
    initFooterHeight() {
      var footerHeight = $('footer').outerHeight();
      $('.main-content').css('margin-bottom', footerHeight);
    },
    /*----WOW ANIMATION-----*/
    initWowAnimation() {
      var length = $('.wow').length;
      if (length >= 1) {
        const wow = new WOW({
          boxClass: 'wow',
          animateClass: 'animated',
          offset: 0,
        });
        wow.init();
      }
    },
    /*----ONSCROLL JS-----*/
    windowScroll() {
      var self = this;
      $(window).on('scroll', function () {
        //self.initCounter();
      });
    },
    /*----ACCORDIAN-----*/
    initAccordian() {
      $('.question-card').on('click', function () {
        if ($(this).hasClass('active')) {
          $('.question-card').removeClass('active');
          $(this).removeClass('active');
        } else {
          $('.question-card').removeClass('active');
          $(this).addClass('active');
        }
      });
    },
    /*----TESTIMONIAL SLIDER-----*/
    initTestimonialSlider() {
      if ($('.swiper-container').length > 0) {
        var swiper = new Swiper('.client-container', {
          slidesPerView: 1,
          draggable: true,
          spaceBetween: 10,
          //init: false,
          pagination: {
            el: '.swiper-pagination',
            clickable: true,
          },
          breakpoints: {
            640: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 40,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 50,
            },
          },
        });
      }
    },
    /*----ISOTOP JS-----*/
    initIsotop() {
      var $container = $('.portfolio-items');
      $container.imagesLoaded(function () {
        $container.isotope({
          filter: '*',
          animationOptions: {
            duration: 750,
            easing: 'linear',
            queue: false,
          },
        });
      });

      $('.portfolio-filter li a').click(function () {
        $('.portfolio-filter .current').removeClass('current');
        $(this).addClass('current');
        var selector = $(this).attr('data-filter');
        $container.isotope({
          filter: selector,
          animationOptions: {
            duration: 750,
            easing: 'linear',
            queue: false,
          },
        });
        return false;
      });
    },
    /*----MAGNIFIC POPUP JS-----*/
    initMagnificPopup() {
      if ('.portfolio-items'.length > 0) {
        $('.portfolio-items').each(function () {
          $(this).magnificPopup({
            delegate: '.js-zoom-gallery',
            type: 'image',
            gallery: {
              enabled: true,
            },
          });
        });
      }
    },
    initScaleImage() {
      var controller = new ScrollMagic.Controller();
      var slides = $('.scale-image');
      for (var i = 0; i < slides.length; i++) {
        var image = $('img', slides[i]);
        var tween = TweenMax.to(image, 1, { scale: 1.2 });
        var scene = new ScrollMagic.Scene({
          triggerElement: slides[i],
          duration: '200%',
          triggerHook: 'onEnter',
        })
          .setTween(tween)
          .addTo(controller);
      }
    },
  },
};
</script>
<style scoped>
ul {
  list-style-position: inside;
}

li {
  list-style-type: '\25BA\0020';
}

.video_wrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9, for an aspect ratio of 1:1 change to this value to 100% */
}

iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.cmmc-image {
  width: 100%;
  padding: 0.5em;
}
</style>
