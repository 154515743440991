var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "main-content"
  }, [_vm._m(0), _c('section', {
    staticClass: "section"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row justify-content-center"
  }, [_c('section', {
    staticClass: "about-area",
    attrs: {
      "id": "aboutus"
    }
  }, [_c('div', {
    staticClass: "container"
  }, [!_vm.isLoggedIn ? _c('div', {
    staticClass: "row align-items-center"
  }, [_vm._m(1)]) : _vm._e(), _c('div', {
    staticClass: "row align-items-center pb-4"
  }), _vm._m(2), _c('b-row', {
    staticClass: "p-0 m-0"
  }, [_c('hr', {
    staticClass: "col-sm-12"
  }), _c('b-row', {
    staticClass: "row align-items-center mt-0"
  }, [_c('div', {
    staticClass: "col-lg-7"
  }, [_c('div', {
    staticClass: "feature-contents section-heading wow fadeInUp",
    attrs: {
      "data-wow-duration": "1s"
    }
  }, [_c('h1', {
    staticClass: "font-18"
  }, [_vm._v("About MEP")]), _c('p', [_vm._v(" The MEP is a unique public-private partnership that delivers comprehensive, proven solutions to U.S. manufacturers, fueling growth and advancing U.S. manufacturing. ")]), _c('p', [_vm._v(" The MEP National Network™ comprises MEP Centers located in all 50 states and Puerto Rico providing any U.S. manufacturer with access to resources they need to succeed. MEP Centers have helped thousands of manufacturers improve operations, increase profits, create or maintain jobs, and establish a foundation for long-term business growth and productivity. ")]), _c('p', [_vm._v(" Please watch the video to learn what the MEP can do for you. ")])])]), _c('div', {
    staticClass: "col-lg-5"
  }, [_c('div', {
    staticClass: "download-img scale-image wow fadeInUp container-iframe",
    attrs: {
      "data-wow-duration": "1s"
    }
  }, [_c('iframe', {
    staticClass: "responsive-iframe",
    staticStyle: {
      "border": "none"
    },
    attrs: {
      "src": "https://iframe.videodelivery.net/1ddbea4d25538df2c9f35386bb2e256e?poster=https%3A%2F%2Fvideodelivery.net%2F1ddbea4d25538df2c9f35386bb2e256e%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D4s%26height%3D250",
      "width": "100%",
      "allow": "accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;",
      "allowfullscreen": "true"
    }
  })])])]), _c('hr', {
    staticClass: "col-sm-12"
  }), _c('b-row', {
    staticClass: "row align-items-center mt-0"
  }, [_c('div', {
    staticClass: "col-sm-12"
  }, [_c('div', {
    staticClass: "feature-contents section-heading wow fadeInUp",
    attrs: {
      "data-wow-duration": "1s"
    }
  }, [_c('h1', {
    staticClass: "font-18"
  }, [_vm._v(" The Manufacturer Path to Cybersecurity Compliance ")]), _c('p', [_vm._v(" Below are the recommended process steps for using Project Spectrum’s educational tools and resources to initiate or strengthen your company’s cybersecurity. "), _c('span', {
    staticClass: "d-none d-lg-block font-weight-bold"
  }, [_vm._v(" Hover your mouse above each step to learn more. ")])])])]), _c('div', {
    staticClass: "col-md-12 mt-5 mb-5 text-center d-block d-lg-none"
  }, [_c('img', {
    staticClass: "img-fluid",
    attrs: {
      "src": require("../../assets/images/mep/MEPHeaderFull.png")
    }
  })]), _c('div', {
    staticClass: "col-md-12 mt-5 mb-5 text-center d-none d-lg-block",
    attrs: {
      "id": "big-screen"
    }
  }, [_c('img', {
    attrs: {
      "id": "MEPHEADER",
      "src": require("../../assets/images/mep/MEPHeader2.png"),
      "usemap": "#image-map"
    }
  }), _c('map', {
    attrs: {
      "name": "image-map",
      "id": "MEPHEADERMAP"
    }
  }, [_c('area', {
    staticStyle: {
      "outline": "none"
    },
    attrs: {
      "alt": "Understand your network",
      "title": "",
      "href": "javascript:void(0)",
      "shape": "poly",
      "coords": "192,136,266,85,378,198,357,257,210,260,13,255,5,134,98,137",
      "target": "",
      "onmouseover": "if(document.images) document.getElementById('MEPHEADER').src= '../../assets/images/mep/understand.png';",
      "onmouseout": "if(document.images) document.getElementById('MEPHEADER').src= '../../assets/images/mep/MEPHeader2.png';"
    }
  }), _c('area', {
    staticStyle: {
      "outline": "none"
    },
    attrs: {
      "alt": "Conduct a risk assessment",
      "title": "",
      "href": "javascript:void(0)",
      "shape": "poly",
      "coords": "10,278,357,274,377,328,278,432,5,414",
      "target": "",
      "onmouseover": "if(document.images) document.getElementById('MEPHEADER').src= '../../assets/images/mep/conduct.png';",
      "onmouseout": "if(document.images) document.getElementById('MEPHEADER').src= '../../assets/images/mep/MEPHeader2.png';"
    }
  }), _c('area', {
    staticStyle: {
      "outline": "none"
    },
    attrs: {
      "alt": "Education and outreach",
      "title": "",
      "href": "javascript:void(0)",
      "shape": "poly",
      "coords": "9,430,276,443,391,339,436,363,441,535,3,542",
      "target": "",
      "onmouseover": "if(document.images) document.getElementById('MEPHEADER').src= '../../assets/images/mep/education.png';",
      "onmouseout": "if(document.images) document.getElementById('MEPHEADER').src= '../../assets/images/mep/MEPHeader2.png';"
    }
  }), _c('area', {
    staticStyle: {
      "outline": "none"
    },
    attrs: {
      "alt": "Continuous Monitoring",
      "title": "",
      "href": "javascript:void(0)",
      "shape": "poly",
      "coords": "460,370,457,542,897,553,895,469,615,475,616,461,514,353",
      "target": "",
      "onmouseover": "if(document.images) document.getElementById('MEPHEADER').src= '../../assets/images/mep/continous.png';",
      "onmouseout": "if(document.images) document.getElementById('MEPHEADER').src= '../../assets/images/mep/MEPHeader2.png';"
    }
  }), _c('area', {
    staticStyle: {
      "outline": "none"
    },
    attrs: {
      "alt": "Remedation",
      "title": "",
      "href": "javascript:void(0)",
      "shape": "poly",
      "coords": "531,344,648,461,899,431,889,297,565,288",
      "target": "",
      "onmouseover": "if(document.images) document.getElementById('MEPHEADER').src= '../../assets/images/mep/remedation.png';",
      "onmouseout": "if(document.images) document.getElementById('MEPHEADER').src= '../../assets/images/mep/MEPHeader2.png';"
    }
  }), _c('area', {
    staticStyle: {
      "outline": "none"
    },
    attrs: {
      "alt": "Develop POA",
      "title": "",
      "href": "javascript:void(0)",
      "shape": "poly",
      "coords": "545,206,641,117,896,161,895,277,724,275,565,271",
      "target": "",
      "onmouseover": "if(document.images) document.getElementById('MEPHEADER').src= '../../assets/images/mep/develop.png';",
      "onmouseout": "if(document.images) document.getElementById('MEPHEADER').src= '../../assets/images/mep/MEPHeader2.png';"
    }
  }), _c('area', {
    staticStyle: {
      "outline": "none"
    },
    attrs: {
      "alt": "SSP",
      "title": "",
      "href": "javascript:void(0)",
      "shape": "poly",
      "coords": "474,157,473,27,898,32,893,141,647,101,539,190",
      "target": "",
      "onmouseover": "if(document.images) document.getElementById('MEPHEADER').src= '../../assets/images/mep/create.png';",
      "onmouseout": "if(document.images) document.getElementById('MEPHEADER').src= '../../assets/images/mep/MEPHeader2.png';"
    }
  }), _c('area', {
    staticStyle: {
      "outline": "none"
    },
    attrs: {
      "alt": "Register",
      "title": "",
      "href": "javascript:void(0)",
      "shape": "poly",
      "coords": "9,102,275,82,391,185,457,160,455,8,9,11",
      "target": "",
      "onmouseover": "if(document.images) document.getElementById('MEPHEADER').src= '../../assets/images/mep/register.png';",
      "onmouseout": "if(document.images) document.getElementById('MEPHEADER').src= '../../assets/images/mep/MEPHeader2.png';"
    }
  }), _c('area', {
    staticStyle: {
      "outline": "none"
    },
    attrs: {
      "alt": "Register",
      "title": "",
      "href": "javascript:void(0)",
      "shape": "circle",
      "coords": "462,265,99",
      "onclick": "",
      "target": ""
    }
  })])])]), _c('hr', {
    staticClass: "col-sm-12"
  }), _c('b-row', {
    staticClass: "row align-items-center mt-0"
  }, [_c('div', {
    staticClass: "col-sm-12"
  }, [_c('div', {
    staticClass: "feature-contents section-heading wow fadeInUp",
    attrs: {
      "data-wow-duration": "1s"
    }
  }, [_c('h1', {
    staticClass: "font-18"
  }, [_vm._v(" How a Manufacturer Can Utilize Project Spectrum ")]), _c('p', [_vm._v(" NIST MEP and the U.S. Department of Defense Office of Small Business Programs signed a Memorandum of Understanding in May 2021 to collaborate and provide cybersecurity awareness resources for small-to-medium-sized manufacturers. ")]), _c('p', [_vm._v(" Every company’s cybersecurity experience will be different. The path to compliance will comprise different steps depending upon the company’s current state and the compliance level required. ")]), _c('p', [_vm._v(" Here are two real-world scenarios that demonstrate how Project Spectrum’s tools and resources can guide a manufacturer’s cybersecurity compliance journey. ")]), _c('p', [_vm._v(" This is a self-directed platform, you can stop at anypoint, save your progress, and finish later. ")]), _c('p', [_c('strong', [_vm._v("Scenario #1: ")]), _c('a', {
    staticStyle: {
      "text-decoration": "underline"
    },
    attrs: {
      "href": "../../assets/images/mep/scenario-1.pdf",
      "target": "_blank"
    }
  }, [_vm._v(" Company seeking DFARS conformity ")]), _c('br'), _c('strong', [_vm._v("Scenario #2: ")]), _c('a', {
    staticStyle: {
      "text-decoration": "underline"
    },
    attrs: {
      "href": "../../assets/images/mep/scenario-2.pdf",
      "target": "_blank"
    }
  }, [_vm._v(" Company reviewing policies and procedures to ensure continued compliance ")])])])])]), _c('hr', {
    staticClass: "col-sm-12"
  }), _c('b-row', {
    staticClass: "row align-items-center mt-0"
  }), _c('b-col', {
    attrs: {
      "sm": "3"
    }
  }, [_c('ul', {
    staticClass: "blog-news list-unstyled"
  }, [_c('li', {
    staticClass: "blog-item"
  }, [_c('h6', {
    staticClass: "post-news-title"
  }, [_vm._v("Learn More")])])]), _c('div', {
    staticClass: "blog-news-content"
  }, [_c('div', {
    staticClass: "blog-post-list"
  }, [_c('ul', {
    staticClass: "list-unstyled"
  }, [_c('li', {
    staticClass: "blog-news-info"
  }, [_c('a', {
    staticClass: "text-dark",
    attrs: {
      "href": "https://www.nist.gov/mep/mep-national-network"
    }
  }, [_vm._v(" MEP National Network ")])]), _c('li', {
    staticClass: "blog-news-info"
  }, [_c('a', {
    staticClass: "text-dark",
    attrs: {
      "href": "https://www.projectspectrum.io/#!/abtcmmc"
    }
  }, [_vm._v(" About CMMC ")])]), _c('li', {
    staticClass: "blog-news-info"
  }, [_c('a', {
    staticClass: "text-dark",
    attrs: {
      "href": "https://www.projectspectrum.io/#!/newsAndEvents"
    }
  }, [_vm._v(" Cybersecurity News & Articles ")])])])])])]), _c('b-col', {
    attrs: {
      "sm": "3"
    }
  }, [_c('ul', {
    staticClass: "blog-news list-unstyled"
  }, [_c('li', {
    staticClass: "blog-item"
  }, [_c('h6', {
    staticClass: "post-news-title"
  }, [_vm._v("Education Topics")])])]), _c('div', {
    staticClass: "blog-news-content"
  }, [_c('div', {
    staticClass: "blog-post-list"
  }, [_c('ul', {
    staticClass: "list-unstyled"
  }, [_c('li', {
    staticClass: "blog-news-info"
  }, [_c('a', {
    staticClass: "text-dark",
    attrs: {
      "href": "https://www.projectspectrum.io/#!/blog/blog-post/c18d9bf7-cae9-4cbc-b692-bfbce789c5a6"
    }
  }, [_vm._v(" VPN Vulnerabilities ")])]), _c('li', {
    staticClass: "blog-news-info"
  }, [_c('a', {
    staticClass: "text-dark",
    attrs: {
      "href": "https://www.projectspectrum.io/#!/blog/blog-post/6c019bd9-bdbf-4778-9c64-ec7feb1db872"
    }
  }, [_vm._v(" Common Vulnerabilities")])]), _c('li', {
    staticClass: "blog-news-info"
  }, [_c('a', {
    staticClass: "text-dark",
    attrs: {
      "href": "https://www.projectspectrum.io/#!/cybercircuit/item/15da1d0e-48eb-4616-a508-c2bad5dc3d05"
    }
  }, [_vm._v(" Cybersecurity Costs ")])]), _c('li', {
    staticClass: "blog-news-info"
  }, [_c('a', {
    staticClass: "text-dark",
    attrs: {
      "href": "https://www.projectspectrum.io/#!/whitepaper/item/048933f4-ab70-458e-a50d-2339cc1ce49b"
    }
  }, [_vm._v(" DoS and DDoS Attacks ")])]), _c('li', {
    staticClass: "blog-news-info"
  }, [_c('a', {
    staticClass: "text-dark",
    attrs: {
      "href": "https://www.projectspectrum.io/#!/whitepaper/item/ceef8fff-693b-47ad-9bbf-074761c4d0ed"
    }
  }, [_vm._v(" Cybersecurity Saving ")])])])])])]), _c('b-col', {
    attrs: {
      "sm": "6"
    }
  }, [_c('ul', {
    staticClass: "blog-news list-unstyled"
  }, [_c('li', {
    staticClass: "blog-item"
  }, [_c('h6', {
    staticClass: "post-news-title"
  }, [_vm._v("Tools and Checklists")])])]), _c('div', {
    staticClass: "blog-news-content"
  }, [_c('div', {
    staticClass: "blog-post-list"
  }, [_c('ul', {
    staticClass: "list-unstyled"
  }, [_c('li', {
    staticClass: "blog-news-info"
  }, [_c('a', {
    staticClass: "text-dark",
    attrs: {
      "href": "https://www.endpointprotector.com/blog/nist-800-171-compliance-checklist/"
    }
  }, [_vm._v(" NIST SP 800-171 Compliance Checklist ")])]), _c('li', {
    staticClass: "blog-news-info"
  }, [_c('a', {
    staticClass: "text-dark",
    attrs: {
      "href": "https://www.acq.osd.mil/dpap/pdi/cyber/docs/NIST%20SP%20800-171%20Assessment%20Methodology%20Version%201.2.1%20%206.24.2020.pdf"
    }
  }, [_vm._v(" NIST SP 800-171 DoD Assessment Methodology ")])]), _c('li', {
    staticClass: "blog-news-info"
  }, [_c('a', {
    staticClass: "text-dark",
    attrs: {
      "href": "https://www.projectspectrum.io/#!/pilotProgram"
    }
  }, [_vm._v(" Mentor-Protégé Program Cybersecurity Pilot ")])]), _c('li', {
    staticClass: "blog-news-info"
  }, [_c('a', {
    staticClass: "text-dark",
    attrs: {
      "href": "https://www.nist.gov/system/files/documents/2019/11/14/mepnn_cybersecurity_guide_10919-508.pdf"
    }
  }, [_vm._v(" Small- and Medium-Sized Manufacturers’ Guide to Cybersecurity ")])])])])])])], 1)], 1)])])]), _c('b-modal', {
    ref: "modal",
    attrs: {
      "id": "modal-prevent-closing",
      "title": "Contact Local MEP Cyber Expert",
      "size": "lg"
    },
    on: {
      "show": _vm.resetModal,
      "hidden": _vm.resetModal,
      "ok": _vm.handleModalOk
    }
  }, [_c('form', {
    ref: "form",
    on: {
      "submit": function ($event) {
        $event.stopPropagation();
        $event.preventDefault();
        return _vm.handleModalSubmit.apply(null, arguments);
      }
    }
  }, [_c('b-col', {
    staticClass: "py-1 px-0",
    attrs: {
      "sm": "12"
    }
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "state-select"
    }
  }, [_vm._v("Select State*")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.modal.state,
      expression: "modal.state"
    }],
    staticClass: "form-control",
    class: _vm.modal.stateError ? 'is-invalid' : '',
    attrs: {
      "id": "state-select"
    },
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.modal, "state", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("Select State")]), _c('option', {
    attrs: {
      "value": "AL"
    }
  }, [_vm._v("Alabama")]), _c('option', {
    attrs: {
      "value": "AK"
    }
  }, [_vm._v("Alaska")]), _c('option', {
    attrs: {
      "value": "AZ"
    }
  }, [_vm._v("Arizona")]), _c('option', {
    attrs: {
      "value": "AR"
    }
  }, [_vm._v("Arkansas")]), _c('option', {
    attrs: {
      "value": "CA"
    }
  }, [_vm._v("California")]), _c('option', {
    attrs: {
      "value": "CO"
    }
  }, [_vm._v("Colorado")]), _c('option', {
    attrs: {
      "value": "CT"
    }
  }, [_vm._v("Connecticut")]), _c('option', {
    attrs: {
      "value": "DE"
    }
  }, [_vm._v("Delaware")]), _c('option', {
    attrs: {
      "value": "DC"
    }
  }, [_vm._v("District Of Columbia")]), _c('option', {
    attrs: {
      "value": "FL"
    }
  }, [_vm._v("Florida")]), _c('option', {
    attrs: {
      "value": "GA"
    }
  }, [_vm._v("Georgia")]), _c('option', {
    attrs: {
      "value": "HI"
    }
  }, [_vm._v("Hawaii")]), _c('option', {
    attrs: {
      "value": "ID"
    }
  }, [_vm._v("Idaho")]), _c('option', {
    attrs: {
      "value": "IL"
    }
  }, [_vm._v("Illinois")]), _c('option', {
    attrs: {
      "value": "IN"
    }
  }, [_vm._v("Indiana")]), _c('option', {
    attrs: {
      "value": "IA"
    }
  }, [_vm._v("Iowa")]), _c('option', {
    attrs: {
      "value": "KS"
    }
  }, [_vm._v("Kansas")]), _c('option', {
    attrs: {
      "value": "KY"
    }
  }, [_vm._v("Kentucky")]), _c('option', {
    attrs: {
      "value": "LA"
    }
  }, [_vm._v("Louisiana")]), _c('option', {
    attrs: {
      "value": "ME"
    }
  }, [_vm._v("Maine")]), _c('option', {
    attrs: {
      "value": "MD"
    }
  }, [_vm._v("Maryland")]), _c('option', {
    attrs: {
      "value": "MA"
    }
  }, [_vm._v("Massachusetts")]), _c('option', {
    attrs: {
      "value": "MI"
    }
  }, [_vm._v("Michigan")]), _c('option', {
    attrs: {
      "value": "MN"
    }
  }, [_vm._v("Minnesota")]), _c('option', {
    attrs: {
      "value": "MS"
    }
  }, [_vm._v("Mississippi")]), _c('option', {
    attrs: {
      "value": "MO"
    }
  }, [_vm._v("Missouri")]), _c('option', {
    attrs: {
      "value": "MT"
    }
  }, [_vm._v("Montana")]), _c('option', {
    attrs: {
      "value": "NE"
    }
  }, [_vm._v("Nebraska")]), _c('option', {
    attrs: {
      "value": "NV"
    }
  }, [_vm._v("Nevada")]), _c('option', {
    attrs: {
      "value": "NH"
    }
  }, [_vm._v("New Hampshire")]), _c('option', {
    attrs: {
      "value": "NJ"
    }
  }, [_vm._v("New Jersey")]), _c('option', {
    attrs: {
      "value": "NM"
    }
  }, [_vm._v("New Mexico")]), _c('option', {
    attrs: {
      "value": "NY"
    }
  }, [_vm._v("New York")]), _c('option', {
    attrs: {
      "value": "NC"
    }
  }, [_vm._v("North Carolina")]), _c('option', {
    attrs: {
      "value": "ND"
    }
  }, [_vm._v("North Dakota")]), _c('option', {
    attrs: {
      "value": "OH"
    }
  }, [_vm._v("Ohio")]), _c('option', {
    attrs: {
      "value": "OK"
    }
  }, [_vm._v("Oklahoma")]), _c('option', {
    attrs: {
      "value": "OR"
    }
  }, [_vm._v("Oregon")]), _c('option', {
    attrs: {
      "value": "PA"
    }
  }, [_vm._v("Pennsylvania")]), _c('option', {
    attrs: {
      "value": "RI"
    }
  }, [_vm._v("Rhode Island")]), _c('option', {
    attrs: {
      "value": "SC"
    }
  }, [_vm._v("South Carolina")]), _c('option', {
    attrs: {
      "value": "SD"
    }
  }, [_vm._v("South Dakota")]), _c('option', {
    attrs: {
      "value": "TN"
    }
  }, [_vm._v("Tennessee")]), _c('option', {
    attrs: {
      "value": "TX"
    }
  }, [_vm._v("Texas")]), _c('option', {
    attrs: {
      "value": "UT"
    }
  }, [_vm._v("Utah")]), _c('option', {
    attrs: {
      "value": "VT"
    }
  }, [_vm._v("Vermont")]), _c('option', {
    attrs: {
      "value": "VA"
    }
  }, [_vm._v("Virginia")]), _c('option', {
    attrs: {
      "value": "WA"
    }
  }, [_vm._v("Washington")]), _c('option', {
    attrs: {
      "value": "WV"
    }
  }, [_vm._v("West Virginia")]), _c('option', {
    attrs: {
      "value": "WI"
    }
  }, [_vm._v("Wisconsin")]), _c('option', {
    attrs: {
      "value": "WY"
    }
  }, [_vm._v("Wyoming")])]), _vm.modal.stateError ? _c('div', {
    staticClass: "invalid-feedback"
  }, [_vm._v(" State is required ")]) : _vm._e()])]), _c('b-form-group', {
    attrs: {
      "label": "Message",
      "label-for": "message-input",
      "invalid-feedback": "Message is required",
      "state": !_vm.modal.messageError
    }
  }, [_c('b-form-textarea', {
    attrs: {
      "id": "message-input",
      "state": _vm.modal.messageError ? false : undefined,
      "rows": "6",
      "max-rows": "6",
      "required": ""
    },
    model: {
      value: _vm.modal.message,
      callback: function ($$v) {
        _vm.$set(_vm.modal, "message", $$v);
      },
      expression: "modal.message"
    }
  })], 1)], 1)])], 1)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "inner-header-bg-partner"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "section-breadcrumb"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12 justify-content-center align-self-center pb-2"
  }, [_c('div', {
    staticClass: "breadcrumb-content"
  }, [_c('div', {
    staticClass: "title-section"
  }, [_c('h1', {
    staticClass: "title text-center text-white"
  }, [_vm._v(" Manufacturing Extension Partnership ")])]), _c('div', {
    staticClass: "breadcrumb-nav"
  }, [_c('ul', {
    staticClass: "breadcrumb-link text-center list-unstyled mb-0"
  }, [_c('li', {
    staticClass: "text-white"
  }, [_c('i', {
    staticClass: "mdi mdi-home theme-light-color pr-2"
  }), _c('a', {
    attrs: {
      "href": "/#/"
    }
  }, [_vm._v("Home")])]), _c('li', {
    staticClass: "theme-light-color active"
  }, [_vm._v("MEP")])])])])])])])])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-lg-6 col-md-12"
  }, [_c('div', {
    staticClass: "about-title section-title"
  }, [_c('span', [_vm._v("APEX ACCELERATORS")]), _c('h2', [_vm._v("Manufacturing Extension Partnership")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row align-items-center mt-0"
  }, [_c('div', {
    staticClass: "col-lg-5"
  }, [_c('div', {
    staticClass: "download-img scale-image wow fadeInUp container-iframe",
    attrs: {
      "data-wow-duration": "1s"
    }
  }, [_c('iframe', {
    staticClass: "responsive-iframe",
    staticStyle: {
      "border": "none"
    },
    attrs: {
      "src": "https://iframe.videodelivery.net/0039ac3112a7e38165a6ba3854365614?poster=https%3A%2F%2Fvideodelivery.net%2F0039ac3112a7e38165a6ba3854365614%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D3s%26height%3D230",
      "width": "100%",
      "allow": "accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;",
      "allowfullscreen": "true"
    }
  })])]), _c('div', {
    staticClass: "col-lg-7"
  }, [_c('div', {
    staticClass: "feature-contents section-heading wow fadeInUp",
    attrs: {
      "data-wow-duration": "1s"
    }
  }, [_c('h1', {
    staticClass: "font-18"
  }, [_vm._v(" Let the MEP and Project Spectrum help your business with cybersecurity compliance ")]), _c('p', [_vm._v(" Welcome to an exciting collaboration between Project Spectrum and the Manufacturing Extension Partnership (MEP). We’re working together to provide manufacturers with access to a self-directed platform for cutting edge cybersecurity compliance training and tools. ")]), _c('p', [_vm._v(" Please watch the video to learn more about how the MEP and Project Spectrum can assist you with your cybersecurity compliance journey. ")])])])]);

}]

export { render, staticRenderFns }