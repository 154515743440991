import Vue from 'vue';
import { BootstrapVue } from 'bootstrap-vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import * as VueGoogleMaps from 'vue2-google-maps'
import JsonCSV from 'vue-json-csv'

Vue.component('downloadCsv', JsonCSV)

window.$ = window.jQuery = require('jquery');

Vue.use(BootstrapVue);

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCMnAObfj4pNs2ljwkKzJoapi5joIX8LiY',
    libraries: 'places',
  }
});
import app from './app.vue';

// VUEX - https://vuex.vuejs.org/
import store from './store';

// VUE-ROUTER - https://router.vuejs.org/
import router from './router';

// VUE-MIXIN - https://vuejs.org/v2/guide/mixins.html
import './mixins';

// Set Authentication from store
import { setHeaderTokensFromStore } from './utils/authentication';
setHeaderTokensFromStore();

// Social Media Sharing
import VueSocialSharing from 'vue-social-sharing';
Vue.use(VueSocialSharing);

import VueGtag from 'vue-gtag'
Vue.use(VueGtag, {
    config: { id: 'G-TDS0DM9HY9' }
  })
// Font Awesome
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faFacebookSquare,
  faTwitterSquare,
  faLinkedin,
  faRedditSquare,
} from '@fortawesome/free-brands-svg-icons';

import { faPlay, faPlayCircle, faSearch, faLocationArrow } from "@fortawesome/free-solid-svg-icons";
import {
  faLink,
  faBookmark as faBookmarkSolid,
  faCheckCircle,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import { faBookmark } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(faFacebookSquare);
library.add(faTwitterSquare);
library.add(faLinkedin);
library.add(faRedditSquare);
library.add(faLink);
library.add(faBookmarkSolid);
library.add(faBookmark);
library.add(faCheckCircle);
library.add(faTimesCircle);
library.add(faPlay);
library.add(faPlayCircle);
library.add(faSearch);
library.add(faLocationArrow);

Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.config.productionTip = false;

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdiSvg', // default - only for display purposes
  },
});

new Vue({
  render: (h) => h(app),
  vuetify: new Vuetify(),
  router,
  store,
  el: "#app",
}).$mount('#app');
router.afterEach((to, from) => {
  //document.title = to.meta.title;
});
