<template>
  <div class="main-content bg-light">
    <!-- BradCrumb header Start-->
    <section class="inner-header-bg-partner">
      <div class="container">
        <!-- Breadcrumb Start-->
        <div class="section-breadcrumb">
          <div class="container">
            <div class="row">
              <div
                class="col-md-12 justify-content-center align-self-center pb-2"
              >
                <div class="breadcrumb-content">
                  <div class="title-section">
                    <h1 class="title text-center text-white">MEP</h1>
                  </div>
                  <div class="breadcrumb-nav">
                    <ul class="breadcrumb-link text-center list-unstyled mb-0">
                      <li class="text-white">
                        <i class="mdi mdi-home theme-light-color pr-2"></i>
                        <a href="/#/">Home</a>
                      </li>
                      <li class="theme-light-color active">
                        <router-link to="/mep">MEP</router-link>
                      </li>
                      <li class="theme-light-color active">Register</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Breadcrumb End -->
      </div>
    </section>
    <!-- BradCrumb header End -->
    <!-- Register Area Start-->
    <section class="login-form section bg-light">
      <div class="container">
        <div class="login-container">
          <div class="row justify-content-center">
            <!-- Show success message when the user submits the form successfully -->
            <div v-if="submissionSuccessful">
              <b-alert show
                ><h5>You have registered for MEP</h5>
                <p>
                  Your application is saved, you can now access additional
                  information on the
                  <router-link :to="{ name: 'mep' }"
                    >Manufacturing Extension Partnership(MEP)
                  </router-link>
                  .
                </p></b-alert
              >
            </div>

            <!-- Show MEP question is the user is logged in with MFA -->
            <div v-if="isLoggedIn && !submissionSuccessful" class="mt-1 w-100">
              <form-wizard
                @onComplete="onComplete"
                @onCannotCompleteFromErrors="onCannotCompleteFromErrors"
                :customButtonLabel="customButtonLabel"
                :errorMessage="errorMessage"
                :successMessage="successMessage"
                :hideSubmitButton="false"
                class="width-override form-floating"
              >
                <tab-content
                  title="Register below to continue accessing Manufacturing Extension Partnership(MEP)"
                  :selected="true"
                >
                  <!-- Form - Shown only if MEP -->
                  <div>
                    <h5 class="py-4">MEP Registration</h5>
                    <b-alert
                      v-if="hasDataBeenPopulatedFromAccount"
                      show
                      variant="info"
                      >The registration form has been automatically filled with
                      your
                      <router-link :to="{ name: 'my-account' }">
                        <a> Account Information</a>
                      </router-link>
                      where possible.
                    </b-alert>
                    <b-row>
                      <!-- First Name -->
                      <b-col class="py-1" sm="12" md="6">
                        <div class="form-label-group">
                          <label for="firstName">First Name*</label>
                          <input
                            type="text"
                            id="firstName"
                            class="form-control"
                            :class="hasError('firstName') ? 'is-invalid' : ''"
                            placeholder="First Name"
                            v-model="formData.firstName"
                          />
                          <div
                            v-if="hasError('firstName')"
                            class="invalid-feedback"
                          >
                            <div
                              class="error"
                              v-if="!$v.formData.firstName.required"
                            >
                              Please provide your first name
                            </div>
                          </div>
                        </div>
                      </b-col>
                      <!-- Last Name -->
                      <b-col class="py-1" sm="12" md="6">
                        <div class="form-group">
                          <label for="lastName">Last Name*</label>
                          <input
                            type="text"
                            class="form-control"
                            :class="hasError('lastName') ? 'is-invalid' : ''"
                            placeholder="Last Name"
                            v-model="formData.lastName"
                          />
                          <div
                            v-if="hasError('lastName')"
                            class="invalid-feedback"
                          >
                            <div
                              class="error"
                              v-if="!$v.formData.lastName.required"
                            >
                              Please provide your last name
                            </div>
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                    <!-- <hr class="col-sm-12" /> -->
                    <!-- <h5 class="py-4">Company Information</h5> -->
                    <!--Do you want to enter your company's CAGE code or DUNS number and look up some of the required fields?-->
                    <div class="py-2">
                      <b-form-group
                        class="font-italic"
                        label="Do you want to enter your company's CAGE code or DUNS number and look up some of the required fields?"
                      >
                        <b-form-radio
                          v-model="formData.useCompanyCodeLookup"
                          :value="true"
                          >Yes</b-form-radio
                        >
                        <b-form-radio
                          v-model="formData.useCompanyCodeLookup"
                          :value="false"
                          >No</b-form-radio
                        >
                      </b-form-group>
                    </div>

                    <!-- Company Lookup Code -->
                    <div
                      class="mb-4 p-4 border border-secondary rounded"
                      v-if="shouldShowCompanyLookup"
                    >
                      <label for="companyCodeLookup"
                        >Company's CAGE code or DUNS number *</label
                      >
                      <b-row>
                        <b-col class="py-1" sm="12" md="6">
                          <div class="form-label-group">
                            <input
                              type="text"
                              id="companyCodeLookup"
                              class="form-control"
                              :class="
                                hasError('companyCodeLookup')
                                  ? 'is-invalid'
                                  : ''
                              "
                              v-model="formData.companyCodeLookup"
                            />
                            <div
                              v-if="hasError('companyCodeLookup')"
                              class="invalid-feedback"
                            >
                              <div
                                class="error"
                                v-if="!$v.formData.companyCodeLookup.required"
                              >
                                Please provide your company's CAGE code or DUNS
                                number.
                              </div>
                            </div>
                            <small
                              id="passwordHelpBlock"
                              class="form-text text-muted"
                            >
                              5 character alpha-numeric CAGE code, or 9
                              character numeric DUNS number
                            </small>
                          </div>
                        </b-col>
                        <b-col class="py-1" sm="12" md="6">
                          <b-button
                            @click="onLookupCageCodeOrDuns"
                            variant="btn w-100 rounded centered-link theme-btn-secondary"
                            >Lookup Code</b-button
                          >
                          <b-alert
                            variant="success"
                            v-if="companyLookupSuccessMessage"
                            class="p-2 px-3 my-1"
                            show
                            >{{ companyLookupSuccessMessage }}</b-alert
                          >
                          <b-alert
                            variant="danger"
                            v-if="companyLookupErrorMessage"
                            class="p-2 px-3 my-1"
                            show
                            >{{ companyLookupErrorMessage }}</b-alert
                          >
                        </b-col>
                      </b-row>
                    </div>
                    <b-row>
                      <!-- DUNS Number -->
                      <b-col class="py-1" sm="12" md="6">
                        <div class="form-group">
                          <label for="DUNSNumber">Company DUNS Number*</label>
                          <input
                            type="text"
                            class="form-control"
                            :class="hasError('DUNSNumber') ? 'is-invalid' : ''"
                            placeholder="DUNS Number"
                            v-model="formData.DUNSNumber"
                          />
                          <small
                            id="passwordHelpBlock"
                            class="form-text text-muted"
                            v-if="!hasError('DUNSNumber')"
                          >
                            9 character numeric identifier
                          </small>
                          <div
                            v-if="hasError('DUNSNumber')"
                            class="invalid-feedback"
                          >
                            <div
                              class="error"
                              v-if="!$v.formData.DUNSNumber.required"
                            >
                              Enter your company's 9 character numeric DUNS
                              Number
                            </div>
                          </div>
                        </div>
                      </b-col>
                      <!-- CAGE Code -->
                      <b-col class="py-1" sm="12" md="6">
                        <div class="form-group">
                          <label for="CAGECode">Company CAGE Code*</label>
                          <input
                            type="text"
                            class="form-control"
                            :class="hasError('CAGECode') ? 'is-invalid' : ''"
                            placeholder="CAGE code"
                            v-model="formData.CAGECode"
                          />
                          <small
                            id="passwordHelpBlock"
                            class="form-text text-muted"
                            v-if="!hasError('CAGECode')"
                          >
                            5 character alpha-numeric identifier
                          </small>
                          <div
                            v-if="hasError('CAGECode')"
                            class="invalid-feedback"
                          >
                            <div
                              class="error"
                              v-if="!$v.formData.CAGECode.required"
                            >
                              Enter your company's 5 character alpha-numeric
                              CAGE Code
                            </div>
                          </div>
                        </div>
                      </b-col>
                      <!-- NAICS Codes -->
                      <b-col class="py-1" sm="12" md="12">
                        <div class="form-group">
                          <label for="NAICSCodes">NAICS Codes*</label>
                          <input
                            type="text"
                            class="form-control"
                            :class="hasError('NAICSCodes') ? 'is-invalid' : ''"
                            placeholder="NAICS codes (511120,...)"
                            v-model="formData.NAICSCodes"
                          />
                          <small
                            id="passwordHelpBlock"
                            class="form-text text-muted"
                            v-if="!hasError('NAICSCodes')"
                          >
                            Numerics separated by space or comma.
                          </small>
                          <div
                            v-if="hasError('NAICSCodes')"
                            class="invalid-feedback"
                          >
                            <div
                              class="error"
                              v-if="!$v.formData.NAICSCodes.required"
                            >
                              Enter your company's numeric NAICS codes,
                              separated by spaces or commas (#,#,#...)
                            </div>
                          </div>
                        </div>
                      </b-col>

                      <!-- Street Address 1 -->
                      <b-col class="py-1" sm="12" md="6">
                        <div class="form-group">
                          <label for="streetaddress1">Street Address 1*</label>
                          <input
                            type="text"
                            class="form-control"
                            :class="
                              hasError('streetaddress1') ? 'is-invalid' : ''
                            "
                            placeholder="Address"
                            v-model="formData.streetaddress1"
                          />
                          <small
                            id="passwordHelpBlock"
                            class="form-text text-muted"
                            v-if="!hasError('streetaddress1')"
                          >
                            Street address, P.O. box, company name
                          </small>
                          <div
                            v-if="hasError('streetaddress1')"
                            class="invalid-feedback"
                          >
                            <div
                              class="error"
                              v-if="!$v.formData.streetaddress1.required"
                            >
                              Please provide organization street address
                            </div>
                          </div>
                        </div>
                      </b-col>
                      <!-- Street Address 2 -->
                      <b-col class="py-1" sm="12" md="6">
                        <div class="form-group">
                          <label for="streetaddress2">Street Address 2</label>
                          <input
                            type="text"
                            class="form-control"
                            :class="
                              hasError('streetaddress2') ? 'is-invalid' : ''
                            "
                            placeholder="Address Continued..."
                            v-model="formData.streetaddress2"
                          />
                          <small
                            id="passwordHelpBlock"
                            class="form-text text-muted"
                            v-if="!hasError('streetaddress2')"
                          >
                            Apartment, suite, unit, building, floor, etc.
                          </small>
                        </div>
                      </b-col>
                      <!-- City -->
                      <b-col class="py-1" sm="12" md="6">
                        <div class="form-group">
                          <label for="city">City*</label>
                          <input
                            type="text"
                            class="form-control"
                            :class="hasError('city') ? 'is-invalid' : ''"
                            placeholder="City"
                            v-model="formData.city"
                          />
                          <div v-if="hasError('city')" class="invalid-feedback">
                            <div
                              class="error"
                              v-if="!$v.formData.city.required"
                            >
                              Please provide organization city
                            </div>
                          </div>
                        </div>
                      </b-col>
                      <!-- State -->
                      <b-col class="py-1" sm="12" md="6">
                        <div class="form-group">
                          <label for="state">State*</label>
                          <select
                            :class="hasError('state') ? 'is-invalid' : ''"
                            class="form-control"
                            v-model="formData.state"
                          >
                            <option value="">Select State</option>
                            <option value="AL">Alabama</option>
                            <option value="AK">Alaska</option>
                            <option value="AZ">Arizona</option>
                            <option value="AR">Arkansas</option>
                            <option value="CA">California</option>
                            <option value="CO">Colorado</option>
                            <option value="CT">Connecticut</option>
                            <option value="DE">Delaware</option>
                            <option value="DC">District Of Columbia</option>
                            <option value="FL">Florida</option>
                            <option value="GA">Georgia</option>
                            <option value="HI">Hawaii</option>
                            <option value="ID">Idaho</option>
                            <option value="IL">Illinois</option>
                            <option value="IN">Indiana</option>
                            <option value="IA">Iowa</option>
                            <option value="KS">Kansas</option>
                            <option value="KY">Kentucky</option>
                            <option value="LA">Louisiana</option>
                            <option value="ME">Maine</option>
                            <option value="MD">Maryland</option>
                            <option value="MA">Massachusetts</option>
                            <option value="MI">Michigan</option>
                            <option value="MN">Minnesota</option>
                            <option value="MS">Mississippi</option>
                            <option value="MO">Missouri</option>
                            <option value="MT">Montana</option>
                            <option value="NE">Nebraska</option>
                            <option value="NV">Nevada</option>
                            <option value="NH">New Hampshire</option>
                            <option value="NJ">New Jersey</option>
                            <option value="NM">New Mexico</option>
                            <option value="NY">New York</option>
                            <option value="NC">North Carolina</option>
                            <option value="ND">North Dakota</option>
                            <option value="OH">Ohio</option>
                            <option value="OK">Oklahoma</option>
                            <option value="OR">Oregon</option>
                            <option value="PA">Pennsylvania</option>
                            <option value="RI">Rhode Island</option>
                            <option value="SC">South Carolina</option>
                            <option value="SD">South Dakota</option>
                            <option value="TN">Tennessee</option>
                            <option value="TX">Texas</option>
                            <option value="UT">Utah</option>
                            <option value="VT">Vermont</option>
                            <option value="VA">Virginia</option>
                            <option value="WA">Washington</option>
                            <option value="WV">West Virginia</option>
                            <option value="WI">Wisconsin</option>
                            <option value="WY">Wyoming</option>
                          </select>
                          <div
                            v-if="hasError('state')"
                            class="invalid-feedback"
                          >
                            <div
                              class="error"
                              v-if="!$v.formData.state.required"
                            >
                              Please provide organization state
                            </div>
                          </div>
                        </div>
                      </b-col>
                      <!-- Zip Code -->
                      <b-col class="py-1" sm="12" md="6">
                        <div class="form-group">
                          <label for="zipCode">Zip Code*</label>
                          <input
                            type="number"
                            class="form-control"
                            :class="hasError('zipCode') ? 'is-invalid' : ''"
                            placeholder="Zip Code"
                            v-model="formData.zipCode"
                          />
                          <div
                            v-if="hasError('zipCode')"
                            class="invalid-feedback"
                          >
                            <div
                              class="error"
                              v-if="!$v.formData.zipCode.required"
                            >
                              Please provide your organiztions zip code
                            </div>
                          </div>
                        </div>
                      </b-col>
                      <!-- Country -->
                      <b-col class="py-1" sm="12" md="6">
                        <div class="form-group">
                          <label for="country">Country</label>
                          <input
                            type="text"
                            class="form-control"
                            :class="hasError('country') ? 'is-invalid' : ''"
                            placeholder="Country"
                            v-model="formData.country"
                          />
                        </div>
                      </b-col>

                      <!-- Organization Type -->
                      <!-- <hr class="col-sm-12" />
                      <h5 class="p-0 py-4 col-sm-12">Company Details</h5> -->

                      <!-- Which one of the following are you familiar with? -->
                      <b-col sm="12">
                        <b-form-group
                          label="With which of the following are you familiar?*"
                        >
                          <b-form-checkbox-group
                            id="familiarWith"
                            name="familiarWith"
                            v-model="formData.familiarWith"
                            :class="
                              hasError('familiarWith') ? 'is-invalid' : ''
                            "
                          >
                            <b-form-checkbox
                              v-model="formData.familiarWith"
                              value="MEP"
                              >MEP</b-form-checkbox
                            >
                            <b-form-checkbox
                              v-model="formData.familiarWith"
                              value="PTAC"
                              >PTAC</b-form-checkbox
                            >
                            <b-form-checkbox
                              v-model="formData.familiarWith"
                              value="SBDC"
                              >SBDC</b-form-checkbox
                            >
                            <b-form-checkbox
                              v-model="formData.familiarWith"
                              value="None"
                              >None</b-form-checkbox
                            >
                          </b-form-checkbox-group>

                          <div
                            v-if="hasError('familiarWith')"
                            class="invalid-feedback"
                          >
                            <div
                              class="error"
                              v-if="!$v.formData.familiarWith.required"
                            >
                              Please check with which program(s) you are
                              familiar.
                            </div>
                          </div>
                        </b-form-group>
                      </b-col>

                      <!-- With which lead center have you worked with?* -->
                      <b-col
                        sm="12"
                        class="py-0"
                        v-if="formData.familiarWith.includes('MEP')"
                      >
                        <div class="form-group">
                          <label for="localCenter"
                            >With which lead center have you worked
                            with?*</label
                          >
                          <select
                            :class="hasError('localCenter') ? 'is-invalid' : ''"
                            class="form-control"
                            v-model="formData.localCenter"
                          >
                            <option value="">Select...</option>
                            <option value="Alabama--Alabama Technology Network">
                              Alabama--Alabama Technology Network
                            </option>
                            <option
                              value="Alaska--Alaska Manufacturing Extension Partnership Center"
                            >
                              Alaska--Alaska Manufacturing Extension Partnership
                              Center
                            </option>
                            <option
                              value="Arizona--Arizona Manufacturing Extension Partnership (Arizona MEP)"
                            >
                              Arizona--Arizona Manufacturing Extension
                              Partnership (Arizona MEP)
                            </option>
                            <option
                              value="Arkansas--Arkansas Manufacturing Solutions"
                            >
                              Arkansas--Arkansas Manufacturing Solutions
                            </option>
                            <option
                              value="California--California Manufacturing Technology Consulting (CMTC)"
                            >
                              California--California Manufacturing Technology
                              Consulting (CMTC)
                            </option>
                            <option value="Colorado--Manufacturer's Edge">
                              Colorado--Manufacturer's Edge
                            </option>
                            <option value="Connecticut--CONNSTEP">
                              Connecticut--CONNSTEP
                            </option>
                            <option
                              value="Delaware--Delaware Manufacturing Extension Partnership (DEMEP)"
                            >
                              Delaware--Delaware Manufacturing Extension
                              Partnership (DEMEP)
                            </option>
                            <option value="Florida--FloridaMakes">
                              Florida--FloridaMakes
                            </option>
                            <option
                              value="Georgia--Georgia Manufacturing Extension Partnership (GaMEP)"
                            >
                              Georgia--Georgia Manufacturing Extension
                              Partnership (GaMEP)
                            </option>
                            <option value="Hawaii--INNOVATE Hawaii">
                              Hawaii--INNOVATE Hawaii
                            </option>
                            <option value="Idaho--TechHelp">
                              Idaho--TechHelp
                            </option>
                            <option
                              value="Illinois--Illinois Manufacturing Excellence Center (IMEC)"
                            >
                              Illinois--Illinois Manufacturing Excellence Center
                              (IMEC)
                            </option>
                            <option
                              value="Indiana--Purdue Manufacturing Extension Partnership"
                            >
                              Indiana--Purdue Manufacturing Extension
                              Partnership
                            </option>
                            <option
                              value="Iowa--Center for Industrial Research and Service (CIRAS)"
                            >
                              Iowa--Center for Industrial Research and Service
                              (CIRAS)
                            </option>
                            <option
                              value="Kansas--Kansas Manufacturing Solutions"
                            >
                              Kansas--Kansas Manufacturing Solutions
                            </option>
                            <option
                              value="Kentucky--Advantage Kentucky Alliance (AKA)"
                            >
                              Kentucky--Advantage Kentucky Alliance (AKA)
                            </option>
                            <option
                              value="Louisiana--Manufacturing Extension Partnership of Louisiana (MEPOL)"
                            >
                              Louisiana--Manufacturing Extension Partnership of
                              Louisiana (MEPOL)
                            </option>
                            <option
                              value="Maine--Maine Manufacturing Extension Partnership"
                            >
                              Maine--Maine Manufacturing Extension Partnership
                            </option>
                            <option value="Maryland--Maryland MEP">
                              Maryland--Maryland MEP
                            </option>
                            <option
                              value="Massachusetts--Massachusetts Manufacturing Extension Partnership (MassMEP)"
                            >
                              Massachusetts--Massachusetts Manufacturing
                              Extension Partnership (MassMEP)
                            </option>
                            <option
                              value="Michigan--Michigan Manufacturing Technology Center (MMTC)"
                            >
                              Michigan--Michigan Manufacturing Technology Center
                              (MMTC)
                            </option>
                            <option value="Minnesota--Enterprise Minnesota">
                              Minnesota--Enterprise Minnesota
                            </option>
                            <option
                              value="Mississippi--Mississippi Manufacturers Association Manufacturing Extension Partnership (MMA--MEP)"
                            >
                              Mississippi--Mississippi Manufacturers Association
                              Manufacturing Extension Partnership (MMA--MEP)
                            </option>
                            <option value="Missouri--Missouri Enterprise">
                              Missouri--Missouri Enterprise
                            </option>
                            <option
                              value="Montana--Montana Manufacturing Extension Center (MMEC)"
                            >
                              Montana--Montana Manufacturing Extension Center
                              (MMEC)
                            </option>
                            <option
                              value="Nebraska--Nebraska Manufacturing Extension Partnership (Nebraska MEP)"
                            >
                              Nebraska--Nebraska Manufacturing Extension
                              Partnership (Nebraska MEP)
                            </option>
                            <option
                              value="Nevada--Nevada Industry Excellence (NVIE)"
                            >
                              Nevada--Nevada Industry Excellence (NVIE)
                            </option>
                            <option
                              value="New Hampshire--New Hampshire Manufacturing Extension Partnership (NHMEP)"
                            >
                              New Hampshire--New Hampshire Manufacturing
                              Extension Partnership (NHMEP)
                            </option>
                            <option
                              value="New Jersey--New Jersey Manufacturing Extension Program (NJMEP)"
                            >
                              New Jersey--New Jersey Manufacturing Extension
                              Program (NJMEP)
                            </option>
                            <option
                              value="New Mexico--New Mexico Manufacturing Extension Partnership (New Mexico MEP)"
                            >
                              New Mexico--New Mexico Manufacturing Extension
                              Partnership (New Mexico MEP)
                            </option>
                            <option
                              value="New York--New York Manufacturing Extension Partnership  (NY MEP)"
                            >
                              New York--New York Manufacturing Extension
                              Partnership (NY MEP)
                            </option>
                            <option
                              value="North Carolina--North Carolina Manufacturing Extension Partnership (NCMEP)"
                            >
                              North Carolina--North Carolina Manufacturing
                              Extension Partnership (NCMEP)
                            </option>
                            <option value="North Dakota--Impact Dakota">
                              North Dakota--Impact Dakota
                            </option>
                            <option
                              value="Ohio--Ohio Manufacturing Extension Partnership (Ohio MEP)"
                            >
                              Ohio--Ohio Manufacturing Extension Partnership
                              (Ohio MEP)
                            </option>
                            <option
                              value="Oklahoma--Oklahoma Manufacturing Alliance"
                            >
                              Oklahoma--Oklahoma Manufacturing Alliance
                            </option>
                            <option
                              value="Oregon--Oregon Manufacturing Extension Partnership"
                            >
                              Oregon--Oregon Manufacturing Extension Partnership
                            </option>
                            <option
                              value="Pennsylvania--Pennsylvania Manufacturing Extension Partnership"
                            >
                              Pennsylvania--Pennsylvania Manufacturing Extension
                              Partnership
                            </option>
                            <option
                              value="Puerto Rico--Puerto Rico Manufacturing Extension Inc. (PRiMEX)"
                            >
                              Puerto Rico--Puerto Rico Manufacturing Extension
                              Inc. (PRiMEX)
                            </option>
                            <option value="Rhode Island--Polaris MEP">
                              Rhode Island--Polaris MEP
                            </option>
                            <option
                              value="South Carolina--South Carolina Manufacturing Extension Partnership (SCMEP)"
                            >
                              South Carolina--South Carolina Manufacturing
                              Extension Partnership (SCMEP)
                            </option>
                            <option
                              value="South Dakota--South Dakota Manufacturing and Technology Solutions"
                            >
                              South Dakota--South Dakota Manufacturing and
                              Technology Solutions
                            </option>
                            <option
                              value="Tennessee--University of Tennessee Center for Industrial Services (UT CIS)"
                            >
                              Tennessee--University of Tennessee Center for
                              Industrial Services (UT CIS)
                            </option>
                            <option value="Texas--TMAC">Texas--TMAC</option>
                            <option
                              value="Utah--University of Utah Manufacturing Extension Partnership (UUMEP) Center"
                            >
                              Utah--University of Utah Manufacturing Extension
                              Partnership (UUMEP) Center
                            </option>
                            <option
                              value="Vermont--Vermont Manufacturing Extension Center (VMEC)"
                            >
                              Vermont--Vermont Manufacturing Extension Center
                              (VMEC)
                            </option>
                            <option value="Virginia--GENEDGE">
                              Virginia--GENEDGE
                            </option>
                            <option value="Washington--Impact Washington">
                              Washington--Impact Washington
                            </option>
                            <option
                              value="West Virginia--West Virginia Manufacturing Extension Partnership (WVMEP)"
                            >
                              West Virginia--West Virginia Manufacturing
                              Extension Partnership (WVMEP)
                            </option>
                            <option
                              value="Wisconsin--Wisconsin Center for Manufacturing and Productivity (WCMP)"
                            >
                              Wisconsin--Wisconsin Center for Manufacturing and
                              Productivity (WCMP)
                            </option>
                            <option value="Wyoming--Manufacturing Works">
                              Wyoming--Manufacturing Works
                            </option>
                            <option
                              value="I have not worked with an MEP office"
                            >
                              I have not worked with an MEP office
                            </option>
                            <option value="Other">Other</option>
                          </select>
                          <div
                            v-if="hasError('localCenter')"
                            class="invalid-feedback"
                          >
                            <div
                              class="error"
                              v-if="!$v.formData.localCenter.required"
                            >
                              Please provide which lead center you worked with.
                            </div>
                          </div>
                        </div>
                      </b-col>

                      <!-- Do you use internal IT or third party provider? -->
                      <b-col
                        sm="12"
                        class="py-2"
                        v-if="formData.familiarWith.includes('MEP')"
                      >
                        <b-form-group
                          label="Do you use internal IT or third party provider?"
                        >
                          <b-form-radio-group
                            id="ITProvider"
                            name="ITProvider"
                            v-model="formData.ITProvider"
                            :class="hasError('ITProvider') ? 'is-invalid' : ''"
                          >
                            <b-form-radio
                              v-model="formData.womenOwned"
                              value="internal"
                              >Internal</b-form-radio
                            >
                            <b-form-radio
                              v-model="formData.womenOwned"
                              value="thirdParty"
                              >Third Party</b-form-radio
                            >
                          </b-form-radio-group>

                          <div
                            v-if="hasError('ITProvider')"
                            class="invalid-feedback"
                          >
                            <div
                              class="error"
                              v-if="!$v.formData.ITProvider.required"
                            >
                              Please answer if your company uses internal of
                              third-party IT.
                            </div>
                          </div>
                        </b-form-group>
                      </b-col>

                      <b-col sm="12" class="pt-0 pb-4">
                        <b-form-checkbox
                          id="checkbox-1"
                          v-model="formData.optInForMEP"
                          name="checkbox-1"
                        >
                          Please check this box if you want to receive more
                          information about the MEP.
                        </b-form-checkbox>
                        <i class="font-weight-light"
                          >By checking this box, you agree to have your contact
                          information shared with your state MEP office.</i
                        >
                      </b-col>

                      <!-- End -->
                    </b-row>
                  </div>
                </tab-content>
              </form-wizard>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Register Area End-->
  </div>
</template>
<script>
import ValidationHelper from '../vue-step-wizard/ValidationHelper.vue';
import TabContent from '../vue-step-wizard/TabContent.vue';
import FormWizard from '../vue-step-wizard/FormWizard.vue';
import {
  handleApiError,
  isPhoneNumberVerified,
  getUserEmail,
  storeAuthTokens,
  isLoggedIn,
  isEnabledMFA,
} from '../../utils/authentication';
import axios from 'axios';

import {
  required,
  requiredIf,
  email,
  numeric,
  minLength,
  sameAs,
} from 'vuelidate/lib/validators';

//component code
export default {
  name: 'my-account',
  components: {
    FormWizard,
    TabContent,
  },
  mixins: [ValidationHelper],
  mounted() {
    this.init();
  },
  data() {
    return {
      errorMessage: '',
      successMessage: '',
      hasDataBeenPopulatedFromAccount: false,
      submissionSuccessful: false,
      customButtonLabel: 'Submit',
      companyLookupSuccessMessage: '',
      companyLookupErrorMessage: '',
      formData: {
        // Initial Input
        useCompanyCodeLookup: null,
        companyCodeLookup: '',
        // Submission Details
        firstName: '',
        lastName: '',
        // Company Information
        CAGECode: '',
        NAICSCodes: '',
        DUNSNumber: '',
        streetaddress1: '',
        streetaddress2: '',
        city: '',
        state: '',
        zipCode: '',
        country: '',
        // Company Details
        familiarWith: [],
        localCenter: '',
        ITProvider: '',
        optInForMEP: false,
      },
      validationRules: [
        {
          // Initial Input
          useCompanyCodeLookup: { required },
          companyCodeLookup: {},
          // Submission Details
          firstName: { required },
          lastName: { required },
          // Company Information
          CAGECode: { required },
          NAICSCodes: { required },
          DUNSNumber: { required },
          streetaddress1: { required },
          streetaddress2: {},
          city: { required },
          state: { required },
          zipCode: { required },
          country: { required },
          // Company Details
          familiarWith: { required, minLength: minLength(1) },
          localCenter: {
            required: requiredIf(function () {
              return this.formData.familiarWith.includes('MEP');
            }),
          },
          ITProvider: {
            required: requiredIf(function () {
              return this.formData.familiarWith.includes('MEP');
            }),
          },
          optInForMEP: {},
        },
      ],
    };
  },
  beforeMount() {
    this.getAccountData();
    this.getSubmissionMEP();
  },
  computed: {
    shouldShowCompanyLookup() {
      return this.formData.useCompanyCodeLookup;
    },
    isLoggedIn() {
      return isLoggedIn();
    },
    isEnabledMFA() {
      return isEnabledMFA();
    },
  },
  methods: {
    init() {
      /* The footer height no longer needs to be set */
      // this.initFooterHeight();
    },
    initFooterHeight() {
      var footerHeight = $('footer').outerHeight();
      $('.main-content').css('margin-bottom', footerHeight);
      // $('.main-content').css('min-height', '100vh');
    },
    onCannotCompleteFromErrors() {
      this.errorMessage = 'Please correct all form errors before submitting';
      this.successMessage = '';
    },
    getAccountData() {
      axios
        .get(`/api/user/`)
        .then((response) => {
          this.handleUserGetResponce(response);
        })
        .catch((error) => {
          handleApiError(error.response, this.$router);
        });
    },
    getSubmissionMEP() {
      axios
        .get(`/api/mep/`)
        .then((response) => {
          this.handleMEPGetResponce(response);
        })
        .catch((error) => {
          handleApiError(error.response, this.$router);
        });
    },
    handleUserGetResponce(response) {
      /** Map response json to the form data */
      //console.log('User Data received', response);
      try {
        if (response.data) {
          this.hasDataBeenPopulatedFromAccount = true;
          this.formData.useCompanyCodeLookup = false;
        }
        this.formData.firstName = response.data.name?.split(' ')[0];
        this.formData.lastName = response.data.name?.split(' ')[1];
        this.formData.streetaddress1 = response.data.address1;
        this.formData.streetaddress2 = response.data.address2;
        this.formData.city = response.data.city;
        this.formData.state = response.data.state;
        this.formData.zipCode = response.data.zipcode;
        this.formData.country = response.data.country;
        this.formData.CAGECode = response.data.CAGECode;
        this.formData.DUNSNumber = response.data.DUNSNumber;
        this.formData.NAICSCodes = response.data.NAICSCodes;
      } catch (error) {
        console.error('Error parsing user account data', error);
      }
    },
    handleMEPGetResponce(response) {
      /** Map response json to the form data */
      if (response.data?.isMEP) {
        this.submissionSuccessful = true;
      }
      //console.log('MEP Status received', response);
    },
    onLookupCageCodeOrDuns() {
      this.companyLookupSuccessMessage = '';
      this.companyLookupErrorMessage = '';
      /*console.log(
        'Company Lookup Code',
        this.formData.companyCodeLookup.trim()
      );*/
      if (this.formData.companyCodeLookup.trim().length == 5) {
        /** CAGE Code */
        axios
          .get(`/api/sam/cageCode/${this.formData.companyCodeLookup.trim()}`)
          .then((response) => {
            //console.log('Company Lookup', response);
            if (response.data) {
              this.companyLookupSuccessMessage =
                'Company Information populated';
            } else {
              this.companyLookupErrorMessage =
                'Could not find a company for this code';
            }
            /** Map response json to the form data */
            this.formData.streetaddress1 =
              response.data.electronicBusinessAddress.address1;
            this.formData.streetaddress2 =
              response.data.electronicBusinessAddress.address2;
            this.formData.city = response.data.electronicBusinessAddress.city;
            this.formData.state = response.data.electronicBusinessAddress.state;
            this.formData.zipCode = response.data.electronicBusinessAddress.zip;
            this.formData.country =
              response.data.electronicBusinessAddress.countryCode;
            this.formData.CAGECode = response.data.cageCode;
            this.formData.DUNSNumber = response.data.duns;
            this.formData.NAICSCodes = response.data.primaryNaics;
          })
          .catch((error) => {
            console.error(error);
          });
      } else if (this.formData.companyCodeLookup.trim().length == 9) {
        /** DUNS Number */
        axios
          .get(`/api/sam/duns/${this.formData.companyCodeLookup.trim()}`)
          .then((response) => {
            //console.log('Company Lookup', response);
            if (response.data) {
              this.companyLookupSuccessMessage =
                'Company Information populated';
            } else {
              this.companyLookupErrorMessage =
                'Could not find a company for this code';
            }
            /** Map response json to the form data */
            this.formData.streetaddress1 =
              response.data.electronicBusinessAddress.address1;
            this.formData.streetaddress2 =
              response.data.electronicBusinessAddress.address2;
            this.formData.city = response.data.electronicBusinessAddress.city;
            this.formData.state = response.data.electronicBusinessAddress.state;
            this.formData.zipCode = response.data.electronicBusinessAddress.zip;
            this.formData.country =
              response.data.electronicBusinessAddress.countryCode;
            this.formData.CAGECode = response.data.cageCode;
            this.formData.DUNSNumber = response.data.duns;
            this.formData.NAICSCodes = response.data.primaryNaics;
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        console.warn(
          'CAGE or DUNS of bad length passed',
          this.formData.companyCode
        );
        this.companyLookupErrorMessage =
          'Must be 5 (CAGE) or 9 (DUNS) character code';
      }
    },
    onComplete() {
      //console.log('OnComplete');
      this.errorMessage = '';
      this.successMessage = '';
      const body = this.formData;
      body.name = `${this.formData.firstName} ${this.formData.lastName}`;

      //console.log('Submit', body);

      axios
        .post('/api/mep/', body)
        .then((response) => {
          //console.log('MEP Submit Successful', response);
          /** Account updated seccessfully */
          this.errorMessage = '';
          this.successMessage = 'Submission Successful';
          this.submissionSuccessful = true;
        })
        .catch((error) => {
          this.successMessage = '';
          /** Error creating account */
          this.errorMessage = error.response.data.message;
          console.error(error.response.data.message);
        });
    },
  },
};
</script>
<style scoped>
.form-control {
  height: 45px;
}

hr {
  margin-top: 2rem;
  padding: 0px;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.b-calendar {
  height: auto !important;
  font-size: inherit !important;
}
</style>
