<template>
  <div class="main-content bg-light">
    <!-- BradCrumb header Start-->
    <section class="inner-header-bg">
      <div class="container">
        <!-- Breadcrumb Start-->
        <div class="section-breadcrumb">
          <div class="container">
            <div class="row">
              <div
                class="col-md-12 justify-content-center align-self-center pb-2"
              >
                <div class="breadcrumb-content">
                  <div class="title-section">
                    <h1 class="title text-center text-white">
                      Update Password
                    </h1>
                  </div>
                  <div class="breadcrumb-nav">
                    <ul class="breadcrumb-link text-center list-unstyled mb-0">
                      <li class="text-white">
                        <i class="mdi mdi-home theme-light-color pr-2"></i>
                        <a href="index.html">Home</a>
                      </li>
                      <li class="text-white">
                        <a href="/login">Login</a>
                      </li>
                      <li class="theme-light-color active">Update Password</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Breadcrumb End -->
      </div>
    </section>
    <!-- BradCrumb header End -->
    <!-- Login Start-->
    <section class="login-form section bg-light">
      <!-- Overlay -->
      <b-overlay :show="busy" no-wrap>
        <template #overlay>
          <b-spinner label="Loading..."></b-spinner>
        </template>
      </b-overlay>
      <!-- MFA Verify Container -->
      <div class="container bg-white">
        <div class="login-container">
          <div class="row justify-content-center">
            <div class="d-none d-lg-block col-lg-6 form-thumbnail">
              <div class="thumboverlay"></div>
            </div>
            <div class="col-lg-6">
              <form @submit.prevent class="form-body">
                <div class="text-center section-title">
                  <h2 class="text-center">Update Password</h2>
                </div>
                <div class="mt-4">
                  <div class="row">
                    <div class="col-12">
                      <div class="form-group">
                        <p>
                          Please update your account password. Enter your old
                          and new passwords below.
                        </p>
                        <!-- Email Address -->
                        <b-col class="p-0" sm="12">
                          <div class="form-group">
                            <label for="email">Your Email</label>
                            <input
                              type="email"
                              class="form-control"
                              :class="hasError('email') ? 'is-invalid' : ''"
                              placeholder="Email Address"
                              v-model="form.email"
                            />
                            <div
                              v-if="hasError('email')"
                              class="invalid-feedback"
                            >
                              <div class="error" v-if="!$v.form.email.required">
                                Email address is required
                              </div>
                              <div class="error" v-if="!$v.form.email.email">
                                Should be in email format
                              </div>
                            </div>
                          </div>
                        </b-col>

                        <!-- Old Password -->
                        <b-col class="p-0" sm="12">
                          <div class="form-group">
                            <label for="oldPassword">Current Password</label>
                            <input
                              type="password"
                              class="form-control"
                              :class="
                                hasError('oldPassword') ? 'is-invalid' : ''
                              "
                              placeholder="Current Password"
                              v-model="form.oldPassword"
                              autocomplete="password"
                            />
                            <div
                              v-if="hasError('oldPassword')"
                              class="invalid-feedback"
                            >
                              <div
                                class="error"
                                v-if="!$v.form.oldPassword.required"
                              >
                                Your current password is required
                              </div>
                            </div>
                          </div>
                        </b-col>
                        <!-- Password -->
                        <b-col class="p-0" sm="12">
                          <div class="form-group">
                            <label for="password">Password</label>
                            <input
                              type="password"
                              class="form-control"
                              :class="hasError('password') ? 'is-invalid' : ''"
                              placeholder="Password"
                              v-model="form.password"
                              autocomplete="password"
                            />
                            <div
                              v-if="hasError('password')"
                              class="invalid-feedback"
                            >
                              <div
                                class="error"
                                v-if="!$v.form.password.required"
                              >
                                A new password is required
                              </div>
                              <div
                                class="error"
                                v-if="!$v.form.password.goodPassword"
                              >
                                Must be at least 8 characters and contain a
                                lowercase, uppercase, number and special
                                character.
                              </div>
                            </div>
                            <small
                              id="passwordHelpBlock"
                              class="form-text text-muted"
                              v-if="!hasError('password')"
                            >
                              Must be at least 8 characters and contain a
                              lowercase, uppercase, number and special
                              character.
                            </small>
                          </div>
                        </b-col>
                        <!-- Confirm Password -->
                        <b-col class="p-0" sm="12">
                          <div class="form-group">
                            <label for="confirmPassword"
                              >Confirm Password</label
                            >
                            <input
                              type="password"
                              class="form-control"
                              :class="
                                hasError('confirmPassword') ? 'is-invalid' : ''
                              "
                              placeholder="Confirm Password"
                              v-model="form.confirmPassword"
                              autocomplete="password"
                            />
                            <div
                              v-if="hasError('confirmPassword')"
                              class="invalid-feedback"
                            >
                              <div
                                class="error"
                                v-if="!$v.form.confirmPassword.required"
                              >
                                Please confirm your new password
                              </div>
                              <div
                                class="error"
                                v-if="
                                  $v.form.confirmPassword.required &&
                                  !$v.form.confirmPassword.sameAs
                                "
                              >
                                Please re-enter the password given above
                              </div>
                            </div>
                          </div>
                        </b-col>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12 text-center">
                      <div class="alert alert-danger" v-if="!!error">
                        {{ error }}
                      </div>
                      <div class="alert alert-success" v-if="!!info & !error">
                        {{ info }}
                      </div>
                      <button
                        type="button"
                        @click="submit"
                        class="btn theme-btn rounded w-100"
                      >
                        Update Password
                      </button>
                    </div>
                    <div class="col-sm-12 mt-5">
                      <div class="lost-password text-center">
                        <span
                          ><a href="#" class="theme-light-color">
                            Don't have an account? Create one</a
                          ></span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Login End -->
  </div>
</template>
<script>
import axios from 'axios';
import { storeAuthTokens, isLoggedIn, setUser } from '../utils/authentication';
import { required, email, sameAs } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
export default {
  name: 'verify',
  props: ['session', 'username', 'redirect'],
  mixins: [validationMixin],
  data() {
    return {
      busy: false,
      error: '',
      info: '',
      form: {
        email: this.username,
        oldPassword: '',
        password: '',
        confirmPassword: '',
      },
    };
  },
  validations: {
    form: {
      email: { required, email },
      oldPassword: { required },
      password: {
        required,
        goodPassword: (password) => {
          //a custom validator!
          return (
            password.length >= 8 &&
            /[a-z]/.test(password) &&
            /[A-Z]/.test(password) &&
            /[0-9]/.test(password) &&
            /[#?!@$%^&*-]/.test(password)
          );
        },
      },
      confirmPassword: { required, sameAsPassword: sameAs('password') },
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      /* The footer height no longer needs to be set */
      // this.initFooterHeight();
    },
    initFooterHeight() {
      var footerHeight = $('footer').outerHeight();
      $('.main-content').css('margin-bottom', footerHeight);
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    hasError(fieldName) {
      return fieldName in this.$v.form && this.$v.form[fieldName].$error;
    },
    submit() {
      // Verify the form input fields
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      this.busy = true;
      this.info = '';
      this.error = '';
      axios
        .post('api/user/password/handleNewPassword', {
          username: this.form.email,
          oldPassword: this.form.oldPassword,
          newPassword: this.form.password,
        })
        .then((response) => {
          //console.log('New Password', response);
          /** Redirect to the link from the query params, or account info */
          if (this.redirect) {
            this.$router.push({ path: this.redirect });
          } else {
            this.$router.push({ path: 'login' });
          }
        })
        .catch((error) => {
          this.error = error.response.data.message
            ? error.response.data.message
            : error.response.data;
          console.error(error.response);
        })
        .finally(() => {
          this.busy = false;
        });
    },
  },
};
</script>
