var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "main-content"
  }, [_vm._m(0), _c('section', {
    staticClass: "section"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('p', [_vm._v(" Our Cyber Readiness Training videos cover all NIST 800-171, CMMC Level 1 and CMMC Level 2 controls in a fun and engaging way. Prior to taking one of our Cyber Readiness Checks, please take some time to enjoy these videos and prepare yourself to conduct your self assessments. For your convenience, these videos are also available within the readiness checks for direct access. ")]), !_vm.isLoggedIn ? _c('div', {
    staticClass: "my-3 text-center"
  }, [_c('br'), _vm._v("Please "), _c('router-link', {
    attrs: {
      "to": {
        name: 'login'
      }
    }
  }, [_vm._v("Login ")]), _vm._v(" or "), _c('router-link', {
    attrs: {
      "to": {
        name: 'signup'
      }
    }
  }, [_vm._v("Register ")]), _vm._v(" as organization to view the Cyber Readiness Check Videos."), _c('br'), _c('br'), _c('div', {
    staticClass: "login-register-btn-container mt-2"
  }, [_c('router-link', {
    attrs: {
      "to": {
        name: 'login'
      }
    }
  }, [_c('b-button', {
    staticClass: "btn theme-btn rounded login-reg-btn"
  }, [_vm._v("Login")])], 1), _c('router-link', {
    attrs: {
      "to": {
        name: 'signup'
      }
    }
  }, [_c('b-button', {
    staticClass: "btn theme-btn rounded login-reg-btn",
    attrs: {
      "variant": "none"
    }
  }, [_vm._v("Register")])], 1)], 1)], 1) : _vm._e(), _vm.isLoggedIn ? _c('div', [_c('div', [_c('b-modal', {
    ref: "video-modal",
    attrs: {
      "size": "lg",
      "hide-footer": "",
      "hide-header": "",
      "no-close-on-backdrop": "",
      "body-class": "p-0"
    }
  }, [_c('img', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "src": require("../../assets/images/trainingVideo.png")
    }
  }), _vm.showModal ? _c('div', {
    staticClass: "modal-body-c"
  }, [_c('button', {
    staticClass: "close",
    staticStyle: {
      "position": "absolute",
      "right": ".2em",
      "top": ".2em"
    },
    attrs: {
      "type": "button",
      "data-dismiss": "modal",
      "aria-label": "Close"
    }
  }, [_c('span', {
    attrs: {
      "aria-hidden": "true"
    },
    on: {
      "click": _vm.closeModal
    }
  }, [_vm._v("×")])]), _c('h5', [_vm._v(" " + _vm._s(_vm.showingVideo.Title) + " ")]), _c('p', [_vm._v(_vm._s(_vm.showingVideo.Intro) + " ")]), _c('ul', _vm._l(_vm.showingVideo.Videos, function (vid) {
    return _c('li', {
      key: vid
    }, [_vm._v(" " + _vm._s(vid.Control) + " ")]);
  }), 0), _c('button', {
    staticClass: "btn theme-btn wide-button",
    staticStyle: {
      "color": "black"
    },
    on: {
      "click": function ($event) {
        return _vm.openLink(_vm.showingVideo.VideoLink);
      }
    }
  }, [_c('font-awesome-icon', {
    staticClass: "pl-1 pr-1",
    attrs: {
      "size": "lg",
      "icon": ['fa', 'play']
    }
  }), _vm._v(" Play ")], 1), _c('div', {
    staticClass: "pt-2 d-flex align-items-center mt-auto"
  }, [_c('p', {
    staticClass: "blog-info-meta"
  }, [_c('i', {
    staticClass: "mdi pr-1"
  }), _vm._v(_vm._s(_vm.showingVideo.Videos.length) + " Chapters")]), _c('p', {
    staticClass: "blog-category"
  }, [_c('i', {
    staticClass: "mdi mdi-clock-outline pr-1"
  }), _vm._v(_vm._s(_vm.showingVideo.Duration))])]), _c('h6', {
    staticClass: "pt-5"
  }, [_vm._v("Chapters")]), _c('b-table', {
    staticClass: "mt-3",
    attrs: {
      "id": "video-table",
      "items": _vm.showingVideo.Videos,
      "fields": _vm.fields,
      "responsive": "",
      "sortable": "true",
      "borderless": "",
      "hover": ""
    },
    scopedSlots: _vm._u([{
      key: "cell(Link)",
      fn: function (data) {
        return [_c('div', {
          staticClass: "image-and-tool d-flex"
        }, [_c('a', {
          staticStyle: {
            "color": "white"
          },
          attrs: {
            "href": data.item.Link,
            "target": "_blank"
          }
        }, [_c('font-awesome-icon', {
          staticClass: "pl-1 pr-1",
          attrs: {
            "size": "2x",
            "icon": ['fa', 'play-circle']
          }
        })], 1)])];
      }
    }], null, false, 363262476)
  })], 1) : _vm._e()])], 1), _c('div', {
    staticClass: "pb-4"
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Search Videos..."
    },
    model: {
      value: _vm.searchQuery,
      callback: function ($$v) {
        _vm.searchQuery = $$v;
      },
      expression: "searchQuery"
    }
  })], 1), _vm.displayedBlogItems.length === 0 && _vm.searchQuery ? _c('p', {
    staticClass: "pb-2"
  }, [_vm._v(" No items match the search criteria... ")]) : _vm._e(), _vm.displayedBlogItems.length === 0 && !_vm.searchQuery ? _c('p', {
    staticClass: "pb-2"
  }, [_vm._v(" Check back soon for Videos... ")]) : _vm._e(), _c('div', {
    staticClass: "blog-post-grid"
  }, [_c('div', {
    staticClass: "row"
  }, _vm._l(_vm.displayedBlogItems, function (item) {
    return _c('div', {
      key: item.id,
      staticClass: "col-md-6 col-lg-4 mb-5"
    }, [_c('div', {
      staticClass: "blog-item border h-100 cursor-item",
      on: {
        "click": function ($event) {
          return _vm.openModal(item);
        }
      }
    }, [_c('div', {
      staticClass: "videoLink",
      staticStyle: {
        "display": "none"
      }
    }, [_vm._v(_vm._s(item.VideoLink))]), _c('a', {
      staticClass: "blog-post-image crc-video"
    }, [_c('img', {
      staticClass: "cover",
      attrs: {
        "src": item.Thumbnail,
        "alt": item.photoDescription
      }
    })]), _c('div', {
      staticClass: "blog-post-info"
    }, [_c('a', {
      staticClass: "text-dark crc-video"
    }, [_c('h4', {
      staticClass: "blog-post-title"
    }, [_vm._v(" " + _vm._s(item.Title) + " ")])]), _c('div', {
      staticClass: "text-dark crc-video pb-2"
    }, [_c('a', {
      staticClass: "blog-info-meta text-dark"
    }, [_c('p', {
      staticClass: "limit-lines-4"
    }, [_vm._v(_vm._s(item.Intro))])])]), _c('div', {
      staticClass: "post-event-bottom d-flex align-items-center mt-auto"
    }, [_c('a', {
      staticClass: "blog-info-meta text-dark"
    }, [_c('i', {
      staticClass: "mdi pr-1"
    }), _vm._v(_vm._s(item.Videos.length) + " Chapters")]), _c('a', {
      staticClass: "blog-category text-dark"
    }, [_c('i', {
      staticClass: "mdi mdi-clock-outline pr-1"
    }), _vm._v(_vm._s(item.Duration))])]), _vm._m(1, true)])])]);
  }), 0)]), _c('div', [_c('b-pagination', {
    attrs: {
      "id": "blog-pagination",
      "total-rows": _vm.totalRows,
      "per-page": _vm.perPage,
      "align": "center"
    },
    on: {
      "page-click": _vm.pageClicked
    },
    model: {
      value: _vm.currentPage,
      callback: function ($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)]) : _vm._e()])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "inner-header-bg"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "section-breadcrumb"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12 justify-content-center align-self-center pb-2"
  }, [_c('div', {
    staticClass: "breadcrumb-content"
  }, [_c('div', {
    staticClass: "title-section"
  }, [_c('h1', {
    staticClass: "title text-center text-white"
  }, [_vm._v(" Cyber Readiness Check Training ")])]), _c('div', {
    staticClass: "breadcrumb-nav"
  }, [_c('ul', {
    staticClass: "breadcrumb-link text-center list-unstyled mb-0"
  }, [_c('li', {
    staticClass: "text-white"
  }, [_c('i', {
    staticClass: "mdi mdi-home theme-light-color pr-2"
  }), _c('a', {
    attrs: {
      "href": "/#/"
    }
  }, [_vm._v("Home")])]), _c('li', {
    staticClass: "theme-light-color active"
  }, [_vm._v(" Cyber Readiness Check Training ")])])])])])])])])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a', {
    staticClass: "blog-read-more crc-video"
  }, [_c('i', {
    staticClass: "mdi mdi-arrow-right"
  })]);

}]

export { render, staticRenderFns }