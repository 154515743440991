<template>
  <div class="main-content">
    <!-- Start Hero Section -->
    <section class="hero-section gradient-overlay" id="home">
      <div ref="swiper" class="swiper-container main-slider">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <div class="slide-inner slide-main">
              <div class="slide-content text-white text-center">
                <h2 class="mb-1 text-center">APEX ACCELERATORS</h2>
                <p class="text-white text-center h5">
                  Accelerating American Entrepreneurship
                </p>
                <b-button
                  pill
                  variant="outline-light"
                  class="m-1"
                  @click="$router.push('contact')"
                  >Contact Us
                </b-button>
              </div>
            </div>
          </div>
          <!-- Slide 1 - Project Spectrum -->
          <div class="swiper-slide">
            <div class="slide-inner slide-mission">
              <div class="slide-content text-white text-center">
                <h2 class="mb-1 text-center">MISSION</h2>
                <p class="text-white text-center h5">
                  Serve as the axis for existing and new business to strengthen
                  the defense industrial base by accelerating innovation,
                  fostering ingenuity, and establishing resilient and diverse
                  supply chains.
                </p>
              </div>
            </div>
          </div>
          <div class="swiper-slide">
            <div class="slide-inner slide-vision">
              <div class="slide-content text-white text-center">
                <h2 class="mb-1 text-center">VISION</h2>
                <p class="text-white text-center h5">
                  A diverse and resilient domestic industrial base that can
                  deliver preeminent solutions to the military and other
                  government users.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-button-next swiper-button-white"></div>
        <div class="swiper-button-prev swiper-button-white"></div>
      </div>
    </section>
    <!--End Hero Section -->
    <!-- Start About Area -->
    <section class="about-area section" id="aboutus">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6 col-md-12">
            <div class="about-title section-title">
              <span> ENTREPRENEURSHIP FOR SMALL BUSINESSES</span>
              <h2>APEX ACCELERATORS</h2>
              <p>
                <strong> <i> (formerly known as PTAP) </i> </strong>
              </p>
            </div>
          </div>
          <div class="col-lg-6 col-md-12">
            <div class="about-text pl-4">
              <p>
                The APEX Accelerators program, formerly known as Procurement
                Technical Assistance Program (PTAP), under management of the
                Department of Defense (DoD) Office of Small Business Programs
                (OSBP), plays a critical role in the Department’s efforts to
                identify and <a href="/#/about-us">helps</a> a wide range of
                businesses enter and participate in the defense supply-chain.
                The program provides the education and training to ensure that
                all businesses become capable of participating in federal,
                state, and local government contracts.
              </p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <center>
              <div>
                <video
                  style="border: 5px outset #ddd; padding: 3px"
                  width="75%"
                  height="auto"
                  controls
                >
                  <source
                    src="https://assets.apexaccelerators.us/videos/APEX_Final2.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
              <strong>
                Footage captured on 7 Nov 2022 for the unveiling of the
                rebranding of PTACs to APEX Accelerators by DoD OSBP Director
                Farooq A. Mitha.
              </strong>
              <hr />
              <strong>
                APEX Accelerators: Accelerating American Entrepreneurship
              </strong>
            </center>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-12 pt-4 pb-4">
            <div>
              <center>
                <div style="position: relative; padding-top: 42.25%">
                  the map goes here
                  
                  <iframe
                    src="https://customer-vqtiuw508mb7y2yt.cloudflarestream.com/a395110cad9210b0bf531127e6fdb047/iframe?poster=https%3A%2F%2Fcustomer-vqtiuw508mb7y2yt.cloudflarestream.com%2Fa395110cad9210b0bf531127e6fdb047%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600"
                    style="
                      border: 5px outset #ddd;
                      position: absolute;
                      top: 0;
                      left: 13%;
                      height: 100%;
                      width: 75%;
                      padding: 3px;
                    "
                    allow="accelerometer; gyroscope;  encrypted-media; picture-in-picture;"
                    allowfullscreen="true"
                  ></iframe>
                </div>
              </center>
            </div>
            <div>
              <center class="mt-3">
                <strong>
                  Footage captured on 30 Mar 2023 for telling a success story
                  about Arlo Solutions featured by the APEX Accelerators Dir.
                  Khalil Mack.
                </strong>
              </center>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row mb-3">
          <div class="col-12">
            <GoogleLocator />
            <center>
              <div>
                <!-- <AddGoogleMap /> -->
                <!-- <iframe src="https://www.google.com/maps/d/embed?mid=1_a-BYUEZhRqlvhHKYK3iwsUCSZE0hD8&ehbc=2E312F"
                  width="100%" height="640"></iframe>  -->
                <!--<iframe src="https://www.google.com/maps/d/u/1/embed?mid=1QfHzMyIHMjdel-SI09I3YHifrX6uk4A&ehbc=2E312F" width="100%" height="640"></iframe> -->
                 <!-- <iframe
                  src="https://www.google.com/maps/d/u/1/embed?mid=1-nEhN9t_loORlC_c0LOCJCbOregoQMc&ehbc=2E312F"
                  width="100%"
                  height="640"
                ></iframe> -->
              </div>
            </center>
          </div>
        </div>

        <div class="row">
          <div v-for="location in nativeAmericanList" class="col-lg-6">
              <NativeAmericanLocationBadge 
                :large="location.large"
                :boxColor="location.boxColor"
                :labelColor="location.labelColor"
                :line1="location.line1"
                :line2="location.line2"
                :line3="location.line3"
                :address1="location.address1"
                :address2="location.address2"
              />
              
          </div>
        </div>
      </div>
    </section>
  
  </div>
</template>
<script>
import { WOW } from "wowjs"
import "../assets/css/swiper.min.css"
import Swiper, { Navigation, Pagination, Autoplay } from "swiper"
Swiper.use([Navigation, Pagination])
import "../assets/js/jquery.isotope.min.js"
import * as ScrollMagic from "scrollmagic" // Or use scrollmagic-with-ssr to avoid server rendering problems
import { TweenMax, TimelineMax } from "gsap" // Also works with TweenLite and TimelineLite
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap"
ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax)
import "magnific-popup/dist/jquery.magnific-popup.min.js"
import "magnific-popup/dist/magnific-popup.css"
import InvitationModal from "./invitation-modal.vue"
import JoinRequestModal from "./join-request-modal.vue"
import GoogleLocator from './googleLocator.vue';
import NativeAmericanLocationBadge from './library/badges/native-america-location-badge.vue';
export default {
  name: "index",
  components: {
    // VueBotUI,
    InvitationModal,
    JoinRequestModal,
    GoogleLocator,
    NativeAmericanLocationBadge
  },
  created() {},
  mounted() {
    this.init()
  },
  data() {
    return {
      showdiv: 0,
      eventdiv: 1,
      datafilter: 0,
      webinarItems: [],
      cybercircuitItems: [],
      newsItems: [],
      nativeAmericanList: [
        {
          id: 'a99b8751asda',
          large: false,
          boxColor: '#15263b',
          labelColor: 'white',
          line1: "Pacific",
          line2: "Region",
          line3: "",
          address1: {
            title: "American Indian Chamber APEX Accelerator",
            street: "5912 Bolsa Avenue, Suite 108",
            cityStateZip: "Huntington Beach, CA 92649",
            phone: "(213) 341-0104",
            website: "http://aicef-apex.org",
            email: "ptacinfo@aicccal.org"
          }
        },
        {
          id: 'a99b8751=345234',
          large: false,
          boxColor: '#8878c3',
          labelColor: 'black',
          line1: "Eastern",
          line2: "Region",
          line3: "",
          address1: {
            title: "The National Center APEX Accelerator",
            street: "86 South Cobb Drive, MZ 0510",
            cityStateZip: "Marietta, GA 30063-0510",
            phone: "(480) 280-6098",
            website: "https://www.ncaied.org/ptac",
            email: "apex@ncaied.org"
          }
        },
        {
          id: 'a99b87qwe',
          large: false,
          boxColor: '#17375e',
          labelColor: 'white',
          line1: "Western",
          line2: "Region",
          line3: "",
          address1: {
            title: "American Indian Chamber APEX Accelerator",
            street: "3200 North Dobson Rd. Building C, Suite 103",
            cityStateZip: "Chandler, AZ 85224",
            phone: "(480) 699-9529",
            website: "http://aicef-apex.org",
            email: "ptacinfo@aicccal.org "
          }
        },
        {
          id: 'a99b8234',
          large: false,
          boxColor: '#cec1ff',
          labelColor: 'black',
          line1: "Midwest",
          line2: "Region",
          line3: "",
          address1: {
            title: "PEDCO Native APEX Accelerator",
            street: "2756 O Street",
            cityStateZip: "Lincoln, NE 68510",
            phone: "(531) 310-2494",
            website: "https://pedco-ne.org/pedco-native-apex-accelerator",
            email: "jniceswanger@pedcoapex.org"
          }
        },
        {
          id: 'a99b87dsfs',
          large: false,
          boxColor: '#868896',
          labelColor: 'white',
          line1: "Rocky Mountain",
          line2: "Region",
          line3: "",
          address1: {
            title: "Native American Development Corporation APEX Accelerator",
            street: "17 N. 26th St.",
            cityStateZip: "Billings, MT 59101",
            phone: "(406) 259-3804",
            website: "http://www.nadc-nabn.org",
            email: "nadcptac@nadc-nabn.org"
          }
        },
        {
          id: 'a99b87523r',
          large: false,
          boxColor: '#392464',
          labelColor: 'white',
          line1: "Southern Plains",
          line2: "Region",
          line3: "",
          address1: {
            title: "Tribal Government Institute APEX Accelerator",
            street: "111 N. Peters, Suite 450",
            cityStateZip: "Norman, OK 73069",
            phone: "(405) 329-5542",
            website: "http://www.tgiok.com",
            email: "tgi@tgiok.com"
          }
        },
        {
          id: 'a99b8qerd',
          large: false,
          boxColor: '#acc8eb',
          labelColor: 'black',
          line1: "Navajo",
          line2: "Region",
          line3: "",
          address1: {
            title: "The National Center APEX Accelerator",
            street: "Hwy 264, 100 Taylor Rd.",
            cityStateZip: "St. Michaels, AZ 86511 - Window Rock, AZ 86511",
            phone: "(480) 371-8373",
            website: "https://www.ncaied.org/ptac",
            email: "apex@ncaied.org"
          }
        },
        {
          id: 'a99b8retwer',
          large: false,
          boxColor: '#09ffff',
          labelColor: 'black',
          line1: "Eastern Oklahoma",
          line2: "Region",
          line3: "",
          address1: {
            title: "Tribal Government Institute APEX Accelerator",
            street: "111 N. Peters, Suite 450",
            cityStateZip: "Norman, OK 73069",
            phone: "(405) 329-5542",
            website: "http://www.tgiok.com",
            email: "tgi@tgiok.com"
          }
        },
        {
          id: 'a99bwretvc',
          large: false,
          boxColor: '#728fb4',
          labelColor: 'white',
          line1: "Southwest",
          line2: "Region",
          line3: "",
          address1: {
            title: "The National Center APEX Accelerator",
            street: "12503 E. Euclid Drive, Suite 200",
            cityStateZip: "Centennial, CO 80111",
            phone: "(480) 824-8520",
            website: "https://www.ncaied.org/ptac",
            email: "apex@ncaied.org"
          }
        },
        {
          id: 'a99wrwer',
          large: false,
          boxColor: '#cac7d4',
          labelColor: 'black',
          line1: "Great Plains",
          line2: "Region",
          line3: "",
          address1: {
            title: "Native American Development Corporation APEX Accelerator",
            street: "17 N. 26th St.",
            cityStateZip: "Billings, MT 59101",
            phone: "(406) 259-3804",
            website: "http://www.nadc-nabn.org",
            email: "nadcptac@nadc-nabn.org"
          }
        },
        {
          id: 'aerdfhhs3423',
          large: true,
          boxColor: '#355e93',
          labelColor: 'white',
          line1: "Northwest",
          line2: "Region",
          line3: "",
          address1: {
            title: "American Indian Chamber APEX Accelerator",
            street: "719 Jadwin Avenue, Suite 23",
            cityStateZip: "Richland, WA 99352",
            phone: "(509) 581-7505",
            website: "http://aicef-apex.org",
            email: "ptacinfo@aicccal.org"
          },
          address2: {
            title: "Northwest Native APEX Accelerator",
            street: "4445 SW Barbur Blvd., Suite 105",
            cityStateZip: "Portland, OR 97239",
            phone: "(503) 404-4572",
            website: "https://nnapex.org",
            email: "info@nnapex.org"
          },
        },
        {
          id: 'a99452gsdf',
          large: true,
          boxColor: '#cac7d4',
          labelColor: 'black',
          line1: "Nebraska",
          line2: "Region",
          line3: "Region",
          address1: {
            title: "Native American Development Corporation APEX Accelerator",
            street: "17 N. 26th St.",
            cityStateZip: "Billings, MT 59101",
            phone: "(406) 259-3804",
            website: "http://www.nadc-nabn.org",
            email: "nadcptac@nadc-nabn.org"
          },
          address2: {
            title: "Nebraska Indian Community College APEX Accelerator",
            street: "1111 Highway 75",
            cityStateZip: "Macy, NE 68039",
            phone: "(402) 494-2311",
            website: "https://govology.com/nicc-apex",
            email: "APEX@thenicc.edu"
          }
        } 
      ]
    }
  },
  computed: {
    items() {
      return this.newsItems
    },
    webinaritems() {
      return this.webinarItems
    },
    cybercircuititems() {
      return this.cybercircuitItems
    },
  },
  beforeMount() {},
  methods: {
    init() {
      this.initMainSlider()
      this.initBackgroundImage()
      this.initWowAnimation()
      this.initAccordian()
      this.windowScroll()
      this.initTestimonialSlider()
      /* The footer height no longer needs to be set */
      // this.initFooterHeight();
      this.initIsotop()
      this.initMagnificPopup()
      this.initScaleImage()
    },
    /*----MAIN ANIMATION-----*/
    initMainSlider() {
      const SECOND = 1000 // milliseconds

      // init Swiper:
      new Swiper(this.$refs.swiper, {
        // configure Swiper to use modules
        modules: [Navigation, Pagination, Autoplay],

        speed: 1000,
        loop: true,
        autoplay: {
          delay: 4 * SECOND,
          disableOnInteraction: false,
        },
        speed: 2 * SECOND,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },

        // Events
        on: {
          slideChange: (swiper) => {
            this.activeIndex = swiper.realIndex
          },
        },
      })
    },
    /*----BACKGROUND IMAGE-----*/
    initBackgroundImage() {
      $(".bg-image").css("background", function () {
        var bg =
          "url(" + $(this).data("image-src") + ") no-repeat center center"
        return bg
      })
    },
    initFooterHeight() {
      var footerHeight = $("footer").outerHeight()
      $(".main-content").css("margin-bottom", footerHeight)
    },
    /*----WOW ANIMATION-----*/
    initWowAnimation() {
      var length = $(".wow").length
      if (length >= 1) {
        const wow = new WOW({
          boxClass: "wow",
          animateClass: "animated",
          offset: 0,
        })
        wow.init()
      }
    },
    /*----ONSCROLL JS-----*/
    windowScroll() {
      var self = this
      $(window).on("scroll", function () {
        //self.initCounter();
      })
    },
    /*----ACCORDIAN-----*/
    initAccordian() {
      $(".question-card").on("click", function () {
        if ($(this).hasClass("active")) {
          $(".question-card").removeClass("active")
          $(this).removeClass("active")
        } else {
          $(".question-card").removeClass("active")
          $(this).addClass("active")
        }
      })
    },
    /*----TESTIMONIAL SLIDER-----*/
    initTestimonialSlider() {
      if ($(".swiper-container").length > 0) {
        var swiper = new Swiper(".client-container", {
          slidesPerView: 1,
          draggable: true,
          spaceBetween: 10,
          //init: false,
          pagination: {
            el: ".swiper-pagination",
            clickable: true,
          },
          breakpoints: {
            640: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 40,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 50,
            },
          },
        })
      }
    },
    /*----ISOTOP JS-----*/
    initIsotop() {
      var $container = $(".portfolio-items")
      $container.imagesLoaded(function () {
        $container.isotope({
          filter: "*",
        })
      })
      $(".portfolio-filter li a").click(function () {
        $(".portfolio-filter .current").removeClass("current")
        $(this).addClass("current")
        var selector = $(this).attr("data-filter")
        $container.isotope({
          filter: selector,
          animationOptions: {
            duration: 750,
            easing: "linear",
            queue: false,
          },
        })
        return false
      })
    },
    /*----MAGNIFIC POPUP JS-----*/
    initMagnificPopup() {
      if (".portfolio-items".length > 0) {
        $(".portfolio-items").each(function () {
          $(this).magnificPopup({
            delegate: ".js-zoom-gallery",
            type: "image",
            gallery: {
              enabled: true,
            },
          })
        })
      }
    },
    initScaleImage() {
      var controller = new ScrollMagic.Controller()
      var slides = $(".scale-image")
      for (var i = 0; i < slides.length; i++) {
        var image = $("img", slides[i])
        var tween = TweenMax.to(image, 1, {
          scale: 1.2,
        })
        var scene = new ScrollMagic.Scene({
          triggerElement: slides[i],
          duration: "200%",
          triggerHook: "onEnter",
        })
          .setTween(tween)
          .addTo(controller)
      }
    },
  },
}
</script>
<style scoped>
ul.new-style {
  list-style-position: outside;
  margin-left: 1.2em;
  text-align: left;
}

li.new-style {
  list-style-type: "\2714\0020";
  padding-inline-start: 0.5em;
  padding-bottom: 0.6em;
}

.box {
  box-sizing: border-box;
  border: 3px solid transparent;
  background-clip: padding-box;
}

.cover {
  object-fit: cover;
  height: 225px;
  width: 100%;
}

.news-cover-small {
  object-fit: cover;
  height: 120px;
  width: 100%;
}

.slide-vision {
  background: url("~@/assets/images/slider-img/aircraft.jpg");
}

.slide-mission {
  background: url("~@/assets/images/slider-img/truck.jpg");
}

.slide-mpp {
  background: url("~@/assets/images/slider-img/mentor.png");
}

@media (min-width: 1100px) {
  .slide-cmmc {
    background: url("~@/assets/images/slider-img/cybercircuits.jpg");
  }
}

@media (max-width: 1101px) {
  .slide-cmmc {
    background: url("~@/assets/images/slider-img/cybercircuits_mobile.png");
  }
}

.slide-readiness {
  background: url("~@/assets/images/slider-img/readiness.jpg");
}

.slide-main {
  background: url("~@/assets/images/slider-img/darkship.jpg");
}

.embed-youtube {
  position: relative;
  padding-bottom: 40%;
  /* - 16:9 aspect ratio (most common) */
  /* padding-bottom: 62.5%; - 16:10 aspect ratio */
  /* padding-bottom: 75%; - 4:3 aspect ratio */
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

.embed-youtube iframe,
.embed-youtube object,
.embed-youtube embed {
  border: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
