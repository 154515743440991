var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "main-content"
  }, [_vm._m(0), _c('section', {
    staticClass: "section pt-5"
  }, [_c('div', {
    staticClass: "container",
    staticStyle: {
      "min-height": "700px"
    }
  }, [_c('p', [_vm._v(" APEX Accelerators are non-profit, government, or tribal entities who received a DoD cooperative agreement award. Congress appropriates funding for this program to assist businesses in obtaining information and training related to contracting and subcontracting opportunities with DoD, other Federal agencies, and State and local governments. APEX Accelerators are expected to help businesses participate in government contracting but they are not allowed to do the work on behalf of the business. APEX Accelerators will keep assisting in the form of such services as helping prepare bids/proposals, marketing to potential buyers, setting up or improving quality assurance and accounting systems, complying with cybersecurity requirements, and facilitating education of small businesses on the Foreign Ownership, Control, and Influence (FOCI) risks and mitigation strategy. APEX Accelerators welcome your feedback on our services. ")]), _c('div', {
    staticClass: "row surveyDiv",
    attrs: {
      "id": "surveyContainer"
    }
  }, [_c('survey', {
    attrs: {
      "survey": _vm.survey
    }
  })], 1)])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "inner-header-bg"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "section-breadcrumb contact-breadcrumb"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12 justify-content-center align-self-center pb-2"
  }, [_c('div', {
    staticClass: "breadcrumb-content"
  }, [_c('div', {
    staticClass: "title-section"
  }, [_c('h1', {
    staticClass: "title text-center text-white"
  }, [_vm._v("Feedback")])]), _c('div', {
    staticClass: "breadcrumb-nav"
  }, [_c('ul', {
    staticClass: "breadcrumb-link text-center list-unstyled mb-0"
  }, [_c('li', {
    staticClass: "text-white"
  }, [_c('i', {
    staticClass: "mdi mdi-home theme-light-color pr-2"
  }), _c('a', {
    attrs: {
      "href": "/#/"
    }
  }, [_vm._v("Home")])]), _c('li', {
    staticClass: "theme-light-color active"
  }, [_vm._v("Feedback")])])])])])])])])])]);

}]

export { render, staticRenderFns }