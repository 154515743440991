<template>
    <div class="native-american-location-badge">
        <div class="row g-2">
            <div class="col-4">
                <div :class="['location-label-box', large ? 'location-label-box-lg' : '' ]" :style="{backgroundColor: boxColor}">
                    <!-- <div class="location-label" :style="{ color: labelColor }">
                        <p>{{ line1 }}</p>
                        <p>{{ line2 }}</p>
                        <p>{{ line3}}</p>
                    </div> -->
                    <img :class="['img-fluid', large ? 'minimgheight' : '']" :src="'/assets/images/native-american/' + line1 + ' ' + line2 + '.png'"/>
                </div>
            </div>
            <div class="col-8">
                <div class="address-box">
                    <div v-if="address1" class="address-info">
                        <h4>{{ address1.title }}</h4>
                        <p>{{ address1.street }}</p>
                        <p>{{ address1.cityStateZip }}</p>
                        <p>{{ address1.phone }}</p>
                        <p><a :href="address1.website" target="_blank">{{ address1.website }}</a></p>
                        <p> <a :href="`mailto:${address1.email}`"
                        >{{ address1.email }}</a
                        ></p>
                    </div>
                    <div v-if="address2" class="address-info mt-3">
                        <h4>{{ address2.title }}</h4>
                        <p>{{ address2.street }}</p>
                        <p>{{ address2.cityStateZip }}</p>
                        <p>{{ address2.phone }}</p>
                        <p><a :href="address2.website" target="_blank">{{ address2.website }}</a></p>
                        <p> <a :href="`mailto:${address1.email}`"
                        >{{ address2.email }}</a
                        ></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'native_american_location_badge',
  props: [ 
    'large',
    'boxColor',
    'labelColor',
    'line1',
    'line2',
    'line3',
    'address1',
    'address2'
 ],
  methods: {},
};
</script>

<style>
    .location-label-box {
        min-height: 135px;
        background-color: rgb(238, 239, 239);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .location-label-box-lg {
        min-height: 260px;
        background-color: rgb(238, 239, 239);
    }

    .location-label p {
        margin: 0;
        font-size: 15px;
        font-weight: 600;
        text-transform: uppercase;
        text-align: center;
    }

    .address-box {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .address-info h4 {
        font-size: 15px;
        font-weight: 600;
        margin-bottom: 5px;
    }

    .address-info p {
        margin: 0;
        line-height: 14px;
        margin-bottom: 5px;
    }
    .minimgheight{
        min-height:260px
    }
</style>