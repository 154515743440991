var render = function render(){
  var _vm$currentInvitation;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    ref: "my-modal",
    attrs: {
      "size": "lg",
      "title": _vm.title,
      "ok-title": "Accept",
      "cancel-title": "Decline",
      "ok-variant": "success",
      "cancel-variant": "danger",
      "no-close-on-backdrop": "",
      "no-close-on-esc": "",
      "busy": _vm.busy
    },
    on: {
      "ok": _vm.handleAccept,
      "cancel": _vm.handleDecline
    }
  }, [_c('p', {
    staticClass: "my-2"
  }, [_vm._v("You have invitations from the following organizations:")]), _vm._l(_vm.organizations, function (item) {
    return _c('li', {
      key: item,
      staticClass: "font-weight-bold px-2 py-0"
    }, [_vm._v(" " + _vm._s(item) + " ")]);
  }), _c('p', {
    staticClass: "my-2"
  }, [_vm._v(" Do you want to accept and become a part of "), _c('b', [_vm._v(_vm._s((_vm$currentInvitation = _vm.currentInvitation) === null || _vm$currentInvitation === void 0 ? void 0 : _vm$currentInvitation.Organization))]), _vm._v("? ")])], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }