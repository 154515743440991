<template>
  <div class="main-content">
    <!-- BradCrumb header Start-->
    <section class="inner-header-bg-techHub">
      <div class="container">
        <!-- Breadcrumb Start-->
        <div class="section-breadcrumb">
          <div class="container">
            <div class="row">
              <div
                class="col-md-12 justify-content-center align-self-center pb-2"
              >
                <div class="breadcrumb-content">
                  <div class="title-section">
                    <h1 class="title text-center text-white">News Details</h1>
                  </div>
                  <div class="breadcrumb-nav">
                    <ul class="breadcrumb-link text-center list-unstyled mb-0">
                      <li class="text-white">
                        <i class="mdi mdi-home theme-light-color pr-2"></i>
                        <a href="/#/">Home</a>
                      </li>
                      <li class="text-white">
                        <router-link :to="{ name: 'news' }">
                          <a href="#">Latest News</a>
                        </router-link>
                      </li>
                      <li class="theme-light-color active">
                        News Details
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Breadcrumb End -->
      </div>
    </section>
    <!-- BradCrumb header End -->
    <!-- News Details Start-->
    <section class="section section-blog">
      <div class="container">
        <div class="row justify-content-lg-center">
          <!-- TODO: Hiding catagories until implemented-->
          <div
            class="col-lg-4 order-lg-2 wow fadeIn d-none"
            data-wow-delay="0.1s"
            data-wow-duration="1.5s"
          >
            <aside class="sidebar sidebar-padding border">
              <h6 class="post-news-title search-title">Search</h6>
              <div class="input-group">
                <b-form-input
                  v-model="query"
                  placeholder="Search..."
                ></b-form-input>
                <router-link
                  class="btn btn-search p-0"
                  :to="{ name: 'news', query: { query: query } }"
                >
                  <span class="mdi mdi-magnify"></span>
                </router-link>
              </div>
              <div class="pb-2 mt-5 mb-3">
                <ul class="blog-news list-unstyled">
                  <li class="blog-item">
                    <h6 class="post-news-title">Categories</h6>
                  </li>
                </ul>

                <div class="blog-news-content">
                  <div class="blog-post-list">
                    <ul class="list-unstyled">
                      <li
                        v-for="(item, index) in categories"
                        v-bind:key="index"
                      >
                        <div class="blog-news-info">
                          <router-link
                            :to="{ name: 'news', query: { query: item.name } }"
                          >
                            <a href="#" class="text-dark">
                              {{ item.name }}
                              <span class="float-right">{{
                                item.articles
                              }}</span>
                            </a>
                          </router-link>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="pb-2 mb-3">
                <ul class="blog-news list-unstyled">
                  <li class="blog-item">
                    <h6 class="post-news-title">Archives</h6>
                  </li>
                </ul>

                <div class="blog-news-content">
                  <div class="blog-post-list">
                    <ul class="list-unstyled">
                      <li v-for="(item, index) in archives" v-bind:key="index">
                        <div class="blog-news-info">
                          <a href="#" class="text-dark"
                            >{{ item.name }}
                            <span class="float-right">{{ item.articles }}</span>
                          </a>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="tags-post">
                <ul class="blog-news list-unstyled">
                  <li class="blog-item">
                    <h6 class="post-news-title">Tags</h6>
                  </li>
                </ul>

                <div class="blog-news-content">
                  <div class="blog-post-list">
                    <ul class="list-unstyled list-inline mb-0">
                      <li
                        class="list-inline-item"
                        v-for="(item, index) in tags"
                        v-bind:key="index"
                      >
                        <router-link
                          class="blog-news-info text-dark"
                          :to="{ name: 'news', query: { query: item.name } }"
                        >
                          {{ item.name }}
                        </router-link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </aside>
          </div>
          <!-- TODO: Making full width until catagories implemented-->
          <div
            class="col-lg-8 wow fadeIn"
            data-wow-delay="0.1s"
            data-wow-duration="1.5s"
          >
            <article class="blog-post-left border">
              <div class="row">
                <div class="col-lg-12">
                  <div class="post-image">
                    <a href="#">
                      <img
                        :src="item?.article?.largeImageURL"
                        :alt="item?.article?.photoDescription"
                        class="img-fluid img-thumbnail border-0"
                      />
                    </a>
                  </div>
                  <div class="blog-post-content text-left mt-3">
                    <h4 class="blog-post-title">
                      <a href="#" class="text-left text-dark">
                        {{ item?.article?.title }}
                      </a>
                    </h4>
                    <div
                      v-if="item?.author"
                      class="post-event d-flex align-items-center mt-3"
                    >
                      <a href="#" class="blog-info-meta pr-1">{{
                        item?.author
                      }}</a>
                    </div>
                    <div class="post-event d-flex align-items-center my-3">
                      <p class="blog-info-meta">{{
                        getFormattedDate(item?.article?.date)
                      }}</p>
                      <!-- <a href="#" class="blog-category">Blog</a> -->
                      <SocialShareBookmark
                        :title="title"
                        :description="description"
                        type="news"
                      />
                    </div>
                  </div>
                  <div class="post-content">
                     <p><strong>{{item?.article?.headLine}}</strong></p>
                  </div>
                  <div class="post-content">
                    <span v-html="item?.article?.summary"></span>
                  </div>
                  <a
                    :href="item?.article?.detailsURL"
                    variant="link"
                    target="_blank"
                    class="btn theme-btn w-100 rounded centered-link"
                  >
                    {{ item?.article?.prompt || 'Click to read full article' }}
                  </a>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
    <!-- News Details Section End-->
  </div>
</template>
<script>
import { BFormInput } from 'bootstrap-vue';
import SocialShareBookmark from './social-share-bookmark.vue';
import axios from 'axios';

export default {
  name: 'newsdetail',
  components: {
    BFormInput,
    SocialShareBookmark,
  },
  props: ['id'],
  data() {
    return {
      query: '',
      item: {},
      categories: [
        { name: 'Cyber Security', articles: 4 },
        { name: 'Software', articles: 5 },
        { name: 'Manufacturing', articles: 6 },
        { name: 'Learning Tools', articles: 7 },
      ],
      archives: [
        { name: 'February 2020', articles: 4 },
        { name: 'January 2020', articles: 5 },
        { name: 'June 2020', articles: 6 },
        { name: 'May 2020', articles: 7 },
      ],
      tags: [
        { name: 'Development' },
        { name: 'Security' },
        { name: 'Infrastructure' },
        { name: 'Androids' },
        { name: 'Phishing' },
        { name: 'Coronavirus' },
      ],
      linkCopyText: 'Click to copy url link',
    };
  },
  async created() {
    if (this.item) {
      this.item = await this.getResource(this.id);
    } else {
      this.$router.push({ path: 'news' });
    }
  },
  mounted() {
    this.init();
  },
  computed: {
    title() {
      return this.item?.article?.title || 'Title';
    },
    description() {
      return this.item?.article?.headLine || 'HeadLine';
    },
  },
  methods: {
    init() {
      /* The footer height no longer needs to be set */
      // this.initFooterHeight();
    },
    initFooterHeight() {
      var footerHeight = $('footer').outerHeight();
      $('.main-content').css('margin-bottom', footerHeight);
    },
    async getResource(id) {
      /*  API call to server: GET /api/resources/blogs */
      axios
        .get(`/api/resources/news/${id}`)
        .then((response) => {
          if (response.data.Items.length > 0) {
            /** Does an item exist for the query id */
            this.item = response.data.Items[0];
            //console.log(response.data);
          } else {
            /** If no item exists with the id, redirect to list*/
            this.$router.push({ path: 'news' });
          }
        })
        .catch((error) => {
          /** On error, redirect back to list */
          console.error(error);
          this.$router.push({ path: 'news' });
        });
    },
  },
};
</script>
<style scoped>
.centered-link {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
