<template>
  <div class="main-content bg-light">
    <!-- BradCrumb header Start-->
    <section class="inner-header-bg">
      <div class="container">
        <!-- Breadcrumb Start-->
        <div class="section-breadcrumb">
          <div class="container">
            <div class="row">
              <div
                class="col-md-12 justify-content-center align-self-center pb-2"
              >
                <div class="breadcrumb-content">
                  <div class="title-section">
                    <h1 class="title text-center text-white">Verify Email</h1>
                  </div>
                  <div class="breadcrumb-nav">
                    <ul class="breadcrumb-link text-center list-unstyled mb-0">
                      <li class="text-white">
                        <i class="mdi mdi-home theme-light-color pr-2"></i>
                        <a href="/#/">Home</a>
                      </li>
                      <li class="theme-light-color active">Verify Email</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Breadcrumb End -->
      </div>
    </section>
    <!-- BradCrumb header End -->
    <!-- Login Start-->
    <section class="login-form section bg-light">
      <!-- Overlay -->
      <b-overlay :show="busy" no-wrap>
        <template #overlay>
          <b-spinner label="Loading..."></b-spinner>
        </template>
      </b-overlay>
      <!-- MFA Verify Container -->
      <div class="container bg-white">
        <div class="login-container">
          <div class="alert alert-primary" v-if="!!message && !verified">
                        {{ message }}
                      </div>
                       <div class="alert alert-primary" v-if="verified">
                        {{ successMessage }}
                        <br/>
                        <br/>
                        Please
                        <router-link :to="{ name: 'login' }">Login </router-link> to continue.
          </div>
          <div class="row justify-content-center">
            <div class="col-lg-6 form-thumbnail">
              <div class="thumboverlay"></div>
            </div>
            <div class="col-lg-6">
              <form @submit.prevent class="form-body">
                <div class="text-center section-title">
                  <h2 class="text-center">Verify Email</h2>
                </div>
                <div class="mt-4">
                  <div class="row">
                    <div class="col-12">
                      <div class="form-group">
                        <p>
                          Authentication code has been sent to your email.
                          <!-- <b>{{ email }}</b> -->
                        </p>
                        <div class="form-group">
                          <b-form-input
                            type="text"
                            :state="validateState('email')"
                            placeholder="Enter Email"
                            required="required"
                            v-model="form.email"
                            :disabled="!!this.email"
                          />
                          <b-form-invalid-feedback id="input-phone-feedback">
                            Email is required
                          </b-form-invalid-feedback>
                        </div>
                        <b-form-input
                          type="text"
                          :state="validateState('code')"
                          name="code"
                          placeholder="Enter verification code"
                          required="required"
                          v-model="form.code"
                        />
                        <b-form-invalid-feedback id="input-phone-feedback">
                          Verification code is required
                        </b-form-invalid-feedback>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12 text-center">
                      <div class="alert alert-danger" v-if="!!error">
                        {{ error }}
                      </div>
                      <button
                        type="button"
                        @click="submit"
                        class="btn theme-btn rounded w-100"
                      >
                        Submit Code
                      </button>
                    </div>
                    <div class="col-sm-12 mt-5">
                      <div class="lost-password text-center">
                        <span
                          ><a href="#" class="theme-light-color">
                            Forgot username or password?</a
                          ></span
                        >
                        <router-link :to="{ name: 'my-account' }">
                          <a class="d-block mt-2 theme-light-color"
                            >Create Account</a
                          >
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Login End -->
  </div>
</template>
<script>
import axios from 'axios';
import { required, email } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
export default {
  name: 'verify',
  props: ['email', 'message', 'accountType'],
  mixins: [validationMixin],
  data() {
    return {
      busy: false,
      error: '',
      form: {
        email: this.email,
        code: '',
        successMessage : '',
        verified : false
      },
    };
  },
  validations: {
    form: {
      email: { required, email },
      code: { required },
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      /* The footer height no longer needs to be set */
// this.initFooterHeight();
    },
    initFooterHeight() {
      var footerHeight = $('footer').outerHeight();
      $('.main-content').css('margin-bottom', footerHeight);
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    submit() {
      // Verify the form input fields
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      this.busy = true;
      axios
        .post('/api/user/email/verify', {
          username: this.form.email,
          code: this.form.code,
        })
        .then((response) => {
          //console.log(response);
          this.error = '';
          if(this.accountType == "OrganizationAdmin"){
            this.successMessage ="We are happy to welcome you as the newest organization within Project Spectrum community! As an organization admin, you will be able to leverage the plethora of tools and services offered by Project Spectrum, including: Cyber Readiness Checks, advisory services, and assistance with every step of your customized cybersecurity journey. Additionally, you will have the ability to invite new members of your organization and manage them accordingly to gain insights into their individual training progress. Please feel free to take advantage of all of the resources at your disposal as well. We offer Cyber Readiness Checks for your organization, personalized cybersecurity training, virtual labs, informational webinars, Cyber Circuits Sessions (hear from industry experts), white papers, blogs, compliance tool reviews, and much more!"
          } else if(this.accountType == "OrganizationMember"){
            this.successMessage ="We are happy to welcome you as the newest member of the Project Spectrum community! As an individual organizational member, please take advantage of all of the resources at your disposal, including personalized cybersecurity training, virtual labs, informational webinars, Cyber Circuits Sessions (hear from industry experts), white papers, blogs, compliance tool reviews, and the ability to see your organizations security posture at a glance"
          }
          else if(this.accountType == "Individual"){
            this.successMessage = "We are happy to welcome you as the newest member of the Project Spectrum community! As an individual member, please take advantage of all of the resources at your disposal, including personalized cybersecurity training, informational webinars, Cyber Circuits Sessions (hear from industry experts), white papers, blogs and much more!"
          }
          else {
               this.successMessage = "We are happy to welcome you as the newest member of the Project Spectrum community!"
          }
          this.verified = true;
          //this.$router.push({ path: 'login' });
        })
        .catch((error) => {
          this.error = error.response.data;
          console.error(error.response);
        })
        .finally(() => {
          this.busy = false;
        });
    },
  },
};
</script>
