<template>
  <div class="main-content">
    <!-- BradCrumb header Start-->
    <section class="inner-header-bg">
      <div class="container">
        <!-- Breadcrumb Start-->
        <div class="section-breadcrumb contact-breadcrumb">
          <div class="container">
            <div class="row">
              <div
                class="col-md-12 justify-content-center align-self-center pb-2"
              >
                <div class="breadcrumb-content">
                  <div class="title-section">
                    <h1 class="title text-center text-white">Feedback: Post Event Survey</h1>
                  </div>
                  <div class="breadcrumb-nav">
                    <ul class="breadcrumb-link text-center list-unstyled mb-0">
                      <li class="text-white">
                        <i class="mdi mdi-home theme-light-color pr-2"></i>
                        <a href="/#/">Home</a>
                      </li>
                      <li class="theme-light-color active">Event Feedback Survey</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Breadcrumb End -->
      </div>
    </section>
    <!-- BradCrumb header End -->
    <!-- Body Area Start-->
    <section class="section pt-5">
      <div class="container" style="min-height: 700px">
        <!-- surveySet - used to track when survey is ready to render -->
        <!-- <h5>PTAP Feedback</h5> -->
        <p class="text-center" v-if="captionVisible">Welcome!</p>
        <p v-if="captionVisible">Thank you for your attendance and participation. Please take a few minutes to share your thoughts and experiences with us through this survey. Your valuable insights will guide us as we strive to create more impactful and informative events in the future.</p>
        <div id="surveyContainer" class="row surveyDiv">
          <!-- If you want to hide survey, comment the lines below -->
          <survey ref="eventFeedbackSurvey" :survey="survey"></survey>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import * as SurveyVue from "survey-vue";
import "survey-vue/survey.min.css";
import axios from "axios";
import $ from 'jquery';
var Survey = SurveyVue.Survey;
export default {
  components: {
    Survey,
  },
  data() {
    return {
      survey: {},
      captionVisible: true
    };
  },
  created() {
    this.setSurvey();
  },
  mounted() {
    let q3job = $('.sv-string-viewer:contains(Before today’s event, how would you describe your knowledge of the APEX Accelerators program?)');
    q3job.html('<i>Before today’s event</i>, how would you describe your knowledge of the APEX Accelerators program?')
    let q4job = $('.sv-string-viewer:contains(After today’s event, how would you describe your knowledge of the APEX Accelerators program?)');
    q4job.html('<i>After today’s event</i>, how would you describe your knowledge of the APEX Accelerators program?')
  },
  methods: {
    init() {
      /* The footer height no longer needs to be set */
      this.initFooterHeight();
    },
    setSurvey() {
      var surveyJSON = {
        title: "Survey",
        logoPosition: "right",
        pages: [
          {
            name: "page1",
            elements: [
              {
                type: "dropdown",
                name: "q1bestAppliesToYou",
                title: "Which best applies to you?",
                choices: [
                  "Small business owner",
                  "Small business professional",
                  "Contracting officer",
                  "University member"
				        ],
                isRequired: true,
                showOtherItem: true,
                otherText: "Other",
              },
              {
                type: "comment",
                name: "q2HearAboutThisEvent",
                title: "How did you hear about this event?",
                isRequired: true,
              },
              {
                type: "dropdown",
                name: "q3beforeTodaysEvent",
                title: "Before today’s event, how would you describe your knowledge of the APEX Accelerators program?",
                choices: [
                  "Very Knowledgeable",
                  "Somewhat Knowledgeable",
                  "Somewhat Unknowledgeable",
                  "Very Unknowledgeable"
				        ],
                isRequired: true,
              },
              {
                type: "dropdown",
                name: "q4afterTodayEvent",
                title: "After today’s event, how would you describe your knowledge of the APEX Accelerators program?",
                choices: [
                  "Very Knowledgeable",
                  "Somewhat Knowledgeable",
                  "Somewhat Unknowledgeable",
                  "Very Unknowledgeable"
				        ],
                isRequired: true,
              },
              {
                type: "dropdown",
                name: "q5CollaborateWithUs",
                title: "How likely are you to collaborate with us?",
                choices: [
                  "Very Likely",
                  "Somewhat Likely",
                  "Neutral",
                  "Somewhat Unlikely",
                  "Very Unlikely"
				        ],
                isRequired: true,
              },
              {
                type: "dropdown",
                name: "q6OverallSatisfaction",
                title: "How would you rate your overall satisfaction with today’s event?",
                choices: [
                  "Very Satisfied",
                  "Somewhat Satisfied",
                  "Neutral",
                  "Somewhat Unsatisfied",
                  "Very Unsatisfied"
				        ],
                isRequired: true,
              },
              {
                type: "comment",
                name: "q7improveTodaysEvent",
                title: "How would you improve today’s event?",
                // isRequired: true,
              },
              {
                "type": "panel",
                "name": "contact-information",
                "title": "Contact Information",
                "state": "expanded",
                "elements": [
                  {
                    name: "attendeesName",
                    title: "Attendees' name",
                    type: "text",
                    // isRequired: true,
                    startWithNewLine: false
                  },
                  {
                    name: "companyName",
                    title: "Company Name (if applicable)",
                    type: "text",
                    // isRequired: true,
                    startWithNewLine: false
                  },
                  {
                    name: "emailAddress",
                    title: "Email",
                    type: "text",
                    inputType: "email",
                    // isRequired: true,
                    startWithNewLine: false
                  },
                  {
                    name: "phoneNumber",
                    title: "Phone Number",
                    type: "text",
                    startWithNewLine: false
                  },
                  {
                    name: "city",
                    title: "City",
                    type: "text",
                    startWithNewLine: false
                  },
                  {
                    name: "county",
                    title: "County",
                    type: "text",
                    startWithNewLine: false
                  },
                
                  {
                    name: "state",
                    title: "State",
                    type: "text",
                    startWithNewLine: false
                  },
                ]
              }
            ],
          },
        ],
        completedHtml: "<h3>Thank you for your time and input. We look forward to seeing you again in the near future.</h3>",
      };
      
      var survey = new SurveyVue.Model(surveyJSON);
      survey.onComplete.add(this.sendDataToServer);
      survey.onValueChanged.fire = (evt) => {
        console.log(evt);
      }
      this.survey = survey;
    },
    sendDataToServer(survey) {
      //send Ajax request to your web server
      //alert("The results are: " + JSON.stringify(survey.data));
      axios.post("/api/survey/apex-event-feedback", survey.data).then((response) => {
        this.captionVisible = false;
      });
    },
    valueSelected(survey) {
      console.log(survey);
    } 
  },
};
</script>

<style>
.sv_main {
    font-family: Rajdhani,sans-serif !important;
}

</style>