<template>
  <div class="main-content">
    <!-- BradCrumb header Start-->
    <section class="inner-header-bg-techHub">
      <div class="container">
        <!-- Breadcrumb Start-->
        <div class="section-breadcrumb">
          <div class="container">
            <div class="row">
              <div
                class="col-md-12 justify-content-center align-self-center pb-2"
              >
                <div class="breadcrumb-content">
                  <div class="title-section">
                    <h1 class="title text-center text-white">White Papers</h1>
                  </div>
                  <div class="breadcrumb-nav">
                    <ul class="breadcrumb-link text-center list-unstyled mb-0">
                      <li class="text-white">
                        <i class="mdi mdi-home theme-light-color pr-2"></i>
                        <a href="/#/">Home</a>
                      </li>
                      <li class="theme-light-color active">White Papers</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Breadcrumb End -->
      </div>
    </section>
    <!-- BradCrumb header End -->
    <!-- Blog Grid Section Start -->
    <section class="section">
      <div class="container">
        <p>
          Our Cyber Advisors have subject matter expertise across the
          cybersecurity spectrum. Our white papers provide deep dive views and
          in-depth analysis of various critical cyber topics. Please check out
          our technical assessments of key cybersecurity issues that will better
          inform you as you seek to protect your organization from cyber
          threats.
        </p>
        <div class="pb-4">
          <b-form-input
            v-model="searchQuery"
            placeholder="Search White Papers..."
          ></b-form-input>
        </div>
        <p class="pb-2" v-if="displayeditems.length === 0 && searchQuery">
          No items match the search criteria...
        </p>
        <p class="pb-2" v-if="displayeditems.length === 0 && !searchQuery">
          Check back soon for white papers...
        </p>
        <div class="blog-post-grid">
          <div class="row">
            <!-- Start Blog Item -->
            <div
              class="col-md-6 col-lg-4 mb-5"
              v-for="item in displayeditems"
              :key="item.id"
            >
              <div class="blog-item border h-100">
                <router-link
                  class="blog-post-image"
                  :to="{ name: 'white-paper-detail', query: { id: item.id } }"
                >
                  <img
                    :src="item.imageURL"
                    :alt="item.photoDescription"
                    class="cover"
                  />
                </router-link>
                <div class="blog-post-info">
                  <h4 class="blog-post-title">
                    {{ item.title }}
                  </h4>
                  <div class="d-flex align-items-center pb-2">
                    <a class="blog-info-meta text-dark"
                      ><b>{{ item.author }}</b></a
                    >
                    <a class="blog-info-meta text-dark pl-2">{{
                      getFormattedDate(item.date)
                    }}</a>
                  </div>
                  <p class="limit-lines-3">{{ item.headLine }}</p>
                  <div class="post-event-links d-flex align-items-center">
                    <router-link
                      class=""
                      :to="{
                        name: 'white-paper-detail',
                        query: { id: item.id },
                      }"
                    >
                      <a class="blog-info-meta link-underline">Read More</a>
                    </router-link>
                    <a
                      class="blog-category link-underline"
                      :href="item.whitepaperLink"
                      variant="link"
                      target="_blank"
                      >View Document</a
                    >
                  </div>
                  <!-- <router-link class="blog-read-more" :to="{ name: 'white-paper-detail', query: { id: item.id } }">
                                            <i class="mdi mdi-arrow-right"></i>
                                        </router-link>  
                                        -->
                </div>
              </div>
            </div>
            <!-- End Blog Item -->
          </div>
        </div>
        <div>
          <b-pagination
            id="blog-pagination"
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="center"
            @page-click="pageClicked"
          ></b-pagination>
        </div>
      </div>
    </section>
    <!-- Blog Grid Section End -->
  </div>
</template>
<script>
import axios from 'axios';
import { BPagination, BFormInput } from 'bootstrap-vue';
export default {
  name: 'blog',
  components: {
    BPagination,
    BFormInput,
  },
  props: {
    query: String,
  },
  data() {
    return {
      searchQuery: this.query,
      items: [],
      itemCount: 0,
      currentPage: 1,
      perPage: 9,
    };
  },
  computed: {
    itemsFromSearchQuery() {
      if (this.searchQuery) {
        return this.items.filter((item) => {
          return this.searchQuery
            .toLowerCase()
            .split(' ')
            .every(
              (v) =>
                item.title.toLowerCase().includes(v) ||
                item.headLine.toLowerCase().includes(v)
            );
        });
      } else {
        return this.items;
      }
    },
    displayeditems() {
      return this.itemsFromSearchQuery.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
      );
    },
    totalRows() {
      return this.itemsFromSearchQuery.length;
    },
  },
  mounted: async function () {
    this.init();
    this.getBlogPosts(); // async
  },
  methods: {
    init() {
      /* The footer height no longer needs to be set */
      // this.initFooterHeight();
    },
    initFooterHeight() {
      var footerHeight = $('footer').outerHeight();
      $('.main-content').css('margin-bottom', footerHeight);
    },
    async getBlogPosts() {
      /*  API call to server: GET /api/resources/whitepapers  */
      axios
        .get('/api/resources/whitepapers')
        .then((response) => {
          this.items = response.data.Items;
          this.itemCount = response.data.Count;
          //console.log(this.items);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    pageClicked() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
  },
};
</script>
<style scoped>
#blog-pagination li {
  margin: 0px;
}

.blog-post-info {
  padding: 0 20px 40px;
}

.cover {
  object-fit: cover;
  height: 225px;
  width: 100%;
}

.post-event-links {
  position: absolute;
  bottom: 15px;
}
</style>
