var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('VueBotUI', {
    attrs: {
      "messages": _vm.messageData,
      "options": _vm.botOptions,
      "bot-typing": _vm.botTyping,
      "input-disable": _vm.inputDisable
    },
    on: {
      "init": _vm.botStart,
      "msg-send": _vm.msgSend
    }
  });

}
var staticRenderFns = []

export { render, staticRenderFns }