var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm._m(0);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "main-content"
  }, [_c('section', {
    staticClass: "inner-header-bg-partner"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "section-breadcrumb"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12 justify-content-center align-self-center pb-2"
  }, [_c('div', {
    staticClass: "breadcrumb-content"
  }, [_c('div', {
    staticClass: "title-section"
  }, [_c('h1', {
    staticClass: "title text-center text-white"
  }, [_vm._v(" Small Business Administration ")])]), _c('div', {
    staticClass: "breadcrumb-nav"
  }, [_c('ul', {
    staticClass: "breadcrumb-link text-center list-unstyled mb-0"
  }, [_c('li', {
    staticClass: "text-white"
  }, [_c('i', {
    staticClass: "mdi mdi-home theme-light-color pr-2"
  }), _c('a', {
    attrs: {
      "href": "/#/"
    }
  }, [_vm._v("Home")])]), _c('li', {
    staticClass: "theme-light-color active"
  }, [_vm._v(" Small Business Administration ")])])])])])])])])])]), _c('section', {
    staticClass: "section"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row justify-content-center"
  }, [_c('div', {
    staticClass: "col-12 py-4"
  }, [_c('div', {
    staticClass: "section-title"
  }, [_c('span', [_vm._v("IMPORTANCE")]), _c('h2', [_vm._v("The Importance of Cybersecurity for Small Businesses")])]), _c('div', {
    staticClass: "mt-4 text-lg-left"
  }, [_c('blockquote', {
    staticClass: "blockquote my-4"
  }, [_c('span', [_vm._v("If you want to do business with the Federal Government, you need to prove you can protect your systems and data")])]), _c('p', [_vm._v(" Cyber criminals consistently target businesses with the intent of weakening our nation’s supply chain, threatening our national security, and endangering the American way of life. Cyberattacks can be very costly for a business when you factor in ransom costs, business downtime, system restoration, and reputation damage. "), _c('br'), _c('br'), _vm._v(" If you want to do business with any Federal Government agency, you need to know that your company’s systems and data are secure. Specifically, you need to demonstrate the ability to safeguard Federal Contract Information (FCI) and Controlled Unclassified Information (CUI) in order to secure a government contract. ")])])]), _c('hr', {
    staticClass: "col-sm-12"
  }), _c('div', {
    staticClass: "col-12 py-4"
  }, [_c('div', {
    staticClass: "section-title"
  }, [_c('span', [_vm._v("COMPLIANCE")]), _c('h2', [_vm._v("CMMC Level 1 Compliance Journey for Small Business")])]), _c('div', {
    staticClass: "row pt-5"
  }, [_c('div', {
    staticClass: "col-1 no-gutters no-padding"
  }, [_c('div', {
    staticClass: "row no-gutters no-padding"
  }, [_c('div', {
    staticClass: "col-12 no-padding"
  }, [_c('img', {
    staticClass: "img-fluid arrow-height",
    staticStyle: {
      "width": "100%",
      "margin-top": "55%"
    },
    attrs: {
      "src": "https://assets.projectspectrum.io/sba/sba_left_arrow.png"
    }
  })])])]), _c('div', {
    staticClass: "col-10 no-gutters no-padding"
  }, [_c('div', {
    staticClass: "row no-gutters no-padding"
  }, [_c('div', {
    staticClass: "col-2 no-gutters no-padding sba_hover"
  }, [_c('a', {
    attrs: {
      "href": "https://www.projectspectrum.io/#/signup",
      "target": "_blank"
    }
  }, [_c('img', {
    staticClass: "img-fluid",
    attrs: {
      "src": "https://assets.projectspectrum.io/sba/sba_register.png",
      "id": "sba_register"
    }
  }), _c('div', {
    staticClass: "sba_tooltip"
  }, [_c('p', {
    staticClass: "centered-text"
  }, [_vm._v(" Register for project spectrum ")]), _c('a', {
    attrs: {
      "href": "https://www.projectspectrum.io/#/signup",
      "target": "_blank"
    }
  })])])]), _c('div', {
    staticClass: "col-2 no-gutters no-padding sba_hover"
  }, [_c('a', {
    attrs: {
      "href": "https://www.projectspectrum.io/#/events",
      "target": "_blank"
    }
  }, [_c('img', {
    staticClass: "img-fluid",
    attrs: {
      "src": "https://assets.projectspectrum.io/sba/sba_webinar.png",
      "id": "sba_webinar"
    }
  }), _c('div', {
    staticClass: "sba_tooltip"
  }, [_c('p', {
    staticClass: "centered-text"
  }, [_vm._v(" Participate in CMMC Level 1 webinar ")]), _c('a', {
    attrs: {
      "href": "https://www.projectspectrum.io/#/events",
      "target": "_blank"
    }
  })])])]), _c('div', {
    staticClass: "col-2 no-gutters no-padding sba_hover"
  }, [_c('a', {
    attrs: {
      "href": "https://www.projectspectrum.io/#/training-videos",
      "target": "_blank"
    }
  }, [_c('img', {
    staticClass: "img-fluid",
    attrs: {
      "src": "https://assets.projectspectrum.io/sba/sba_videos.png",
      "id": "sba_videos"
    }
  }), _c('div', {
    staticClass: "sba_tooltip"
  }, [_c('p', {
    staticClass: "centered-text"
  }, [_vm._v(" Watch training videos for CMMC Level 1 domains ")]), _c('a', {
    attrs: {
      "href": "https://www.projectspectrum.io/#/training-videos",
      "target": "_blank"
    }
  })])])]), _c('div', {
    staticClass: "col-2 no-gutters no-padding sba_hover"
  }, [_c('a', {
    attrs: {
      "href": "https://www.projectspectrum.io/#/courses-encite",
      "target": "_blank"
    }
  }, [_c('img', {
    staticClass: "img-fluid",
    attrs: {
      "src": "https://assets.projectspectrum.io/sba/sba_courses.png",
      "id": "sba_courses"
    }
  }), _c('div', {
    staticClass: "sba_tooltip"
  }, [_c('p', {
    staticClass: "centered-text"
  }, [_vm._v(" Complete CMMC Level 1 Online Course ")]), _c('a', {
    attrs: {
      "href": "https://www.projectspectrum.io/#/courses-encite",
      "target": "_blank"
    }
  })])])]), _c('div', {
    staticClass: "col-2 no-gutters no-padding sba_hover"
  }, [_c('a', {
    attrs: {
      "href": "https://www.projectspectrum.io/#/cyber-readiness-check",
      "target": "_blank"
    }
  }, [_c('img', {
    staticClass: "img-fluid",
    attrs: {
      "src": "https://assets.projectspectrum.io/sba/sba_readiness_hover.png",
      "id": "sba_readiness"
    }
  }), _c('div', {
    staticClass: "sba_tooltip"
  }, [_c('p', {
    staticClass: "centered-text"
  }, [_vm._v(" Take the CMMC Level 1 Readiness Check ")]), _c('a', {
    attrs: {
      "href": "https://www.projectspectrum.io/#/cyber-readiness-check",
      "target": "_blank"
    }
  })])])]), _c('div', {
    staticClass: "col-2 no-gutters no-padding sba_hover"
  }, [_c('a', {
    attrs: {
      "href": "https://cmmcab.org/osc-lp/",
      "target": "_blank"
    }
  }, [_c('img', {
    staticClass: "img-fluid",
    attrs: {
      "src": "https://assets.projectspectrum.io/sba/sba_evaluation_hover.png",
      "id": "sba_evaluation"
    }
  }), _c('div', {
    staticClass: "sba_tooltip"
  }, [_c('p', {
    staticClass: "centered-text"
  }, [_vm._v(" CMMC Level 1 preparedness for CMMC evaluation ")]), _c('a', {
    attrs: {
      "href": "https://cmmcab.org/osc-lp/",
      "target": "_blank"
    }
  })])])])])]), _c('div', {
    staticClass: "col-1 no-gutters no-padding"
  }, [_c('div', {
    staticClass: "row no-gutters no-padding"
  }, [_c('div', {
    staticClass: "col-12 no-padding"
  }, [_c('img', {
    staticClass: "img-fluid arrow-height",
    staticStyle: {
      "width": "100%",
      "margin-top": "55%"
    },
    attrs: {
      "src": "https://assets.projectspectrum.io/sba/sba_right_arrow.png"
    }
  })])])])])]), _c('hr', {
    staticClass: "col-sm-12 mt-5"
  }), _c('div', {
    staticClass: "col-12 py-4"
  }, [_c('div', {
    staticClass: "mt-4 text-lg-left text-center"
  }, [_c('div', {
    staticClass: "section-title"
  }, [_c('span', [_vm._v("IMPLEMENTATION")]), _c('h2', [_vm._v(" Implementing the U.S. Department of Defense (DoD) Cybersecurity Maturity Model Certification (CMMC) ")])]), _c('p', [_vm._v(" Previously, Defense Federal Acquisition Regulation Supplement (DFARS) regulations required all DoD contractors to self-certify their cybersecurity readiness using guidelines from the National Institute of Standards and Technology (NIST) Special Publication (SP) 800-171. NIST SP 800-171 provides businesses with recommended requirements for protecting defense-relevant information and reporting any cyber incidents. "), _c('br'), _c('br'), _vm._v(" DoD unveiled its Cybersecurity Maturity Model Certification (CMMC) framework, which built upon NIST SP 800-171 requirements to provide a new set of cybersecurity standards and best practices. CMMC maps controls and processes across three cybersecurity maturity levels ranging from basic to advanced. "), _c('br'), _c('br'), _vm._v(" The CMMC level required for a business to win a DoD contract is explained in each request for proposal. While CMMC accreditation requirements are currently almost exclusively limited to DoD contracts, the cyber hygiene best practices and standards contained within the CMMC framework is applicable to all Federal Agencies. ")])])]), _c('hr', {
    staticClass: "col-12"
  }), _c('div', {
    staticClass: "col-12 py-2"
  }, [_c('div', [_c('strong', [_vm._v("SBA Resource Partners")])]), _c('a', {
    attrs: {
      "href": "https://www.sba.gov/brand/assets/sba/resource-partners/SBA-ResourcePartnerNetwork-508_MCS0090.pdf"
    }
  }, [_vm._v("https://www.sba.gov/brand/assets/sba/resource-partners/SBA-ResourcePartnerNetwork-508_MCS0090.pdf")])])])])])]);

}]

export { render, staticRenderFns }