<template>
  <div class="main-content">
    <!-- BradCrumb header Start-->
    <section class="inner-header-bg">
      <div class="container">
        <!-- Breadcrumb Start-->
        <div class="section-breadcrumb">
          <div class="container">
            <div class="row">
              <div
                class="col-md-12 justify-content-center align-self-center pb-2"
              >
                <div class="breadcrumb-content">
                  <div class="title-section">
                    <h1 class="title text-center text-white">Tool Compare</h1>
                  </div>
                  <div class="breadcrumb-nav">
                    <ul class="breadcrumb-link text-center list-unstyled mb-0">
                      <li class="text-white">
                        <i class="mdi mdi-home theme-light-color pr-2"></i>
                        <a href="/#/">Home</a>
                      </li>
                      <li class="text-white">
                        <router-link :to="{ name: 'tool-list' }">
                          <a href="#">Tool Reviews</a>
                        </router-link>
                      </li>
                      <li class="theme-light-color active">Tool Compare</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Breadcrumb End -->
      </div>
    </section>
    <!-- BradCrumb header End -->
    <!-- Blog Details Start-->
    <section class="section section-blog">
      <div class="container">
        <article>
          <b-table-simple responsive hover>
            <b-thead class="border-0">
              <!-- Icon Header -->
              <b-tr class="border-0">
                <b-th class="border-0"></b-th>
                <b-th v-for="tool in items" :key="tool.id" class="border-0">
                  <img
                    :src="tool?.mainImage"
                    :alt="tool?.photoDescription"
                    class="tool-icon"
                  />
                </b-th>
              </b-tr>
              <!-- Title Header -->
              <b-tr>
                <b-th class="border-0"></b-th>
                <b-th v-for="tool in items" :key="tool.id" class="border-0">
                  <div class="flex-container title-container">
                    <p class="m-0 clipped-text flex-center title-text">
                      {{ tool.title }}
                    </p>
                  </div>
                </b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr v-for="feature in fieldsToCompare" :key="feature">
                <b-td class="bg-light">{{ feature.name }}</b-td>
                <b-td v-for="tool in items" :key="tool.id">
                  <!-- Show list for Certifications -->
                  <span v-if="feature.field === 'toolRelatedCertifications'">
                    <b-list-group
                      v-if="
                        tool?.toolRelatedCertifications?.certification.length >
                        0
                      "
                    >
                      <b-list-group-item
                        v-for="cert in tool?.toolRelatedCertifications
                          ?.certification"
                        :key="cert"
                        >{{ cert }}</b-list-group-item
                      >
                    </b-list-group>
                    <b-list-group v-else>
                      <b-list-group-item>None</b-list-group-item>
                    </b-list-group>
                  </span>
                  <!-- Show list for Certifications -->
                  <span v-else-if="feature.field === 'toolKnownClients'">
                    <b-list-group
                      v-if="tool?.toolKnownClients?.knownClient.length > 0"
                    >
                      <b-list-group-item
                        v-for="cert in tool?.toolKnownClients?.knownClient"
                        :key="cert"
                        >{{ cert }}</b-list-group-item
                      >
                    </b-list-group>
                    <b-list-group v-else>
                      <b-list-group-item>None</b-list-group-item>
                    </b-list-group>
                  </span>
                  <!-- Foor Boolean dields, show Check or X -->
                  <p
                    class="text-center m-0"
                    v-else-if="isBoolean(tool[feature.field])"
                  >
                    <font-awesome-icon
                      v-if="isBooleanTrue(tool[feature.field])"
                      class="pl-1"
                      size="lg"
                      :icon="['fas', 'check-circle']"
                    />
                    <font-awesome-icon
                      v-else
                      class="pl-1"
                      size="lg"
                      :icon="['fas', 'times-circle']"
                    />
                  </p>
                  <p class="text-center m-0" v-else>
                    {{ tool[feature.field] }}
                  </p>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </article>
      </div>
    </section>
    <!-- Blog Details Section End-->
  </div>
</template>
<script>
import { BFormInput } from 'bootstrap-vue';
import axios from 'axios';
import SocialShareBookmark from '../social-share-bookmark.vue';

export default {
  name: 'tool-detail',
  components: {
    BFormInput,
    SocialShareBookmark,
  },
  props: {
    ids: Array,
  },
  data() {
    return {
      isLoading: false,
      query: '',
      items: [],
      comment: '',
      linkCopyText: 'Click to copy url link',
      fieldsToCompare: [
        { name: 'Price Range', field: 'toolPriceRange' },
        { name: 'Tool Created By', field: 'toolCreatedBy' },
        { name: 'Established In', field: 'toolCompanyFoundingDate' },
        { name: 'Website', field: 'toolWebsite' },
        { name: 'Ease of Use', field: 'toolCostModel' },
        { name: 'Cost Model', field: 'toolCostModel' },
        { name: 'Typical Users', field: 'toolTypicalUsers' },
        { name: 'Description', field: 'headLine' },
        { name: 'Related Certifications', field: 'toolRelatedCertifications' },
        {
          name: 'Free Training Resources',
          field: 'toolFreeTrainingResourcesAvailable',
        },
        { name: 'Paid Training Resources', field: 'toolPaidTraining' },
        { name: 'Known Clients', field: 'toolKnownClients' },
      ],
    };
  },
  async created() {
    //console.log('ids', this.ids);
    if (this.ids.length > 0) {
      this.ids.forEach((element) => {
        this.getResource(element);
      });
    } else {
      this.$router.push({ path: 'tool-list' });
    }
  },
  mounted() {
    this.init();
  },
  computed: {
    title() {
      return this.item?.title || 'Title';
    },
    description() {
      return this.item?.headLine || 'HeadLine';
    },
  },
  methods: {
    init() {
      /* The footer height no longer needs to be set */
      // this.initFooterHeight();
    },
    initFooterHeight() {
      var footerHeight = $('footer').outerHeight();
      $('.main-content').css('margin-bottom', footerHeight);
    },
    async getResource(id) {
      //console.log('Get Resource', id);
      axios
        .get(`/api/resources/toolReviews/${id}`)
        .then((response) => {
          if (response.data.Items.length > 0) {
            /** Does an item exist for the query id */
            this.items.push(response.data.Items[0]);
            //console.log('Item', response.data);
            //console.log('ITems', this.items);
          } else {
            /** If no item exists with the id, redirect to list*/
            this.$router.push({ path: 'tool-list' });
          }
        })
        .catch((error) => {
          /** On error, redirect back to list */
          console.error(error);
          this.$router.push({ path: 'tool-list' });
        });
    },
    isBoolean(value) {
      var isBool = typeof value === 'boolean';
      var isBoolString = ['true', 'false'].includes(value);
      return isBool || isBoolString;
    },
    isBooleanTrue(value) {
      var isBool = typeof value === 'boolean';
      var isBoolString = ['true', 'false'].includes(value);
      return (isBool && value) || (isBoolString && value === 'true');
    },
  },
};
</script>
<style scoped>
.centered-link {
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 125px;
}

.image-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.tool-icon {
  object-fit: contain;
  height: 50px;
  display: block;
  margin: auto; /*optional centering of image*/
}

.title-container {
  height: 50px;
}

.flex-container {
  display: flex; /* establish flex container */
  flex-direction: column; /* make main axis vertical */
  justify-content: center; /* center items vertically, in this case */
  align-items: center; /* center items horizontally, in this case */
}

.flex-center {
  text-align: center; /* will center text in <p>, which is not a flex item */
}

.title-text {
  font-size: 1.2em;
  line-height: 1.2em;
}

.clipped-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>
