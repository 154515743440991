<template>
  <div class="main-content">
    <!-- BradCrumb header Start-->
    <section class="inner-header-bg">
      <div class="container">
        <!-- Breadcrumb Start-->
        <div class="section-breadcrumb">
          <div class="container">
            <div class="row">
              <div class="col-md-12 justify-content-center align-self-center pb-2">
                <div class="breadcrumb-content">
                  <div class="title-section">
                    <h1 class="title text-center text-white">
                      Training
                    </h1>
                  </div>
                  <div class="breadcrumb-nav">
                    <ul class="breadcrumb-link text-center list-unstyled mb-0">
                      <li class="text-white">
                        <i class="mdi mdi-home theme-light-color pr-2"></i>
                        <a href="/#/">Home</a>
                      </li>
                      <li class="theme-light-color active">training</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Breadcrumb End -->
      </div>
    </section>
    <!-- BradCrumb header End -->
    <!-- Blog Grid Section Start -->
    <section class="section">
      <div class="container">
        <p>
          APEX Accelerators can use the following resources to serve as critical enablers to opening the defense
          industrial base to small, medium, large, and new entrant companies.
        </p>

        <p class="pb-2" v-if="trainingCategoriesFilteredVisible.length === 0 && searchQuery">
          No items match the search criteria...
        </p>
        <p class="pb-2" v-if="trainingCategoriesFilteredVisible.length === 0 && !searchQuery">
          Check back soon for documents..
        </p>
        <div class="rows">
          <div class="col-md-6">
            <div class="pb-2 text-left">
              <p class="text-left"><strong>Choose a Document Type</strong> </p>
            </div>
            <div class="row pb-2  text-center">
              <div class="col-md-3"> 
                <img @click="setOverallCategory('trainings')" src="assets/images/trainings.png" class="img-fluid overall-category-img" alt="Trainings" />
              </div>
              <div class="col-md-3">
                <img @click="setOverallCategory('resources')" src="assets/images/resources.png" class="img-fluid overall-category-img" alt="Resources" />
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-md-4 order-lg-2">
            <aside class="sidebar sidebar-padding border">
              <h6 class="post-news-title search-title">Search</h6>
              <div class="pb-4">
                <b-form-input v-model="queryCategory" placeholder="Search All Training..."></b-form-input>
              </div>
              <div class="pb-2 mt-2 mb-3 tags-post">
                <div class="blog-news-content">
                  <div class="blog-post-list">
                    <ul class="list-unstyled ml-0">
                      <li class="blog-item">
                        <h6 class="post-news-title">Tags</h6>
                      </li>
                      <li v-for="cat in trainingCategories" :key="cat.id" class="list-inline-item">
                        <div class="blog-news-info"><a href="javascript:void(0)" class="text-dark text-small"
                            v-on:click="setTag(cat.tag)"> {{ cat.tag }}
                            <!-- <span
                              class="float-right">102</span> -->
                          </a></div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </aside>
          </div>
          <div class="col-md-8 order-lg-1">
            <aside class="sidebar sidebar-padding border">
              <div class="blog-post-grid">
                <div class="row mt-1 mb-4">
                  <!-- Start Blog Item -->
                  <!-- <ul class="list-unstyled ml-0">

                    <li v-for="item in displayedBlogItems" :key="item.id" class="mt-4 mb-4">
                      <a class="text-dark">

                        <router-link class="text-dark" :to="{ name: 'trainingDetail', query: { query: item.category } }">
                          <h4 class="blog-post-title training-category">
                            {{ item.category }}
                          </h4>
                        </router-link>

                      </a>
                    </li>

                  </ul> -->
                  <ul class="list-group">
                    <li class="list-group-item list-group-item-action" v-for="item in trainingCategoriesFilteredVisible" :key="item.id">
                      <div class="list-group-image-wrapper">
                        <div class="list-group-image">
                          <img v-if="item.imageURL == undefined" class="img-fluid" alt="training document icon" src="../../assets/images/logo/apex-logo-light.png" />
                          <img v-else class="img-fluid" alt="training document icon" :src="item.imageURL" />
                      </div>
                      </div>
                      
                      <a class="text-dark">
                        <router-link class="text-dark" :to="{ name: 'trainingDetail', query: { query: item.category } }">
                          <h4 class="blog-post-title training-category">
                            {{ item.category }}
                          </h4>
                        </router-link>
                      </a>
                    </li>
                  </ul>

                  <!-- End Blog Item -->
                </div>
              </div>

              <div>
                <b-pagination 
                  id="blog-pagination" 
                  v-model="currentPage" 
                  :total-rows="trainingCategoriesFilteredCount" 
                  :per-page="perPage"
                  align="center" 
                  @page-click="pageClicked">
                </b-pagination>
              </div>
            </aside>
          </div>
        </div>

      </div>
    </section>
    <!-- Blog Grid Section End -->
  </div>
</template>
<script>
import axios from 'axios';
import { BPagination, BFormInput } from 'bootstrap-vue';
export default {
  name: 'blog',
  components: {
    BPagination,
    BFormInput,
  },
  props: {
    query: String,
  },
  data() {
    return {
      searchQuery: this.query,
      items: [],
      itemCount: 0,
      currentPage: 1,
      perPage: 6,
      bucket: '',
      selectedFolder: '',
      trainingCategories: [],
      trainingCategoriesFiltered: [],
      queryCategory: '',
      queryOverallCategory: '',
      queryTag: ''
    };
  },
  computed: {
    trainingCategoriesFilteredCount() {
      return this.trainingCategoriesFiltered.length
    },
    trainingCategoriesFilteredVisible() {
      return this.trainingCategoriesFiltered.slice((this.currentPage - 1) * this.perPage, this.currentPage * this.perPage);
    }
  },
  methods: {
    setTag(tag) {
      console.log("you want to set the tag");
      this.queryTag = tag;
    },
    setOverallCategory(overallCategory) {
      this.queryOverallCategory = overallCategory;
    },
    async getTrainingCategories() {
      axios
        .get('/api/resources/training/categories')
        .then((response) => {
          this.items = response.data;
          this.trainingCategories = response.data;
          this.trainingCategoriesFiltered = response.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    pageClicked() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    filterTrainingCategories() {

      this.currentPage = 1;

      this.trainingCategoriesFiltered = this.items.filter((item) => {
          
        let matchesFound = [];

        // check category
        if(this.queryCategory && item.category.toLowerCase().includes(this.queryCategory.toLowerCase())) {
          matchesFound.push("category found");
        }

        // check tag
        if(item.tag.toLowerCase() == this.queryTag.toLowerCase()) {
          matchesFound.push("tag found");
        }

        if(item.overallCategory.toLowerCase() == this.queryOverallCategory.toLowerCase()) {
          matchesFound.push("overall category found");
        }

        return matchesFound.length > 0;

      });

    }
  },
  watch: {
    queryCategory() {
      this.filterTrainingCategories()
    },
    queryOverallCategory() {
      this.filterTrainingCategories()
    },
    queryTag() {
      this.filterTrainingCategories()
    }
  },
  mounted: async function () {
    await this.getTrainingCategories(); // async
  },
};
</script>
<style scoped>
#blog-pagination li {
  margin: 0px;
}

.useful-link-info {
  padding: 10px 5px 25px 0;
}

.thumbnail-cover {
  object-fit: cover;
  margin: auto;
  max-width: 100%;
  max-height: 100%;
  /* padding: 10px; */
}

.center-image-vertically {
  display: flex;
  justify-content: center;
}

.img-fluid {
  cursor: pointer;
}


.list-group-item {
  border: 1px solid transparent !important;
  display: flex;
  align-items: center;  
}

.list-group-image-wrapper {
  height: 60px;
  width: 60px;
  display: flex;
  flex-shrink: 0;
  margin-right: 10px;
  justify-content: center;
  align-items: center;
}

.list-group-item:hover, .list-group-item:focus {
  border: 1px solid rgba(0, 0, 0, 0.125)  !important;
}
</style> 