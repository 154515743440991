<template>
  <div class="main-content">
    <!-- BradCrumb header Start-->
    <section class="inner-header-bg">
      <div class="container">
        <!-- Breadcrumb Start-->
        <div class="section-breadcrumb">
          <div class="container">
            <div class="row">
              <div
                class="col-md-12 justify-content-center align-self-center pb-2"
              >
                <div class="breadcrumb-content">
                  <div class="title-section">
                    <h1 class="title text-center text-white">
                      Training Videos
                    </h1>
                  </div>
                  <div class="breadcrumb-nav">
                    <ul class="breadcrumb-link text-center list-unstyled mb-0">
                      <li class="text-white">
                        <i class="mdi mdi-home theme-light-color pr-2"></i>
                        <a href="/#/">Home</a>
                      </li>
                      <li class="theme-light-color active">Training Videos</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Breadcrumb End -->
      </div>
    </section>
    <!-- BradCrumb header End -->
    <!-- Courses and Training Videos Grid Section Start -->
    <section class="section">
      <div class="container">
        <p>
          Project Spectrum provides short, digestible, in-house produced videos
          that give you a quick primer on important cybersecurity issues.
        </p>
        <div class="pb-4">
          <b-form-input
            v-model="searchQuery"
            placeholder="Search Training Videos..."
          ></b-form-input>
        </div>

        <p class="pb-2" v-if="displayedItems.length === 0 && searchQuery">
          No items match the search criteria...
        </p>
        <p class="pb-2" v-if="displayedItems.length === 0 && !searchQuery">
          Check back soon for more courses and training videos...
        </p>
        <div class="blog-post-grid">
          <div class="row">
            <!-- Start Blog Item -->
            <div
              class="col-md-6 col-lg-4 mb-5"
              v-for="item in displayedItems"
              :key="item.id"
            >
              <div class="blog-item border h-100">
                <a
                  :href="itemHref(item)"
                  :target="itemTarget(item)"
                  class="blog-post-image"
                >
                  <img
                    :src="item.trainingVideoThumbnailLink"
                    :alt="item.photoDescription"
                    class="cover"
                  />
                </a>
                <div
                  class="blog-post-info d-flex align-items-start flex-column"
                >
                  <h4 class="blog-post-title">
                    {{ item.title }}
                  </h4>
                  <div class="w-100">
                    <p class="truncate">{{ item.source }}</p>
                  </div>

                  <div
                    class="post-event-bottom d-flex align-items-center mt-auto"
                  >
                    <a class="blog-info-meta text-dark"
                      ><i class="mdi mdi-calendar-outline pr-1"></i
                      >{{ getFormattedDate(item.date) }}</a
                    >

                    <a class="blog-category text-dark"
                      ><i class="mdi mdi-clock-outline pr-1"></i
                      >{{ item.duration }}</a
                    >
                  </div>
                  <!-- Bookmark Page -->
                  <div class="float-right-bottom" v-if="!item.isPageBookmarked">
                    <font-awesome-icon
                      id="tooltip-bookmark"
                      :icon="['far', 'bookmark']"
                      v-on:click="onBookmarkPage(item)"
                    />
                    <b-tooltip target="tooltip-bookmark" variant="primary"
                      >Click to bookmark video</b-tooltip
                    >
                  </div>
                  <!-- Page Already Bookmarked -->
                  <div class="float-right-bottom" v-else>
                    <font-awesome-icon
                      id="tooltip-bookmark"
                      :icon="['fas', 'bookmark']"
                    />
                    <b-tooltip target="tooltip-bookmark" variant="success"
                      >Bookmarked!</b-tooltip
                    >
                  </div>
                  <!-- End Page Already Bookmarked -->
                  <a
                    class="blog-read-more"
                    :href="itemHref(item)"
                    :target="itemTarget(item)"
                  >
                    <i
                      class="mdi"
                      :class="isLocked(item) ? 'mdi-lock' : 'mdi-play'"
                    ></i>
                  </a>
                </div>
              </div>
            </div>
            <!-- End Blog Item -->
          </div>
        </div>
        <div>
          <b-pagination
            id="blog-pagination"
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="center"
            @page-click="pageClicked"
          ></b-pagination>
        </div>
      </div>
    </section>
    <!-- Courses and Training Videos Grid Section End -->
    <!-- Login Modal -->
    <b-modal size="lg" ref="my-modal" hide-footer :title="modalMessage">
      <p class="my-4">
        Please
        <router-link
          :to="{
            name: 'login',
            query: { redirect: getRedirectRoute },
          }"
        >
          <a>LOGIN</a>
        </router-link>
        or
        <router-link :to="{ name: 'signup' }">
          <a>SIGN UP</a>
        </router-link>
        for project spectrum.
      </p>
    </b-modal>
    <!-- End Login Modal -->
  </div>
</template>
<script>
import axios from 'axios';
import { BPagination, BFormInput } from 'bootstrap-vue';
import { isLoggedIn, handleApiError } from '../../utils/authentication';
export default {
  name: 'courses-training',
  components: {
    BPagination,
    BFormInput,
  },
  props: {
    query: String,
  },
  data() {
    return {
      tabIndex: 0,
      searchQuery: this.query,
      blogItems: [],
      blogItemCount: 0,
      currentPage: 1,
      perPage: 9,
      items: [],
      modalMessage: '',
      loginToBookmarkMessage: 'Want to bookmark this for later?',
      loginToAccessMessage: 'Want to access additional content?',
    };
  },
  computed: {
    getRedirectRoute() {
      return '/training-videos';
    },
    displayedItems() {
      return this.itemsFromSearchQuery.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
      );
    },
    itemsFromSearchQuery() {
      if (this.searchQuery) {
        return this.items.filter((item) => {
          return this.searchQuery
            .toLowerCase()
            .split(' ')
            .every(
              (v) =>
                item.title.toLowerCase().includes(v) ||
                item.source.toLowerCase().includes(v)
            );
        });
      } else {
        return this.items;
      }
    },
    totalRows() {
      return this.itemsFromSearchQuery.length;
    },
  },
  mounted: async function () {
    this.init();
    this.getBlogPosts(); // async
  },
  methods: {
    init() {
      /* The footer height no longer needs to be set */
      // this.initFooterHeight();
    },
    initFooterHeight() {
      var footerHeight = $('footer').outerHeight();
      $('.main-content').css('margin-bottom', footerHeight);
    },
    itemHref(item) {
      return item.auth && !isLoggedIn()
        ? '/#/login?redirect=/training-videos'
        : item.trainingVideoRecordingLink;
    },
    itemTarget(item) {
      return item.auth && !isLoggedIn() ? '' : '_blank';
    },
    async getBlogPosts() {
      var training = await this.getTrainingPosts();
      var trainingAuth = await this.getAuthTrainingPosts();
      this.items = training.concat(trainingAuth);
    },
    pageClicked() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    /**
     * NO AUTH Training Videos
     * API call to server: GET /api/resources/trainingVideos
     */
    async getTrainingPosts() {
      var items = [];
      await axios
        .get('/api/resources/trainingVideos')
        .then((response) => {
          items = response.data.Items.map((obj) => ({ ...obj, auth: false }));
        })
        .catch((error) => {
          console.error(error);
        });
      return items;
    },
    /**
     * AUTH Training Videos
     * API call to server: GET /api/resources/trainingVideosAuthenticated
     */
    async getAuthTrainingPosts() {
      var items = [];
      await axios
        .get('/api/resources/trainingVideosAuthenticated')
        .then((response) => {
          items = response.data.Items.map((obj) => ({ ...obj, auth: true }));
        })
        .catch((error) => {
          console.error(error);
        });
      return items;
    },
    isLocked(item) {
      return item.auth && !isLoggedIn();
    },
    showLoginModal() {
      this.modalMessage = this.loginToAccessMessage;
      this.$refs['my-modal'].show();
    },
    onBookmarkPage(item) {
      //console.log('Bookmark Page', item);
      if (!isLoggedIn()) {
        console.warn('Not Logged In');
        this.modalMessage = this.loginToBookmarkMessage;
        this.$refs['my-modal'].show();
      } else {
        this.bookmarkPage(item);
      }
    },
    async bookmarkPage(item) {
      const url = item.trainingVideoRecordingLink;
      const title = item.title;
      const type = 'video';
      //console.log('Bookmarking', url, title, type);

      axios
        .post(`/api/bookmark`, {
          url: url,
          title: title,
          type: type,
        })
        .then(async (response) => {
          //console.log('Bookmark Response', response);
          // Set selected as bookmarked
          const updatedItems = await Promise.all(
            this.items.map(async (training) => {
              if (training.id == item.id) {
                training.isPageBookmarked = true;
              }
              return training;
            })
          );
          this.items = updatedItems;
        })
        .catch((error) => {
          handleApiError(error, this.$router);
        });
    },
  },
};
</script>
<style scoped>
.inner-header-bg {
  background-image: url('~@/assets/images/headers/training.png');
  background-position: center center;
}
#blog-pagination li {
  margin: 0px;
}

.cover {
  object-fit: cover;
  height: 225px;
  width: 100%;
}

.float-right-bottom {
  position: absolute;
  bottom: 40px;
  right: 20px;
}
</style>
