<template>
  <div class="main-content">
    <!-- BreadCrumb header Start-->
    <section class="inner-header-bg-partner">
      <div class="container">
        <!-- Breadcrumb Start-->
        <div class="section-breadcrumb">
          <div class="container">
            <div class="row">
              <div class="col-md-12 justify-content-center align-self-center pb-2">
                <div class="breadcrumb-content">
                  <div class="title-section">
                    <h1 class="title text-center text-white">
                      Service Area Locator
                    </h1>
                  </div>
                  <div class="breadcrumb-nav">
                    <ul class="breadcrumb-link text-center list-unstyled mb-0">
                      <li class="text-white">
                        <i class="mdi mdi-home theme-light-color pr-2"></i>
                        <a href="#">Home</a>
                      </li>
                      <li class="theme-light-color active">
                        Service Area Locator
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Breadcrumb End -->
      </div>
    </section>

    <!-- Body Area Start-->
    <section class="section portfolio-section" id="portfolio">
      <div class="container">
        <h2 class="pb-3 text-left">FIND AN APEX ACCELERATOR NEAR YOU</h2>
        <p>
          Select your state, then county from the drop down list to find the location serves you. There is map lookup on
          the <a href="/">home page</a> if you prefer that method.
          <br />
          If you are a Native American business, or if you wish to explore the potential of selling to Tribal
          governments, we invite you to contact the Native American Accelerator that serves your area. <a
                        href="#NativeAmerican"  @click="scrollToElement">Click
                        here</a> for
          the Native American Apex Accelerators map for contact information.
        </p>
        <div class="row">
          <div class="col-lg-4 col-md-12">
            <b-form @submit.prevent="submitForm">
              <b-form-group label-for="state">
                <b-form-select id="state" v-model="form.state" :options="stateFipsOption" :state="null" name="state"
                  class="form-control" aria-describedby="input-state-feedback" placeholder="State*" required="required"
                  @change="onStateChange"></b-form-select>
              </b-form-group>
              <p class="error-message" v-if="!validateState('state')">Please select a state from the dropdown</p>

              <b-form-group label-for="county" v-if="validateState('state')">
                <b-form-select id="county" v-model="form.county" :options="countiesOption" :state="null" name="county"
                  class="form-control" aria-describedby="input-county-feedback" placeholder="County*"
                  required="required"></b-form-select>
              </b-form-group>
              <p class="error-message" v-if="validateState('state') && !validateState('county')">Please select a county
                from the dropdown</p>
              <div v-if="loadingCounties" class="spinner"></div>


              <p class="text-muted small">(* Required)</p>
              <div class="d-flex justify-content-end">
                <b-button type="submit" class="btn theme-btn w-50 rounded mt-1">Submit
                </b-button>
              </div>
            </b-form>
          </div>
          <div class="col-lg-8 col-md-12">
            <div v-if="result">
              <p class="mb-2 h4"><b>Please contact:</b></p>
              <custom-list-component :items="result" />
            </div>
          </div>
          <div class="container">
            <div class="row mb-3">
              <div class="col-12">
                <div id="NativeAmerican"></div>
                <h3 class="mt-5 pt-5 map-subtitle">NATIVE AMERICAN APEX ACCELERATORS</h3>
                <p>There are six specific APEX Accelerators designated to serve Native-owned businesses doing business
                    with federal, state, local and tribal governments. These Accelerators bring expert knowledge of laws
                    and certifications specific to Native business and an understanding of the cultures and communities
                    to help Native and non-Native firms be successful.</p>
                <p class="mb-5">For assistance, please contact the Native American APEX Accelerator that serves your area.</p>
                <img class="img-fluid" src="../../assets/images/native-american/Native_APEX_Map.png" />
              </div>
            </div>
            <div class="row">
              <div v-for="location in nativeAmericanList" class="col-lg-6">
                <NativeAmericanLocationBadge :large="location.large" :boxColor="location.boxColor"
                  :labelColor="location.labelColor" :line1="location.line1" :line2="location.line2"
                  :line3="location.line3" :address1="location.address1" :address2="location.address2" />

              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import axios from "axios";
import CustomListComponent from "@/components/customListComponent.vue";
import NativeAmericanLocationBadge from '../../components/library/badges/native-america-location-badge.vue';
export default {
  components: {
    CustomListComponent,
    NativeAmericanLocationBadge
  },
  data() {
    return {
      loadingCities: false,
      loadingCounties: false,
      form: {
        state: "",
        county: "",
        // city: "",
      },
      // currentRange: 10,
      // rangeOption: [
      //   { value: 10, text: "10 miles" },
      //   { value: 25, text: "25 miles" },
      //   { value: 50, text: "50 miles" },
      //   { value: 100, text: "100 miles" },
      // ],
      countiesOption: [],
      // citiesOption: [],
      stateFipsOption: [
        { value: "01", text: "AL - Alabama" },
        { value: "02", text: "AK - Alaska" },
        { value: "04", text: "AZ - Arizona" },
        { value: "05", text: "AR - Arkansas" },
        { value: "06", text: "CA - California" },
        { value: "08", text: "CO - Colorado" },
        { value: "09", text: "CT - Connecticut" },
        { value: "10", text: "DE - Delaware" },
        { value: "11", text: "DC - District of Columbia" },
        { value: "12", text: "FL - Florida" },
        { value: "13", text: "GA - Georgia" },
        { value: "66", text: "GU - Guam" },
        { value: "15", text: "HI - Hawaii" },
        { value: "16", text: "ID - Idaho" },
        { value: "17", text: "IL - Illinois" },
        { value: "18", text: "IN - Indiana" },
        { value: "19", text: "IA - Iowa" },
        { value: "20", text: "KS - Kansas" },
        { value: "21", text: "KY - Kentucky" },
        { value: "22", text: "LA - Louisiana" },
        { value: "23", text: "ME - Maine" },
        { value: "24", text: "MD - Maryland" },
        { value: "25", text: "MA - Massachusetts" },
        { value: "26", text: "MI - Michigan" },
        { value: "27", text: "MN - Minnesota" },
        { value: "28", text: "MS - Mississippi" },
        { value: "29", text: "MO - Missouri" },
        { value: "30", text: "MT - Montana" },
        { value: "31", text: "NE - Nebraska" },
        { value: "32", text: "NV - Nevada" },
        { value: "33", text: "NH - New Hampshire" },
        { value: "34", text: "NJ - New Jersey" },
        { value: "35", text: "NM - New Mexico" },
        { value: "36", text: "NY - New York" },
        { value: "37", text: "NC - North Carolina" },
        { value: "38", text: "ND - North Dakota" },
        { value: "39", text: "OH - Ohio" },
        { value: "40", text: "OK - Oklahoma" },
        { value: "41", text: "OR - Oregon" },
        { value: "42", text: "PA - Pennsylvania" },
        { value: "72", text: "PR - Puerto Rico" },
        { value: "44", text: "RI - Rhode Island" },
        { value: "45", text: "SC - South Carolina" },
        { value: "46", text: "SD - South Dakota" },
        { value: "47", text: "TN - Tennessee" },
        { value: "48", text: "TX - Texas" },
        { value: "49", text: "UT - Utah" },
        { value: "50", text: "VT - Vermont" },
        { value: "51", text: "VA - Virginia" },
        { value: "78", text: "VI - Virgin Islands" },
        { value: "53", text: "WA - Washington" },
        { value: "54", text: "WV - West Virginia" },
        { value: "55", text: "WI - Wisconsin" },
        { value: "56", text: "WY - Wyoming" },
      ],
      result: null,
      nativeAmericanList: [
        {
          id: 'a99b8751asda',
          large: false,
          boxColor: '#15263b',
          labelColor: 'white',
          line1: "Pacific",
          line2: "Region",
          line3: "",
          address1: {
            title: "American Indian Chamber APEX Accelerator",
            street: "5912 Bolsa Avenue, Suite 108",
            cityStateZip: "Huntington Beach, CA 92649",
            phone: "(213) 341-0104",
            website: "http://aicef-apex.org",
            email: "ptacinfo@aicccal.org"
          }
        },
        {
          id: 'a99b8751=345234',
          large: false,
          boxColor: '#8878c3',
          labelColor: 'black',
          line1: "Eastern",
          line2: "Region",
          line3: "",
          address1: {
            title: "The National Center APEX Accelerator",
            street: "86 South Cobb Drive, MZ 0510",
            cityStateZip: "Marietta, GA 30063-0510",
            phone: "(480) 280-6098",
            website: "https://www.ncaied.org/ptac",
            email: "apex@ncaied.org"
          }
        },
        {
          id: 'a99b87qwe',
          large: false,
          boxColor: '#17375e',
          labelColor: 'white',
          line1: "Western",
          line2: "Region",
          line3: "",
          address1: {
            title: "American Indian Chamber APEX Accelerator",
            street: "3200 North Dobson Rd. Building C, Suite 103",
            cityStateZip: "Chandler, AZ 85224",
            phone: "(480) 699-9529",
            website: "http://aicef-apex.org",
            email: "ptacinfo@aicccal.org "
          }
        },
        {
          id: 'a99b8234',
          large: false,
          boxColor: '#cec1ff',
          labelColor: 'black',
          line1: "Midwest",
          line2: "Region",
          line3: "",
          address1: {
            title: "PEDCO Native APEX Accelerator",
            street: "2756 O Street",
            cityStateZip: "Lincoln, NE 68510",
            phone: "(531) 310-2494",
            website: "https://pedco-ne.org/pedco-native-apex-accelerator",
            email: "jniceswanger@pedcoapex.org"
          }
        },
        {
          id: 'a99b87dsfs',
          large: false,
          boxColor: '#868896',
          labelColor: 'white',
          line1: "Rocky Mountain",
          line2: "Region",
          line3: "",
          address1: {
            title: "Native American Development Corporation APEX Accelerator",
            street: "17 N. 26th St.",
            cityStateZip: "Billings, MT 59101",
            phone: "(406) 259-3804",
            website: "http://www.nadc-nabn.org",
            email: "nadcptac@nadc-nabn.org"
          }
        },
        {
          id: 'a99b87523r',
          large: false,
          boxColor: '#392464',
          labelColor: 'white',
          line1: "Southern Plains",
          line2: "Region",
          line3: "",
          address1: {
            title: "Tribal Government Institute APEX Accelerator",
            street: "111 N. Peters, Suite 450",
            cityStateZip: "Norman, OK 73069",
            phone: "(405) 329-5542",
            website: "http://www.tgiok.com",
            email: "tgi@tgiok.com"
          }
        },
        {
          id: 'a99b8qerd',
          large: false,
          boxColor: '#acc8eb',
          labelColor: 'black',
          line1: "Navajo",
          line2: "Region",
          line3: "",
          address1: {
            title: "The National Center APEX Accelerator",
            street: "Hwy 264, 100 Taylor Rd.",
            cityStateZip: "St. Michaels, AZ 86511 - Window Rock, AZ 86511",
            phone: "(480) 371-8373",
            website: "https://www.ncaied.org/ptac",
            email: "apex@ncaied.org"
          }
        },
        {
          id: 'a99b8retwer',
          large: false,
          boxColor: '#09ffff',
          labelColor: 'black',
          line1: "Eastern Oklahoma",
          line2: "Region",
          line3: "",
          address1: {
            title: "Tribal Government Institute APEX Accelerator",
            street: "111 N. Peters, Suite 450",
            cityStateZip: "Norman, OK 73069",
            phone: "(405) 329-5542",
            website: "http://www.tgiok.com",
            email: "tgi@tgiok.com"
          }
        },
        {
          id: 'a99bwretvc',
          large: false,
          boxColor: '#728fb4',
          labelColor: 'white',
          line1: "Southwest",
          line2: "Region",
          line3: "",
          address1: {
            title: "The National Center APEX Accelerator",
            street: "12503 E. Euclid Drive, Suite 200",
            cityStateZip: "Centennial, CO 80111",
            phone: "(480) 824-8520",
            website: "https://www.ncaied.org/ptac",
            email: "apex@ncaied.org"
          }
        },
        {
          id: 'a99wrwer',
          large: false,
          boxColor: '#cac7d4',
          labelColor: 'black',
          line1: "Great Plains",
          line2: "Region",
          line3: "",
          address1: {
            title: "Native American Development Corporation APEX Accelerator",
            street: "17 N. 26th St.",
            cityStateZip: "Billings, MT 59101",
            phone: "(406) 259-3804",
            website: "http://www.nadc-nabn.org",
            email: "nadcptac@nadc-nabn.org"
          }
        },
        {
          id: 'aerdfhhs3423',
          large: true,
          boxColor: '#355e93',
          labelColor: 'white',
          line1: "Northwest",
          line2: "Region",
          line3: "",
          address1: {
            title: "American Indian Chamber APEX Accelerator",
            street: "719 Jadwin Avenue, Suite 23",
            cityStateZip: "Richland, WA 99352",
            phone: "(509) 581-7505",
            website: "http://aicef-apex.org",
            email: "ptacinfo@aicccal.org"
          },
          address2: {
            title: "Northwest Native APEX Accelerator",
            street: "4445 SW Barbur Blvd., Suite 105",
            cityStateZip: "Portland, OR 97239",
            phone: "(503) 404-4572",
            website: "https://nnapex.org",
            email: "info@nnapex.org"
          },
        },
        {
          id: 'a99452gsdf',
          large: true,
          boxColor: '#cac7d4',
          labelColor: 'black',
          line1: "Nebraska",
          line2: "Region",
          line3: "Region",
          address1: {
            title: "Native American Development Corporation APEX Accelerator",
            street: "17 N. 26th St.",
            cityStateZip: "Billings, MT 59101",
            phone: "(406) 259-3804",
            website: "http://www.nadc-nabn.org",
            email: "nadcptac@nadc-nabn.org"
          },
          address2: {
            title: "Nebraska Indian Community College APEX Accelerator",
            street: "1111 Highway 75",
            cityStateZip: "Macy, NE 68039",
            phone: "(402) 494-2311",
            website: "https://govology.com/nicc-apex",
            email: "APEX@thenicc.edu"
          }
        }
      ]

    };
  },
  methods: {
    scrollToElement() {
            setTimeout(function(){
            history.replaceState("", document.title, window.location.pathname);
        }, 100);
      },
    async getCounties() {
      //this.test();
      this.loadingCounties = true;
      try {
        const response = await axios.get(
          `/api/resources/counties/${this.form.state}`
        );
        if (response.data.length == 0 && Array.isArray(response.data)) {
          this.countiesOption = [{
            value: 'Statewide',
            text: 'Statewide'
          }];
        }
        else if (response.data && Array.isArray(response.data)) {
          this.countiesOption = response.data.map((item) => ({
            value: item.value, // The county FIPS code
            text: item.text, // The county name
          }));
        } else {
          this.countiesOption = [];
        }
      } catch (error) {
        console.error("Error fetching counties:", error);
      } finally {
        this.loadingCounties = false
      }
    },

    // async getCities() {
    //   this.loadingCities = true;
    //   try {
    //     const response = await axios.get(
    //       `/api/resources/cities/${this.form.county}`
    //     );
    //
    //     if (response.data && Array.isArray(response.data)) {
    //       this.citiesOption = response.data.map((city) => ({
    //         value: city.value,
    //         text: city.text,
    //         lat: city.lat,
    //         lng: city.lng,
    //       }));
    //     } else {
    //       this.citiesOption = [];
    //     }
    //   } catch (error) {
    //     console.error("Error fetching cities:", error);
    //   } finally {
    //     this.loadingCities = false;
    //   }
    // },
    async onRangeChange() {
      // const selectedCity = this.citiesOption.find(
      //   (city) => city.value === this.form.city
      // );
      const selectedState = this.stateFipsOption.find(
        (state) => state.value === this.form.state
      );
      let selectedCounty = this.countiesOption.find(
        (county) => county.value === this.form.county
      );
      selectedCounty = selectedCounty.text.split(",")[0];
      const rawData = (
        await axios.post("/api/resources/getApexSC", {
          state: selectedState.text.slice(0, 2),
          county: selectedCounty,
        })
      ).data;
      console.log("my DATa is ", rawData);
      // for (const key in rawData) {
      //   formattedData.push(rawData(key));
      // }
      this.result = rawData;
    },
    onStateChange() {
      if (this.validateState("state")) {
        this.getCounties();
      } else {
        this.form.county = "";
        this.form.city = "";
      }
    },
    // onCountyChange() {
    //   if (this.validateState("county")) {
    //     console.log("calling cities");
    //     this.getCities();
    //   } else {
    //     this.form.city = "";
    //   }
    // },
    submitForm() {
      // Add form validation logic here
      if (this.validateForm()) {
        this.onRangeChange();
      } else {
        alert("Please fill in all fields.");
      }
    },
    validateForm() {
      return this.validateState("state") && this.validateState("county");
    },
    validateState(field) {
      return this.form[field] !== "";
    }
  }
}
</script>
<style scoped>
.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #09f;
  animation: spinner 1s linear infinite;
}

.error-message {
  font-weight: 600;
  color: #ff6767;
  margin: 0;
  margin-top: 5px;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
</style>
