import { render, staticRenderFns } from "./manufacturing-extension-partnership.vue?vue&type=template&id=3682adc0&scoped=true&"
import script from "./manufacturing-extension-partnership.vue?vue&type=script&lang=js&"
export * from "./manufacturing-extension-partnership.vue?vue&type=script&lang=js&"
import style0 from "./manufacturing-extension-partnership.vue?vue&type=style&index=0&id=3682adc0&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3682adc0",
  null
  
)

export default component.exports