<template>
  <div class="share-network-container">
    <ShareNetwork network="linkedin" :url="url" :title="title">
      <font-awesome-icon class="pl-1" size="2x" :icon="['fab', 'linkedin']" />
    </ShareNetwork>
    <ShareNetwork
      network="twitter"
      :url="url"
      :title="title"
      :description="description"
    >
      <font-awesome-icon
        class="pl-1"
        size="2x"
        :icon="['fab', 'twitter-square']"
      />
    </ShareNetwork>
    <ShareNetwork
      network="facebook"
      :url="url"
      :title="title"
      :description="description"
    >
      <font-awesome-icon
        class="pl-1"
        size="2x"
        :icon="['fab', 'facebook-square']"
      />
    </ShareNetwork>
    <ShareNetwork
      network="reddit"
      :url="url"
      :title="title"
      :description="description"
    >
      <font-awesome-icon
        class="pl-1"
        size="2x"
        :icon="['fab', 'reddit-square']"
      />
    </ShareNetwork>
    <div class="pl-1">
      <font-awesome-icon
        id="tooltip-copy"
        size="1x"
        icon="link"
        v-on:click="onCopyLink"
      />
      <b-tooltip variant="primary" target="tooltip-copy">{{
        linkCopyText
      }}</b-tooltip>
    </div>
    <!-- Bookmark Page -->
    <div class="pl-1" v-if="!isPageBookmarked">
      <font-awesome-icon
        id="tooltip-bookmark"
        :icon="['far', 'bookmark']"
        v-on:click="onBookmarkPage"
      />
      <b-tooltip variant="primary" target="tooltip-bookmark"
        >Click to bookmark page</b-tooltip
      >
    </div>
    <!-- Page Already Bookmarked -->
    <div class="pl-1" v-else>
      <font-awesome-icon id="tooltip-bookmark" :icon="['fas', 'bookmark']" />
      <b-tooltip  variant="success" target="tooltip-bookmark">Bookmarked!</b-tooltip>
    </div>
    <!-- Login Modal -->
    <b-modal
      size="lg"
      ref="my-modal"
      hide-footer
      title="Want to bookmark this for later?"
    >
      <p class="my-4">
        Please
        <router-link
          :to="{
            name: 'login',
          }"
        >
          <a>LOGIN</a>
        </router-link>
        or
        <router-link :to="{ name: 'signup' }">
          <a>SIGN UP</a>
        </router-link>
        to bookmark content.
      </p>
    </b-modal>
    <!-- End Login Modal -->
  </div>
</template>
<script>
import axios from 'axios';
import { isLoggedIn, handleApiError } from '../utils/authentication';
export default {
  name: 'social-share',
  props: ['title', 'description', 'type'],
  data() {
    return {
      linkCopyText: 'Click to copy url link',
      isPageBookmarked: false,
    };
  },
  mounted() {
    // TODO: Check is the item is currently bookmarked
    // this.checkIfBookmarked()
  },
  computed: {
    url() {
      return location.href || 'URL';
    },
  },
  methods: {
    onCopyLink() {
      navigator.clipboard.writeText(location.href);
      this.linkCopyText = 'Link Copied!';
    },
    onBookmarkPage() {
      //console.log('Bookmark Page', this.url);
      if (!isLoggedIn()) {
        console.warn('Not Logged In');
        this.$refs['my-modal'].show();
      } else {
        this.bookmarkPage();
      }
    },
    async bookmarkPage() {
      /*  API call to server: GET /api/resources/blogs */
      //console.log('Bookmarking', this.url, this.title, this.type);
      axios
        .post(`/api/bookmark`, {
          url: this.url,
          title: this.title,
          type: this.type,
        })
        .then((response) => {
          //console.log('Bookmark Response', response);
          this.isPageBookmarked = true;
        })
        .catch((error) => {
          handleApiError(error, this.$router);
        });
    },
    // TODO: Check if bookmarked
    // async checkIfBookmarked() {
    //   console.log('Checking if Bookmarked', this.url);
    //   axios
    //     .get(`/api/bookmark/${this.url}`)
    //     .then((response) => {
    //       console.log('Is Bookmarked Response', response);
    //       // this.isPageBookmarked = true;
    //     })
    //     .catch((error) => {
    //       handleApiError(error,  this.$router);
    //     });
    // },
  },
};
</script>
<style scoped>
.centered-link {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
