<template>
  <div class="main-content bg-light">
    <!-- BradCrumb header Start-->
    <section class="inner-header-bg">
      <div class="container">
        <!-- Breadcrumb Start-->
        <div class="section-breadcrumb">
          <div class="container">
            <div class="row">
              <div
                class="col-md-12 justify-content-center align-self-center pb-2"
              >
                <div class="breadcrumb-content">
                  <div class="title-section">
                    <h1 class="title text-center text-white">Login</h1>
                  </div>
                  <div class="breadcrumb-nav">
                    <ul class="breadcrumb-link text-center list-unstyled mb-0">
                      <li class="text-white">
                        <i class="mdi mdi-home theme-light-color pr-2"></i>
                        <a href="/#/">Home</a>
                      </li>
                      <li class="theme-light-color active">Login</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Breadcrumb End -->
      </div>
    </section>
    <!-- BradCrumb header End -->
    <!-- Login Start-->
    <section class="login-form section bg-light">
      <!-- Overlay -->
      <b-overlay :show="busy" no-wrap>
        <template #overlay>
          <b-spinner label="Loading..."></b-spinner>
        </template>
      </b-overlay>
      <!-- Login Container -->
      <div class="container bg-white">
        <div class="login-container">
          <div class="row justify-content-center">
            <div class="d-none d-lg-flex col-lg-6 form-thumbnail p-5">
              <div class="thumboverlay"></div>
            </div>
            <div
              class="col-lg-6"
              v-if="form.currentLoginState == LoginState.LOGIN"
            >
              <form @submit.prevent class="form-body">
                <div class="text-center section-title">
                  <h2 class="text-center">Login</h2>
                </div>
                <div class="mt-4">
                  <div class="row">
                    <div class="col-12">
                      <div class="form-group">
                        <b-form-input
                          type="text"
                          :state="validateState('email')"
                          placeholder="Enter Email"
                          required="required"
                          v-model="form.email"
                          autocomplete="username"
                        />
                        <b-form-invalid-feedback id="input-phone-feedback">
                          Email is required
                        </b-form-invalid-feedback>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-group">
                        <b-form-input
                          type="password"
                          :state="validateState('password')"
                          name="password"
                          placeholder="Enter Password"
                          required="required"
                          v-model="form.password"
                          autocomplete="current-password"
                        />
                        <b-form-invalid-feedback id="input-phone-feedback">
                          Password is required
                        </b-form-invalid-feedback>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12 text-center">
                      <div class="alert alert-danger" v-if="!!error">
                        {{ error }}
                      </div>
                      <button
                        type="button"
                        @click="login"
                        class="btn theme-btn rounded w-100"
                      >
                        Login
                      </button>
                    </div>
                    <div class="col-sm-12 mt-5">
                      <div class="lost-password text-center">
                        <span
                          ><a
                            href="#"
                            @click="onPasswdReset"
                            class="theme-light-color"
                          >
                            Forgot username or password?</a
                          ></span
                        >
                        <router-link :to="{ name: 'signup' }">
                          <a class="d-block mt-2 theme-light-color"
                            >Create Account</a
                          >
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div
              class="col-lg-6"
              v-if="form.currentLoginState == LoginState.SEND_EMAIL_CODE"
            >
              <form @submit.prevent class="form-body">
                <div class="text-center section-title">
                  <h2 class="text-center">Password Reset</h2>
                </div>

                <div class="mt-4">
                  <div class="row">
                    <div class="col-12">
                      <div class="form-group">
                        <b-form-input
                          type="text"
                          :state="validateState('email')"
                          placeholder="Enter Email"
                          required="required"
                          v-model="form.email"
                          autocomplete="username"
                        />
                        <b-form-invalid-feedback id="input-phone-feedback">
                          Email is required
                        </b-form-invalid-feedback>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12 text-center">
                      <div class="alert alert-danger" v-if="!!error">
                        {{ error }}
                      </div>
                      <button
                        type="button"
                        @click="sendResetCode"
                        class="btn theme-btn rounded w-100"
                      >
                        Email Code
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div
              class="col-lg-6"
              v-if="form.currentLoginState == LoginState.RESET_PASSWORD"
            >
              <form @submit.prevent class="form-body">
                <div class="text-center section-title">
                  <h2 class="text-center">Password Reset</h2>
                </div>

                <div class="mt-4">
                  <div class="row">
                    <div class="col-12">
                      <div class="form-group">
                        <b-form-input
                          type="text"
                          :state="validateState('email')"
                          placeholder="Enter username"
                          required="required"
                          v-model="form.email"
                          autocomplete="username"
                        />
                        <b-form-invalid-feedback id="input-phone-feedback">
                          Email is Required
                        </b-form-invalid-feedback>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-group">
                        <b-form-input
                          type="number"
                          :state="validateState('code')"
                          placeholder="Enter Code"
                          required="required"
                          v-model="form.code"
                          autocomplete="username"
                        />
                        <b-form-invalid-feedback id="input-phone-feedback">
                          Verification Code is Required (sent to your email)
                        </b-form-invalid-feedback>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-group">
                        <b-form-input
                          type="password"
                          :state="validateState('password')"
                          name="password"
                          placeholder="Enter Password"
                          required="required"
                          v-model="form.password"
                          autocomplete="current-password"
                        />
                        <div
                          v-if="!validateState('password')"
                          class="invalid-feedback"
                        >
                          <div class="error" v-if="!$v.form.password.required">
                            Password is required
                          </div>
                          <div
                            class="error"
                            v-if="!$v.form.password.goodPassword"
                          >
                            Must be at least 8 characters and contain a
                            lowercase, uppercase, number and special character.
                          </div>
                        </div>
                        <small
                          id="passwordHelpBlock"
                          class="form-text text-muted"
                          v-if="$v.form.password.goodPassword"
                        >
                          Must be at least 8 characters and contain a lowercase,
                          uppercase, number and special character.
                        </small>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12 text-center">
                      <div class="alert alert-danger" v-if="!!error">
                        {{ error }}
                      </div>
                      <button
                        type="button"
                        @click="passwordreset"
                        class="btn theme-btn rounded w-100"
                      >
                        Reset Password
                      </button>
					  	 <b-alert
                    :variant="dismissableAlertVariant"
                    class="mt-1"
                    dismissible
                    fade
                    :show="showDismissibleAlert"
                    @dismissed="showDismissibleAlert = false"
                  >
                    {{ dismissableAlertText }}
                </b-alert>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Login End -->
  </div>
</template>
<script>
import axios from 'axios';
import { required, email, requiredIf, helpers } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import { storeAuthTokens, isLoggedIn, setUser } from '../utils/authentication';

const LoginState = {
  LOGIN: 0,
  SEND_EMAIL_CODE: 1,
  RESET_PASSWORD: 2,
};

function isPasswordValidationRequired(value, vm) {
  return vm.currentLoginState === LoginState.RESET_PASSWORD;
}

const isPasswordGood = (password, vm) =>
  !isPasswordValidationRequired(password, vm) ||
  (password.length >= 8 &&
    /[a-z]/.test(password) &&
    /[A-Z]/.test(password) &&
    /[0-9]/.test(password) &&
    /[#?!@$%^&*-]/.test(password));

export default {
  mixins: [validationMixin],
  name: 'login',
  props: ['redirect'],
  data() {
    return {
      busy: false,
      error: '',
      form: {
        email: '',
        password: '',
        code: '',
        currentLoginState: LoginState.LOGIN,
      },
      LoginState,
	  showDismissibleAlert: false,
      dismissableAlertText: 'Dismissable Alert',
      dismissableAlertVariant: 'danger',
    };
  },
  validations: {
    form: {
      email: { required, email },
      password: {
        required: requiredIf(function () {
          return (
            this.form.currentLoginState == LoginState.LOGIN ||
            this.form.currentLoginState == LoginState.RESET_PASSWORD
          );
        }),
        isPasswordGood,
      },
      code: {
        required: requiredIf(function () {
          return this.form.currentLoginState == LoginState.RESET_PASSWORD;
        }),
      },
    },
  },
  mounted() {
    //console.log('Redirect', this.redirect);
    this.init();
    if (isLoggedIn()) this.$router.push({ path: 'my-account' });
  },
  methods: {
    onPasswdReset() {
      this.form.currentLoginState = LoginState.SEND_EMAIL_CODE;
      this.$v.$reset();
      //console.log('Send Email Code State');
    },
    async sendResetCode() {
      const sendResetCode = await this.sendResetVerificationCode();
      if (sendResetCode) {
        this.form.currentLoginState = LoginState.RESET_PASSWORD;
        this.$v.$reset();
        //console.log('Reset Password State');
      } else {
        console.warn('Problem sending reset verification email');
      }
    },
    async passwordreset() {
      const passwordReset = await this.resetPassword();
      if (passwordReset) {
		this.dismissableAlertText = 'Password Reset! Redirecting to Login Page';
        this.showDismissibleAlert = true;
        this.dismissableAlertVariant = 'success';
		var delayInMilliseconds = 2000; //1 second
		setTimeout(() => {
		 this.form.currentLoginState = LoginState.LOGIN;
         this.$v.$reset();
         console.log('Login State');
		}, delayInMilliseconds);
        
      } else {
		this.dismissableAlertText = 'Password Reset Failed!';
        this.showDismissibleAlert = true;
        this.dismissableAlertVariant = 'danger';
        console.warn('Problem sending reset verification email');
      }
    },
    init() {
      /* The footer height no longer needs to be set */
      // this.initFooterHeight();
    },
    initFooterHeight() {
      var footerHeight = $('footer').outerHeight();
      $('.main-content').css('margin-bottom', footerHeight);
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    async resetPassword() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return false;
      }
      this.busy = true;
      await axios
        .put('/api/user/password', {
          username: this.form.email,
          verificationCode: this.form.code,
          newPassword: this.form.password,
        })
        .then((response) => {
          this.error = '';
          //console.log(response);
        })
        .catch((error) => {
          this.error = error.response.data.message;
          console.error(error.response);
        })
        .finally(() => {
          this.busy = false;
        });
      return !this.error;
    },
    async sendResetVerificationCode() {
      // Verify the form input fields
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return false;
      }
      //console.log('Gettig Email Code');
      this.busy = true;
      await axios
        .post('/api/user/password/forgot/code', {
          email: this.form.email,
        })
        .then((response) => {
          this.error = '';
          //console.log(response);
        })
        .catch((error) => {
          this.error = error.response.data.message;
          console.error(error);
        })
        .finally(() => {
          this.busy = false;
          //console.log('return', !this.error);
        });
      return !this.error;
    },
    login() {
      // Verify the form input fields
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      //console.log('Loging In');
      this.busy = true;
      /** Login */
      const email = this.form.email;
      axios
        .post('/api/user/login', {
          username: email,
          password: this.form.password,
        })
        .then((response) => {
          if (response?.data?.message === 'SMS_MFA') {
            /* Check if MFA required */
            //console.log('Login Successful, but redirecting to MFA', response);
            this.$router.push({
              path: 'verify-mfa',
              query: {
                session: response.data.session,
                username: email,
                redirect: this.redirect,
              },
            });
          } else if (response?.data?.message === 'NEW_PASSWORD_REQUIRED') {
            /* Check if New Password required */
            /*console.log(
              'Login Successful, but new password required',
              response
            );*/
            this.$router.push({
              path: 'new-password',
              query: {
                session: response.data.session,
                username: email,
                redirect: this.redirect,
              },
            });
          } else {
            //console.log('Login Successful', response);
            storeAuthTokens(response.data);
            // Get User Data
            axios.get(`/api/user/`).then((response) => {
              setUser(response.data);
              /** Redirect to the link from the query params, or account info */
              if (this.redirect) {
                this.$router.push({ path: this.redirect });
              } else {
                this.$router.push({ path: '/' });
              }
            });
          }
        })
        .catch((error) => {
          if (error.response.data.code == 'UserNotConfirmedException') {
            // forward user to email verification page.
            // err.config.data.username is email
            var body = {
              "username" :JSON.parse( error.response.config.data).username
            }
             axios
              .post('/api/user/email/code/resend', body)
              .then((response) => {
                this.$router.push({
                    path: 'verify',
                    query: { email: JSON.parse( error.response.config.data).username, 
                    message: 'Please verify your email before logging in.'}
                });
              });
          }
          else {
            this.error = error.response.data.message;
            console.error(error.response);
          }
        })
        .finally(() => {
          this.busy = false;
        });
    },
  },
};
</script>
<style scoped>
.inner-header-bg {
  background-image: url('~@/assets/images/headers/login.png');
  background-position: center center;
}
.loading-gif {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  margin: auto;
}
</style>
