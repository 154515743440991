<template>
  <div class="main-content bg-light">
    <!-- BradCrumb header Start-->
    <section class="inner-header-bg">
      <div class="container">
        <!-- Breadcrumb Start-->
        <div class="section-breadcrumb">
          <div class="container">
            <div class="row">
              <div
                class="col-md-12 justify-content-center align-self-center pb-2"
              >
                <div class="breadcrumb-content">
                  <div class="title-section">
                    <h1 class="title text-center text-white">
                      Verify Phone Number
                    </h1>
                  </div>
                  <div class="breadcrumb-nav">
                    <ul class="breadcrumb-link text-center list-unstyled mb-0">
                      <li class="text-white">
                        <i class="mdi mdi-home theme-light-color pr-2"></i>
                        <a href="/#/">Home</a>
                      </li>
                      <li class="theme-light-color active">
                        Verify Phone Number
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Breadcrumb End -->
      </div>
    </section>
    <!-- BradCrumb header End -->
    <!-- Login Start-->
    <section class="login-form section bg-light">
      <!-- Overlay -->
      <b-overlay :show="busy" no-wrap>
        <template #overlay>
          <b-spinner label="Loading..."></b-spinner>
        </template>
      </b-overlay>
      <!-- MFA Verify Container -->
      <div class="container bg-white">
        <div class="login-container">
          <div class="row justify-content-center">
            <div class="col-lg-6 form-thumbnail">
              <div class="thumboverlay"></div>
            </div>
            <div class="col-lg-6">
              <form @submit.prevent class="form-body">
                <div class="text-center section-title">
                  <h2 class="text-center">
                    Please verify your phone number to enable MFA
                  </h2>
                </div>
                <div class="mt-4">
                  <div class="row">
                    <div class="col-12">
                      <div class="form-group">
                        <p>
                          An authentication code has been sent to your
                          registered phone.
                          <b>{{ phone }}</b>
                        </p>
                        <b-form-input
                          type="text"
                          :state="validateState('code')"
                          name="code"
                          placeholder="Enter verification code"
                          required="required"
                          v-model="form.code"
                        />
                        <b-form-invalid-feedback id="input-phone-feedback">
                          Verification code is required. Please check your
                          phone.
                        </b-form-invalid-feedback>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12 text-center">
                      <div class="alert alert-danger" v-if="!!error">
                        {{ error }}
                      </div>
                      <div class="alert alert-success" v-if="!!info & !error">
                        {{ info }}
                      </div>
                      <button
                        type="button"
                        @click="submit"
                        class="btn theme-btn rounded w-100"
                      >
                        Submit Code
                      </button>
                    </div>
                    <div class="col-sm-12 mt-5">
                      <div class="lost-password text-center">
                        <span
                          ><a
                            href="#"
                            @click="resendAuthenticationCode"
                            class="theme-light-color"
                          >
                            Re-send MFA Verification Code</a
                          ></span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Login End -->
  </div>
</template>
<script>
import axios from 'axios';
import { required, email } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
export default {
  name: 'verify',
  props: ['phone'],
  mixins: [validationMixin],
  data() {
    return {
      busy: false,
      error: '',
      info: '',
      form: {
        code: '',
      },
    };
  },
  validations: {
    form: {
      code: { required },
    },
  },
  mounted() {
    this.init();
    this.sendAuthenticationCode();
  },
  methods: {
    init() {
      /* The footer height no longer needs to be set */
// this.initFooterHeight();
    },
    initFooterHeight() {
      var footerHeight = $('footer').outerHeight();
      $('.main-content').css('margin-bottom', footerHeight);
    },
    resendAuthenticationCode() {
      this.info = `New MFA verification code sent to ${this.phone}`;
      this.sendAuthenticationCode();
    },
    sendAuthenticationCode() {
      this.busy = true;
      axios
        .post('/api/user/phone/code/send')
        .then((response) => {
          //console.log(response);
          this.error = '';
        })
        .catch((error) => {
          this.error = error.response.data.message;
          console.error(error.response);
        })
        .finally(() => {
          this.busy = false;
        });
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    submit() {
      // Verify the form input fields
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      this.busy = true;
      this.info = '';
      this.error = '';
      axios
        .post('/api/user/phone/code/verify', {
          code: this.form.code,
        })
        .then((response) => {
          //console.log(response);
          this.enableMFA();
        })
        .catch((error) => {
          this.error = error.response.data.message;
          console.error(error.response);
        })
        .finally(() => {
          this.busy = false;
        });
    },
    enableMFA() {
      axios
        .post('/api/user/phone/mfa/enable', {
          enabled: true,
        })
        .then((response) => {
          //console.log(response);
          this.$router.push({ path: 'my-account' });
        })
        .catch((error) => {
          this.error = error.response.data.message;
          console.error(error.response);
        });
    },
  },
};
</script>
