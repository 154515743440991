<template>
  <div class="main-content">
    <!-- BradCrumb header Start-->
    <section class="inner-header-bg">
      <div class="container">
        <!-- Breadcrumb Start-->
        <div class="section-breadcrumb contact-breadcrumb">
          <div class="container">
            <div class="row">
              <div class="col-md-12 justify-content-center align-self-center pb-2">
                <div class="breadcrumb-content">
                  <div class="title-section">
                    <h1 class="title text-center text-white">Contact Us</h1>
                  </div>
                  <div class="breadcrumb-nav">
                    <ul class="breadcrumb-link text-center list-unstyled mb-0">
                      <li class="text-white">
                        <i class="mdi mdi-home theme-light-color pr-2"></i>
                        <a href="/#/">Home</a>
                      </li>
                      <li class="theme-light-color active">Contact</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Breadcrumb End -->
      </div>
    </section>
    <!-- BradCrumb header End -->
    <!-- Start Contact Area -->
    <section class="section contact-area">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-7 col-12">
            <div class="contact-main form-box">
              <div class="contact-inner z-index9">
                <div class="section-title">
                  <h2 class="text-white">Let's Connect</h2>
                </div>
                <div class="contact-address mt-4">
                  <div class="address mt-2">
                    <p class="text-white">
                      The APEX Accelerators program, under management of the Department of Defense (DoD) Office of Small
                      Business Programs (OSBP), plays a critical role in the Department’s efforts to identify and engage
                      with a wide range of businesses entering and participating in the defense supply-chain. The program
                      provides the education and training that all businesses need to participate to become capable of
                      participating in DoD and other government contracts. </p>
                    <!-- <h2 class="text-white mt-5 section-header">To find your local APEX Accelerator, please click <a href="/" class="section-header-link">here</a>.</h2>
                      -->
                  </div>
                  <div class="contact-email mt-5">
                    <h2 class="find-text"><span>To find your local APEX Accelerator, <br />please click</span> <a
                        href="/#/service-area-locator">here</a>.</h2>
                  </div>
                </div>
                <!-- <div class="social-media-transparent mt-5">
                  <ul
                    class="
                      social-links
                      list-unstyled
                      d-flex
                      justify-content-start
                    "
                  >
                    <li class="linkedin">
                      <a
                        href="https://www.linkedin.com/showcase/project-spectrum/"
                        title="Linkedin"
                        target="_blank"
                      >
                        <i class="mdi mdi-linkedin font-20"></i>
                      </a>
                    </li> -->
                <!-- <li class="twitter">
                      <a
                        href="https://twitter.com/ProjectSpectru5"
                        title="Twitter"
                        target="_blank"
                      >
                        <i class="mdi mdi-twitter font-20"></i>
                      </a>
                    </li> -->
                <!-- <li class="youtube">
                      <a
                        href="https://www.youtube.com/channel/UCHU-4dUkYInJhpabvW6Ps1g/videos"
                        title="Youtube"
                        target="_blank"
                      >
                        <i class="mdi mdi-youtube font-20"></i>
                      </a>
                    </li> 
                  </ul> 
                </div> -->
              </div>
            </div>
          </div>
          <div class="col-lg-5 col-12">
            <div class="mt-3">
              <b-alert :variant="dismissableAlertVariant" class="mt-1" dismissible fade :show="showDismissibleAlert"
                @dismissed="showDismissibleAlert = false">
                {{ dismissableAlertText }}
              </b-alert>
              <h2 class="pb-1">Send us a message</h2>
              <div>
                  <p class="p-2">If you are looking for your local APEX Accelerator, please
                    reference the Map on the <a href="/">home page</a> or use this
                    <a href="/#/service-area-locator">state and county-based text look up tool.</a>
                    This form is not for requesting location information.
                  </p>
                </div>
              <div class="row formRow">
                <div class="col-12">
                  <b-form-group>
                    <div>
                      <b-form-input type="text" name="name" v-model="$v.form.name.$model" :state="validateState('name')"
                        aria-describedby="input-name-feedback" placeholder="Name *">
                      </b-form-input>
                    </div>
                    <b-form-invalid-feedback id="input-name-feedback">Please enter a name.</b-form-invalid-feedback>
                  </b-form-group>
                </div>
                <div class="col-12">
                  <b-form-group>
                    <b-form-input type="email" class="form-control" name="email" v-model="form.email"
                      :state="validateState('email')" placeholder="Email *" aria-describedby="input-email-feedback"
                      required="required">
                    </b-form-input>
                    <b-form-invalid-feedback id="input-email-feedback">Please enter a valid email
                      address.</b-form-invalid-feedback>
                  </b-form-group>
                </div>
              </div>
              <div class="row formRow">
                <div class="col-12">
                  <b-form-group>
                    <b-form-input type="text" name="phone" v-model="form.phone" :state="validateState('phone')" value=""
                      aria-describedby="input-phone-feedback" placeholder="Phone *">
                    </b-form-input>
                    <b-form-invalid-feedback id="input-phone-feedback">Please enter a phone
                      number.</b-form-invalid-feedback>
                  </b-form-group>
                </div>
                <div class="col-12">
                  <b-form-group>
                    <b-form-input type="text" name="organization" v-model="form.organization"
                      :state="validateState('organization')" value="" size="40"
                      aria-describedby="input-organization-feedback" placeholder="Organization *">
                    </b-form-input>
                    <b-form-invalid-feedback id="input-organization-feedback">Please enter an
                      organization.</b-form-invalid-feedback>
                  </b-form-group>
                </div>
                <div class="col-12">
                  <b-form-group>
                    <b-form-input type="text" name="city" v-model="form.city" :state="validateState('city')" value=""
                      size="40" aria-describedby="input-city-feedback" placeholder="City *">
                    </b-form-input>
                    <b-form-invalid-feedback id="input-city-feedback">Please enter a city.</b-form-invalid-feedback>
                  </b-form-group>
                </div>
                <div class="col-12">
                  <b-form-group>
                    <b-form-input type="text" name="website" v-model="form.website" :state="validateState('website')" value=""
                      size="40" aria-describedby="input-website-feedback" placeholder="Website *">
                    </b-form-input>
                    <b-form-invalid-feedback id="input-website-feedback">Please enter a website.</b-form-invalid-feedback>
                  </b-form-group>
                </div>
                <div class="col-12">
                  <b-form-group>
                    <b-form-select id="state" v-model="form.state" :options="stateFipsOption" name="state"
                      class="form-control" aria-describedby="input-state-feedback" placeholder="State*"
                      required="required" :state="validateState('state')"  @change="onStateChange">
                      >
                      <b-form-select-option :value="null">State *</b-form-select-option>
                      <b-form-invalid-feedback id="input-state-feedback">Please select a state from the dropdown</b-form-invalid-feedback>
                    </b-form-select>
                  </b-form-group>
                </div>
                <div class="col-12">
                  <div v-if="form.state">
                    <b-form-group label-for="county">
                      <b-form-select id="county" v-model="form.county" :options="countiesOption" :state="validateState('county')"
                        name="county" class="form-control" aria-describedby="input-county-feedback" placeholder="County*"
                        required="required" @change="onCountyChange">
                        >
                        <b-form-select-option :value="null">County *</b-form-select-option>
                        <b-form-invalid-feedback id="input-county-feedback">Please select a county from the dropdown</b-form-invalid-feedback>
                   
                      </b-form-select>
                    </b-form-group>
                  </div>
                  <div v-if="loadingCounties" class="spinner"></div>
                </div>
              </div>
              <div class="row ">
                <div class="col-12">
                  <b-form-group>
                    <b-form-textarea name="message" v-model="form.message" :state="validateState('message')" rows="7"
                      placeholder="How can we help you? *">
                    </b-form-textarea>
                    <b-form-invalid-feedback id="input-organization-feedback">Please enter a
                      message.</b-form-invalid-feedback>
                  </b-form-group>
                </div>
              </div>
              <p>(* Required)</p>
              <div class="row">
                <div class="col-sm-12">
                  <!-- <vue-recaptcha
                    class="pb-1"
                    :sitekey="sitekey"
                    :loadRecaptchaScript="true"
                    @verify="verifyMethod"
                    @expired="expiredMethod"
                    @render="renderMethod"
                    @error="errorMethod"
                  >
                  </vue-recaptcha>
                  <b-form-invalid-feedback :state="captchaValidated">
                    Please complete the captcha by selecting the checkbox.
                  </b-form-invalid-feedback> -->
                  <button type="submit" @click="submit" class="btn theme-btn w-100 rounded mt-1">
                    Send Message
                  </button>
                  <div class="mt-3" v-if="show == true">
                    <span class="mdi mdi-check-circle text-success">Your message has been sent and a copy forwarded to the email address used in your submission. Please
reference that message for the contact information of your local APEX Accelerator.</span>
                  </div>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Contact Area -->
  </div>
</template>
<script>
import VueRecaptcha from 'vue-recaptcha';
import axios from 'axios';
import { validationMixin } from 'vuelidate';
import { required, email } from 'vuelidate/lib/validators';
import emailjs from 'emailjs-com'

export default {
  mixins: [validationMixin],
  name: 'contact',
  data() {
    return {
      form: {
        name: null,
        email: null,
        phone: null,
        organization: null,
        city: null,
        county: null,
        state: null,
        website: null,
        message: null,
      },
      show: false,
      sitekey: '6LfwD60UAAAAAFbz5jRRMiCXI5cWFI0TP0MN8gXX',
      recaptcha: null,
      recaptchaVerified: false,
      captchaValidated: null,
      showDismissibleAlert: false,
      dismissableAlertText: 'Dismissable Alert',
      dismissableAlertVariant: 'danger',
      loadingCities: false,
      loadingCounties: false,
      countiesOption: [],
      stateFipsOption: [
        { value: "01", text: "AL - Alabama" },
        { value: "02", text: "AK - Alaska" },
        { value: "04", text: "AZ - Arizona" },
        { value: "05", text: "AR - Arkansas" },
        { value: "06", text: "CA - California" },
        { value: "08", text: "CO - Colorado" },
        { value: "09", text: "CT - Connecticut" },
        { value: "10", text: "DE - Delaware" },
        { value: "11", text: "DC - District of Columbia" },
        { value: "12", text: "FL - Florida" },
        { value: "13", text: "GA - Georgia" },
        { value: "66", text: "GU - Guam" },
        { value: "15", text: "HI - Hawaii" },
        { value: "16", text: "ID - Idaho" },
        { value: "17", text: "IL - Illinois" },
        { value: "18", text: "IN - Indiana" },
        { value: "19", text: "IA - Iowa" },
        { value: "20", text: "KS - Kansas" },
        { value: "21", text: "KY - Kentucky" },
        { value: "22", text: "LA - Louisiana" },
        { value: "23", text: "ME - Maine" },
        { value: "24", text: "MD - Maryland" },
        { value: "25", text: "MA - Massachusetts" },
        { value: "26", text: "MI - Michigan" },
        { value: "27", text: "MN - Minnesota" },
        { value: "28", text: "MS - Mississippi" },
        { value: "29", text: "MO - Missouri" },
        { value: "30", text: "MT - Montana" },
        { value: "31", text: "NE - Nebraska" },
        { value: "32", text: "NV - Nevada" },
        { value: "33", text: "NH - New Hampshire" },
        { value: "34", text: "NJ - New Jersey" },
        { value: "35", text: "NM - New Mexico" },
        { value: "36", text: "NY - New York" },
        { value: "37", text: "NC - North Carolina" },
        { value: "38", text: "ND - North Dakota" },
        { value: "39", text: "OH - Ohio" },
        { value: "40", text: "OK - Oklahoma" },
        { value: "41", text: "OR - Oregon" },
        { value: "42", text: "PA - Pennsylvania" },
        { value: "72", text: "PR - Puerto Rico" },
        { value: "44", text: "RI - Rhode Island" },
        { value: "45", text: "SC - South Carolina" },
        { value: "46", text: "SD - South Dakota" },
        { value: "47", text: "TN - Tennessee" },
        { value: "48", text: "TX - Texas" },
        { value: "49", text: "UT - Utah" },
        { value: "50", text: "VT - Vermont" },
        { value: "51", text: "VA - Virginia" },
        { value: "78", text: "VI - Virgin Islands" },
        { value: "53", text: "WA - Washington" },
        { value: "54", text: "WV - West Virginia" },
        { value: "55", text: "WI - Wisconsin" },
        { value: "56", text: "WY - Wyoming" },
      ],
      countyAccelerator: null,
      result: null
    };
  },
  validations: {
    form: {
      name: { required },
      email: { required, email },
      phone: { required },
      organization: { required },
      message: { required },
      city: { required },
      county: { required },
      website: { required },
      state: { required }
    },
  },
  components: { VueRecaptcha },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      /* The footer height no longer needs to be set */
      // this.initFooterHeight();
    },
    initFooterHeight() {
      var footerHeight = $('footer').outerHeight();
      $('.main-content').css('margin-bottom', footerHeight);
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    async submit() {
      // Verify the form input fields
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        console.log("complete the form")
        return;
      }

      let selectedState = this.stateFipsOption.find(
        (state) => state.value === this.form.state
      );

      let selectedCounty = this.countiesOption.find(
        (county) => county.value === this.form.county
      );

      let payload = {
        name: this.form.name,
        phone: this.form.phone,
        email: this.form.email,
        message: this.form.message,
        website: this.form.website,
        company: this.form.organization,
        city: this.form.city,
        county: selectedCounty.text,
        state: selectedState.text,
        myApexAccelerator: {
          state: this.countyAccelerator[0]?.["State"],
          name: this.countyAccelerator[0]?.["Name"],
          addressLine1: this.countyAccelerator[0]?.["Address Line 1"],
          addressLine2: this.countyAccelerator[0]?.["Address Line 2"],
          city: this.countyAccelerator[0]?.["City"],
          postalCode: this.countyAccelerator[0]?.["Postal Code"],
          phoneNumber: this.countyAccelerator[0]?.["Phone Number"],
          email: this.countyAccelerator[0]?.["email for references"],
          website: this.countyAccelerator[0]?.["website"],
          organizationServiceArea: this.countyAccelerator[0]?.["Organization Service Area"],
          extension: this.countyAccelerator[0]?.["Extension"],
          territory: this.countyAccelerator[0]?.["Territory"],
        }
      }

      try {
        await axios.post('/api/feedback/contact', payload);
        this.show = true;
      } catch (err) {
        console.log({ error })
      }

    },
    resetForm() {
      this.form = {
        name: null,
        email: null,
        phone: null,
        organization: null,
        message: null,
      };
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    verifyMethod(responce) {
      //console.log('ReCaptcha Verified', responce);
      this.recaptchaVerified = true;
      this.recaptcha = responce;
      this.captchaValidated = true;
    },
    expiredMethod() {
      //console.warn('ReCaptcha Expired');
      this.recaptchaVerified = false;
      this.recaptcha = false;
      this.captchaValidated = false;
    },
    renderMethod() {
      console.log('ReCaptcha Rendered');
    },
    errorMethod() {
      console.error('ReCaptcha Error');
      this.recaptchaVerified = false;
      this.recaptcha = false;
    },
    async getCounties() {
      this.loadingCounties = true;
      try {
        const response = await axios.get(
          `/api/resources/counties/${this.form.state}`
        );
        if (response.data.length == 0 && Array.isArray(response.data)) {
          this.countiesOption = [{
            value: 'Statewide',
            text: 'Statewide'
          }];
        }
        else if (response.data && Array.isArray(response.data)) {
          this.countiesOption = response.data.map((item) => ({
            value: item.value, // The county FIPS code
            text: item.text, // The county name
          }));
        } else {
          this.countiesOption = [];
        }
      } catch (error) {
        console.error("Error fetching counties:", error);
      } finally {
        this.loadingCounties = false
      }
    },
    async onCountyChange() {

      if (!this.form.county) {
        return;
      }

      const selectedState = this.stateFipsOption.find(
        (state) => state.value === this.form.state
      );
      let selectedCounty = this.countiesOption.find(
        (county) => county.value === this.form.county
      );
      selectedCounty = selectedCounty.text.split(",")[0];
      const rawData = (
        await axios.post("/api/resources/getApexSC", {
          state: selectedState.text.slice(0, 2),
          county: selectedCounty,
        })
      ).data;

      this.countyAccelerator = rawData;

    },
    async onStateChange() {

      if (!this.form.state) {
        return;
      }

      try {
        await this.getCounties();
      } catch (err) {
        console.log(err);
      }

    },
    submitForm() {
      // Add form validation logic here
      if (this.validateForm()) {
        this.onRangeChange();
      } else {
        alert("Please fill in all fields.");
      }
    },
    validateForm() {
      return this.validateState("state") && this.validateState("county");
    }
  },

};
</script>
<style scoped>
.form-group {
  margin-bottom: 0px;
}

.formRow .col-12 {
  padding-top: 0px !important;
  padding-bottom: 5px !important;
}

.link-white {
  color: #f2b636;
}

.link-white:hover {
  text-decoration: underline !important;
}

.section-header {
  font-size: 20px !important;
}

.section-header-link {
  color: #f2b636;
  text-decoration: underline !important;
}

.find-text {
  font-size: 28px !important;
  white-space: nowrap;
}

.find-text span:first-of-type {
  color: #f2b636;
}

.find-text a {
  text-decoration: underline !important;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #09f;
  animation: spinner 1s linear infinite;
}

.error-message {
  font-weight: 600;
  color: #ff6767;
  margin: 0;
  margin-top: 5px;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
</style>
