<template>
  <div class="main-content">
    <!-- BradCrumb header Start-->
    <section class="inner-header-bg-techHub">
      <div class="container">
        <!-- Breadcrumb Start-->
        <div class="section-breadcrumb">
          <div class="container">
            <div class="row">
              <div
                class="col-md-12 justify-content-center align-self-center pb-2"
              >
                <div class="breadcrumb-content">
                  <div class="title-section">
                    <h1 class="title text-center text-white">Blogs</h1>
                  </div>
                  <div class="breadcrumb-nav">
                    <ul class="breadcrumb-link text-center list-unstyled mb-0">
                      <li class="text-white">
                        <i class="mdi mdi-home theme-light-color pr-2"></i>
                        <a href="/#/">Home</a>
                      </li>
                      <li class="theme-light-color active">Blogs</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Breadcrumb End -->
      </div>
    </section>
    <!-- BradCrumb header End -->
    <!-- Blog Grid Section Start -->
    <section class="section">
      <div class="container">
        <p>The Project Spectrum Cyber Advisory Team is one of the best – and the
          group regularly writes about key issues that affect every user in the
          cyber realm. Get key insight from experts on the latest developments
          effecting your work in cyberspace. Check this space regularly – we
          update it often!
          
        </p>
        <div class="pb-4">
          <b-form-input
            v-model="searchQuery"
            placeholder="Search Blog Posts..."
          ></b-form-input>
        </div>
        <p class="pb-2" v-if="displayedBlogItems.length === 0 && searchQuery">
          No items match the search criteria...
        </p>
        <p class="pb-2" v-if="displayedBlogItems.length === 0 && !searchQuery">
          Check back soon for blog posts...
        </p>
        <div class="blog-post-grid">
          <div class="row">
            <!-- Start Blog Item -->
            <div
              class="col-md-6 col-lg-4 mb-5"
              v-for="item in displayedBlogItems"
              :key="item.id"
            >
              <div class="blog-item border h-100">
                <router-link
                  class="blog-post-image"
                  :to="{ name: 'blogdetail', query: { id: item.id } }"
                >
                  <img
                    :src="item.mainImage"
                    :alt="item.photoDescription"
                    class="cover"
                  />
                </router-link>
                <div class="blog-post-info">
                  <router-link
                    class="text-dark"
                    :to="{ name: 'blogdetail', query: { id: item.id } }"
                  >
                    <h4 class="blog-post-title">
                      {{ item.title }}
                    </h4>
                    <p class="limit-lines-3">{{ item.headLine }}</p>
                    <div class="post-event-bottom d-flex align-items-center">
                      <a class="blog-info-meta text-dark">{{
                        getFormattedDate(item.date)
                      }}</a>
                      <a class="blog-category text-dark">Blog</a>
                    </div>
                  </router-link>
                  <router-link
                    class="blog-read-more"
                    :to="{ name: 'blogdetail', query: { id: item.id } }"
                  >
                    <i class="mdi mdi-arrow-right"></i>
                  </router-link>
                </div>
              </div>
            </div>
            <!-- End Blog Item -->
          </div>
        </div>
        <div>
          <b-pagination
            id="blog-pagination"
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="center"
            @page-click="pageClicked"
          ></b-pagination>
        </div>
      </div>
    </section>
    <!-- Blog Grid Section End -->
  </div>
</template>
<script>
import axios from 'axios';
import { BPagination, BFormInput } from 'bootstrap-vue';
export default {
  name: 'blog',
  components: {
    BPagination,
    BFormInput,
  },
  props: {
    query: String,
  },
  data() {
    return {
      searchQuery: this.query,
      blogItems: [],
      blogItemCount: 0,
      currentPage: 1,
      perPage: 9,
    };
  },
  computed: {
    blogItemsFromSearchQuery() {
      if (this.searchQuery) {
        return this.blogItems.filter((item) => {
          return this.searchQuery
            .toLowerCase()
            .split(' ')
            .every(
              (v) =>
                item.title.toLowerCase().includes(v) ||
                item.headLine.toLowerCase().includes(v)
            );
        });
      } else {
        return this.blogItems;
      }
    },
    displayedBlogItems() {
      return this.blogItemsFromSearchQuery.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
      );
    },
    totalRows() {
      return this.blogItemsFromSearchQuery.length;
    },
  },
  mounted: async function () {
    this.init();
    this.getBlogPosts(); // async
  },
  methods: {
    init() {
      /* The footer height no longer needs to be set */
      // this.initFooterHeight();
    },
    initFooterHeight() {
      var footerHeight = $('footer').outerHeight();
      $('.main-content').css('margin-bottom', footerHeight);
    },
    async getBlogPosts() {
      /*  API call to server: GET /api/resources/blogs  */
      axios
        .get('/api/resources/blogs')
        .then((response) => {
          this.blogItems = response.data.Items;
          this.blogItemCount = response.data.Count;
          //console.log(this.blogItems);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    pageClicked() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
  },
};
</script>
<style scoped>
#blog-pagination li {
  margin: 0px;
}

.cover {
  object-fit: cover;
  height: 225px;
  width: 100%;
}
</style>
