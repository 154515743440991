var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm._m(0);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "main-content"
  }, [_c('section', {
    staticClass: "inner-header-bg-partner"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "section-breadcrumb"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12 justify-content-center align-self-center pb-2"
  }, [_c('div', {
    staticClass: "breadcrumb-content"
  }, [_c('div', {
    staticClass: "title-section"
  }, [_c('h1', {
    staticClass: "title text-center text-white"
  }, [_vm._v(" Partners ")])]), _c('div', {
    staticClass: "breadcrumb-nav"
  }, [_c('ul', {
    staticClass: "breadcrumb-link text-center list-unstyled mb-0"
  }, [_c('li', {
    staticClass: "text-white"
  }, [_c('i', {
    staticClass: "mdi mdi-home theme-light-color pr-2"
  }), _c('a', {
    attrs: {
      "href": "/#/"
    }
  }, [_vm._v("Home")])]), _c('li', {
    staticClass: "theme-light-color active"
  }, [_vm._v(" Partners ")])])])])])])])])])]), _c('section', {
    staticClass: "section"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row justify-content-center"
  }, [_c('div', {
    staticClass: "col-12 py-4"
  }), _c('div', {
    staticClass: "col-12 py-4"
  }, [_c('div', {
    staticClass: "section-title"
  }, [_c('h2', [_vm._v("MEP")])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-8"
  }, [_c('blockquote', {
    staticClass: "blockquote"
  }, [_c('p', [_vm._v(" The Manufacturing Extension Partnership (MEP), based at the National Institute of Standards and Technology (NIST), is a public-private partnership with Centers in all 50 states and Puerto Rico dedicated to serving small and medium-sized manufacturers. MEP Centers and partners have developed various services to enable manufacturers to identify opportunities that will accelerate their growth and improve competitiveness in the global marketplace. The program provides the federal government funding for the MEP National Network™ which was designed to advance U.S. manufacturing. ")])]), _c('p', {
    staticStyle: {
      "padding-top": "0px"
    }
  }, [_vm._v("► "), _c('a', {
    attrs: {
      "href": "https://nist.gov/mep",
      "target": "_blank"
    }
  }, [_c('strong', [_vm._v(" LEARN MORE ")])])])]), _c('div', {
    staticClass: "col-4"
  }, [_c('img', {
    staticClass: "img-fluid w-200",
    attrs: {
      "src": "assets/images/MEP.png",
      "alt": ""
    }
  })])])]), _c('hr', {
    staticClass: "col-sm-12"
  }), _c('div', {
    staticClass: "col-12 py-4"
  }, [_c('div', {
    staticClass: "section-title"
  }, [_c('h2', [_vm._v("Mentor-Protégé Program ")])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-8"
  }, [_c('blockquote', {
    staticClass: "blockquote"
  }, [_c('p', [_vm._v(" The Mentor-Protégé Program helps eligible small businesses (protégés) gain capacity and win government contracts through partnerships with more experienced companies (mentors). DoD’s MPP is the oldest continuously operating federal MPP in existence, which has assisted approximately 200 small businesses fill unique niches and become part of the military’s supply chain in the past five years. The program helps protégés expand their footprint in the defense industrial base. ")])]), _c('p', {
    staticStyle: {
      "padding-top": "0px"
    }
  }, [_vm._v("► "), _c('a', {
    attrs: {
      "href": "https://business.defense.gov/Programs/Mentor-Protege-Program/",
      "target": "_blank"
    }
  }, [_c('strong', [_vm._v(" LEARN MORE ")])])])]), _c('div', {
    staticClass: "col-4"
  }, [_c('img', {
    staticClass: "img-fluid w-100",
    attrs: {
      "src": "assets/images/MPP.png",
      "alt": ""
    }
  })])])]), _c('hr', {
    staticClass: "col-sm-12"
  }), _c('div', {
    staticClass: "col-12 py-4"
  }, [_c('div', {
    staticClass: "section-title"
  }, [_c('h2', [_vm._v("Project Spectrum ")])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-8"
  }, [_c('blockquote', {
    staticClass: "blockquote"
  }, [_c('p', [_vm._v(" Project Spectrum is a comprehensive, proven, and cost-effective digital platform that provides cybersecurity information, tools, resources, and training to organizations including small businesses in the defense industrial base. The program mission is to improve cybersecurity readiness, resiliency, and compliance for small and medium-sized businesses and the federal manufacturing supply chain. ")])]), _c('p', {
    staticStyle: {
      "padding-top": "0px"
    }
  }, [_vm._v("► "), _c('a', {
    attrs: {
      "href": "https://www.projectspectrum.io/#/",
      "target": "_blank"
    }
  }, [_c('strong', [_vm._v(" LEARN MORE ")])])])]), _c('div', {
    staticClass: "col-4"
  }, [_c('img', {
    staticClass: "img-fluid w-50",
    attrs: {
      "src": "assets/images/logo/logo-dark-small.png",
      "alt": ""
    }
  })])])]), _c('hr', {
    staticClass: "col-sm-12"
  }), _c('div', {
    staticClass: "col-12 py-4"
  }, [_c('div', {
    staticClass: "section-title"
  }, [_c('h2', [_vm._v("SBA")])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-8"
  }, [_c('blockquote', {
    staticClass: "blockquote"
  }, [_c('p', [_vm._v(" The U.S. Small Business Administration (SBA) is a cabinet-level federal agency that helps Americans start, grow, and build resilient businesses. SBA is dedicated to small business and provides counseling, capital, and contracting expertise. Since it was founded in 1953, SBA has delivered millions of loans, contracts, counseling sessions, and other forms of assistance to innumerable small businesses as the nation’s go-to resource and voice for small businesses. ")])]), _c('p', {
    staticStyle: {
      "padding-top": "0px"
    }
  }, [_vm._v("► "), _c('a', {
    attrs: {
      "href": "https://www.sba.gov",
      "target": "_blank"
    }
  }, [_c('strong', [_vm._v(" LEARN MORE ")])])])]), _c('div', {
    staticClass: "col-4"
  }, [_c('img', {
    staticClass: "img-fluid w-100",
    attrs: {
      "src": "assets/images/SBA.png",
      "alt": ""
    }
  })])])]), _c('hr', {
    staticClass: "col-sm-12"
  })])])])]);

}]

export { render, staticRenderFns }