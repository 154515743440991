var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('nav', {
    staticClass: "navbar navbar-expand-lg fixed-top custom-nav sticky"
  }, [_c('div', {
    staticClass: "container py-1"
  }, [_c('router-link', {
    staticClass: "navbar-brand brand-logo mr-4",
    attrs: {
      "to": "/"
    }
  }, [_c('img', {
    staticClass: "img-fluid logo-small-screen",
    attrs: {
      "src": require("../assets/images/logo/apex-logo-light.png"),
      "alt": ""
    }
  }), _c('img', {
    staticClass: "img-fluid logo-light",
    attrs: {
      "src": require("../assets/images/logo/apex_logo_light_1.png"),
      "alt": ""
    }
  }), _c('img', {
    staticClass: "img-fluid logo-dark",
    attrs: {
      "src": require("../assets/images/logo/apex-logo-light.png"),
      "alt": ""
    }
  })]), _c('b-sidebar', {
    attrs: {
      "id": "sidebar-backdrop",
      "sidebar-class": "sidebar-background",
      "bg-variant": "dark",
      "text-variant": "light",
      "backdrop": "",
      "shadow": ""
    }
  }, [_c('div', {
    staticClass: "px-3 py-2"
  }, [_c('div', [_c('ul', {
    staticClass: "navbar-nav navbar-center",
    attrs: {
      "id": "mySidenav"
    }
  }, [_c('li', {
    staticClass: "nav-item"
  }, [_c('router-link', {
    staticClass: "nav-link",
    attrs: {
      "to": "/"
    }
  }, [_vm._v("Home")])], 1), _c('li', {
    staticClass: "nav-item dropdown"
  }, [_c('a', {
    staticClass: "nav-link dropdown-toggle",
    attrs: {
      "data-toggle": "dropdown"
    }
  }, [_vm._v("About Us")]), _c('ul', {
    staticClass: "nav-bar submenu dropdown-menu list-unstyled p-0"
  }, [_c('li', {
    staticClass: "nav-item"
  }, [_c('router-link', {
    staticClass: "nav-link",
    attrs: {
      "to": "/leadership"
    }
  }, [_vm._v("Leadership ")])], 1), _c('li', {
    staticClass: "nav-item"
  }, [_c('router-link', {
    staticClass: "nav-link",
    attrs: {
      "to": "/about-us"
    }
  }, [_vm._v("What We Do ")])], 1), _c('li', {
    staticClass: "nav-item"
  }, [_c('router-link', {
    staticClass: "nav-link",
    attrs: {
      "to": "/partners"
    }
  }, [_vm._v("Partners ")])], 1)])]), _c('li', {
    staticClass: "nav-item"
  }, [_c('router-link', {
    staticClass: "nav-link",
    attrs: {
      "to": "/feedback"
    }
  }, [_vm._v("Feedback ")])], 1), _c('li', {
    staticClass: "nav-item dropdown"
  }, [_c('a', {
    staticClass: "nav-link dropdown-toggle",
    attrs: {
      "data-toggle": "dropdown"
    }
  }, [_vm._v("Contact Us")]), _c('ul', {
    staticClass: "nav-bar submenu dropdown-menu list-unstyled p-0"
  }, [_c('li', {
    staticClass: "nav-item"
  }, [_c('router-link', {
    staticClass: "nav-link",
    attrs: {
      "to": "/contact"
    }
  }, [_vm._v("Let’s Connect")])], 1), _c('li', {
    staticClass: "nav-item"
  }, [_c('router-link', {
    staticClass: "nav-link",
    attrs: {
      "to": "/speaker-request"
    }
  }, [_vm._v("Speaker Request")])], 1)])])])])])]), _c('div', {
    staticClass: "navbar-collapse collapse justify-content-end",
    attrs: {
      "id": "navbarCollapse"
    }
  }, [_c('ul', {
    staticClass: "navbar-nav navbar-center",
    attrs: {
      "id": "mySidenav"
    }
  }, [_c('li', {
    staticClass: "nav-item"
  }, [_c('router-link', {
    staticClass: "nav-link",
    attrs: {
      "to": "/"
    }
  }, [_vm._v("Home")])], 1), _c('li', {
    staticClass: "nav-item dropdown"
  }, [_c('a', {
    staticClass: "nav-link dropdown-toggle",
    attrs: {
      "data-toggle": "dropdown"
    }
  }, [_vm._v("About Us")]), _c('ul', {
    staticClass: "nav-bar submenu dropdown-menu list-unstyled p-0"
  }, [_c('li', {
    staticClass: "nav-item"
  }, [_c('router-link', {
    staticClass: "nav-link",
    attrs: {
      "to": "/leadership"
    }
  }, [_vm._v("Leadership ")])], 1), _c('li', {
    staticClass: "nav-item"
  }, [_c('router-link', {
    staticClass: "nav-link",
    attrs: {
      "to": "/about-us"
    }
  }, [_vm._v("What We Do ")])], 1), _c('li', {
    staticClass: "nav-item"
  }, [_c('router-link', {
    staticClass: "nav-link",
    attrs: {
      "to": "/service-area-locator"
    }
  }, [_vm._v("Service Area Locator ")])], 1), _c('li', {
    staticClass: "nav-item"
  }, [_c('router-link', {
    staticClass: "nav-link",
    attrs: {
      "to": "/partners"
    }
  }, [_vm._v("Partners ")])], 1)])]), _c('li', {
    staticClass: "nav-item"
  }, [_c('router-link', {
    staticClass: "nav-link",
    attrs: {
      "to": "/feedback"
    }
  }, [_vm._v("Feedback")])], 1), _c('li', {
    staticClass: "nav-item dropdown"
  }, [_c('a', {
    staticClass: "nav-link dropdown-toggle",
    attrs: {
      "data-toggle": "dropdown"
    }
  }, [_vm._v("Contact Us")]), _c('ul', {
    staticClass: "nav-bar submenu dropdown-menu list-unstyled p-0"
  }, [_c('li', {
    staticClass: "nav-item"
  }, [_c('router-link', {
    staticClass: "nav-link",
    attrs: {
      "to": "/contact"
    }
  }, [_vm._v("Let’s Connect")])], 1), _c('li', {
    staticClass: "nav-item"
  }, [_c('router-link', {
    staticClass: "nav-link",
    attrs: {
      "to": "/speaker-request"
    }
  }, [_vm._v("Speaker Request")])], 1)])])]), _vm._m(0)]), _c('div', {
    staticClass: "header_btn"
  }, [_c('button', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle.sidebar-backdrop",
      modifiers: {
        "sidebar-backdrop": true
      }
    }],
    staticClass: "navbar-toggler ml-2 p-0",
    attrs: {
      "type": "button"
    }
  }, [_c('i', {
    staticClass: "mdi mdi-menu"
  })])])], 1)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "menu-close-btn"
  }, [_c('i', {
    staticClass: "mdi mdi-close-circle-outline"
  })]);

}]

export { render, staticRenderFns }