var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "share-network-container"
  }, [_c('ShareNetwork', {
    attrs: {
      "network": "linkedin",
      "url": _vm.url,
      "title": _vm.title
    }
  }, [_c('font-awesome-icon', {
    staticClass: "pl-1",
    attrs: {
      "size": "2x",
      "icon": ['fab', 'linkedin']
    }
  })], 1), _c('ShareNetwork', {
    attrs: {
      "network": "twitter",
      "url": _vm.url,
      "title": _vm.title,
      "description": _vm.description
    }
  }, [_c('font-awesome-icon', {
    staticClass: "pl-1",
    attrs: {
      "size": "2x",
      "icon": ['fab', 'twitter-square']
    }
  })], 1), _c('ShareNetwork', {
    attrs: {
      "network": "facebook",
      "url": _vm.url,
      "title": _vm.title,
      "description": _vm.description
    }
  }, [_c('font-awesome-icon', {
    staticClass: "pl-1",
    attrs: {
      "size": "2x",
      "icon": ['fab', 'facebook-square']
    }
  })], 1), _c('ShareNetwork', {
    attrs: {
      "network": "reddit",
      "url": _vm.url,
      "title": _vm.title,
      "description": _vm.description
    }
  }, [_c('font-awesome-icon', {
    staticClass: "pl-1",
    attrs: {
      "size": "2x",
      "icon": ['fab', 'reddit-square']
    }
  })], 1), _c('div', {
    staticClass: "pl-1"
  }, [_c('font-awesome-icon', {
    attrs: {
      "id": "tooltip-copy",
      "size": "1x",
      "icon": "link"
    },
    on: {
      "click": _vm.onCopyLink
    }
  }), _c('b-tooltip', {
    attrs: {
      "variant": "primary",
      "target": "tooltip-copy"
    }
  }, [_vm._v(_vm._s(_vm.linkCopyText))])], 1), !_vm.isPageBookmarked ? _c('div', {
    staticClass: "pl-1"
  }, [_c('font-awesome-icon', {
    attrs: {
      "id": "tooltip-bookmark",
      "icon": ['far', 'bookmark']
    },
    on: {
      "click": _vm.onBookmarkPage
    }
  }), _c('b-tooltip', {
    attrs: {
      "variant": "primary",
      "target": "tooltip-bookmark"
    }
  }, [_vm._v("Click to bookmark page")])], 1) : _c('div', {
    staticClass: "pl-1"
  }, [_c('font-awesome-icon', {
    attrs: {
      "id": "tooltip-bookmark",
      "icon": ['fas', 'bookmark']
    }
  }), _c('b-tooltip', {
    attrs: {
      "variant": "success",
      "target": "tooltip-bookmark"
    }
  }, [_vm._v("Bookmarked!")])], 1), _c('b-modal', {
    ref: "my-modal",
    attrs: {
      "size": "lg",
      "hide-footer": "",
      "title": "Want to bookmark this for later?"
    }
  }, [_c('p', {
    staticClass: "my-4"
  }, [_vm._v(" Please "), _c('router-link', {
    attrs: {
      "to": {
        name: 'login'
      }
    }
  }, [_c('a', [_vm._v("LOGIN")])]), _vm._v(" or "), _c('router-link', {
    attrs: {
      "to": {
        name: 'signup'
      }
    }
  }, [_c('a', [_vm._v("SIGN UP")])]), _vm._v(" to bookmark content. ")], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }