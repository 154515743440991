<template>
  <div class="main-content">
    <!-- BradCrumb header Start-->
    <section class="inner-header-bg-partner">
      <div class="container">
        <!-- Breadcrumb Start-->
        <div class="section-breadcrumb">
          <div class="container">
            <div class="row">
              <div
                class="col-md-12 justify-content-center align-self-center pb-2"
              >
                <div class="breadcrumb-content">
                  <div class="title-section">
                    <h1 class="title text-center text-white">
                      MPP Pilot Program
                    </h1>
                  </div>
                  <div class="breadcrumb-nav">
                    <ul class="breadcrumb-link text-center list-unstyled mb-0">
                      <li class="text-white">
                        <i class="mdi mdi-home theme-light-color pr-2"></i>
                        <a href="/#/">Home</a>
                      </li>
                      <li class="theme-light-color active">MPP Pilot</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Breadcrumb End -->
      </div>
    </section>
    <!-- BradCrumb header End -->
    <!-- Body Area Start-->
    <section class="section">
      <div class="container">
        <div class="row justify-content-center">
          <!-- /* Implementing the U.S. Department of Defense (DoD) ... */ -->
          <div class="col-12 row py-4">
            <div class="section-title">
              <span>PROGRAM</span>
              <h2>
                Cybersecurity for Defense Industrial Base MPP Pilot Program
              </h2>
            </div>
            <b-col sm="12" lg="7" xl="8" class="px-0 mt-4">
              <blockquote class="blockquote mb-4">
                <span
                  >Project Spectrum’s Cybersecurity for Defense Industrial Base
                  Mentor-Protégé Program (MPP) Pilot helps eligible small
                  businesses improve cybersecurity compliance and cyber incident
                  reporting and hygiene.</span
                >
              </blockquote>
              <p>
                The primary goal of the <i>MPP Pilot</i> is to allow
                <a
                  href="https://business.defense.gov/Programs/Mentor-Protege-Program/"
                  target="_blank"
                  >Department of Defense (DoD) Mentor-Protégé Program (MPP)</a
                >
                participants and other select companies in the Manufacturing,
                Research and Development, and Knowledge-based service sectors to
                expand their footprint in the defense industrial base (DIB). DoD
                recognizes Project Spectrum as a premier resource for the tools
                and training needed to increase cybersecurity awareness and
                maintain compliance in accordance with DoD contracting
                requirements.
              </p>
            </b-col>
            <b-col sm="12" lg="5" xl="4" class="d-none d-lg-block px-4 mt-4">
              <img
                :src="require('../../assets/images/mpp/mpp_image.png')"
                class="img-fluid"
              />
            </b-col>
            <div class="text-lg-left">
              <p>
                To be eligible to participate in the MPP Pilot, a company must
                have a recent, current, or new DoD MPP agreement. MPP Pilot
                participants must meet the following expectations:
              </p>
              <ul>
                <li>
                  Devote one (1) senior level representative and one (1) IT
                  representative to the project.
                </li>
                <li>
                  Dedicate approximately ten (10) hours per week of staff time
                  to maintain their responsibility to the partnership.
                </li>
                <li>Agree to a 24-month collaboration.</li>
              </ul>
              <p>
                By the conclusion of the MPP Pilot, participants should be able
                to:
              </p>
              <ul>
                <li>
                  Show increased awareness of, and compliance with,
                  <a
                    href="https://csrc.nist.gov/publications/detail/sp/800-171/rev-2/final"
                    target="_blank"
                    >NIST SP 800-171</a
                  >
                  and
                  <a
                    href="https://csrc.nist.gov/publications/detail/sp/800-172/final"
                    target="_blank"
                    >NIST SP 800-172</a
                  >.
                </li>
                <li>
                  Demonstrate readiness for
                  <a href="https://www.acq.osd.mil/cmmc/" target="_blank"
                    >Cybersecurity Maturity Model Certification (CMMC)
                  </a>
                  accreditation.
                </li>
                <li>
                  Share best practices among the DoD small business community.
                </li>
                <li>Engage with DoD small business for valued services.</li>
              </ul>
              <b
                >There are currently four phases for the Project Spectrum MPP
                Pilot</b
              >
            </div>
          </div>
          <hr class="col-sm-12" />
          <div class="phase-container">
            <!-- /* CMMC Level 1 Compliance Journey for Small Business */ -->
            <div class="col-12 py-4 pt-0I">
              <span class="dot dot-1"></span>
              <img
                :src="require('../../assets/images/mpp/phase1.png')"
                class="phase-icon"
              />
              <div class="section-title">
                <span>PHASE 1</span>
                <h2>REGISTRATION</h2>
              </div>

              <h3>STEP 1: REGISTER FOR PROJECT SPECTRUM</h3>
              <p class="m-0">
                <span
                  v-if="isLoggedIn"
                  class="mdi mdi-check-circle text-success"
                ></span>
                <span
                  v-else
                  class="mdi mdi-checkbox-blank-circle-outline text-secondary"
                ></span>
                  <a href="https://www.projectspectrum.io/#/login"> Login</a>
                or
                  <a href="https://www.projectspectrum.io/#/signup">Create Account</a>
                for Project Spectrum.<br />
              </p>
              <p class="m-0">
                <span
                  v-if="isLoggedIn && isEnabledMFA"
                  class="mdi mdi-check-circle text-success"
                ></span>
                <span
                  v-else-if="isLoggedIn && !isEnabledMFA"
                  class="mdi mdi-minus-circle text-warning"
                ></span>
                <span
                  v-else
                  class="mdi mdi-checkbox-blank-circle-outline text-secondary"
                ></span>
                Enable <b>multifactor authentication (MFA)</b> in your
                  <a href="https://www.projectspectrum.io/#/my-account">Account Settings</a> .
              </p>
              <h3>
                STEP 2: APPLY TO THE CYBERSECURITY FOR DEFENSE INDUSTRIAL BASE
                MPP PILOT
              </h3>
              <p v-if="isLoggedIn && isEnabledMFA">
                After completing registration for Project Spectrum,
                  <a href="https://www.projectspectrum.io/#/pilot-register">start your application</a>.				  
				Applicants will be asked additional qualification questions
                for the <i>MPP Pilot Program</i>.
              </p>
              <p v-else>
                After completing registration for Project Spectrum, applicants
                can start their application here. Applicants will be asked
                additional qualification questions for the
                <i>MPP Pilot Program</i>.
              </p>
              <h3>STEP 3: TAKE THE CYBER READINESS CHECK</h3>
              <p>
                Initiate the readiness check process for CMMC.
                <a href="https://www.projectspectrum.io/#/cyber-readiness-check"
                  >Start your Cyber Readiness Check evaluation now</a
                >.<br />
                <i>
                  Participants can stop at any point, save their progress, and
                  finish later.
                </i>
              </p>
            </div>
            <hr class="col-sm-12" />
            <!-- /* Implementing the U.S. Department of Defense (DoD) ... */ -->
            <div class="col-12 py-4">
              <div class="mt-0 text-lg-left">
                <span class="dot dot-2"></span>
                <img
                  :src="require('../../assets/images/mpp/phase2.png')"
                  class="phase-icon"
                />
                <div class="section-title">
                  <span>PHASE 2</span>
                  <h2>TRAINING</h2>
                </div>
                <h3>STEP 4: TAKE THE READINESS TRAINING</h3>
                <p>
                  MPP Pilot participants will take basic training as needed, and
                  that training will be specific to the results of the readiness
                  check. For those that need the basic training foundational to
                  navigating cybersecurity compliance, Project Spectrum strongly
                  recommends the following offerings:
                </p>
                <ul>
                  <li>
                    <a
                      href="https://www.projectspectrum.io/#/courses-encite?query=CUI%20for%20Contractors"
                      target="_blank"
                    >
                      Controlled Unclassified Information (CUI) online course.
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.projectspectrum.io/#/courses-encite?query=CMMC%20Level%201%20Course"
                      target="_blank"
                    >
                      CMMC Level 1 online course.
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.projectspectrum.io/#/courses-encite?query=Systems%20Security%20Plan%20Fundamentals"
                      target="_blank"
                    >
                     Systems Security Plan Fundamentals
                    </a>
                  </li>
                </ul>
                <p>
                  Project Spectrum cyber advisors will create a training plan
                  and improvement strategy for each participating company based
                  on readiness check scores and other key information.
                </p>
              </div>
            </div>
            <hr class="col-12" />
            <!-- /* Project Spectrum – A Comprehensive Resource for Small Businesses */ -->
            <div class="col-12 py-2">
              <div class="mt-4 text-lg-left">
                <div class="mt-4 text-lg-left">
                  <span class="dot dot-3"></span>
                  <img
                    :src="require('../../assets/images/mpp/phase3.png')"
                    class="phase-icon"
                  />
                  <div class="section-title">
                    <span>PHASE 3 & 4</span>
                    <h2>COMPLIANCE MENTORSHIP & CONTINUOUS COMPLIANCE</h2>
                  </div>
                </div>
                <p>
                    Project Spectrum previously defined these phases and
                    outlined specific steps for both Phase 3 and Phase 4.
                    Following the recently announced changes to CMMC (Version
                    2.0), we are reassessing these phases to ensure they follow
                    the new criteria and will re-launch them in the near future.
				</p>
              </div>
            </div>
            <hr class="col-12" />
          </div>
        </div>
      </div>
    </section>
    <!-- Body Area End-->
  </div>
</template>
<script>
import { isLoggedIn, isEnabledMFA } from '../../utils/authentication';
export default {
  name: 'pilot-program',
  mounted() {
    this.init();
  },
  data() {
    return {};
  },
  computed: {
    isLoggedIn() {
      return isLoggedIn();
    },
    isEnabledMFA() {
      return isEnabledMFA();
    },
  },
  methods: {
    init() {
      /* The footer height no longer needs to be set */
      // this.initFooterHeight();
    },
    initFooterHeight() {
      var footerHeight = $('footer').outerHeight();
      $('.main-content').css('margin-bottom', footerHeight);
    },
  },
};
</script>
<style scoped>
h3 {
  font-size: 1.2rem;
  margin-top: 1rem;
}

hr {
  margin-top: 0rem;
  margin-bottom: 0rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding: 0.5rem;
}

ul {
  padding-left: 0rem;
}

.blockquote {
  border-left: 3px solid #f2b636;
}

.phase-container {
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  padding-left: 15px;
  margin-left: 15px;
}

.phase-icon {
  margin-bottom: 10px;
  width: 60px;
  height: 60px;
  object-fit: contain;
}

.dot-1 {
  top: calc(60px + 10px + 5px);
}

.dot-2 {
  top: calc(60px + 10px + 29px);
}

.dot-3 {
  top: calc(60px + 10px + 37px);
}

@media (min-width: 768px) {
  .phase-icon {
    padding: 0;
    max-width: 69px;
    max-height: 73px;
    position: absolute;
    left: -105px;
  }

  .phase-container {
    margin-top: 1rem;
    margin-bottom: 0px;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    padding-left: 15px;
    margin-left: 100px;
  }

  .dot-1 {
    top: 5px;
  }

  .dot-2 {
    top: 29px;
  }

  .dot-3 {
    top: 37px;
  }
}

.dot {
  height: 15px;
  width: 15px;
  background-color: #f2b636;
  border-radius: 50%;
  position: absolute;
  left: -23.5px;
}

.pt-0I {
  padding-top: 0px !important;
}
</style>
