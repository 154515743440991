var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "main-content"
  }, [_c('section', {
    staticClass: "inner-header-bg"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "section-breadcrumb"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12 justify-content-center align-self-center pb-2"
  }, [_c('div', {
    staticClass: "breadcrumb-content"
  }, [_vm._m(0), _c('div', {
    staticClass: "breadcrumb-nav"
  }, [_c('ul', {
    staticClass: "breadcrumb-link text-center list-unstyled mb-0"
  }, [_vm._m(1), _c('li', {
    staticClass: "text-white"
  }, [_c('router-link', {
    attrs: {
      "to": {
        name: 'tool-list'
      }
    }
  }, [_c('a', {
    attrs: {
      "href": "#"
    }
  }, [_vm._v("Tool Reviews")])])], 1), _c('li', {
    staticClass: "theme-light-color active"
  }, [_vm._v("Tool Compare")])])])])])])])])])]), _c('section', {
    staticClass: "section section-blog"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('article', [_c('b-table-simple', {
    attrs: {
      "responsive": "",
      "hover": ""
    }
  }, [_c('b-thead', {
    staticClass: "border-0"
  }, [_c('b-tr', {
    staticClass: "border-0"
  }, [_c('b-th', {
    staticClass: "border-0"
  }), _vm._l(_vm.items, function (tool) {
    return _c('b-th', {
      key: tool.id,
      staticClass: "border-0"
    }, [_c('img', {
      staticClass: "tool-icon",
      attrs: {
        "src": tool === null || tool === void 0 ? void 0 : tool.mainImage,
        "alt": tool === null || tool === void 0 ? void 0 : tool.photoDescription
      }
    })]);
  })], 2), _c('b-tr', [_c('b-th', {
    staticClass: "border-0"
  }), _vm._l(_vm.items, function (tool) {
    return _c('b-th', {
      key: tool.id,
      staticClass: "border-0"
    }, [_c('div', {
      staticClass: "flex-container title-container"
    }, [_c('p', {
      staticClass: "m-0 clipped-text flex-center title-text"
    }, [_vm._v(" " + _vm._s(tool.title) + " ")])])]);
  })], 2)], 1), _c('b-tbody', _vm._l(_vm.fieldsToCompare, function (feature) {
    return _c('b-tr', {
      key: feature
    }, [_c('b-td', {
      staticClass: "bg-light"
    }, [_vm._v(_vm._s(feature.name))]), _vm._l(_vm.items, function (tool) {
      var _tool$toolRelatedCert, _tool$toolRelatedCert2, _tool$toolKnownClient, _tool$toolKnownClient2;
      return _c('b-td', {
        key: tool.id
      }, [feature.field === 'toolRelatedCertifications' ? _c('span', [(tool === null || tool === void 0 ? void 0 : (_tool$toolRelatedCert = tool.toolRelatedCertifications) === null || _tool$toolRelatedCert === void 0 ? void 0 : _tool$toolRelatedCert.certification.length) > 0 ? _c('b-list-group', _vm._l(tool === null || tool === void 0 ? void 0 : (_tool$toolRelatedCert2 = tool.toolRelatedCertifications) === null || _tool$toolRelatedCert2 === void 0 ? void 0 : _tool$toolRelatedCert2.certification, function (cert) {
        return _c('b-list-group-item', {
          key: cert
        }, [_vm._v(_vm._s(cert))]);
      }), 1) : _c('b-list-group', [_c('b-list-group-item', [_vm._v("None")])], 1)], 1) : feature.field === 'toolKnownClients' ? _c('span', [(tool === null || tool === void 0 ? void 0 : (_tool$toolKnownClient = tool.toolKnownClients) === null || _tool$toolKnownClient === void 0 ? void 0 : _tool$toolKnownClient.knownClient.length) > 0 ? _c('b-list-group', _vm._l(tool === null || tool === void 0 ? void 0 : (_tool$toolKnownClient2 = tool.toolKnownClients) === null || _tool$toolKnownClient2 === void 0 ? void 0 : _tool$toolKnownClient2.knownClient, function (cert) {
        return _c('b-list-group-item', {
          key: cert
        }, [_vm._v(_vm._s(cert))]);
      }), 1) : _c('b-list-group', [_c('b-list-group-item', [_vm._v("None")])], 1)], 1) : _vm.isBoolean(tool[feature.field]) ? _c('p', {
        staticClass: "text-center m-0"
      }, [_vm.isBooleanTrue(tool[feature.field]) ? _c('font-awesome-icon', {
        staticClass: "pl-1",
        attrs: {
          "size": "lg",
          "icon": ['fas', 'check-circle']
        }
      }) : _c('font-awesome-icon', {
        staticClass: "pl-1",
        attrs: {
          "size": "lg",
          "icon": ['fas', 'times-circle']
        }
      })], 1) : _c('p', {
        staticClass: "text-center m-0"
      }, [_vm._v(" " + _vm._s(tool[feature.field]) + " ")])]);
    })], 2);
  }), 1)], 1)], 1)])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "title-section"
  }, [_c('h1', {
    staticClass: "title text-center text-white"
  }, [_vm._v("Tool Compare")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('li', {
    staticClass: "text-white"
  }, [_c('i', {
    staticClass: "mdi mdi-home theme-light-color pr-2"
  }), _c('a', {
    attrs: {
      "href": "/#/"
    }
  }, [_vm._v("Home")])]);

}]

export { render, staticRenderFns }