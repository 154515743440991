var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('ul', _vm._l(_vm.items, function (item, index) {
    return _c('li', {
      key: index,
      staticStyle: {
        "list-style": "none"
      }
    }, [item.State ? _c('div', [_c('strong', [_vm._v("State:")]), _vm._v(" " + _vm._s(item.State))]) : _vm._e(), item.Name ? _c('div', [_c('strong', [_vm._v("Name:")]), _vm._v(" " + _vm._s(item.Name))]) : _vm._e(), item['Address Line 1'] ? _c('div', [_c('strong', [_vm._v("Address Line 1:")]), _vm._v(" " + _vm._s(item["Address Line 1"]) + " ")]) : _vm._e(), item['Address Line 2'] ? _c('div', [_c('strong', [_vm._v("Address Line 2:")]), _vm._v(" " + _vm._s(item["Address Line 2"]) + " ")]) : _vm._e(), item.City ? _c('div', [_c('strong', [_vm._v("City:")]), _vm._v(" " + _vm._s(item.City))]) : _vm._e(), item['Postal Code'] ? _c('div', [_c('strong', [_vm._v("Postal Code:")]), _vm._v(" " + _vm._s(item["Postal Code"]) + " ")]) : _vm._e(), item['Phone Number'] ? _c('div', [_c('strong', [_vm._v("Phone Number: ")]), _c('a', {
      attrs: {
        "href": 'tel:' + item['Phone Number']
      }
    }, [_vm._v(_vm._s(item["Phone Number"]))])]) : _vm._e(), item.Extension ? _c('div', [_c('strong', [_vm._v("Extension: ")]), _vm._v(" " + _vm._s(item.Extension) + " ")]) : _vm._e(), item['email for references'] ? _c('div', [_c('strong', [_vm._v("Email: ")]), _c('a', {
      attrs: {
        "href": 'mailto:' + item['email for references']
      }
    }, [_vm._v(_vm._s(item["email for references"]))])]) : _vm._e(), item.website ? _c('div', [_c('strong', [_vm._v("Website: ")]), _c('a', {
      attrs: {
        "href": item.website,
        "target": "_blank"
      }
    }, [_vm._v(_vm._s(item.website))])]) : _vm._e(), item['Organization Service Area'] ? _c('div', [_c('strong', [_vm._v("Organization Service Area: ")]), item['Organization Service Area'] ? _c('span', {
      staticClass: "countyCell"
    }, [_vm._v(" " + _vm._s(item['Organization Service Area'].toLowerCase()) + " ")]) : _vm._e()]) : _vm._e(), index < _vm.items.length - 1 ? _c('hr') : _vm._e()]);
  }), 0)]);

}
var staticRenderFns = []

export { render, staticRenderFns }