var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm._m(0);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "main-content"
  }, [_c('section', {
    staticClass: "inner-header-bg-techHub"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "section-breadcrumb"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12 justify-content-center align-self-center pb-2"
  }, [_c('div', {
    staticClass: "breadcrumb-content"
  }, [_c('div', {
    staticClass: "title-section"
  }, [_c('h1', {
    staticClass: "title text-center text-white"
  }, [_vm._v("Pricing")])]), _c('div', {
    staticClass: "breadcrumb-nav"
  }, [_c('ul', {
    staticClass: "breadcrumb-link text-center list-unstyled mb-0"
  }, [_c('li', {
    staticClass: "text-white"
  }, [_c('i', {
    staticClass: "mdi mdi-home theme-light-color pr-2"
  }), _c('a', {
    attrs: {
      "href": "/#/"
    }
  }, [_vm._v("Home")])]), _c('li', {
    staticClass: "theme-light-color active"
  }, [_vm._v("Pricing")])])])])])])])])])]), _c('section', {
    staticClass: "section"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('h1', {
    staticClass: "text-center"
  }, [_vm._v("Industrial Cyber Tools and Techniques Pricing")]), _c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "font-size": "1em"
    }
  }, [_c('u', [_c('span', {
    staticStyle: {
      "font-size": "19px"
    }
  }, [_vm._v("Introduction")])])]), _c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "font-size": "1em"
    }
  }, [_c('span', [_vm._v("Project Spectrum and Project Krome address the cybersecurity needs of the industrial base and supply chains of the federal government and commercial markets.  These industrialized cybersecurity tools and techniques will create the community of interest and information sharing that will allow for proactive protection and reporting.  Project Spectrum and Krome simplify the complexities of good cyber hygiene and streamlines the process to be cybersecurity maturity model (CMMC) and NIST compliant.  Project Spectrum and Project Krome will empower Government agencies and small and medium sized companies to reduce their overall cost to implement good cyber approaches, maintain education and training requirements, and access to a diverse toolset.  These products will ensure Readiness, Compliance, Resilience, and Advisement.")])]), _c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "font-size": "1em"
    }
  }, [_c('span', [_vm._v(" ")])]), _c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "font-size": "1em"
    }
  }, [_c('span', [_vm._v("Eccalon’s cyber pricing is divided into two offerings: Project Spectrum and Project Krome. ")])]), _c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "font-size": "1em"
    }
  }, [_vm._v(" ")]), _c('div', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "8.0pt",
      "margin-left": "0in",
      "line-height": "107%",
      "font-size": "1em"
    },
    attrs: {
      "align": "center"
    }
  }, [_c('table', {
    staticStyle: {
      "border-collapse": "collapse",
      "border": "none"
    }
  }, [_c('tbody', [_c('tr', [_c('td', {
    staticStyle: {
      "width": "116.85pt",
      "border": "solid windowtext 1.0pt",
      "background": "#D9D9D9",
      "padding": "0in 5.4pt 0in 5.4pt",
      "height": "40.15pt"
    },
    attrs: {
      "rowspan": "2"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "font-size": "1em"
    }
  }, [_c('span', [_vm._v("Project Spectrum Enterprise"), _c('span', {
    staticStyle: {
      "color": "black"
    }
  }, [_vm._v("* – Aggregate Spend (Both Products and Services) Annual Spend with Premium Support")])])])]), _c('td', {
    staticStyle: {
      "width": "116.85pt",
      "border": "solid windowtext 1.0pt",
      "border-left": "none",
      "padding": "0in 5.4pt 0in 5.4pt",
      "height": "40.15pt"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('strong', [_c('span', [_vm._v("Discount Level 1")])])]), _c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('strong', [_c('span', [_vm._v("≥$5M Annual Spend")])])])]), _c('td', {
    staticStyle: {
      "width": "116.9pt",
      "border": "solid windowtext 1.0pt",
      "border-left": "none",
      "padding": "0in 5.4pt 0in 5.4pt",
      "height": "40.15pt"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('strong', [_c('span', [_vm._v("Discount Level 2")])])]), _c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('strong', [_c('span', [_vm._v("≥$12M Annual Spend")])])])]), _c('td', {
    staticStyle: {
      "width": "116.9pt",
      "border": "solid windowtext 1.0pt",
      "border-left": "none",
      "padding": "0in 5.4pt 0in 5.4pt",
      "height": "40.15pt"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('strong', [_c('span', [_vm._v("Discount Level 3")])])]), _c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('strong', [_c('span', [_vm._v("≥$20M Annual Spend")])])])])]), _c('tr', [_c('td', {
    staticStyle: {
      "width": "116.85pt",
      "border-top": "none",
      "border-left": "none",
      "border-bottom": "solid windowtext 1.0pt",
      "border-right": "solid windowtext 1.0pt",
      "padding": "0in 5.4pt 0in 5.4pt",
      "height": "40.1pt"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('span', [_vm._v("2%")])])]), _c('td', {
    staticStyle: {
      "width": "116.9pt",
      "border-top": "none",
      "border-left": "none",
      "border-bottom": "solid windowtext 1.0pt",
      "border-right": "solid windowtext 1.0pt",
      "padding": "0in 5.4pt 0in 5.4pt",
      "height": "40.1pt"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('span', [_vm._v("5%")])])]), _c('td', {
    staticStyle: {
      "width": "116.9pt",
      "border-top": "none",
      "border-left": "none",
      "border-bottom": "solid windowtext 1.0pt",
      "border-right": "solid windowtext 1.0pt",
      "padding": "0in 5.4pt 0in 5.4pt",
      "height": "40.1pt"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('span', [_vm._v("8%")])])])])])])]), _c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "font-size": "1em"
    }
  }, [_vm._v(" ")]), _c('div', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "8.0pt",
      "margin-left": "0in",
      "line-height": "107%",
      "font-size": "1em"
    },
    attrs: {
      "align": "center"
    }
  }, [_c('table', {
    staticStyle: {
      "border-collapse": "collapse",
      "border": "none"
    }
  }, [_c('tbody', [_c('tr', [_c('td', {
    staticStyle: {
      "width": "116.85pt",
      "border": "solid windowtext 1.0pt",
      "background": "#D9D9D9",
      "padding": "0in 5.4pt 0in 5.4pt",
      "height": "40.15pt"
    },
    attrs: {
      "rowspan": "2"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "font-size": "1em"
    }
  }, [_c('span', [_vm._v("Project Krome Enterprise"), _c('span', {
    staticStyle: {
      "color": "black"
    }
  }, [_vm._v("* – Aggregate Spend (Both Products and Services) Annual Spend with Premium Support")])])])]), _c('td', {
    staticStyle: {
      "width": "116.85pt",
      "border": "solid windowtext 1.0pt",
      "border-left": "none",
      "padding": "0in 5.4pt 0in 5.4pt",
      "height": "40.15pt"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('strong', [_c('span', [_vm._v("Discount Level 1")])])]), _c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('strong', [_c('span', [_vm._v("≥$8M Annual Spend")])])])]), _c('td', {
    staticStyle: {
      "width": "116.9pt",
      "border": "solid windowtext 1.0pt",
      "border-left": "none",
      "padding": "0in 5.4pt 0in 5.4pt",
      "height": "40.15pt"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('strong', [_c('span', [_vm._v("Discount Level 2")])])]), _c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('strong', [_c('span', [_vm._v("≥$18M Annual Spend")])])])]), _c('td', {
    staticStyle: {
      "width": "116.9pt",
      "border": "solid windowtext 1.0pt",
      "border-left": "none",
      "padding": "0in 5.4pt 0in 5.4pt",
      "height": "40.15pt"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('strong', [_c('span', [_vm._v("Discount Level 3")])])]), _c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('strong', [_c('span', [_vm._v("≥$35M Annual Spend")])])])])]), _c('tr', [_c('td', {
    staticStyle: {
      "width": "116.85pt",
      "border-top": "none",
      "border-left": "none",
      "border-bottom": "solid windowtext 1.0pt",
      "border-right": "solid windowtext 1.0pt",
      "padding": "0in 5.4pt 0in 5.4pt",
      "height": "40.1pt"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('span', [_vm._v("2%")])])]), _c('td', {
    staticStyle: {
      "width": "116.9pt",
      "border-top": "none",
      "border-left": "none",
      "border-bottom": "solid windowtext 1.0pt",
      "border-right": "solid windowtext 1.0pt",
      "padding": "0in 5.4pt 0in 5.4pt",
      "height": "40.1pt"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('span', [_vm._v("5%")])])]), _c('td', {
    staticStyle: {
      "width": "116.9pt",
      "border-top": "none",
      "border-left": "none",
      "border-bottom": "solid windowtext 1.0pt",
      "border-right": "solid windowtext 1.0pt",
      "padding": "0in 5.4pt 0in 5.4pt",
      "height": "40.1pt"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('span', [_vm._v("10%")])])])])])])]), _c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "font-size": "1em"
    }
  }, [_vm._v(" ")]), _c('p', [_c('span', {
    staticStyle: {
      "font-size": "1em",
      "line-height": "107%"
    }
  }, [_c('br'), _vm._v(" ")])]), _c('h1', {
    staticClass: "text-center"
  }, [_vm._v("Project Spectrum")]), _c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "8.0pt",
      "margin-left": "0in",
      "line-height": "107%",
      "font-size": "1em"
    }
  }, [_c('span', [_vm._v("Project Spectrum is a resources-based platform as a service that is fully integrated with the Encite Learning Management System (LMS).  Project Spectrum is a comprehensive, cost-effective platform for cybersecurity information, resources, tools, and training focused on supply chain risk management. ")])]), _c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "8.0pt",
      "margin-left": "0in",
      "line-height": "107%",
      "font-size": "1em"
    }
  }, [_c('u', [_c('span', {
    staticStyle: {
      "font-size": "19px",
      "line-height": "107%"
    }
  }, [_vm._v("Compare Editions and Top Features")])])]), _c('div', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "8.0pt",
      "margin-left": "0in",
      "line-height": "107%",
      "font-size": "1em"
    },
    attrs: {
      "align": "center"
    }
  }, [_c('table', {
    staticStyle: {
      "width": "70%",
      "border-collapse": "collapse",
      "border": "none"
    }
  }, [_c('tbody', [_c('tr', [_c('td', {
    staticStyle: {
      "width": "10%",
      "border-top": "none",
      "border-right": "none",
      "border-left": "none",
      "border-image": "initial",
      "border-bottom": "1pt solid rgb(217, 217, 217)",
      "padding": "0in 5.4pt",
      "vertical-align": "top"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em"
    }
  }, [_c('strong', [_c('span', {
    staticStyle: {
      "color": "white"
    }
  }, [_vm._v(" ")])])])]), _c('td', {
    staticStyle: {
      "width": "10%",
      "border-top": "solid #4472C4 1.0pt",
      "border-left": "none",
      "border-bottom": "solid #D9D9D9 1.0pt",
      "border-right": "none",
      "background": "#4472C4",
      "padding": "0in 5.4pt 0in 5.4pt"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('strong', [_c('span', {
    staticStyle: {
      "color": "white"
    }
  }, [_vm._v("Project Spectrum Basic")])])]), _c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('strong', [_c('span', {
    staticStyle: {
      "color": "white"
    }
  }, [_vm._v("$400/User/Month")])])]), _c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('strong', [_c('span', {
    staticStyle: {
      "color": "white"
    }
  }, [_vm._v("(Billed Annually)")])])])]), _c('td', {
    staticStyle: {
      "width": "20%",
      "border-top": "solid #4472C4 1.0pt",
      "border-left": "none",
      "border-bottom": "solid #D9D9D9 1.0pt",
      "border-right": "none",
      "background": "#4472C4",
      "padding": "0in 5.4pt 0in 5.4pt"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('strong', [_c('span', {
    staticStyle: {
      "color": "white"
    }
  }, [_vm._v("Project Spectrum Plus")])])]), _c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('strong', [_c('span', {
    staticStyle: {
      "color": "white"
    }
  }, [_vm._v("$700/User/Month")])])]), _c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('strong', [_c('span', {
    staticStyle: {
      "color": "white"
    }
  }, [_vm._v("(Billed Annually)")])])])]), _c('td', {
    staticStyle: {
      "width": "10%",
      "border-top": "solid #4472C4 1.0pt",
      "border-left": "none",
      "border-bottom": "solid #D9D9D9 1.0pt",
      "border-right": "solid #4472C4 1.0pt",
      "background": "#4472C4",
      "padding": "0in 5.4pt 0in 5.4pt"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('strong', [_c('span', {
    staticStyle: {
      "color": "white"
    }
  }, [_vm._v("Project Spectrum Plus")])])]), _c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('strong', [_c('span', {
    staticStyle: {
      "color": "white"
    }
  }, [_vm._v("$2100/Enterprise/Month")])])]), _c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('strong', [_c('span', {
    staticStyle: {
      "color": "white"
    }
  }, [_vm._v("(Billed Annually)")])])])])]), _c('tr', [_c('td', {
    staticStyle: {
      "width": "10%",
      "border-right": "1pt solid rgb(217, 217, 217)",
      "border-bottom": "1pt solid rgb(217, 217, 217)",
      "border-left": "1pt solid rgb(217, 217, 217)",
      "border-image": "initial",
      "border-top": "none",
      "background": "white",
      "padding": "0in 5.4pt",
      "vertical-align": "top"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em"
    }
  }, [_c('span', {
    staticStyle: {
      "color": "black"
    }
  }, [_vm._v("Cloud based online curriculum")])])]), _c('td', {
    staticStyle: {
      "width": "10%",
      "border-top": "none",
      "border-left": "none",
      "border-bottom": "solid #D9D9D9 1.0pt",
      "border-right": "solid #D9D9D9 1.0pt",
      "padding": "0in 5.4pt 0in 5.4pt"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('span', [_c('img', {
    attrs: {
      "width": "16",
      "src": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAZCAMAAADt/kXiAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAwUExURQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAFo/HAsAAAAPdFJOUwAQIDBAUGBwgI+fv8/f76R8WAwAAAAJcEhZcwAAFxEAABcRAcom8z8AAACjSURBVChTnZHtDsIgDEULhW0Ko+//ttLb69AYE+P51R4+Wor8jdZaMuOLdHRzzoMi0LBOV7rJPijBRiv6ps14IjXmT87wO1PQ8ywVxU8qpye5mw3XSuWENvN3FBjw1FanrzBe+9JoFT5Le9HYnzxoIrel4wXoZy4sjX7kQNyWZv8SQ1vDmIVAZk5GCS2y0YBxzfPr/Cf8grHz7oXWreiH/Q2RBxLzEDnF6jM5AAAAAElFTkSuQmCC",
      "alt": "Badge Tick1 with solid fill"
    }
  })])])]), _c('td', {
    staticStyle: {
      "width": "20%",
      "border-top": "none",
      "border-left": "none",
      "border-bottom": "solid #D9D9D9 1.0pt",
      "border-right": "solid #D9D9D9 1.0pt",
      "padding": "0in 5.4pt 0in 5.4pt"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('span', [_c('img', {
    attrs: {
      "width": "16",
      "src": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAZCAMAAADt/kXiAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAwUExURQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAFo/HAsAAAAPdFJOUwAQIDBAUGBwgI+fv8/f76R8WAwAAAAJcEhZcwAAFxEAABcRAcom8z8AAACjSURBVChTnZHtDsIgDEULhW0Ko+//ttLb69AYE+P51R4+Wor8jdZaMuOLdHRzzoMi0LBOV7rJPijBRiv6ps14IjXmT87wO1PQ8ywVxU8qpye5mw3XSuWENvN3FBjw1FanrzBe+9JoFT5Le9HYnzxoIrel4wXoZy4sjX7kQNyWZv8SQ1vDmIVAZk5GCS2y0YBxzfPr/Cf8grHz7oXWreiH/Q2RBxLzEDnF6jM5AAAAAElFTkSuQmCC",
      "alt": "Badge Tick1 with solid fill"
    }
  })])])]), _c('td', {
    staticStyle: {
      "width": "10%",
      "border-top": "none",
      "border-left": "none",
      "border-bottom": "solid #D9D9D9 1.0pt",
      "border-right": "solid #D9D9D9 1.0pt",
      "padding": "0in 5.4pt 0in 5.4pt"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('span', [_c('img', {
    attrs: {
      "width": "16",
      "src": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAZCAMAAADt/kXiAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAwUExURQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAFo/HAsAAAAPdFJOUwAQIDBAUGBwgI+fv8/f76R8WAwAAAAJcEhZcwAAFxEAABcRAcom8z8AAACjSURBVChTnZHtDsIgDEULhW0Ko+//ttLb69AYE+P51R4+Wor8jdZaMuOLdHRzzoMi0LBOV7rJPijBRiv6ps14IjXmT87wO1PQ8ywVxU8qpye5mw3XSuWENvN3FBjw1FanrzBe+9JoFT5Le9HYnzxoIrel4wXoZy4sjX7kQNyWZv8SQ1vDmIVAZk5GCS2y0YBxzfPr/Cf8grHz7oXWreiH/Q2RBxLzEDnF6jM5AAAAAElFTkSuQmCC",
      "alt": "Badge Tick1 with solid fill"
    }
  })])])])]), _c('tr', [_c('td', {
    staticStyle: {
      "width": "10%",
      "border-right": "1pt solid rgb(217, 217, 217)",
      "border-bottom": "1pt solid rgb(217, 217, 217)",
      "border-left": "1pt solid rgb(217, 217, 217)",
      "border-image": "initial",
      "border-top": "none",
      "background": "white",
      "padding": "0in 5.4pt",
      "vertical-align": "top"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em"
    }
  }, [_c('span', {
    staticStyle: {
      "color": "black"
    }
  }, [_vm._v("Hosted Webinars")])])]), _c('td', {
    staticStyle: {
      "width": "10%",
      "border-top": "none",
      "border-left": "none",
      "border-bottom": "solid #D9D9D9 1.0pt",
      "border-right": "solid #D9D9D9 1.0pt",
      "padding": "0in 5.4pt 0in 5.4pt"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('span', [_c('img', {
    attrs: {
      "width": "16",
      "src": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAZCAMAAADt/kXiAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAwUExURQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAFo/HAsAAAAPdFJOUwAQIDBAUGBwgI+fv8/f76R8WAwAAAAJcEhZcwAAFxEAABcRAcom8z8AAACjSURBVChTnZHtDsIgDEULhW0Ko+//ttLb69AYE+P51R4+Wor8jdZaMuOLdHRzzoMi0LBOV7rJPijBRiv6ps14IjXmT87wO1PQ8ywVxU8qpye5mw3XSuWENvN3FBjw1FanrzBe+9JoFT5Le9HYnzxoIrel4wXoZy4sjX7kQNyWZv8SQ1vDmIVAZk5GCS2y0YBxzfPr/Cf8grHz7oXWreiH/Q2RBxLzEDnF6jM5AAAAAElFTkSuQmCC",
      "alt": "Badge Tick1 with solid fill"
    }
  })])])]), _c('td', {
    staticStyle: {
      "width": "20%",
      "border-top": "none",
      "border-left": "none",
      "border-bottom": "solid #D9D9D9 1.0pt",
      "border-right": "solid #D9D9D9 1.0pt",
      "padding": "0in 5.4pt 0in 5.4pt"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('span', [_c('img', {
    attrs: {
      "width": "16",
      "src": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAZCAMAAADt/kXiAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAwUExURQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAFo/HAsAAAAPdFJOUwAQIDBAUGBwgI+fv8/f76R8WAwAAAAJcEhZcwAAFxEAABcRAcom8z8AAACjSURBVChTnZHtDsIgDEULhW0Ko+//ttLb69AYE+P51R4+Wor8jdZaMuOLdHRzzoMi0LBOV7rJPijBRiv6ps14IjXmT87wO1PQ8ywVxU8qpye5mw3XSuWENvN3FBjw1FanrzBe+9JoFT5Le9HYnzxoIrel4wXoZy4sjX7kQNyWZv8SQ1vDmIVAZk5GCS2y0YBxzfPr/Cf8grHz7oXWreiH/Q2RBxLzEDnF6jM5AAAAAElFTkSuQmCC",
      "alt": "Badge Tick1 with solid fill"
    }
  })])])]), _c('td', {
    staticStyle: {
      "width": "10%",
      "border-top": "none",
      "border-left": "none",
      "border-bottom": "solid #D9D9D9 1.0pt",
      "border-right": "solid #D9D9D9 1.0pt",
      "padding": "0in 5.4pt 0in 5.4pt"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('span', [_c('img', {
    attrs: {
      "width": "16",
      "src": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAZCAMAAADt/kXiAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAwUExURQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAFo/HAsAAAAPdFJOUwAQIDBAUGBwgI+fv8/f76R8WAwAAAAJcEhZcwAAFxEAABcRAcom8z8AAACjSURBVChTnZHtDsIgDEULhW0Ko+//ttLb69AYE+P51R4+Wor8jdZaMuOLdHRzzoMi0LBOV7rJPijBRiv6ps14IjXmT87wO1PQ8ywVxU8qpye5mw3XSuWENvN3FBjw1FanrzBe+9JoFT5Le9HYnzxoIrel4wXoZy4sjX7kQNyWZv8SQ1vDmIVAZk5GCS2y0YBxzfPr/Cf8grHz7oXWreiH/Q2RBxLzEDnF6jM5AAAAAElFTkSuQmCC",
      "alt": "Badge Tick1 with solid fill"
    }
  })])])])]), _c('tr', [_c('td', {
    staticStyle: {
      "width": "10%",
      "border-right": "1pt solid rgb(217, 217, 217)",
      "border-bottom": "1pt solid rgb(217, 217, 217)",
      "border-left": "1pt solid rgb(217, 217, 217)",
      "border-image": "initial",
      "border-top": "none",
      "background": "white",
      "padding": "0in 5.4pt",
      "vertical-align": "top"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em"
    }
  }, [_c('span', {
    staticStyle: {
      "color": "black"
    }
  }, [_vm._v("Supplier Performance Risk System Assistance")])])]), _c('td', {
    staticStyle: {
      "width": "10%",
      "border-top": "none",
      "border-left": "none",
      "border-bottom": "solid #D9D9D9 1.0pt",
      "border-right": "solid #D9D9D9 1.0pt",
      "padding": "0in 5.4pt 0in 5.4pt"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('span', [_c('img', {
    attrs: {
      "width": "16",
      "src": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAZCAMAAADt/kXiAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAwUExURQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAFo/HAsAAAAPdFJOUwAQIDBAUGBwgI+fv8/f76R8WAwAAAAJcEhZcwAAFxEAABcRAcom8z8AAACjSURBVChTnZHtDsIgDEULhW0Ko+//ttLb69AYE+P51R4+Wor8jdZaMuOLdHRzzoMi0LBOV7rJPijBRiv6ps14IjXmT87wO1PQ8ywVxU8qpye5mw3XSuWENvN3FBjw1FanrzBe+9JoFT5Le9HYnzxoIrel4wXoZy4sjX7kQNyWZv8SQ1vDmIVAZk5GCS2y0YBxzfPr/Cf8grHz7oXWreiH/Q2RBxLzEDnF6jM5AAAAAElFTkSuQmCC",
      "alt": "Badge Tick1 with solid fill"
    }
  })])])]), _c('td', {
    staticStyle: {
      "width": "20%",
      "border-top": "none",
      "border-left": "none",
      "border-bottom": "solid #D9D9D9 1.0pt",
      "border-right": "solid #D9D9D9 1.0pt",
      "padding": "0in 5.4pt 0in 5.4pt"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('span', [_c('img', {
    attrs: {
      "width": "16",
      "src": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAZCAMAAADt/kXiAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAwUExURQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAFo/HAsAAAAPdFJOUwAQIDBAUGBwgI+fv8/f76R8WAwAAAAJcEhZcwAAFxEAABcRAcom8z8AAACjSURBVChTnZHtDsIgDEULhW0Ko+//ttLb69AYE+P51R4+Wor8jdZaMuOLdHRzzoMi0LBOV7rJPijBRiv6ps14IjXmT87wO1PQ8ywVxU8qpye5mw3XSuWENvN3FBjw1FanrzBe+9JoFT5Le9HYnzxoIrel4wXoZy4sjX7kQNyWZv8SQ1vDmIVAZk5GCS2y0YBxzfPr/Cf8grHz7oXWreiH/Q2RBxLzEDnF6jM5AAAAAElFTkSuQmCC",
      "alt": "Badge Tick1 with solid fill"
    }
  })])])]), _c('td', {
    staticStyle: {
      "width": "10%",
      "border-top": "none",
      "border-left": "none",
      "border-bottom": "solid #D9D9D9 1.0pt",
      "border-right": "solid #D9D9D9 1.0pt",
      "padding": "0in 5.4pt 0in 5.4pt"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('span', [_c('img', {
    attrs: {
      "width": "16",
      "src": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAZCAMAAADt/kXiAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAwUExURQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAFo/HAsAAAAPdFJOUwAQIDBAUGBwgI+fv8/f76R8WAwAAAAJcEhZcwAAFxEAABcRAcom8z8AAACjSURBVChTnZHtDsIgDEULhW0Ko+//ttLb69AYE+P51R4+Wor8jdZaMuOLdHRzzoMi0LBOV7rJPijBRiv6ps14IjXmT87wO1PQ8ywVxU8qpye5mw3XSuWENvN3FBjw1FanrzBe+9JoFT5Le9HYnzxoIrel4wXoZy4sjX7kQNyWZv8SQ1vDmIVAZk5GCS2y0YBxzfPr/Cf8grHz7oXWreiH/Q2RBxLzEDnF6jM5AAAAAElFTkSuQmCC",
      "alt": "Badge Tick1 with solid fill"
    }
  })])])])]), _c('tr', [_c('td', {
    staticStyle: {
      "width": "10%",
      "border-right": "1pt solid rgb(217, 217, 217)",
      "border-bottom": "1pt solid rgb(217, 217, 217)",
      "border-left": "1pt solid rgb(217, 217, 217)",
      "border-image": "initial",
      "border-top": "none",
      "background": "white",
      "padding": "0in 5.4pt",
      "vertical-align": "top"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em"
    }
  }, [_c('span', {
    staticStyle: {
      "color": "black"
    }
  }, [_vm._v("Cyber Maturity Model Assistance – CMMC v2.0 Levels 1-3")])])]), _c('td', {
    staticStyle: {
      "width": "10%",
      "border-top": "none",
      "border-left": "none",
      "border-bottom": "solid #D9D9D9 1.0pt",
      "border-right": "solid #D9D9D9 1.0pt",
      "padding": "0in 5.4pt 0in 5.4pt"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('span', [_c('img', {
    attrs: {
      "width": "16",
      "src": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAZCAMAAADt/kXiAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAwUExURQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAFo/HAsAAAAPdFJOUwAQIDBAUGBwgI+fv8/f76R8WAwAAAAJcEhZcwAAFxEAABcRAcom8z8AAACjSURBVChTnZHtDsIgDEULhW0Ko+//ttLb69AYE+P51R4+Wor8jdZaMuOLdHRzzoMi0LBOV7rJPijBRiv6ps14IjXmT87wO1PQ8ywVxU8qpye5mw3XSuWENvN3FBjw1FanrzBe+9JoFT5Le9HYnzxoIrel4wXoZy4sjX7kQNyWZv8SQ1vDmIVAZk5GCS2y0YBxzfPr/Cf8grHz7oXWreiH/Q2RBxLzEDnF6jM5AAAAAElFTkSuQmCC",
      "alt": "Badge Tick1 with solid fill"
    }
  })])])]), _c('td', {
    staticStyle: {
      "width": "20%",
      "border-top": "none",
      "border-left": "none",
      "border-bottom": "solid #D9D9D9 1.0pt",
      "border-right": "solid #D9D9D9 1.0pt",
      "padding": "0in 5.4pt 0in 5.4pt"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('span', [_c('img', {
    attrs: {
      "width": "16",
      "src": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAZCAMAAADt/kXiAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAwUExURQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAFo/HAsAAAAPdFJOUwAQIDBAUGBwgI+fv8/f76R8WAwAAAAJcEhZcwAAFxEAABcRAcom8z8AAACjSURBVChTnZHtDsIgDEULhW0Ko+//ttLb69AYE+P51R4+Wor8jdZaMuOLdHRzzoMi0LBOV7rJPijBRiv6ps14IjXmT87wO1PQ8ywVxU8qpye5mw3XSuWENvN3FBjw1FanrzBe+9JoFT5Le9HYnzxoIrel4wXoZy4sjX7kQNyWZv8SQ1vDmIVAZk5GCS2y0YBxzfPr/Cf8grHz7oXWreiH/Q2RBxLzEDnF6jM5AAAAAElFTkSuQmCC",
      "alt": "Badge Tick1 with solid fill"
    }
  })])])]), _c('td', {
    staticStyle: {
      "width": "10%",
      "border-top": "none",
      "border-left": "none",
      "border-bottom": "solid #D9D9D9 1.0pt",
      "border-right": "solid #D9D9D9 1.0pt",
      "padding": "0in 5.4pt 0in 5.4pt"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('span', [_c('img', {
    attrs: {
      "width": "16",
      "src": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAZCAMAAADt/kXiAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAwUExURQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAFo/HAsAAAAPdFJOUwAQIDBAUGBwgI+fv8/f76R8WAwAAAAJcEhZcwAAFxEAABcRAcom8z8AAACjSURBVChTnZHtDsIgDEULhW0Ko+//ttLb69AYE+P51R4+Wor8jdZaMuOLdHRzzoMi0LBOV7rJPijBRiv6ps14IjXmT87wO1PQ8ywVxU8qpye5mw3XSuWENvN3FBjw1FanrzBe+9JoFT5Le9HYnzxoIrel4wXoZy4sjX7kQNyWZv8SQ1vDmIVAZk5GCS2y0YBxzfPr/Cf8grHz7oXWreiH/Q2RBxLzEDnF6jM5AAAAAElFTkSuQmCC",
      "alt": "Badge Tick1 with solid fill"
    }
  })])])])]), _c('tr', [_c('td', {
    staticStyle: {
      "width": "10%",
      "border-right": "1pt solid rgb(217, 217, 217)",
      "border-bottom": "1pt solid rgb(217, 217, 217)",
      "border-left": "1pt solid rgb(217, 217, 217)",
      "border-image": "initial",
      "border-top": "none",
      "background": "white",
      "padding": "0in 5.4pt",
      "vertical-align": "top"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em"
    }
  }, [_c('span', {
    staticStyle: {
      "color": "black"
    }
  }, [_vm._v("DIY compliance reviews")])])]), _c('td', {
    staticStyle: {
      "width": "10%",
      "border-top": "none",
      "border-left": "none",
      "border-bottom": "solid #D9D9D9 1.0pt",
      "border-right": "solid #D9D9D9 1.0pt",
      "padding": "0in 5.4pt 0in 5.4pt"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('span', [_c('img', {
    attrs: {
      "width": "16",
      "src": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAZCAMAAADt/kXiAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAwUExURQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAFo/HAsAAAAPdFJOUwAQIDBAUGBwgI+fv8/f76R8WAwAAAAJcEhZcwAAFxEAABcRAcom8z8AAACjSURBVChTnZHtDsIgDEULhW0Ko+//ttLb69AYE+P51R4+Wor8jdZaMuOLdHRzzoMi0LBOV7rJPijBRiv6ps14IjXmT87wO1PQ8ywVxU8qpye5mw3XSuWENvN3FBjw1FanrzBe+9JoFT5Le9HYnzxoIrel4wXoZy4sjX7kQNyWZv8SQ1vDmIVAZk5GCS2y0YBxzfPr/Cf8grHz7oXWreiH/Q2RBxLzEDnF6jM5AAAAAElFTkSuQmCC",
      "alt": "Badge Tick1 with solid fill"
    }
  })])])]), _c('td', {
    staticStyle: {
      "width": "20%",
      "border-top": "none",
      "border-left": "none",
      "border-bottom": "solid #D9D9D9 1.0pt",
      "border-right": "solid #D9D9D9 1.0pt",
      "padding": "0in 5.4pt 0in 5.4pt"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('span', [_c('img', {
    attrs: {
      "width": "16",
      "src": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAZCAMAAADt/kXiAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAwUExURQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAFo/HAsAAAAPdFJOUwAQIDBAUGBwgI+fv8/f76R8WAwAAAAJcEhZcwAAFxEAABcRAcom8z8AAACjSURBVChTnZHtDsIgDEULhW0Ko+//ttLb69AYE+P51R4+Wor8jdZaMuOLdHRzzoMi0LBOV7rJPijBRiv6ps14IjXmT87wO1PQ8ywVxU8qpye5mw3XSuWENvN3FBjw1FanrzBe+9JoFT5Le9HYnzxoIrel4wXoZy4sjX7kQNyWZv8SQ1vDmIVAZk5GCS2y0YBxzfPr/Cf8grHz7oXWreiH/Q2RBxLzEDnF6jM5AAAAAElFTkSuQmCC",
      "alt": "Badge Tick1 with solid fill"
    }
  })])])]), _c('td', {
    staticStyle: {
      "width": "10%",
      "border-top": "none",
      "border-left": "none",
      "border-bottom": "solid #D9D9D9 1.0pt",
      "border-right": "solid #D9D9D9 1.0pt",
      "padding": "0in 5.4pt 0in 5.4pt"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('span', [_c('img', {
    attrs: {
      "width": "16",
      "src": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAZCAMAAADt/kXiAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAwUExURQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAFo/HAsAAAAPdFJOUwAQIDBAUGBwgI+fv8/f76R8WAwAAAAJcEhZcwAAFxEAABcRAcom8z8AAACjSURBVChTnZHtDsIgDEULhW0Ko+//ttLb69AYE+P51R4+Wor8jdZaMuOLdHRzzoMi0LBOV7rJPijBRiv6ps14IjXmT87wO1PQ8ywVxU8qpye5mw3XSuWENvN3FBjw1FanrzBe+9JoFT5Le9HYnzxoIrel4wXoZy4sjX7kQNyWZv8SQ1vDmIVAZk5GCS2y0YBxzfPr/Cf8grHz7oXWreiH/Q2RBxLzEDnF6jM5AAAAAElFTkSuQmCC",
      "alt": "Badge Tick1 with solid fill"
    }
  })])])])]), _c('tr', [_c('td', {
    staticStyle: {
      "width": "10%",
      "border-right": "1pt solid rgb(217, 217, 217)",
      "border-bottom": "1pt solid rgb(217, 217, 217)",
      "border-left": "1pt solid rgb(217, 217, 217)",
      "border-image": "initial",
      "border-top": "none",
      "background": "white",
      "padding": "0in 5.4pt",
      "vertical-align": "top"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em"
    }
  }, [_c('span', {
    staticStyle: {
      "color": "black"
    }
  }, [_vm._v("Interpret DFARS requirements for DIB ")])])]), _c('td', {
    staticStyle: {
      "width": "10%",
      "border-top": "none",
      "border-left": "none",
      "border-bottom": "solid #D9D9D9 1.0pt",
      "border-right": "solid #D9D9D9 1.0pt",
      "padding": "0in 5.4pt 0in 5.4pt"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('span', [_c('img', {
    attrs: {
      "width": "16",
      "src": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAZCAMAAADt/kXiAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAwUExURQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAFo/HAsAAAAPdFJOUwAQIDBAUGBwgI+fv8/f76R8WAwAAAAJcEhZcwAAFxEAABcRAcom8z8AAACjSURBVChTnZHtDsIgDEULhW0Ko+//ttLb69AYE+P51R4+Wor8jdZaMuOLdHRzzoMi0LBOV7rJPijBRiv6ps14IjXmT87wO1PQ8ywVxU8qpye5mw3XSuWENvN3FBjw1FanrzBe+9JoFT5Le9HYnzxoIrel4wXoZy4sjX7kQNyWZv8SQ1vDmIVAZk5GCS2y0YBxzfPr/Cf8grHz7oXWreiH/Q2RBxLzEDnF6jM5AAAAAElFTkSuQmCC",
      "alt": "Badge Tick1 with solid fill"
    }
  })])])]), _c('td', {
    staticStyle: {
      "width": "20%",
      "border-top": "none",
      "border-left": "none",
      "border-bottom": "solid #D9D9D9 1.0pt",
      "border-right": "solid #D9D9D9 1.0pt",
      "padding": "0in 5.4pt 0in 5.4pt"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('span', [_c('img', {
    attrs: {
      "width": "16",
      "src": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAZCAMAAADt/kXiAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAwUExURQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAFo/HAsAAAAPdFJOUwAQIDBAUGBwgI+fv8/f76R8WAwAAAAJcEhZcwAAFxEAABcRAcom8z8AAACjSURBVChTnZHtDsIgDEULhW0Ko+//ttLb69AYE+P51R4+Wor8jdZaMuOLdHRzzoMi0LBOV7rJPijBRiv6ps14IjXmT87wO1PQ8ywVxU8qpye5mw3XSuWENvN3FBjw1FanrzBe+9JoFT5Le9HYnzxoIrel4wXoZy4sjX7kQNyWZv8SQ1vDmIVAZk5GCS2y0YBxzfPr/Cf8grHz7oXWreiH/Q2RBxLzEDnF6jM5AAAAAElFTkSuQmCC",
      "alt": "Badge Tick1 with solid fill"
    }
  })])])]), _c('td', {
    staticStyle: {
      "width": "10%",
      "border-top": "none",
      "border-left": "none",
      "border-bottom": "solid #D9D9D9 1.0pt",
      "border-right": "solid #D9D9D9 1.0pt",
      "padding": "0in 5.4pt 0in 5.4pt"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('span', [_c('img', {
    attrs: {
      "width": "16",
      "src": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAZCAMAAADt/kXiAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAwUExURQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAFo/HAsAAAAPdFJOUwAQIDBAUGBwgI+fv8/f76R8WAwAAAAJcEhZcwAAFxEAABcRAcom8z8AAACjSURBVChTnZHtDsIgDEULhW0Ko+//ttLb69AYE+P51R4+Wor8jdZaMuOLdHRzzoMi0LBOV7rJPijBRiv6ps14IjXmT87wO1PQ8ywVxU8qpye5mw3XSuWENvN3FBjw1FanrzBe+9JoFT5Le9HYnzxoIrel4wXoZy4sjX7kQNyWZv8SQ1vDmIVAZk5GCS2y0YBxzfPr/Cf8grHz7oXWreiH/Q2RBxLzEDnF6jM5AAAAAElFTkSuQmCC",
      "alt": "Badge Tick1 with solid fill"
    }
  })])])])]), _c('tr', [_c('td', {
    staticStyle: {
      "width": "10%",
      "border-right": "1pt solid rgb(217, 217, 217)",
      "border-bottom": "1pt solid rgb(217, 217, 217)",
      "border-left": "1pt solid rgb(217, 217, 217)",
      "border-image": "initial",
      "border-top": "none",
      "background": "white",
      "padding": "0in 5.4pt",
      "vertical-align": "top"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em"
    }
  }, [_c('span', {
    staticStyle: {
      "color": "black"
    }
  }, [_vm._v("Guided NIST SP 800-171")])])]), _c('td', {
    staticStyle: {
      "width": "10%",
      "border-top": "none",
      "border-left": "none",
      "border-bottom": "solid #D9D9D9 1.0pt",
      "border-right": "solid #D9D9D9 1.0pt",
      "padding": "0in 5.4pt 0in 5.4pt"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('span', [_c('img', {
    attrs: {
      "width": "16",
      "src": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAZCAMAAADt/kXiAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAwUExURQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAFo/HAsAAAAPdFJOUwAQIDBAUGBwgI+fv8/f76R8WAwAAAAJcEhZcwAAFxEAABcRAcom8z8AAACjSURBVChTnZHtDsIgDEULhW0Ko+//ttLb69AYE+P51R4+Wor8jdZaMuOLdHRzzoMi0LBOV7rJPijBRiv6ps14IjXmT87wO1PQ8ywVxU8qpye5mw3XSuWENvN3FBjw1FanrzBe+9JoFT5Le9HYnzxoIrel4wXoZy4sjX7kQNyWZv8SQ1vDmIVAZk5GCS2y0YBxzfPr/Cf8grHz7oXWreiH/Q2RBxLzEDnF6jM5AAAAAElFTkSuQmCC",
      "alt": "Badge Tick1 with solid fill"
    }
  })])])]), _c('td', {
    staticStyle: {
      "width": "20%",
      "border-top": "none",
      "border-left": "none",
      "border-bottom": "solid #D9D9D9 1.0pt",
      "border-right": "solid #D9D9D9 1.0pt",
      "padding": "0in 5.4pt 0in 5.4pt"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('span', [_c('img', {
    attrs: {
      "width": "16",
      "src": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAZCAMAAADt/kXiAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAwUExURQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAFo/HAsAAAAPdFJOUwAQIDBAUGBwgI+fv8/f76R8WAwAAAAJcEhZcwAAFxEAABcRAcom8z8AAACjSURBVChTnZHtDsIgDEULhW0Ko+//ttLb69AYE+P51R4+Wor8jdZaMuOLdHRzzoMi0LBOV7rJPijBRiv6ps14IjXmT87wO1PQ8ywVxU8qpye5mw3XSuWENvN3FBjw1FanrzBe+9JoFT5Le9HYnzxoIrel4wXoZy4sjX7kQNyWZv8SQ1vDmIVAZk5GCS2y0YBxzfPr/Cf8grHz7oXWreiH/Q2RBxLzEDnF6jM5AAAAAElFTkSuQmCC",
      "alt": "Badge Tick1 with solid fill"
    }
  })])])]), _c('td', {
    staticStyle: {
      "width": "10%",
      "border-top": "none",
      "border-left": "none",
      "border-bottom": "solid #D9D9D9 1.0pt",
      "border-right": "solid #D9D9D9 1.0pt",
      "padding": "0in 5.4pt 0in 5.4pt"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('span', [_c('img', {
    attrs: {
      "width": "16",
      "src": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAZCAMAAADt/kXiAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAwUExURQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAFo/HAsAAAAPdFJOUwAQIDBAUGBwgI+fv8/f76R8WAwAAAAJcEhZcwAAFxEAABcRAcom8z8AAACjSURBVChTnZHtDsIgDEULhW0Ko+//ttLb69AYE+P51R4+Wor8jdZaMuOLdHRzzoMi0LBOV7rJPijBRiv6ps14IjXmT87wO1PQ8ywVxU8qpye5mw3XSuWENvN3FBjw1FanrzBe+9JoFT5Le9HYnzxoIrel4wXoZy4sjX7kQNyWZv8SQ1vDmIVAZk5GCS2y0YBxzfPr/Cf8grHz7oXWreiH/Q2RBxLzEDnF6jM5AAAAAElFTkSuQmCC",
      "alt": "Badge Tick1 with solid fill"
    }
  })])])])]), _c('tr', [_c('td', {
    staticStyle: {
      "width": "10%",
      "border-right": "1pt solid rgb(217, 217, 217)",
      "border-bottom": "1pt solid rgb(217, 217, 217)",
      "border-left": "1pt solid rgb(217, 217, 217)",
      "border-image": "initial",
      "border-top": "none",
      "background": "white",
      "padding": "0in 5.4pt",
      "vertical-align": "top"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em"
    }
  }, [_c('span', {
    staticStyle: {
      "color": "black"
    }
  }, [_vm._v("Encite Learning Management System")])])]), _c('td', {
    staticStyle: {
      "width": "10%",
      "border-top": "none",
      "border-left": "none",
      "border-bottom": "solid #D9D9D9 1.0pt",
      "border-right": "solid #D9D9D9 1.0pt",
      "padding": "0in 5.4pt 0in 5.4pt"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('span', [_c('img', {
    attrs: {
      "width": "16",
      "src": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAZCAMAAADt/kXiAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAwUExURQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAFo/HAsAAAAPdFJOUwAQIDBAUGBwgI+fv8/f76R8WAwAAAAJcEhZcwAAFxEAABcRAcom8z8AAACjSURBVChTnZHtDsIgDEULhW0Ko+//ttLb69AYE+P51R4+Wor8jdZaMuOLdHRzzoMi0LBOV7rJPijBRiv6ps14IjXmT87wO1PQ8ywVxU8qpye5mw3XSuWENvN3FBjw1FanrzBe+9JoFT5Le9HYnzxoIrel4wXoZy4sjX7kQNyWZv8SQ1vDmIVAZk5GCS2y0YBxzfPr/Cf8grHz7oXWreiH/Q2RBxLzEDnF6jM5AAAAAElFTkSuQmCC",
      "alt": "Badge Tick1 with solid fill"
    }
  })])])]), _c('td', {
    staticStyle: {
      "width": "20%",
      "border-top": "none",
      "border-left": "none",
      "border-bottom": "solid #D9D9D9 1.0pt",
      "border-right": "solid #D9D9D9 1.0pt",
      "padding": "0in 5.4pt 0in 5.4pt"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('span', [_c('img', {
    attrs: {
      "width": "16",
      "src": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAZCAMAAADt/kXiAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAwUExURQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAFo/HAsAAAAPdFJOUwAQIDBAUGBwgI+fv8/f76R8WAwAAAAJcEhZcwAAFxEAABcRAcom8z8AAACjSURBVChTnZHtDsIgDEULhW0Ko+//ttLb69AYE+P51R4+Wor8jdZaMuOLdHRzzoMi0LBOV7rJPijBRiv6ps14IjXmT87wO1PQ8ywVxU8qpye5mw3XSuWENvN3FBjw1FanrzBe+9JoFT5Le9HYnzxoIrel4wXoZy4sjX7kQNyWZv8SQ1vDmIVAZk5GCS2y0YBxzfPr/Cf8grHz7oXWreiH/Q2RBxLzEDnF6jM5AAAAAElFTkSuQmCC",
      "alt": "Badge Tick1 with solid fill"
    }
  })])])]), _c('td', {
    staticStyle: {
      "width": "10%",
      "border-top": "none",
      "border-left": "none",
      "border-bottom": "solid #D9D9D9 1.0pt",
      "border-right": "solid #D9D9D9 1.0pt",
      "padding": "0in 5.4pt 0in 5.4pt"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('span', [_c('img', {
    attrs: {
      "width": "16",
      "src": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAZCAMAAADt/kXiAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAwUExURQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAFo/HAsAAAAPdFJOUwAQIDBAUGBwgI+fv8/f76R8WAwAAAAJcEhZcwAAFxEAABcRAcom8z8AAACjSURBVChTnZHtDsIgDEULhW0Ko+//ttLb69AYE+P51R4+Wor8jdZaMuOLdHRzzoMi0LBOV7rJPijBRiv6ps14IjXmT87wO1PQ8ywVxU8qpye5mw3XSuWENvN3FBjw1FanrzBe+9JoFT5Le9HYnzxoIrel4wXoZy4sjX7kQNyWZv8SQ1vDmIVAZk5GCS2y0YBxzfPr/Cf8grHz7oXWreiH/Q2RBxLzEDnF6jM5AAAAAElFTkSuQmCC",
      "alt": "Badge Tick1 with solid fill"
    }
  })])])])]), _c('tr', [_c('td', {
    staticStyle: {
      "width": "10%",
      "border-right": "1pt solid rgb(217, 217, 217)",
      "border-bottom": "1pt solid rgb(217, 217, 217)",
      "border-left": "1pt solid rgb(217, 217, 217)",
      "border-image": "initial",
      "border-top": "none",
      "background": "white",
      "padding": "0in 5.4pt",
      "vertical-align": "top"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em"
    }
  }, [_c('span', {
    staticStyle: {
      "color": "black"
    }
  }, [_vm._v("Embedded Progress and Reporting Analytics")])])]), _c('td', {
    staticStyle: {
      "width": "10%",
      "border-top": "none",
      "border-left": "none",
      "border-bottom": "solid #D9D9D9 1.0pt",
      "border-right": "solid #D9D9D9 1.0pt",
      "padding": "0in 5.4pt 0in 5.4pt"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('span', [_vm._v("$75/User/Month")])])]), _c('td', {
    staticStyle: {
      "width": "20%",
      "border-top": "none",
      "border-left": "none",
      "border-bottom": "solid #D9D9D9 1.0pt",
      "border-right": "solid #D9D9D9 1.0pt",
      "padding": "0in 5.4pt 0in 5.4pt"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('span', [_c('img', {
    attrs: {
      "width": "16",
      "src": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAZCAMAAADt/kXiAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAwUExURQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAFo/HAsAAAAPdFJOUwAQIDBAUGBwgI+fv8/f76R8WAwAAAAJcEhZcwAAFxEAABcRAcom8z8AAACjSURBVChTnZHtDsIgDEULhW0Ko+//ttLb69AYE+P51R4+Wor8jdZaMuOLdHRzzoMi0LBOV7rJPijBRiv6ps14IjXmT87wO1PQ8ywVxU8qpye5mw3XSuWENvN3FBjw1FanrzBe+9JoFT5Le9HYnzxoIrel4wXoZy4sjX7kQNyWZv8SQ1vDmIVAZk5GCS2y0YBxzfPr/Cf8grHz7oXWreiH/Q2RBxLzEDnF6jM5AAAAAElFTkSuQmCC",
      "alt": "Badge Tick1 with solid fill"
    }
  })])])]), _c('td', {
    staticStyle: {
      "width": "10%",
      "border-top": "none",
      "border-left": "none",
      "border-bottom": "solid #D9D9D9 1.0pt",
      "border-right": "solid #D9D9D9 1.0pt",
      "padding": "0in 5.4pt 0in 5.4pt"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('span', [_c('img', {
    attrs: {
      "width": "16",
      "src": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAZCAMAAADt/kXiAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAwUExURQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAFo/HAsAAAAPdFJOUwAQIDBAUGBwgI+fv8/f76R8WAwAAAAJcEhZcwAAFxEAABcRAcom8z8AAACjSURBVChTnZHtDsIgDEULhW0Ko+//ttLb69AYE+P51R4+Wor8jdZaMuOLdHRzzoMi0LBOV7rJPijBRiv6ps14IjXmT87wO1PQ8ywVxU8qpye5mw3XSuWENvN3FBjw1FanrzBe+9JoFT5Le9HYnzxoIrel4wXoZy4sjX7kQNyWZv8SQ1vDmIVAZk5GCS2y0YBxzfPr/Cf8grHz7oXWreiH/Q2RBxLzEDnF6jM5AAAAAElFTkSuQmCC",
      "alt": "Badge Tick1 with solid fill"
    }
  })])])])]), _c('tr', [_c('td', {
    staticStyle: {
      "width": "10%",
      "border-right": "1pt solid rgb(217, 217, 217)",
      "border-bottom": "1pt solid rgb(217, 217, 217)",
      "border-left": "1pt solid rgb(217, 217, 217)",
      "border-image": "initial",
      "border-top": "none",
      "background": "white",
      "padding": "0in 5.4pt",
      "vertical-align": "top"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em"
    }
  }, [_c('span', {
    staticStyle: {
      "color": "black"
    }
  }, [_vm._v("Access to SCRM Community of Interest")])])]), _c('td', {
    staticStyle: {
      "width": "10%",
      "border-top": "none",
      "border-left": "none",
      "border-bottom": "solid #D9D9D9 1.0pt",
      "border-right": "solid #D9D9D9 1.0pt",
      "padding": "0in 5.4pt 0in 5.4pt"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('span', [_vm._v("$35/User/Month")])])]), _c('td', {
    staticStyle: {
      "width": "20%",
      "border-top": "none",
      "border-left": "none",
      "border-bottom": "solid #D9D9D9 1.0pt",
      "border-right": "solid #D9D9D9 1.0pt",
      "padding": "0in 5.4pt 0in 5.4pt"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('span', [_c('img', {
    attrs: {
      "width": "16",
      "src": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAZCAMAAADt/kXiAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAwUExURQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAFo/HAsAAAAPdFJOUwAQIDBAUGBwgI+fv8/f76R8WAwAAAAJcEhZcwAAFxEAABcRAcom8z8AAACjSURBVChTnZHtDsIgDEULhW0Ko+//ttLb69AYE+P51R4+Wor8jdZaMuOLdHRzzoMi0LBOV7rJPijBRiv6ps14IjXmT87wO1PQ8ywVxU8qpye5mw3XSuWENvN3FBjw1FanrzBe+9JoFT5Le9HYnzxoIrel4wXoZy4sjX7kQNyWZv8SQ1vDmIVAZk5GCS2y0YBxzfPr/Cf8grHz7oXWreiH/Q2RBxLzEDnF6jM5AAAAAElFTkSuQmCC",
      "alt": "Badge Tick1 with solid fill"
    }
  })])])]), _c('td', {
    staticStyle: {
      "width": "10%",
      "border-top": "none",
      "border-left": "none",
      "border-bottom": "solid #D9D9D9 1.0pt",
      "border-right": "solid #D9D9D9 1.0pt",
      "padding": "0in 5.4pt 0in 5.4pt"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('span', [_c('img', {
    attrs: {
      "width": "16",
      "src": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAZCAMAAADt/kXiAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAwUExURQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAFo/HAsAAAAPdFJOUwAQIDBAUGBwgI+fv8/f76R8WAwAAAAJcEhZcwAAFxEAABcRAcom8z8AAACjSURBVChTnZHtDsIgDEULhW0Ko+//ttLb69AYE+P51R4+Wor8jdZaMuOLdHRzzoMi0LBOV7rJPijBRiv6ps14IjXmT87wO1PQ8ywVxU8qpye5mw3XSuWENvN3FBjw1FanrzBe+9JoFT5Le9HYnzxoIrel4wXoZy4sjX7kQNyWZv8SQ1vDmIVAZk5GCS2y0YBxzfPr/Cf8grHz7oXWreiH/Q2RBxLzEDnF6jM5AAAAAElFTkSuQmCC",
      "alt": "Badge Tick1 with solid fill"
    }
  })])])])]), _c('tr', [_c('td', {
    staticStyle: {
      "width": "10%",
      "border-right": "1pt solid rgb(217, 217, 217)",
      "border-bottom": "1pt solid rgb(217, 217, 217)",
      "border-left": "1pt solid rgb(217, 217, 217)",
      "border-image": "initial",
      "border-top": "none",
      "background": "white",
      "padding": "0in 5.4pt",
      "vertical-align": "top"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em"
    }
  }, [_c('span', {
    staticStyle: {
      "color": "black"
    }
  }, [_vm._v("Hygiene and Readiness Checks")])])]), _c('td', {
    staticStyle: {
      "width": "10%",
      "border-top": "none",
      "border-left": "none",
      "border-bottom": "solid #D9D9D9 1.0pt",
      "border-right": "solid #D9D9D9 1.0pt",
      "padding": "0in 5.4pt 0in 5.4pt"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('span', [_vm._v("$50/User/Month")])])]), _c('td', {
    staticStyle: {
      "width": "20%",
      "border-top": "none",
      "border-left": "none",
      "border-bottom": "solid #D9D9D9 1.0pt",
      "border-right": "solid #D9D9D9 1.0pt",
      "padding": "0in 5.4pt 0in 5.4pt"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('span', [_c('img', {
    attrs: {
      "width": "16",
      "src": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAZCAMAAADt/kXiAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAwUExURQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAFo/HAsAAAAPdFJOUwAQIDBAUGBwgI+fv8/f76R8WAwAAAAJcEhZcwAAFxEAABcRAcom8z8AAACjSURBVChTnZHtDsIgDEULhW0Ko+//ttLb69AYE+P51R4+Wor8jdZaMuOLdHRzzoMi0LBOV7rJPijBRiv6ps14IjXmT87wO1PQ8ywVxU8qpye5mw3XSuWENvN3FBjw1FanrzBe+9JoFT5Le9HYnzxoIrel4wXoZy4sjX7kQNyWZv8SQ1vDmIVAZk5GCS2y0YBxzfPr/Cf8grHz7oXWreiH/Q2RBxLzEDnF6jM5AAAAAElFTkSuQmCC",
      "alt": "Badge Tick1 with solid fill"
    }
  })])])]), _c('td', {
    staticStyle: {
      "width": "10%",
      "border-top": "none",
      "border-left": "none",
      "border-bottom": "solid #D9D9D9 1.0pt",
      "border-right": "solid #D9D9D9 1.0pt",
      "padding": "0in 5.4pt 0in 5.4pt"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('span', [_c('img', {
    attrs: {
      "width": "16",
      "src": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAZCAMAAADt/kXiAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAwUExURQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAFo/HAsAAAAPdFJOUwAQIDBAUGBwgI+fv8/f76R8WAwAAAAJcEhZcwAAFxEAABcRAcom8z8AAACjSURBVChTnZHtDsIgDEULhW0Ko+//ttLb69AYE+P51R4+Wor8jdZaMuOLdHRzzoMi0LBOV7rJPijBRiv6ps14IjXmT87wO1PQ8ywVxU8qpye5mw3XSuWENvN3FBjw1FanrzBe+9JoFT5Le9HYnzxoIrel4wXoZy4sjX7kQNyWZv8SQ1vDmIVAZk5GCS2y0YBxzfPr/Cf8grHz7oXWreiH/Q2RBxLzEDnF6jM5AAAAAElFTkSuQmCC",
      "alt": "Badge Tick1 with solid fill"
    }
  })])])])]), _c('tr', [_c('td', {
    staticStyle: {
      "width": "10%",
      "border-right": "1pt solid rgb(217, 217, 217)",
      "border-bottom": "1pt solid rgb(217, 217, 217)",
      "border-left": "1pt solid rgb(217, 217, 217)",
      "border-image": "initial",
      "border-top": "none",
      "background": "white",
      "padding": "0in 5.4pt",
      "vertical-align": "top"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em"
    }
  }, [_c('span', {
    staticStyle: {
      "color": "black"
    }
  }, [_vm._v("Virtual Cyber-range")])])]), _c('td', {
    staticStyle: {
      "width": "10%",
      "border-top": "none",
      "border-left": "none",
      "border-bottom": "solid #D9D9D9 1.0pt",
      "border-right": "solid #D9D9D9 1.0pt",
      "padding": "0in 5.4pt 0in 5.4pt"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('span', [_vm._v("$120/User/Month")])])]), _c('td', {
    staticStyle: {
      "width": "20%",
      "border-top": "none",
      "border-left": "none",
      "border-bottom": "solid #D9D9D9 1.0pt",
      "border-right": "solid #D9D9D9 1.0pt",
      "padding": "0in 5.4pt 0in 5.4pt"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('span', [_c('img', {
    attrs: {
      "width": "16",
      "src": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAZCAMAAADt/kXiAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAwUExURQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAFo/HAsAAAAPdFJOUwAQIDBAUGBwgI+fv8/f76R8WAwAAAAJcEhZcwAAFxEAABcRAcom8z8AAACjSURBVChTnZHtDsIgDEULhW0Ko+//ttLb69AYE+P51R4+Wor8jdZaMuOLdHRzzoMi0LBOV7rJPijBRiv6ps14IjXmT87wO1PQ8ywVxU8qpye5mw3XSuWENvN3FBjw1FanrzBe+9JoFT5Le9HYnzxoIrel4wXoZy4sjX7kQNyWZv8SQ1vDmIVAZk5GCS2y0YBxzfPr/Cf8grHz7oXWreiH/Q2RBxLzEDnF6jM5AAAAAElFTkSuQmCC",
      "alt": "Badge Tick1 with solid fill"
    }
  })])])]), _c('td', {
    staticStyle: {
      "width": "10%",
      "border-top": "none",
      "border-left": "none",
      "border-bottom": "solid #D9D9D9 1.0pt",
      "border-right": "solid #D9D9D9 1.0pt",
      "padding": "0in 5.4pt 0in 5.4pt"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('span', [_c('img', {
    attrs: {
      "width": "16",
      "src": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAZCAMAAADt/kXiAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAwUExURQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAFo/HAsAAAAPdFJOUwAQIDBAUGBwgI+fv8/f76R8WAwAAAAJcEhZcwAAFxEAABcRAcom8z8AAACjSURBVChTnZHtDsIgDEULhW0Ko+//ttLb69AYE+P51R4+Wor8jdZaMuOLdHRzzoMi0LBOV7rJPijBRiv6ps14IjXmT87wO1PQ8ywVxU8qpye5mw3XSuWENvN3FBjw1FanrzBe+9JoFT5Le9HYnzxoIrel4wXoZy4sjX7kQNyWZv8SQ1vDmIVAZk5GCS2y0YBxzfPr/Cf8grHz7oXWreiH/Q2RBxLzEDnF6jM5AAAAAElFTkSuQmCC",
      "alt": "Badge Tick1 with solid fill"
    }
  })])])])])])])]), _c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "8.0pt",
      "margin-left": "0in",
      "line-height": "107%",
      "font-size": "1em"
    }
  }, [_c('span', {
    staticStyle: {
      "font-size": "19px",
      "line-height": "107%"
    }
  }, [_vm._v(" ")])]), _c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "8.0pt",
      "margin-left": "0in",
      "line-height": "107%",
      "font-size": "1em"
    }
  }, [_c('u', [_c('span', {
    staticStyle: {
      "font-size": "19px",
      "line-height": "107%"
    }
  }, [_vm._v("Standard Support and Premium support")])])]), _c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "8.0pt",
      "margin-left": "0in",
      "line-height": "107%",
      "font-size": "1em"
    }
  }, [_c('strong', [_c('span', [_vm._v("Standard support")])]), _c('span', [_vm._v(" is included with each license in the basic and plus options.  Every license includes three-day response time, self-paced assessments, hosted webinars, and access to the information portal.")])]), _c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "8.0pt",
      "margin-left": "0in",
      "line-height": "107%",
      "font-size": "1em"
    }
  }, [_c('strong', [_c('span', [_vm._v("Premium support")])]), _c('span', [_vm._v(" is offered at an additional fee and will have twenty-four-hour response time, expert coaching and support, and 24/7 access to the community interest information sharing.")])]), _c('p', [_c('span', {
    staticStyle: {
      "font-size": "1em",
      "line-height": "107%"
    }
  }, [_c('br'), _vm._v(" ")])]), _c('h1', {
    staticClass: "text-center"
  }, [_vm._v("Project Krome")]), _c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "8.0pt",
      "margin-left": "0in",
      "line-height": "107%",
      "font-size": "1em"
    }
  }, [_c('span', [_vm._v("Project Krome is an enterprise content platform that powers organizations with industrialized cyber tools and techniques.   Project Krome is a comprehensive, cost-effective enterprise-wide platform for cybersecurity analytics, business intelligence, zero trust cyber security tools with trusted artificial intelligence. ")])]), _c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "8.0pt",
      "margin-left": "0in",
      "line-height": "107%",
      "font-size": "1em"
    }
  }, [_c('u', [_c('span', {
    staticStyle: {
      "font-size": "19px",
      "line-height": "107%"
    }
  }, [_vm._v("Compare Editions and Top Features")])])]), _c('div', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "8.0pt",
      "margin-left": "0in",
      "line-height": "107%",
      "font-size": "1em"
    },
    attrs: {
      "align": "center"
    }
  }, [_c('table', {
    staticStyle: {
      "width": "70%",
      "border-collapse": "collapse",
      "border": "none"
    }
  }, [_c('tbody', [_c('tr', [_c('td', {
    staticStyle: {
      "width": "13%",
      "border-top": "none",
      "border-right": "none",
      "border-left": "none",
      "border-image": "initial",
      "border-bottom": "1pt solid rgb(217, 217, 217)",
      "padding": "0in 5.4pt",
      "vertical-align": "top"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em"
    }
  }, [_c('strong', [_c('span', {
    staticStyle: {
      "color": "white"
    }
  }, [_vm._v(" ")])])])]), _c('td', {
    staticStyle: {
      "width": "15%",
      "border-top": "1pt solid rgb(68, 114, 196)",
      "border-left": "none",
      "border-bottom": "1pt solid rgb(217, 217, 217)",
      "border-right": "none",
      "background": "rgb(68, 114, 196)",
      "padding": "0in 5.4pt",
      "vertical-align": "top"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('strong', [_c('span', {
    staticStyle: {
      "color": "white"
    }
  }, [_vm._v("Project Krome Basic")])])]), _c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('strong', [_c('span', {
    staticStyle: {
      "color": "white"
    }
  }, [_vm._v("$1100/User/Month")])])]), _c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('strong', [_c('span', {
    staticStyle: {
      "color": "white"
    }
  }, [_vm._v("(Billed annually)")])])])]), _c('td', {
    staticStyle: {
      "width": "17%",
      "border-top": "1pt solid rgb(68, 114, 196)",
      "border-left": "none",
      "border-bottom": "1pt solid rgb(217, 217, 217)",
      "border-right": "none",
      "background": "rgb(68, 114, 196)",
      "padding": "0in 5.4pt",
      "vertical-align": "top"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('strong', [_c('span', {
    staticStyle: {
      "color": "white"
    }
  }, [_vm._v("Project Krome Plus")])])]), _c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('strong', [_c('span', {
    staticStyle: {
      "color": "white"
    }
  }, [_vm._v("$1700/User/Month")])])]), _c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('strong', [_c('span', {
    staticStyle: {
      "color": "white"
    }
  }, [_vm._v("(Billed annually)")])])])]), _c('td', {
    staticStyle: {
      "width": "10%",
      "border-top": "1pt solid rgb(68, 114, 196)",
      "border-left": "none",
      "border-bottom": "1pt solid rgb(217, 217, 217)",
      "border-right": "1pt solid rgb(68, 114, 196)",
      "background": "rgb(68, 114, 196)",
      "padding": "0in 5.4pt",
      "vertical-align": "top"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('strong', [_c('span', {
    staticStyle: {
      "color": "white"
    }
  }, [_vm._v("Project Krome Plus")])])]), _c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('strong', [_c('span', {
    staticStyle: {
      "color": "white"
    }
  }, [_vm._v("$7000/Enterprise/Month")])])]), _c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('strong', [_c('span', {
    staticStyle: {
      "color": "white"
    }
  }, [_vm._v("(Billed annually)")])])])])]), _c('tr', [_c('td', {
    staticStyle: {
      "width": "13%",
      "border-right": "1pt solid rgb(217, 217, 217)",
      "border-bottom": "1pt solid rgb(217, 217, 217)",
      "border-left": "1pt solid rgb(217, 217, 217)",
      "border-image": "initial",
      "border-top": "none",
      "background": "white",
      "padding": "0in 5.4pt",
      "vertical-align": "top"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em"
    }
  }, [_c('span', {
    staticStyle: {
      "color": "black"
    }
  }, [_vm._v("Real-time Computer Vision Systems")])])]), _c('td', {
    staticStyle: {
      "width": "15%",
      "border-top": "none",
      "border-left": "none",
      "border-bottom": "solid #D9D9D9 1.0pt",
      "border-right": "solid #D9D9D9 1.0pt",
      "padding": "0in 5.4pt 0in 5.4pt"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('span', [_c('img', {
    attrs: {
      "width": "16",
      "src": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAZCAMAAADt/kXiAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAwUExURQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAFo/HAsAAAAPdFJOUwAQIDBAUGBwgI+fv8/f76R8WAwAAAAJcEhZcwAAFxEAABcRAcom8z8AAACjSURBVChTnZHtDsIgDEULhW0Ko+//ttLb69AYE+P51R4+Wor8jdZaMuOLdHRzzoMi0LBOV7rJPijBRiv6ps14IjXmT87wO1PQ8ywVxU8qpye5mw3XSuWENvN3FBjw1FanrzBe+9JoFT5Le9HYnzxoIrel4wXoZy4sjX7kQNyWZv8SQ1vDmIVAZk5GCS2y0YBxzfPr/Cf8grHz7oXWreiH/Q2RBxLzEDnF6jM5AAAAAElFTkSuQmCC",
      "alt": "Badge Tick1 with solid fill"
    }
  })])])]), _c('td', {
    staticStyle: {
      "width": "17%",
      "border-top": "none",
      "border-left": "none",
      "border-bottom": "solid #D9D9D9 1.0pt",
      "border-right": "solid #D9D9D9 1.0pt",
      "padding": "0in 5.4pt 0in 5.4pt"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('span', [_c('img', {
    attrs: {
      "width": "16",
      "src": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAZCAMAAADt/kXiAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAwUExURQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAFo/HAsAAAAPdFJOUwAQIDBAUGBwgI+fv8/f76R8WAwAAAAJcEhZcwAAFxEAABcRAcom8z8AAACjSURBVChTnZHtDsIgDEULhW0Ko+//ttLb69AYE+P51R4+Wor8jdZaMuOLdHRzzoMi0LBOV7rJPijBRiv6ps14IjXmT87wO1PQ8ywVxU8qpye5mw3XSuWENvN3FBjw1FanrzBe+9JoFT5Le9HYnzxoIrel4wXoZy4sjX7kQNyWZv8SQ1vDmIVAZk5GCS2y0YBxzfPr/Cf8grHz7oXWreiH/Q2RBxLzEDnF6jM5AAAAAElFTkSuQmCC",
      "alt": "Badge Tick1 with solid fill"
    }
  })])])]), _c('td', {
    staticStyle: {
      "width": "20%",
      "border-top": "none",
      "border-left": "none",
      "border-bottom": "solid #D9D9D9 1.0pt",
      "border-right": "solid #D9D9D9 1.0pt",
      "padding": "0in 5.4pt 0in 5.4pt"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('span', [_c('img', {
    attrs: {
      "width": "16",
      "src": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAZCAMAAADt/kXiAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAwUExURQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAFo/HAsAAAAPdFJOUwAQIDBAUGBwgI+fv8/f76R8WAwAAAAJcEhZcwAAFxEAABcRAcom8z8AAACjSURBVChTnZHtDsIgDEULhW0Ko+//ttLb69AYE+P51R4+Wor8jdZaMuOLdHRzzoMi0LBOV7rJPijBRiv6ps14IjXmT87wO1PQ8ywVxU8qpye5mw3XSuWENvN3FBjw1FanrzBe+9JoFT5Le9HYnzxoIrel4wXoZy4sjX7kQNyWZv8SQ1vDmIVAZk5GCS2y0YBxzfPr/Cf8grHz7oXWreiH/Q2RBxLzEDnF6jM5AAAAAElFTkSuQmCC",
      "alt": "Badge Tick1 with solid fill"
    }
  })])])])]), _c('tr', [_c('td', {
    staticStyle: {
      "width": "13%",
      "border-right": "1pt solid rgb(217, 217, 217)",
      "border-bottom": "1pt solid rgb(217, 217, 217)",
      "border-left": "1pt solid rgb(217, 217, 217)",
      "border-image": "initial",
      "border-top": "none",
      "background": "white",
      "padding": "0in 5.4pt",
      "vertical-align": "top"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em"
    }
  }, [_c('span', {
    staticStyle: {
      "color": "black"
    }
  }, [_vm._v("Application for scheduling hardware resources")])])]), _c('td', {
    staticStyle: {
      "width": "15%",
      "border-top": "none",
      "border-left": "none",
      "border-bottom": "solid #D9D9D9 1.0pt",
      "border-right": "solid #D9D9D9 1.0pt",
      "padding": "0in 5.4pt 0in 5.4pt"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('span', [_vm._v("$85/User/Month")])])]), _c('td', {
    staticStyle: {
      "width": "17%",
      "border-top": "none",
      "border-left": "none",
      "border-bottom": "solid #D9D9D9 1.0pt",
      "border-right": "solid #D9D9D9 1.0pt",
      "padding": "0in 5.4pt 0in 5.4pt"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('span', [_c('img', {
    attrs: {
      "width": "16",
      "src": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAZCAMAAADt/kXiAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAwUExURQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAFo/HAsAAAAPdFJOUwAQIDBAUGBwgI+fv8/f76R8WAwAAAAJcEhZcwAAFxEAABcRAcom8z8AAACjSURBVChTnZHtDsIgDEULhW0Ko+//ttLb69AYE+P51R4+Wor8jdZaMuOLdHRzzoMi0LBOV7rJPijBRiv6ps14IjXmT87wO1PQ8ywVxU8qpye5mw3XSuWENvN3FBjw1FanrzBe+9JoFT5Le9HYnzxoIrel4wXoZy4sjX7kQNyWZv8SQ1vDmIVAZk5GCS2y0YBxzfPr/Cf8grHz7oXWreiH/Q2RBxLzEDnF6jM5AAAAAElFTkSuQmCC",
      "alt": "Badge Tick1 with solid fill"
    }
  })])])]), _c('td', {
    staticStyle: {
      "width": "20%",
      "border-top": "none",
      "border-left": "none",
      "border-bottom": "solid #D9D9D9 1.0pt",
      "border-right": "solid #D9D9D9 1.0pt",
      "padding": "0in 5.4pt 0in 5.4pt"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('span', [_c('img', {
    attrs: {
      "width": "16",
      "src": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAZCAMAAADt/kXiAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAwUExURQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAFo/HAsAAAAPdFJOUwAQIDBAUGBwgI+fv8/f76R8WAwAAAAJcEhZcwAAFxEAABcRAcom8z8AAACjSURBVChTnZHtDsIgDEULhW0Ko+//ttLb69AYE+P51R4+Wor8jdZaMuOLdHRzzoMi0LBOV7rJPijBRiv6ps14IjXmT87wO1PQ8ywVxU8qpye5mw3XSuWENvN3FBjw1FanrzBe+9JoFT5Le9HYnzxoIrel4wXoZy4sjX7kQNyWZv8SQ1vDmIVAZk5GCS2y0YBxzfPr/Cf8grHz7oXWreiH/Q2RBxLzEDnF6jM5AAAAAElFTkSuQmCC",
      "alt": "Badge Tick1 with solid fill"
    }
  })])])])]), _c('tr', [_c('td', {
    staticStyle: {
      "width": "13%",
      "border-right": "1pt solid rgb(217, 217, 217)",
      "border-bottom": "1pt solid rgb(217, 217, 217)",
      "border-left": "1pt solid rgb(217, 217, 217)",
      "border-image": "initial",
      "border-top": "none",
      "background": "white",
      "padding": "0in 5.4pt",
      "vertical-align": "top"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em"
    }
  }, [_c('span', {
    staticStyle: {
      "color": "black"
    }
  }, [_vm._v("Synthetic 2D and 3D data generation systems")])])]), _c('td', {
    staticStyle: {
      "width": "15%",
      "border-top": "none",
      "border-left": "none",
      "border-bottom": "solid #D9D9D9 1.0pt",
      "border-right": "solid #D9D9D9 1.0pt",
      "padding": "0in 5.4pt 0in 5.4pt"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('span', [_c('img', {
    attrs: {
      "width": "16",
      "src": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAZCAMAAADt/kXiAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAwUExURQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAFo/HAsAAAAPdFJOUwAQIDBAUGBwgI+fv8/f76R8WAwAAAAJcEhZcwAAFxEAABcRAcom8z8AAACjSURBVChTnZHtDsIgDEULhW0Ko+//ttLb69AYE+P51R4+Wor8jdZaMuOLdHRzzoMi0LBOV7rJPijBRiv6ps14IjXmT87wO1PQ8ywVxU8qpye5mw3XSuWENvN3FBjw1FanrzBe+9JoFT5Le9HYnzxoIrel4wXoZy4sjX7kQNyWZv8SQ1vDmIVAZk5GCS2y0YBxzfPr/Cf8grHz7oXWreiH/Q2RBxLzEDnF6jM5AAAAAElFTkSuQmCC",
      "alt": "Badge Tick1 with solid fill"
    }
  })])])]), _c('td', {
    staticStyle: {
      "width": "17%",
      "border-top": "none",
      "border-left": "none",
      "border-bottom": "solid #D9D9D9 1.0pt",
      "border-right": "solid #D9D9D9 1.0pt",
      "padding": "0in 5.4pt 0in 5.4pt"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('span', [_c('img', {
    attrs: {
      "width": "16",
      "src": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAZCAMAAADt/kXiAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAwUExURQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAFo/HAsAAAAPdFJOUwAQIDBAUGBwgI+fv8/f76R8WAwAAAAJcEhZcwAAFxEAABcRAcom8z8AAACjSURBVChTnZHtDsIgDEULhW0Ko+//ttLb69AYE+P51R4+Wor8jdZaMuOLdHRzzoMi0LBOV7rJPijBRiv6ps14IjXmT87wO1PQ8ywVxU8qpye5mw3XSuWENvN3FBjw1FanrzBe+9JoFT5Le9HYnzxoIrel4wXoZy4sjX7kQNyWZv8SQ1vDmIVAZk5GCS2y0YBxzfPr/Cf8grHz7oXWreiH/Q2RBxLzEDnF6jM5AAAAAElFTkSuQmCC",
      "alt": "Badge Tick1 with solid fill"
    }
  })])])]), _c('td', {
    staticStyle: {
      "width": "20%",
      "border-top": "none",
      "border-left": "none",
      "border-bottom": "solid #D9D9D9 1.0pt",
      "border-right": "solid #D9D9D9 1.0pt",
      "padding": "0in 5.4pt 0in 5.4pt"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('span', [_c('img', {
    attrs: {
      "width": "16",
      "src": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAZCAMAAADt/kXiAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAwUExURQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAFo/HAsAAAAPdFJOUwAQIDBAUGBwgI+fv8/f76R8WAwAAAAJcEhZcwAAFxEAABcRAcom8z8AAACjSURBVChTnZHtDsIgDEULhW0Ko+//ttLb69AYE+P51R4+Wor8jdZaMuOLdHRzzoMi0LBOV7rJPijBRiv6ps14IjXmT87wO1PQ8ywVxU8qpye5mw3XSuWENvN3FBjw1FanrzBe+9JoFT5Le9HYnzxoIrel4wXoZy4sjX7kQNyWZv8SQ1vDmIVAZk5GCS2y0YBxzfPr/Cf8grHz7oXWreiH/Q2RBxLzEDnF6jM5AAAAAElFTkSuQmCC",
      "alt": "Badge Tick1 with solid fill"
    }
  })])])])]), _c('tr', [_c('td', {
    staticStyle: {
      "width": "13%",
      "border-right": "1pt solid rgb(217, 217, 217)",
      "border-bottom": "1pt solid rgb(217, 217, 217)",
      "border-left": "1pt solid rgb(217, 217, 217)",
      "border-image": "initial",
      "border-top": "none",
      "background": "white",
      "padding": "0in 5.4pt",
      "vertical-align": "top"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em"
    }
  }, [_c('span', {
    staticStyle: {
      "color": "black"
    }
  }, [_vm._v("Polymorphic Encryption System")])])]), _c('td', {
    staticStyle: {
      "width": "15%",
      "border-top": "none",
      "border-left": "none",
      "border-bottom": "solid #D9D9D9 1.0pt",
      "border-right": "solid #D9D9D9 1.0pt",
      "padding": "0in 5.4pt 0in 5.4pt"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('span', [_vm._v("$25/User/Month")])])]), _c('td', {
    staticStyle: {
      "width": "17%",
      "border-top": "none",
      "border-left": "none",
      "border-bottom": "solid #D9D9D9 1.0pt",
      "border-right": "solid #D9D9D9 1.0pt",
      "padding": "0in 5.4pt 0in 5.4pt"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('span', [_c('img', {
    attrs: {
      "width": "16",
      "src": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAZCAMAAADt/kXiAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAwUExURQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAFo/HAsAAAAPdFJOUwAQIDBAUGBwgI+fv8/f76R8WAwAAAAJcEhZcwAAFxEAABcRAcom8z8AAACjSURBVChTnZHtDsIgDEULhW0Ko+//ttLb69AYE+P51R4+Wor8jdZaMuOLdHRzzoMi0LBOV7rJPijBRiv6ps14IjXmT87wO1PQ8ywVxU8qpye5mw3XSuWENvN3FBjw1FanrzBe+9JoFT5Le9HYnzxoIrel4wXoZy4sjX7kQNyWZv8SQ1vDmIVAZk5GCS2y0YBxzfPr/Cf8grHz7oXWreiH/Q2RBxLzEDnF6jM5AAAAAElFTkSuQmCC",
      "alt": "Badge Tick1 with solid fill"
    }
  })])])]), _c('td', {
    staticStyle: {
      "width": "20%",
      "border-top": "none",
      "border-left": "none",
      "border-bottom": "solid #D9D9D9 1.0pt",
      "border-right": "solid #D9D9D9 1.0pt",
      "padding": "0in 5.4pt 0in 5.4pt"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('span', [_c('img', {
    attrs: {
      "width": "16",
      "src": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAZCAMAAADt/kXiAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAwUExURQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAFo/HAsAAAAPdFJOUwAQIDBAUGBwgI+fv8/f76R8WAwAAAAJcEhZcwAAFxEAABcRAcom8z8AAACjSURBVChTnZHtDsIgDEULhW0Ko+//ttLb69AYE+P51R4+Wor8jdZaMuOLdHRzzoMi0LBOV7rJPijBRiv6ps14IjXmT87wO1PQ8ywVxU8qpye5mw3XSuWENvN3FBjw1FanrzBe+9JoFT5Le9HYnzxoIrel4wXoZy4sjX7kQNyWZv8SQ1vDmIVAZk5GCS2y0YBxzfPr/Cf8grHz7oXWreiH/Q2RBxLzEDnF6jM5AAAAAElFTkSuQmCC",
      "alt": "Badge Tick1 with solid fill"
    }
  })])])])]), _c('tr', [_c('td', {
    staticStyle: {
      "width": "13%",
      "border-right": "1pt solid rgb(217, 217, 217)",
      "border-bottom": "1pt solid rgb(217, 217, 217)",
      "border-left": "1pt solid rgb(217, 217, 217)",
      "border-image": "initial",
      "border-top": "none",
      "background": "white",
      "padding": "0in 5.4pt",
      "vertical-align": "top"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em"
    }
  }, [_c('span', {
    staticStyle: {
      "color": "black"
    }
  }, [_vm._v("Precipio Cloud Management ")])])]), _c('td', {
    staticStyle: {
      "width": "15%",
      "border-top": "none",
      "border-left": "none",
      "border-bottom": "solid #D9D9D9 1.0pt",
      "border-right": "solid #D9D9D9 1.0pt",
      "padding": "0in 5.4pt 0in 5.4pt"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('span', [_c('img', {
    attrs: {
      "width": "16",
      "src": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAZCAMAAADt/kXiAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAwUExURQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAFo/HAsAAAAPdFJOUwAQIDBAUGBwgI+fv8/f76R8WAwAAAAJcEhZcwAAFxEAABcRAcom8z8AAACjSURBVChTnZHtDsIgDEULhW0Ko+//ttLb69AYE+P51R4+Wor8jdZaMuOLdHRzzoMi0LBOV7rJPijBRiv6ps14IjXmT87wO1PQ8ywVxU8qpye5mw3XSuWENvN3FBjw1FanrzBe+9JoFT5Le9HYnzxoIrel4wXoZy4sjX7kQNyWZv8SQ1vDmIVAZk5GCS2y0YBxzfPr/Cf8grHz7oXWreiH/Q2RBxLzEDnF6jM5AAAAAElFTkSuQmCC",
      "alt": "Badge Tick1 with solid fill"
    }
  })])])]), _c('td', {
    staticStyle: {
      "width": "17%",
      "border-top": "none",
      "border-left": "none",
      "border-bottom": "solid #D9D9D9 1.0pt",
      "border-right": "solid #D9D9D9 1.0pt",
      "padding": "0in 5.4pt 0in 5.4pt"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('span', [_c('img', {
    attrs: {
      "width": "16",
      "src": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAZCAMAAADt/kXiAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAwUExURQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAFo/HAsAAAAPdFJOUwAQIDBAUGBwgI+fv8/f76R8WAwAAAAJcEhZcwAAFxEAABcRAcom8z8AAACjSURBVChTnZHtDsIgDEULhW0Ko+//ttLb69AYE+P51R4+Wor8jdZaMuOLdHRzzoMi0LBOV7rJPijBRiv6ps14IjXmT87wO1PQ8ywVxU8qpye5mw3XSuWENvN3FBjw1FanrzBe+9JoFT5Le9HYnzxoIrel4wXoZy4sjX7kQNyWZv8SQ1vDmIVAZk5GCS2y0YBxzfPr/Cf8grHz7oXWreiH/Q2RBxLzEDnF6jM5AAAAAElFTkSuQmCC",
      "alt": "Badge Tick1 with solid fill"
    }
  })])])]), _c('td', {
    staticStyle: {
      "width": "20%",
      "border-top": "none",
      "border-left": "none",
      "border-bottom": "solid #D9D9D9 1.0pt",
      "border-right": "solid #D9D9D9 1.0pt",
      "padding": "0in 5.4pt 0in 5.4pt"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('span', [_c('img', {
    attrs: {
      "width": "16",
      "src": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAZCAMAAADt/kXiAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAwUExURQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAFo/HAsAAAAPdFJOUwAQIDBAUGBwgI+fv8/f76R8WAwAAAAJcEhZcwAAFxEAABcRAcom8z8AAACjSURBVChTnZHtDsIgDEULhW0Ko+//ttLb69AYE+P51R4+Wor8jdZaMuOLdHRzzoMi0LBOV7rJPijBRiv6ps14IjXmT87wO1PQ8ywVxU8qpye5mw3XSuWENvN3FBjw1FanrzBe+9JoFT5Le9HYnzxoIrel4wXoZy4sjX7kQNyWZv8SQ1vDmIVAZk5GCS2y0YBxzfPr/Cf8grHz7oXWreiH/Q2RBxLzEDnF6jM5AAAAAElFTkSuQmCC",
      "alt": "Badge Tick1 with solid fill"
    }
  })])])])]), _c('tr', [_c('td', {
    staticStyle: {
      "width": "13%",
      "border-right": "1pt solid rgb(217, 217, 217)",
      "border-bottom": "1pt solid rgb(217, 217, 217)",
      "border-left": "1pt solid rgb(217, 217, 217)",
      "border-image": "initial",
      "border-top": "none",
      "background": "white",
      "padding": "0in 5.4pt",
      "vertical-align": "top"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em"
    }
  }, [_c('span', {
    staticStyle: {
      "color": "black"
    }
  }, [_vm._v("Cloud based HSM")])])]), _c('td', {
    staticStyle: {
      "width": "15%",
      "border-top": "none",
      "border-left": "none",
      "border-bottom": "solid #D9D9D9 1.0pt",
      "border-right": "solid #D9D9D9 1.0pt",
      "padding": "0in 5.4pt 0in 5.4pt"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('span', [_vm._v("$35/User/Month")])])]), _c('td', {
    staticStyle: {
      "width": "17%",
      "border-top": "none",
      "border-left": "none",
      "border-bottom": "solid #D9D9D9 1.0pt",
      "border-right": "solid #D9D9D9 1.0pt",
      "padding": "0in 5.4pt 0in 5.4pt"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('span', [_c('img', {
    attrs: {
      "width": "16",
      "src": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAZCAMAAADt/kXiAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAwUExURQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAFo/HAsAAAAPdFJOUwAQIDBAUGBwgI+fv8/f76R8WAwAAAAJcEhZcwAAFxEAABcRAcom8z8AAACjSURBVChTnZHtDsIgDEULhW0Ko+//ttLb69AYE+P51R4+Wor8jdZaMuOLdHRzzoMi0LBOV7rJPijBRiv6ps14IjXmT87wO1PQ8ywVxU8qpye5mw3XSuWENvN3FBjw1FanrzBe+9JoFT5Le9HYnzxoIrel4wXoZy4sjX7kQNyWZv8SQ1vDmIVAZk5GCS2y0YBxzfPr/Cf8grHz7oXWreiH/Q2RBxLzEDnF6jM5AAAAAElFTkSuQmCC",
      "alt": "Badge Tick1 with solid fill"
    }
  })])])]), _c('td', {
    staticStyle: {
      "width": "20%",
      "border-top": "none",
      "border-left": "none",
      "border-bottom": "solid #D9D9D9 1.0pt",
      "border-right": "solid #D9D9D9 1.0pt",
      "padding": "0in 5.4pt 0in 5.4pt"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('span', [_c('img', {
    attrs: {
      "width": "16",
      "src": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAZCAMAAADt/kXiAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAwUExURQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAFo/HAsAAAAPdFJOUwAQIDBAUGBwgI+fv8/f76R8WAwAAAAJcEhZcwAAFxEAABcRAcom8z8AAACjSURBVChTnZHtDsIgDEULhW0Ko+//ttLb69AYE+P51R4+Wor8jdZaMuOLdHRzzoMi0LBOV7rJPijBRiv6ps14IjXmT87wO1PQ8ywVxU8qpye5mw3XSuWENvN3FBjw1FanrzBe+9JoFT5Le9HYnzxoIrel4wXoZy4sjX7kQNyWZv8SQ1vDmIVAZk5GCS2y0YBxzfPr/Cf8grHz7oXWreiH/Q2RBxLzEDnF6jM5AAAAAElFTkSuQmCC",
      "alt": "Badge Tick1 with solid fill"
    }
  })])])])]), _c('tr', [_c('td', {
    staticStyle: {
      "width": "13%",
      "border-right": "1pt solid rgb(217, 217, 217)",
      "border-bottom": "1pt solid rgb(217, 217, 217)",
      "border-left": "1pt solid rgb(217, 217, 217)",
      "border-image": "initial",
      "border-top": "none",
      "background": "white",
      "padding": "0in 5.4pt",
      "vertical-align": "top"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em"
    }
  }, [_c('span', {
    staticStyle: {
      "color": "black"
    }
  }, [_vm._v("Industrial Security Mapping tools")])])]), _c('td', {
    staticStyle: {
      "width": "15%",
      "border-top": "none",
      "border-left": "none",
      "border-bottom": "solid #D9D9D9 1.0pt",
      "border-right": "solid #D9D9D9 1.0pt",
      "padding": "0in 5.4pt 0in 5.4pt"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('span', [_c('img', {
    attrs: {
      "width": "16",
      "src": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAZCAMAAADt/kXiAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAwUExURQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAFo/HAsAAAAPdFJOUwAQIDBAUGBwgI+fv8/f76R8WAwAAAAJcEhZcwAAFxEAABcRAcom8z8AAACjSURBVChTnZHtDsIgDEULhW0Ko+//ttLb69AYE+P51R4+Wor8jdZaMuOLdHRzzoMi0LBOV7rJPijBRiv6ps14IjXmT87wO1PQ8ywVxU8qpye5mw3XSuWENvN3FBjw1FanrzBe+9JoFT5Le9HYnzxoIrel4wXoZy4sjX7kQNyWZv8SQ1vDmIVAZk5GCS2y0YBxzfPr/Cf8grHz7oXWreiH/Q2RBxLzEDnF6jM5AAAAAElFTkSuQmCC",
      "alt": "Badge Tick1 with solid fill"
    }
  })])])]), _c('td', {
    staticStyle: {
      "width": "17%",
      "border-top": "none",
      "border-left": "none",
      "border-bottom": "solid #D9D9D9 1.0pt",
      "border-right": "solid #D9D9D9 1.0pt",
      "padding": "0in 5.4pt 0in 5.4pt"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('span', [_c('img', {
    attrs: {
      "width": "16",
      "src": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAZCAMAAADt/kXiAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAwUExURQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAFo/HAsAAAAPdFJOUwAQIDBAUGBwgI+fv8/f76R8WAwAAAAJcEhZcwAAFxEAABcRAcom8z8AAACjSURBVChTnZHtDsIgDEULhW0Ko+//ttLb69AYE+P51R4+Wor8jdZaMuOLdHRzzoMi0LBOV7rJPijBRiv6ps14IjXmT87wO1PQ8ywVxU8qpye5mw3XSuWENvN3FBjw1FanrzBe+9JoFT5Le9HYnzxoIrel4wXoZy4sjX7kQNyWZv8SQ1vDmIVAZk5GCS2y0YBxzfPr/Cf8grHz7oXWreiH/Q2RBxLzEDnF6jM5AAAAAElFTkSuQmCC",
      "alt": "Badge Tick1 with solid fill"
    }
  })])])]), _c('td', {
    staticStyle: {
      "width": "20%",
      "border-top": "none",
      "border-left": "none",
      "border-bottom": "solid #D9D9D9 1.0pt",
      "border-right": "solid #D9D9D9 1.0pt",
      "padding": "0in 5.4pt 0in 5.4pt"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('span', [_c('img', {
    attrs: {
      "width": "16",
      "src": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAZCAMAAADt/kXiAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAwUExURQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAFo/HAsAAAAPdFJOUwAQIDBAUGBwgI+fv8/f76R8WAwAAAAJcEhZcwAAFxEAABcRAcom8z8AAACjSURBVChTnZHtDsIgDEULhW0Ko+//ttLb69AYE+P51R4+Wor8jdZaMuOLdHRzzoMi0LBOV7rJPijBRiv6ps14IjXmT87wO1PQ8ywVxU8qpye5mw3XSuWENvN3FBjw1FanrzBe+9JoFT5Le9HYnzxoIrel4wXoZy4sjX7kQNyWZv8SQ1vDmIVAZk5GCS2y0YBxzfPr/Cf8grHz7oXWreiH/Q2RBxLzEDnF6jM5AAAAAElFTkSuQmCC",
      "alt": "Badge Tick1 with solid fill"
    }
  })])])])]), _c('tr', [_c('td', {
    staticStyle: {
      "width": "13%",
      "border-right": "1pt solid rgb(217, 217, 217)",
      "border-bottom": "1pt solid rgb(217, 217, 217)",
      "border-left": "1pt solid rgb(217, 217, 217)",
      "border-image": "initial",
      "border-top": "none",
      "background": "white",
      "padding": "0in 5.4pt",
      "vertical-align": "top"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em"
    }
  }, [_c('span', {
    staticStyle: {
      "color": "black"
    }
  }, [_vm._v("Machine Learning for Additive and 3D shape deformation prediction")])])]), _c('td', {
    staticStyle: {
      "width": "15%",
      "border-top": "none",
      "border-left": "none",
      "border-bottom": "solid #D9D9D9 1.0pt",
      "border-right": "solid #D9D9D9 1.0pt",
      "padding": "0in 5.4pt 0in 5.4pt"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('span', [_c('img', {
    attrs: {
      "width": "16",
      "src": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAZCAMAAADt/kXiAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAwUExURQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAFo/HAsAAAAPdFJOUwAQIDBAUGBwgI+fv8/f76R8WAwAAAAJcEhZcwAAFxEAABcRAcom8z8AAACjSURBVChTnZHtDsIgDEULhW0Ko+//ttLb69AYE+P51R4+Wor8jdZaMuOLdHRzzoMi0LBOV7rJPijBRiv6ps14IjXmT87wO1PQ8ywVxU8qpye5mw3XSuWENvN3FBjw1FanrzBe+9JoFT5Le9HYnzxoIrel4wXoZy4sjX7kQNyWZv8SQ1vDmIVAZk5GCS2y0YBxzfPr/Cf8grHz7oXWreiH/Q2RBxLzEDnF6jM5AAAAAElFTkSuQmCC",
      "alt": "Badge Tick1 with solid fill"
    }
  })])])]), _c('td', {
    staticStyle: {
      "width": "17%",
      "border-top": "none",
      "border-left": "none",
      "border-bottom": "solid #D9D9D9 1.0pt",
      "border-right": "solid #D9D9D9 1.0pt",
      "padding": "0in 5.4pt 0in 5.4pt"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('span', [_c('img', {
    attrs: {
      "width": "16",
      "src": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAZCAMAAADt/kXiAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAwUExURQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAFo/HAsAAAAPdFJOUwAQIDBAUGBwgI+fv8/f76R8WAwAAAAJcEhZcwAAFxEAABcRAcom8z8AAACjSURBVChTnZHtDsIgDEULhW0Ko+//ttLb69AYE+P51R4+Wor8jdZaMuOLdHRzzoMi0LBOV7rJPijBRiv6ps14IjXmT87wO1PQ8ywVxU8qpye5mw3XSuWENvN3FBjw1FanrzBe+9JoFT5Le9HYnzxoIrel4wXoZy4sjX7kQNyWZv8SQ1vDmIVAZk5GCS2y0YBxzfPr/Cf8grHz7oXWreiH/Q2RBxLzEDnF6jM5AAAAAElFTkSuQmCC",
      "alt": "Badge Tick1 with solid fill"
    }
  })])])]), _c('td', {
    staticStyle: {
      "width": "20%",
      "border-top": "none",
      "border-left": "none",
      "border-bottom": "solid #D9D9D9 1.0pt",
      "border-right": "solid #D9D9D9 1.0pt",
      "padding": "0in 5.4pt 0in 5.4pt"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('span', [_c('img', {
    attrs: {
      "width": "16",
      "src": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAZCAMAAADt/kXiAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAwUExURQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAFo/HAsAAAAPdFJOUwAQIDBAUGBwgI+fv8/f76R8WAwAAAAJcEhZcwAAFxEAABcRAcom8z8AAACjSURBVChTnZHtDsIgDEULhW0Ko+//ttLb69AYE+P51R4+Wor8jdZaMuOLdHRzzoMi0LBOV7rJPijBRiv6ps14IjXmT87wO1PQ8ywVxU8qpye5mw3XSuWENvN3FBjw1FanrzBe+9JoFT5Le9HYnzxoIrel4wXoZy4sjX7kQNyWZv8SQ1vDmIVAZk5GCS2y0YBxzfPr/Cf8grHz7oXWreiH/Q2RBxLzEDnF6jM5AAAAAElFTkSuQmCC",
      "alt": "Badge Tick1 with solid fill"
    }
  })])])])]), _c('tr', [_c('td', {
    staticStyle: {
      "width": "13%",
      "border-right": "1pt solid rgb(217, 217, 217)",
      "border-bottom": "1pt solid rgb(217, 217, 217)",
      "border-left": "1pt solid rgb(217, 217, 217)",
      "border-image": "initial",
      "border-top": "none",
      "background": "white",
      "padding": "0in 5.4pt",
      "vertical-align": "top"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em"
    }
  }, [_c('span', {
    staticStyle: {
      "color": "black"
    }
  }, [_vm._v("Digital Twinning Tools")])])]), _c('td', {
    staticStyle: {
      "width": "15%",
      "border-top": "none",
      "border-left": "none",
      "border-bottom": "solid #D9D9D9 1.0pt",
      "border-right": "solid #D9D9D9 1.0pt",
      "padding": "0in 5.4pt 0in 5.4pt"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('span', [_vm._v("$150/User/Month")])])]), _c('td', {
    staticStyle: {
      "width": "17%",
      "border-top": "none",
      "border-left": "none",
      "border-bottom": "solid #D9D9D9 1.0pt",
      "border-right": "solid #D9D9D9 1.0pt",
      "padding": "0in 5.4pt 0in 5.4pt"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('span', [_c('img', {
    attrs: {
      "width": "16",
      "src": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAZCAMAAADt/kXiAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAwUExURQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAFo/HAsAAAAPdFJOUwAQIDBAUGBwgI+fv8/f76R8WAwAAAAJcEhZcwAAFxEAABcRAcom8z8AAACjSURBVChTnZHtDsIgDEULhW0Ko+//ttLb69AYE+P51R4+Wor8jdZaMuOLdHRzzoMi0LBOV7rJPijBRiv6ps14IjXmT87wO1PQ8ywVxU8qpye5mw3XSuWENvN3FBjw1FanrzBe+9JoFT5Le9HYnzxoIrel4wXoZy4sjX7kQNyWZv8SQ1vDmIVAZk5GCS2y0YBxzfPr/Cf8grHz7oXWreiH/Q2RBxLzEDnF6jM5AAAAAElFTkSuQmCC",
      "alt": "Badge Tick1 with solid fill"
    }
  })])])]), _c('td', {
    staticStyle: {
      "width": "20%",
      "border-top": "none",
      "border-left": "none",
      "border-bottom": "solid #D9D9D9 1.0pt",
      "border-right": "solid #D9D9D9 1.0pt",
      "padding": "0in 5.4pt 0in 5.4pt"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('span', [_c('img', {
    attrs: {
      "width": "16",
      "src": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAZCAMAAADt/kXiAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAwUExURQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAFo/HAsAAAAPdFJOUwAQIDBAUGBwgI+fv8/f76R8WAwAAAAJcEhZcwAAFxEAABcRAcom8z8AAACjSURBVChTnZHtDsIgDEULhW0Ko+//ttLb69AYE+P51R4+Wor8jdZaMuOLdHRzzoMi0LBOV7rJPijBRiv6ps14IjXmT87wO1PQ8ywVxU8qpye5mw3XSuWENvN3FBjw1FanrzBe+9JoFT5Le9HYnzxoIrel4wXoZy4sjX7kQNyWZv8SQ1vDmIVAZk5GCS2y0YBxzfPr/Cf8grHz7oXWreiH/Q2RBxLzEDnF6jM5AAAAAElFTkSuQmCC",
      "alt": "Badge Tick1 with solid fill"
    }
  })])])])]), _c('tr', [_c('td', {
    staticStyle: {
      "width": "13%",
      "border-right": "1pt solid rgb(217, 217, 217)",
      "border-bottom": "1pt solid rgb(217, 217, 217)",
      "border-left": "1pt solid rgb(217, 217, 217)",
      "border-image": "initial",
      "border-top": "none",
      "background": "white",
      "padding": "0in 5.4pt",
      "vertical-align": "top"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em"
    }
  }, [_c('span', {
    staticStyle: {
      "color": "black"
    }
  }, [_vm._v("Artificial Attack surface")])])]), _c('td', {
    staticStyle: {
      "width": "15%",
      "border-top": "none",
      "border-left": "none",
      "border-bottom": "solid #D9D9D9 1.0pt",
      "border-right": "solid #D9D9D9 1.0pt",
      "padding": "0in 5.4pt 0in 5.4pt"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('span', [_vm._v("$50/User/Month")])])]), _c('td', {
    staticStyle: {
      "width": "17%",
      "border-top": "none",
      "border-left": "none",
      "border-bottom": "solid #D9D9D9 1.0pt",
      "border-right": "solid #D9D9D9 1.0pt",
      "padding": "0in 5.4pt 0in 5.4pt"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('span', [_c('img', {
    attrs: {
      "width": "16",
      "src": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAZCAMAAADt/kXiAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAwUExURQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAFo/HAsAAAAPdFJOUwAQIDBAUGBwgI+fv8/f76R8WAwAAAAJcEhZcwAAFxEAABcRAcom8z8AAACjSURBVChTnZHtDsIgDEULhW0Ko+//ttLb69AYE+P51R4+Wor8jdZaMuOLdHRzzoMi0LBOV7rJPijBRiv6ps14IjXmT87wO1PQ8ywVxU8qpye5mw3XSuWENvN3FBjw1FanrzBe+9JoFT5Le9HYnzxoIrel4wXoZy4sjX7kQNyWZv8SQ1vDmIVAZk5GCS2y0YBxzfPr/Cf8grHz7oXWreiH/Q2RBxLzEDnF6jM5AAAAAElFTkSuQmCC",
      "alt": "Badge Tick1 with solid fill"
    }
  })])])]), _c('td', {
    staticStyle: {
      "width": "20%",
      "border-top": "none",
      "border-left": "none",
      "border-bottom": "solid #D9D9D9 1.0pt",
      "border-right": "solid #D9D9D9 1.0pt",
      "padding": "0in 5.4pt 0in 5.4pt"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('span', [_c('img', {
    attrs: {
      "width": "16",
      "src": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAZCAMAAADt/kXiAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAwUExURQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAFo/HAsAAAAPdFJOUwAQIDBAUGBwgI+fv8/f76R8WAwAAAAJcEhZcwAAFxEAABcRAcom8z8AAACjSURBVChTnZHtDsIgDEULhW0Ko+//ttLb69AYE+P51R4+Wor8jdZaMuOLdHRzzoMi0LBOV7rJPijBRiv6ps14IjXmT87wO1PQ8ywVxU8qpye5mw3XSuWENvN3FBjw1FanrzBe+9JoFT5Le9HYnzxoIrel4wXoZy4sjX7kQNyWZv8SQ1vDmIVAZk5GCS2y0YBxzfPr/Cf8grHz7oXWreiH/Q2RBxLzEDnF6jM5AAAAAElFTkSuQmCC",
      "alt": "Badge Tick1 with solid fill"
    }
  })])])])]), _c('tr', [_c('td', {
    staticStyle: {
      "width": "13%",
      "border-right": "1pt solid rgb(217, 217, 217)",
      "border-bottom": "1pt solid rgb(217, 217, 217)",
      "border-left": "1pt solid rgb(217, 217, 217)",
      "border-image": "initial",
      "border-top": "none",
      "background": "white",
      "padding": "0in 5.4pt",
      "vertical-align": "top"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em"
    }
  }, [_c('span', {
    staticStyle: {
      "color": "black"
    }
  }, [_vm._v("Cyber-physical systems")])])]), _c('td', {
    staticStyle: {
      "width": "15%",
      "border-top": "none",
      "border-left": "none",
      "border-bottom": "solid #D9D9D9 1.0pt",
      "border-right": "solid #D9D9D9 1.0pt",
      "padding": "0in 5.4pt 0in 5.4pt"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('span', [_vm._v("$70/User/Month")])])]), _c('td', {
    staticStyle: {
      "width": "17%",
      "border-top": "none",
      "border-left": "none",
      "border-bottom": "solid #D9D9D9 1.0pt",
      "border-right": "solid #D9D9D9 1.0pt",
      "padding": "0in 5.4pt 0in 5.4pt"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('span', [_c('img', {
    attrs: {
      "width": "16",
      "src": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAZCAMAAADt/kXiAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAwUExURQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAFo/HAsAAAAPdFJOUwAQIDBAUGBwgI+fv8/f76R8WAwAAAAJcEhZcwAAFxEAABcRAcom8z8AAACjSURBVChTnZHtDsIgDEULhW0Ko+//ttLb69AYE+P51R4+Wor8jdZaMuOLdHRzzoMi0LBOV7rJPijBRiv6ps14IjXmT87wO1PQ8ywVxU8qpye5mw3XSuWENvN3FBjw1FanrzBe+9JoFT5Le9HYnzxoIrel4wXoZy4sjX7kQNyWZv8SQ1vDmIVAZk5GCS2y0YBxzfPr/Cf8grHz7oXWreiH/Q2RBxLzEDnF6jM5AAAAAElFTkSuQmCC",
      "alt": "Badge Tick1 with solid fill"
    }
  })])])]), _c('td', {
    staticStyle: {
      "width": "20%",
      "border-top": "none",
      "border-left": "none",
      "border-bottom": "solid #D9D9D9 1.0pt",
      "border-right": "solid #D9D9D9 1.0pt",
      "padding": "0in 5.4pt 0in 5.4pt"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('span', [_c('img', {
    attrs: {
      "width": "16",
      "src": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAZCAMAAADt/kXiAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAwUExURQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAFo/HAsAAAAPdFJOUwAQIDBAUGBwgI+fv8/f76R8WAwAAAAJcEhZcwAAFxEAABcRAcom8z8AAACjSURBVChTnZHtDsIgDEULhW0Ko+//ttLb69AYE+P51R4+Wor8jdZaMuOLdHRzzoMi0LBOV7rJPijBRiv6ps14IjXmT87wO1PQ8ywVxU8qpye5mw3XSuWENvN3FBjw1FanrzBe+9JoFT5Le9HYnzxoIrel4wXoZy4sjX7kQNyWZv8SQ1vDmIVAZk5GCS2y0YBxzfPr/Cf8grHz7oXWreiH/Q2RBxLzEDnF6jM5AAAAAElFTkSuQmCC",
      "alt": "Badge Tick1 with solid fill"
    }
  })])])])]), _c('tr', [_c('td', {
    staticStyle: {
      "width": "13%",
      "border-right": "1pt solid rgb(217, 217, 217)",
      "border-bottom": "1pt solid rgb(217, 217, 217)",
      "border-left": "1pt solid rgb(217, 217, 217)",
      "border-image": "initial",
      "border-top": "none",
      "background": "white",
      "padding": "0in 5.4pt",
      "vertical-align": "top"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em"
    }
  }, [_c('span', {
    staticStyle: {
      "color": "black"
    }
  }, [_vm._v("Interpretable AI for Cyber Systems")])])]), _c('td', {
    staticStyle: {
      "width": "15%",
      "border-top": "none",
      "border-left": "none",
      "border-bottom": "solid #D9D9D9 1.0pt",
      "border-right": "solid #D9D9D9 1.0pt",
      "padding": "0in 5.4pt 0in 5.4pt"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('span', [_vm._v("$125/User/Month")])])]), _c('td', {
    staticStyle: {
      "width": "17%",
      "border-top": "none",
      "border-left": "none",
      "border-bottom": "solid #D9D9D9 1.0pt",
      "border-right": "solid #D9D9D9 1.0pt",
      "padding": "0in 5.4pt 0in 5.4pt"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('span', [_c('img', {
    attrs: {
      "width": "16",
      "src": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAZCAMAAADt/kXiAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAwUExURQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAFo/HAsAAAAPdFJOUwAQIDBAUGBwgI+fv8/f76R8WAwAAAAJcEhZcwAAFxEAABcRAcom8z8AAACjSURBVChTnZHtDsIgDEULhW0Ko+//ttLb69AYE+P51R4+Wor8jdZaMuOLdHRzzoMi0LBOV7rJPijBRiv6ps14IjXmT87wO1PQ8ywVxU8qpye5mw3XSuWENvN3FBjw1FanrzBe+9JoFT5Le9HYnzxoIrel4wXoZy4sjX7kQNyWZv8SQ1vDmIVAZk5GCS2y0YBxzfPr/Cf8grHz7oXWreiH/Q2RBxLzEDnF6jM5AAAAAElFTkSuQmCC",
      "alt": "Badge Tick1 with solid fill"
    }
  })])])]), _c('td', {
    staticStyle: {
      "width": "20%",
      "border-top": "none",
      "border-left": "none",
      "border-bottom": "solid #D9D9D9 1.0pt",
      "border-right": "solid #D9D9D9 1.0pt",
      "padding": "0in 5.4pt 0in 5.4pt"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('span', [_c('img', {
    attrs: {
      "width": "16",
      "src": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAZCAMAAADt/kXiAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAwUExURQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAFo/HAsAAAAPdFJOUwAQIDBAUGBwgI+fv8/f76R8WAwAAAAJcEhZcwAAFxEAABcRAcom8z8AAACjSURBVChTnZHtDsIgDEULhW0Ko+//ttLb69AYE+P51R4+Wor8jdZaMuOLdHRzzoMi0LBOV7rJPijBRiv6ps14IjXmT87wO1PQ8ywVxU8qpye5mw3XSuWENvN3FBjw1FanrzBe+9JoFT5Le9HYnzxoIrel4wXoZy4sjX7kQNyWZv8SQ1vDmIVAZk5GCS2y0YBxzfPr/Cf8grHz7oXWreiH/Q2RBxLzEDnF6jM5AAAAAElFTkSuQmCC",
      "alt": "Badge Tick1 with solid fill"
    }
  })])])])]), _c('tr', [_c('td', {
    staticStyle: {
      "width": "13%",
      "border-right": "1pt solid rgb(217, 217, 217)",
      "border-bottom": "1pt solid rgb(217, 217, 217)",
      "border-left": "1pt solid rgb(217, 217, 217)",
      "border-image": "initial",
      "border-top": "none",
      "background": "white",
      "padding": "0in 5.4pt",
      "vertical-align": "top"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em"
    }
  }, [_c('span', {
    staticStyle: {
      "color": "black"
    }
  }, [_vm._v("NAICS 2.0 advanced analytics ")])])]), _c('td', {
    staticStyle: {
      "width": "15%",
      "border-top": "none",
      "border-left": "none",
      "border-bottom": "solid #D9D9D9 1.0pt",
      "border-right": "solid #D9D9D9 1.0pt",
      "padding": "0in 5.4pt 0in 5.4pt"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('span', [_vm._v("$90/User/Month")])])]), _c('td', {
    staticStyle: {
      "width": "17%",
      "border-top": "none",
      "border-left": "none",
      "border-bottom": "solid #D9D9D9 1.0pt",
      "border-right": "solid #D9D9D9 1.0pt",
      "padding": "0in 5.4pt 0in 5.4pt"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('span', [_c('img', {
    attrs: {
      "width": "16",
      "src": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAZCAMAAADt/kXiAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAwUExURQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAFo/HAsAAAAPdFJOUwAQIDBAUGBwgI+fv8/f76R8WAwAAAAJcEhZcwAAFxEAABcRAcom8z8AAACjSURBVChTnZHtDsIgDEULhW0Ko+//ttLb69AYE+P51R4+Wor8jdZaMuOLdHRzzoMi0LBOV7rJPijBRiv6ps14IjXmT87wO1PQ8ywVxU8qpye5mw3XSuWENvN3FBjw1FanrzBe+9JoFT5Le9HYnzxoIrel4wXoZy4sjX7kQNyWZv8SQ1vDmIVAZk5GCS2y0YBxzfPr/Cf8grHz7oXWreiH/Q2RBxLzEDnF6jM5AAAAAElFTkSuQmCC",
      "alt": "Badge Tick1 with solid fill"
    }
  })])])]), _c('td', {
    staticStyle: {
      "width": "20%",
      "border-top": "none",
      "border-left": "none",
      "border-bottom": "solid #D9D9D9 1.0pt",
      "border-right": "solid #D9D9D9 1.0pt",
      "padding": "0in 5.4pt 0in 5.4pt"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "0in",
      "margin-left": "0in",
      "line-height": "normal",
      "font-size": "1em",
      "text-align": "center"
    }
  }, [_c('span', [_c('img', {
    attrs: {
      "width": "16",
      "src": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAZCAMAAADt/kXiAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAwUExURQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAFo/HAsAAAAPdFJOUwAQIDBAUGBwgI+fv8/f76R8WAwAAAAJcEhZcwAAFxEAABcRAcom8z8AAACjSURBVChTnZHtDsIgDEULhW0Ko+//ttLb69AYE+P51R4+Wor8jdZaMuOLdHRzzoMi0LBOV7rJPijBRiv6ps14IjXmT87wO1PQ8ywVxU8qpye5mw3XSuWENvN3FBjw1FanrzBe+9JoFT5Le9HYnzxoIrel4wXoZy4sjX7kQNyWZv8SQ1vDmIVAZk5GCS2y0YBxzfPr/Cf8grHz7oXWreiH/Q2RBxLzEDnF6jM5AAAAAElFTkSuQmCC",
      "alt": "Badge Tick1 with solid fill"
    }
  })])])])])])])]), _c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "8.0pt",
      "margin-left": "0in",
      "line-height": "107%",
      "font-size": "1em"
    }
  }, [_c('span', {
    staticStyle: {
      "font-size": "19px",
      "line-height": "107%"
    }
  }, [_vm._v(" ")])]), _c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "8.0pt",
      "margin-left": "0in",
      "line-height": "107%",
      "font-size": "1em"
    }
  }, [_c('u', [_c('span', {
    staticStyle: {
      "font-size": "19px",
      "line-height": "107%"
    }
  }, [_vm._v("Standard Support and Premium Support")])])]), _c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "8.0pt",
      "margin-left": "0in",
      "line-height": "107%",
      "font-size": "1em"
    }
  }, [_c('strong', [_c('span', [_vm._v("Standard support")])]), _c('span', [_vm._v(" is included with each license in the basic and plus options.  Every license includes three-day response time, self-paced assessments, hosted webinars, and access to the information portal.")])]), _c('p', {
    staticStyle: {
      "margin-top": "0in",
      "margin-right": "0in",
      "margin-bottom": "8.0pt",
      "margin-left": "0in",
      "line-height": "107%",
      "font-size": "1em"
    }
  }, [_c('strong', [_c('span', [_vm._v("Premium support")])]), _c('span', [_vm._v(" is offered at an additional fee and will have twenty-four-hour response time, expert coaching and support, and 24/7 access to the community interest information sharing, dedicated account management.")])])])])]);

}]

export { render, staticRenderFns }