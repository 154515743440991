var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "main-content"
  }, [_c('section', {
    staticClass: "hero-section gradient-overlay",
    attrs: {
      "id": "home"
    }
  }, [_c('div', {
    ref: "swiper",
    staticClass: "swiper-container main-slider"
  }, [_c('div', {
    staticClass: "swiper-wrapper"
  }, [_c('div', {
    staticClass: "swiper-slide"
  }, [_c('div', {
    staticClass: "slide-inner slide-main"
  }, [_c('div', {
    staticClass: "slide-content text-white text-center"
  }, [_c('h2', {
    staticClass: "mb-1 text-center"
  }, [_vm._v("APEX ACCELERATORS")]), _c('p', {
    staticClass: "text-white text-center h5"
  }, [_vm._v(" Accelerating American Entrepreneurship ")]), _c('b-button', {
    staticClass: "m-1",
    attrs: {
      "pill": "",
      "variant": "outline-light"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.push('contact');
      }
    }
  }, [_vm._v("Contact Us ")])], 1)])]), _vm._m(0), _vm._m(1)]), _c('div', {
    staticClass: "swiper-button-next swiper-button-white"
  }), _c('div', {
    staticClass: "swiper-button-prev swiper-button-white"
  })])]), _c('section', {
    staticClass: "about-area section",
    attrs: {
      "id": "aboutus"
    }
  }, [_c('div', {
    staticClass: "container"
  }, [_vm._m(2), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('center', [_c('div', [_c('video', {
    staticStyle: {
      "border": "5px outset #ddd",
      "padding": "3px"
    },
    attrs: {
      "width": "75%",
      "height": "auto",
      "controls": ""
    }
  }, [_c('source', {
    attrs: {
      "src": "https://assets.apexaccelerators.us/videos/APEX_Final2.mp4",
      "type": "video/mp4"
    }
  })])]), _c('strong', [_vm._v(" Footage captured on 7 Nov 2022 for the unveiling of the rebranding of PTACs to APEX Accelerators by DoD OSBP Director Farooq A. Mitha. ")]), _c('hr'), _c('strong', [_vm._v(" APEX Accelerators: Accelerating American Entrepreneurship ")])])], 1)])]), _c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12 pt-4 pb-4"
  }, [_c('div', [_c('center', [_c('div', {
    staticStyle: {
      "position": "relative",
      "padding-top": "42.25%"
    }
  }, [_vm._v(" the map goes here "), _c('iframe', {
    staticStyle: {
      "border": "5px outset #ddd",
      "position": "absolute",
      "top": "0",
      "left": "13%",
      "height": "100%",
      "width": "75%",
      "padding": "3px"
    },
    attrs: {
      "src": "https://customer-vqtiuw508mb7y2yt.cloudflarestream.com/a395110cad9210b0bf531127e6fdb047/iframe?poster=https%3A%2F%2Fcustomer-vqtiuw508mb7y2yt.cloudflarestream.com%2Fa395110cad9210b0bf531127e6fdb047%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600",
      "allow": "accelerometer; gyroscope;  encrypted-media; picture-in-picture;",
      "allowfullscreen": "true"
    }
  })])])], 1), _c('div', [_c('center', {
    staticClass: "mt-3"
  }, [_c('strong', [_vm._v(" Footage captured on 30 Mar 2023 for telling a success story about Arlo Solutions featured by the APEX Accelerators Dir. Khalil Mack. ")])])], 1)])])]), _c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row mb-3"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('GoogleLocator'), _c('center', [_c('div')])], 1)]), _c('div', {
    staticClass: "row"
  }, _vm._l(_vm.nativeAmericanList, function (location) {
    return _c('div', {
      staticClass: "col-lg-6"
    }, [_c('NativeAmericanLocationBadge', {
      attrs: {
        "large": location.large,
        "boxColor": location.boxColor,
        "labelColor": location.labelColor,
        "line1": location.line1,
        "line2": location.line2,
        "line3": location.line3,
        "address1": location.address1,
        "address2": location.address2
      }
    })], 1);
  }), 0)])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "swiper-slide"
  }, [_c('div', {
    staticClass: "slide-inner slide-mission"
  }, [_c('div', {
    staticClass: "slide-content text-white text-center"
  }, [_c('h2', {
    staticClass: "mb-1 text-center"
  }, [_vm._v("MISSION")]), _c('p', {
    staticClass: "text-white text-center h5"
  }, [_vm._v(" Serve as the axis for existing and new business to strengthen the defense industrial base by accelerating innovation, fostering ingenuity, and establishing resilient and diverse supply chains. ")])])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "swiper-slide"
  }, [_c('div', {
    staticClass: "slide-inner slide-vision"
  }, [_c('div', {
    staticClass: "slide-content text-white text-center"
  }, [_c('h2', {
    staticClass: "mb-1 text-center"
  }, [_vm._v("VISION")]), _c('p', {
    staticClass: "text-white text-center h5"
  }, [_vm._v(" A diverse and resilient domestic industrial base that can deliver preeminent solutions to the military and other government users. ")])])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row align-items-center"
  }, [_c('div', {
    staticClass: "col-lg-6 col-md-12"
  }, [_c('div', {
    staticClass: "about-title section-title"
  }, [_c('span', [_vm._v(" ENTREPRENEURSHIP FOR SMALL BUSINESSES")]), _c('h2', [_vm._v("APEX ACCELERATORS")]), _c('p', [_c('strong', [_c('i', [_vm._v(" (formerly known as PTAP) ")])])])])]), _c('div', {
    staticClass: "col-lg-6 col-md-12"
  }, [_c('div', {
    staticClass: "about-text pl-4"
  }, [_c('p', [_vm._v(" The APEX Accelerators program, formerly known as Procurement Technical Assistance Program (PTAP), under management of the Department of Defense (DoD) Office of Small Business Programs (OSBP), plays a critical role in the Department’s efforts to identify and "), _c('a', {
    attrs: {
      "href": "/#/about-us"
    }
  }, [_vm._v("helps")]), _vm._v(" a wide range of businesses enter and participate in the defense supply-chain. The program provides the education and training to ensure that all businesses become capable of participating in federal, state, and local government contracts. ")])])])]);

}]

export { render, staticRenderFns }