<template>
  <div class="main-content bg-light">
    <!-- BradCrumb header Start-->
    <section class="inner-header-bg">
      <div class="container">
        <!-- Breadcrumb Start-->
        <div class="section-breadcrumb">
          <div class="container">
            <div class="row">
              <div
                class="col-md-12 justify-content-center align-self-center pb-2"
              >
                <div class="breadcrumb-content">
                  <div class="title-section">
                    <h1 class="title text-center text-white">Register</h1>
                  </div>
                  <div class="breadcrumb-nav">
                    <ul class="breadcrumb-link text-center list-unstyled mb-0">
                      <li class="text-white">
                        <i class="mdi mdi-home theme-light-color pr-2"></i>
                        <a href="/#/">Home</a>
                      </li>
                      <li class="theme-light-color">
                        <a href="/#/signup">Register</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Breadcrumb End -->
      </div>
    </section>
    <!-- BradCrumb header End -->
    <!-- Register Area Start-->
    <section class="login-form section bg-light">
      <div class="container">
        <div class="login-container">
          <div class="contact-address pb-4">
            <div class="section-title">
              <span>REGISTER</span>
              <h2>Sign up for a free project spectrum account</h2>
            </div>
            <div class="address mt-2">
              <p>
                Project Spectrum provides companies, institutions, and
                organizations with a comprehensive, cost-effective platform of
                cybersecurity information, resources, tools, and training. Our
                mission is to improve cybersecurity readiness, resiliency, and
                compliance of small/medium-sized businesses and the Defense
                Industrial Base (DIB) manufacturing supply chain.
              </p>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="mt-1 w-100">
              <form-wizard
                @onComplete="onComplete"
                @onNextStep="nextStep"
                @onPreviousStep="previousStep"
                :errorMessage="signUpError"
                class="width-override form-floating"
                ref="wizard"
              >
                <tab-content title="Account Info" :selected="true">
                  <b-row>
                    <!-- First Name -->
                    <b-col class="py-1" sm="12" md="6">
                      <div class="form-label-group">
                        <label for="firstName">First Name*</label>
                        <input
                          type="text"
                          id="firstName"
                          class="form-control"
                          :class="hasError('firstName') ? 'is-invalid' : ''"
                          placeholder="First Name"
                          v-model="formData.firstName"
                        />
                        <div
                          v-if="hasError('firstName')"
                          class="invalid-feedback"
                        >
                          <div
                            class="error"
                            v-if="!$v.formData.firstName.required"
                          >
                            Please provide your first name
                          </div>
                        </div>
                      </div>
                    </b-col>
                    <!-- Last Name -->
                    <b-col class="py-1" sm="12" md="6">
                      <div class="form-group">
                        <label for="lastName">Last Name*</label>
                        <input
                          type="text"
                          class="form-control"
                          :class="hasError('lastName') ? 'is-invalid' : ''"
                          placeholder="Last Name"
                          v-model="formData.lastName"
                        />
                        <div
                          v-if="hasError('lastName')"
                          class="invalid-feedback"
                        >
                          <div
                            class="error"
                            v-if="!$v.formData.lastName.required"
                          >
                            Please provide your last name
                          </div>
                        </div>
                      </div>
                    </b-col>
                    <!-- Email Address -->
                    <b-col class="py-1" sm="12" md="6">
                      <div class="form-group">
                        <label for="email">Your Email*</label>
                        <input
                          type="email"
                          class="form-control"
                          :class="hasError('email') ? 'is-invalid' : ''"
                          placeholder="Email Address"
                          v-model="formData.email"
                        />
                        <div v-if="hasError('email')" class="invalid-feedback">
                          <div class="error" v-if="!$v.formData.email.required">
                            Email address is required
                          </div>
                          <div class="error" v-if="!$v.formData.email.email">
                            Should be in email format
                          </div>
                        </div>
                      </div>
                    </b-col>
                    <!-- Forum Screen Name -->
                    <b-col class="py-1" sm="12" md="6">
                      <div class="form-group">
                        <label for="forumScreenName">Forum Username*</label>
                        <input
                          type="text"
                          class="form-control"
                          :class="
                            hasError('forumScreenName') ? 'is-invalid' : ''
                          "
                          placeholder="Username"
                          v-model="formData.forumScreenName"
                        />
                        <div
                          v-if="hasError('forumScreenName')"
                          class="invalid-feedback"
                        >
                          <div
                            class="error"
                            v-if="!$v.formData.forumScreenName.required"
                          >
                            Please provide a screen name
                          </div>
                        </div>
                      </div>
                    </b-col>
                    <!-- Password -->
                    <b-col class="py-1" sm="12" md="6">
                      <div class="form-group">
                        <label for="password">Password*</label>
                        <input
                          type="password"
                          class="form-control"
                          :class="hasError('password') ? 'is-invalid' : ''"
                          placeholder="Password"
                          v-model="formData.password"
                          autocomplete="password"
                        />
                        <div
                          v-if="hasError('password')"
                          class="invalid-feedback"
                        >
                          <div
                            class="error"
                            v-if="!$v.formData.password.required"
                          >
                            Password is required
                          </div>
                          <div
                            class="error"
                            v-if="!$v.formData.password.goodPassword"
                          >
                            Must be at least 8 characters and contain a
                            lowercase, uppercase, number and special character.
                          </div>
                        </div>
                        <small
                          id="passwordHelpBlock"
                          class="form-text text-muted"
                          v-if="!hasError('password')"
                        >
                          Must be at least 8 characters and contain a lowercase,
                          uppercase, number and special character.
                        </small>
                      </div>
                    </b-col>
                    <!-- Confirm Password -->
                    <b-col class="py-1" sm="12" md="6">
                      <div class="form-group">
                        <label for="confirmPassword">Confirm Password*</label>
                        <input
                          type="password"
                          class="form-control"
                          :class="
                            hasError('confirmPassword') ? 'is-invalid' : ''
                          "
                          placeholder="Confirm Password"
                          v-model="formData.confirmPassword"
                          autocomplete="password"
                        />
                        <div
                          v-if="hasError('confirmPassword')"
                          class="invalid-feedback"
                        >
                          <div
                            class="error"
                            v-if="!$v.formData.confirmPassword.required"
                          >
                            Please confirm your password
                          </div>
                          <div
                            class="error"
                            v-if="
                              $v.formData.confirmPassword.required &&
                              !$v.formData.confirmPassword.sameAs
                            "
                          >
                            Please re-enter the password given above
                          </div>
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </tab-content>
                <tab-content title="Account Type">
                  <b-row>
                    <b-col class="py-1" sm="12">
                      <label for="companyName"
                        >Choose your account type (select each to view more
                        information):</label
                      >
                      <b-form-radio-group
                        v-model="formData.accountType"
                        :options="accountTypes"
                        class="mb-3"
                        value-field="item"
                        text-field="name"
                        stacked
                      ></b-form-radio-group>
                    </b-col>
                    <div
                      class="col-12 py-4"
                      v-if="formData.accountType === AccountTypes.INDIVIDUAL"
                    >
                      <div class="section-title">
                        <span>INDIVIDUAL</span>
                        <h2>Individual Account</h2>
                      </div>
                      <div class="mt-4 text-lg-left">
                        <p>
                          If you choose to open your account by registering as
                          an individual (unaffiliated with a specific company),
                          you gain immediate access to many of Project
                          Spectrum’s key offerings including:
                        </p>

                        <ul>
                          <li>Cyber Security Training (for individuals)</li>
                          <li>Informational Webinars</li>
                          <li>
                            Cyber Circuits Sessions (Hear DIRECTLY from
                            community leaders and tech experts!)
                          </li>
                          <li>White Papers</li>
                          <li>Blogs</li>
                        </ul>
                      </div>
                    </div>
                    <div
                      class="col-12 py-4"
                      v-if="formData.accountType === AccountTypes.ORG_ADMIN"
                    >
                      <div class="section-title">
                        <span>ORGANIZATION </span>
                        <h2>Organization Account</h2>
                      </div>
                      <div class="mt-4 text-lg-left">
                        <p>
                          As an organization, you can register today to start
                          your compliance journey! We provide tailored resources
                          to help you based on your current level of cyber
                          hygiene. Your organization can immediately access our
                          Cyber Readiness Check utility to help you determine
                          how compliant you are with NIST and CMMC cybersecurity
                          standards. You will also get direct access to our
                          expert cyber advisors – and one will be assigned as
                          your personal guide on your cybersecurity compliance
                          journey. If you are the first member of your
                          organization to join Project Spectrum, you will be
                          made the administrator of the organization. As your
                          company’s administrator, you can invite specific
                          members of your organization to join Project Spectrum
                          and recommend specific material to them that they
                          might otherwise miss. In addition to those benefits,
                          as a registered organization you will have access to a
                          myriad of resources to boost your cybersecurity
                          posture, including:
                        </p>

                        <ul>
                          <li>Cyber Readiness Checks (for organization)</li>
                          <li>
                            Cyber Security Training (for all members of
                            organization)
                          </li>
                          <li>
                            Virtual Labs (for all members of organization)
                          </li>
                          <li>Informational Webinars</li>
                          <li>
                            Cyber Circuits Sessions (Hear DIRECTLY from
                            community leaders and tech experts!)
                          </li>
                          <li>White Papers</li>
                          <li>Blogs</li>
                          <li>Cybersecurity Tool Reviews</li>
                        </ul>
                      </div>
                    </div>
                    <div
                      class="col-12 py-4"
                      v-if="formData.accountType === AccountTypes.ORG_MEMBER"
                    >
                      <div class="section-title">
                        <span>ORGANIZATION MEMBER</span>
                        <h2>Organization Member Account (Affiliate)</h2>
                      </div>
                      <div class="mt-4 text-lg-left">
                        <p>
                          If your company is already a member of Project
                          Spectrum and you want to open an individual account to
                          access our key cybersecurity resources, simply
                          register as an individual and identify your company
                          during the registration process. By joining, you gain
                          immediate access to a trove of important resources to
                          improve your level of cybersecurity expertise and to
                          help your organization raise its overall level of
                          cyber hygiene. By choosing to join others in your
                          company and opening an account today, you gain
                          immediate access to Project Spectrum’s key offerings
                          including:
                        </p>

                        <ul>
                          <li>Cyber Readiness Checks (for organization)</li>
                          <li>Cyber Security Training (for individuals)</li>
                          <li>Virtual Labs (for individuals)</li>
                          <li>Informational Webinars</li>
                          <li>
                            Cyber Circuits Sessions (Hear DIRECTLY from
                            community leaders and tech experts!)
                          </li>
                          <li>White Papers</li>
                          <li>Blogs</li>
                          <li>Cybersecurity Tool Reviews</li>
                        </ul>
                      </div>
                    </div>
                  </b-row>
                </tab-content>
                <tab-content
                  :title="
                    formData.accountType === AccountTypes.INDIVIDUAL
                      ? 'Contact Info'
                      : 'Company Info'
                  "
                >
                  <!--Do you want to enter your company's CAGE code or DUNS number and look up some of the required fields?-->
                  <b-row v-if="isOrgAdmin">
                    <b-col>
                      <div class="py-2">
                        <b-form-group
                          class="font-italic"
                          label="Do you want to enter your company's CAGE code or DUNS number and look up some of the required fields?"
                        >
                          <b-form-radio
                            v-model="formData.useCompanyCodeLookup"
                            :value="true"
                            >Yes</b-form-radio
                          >
                          <b-form-radio
                            v-model="formData.useCompanyCodeLookup"
                            :value="false"
                            >No</b-form-radio
                          >
                        </b-form-group>
                      </div></b-col
                    >

                    <!-- Company Lookup Code -->
                    <b-col
                      sm="12"
                      class="mb-4 p-4 border border-secondary rounded"
                      v-if="formData.useCompanyCodeLookup"
                    >
                      <label for="companyCodeLookup"
                        >Company's CAGE code or DUNS number *</label
                      >
                      <b-row>
                        <b-col class="py-1" sm="12" md="6">
                          <div class="form-label-group">
                            <input
                              type="text"
                              id="companyCodeLookup"
                              class="form-control"
                              :class="
                                hasError('companyCodeLookup')
                                  ? 'is-invalid'
                                  : ''
                              "
                              v-model="formData.companyCodeLookup"
                            />
                            <div
                              v-if="hasError('companyCodeLookup')"
                              class="invalid-feedback"
                            >
                              <div
                                class="error"
                                v-if="!$v.formData.companyCodeLookup.required"
                              >
                                Please provide your company's CAGE code or DUNS
                                number.
                              </div>
                            </div>
                            <small
                              id="passwordHelpBlock"
                              class="form-text text-muted"
                            >
                              5 character alpha-numeric CAGE code, or 9
                              character numeric DUNS number
                            </small>
                          </div>
                        </b-col>
                        <b-col class="py-1" sm="12" md="6">
                          <b-button
                            @click="onLookupCageCodeOrDuns"
                            variant="btn w-100 rounded centered-link theme-btn-secondary"
                            >Lookup Code</b-button
                          >
                          <b-alert
                            variant="success"
                            v-if="companyLookupSuccessMessage"
                            class="p-2 px-3 my-1"
                            show
                            >{{ companyLookupSuccessMessage }}</b-alert
                          >
                          <b-alert
                            variant="danger"
                            v-if="companyLookupErrorMessage"
                            class="p-2 px-3 my-1"
                            show
                            >{{ companyLookupErrorMessage }}</b-alert
                          >
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                  <b-row>
                    <!-- Organization -->
                    <b-col
                      class="py-1"
                      sm="12"
                      md="6"
                      v-if="isOrgAdminOrMember"
                    >
                      <div class="form-group">
                        <label for="organization"> Organization* </label>
                        <input
                          type="text"
                          class="form-control"
                          :class="hasError('organization') ? 'is-invalid' : ''"
                          placeholder="Organization"
                          v-model="formData.organization"
                        />
                        <div
                          v-if="hasError('organization')"
                          class="invalid-feedback"
                        >
                          <div
                            class="error"
                            v-if="!$v.formData.organization.required"
                          >
                            Enter the name of your organization
                          </div>
                        </div>
                      </div>
                    </b-col>
                    <!-- Role in Organization -->
                    <b-col class="py-1" sm="12" md="6" v-if="isOrgAdmin">
                      <div class="form-group">
                        <label for="organizationRole"
                          >Role in Organization</label
                        >
                        <select
                          :class="
                            hasError('organizationRole') ? 'is-invalid' : ''
                          "
                          class="form-control"
                          v-model="formData.organizationRole"
                        >
                          <option value="" selected="selected">
                            Select Organization Role
                          </option>
                          <option value="Executive or leadership">
                            Executive or leadership
                          </option>
                          <option
                            value="Chief Information Officer (CIO) or Chief Information Security Officer (CISO)"
                          >
                            Chief Information Officer (CIO) or Chief Information
                            Security Officer (CISO)
                          </option>
                          <option value="Operations Manager">
                            Operations Manager
                          </option>
                          <option value="Technical program manager">
                            Technical program manager
                          </option>
                          <option value="Sales">Sales</option>
                          <option value="Manufacturing">Manufacturing</option>
                          <option value="Research or engineering">
                            Research or engineering
                          </option>
                          <option value="Marketing or communications">
                            Marketing or communications
                          </option>
                          <option value="Financial">Financial</option>
                          <option value="Human resources">
                            Human resources
                          </option>
                          <option value="Analyst">Analyst</option>
                          <option value="IT support">IT support</option>
                          <option value="Other">Other</option>
                        </select>
                      </div>
                    </b-col>
                    <!-- Street Address 1 -->
                    <b-col
                      class="py-1"
                      sm="12"
                      md="6"
                      v-if="isIndividualOrAdminOrMember"
                    >
                      <div class="form-group">
                        <label for="address1">Street Address 1*</label>
                        <input
                          type="text"
                          class="form-control"
                          :class="hasError('address1') ? 'is-invalid' : ''"
                          placeholder="Address"
                          v-model="formData.address1"
                        />
                        <small
                          id="passwordHelpBlock"
                          class="form-text text-muted"
                          v-if="!hasError('address1')"
                        >
                          Street address, P.O. box, company name
                        </small>
                        <div
                          v-if="hasError('address1')"
                          class="invalid-feedback"
                        >
                          <div
                            class="error"
                            v-if="!$v.formData.address1.required"
                          >
                            Please provide organization street address
                          </div>
                        </div>
                      </div>
                    </b-col>
                    <!-- Street Address 2 -->
                    <b-col
                      class="py-1"
                      sm="12"
                      md="6"
                      v-if="isIndividualOrAdminOrMember"
                    >
                      <div class="form-group">
                        <label for="address2">Street Address 2</label>
                        <input
                          type="text"
                          class="form-control"
                          :class="hasError('address2') ? 'is-invalid' : ''"
                          placeholder="Address Continued..."
                          v-model="formData.address2"
                        />
                        <small
                          id="passwordHelpBlock"
                          class="form-text text-muted"
                          v-if="!hasError('address2')"
                        >
                          Apartment, suite, unit, building, floor, etc.
                        </small>
                      </div>
                    </b-col>
                    <!-- City -->
                    <b-col
                      class="py-1"
                      sm="12"
                      md="6"
                      v-if="isIndividualOrAdminOrMember"
                    >
                      <div class="form-group">
                        <label for="city">City*</label>
                        <input
                          type="text"
                          class="form-control"
                          :class="hasError('city') ? 'is-invalid' : ''"
                          placeholder="City"
                          v-model="formData.city"
                        />
                        <div v-if="hasError('city')" class="invalid-feedback">
                          <div class="error" v-if="!$v.formData.city.required">
                            Please provide organization city
                          </div>
                        </div>
                      </div>
                    </b-col>
                    <!-- State -->
                    <b-col
                      class="py-1"
                      sm="12"
                      md="6"
                      v-if="isIndividualOrAdminOrMember"
                    >
                      <div class="form-group">
                        <label for="state">State*</label>
                        <select
                          :class="hasError('state') ? 'is-invalid' : ''"
                          class="form-control"
                          v-model="formData.state"
                        >
                          <option value="">Select State</option>
                          <option value="AL">Alabama</option>
                          <option value="AK">Alaska</option>
                          <option value="AZ">Arizona</option>
                          <option value="AR">Arkansas</option>
                          <option value="CA">California</option>
                          <option value="CO">Colorado</option>
                          <option value="CT">Connecticut</option>
                          <option value="DE">Delaware</option>
                          <option value="DC">District Of Columbia</option>
                          <option value="FL">Florida</option>
                          <option value="GA">Georgia</option>
                          <option value="HI">Hawaii</option>
                          <option value="ID">Idaho</option>
                          <option value="IL">Illinois</option>
                          <option value="IN">Indiana</option>
                          <option value="IA">Iowa</option>
                          <option value="KS">Kansas</option>
                          <option value="KY">Kentucky</option>
                          <option value="LA">Louisiana</option>
                          <option value="ME">Maine</option>
                          <option value="MD">Maryland</option>
                          <option value="MA">Massachusetts</option>
                          <option value="MI">Michigan</option>
                          <option value="MN">Minnesota</option>
                          <option value="MS">Mississippi</option>
                          <option value="MO">Missouri</option>
                          <option value="MT">Montana</option>
                          <option value="NE">Nebraska</option>
                          <option value="NV">Nevada</option>
                          <option value="NH">New Hampshire</option>
                          <option value="NJ">New Jersey</option>
                          <option value="NM">New Mexico</option>
                          <option value="NY">New York</option>
                          <option value="NC">North Carolina</option>
                          <option value="ND">North Dakota</option>
                          <option value="OH">Ohio</option>
                          <option value="OK">Oklahoma</option>
                          <option value="OR">Oregon</option>
                          <option value="PA">Pennsylvania</option>
                          <option value="RI">Rhode Island</option>
                          <option value="SC">South Carolina</option>
                          <option value="SD">South Dakota</option>
                          <option value="TN">Tennessee</option>
                          <option value="TX">Texas</option>
                          <option value="UT">Utah</option>
                          <option value="VT">Vermont</option>
                          <option value="VA">Virginia</option>
                          <option value="WA">Washington</option>
                          <option value="WV">West Virginia</option>
                          <option value="WI">Wisconsin</option>
                          <option value="WY">Wyoming</option>
                        </select>
                        <div v-if="hasError('state')" class="invalid-feedback">
                          <div class="error" v-if="!$v.formData.state.required">
                            Please provide organization state
                          </div>
                        </div>
                      </div>
                    </b-col>
                    <!-- Zip Code -->
                    <b-col
                      class="py-1"
                      sm="12"
                      md="6"
                      v-if="isIndividualOrAdminOrMember"
                    >
                      <div class="form-group">
                        <label for="zipCode">Zip Code*</label>
                        <input
                          type="number"
                          class="form-control"
                          :class="hasError('zipCode') ? 'is-invalid' : ''"
                          placeholder="Zip Code"
                          v-model="formData.zipCode"
                        />
                        <div
                          v-if="hasError('zipCode')"
                          class="invalid-feedback"
                        >
                          <div
                            class="error"
                            v-if="!$v.formData.zipCode.required"
                          >
                            Please provide your organization zip code
                          </div>
                        </div>
                      </div>
                    </b-col>
                    <!-- Country -->
                    <b-col
                      class="py-1"
                      sm="12"
                      md="6"
                      v-if="isIndividualOrAdminOrMember"
                    >
                      <div class="form-group">
                        <label for="country">Country*</label>
                        <input
                          type="text"
                          class="form-control"
                          :class="hasError('country') ? 'is-invalid' : ''"
                          placeholder="Country"
                          v-model="formData.country"
                        />
                        <div
                          v-if="hasError('country')"
                          class="invalid-feedback"
                        >
                          <div
                            class="error"
                            v-if="!$v.formData.country.required"
                          >
                            Please provide your organization country
                          </div>
                        </div>
                      </div>
                    </b-col>
                    <!-- Telephone -->
                    <b-col
                      class="py-1"
                      sm="12"
                      md="6"
                      v-if="isIndividualOrAdminOrMember"
                    >
                      <div class="form-group">
                        <label for="telephone">Phone</label>
                        <input
                          type="tel"
                          pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                          class="form-control"
                          :class="hasError('telephone') ? 'is-invalid' : ''"
                          placeholder="Phone Number"
                          v-model="formData.telephone"
                        />
                        <div
                          v-if="hasError('telephone')"
                          class="invalid-feedback"
                        >
                          <div
                            class="error"
                            v-if="!$v.formData.telephone.goodPhone"
                          >
                            Please provide a 10-digit U.S. phone number
                          </div>
                        </div>
                      </div>
                    </b-col>
                    <!-- Website -->
                    <b-col class="py-1" sm="12" md="6" v-if="isOrgAdmin">
                      <div class="form-group">
                        <label for="website">Website</label>
                        <input
                          type="text"
                          class="form-control"
                          :class="hasError('website') ? 'is-invalid' : ''"
                          placeholder="Website URL"
                          v-model="formData.website"
                        />
                      </div>
                    </b-col>
                    <!-- Organization Type -->
                    <b-col class="py-1" sm="12" v-if="isOrgAdmin">
                      <div class="form-group">
                        <label for="organizationType">Organization Type*</label>
                        <select
                          :class="
                            hasError('organizationType') ? 'is-invalid' : ''
                          "
                          v-model="formData.organizationType"
                          multiple
                          class="form-control h-auto"
                          id="organizationType"
                          style="min-height: 290px"
                        >
                          <option value="Small Business (SB)">
                            Small Business (SB)
                          </option>
                          <option value="Small Disadvantaged Business (SDB)">
                            Small Disadvantaged Business (SDB)
                          </option>
                          <option value="8(a) Business">8(a) Business</option>
                          <option value="Women Owned Small Business (WOSB)">
                            Women Owned Small Business (WOSB)
                          </option>
                          <option value="Veteran Owned Small Business (VOSB)">
                            Veteran Owned Small Business (VOSB)
                          </option>
                          <option
                            value="Service Disabled Veteran Owned Small Business (SDVOSB)"
                          >
                            Service Disabled Veteran Owned Small Business
                            (SDVOSB)
                          </option>
                          <option value="HUBZone Business">
                            HUBZone Business
                          </option>
                          <option
                            value="Economically Disadvantaged Women-Owned Small Business (EDWOSB)"
                          >
                            Economically Disadvantaged Women-Owned Small
                            Business (EDWOSB)
                          </option>
                          <option value="Large Business">Large Business</option>
                          <option value="Academic Institution">
                            Academic Institution
                          </option>
                          <option value="Non-Profit">Non-Profit</option>
                        </select>
                        <div
                          v-if="hasError('organizationType')"
                          class="invalid-feedback"
                        >
                          <div
                            class="error"
                            v-if="!$v.formData.organizationType.required"
                          >
                            Please provide your organization type(s)
                          </div>
                        </div>
                        <small
                          class="form-text text-muted"
                          v-if="!hasError('organizationType')"
                        >
                          Please note that you may choose more than one
                          organization type.
                        </small>
                      </div>
                    </b-col>
                    <!-- CAGE Code -->
                    <b-col class="py-1" sm="12" md="6" v-if="isOrgAdmin">
                      <div class="form-group">
                        <label for="codeCAGE">CAGE Code</label>
                        <input
                          type="text"
                          class="form-control"
                          :class="hasError('codeCAGE') ? 'is-invalid' : ''"
                          placeholder="CAGE code"
                          v-model="formData.codeCAGE"
                        />
                        <small
                          id="passwordHelpBlock"
                          class="form-text text-muted"
                          v-if="!hasError('codeCAGE')"
                        >
                          5 character alpha-numeric identifier
                        </small>
                      </div>
                    </b-col>
                    <!-- NAICS Codes -->
                    <b-col class="py-1" sm="12" md="6" v-if="isOrgAdmin">
                      <div class="form-group">
                        <label for="codesNAICS">NAICS Codes</label>
                        <input
                          type="text"
                          class="form-control"
                          :class="hasError('codesNAICS') ? 'is-invalid' : ''"
                          placeholder="NAICS codes (511120,...)"
                          v-model="formData.codesNAICS"
                        />
                        <small
                          id="passwordHelpBlock"
                          class="form-text text-muted"
                          v-if="!hasError('codesNAICS')"
                        >
                          Numerics separated by space or comma.
                        </small>
                      </div>
                    </b-col>
                    <!-- Number of Employees -->
                    <b-col class="py-1" sm="12" md="6" v-if="isOrgAdmin">
                      <div class="form-group">
                        <label for="numberOfEmployees"
                          >Number of Employees</label
                        >
                        <select
                          :class="
                            hasError('numberOfEmployees') ? 'is-invalid' : ''
                          "
                          class="form-control"
                          v-model="formData.numberOfEmployees"
                        >
                          <option value="">Select Number of Employees</option>
                          <option value="1-10">1-10</option>
                          <option value="11-50">11-50</option>
                          <option value="51-500">51-500</option>
                          <option value=" 501-2,000">501-2,000</option>
                          <option value="2,001-10,000">2,001-10,000</option>
                          <option value=">10,001">&gt;10,001</option>
                        </select>
                      </div>
                    </b-col>
                    <!-- Annual Revenues -->
                    <b-col class="py-1" sm="12" md="6" v-if="isOrgAdmin">
                      <div class="form-group">
                        <label for="annualRevenue">Annual Revenues</label>
                        <select
                          id="annualRevenue"
                          :class="hasError('annualRevenue') ? 'is-invalid' : ''"
                          class="form-control"
                          v-model="formData.annualRevenue"
                        >
                          <option value="">Select Annual Revenue</option>
                          <option value="<$200K">&lt;$200K</option>
                          <option value="$200K-$1M">$200K-$1M</option>
                          <option value="$1M-$10M">$1M-$10M</option>
                          <option value="$10M-$50M">$10M-$50M</option>
                        </select>
                      </div>
                    </b-col>
                    <!-- DUNS Number -->
                    <b-col class="py-1" sm="12" md="6" v-if="isOrgAdmin">
                      <div class="form-group">
                        <label for="numberDUNS">DUNS Number</label>
                        <input
                          type="text"
                          class="form-control"
                          :class="hasError('numberDUNS') ? 'is-invalid' : ''"
                          placeholder="DUNS Number"
                          v-model="formData.numberDUNS"
                        />
                        <small
                          id="passwordHelpBlock"
                          class="form-text text-muted"
                          v-if="!hasError('numberDUNS')"
                        >
                          9 character numeric identifier
                        </small>
                      </div>
                    </b-col>
                  </b-row>
                </tab-content>
              </form-wizard>
              <!-- <div class="row">
                <div class="col-sm-12 text-center">
                  <button type="submit" class="btn theme-btn rounded w-100">
                    Register
                  </button>
                </div>
                <div class="col-sm-12 mt-5">
                  <div class="text-center">
                    <div>
                      Have already an account ?
                      <a href="login.html" class="theme-light-color">
                        SIGN IN
                      </a>
                    </div>
                  </div>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Register Area End-->
  </div>
</template>
<script>
import ValidationHelper from './vue-step-wizard/ValidationHelper.vue';
import { helpers } from 'vuelidate/lib/validators';
import TabContent from './vue-step-wizard/TabContent.vue';
import FormWizard from './vue-step-wizard/FormWizard.vue';
import axios from 'axios';

import {
  required,
  requiredIf,
  email,
  numeric,
  minLength,
  sameAs,
} from 'vuelidate/lib/validators';

const AccountTypes = {
  INDIVIDUAL: 0,
  ORG_ADMIN: 1,
  ORG_MEMBER: 2,
};

function isIndividual(value) {
  return value.accountType === AccountTypes.INDIVIDUAL;
}

function isOrganizationAdmin(value) {
  return value.accountType === AccountTypes.ORG_ADMIN;
}

function isOrganizationMember(value) {
  return value.accountType === AccountTypes.ORG_MEMBER;
}

let requiredForAll = requiredIf(function (value) {
  return (
    isIndividual(value) ||
    isOrganizationMember(value) ||
    isOrganizationAdmin(value)
  );
});

let requiredIfOrgAdminOrMember = requiredIf(function (value) {
  return isOrganizationAdmin(value) || isOrganizationMember(value);
});

let requiredIfOrgAdmin = requiredIf(function (value) {
  return isOrganizationAdmin(value);
});

//component code
export default {
  name: 'signup',
  components: {
    FormWizard,
    TabContent,
  },
  mixins: [ValidationHelper],
  mounted() {
    this.init();
  },
  data() {
    return {
      AccountTypes,
      knowCompanyCodeOptions: [
        { item: true, name: 'Yes' },
        { item: false, name: 'No' },
      ],
      accountTypes: [
        { item: AccountTypes.INDIVIDUAL, name: 'Individual' },
        { item: AccountTypes.ORG_ADMIN, name: 'Organization' },
        { item: AccountTypes.ORG_MEMBER, name: 'Organization Member' },
      ],
      signUpError: '',
      companyLookupSuccessMessage: '',
      companyLookupErrorMessage: '',
      formData: {
        // Step 1
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: '',
        forumScreenName: '',
        // Step 2
        accountType: 0,
        // Step 3
        useCompanyCodeLookup: false,
        companyCodeLookup: '',
        companyCode: '',
        organization: '',
        organizationRole: '',
        website: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zipCode: '',
        country: '',
        telephone: '',
        organizationType: [],
        codeCAGE: '',
        codesNAICS: '',
        numberOfEmployees: '',
        annualRevenue: '',
        numberDUNS: '',
      },
      validationRules: [
        // Step 1: Personal
        {
          firstName: { required },
          lastName: { required },
          email: { required, email },
          password: {
            required,
            goodPassword: (password) => {
              //a custom validator!
              return (
                password.length >= 8 &&
                /[a-z]/.test(password) &&
                /[A-Z]/.test(password) &&
                /[0-9]/.test(password) &&
                /[#?!@$%^&*-]/.test(password)
              );
            },
          },
          confirmPassword: { required, sameAsPassword: sameAs('password') },
          forumScreenName: { required },
        },
        // Step 2: Account Selection
        {
          accountType: {
            required,
          },
        },
        // Step 3: Company Info
        {
          companyCode: {
            required: requiredIf(function (form) {
              return this.formData.knowCompanyCode;
            }),
            goodCode: (password) => {
              //a custom validator!
              if (!this.formData.knowCompanyCode) {
                return true;
              } else {
                return password.length == 5 || password.length == 9;
              }
            },
          },
          organization: {
            required: requiredIfOrgAdminOrMember,
          },
          organizationRole: {},
          website: {},
          address1: {
            required: requiredForAll,
          },
          address2: {},
          city: {
            required: requiredForAll,
          },
          state: {
            required: requiredForAll,
          },
          zipCode: {
            required: requiredForAll,
          },
          country: {
            required: requiredForAll,
          },
          telephone: {
            goodPhone: (telephone) => {
              return telephone.length === 10 || !helpers.req(telephone);
            },
          },
          organizationType: {
            required: requiredIfOrgAdmin,
            oneOrMore: (organizationType) => {
              return (
                organizationType.length > 0 || !helpers.req(organizationType)
              );
            },
          },
          codeCAGE: {},
          codesNAICS: {},
          numberOfEmployees: {},
          annualRevenue: {},
          numberDUNS: {},
        },
      ],
    };
  },
  computed: {
    isOrgAdmin() {
      return this.formData.accountType === AccountTypes.ORG_ADMIN;
    },
    isIndividualOrAdminOrMember() {
      return (
        this.formData.accountType === AccountTypes.ORG_ADMIN ||
        this.formData.accountType === AccountTypes.INDIVIDUAL ||
        this.formData.accountType === AccountTypes.ORG_MEMBER
      );
    },
    isOrgAdminOrMember() {
      return (
        this.formData.accountType === AccountTypes.ORG_ADMIN ||
        this.formData.accountType === AccountTypes.ORG_MEMBER
      );
    },
  },
  methods: {
    init() {
      /* The footer height no longer needs to be set */
      // this.initFooterHeight();
    },
    initFooterHeight() {
      var footerHeight = $('footer').outerHeight();
      $('.main-content').css('margin-bottom', footerHeight);
      $('.main-content').css('min-height', '100vh');
    },
    previousStep(tab) {
      console.log('Previous Step: ', tab);
      this.signUpError = ""
    },
    nextStep(tab) {
      console.log('Next Step: ', tab);
      this.signUpError = ""
    },
    onLookupCageCodeOrDuns() {
      this.companyLookupSuccessMessage = '';
      this.companyLookupErrorMessage = '';
      /*console.log(
        'Company Lookup Code',
        this.formData.companyCodeLookup.trim()
      );*/
      if (this.formData.companyCodeLookup.trim().length == 5) {
        /** CAGE Code */
        axios
          .get(`/api/sam/cageCode/${this.formData.companyCodeLookup.trim()}`)
          .then((response) => {
            //console.log('Company Lookup', response);
            if (response.data) {
              this.companyLookupSuccessMessage =
                'Company Information populated';
            } else {
              this.companyLookupErrorMessage =
                'Could not find a company for this code';
            }
            /** Map response json to the form data */
            this.formData.organization = response.data.legalBusinessName;
            this.formData.address1 =
              response.data.electronicBusinessAddress.address1;
            this.formData.address2 =
              response.data.electronicBusinessAddress.address2;
            this.formData.city = response.data.electronicBusinessAddress.city;
            this.formData.state = response.data.electronicBusinessAddress.state;
            this.formData.zipCode = response.data.electronicBusinessAddress.zip;
            this.formData.country =
              response.data.electronicBusinessAddress.countryCode;
            this.formData.website = response.data.corporateUrl;
            this.formData.telephone =
              response.data.electronicBusinessPoc.usPhone;
            this.formData.codeCAGE = response.data.cageCode;
            this.formData.numberDUNS = response.data.duns;
            this.formData.NAICSCodes = response.data.primaryNaics;
          })
          .catch((error) => {
            console.error(error);
          });
      } else if (this.formData.companyCodeLookup.trim().length == 9) {
        /** DUNS Number */
        axios
          .get(`/api/sam/duns/${this.formData.companyCodeLookup.trim()}`)
          .then((response) => {
            //console.log('Company Lookup', response);
            if (response.data) {
              this.companyLookupSuccessMessage =
                'Company Information populated';
            } else {
              this.companyLookupErrorMessage =
                'Could not find a company for this code';
            }
            /** Map response json to the form data */
            if (
              response.data.dbaName !== '' &&
              response.data.dbaName !== response.data.legalBusinessName
            ) {
              this.formData.organization =
                response.data.legalBusinessName +
                ' dba ' +
                response.data.dbaName;
            } else {
              this.formData.organization = response.data.legalBusinessName;
            }
            this.formData.address1 =
              response.data.electronicBusinessAddress.address1;
            this.formData.address2 =
              response.data.electronicBusinessAddress.address2;
            this.formData.city = response.data.electronicBusinessAddress.city;
            this.formData.state = response.data.electronicBusinessAddress.state;
            this.formData.zipCode = response.data.electronicBusinessAddress.zip;
            this.formData.country =
              response.data.electronicBusinessAddress.countryCode;
            this.formData.website = response.data.corporateUrl;
            this.formData.telephone =
              response.data.electronicBusinessPoc.usPhone;
            this.formData.codeCAGE = response.data.cageCode;
            this.formData.numberDUNS = response.data.duns;
            this.formData.codesNAICS = response.data.primaryNaics;
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        console.warn(
          'CAGE or DUNS of bad length passed',
          this.formData.companyCode
        );
        this.companyLookupErrorMessage =
          'Must be a 5 (CAGE) or 9 (DUNS) character code';
      }
    },
    onComplete() {
      console.log("On Complete")
      /**
       * Take the form data and remove any unpopulated fields to create the body of the sign-up POST
       */
      const body = {
        ...(this.formData.firstName && { firstName: this.formData.firstName }),
        ...(this.formData.lastName && { lastName: this.formData.lastName }),
        ...(this.formData.email && { email: this.formData.email }),
        ...(this.formData.password && { password: this.formData.password }),
        ...(this.formData.forumScreenName && {
          forumScreenName: this.formData.forumScreenName,
        }),
        ...(this.formData.organization && {
          organization: this.formData.organization,
        }),
        ...(this.formData.organizationRole && {
          titleInOrganization: this.formData.organizationRole,
        }),
        ...(this.formData.website && { website: this.formData.website }),
        ...(this.formData.address1 && {
          streetAddress1: this.formData.address1,
        }),
        ...(this.formData.address2 && {
          streetAddress2: this.formData.address2,
        }),
        ...(this.formData.city && { city: this.formData.city }),
        ...(this.formData.state && { state: this.formData.state }),
        ...(this.formData.zipCode && { zipcode: this.formData.zipCode }),
        ...(this.formData.country && { country: this.formData.country }),
        ...(this.formData.telephone && { telephone: this.formData.telephone }),
        ...(this.formData.organizationType && {
          organizationType: this.formData.organizationType.join(';'),
        }),
        ...(this.formData.codeCAGE && { CAGECode: this.formData.codeCAGE }),
        ...(this.formData.codesNAICS && {
          NAICSCodes: this.formData.codesNAICS,
        }),
        ...(this.formData.numberOfEmployees && {
          numberOfEmployees: this.formData.numberOfEmployees,
        }),
        ...(this.formData.annualRevenue && {
          annualRevenues: this.formData.annualRevenue,
        }),
        ...(this.formData.numberDUNS && {
          DUNSNumber: this.formData.numberDUNS,
        }),
      };

      if (this.formData.accountType === AccountTypes.INDIVIDUAL) {
        console.log('Creating Individual Account', body);
        axios
          .post('/api/user/individual', body)
          .then((response) => {
            //console.log('Create Individual Account', response);
            /** Account created successfully */
            this.signUpError = '';
            this.$router.push({
              path: 'verify',
              query: { email: this.formData.email, accountType: 'Individual' },
            });
          })
          .catch((error) => {
            /** Error creating account */
            this.signUpError = error.response.data.message;
            console.error(error.response.data.message);
          });
      } else if (this.formData.accountType === AccountTypes.ORG_ADMIN) {
        console.log('Creating Organization Admin Account', body);
        axios
          .post('/api/user/organization', body)
          .then((response) => {
            //console.log(response);
            //console.log(this);
            /** Account created successfully */
            this.signUpError = '';
            this.$router.push({
              path: 'verify',
              query: {
                email: this.formData.email,
                accountType: 'OrganizationAdmin',
              },
            });
          })
          .catch((error) => {
            /** Error creating account */
            this.signUpError = error.response.data.message;
            console.error(error.response.data.message);
          });
      } else {
        console.log('Creating Organization Member Account', body);
        axios
          .post('/api/user/organizationMember', body)
          .then((response) => {
            console.log(response);
            console.log(this);
            /** Account created successfully */
            this.signUpError = '';
            this.$router.push({
              path: 'verify',
              query: {
                email: this.formData.email,
                message: response.data,
                accountType: 'OrganizationMember',
              },
            });
          })
          .catch((error) => {
            /** Error creating account */
            this.signUpError = error.response.data.message;
            console.error(error.response.data.message);
          });
      }
      // this.$refs.wizard.reset();
    },
  },
};
</script>
<style scoped>
.inner-header-bg {
  background-image: url('~@/assets/images/headers/register.png');
  background-position: center center;
}
.form-control {
  height: 45px;
}
</style>
