var render = function render(){
  var _vm$item, _vm$item2, _vm$item3, _vm$item4, _vm$item5, _vm$item6, _vm$item7, _vm$item8, _vm$item9, _vm$item10, _vm$item11, _vm$item12, _vm$item13, _vm$item14, _vm$item15, _vm$item16, _vm$item17, _vm$item17$toolRelate, _vm$item18, _vm$item18$toolRelate, _vm$item19, _vm$item19$toolKnownC, _vm$item20, _vm$item20$toolKnownC;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "main-content"
  }, [_c('section', {
    staticClass: "inner-header-bg"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "section-breadcrumb"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12 justify-content-center align-self-center pb-2"
  }, [_c('div', {
    staticClass: "breadcrumb-content"
  }, [_vm._m(0), _c('div', {
    staticClass: "breadcrumb-nav"
  }, [_c('ul', {
    staticClass: "breadcrumb-link text-center list-unstyled mb-0"
  }, [_vm._m(1), _c('li', {
    staticClass: "text-white"
  }, [_c('router-link', {
    attrs: {
      "to": {
        name: 'tool-list'
      }
    }
  }, [_c('a', {
    attrs: {
      "href": "#"
    }
  }, [_vm._v("Tool Reviews")])])], 1), _c('li', {
    staticClass: "theme-light-color active"
  }, [_vm._v("Tool Details")])])])])])])])])])]), _c('section', {
    staticClass: "section section-blog"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('article', [_c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "4",
      "order-md": "1"
    }
  }, [_c('img', {
    staticClass: "img-fluid img-thumbnail border-0 tool-logo",
    attrs: {
      "src": (_vm$item = _vm.item) === null || _vm$item === void 0 ? void 0 : _vm$item.mainImage,
      "alt": (_vm$item2 = _vm.item) === null || _vm$item2 === void 0 ? void 0 : _vm$item2.photoDescription
    }
  })]), _c('b-col', {
    staticClass: "text-center text-md-left align-items-center",
    attrs: {
      "sm": "12",
      "md": "8"
    }
  }, [_c('div', [_c('h1', {
    staticClass: "mb-0 pb-0"
  }, [_vm._v(" " + _vm._s((_vm$item3 = _vm.item) === null || _vm$item3 === void 0 ? void 0 : _vm$item3.title) + " ")]), _c('p', {
    staticClass: "blog-post-title my-0"
  }, [_vm._v(" " + _vm._s((_vm$item4 = _vm.item) === null || _vm$item4 === void 0 ? void 0 : _vm$item4.toolCreatedBy) + " ")]), _c('b-badge', {
    staticClass: "font-weight-normal",
    attrs: {
      "pill": "",
      "variant": "primary"
    }
  }, [_vm._v(_vm._s((_vm$item5 = _vm.item) === null || _vm$item5 === void 0 ? void 0 : _vm$item5.toolCategory))]), _c('SocialShareBookmark', {
    staticClass: "justify-content-center justify-content-md-start",
    attrs: {
      "id": "social-share",
      "title": _vm.title,
      "description": _vm.description,
      "type": "tool"
    }
  })], 1)])], 1), _c('b-row', {
    staticClass: "my-2 descriptor"
  }, [_c('b-col', {
    attrs: {
      "xs": "12",
      "sm": "6",
      "md": "4"
    }
  }, [_c('span', {
    staticClass: "row col py-0 mt-0 d-flex text-center"
  }, [_c('b', [_vm._v("Price Range:")]), _vm._v(" " + _vm._s((_vm$item6 = _vm.item) === null || _vm$item6 === void 0 ? void 0 : _vm$item6.toolPriceRange))]), _c('span', {
    staticClass: "row col py-0"
  }, [_c('b', [_vm._v("Creator:")]), _vm._v(" " + _vm._s((_vm$item7 = _vm.item) === null || _vm$item7 === void 0 ? void 0 : _vm$item7.toolCreatedBy))]), _c('span', {
    staticClass: "row col py-0"
  }, [_c('b', [_vm._v("Founded In:")]), _vm._v(" " + _vm._s((_vm$item8 = _vm.item) === null || _vm$item8 === void 0 ? void 0 : _vm$item8.toolCompanyFoundingDate))])]), _c('b-col', {
    attrs: {
      "xs": "12",
      "sm": "6",
      "md": "4"
    }
  }, [_c('span', {
    staticClass: "row col py-0 mt-0"
  }, [_c('b', [_vm._v("Cost Model:")]), _vm._v(" " + _vm._s((_vm$item9 = _vm.item) === null || _vm$item9 === void 0 ? void 0 : _vm$item9.toolCostModel))]), _c('span', {
    staticClass: "row col py-0"
  }, [_c('b', [_vm._v("Ease of Use:")]), _vm._v(" " + _vm._s((_vm$item10 = _vm.item) === null || _vm$item10 === void 0 ? void 0 : _vm$item10.toolEaseOfUse))]), _c('span', {
    staticClass: "row col py-0"
  }, [_c('b', [_vm._v("Typical Users:")]), _vm._v(" " + _vm._s((_vm$item11 = _vm.item) === null || _vm$item11 === void 0 ? void 0 : _vm$item11.toolTypicalUsers))])]), _c('b-col', {
    attrs: {
      "xs": "12",
      "sm": "6",
      "md": "4"
    }
  }, [_c('span', {
    staticClass: "row col py-0 mt-0"
  }, [_c('b', [_vm._v("Free Training Resources:")]), _vm._v("  "), (_vm$item12 = _vm.item) !== null && _vm$item12 !== void 0 && _vm$item12.toolFreeTrainingResourcesAvailable ? _c('font-awesome-icon', {
    staticClass: "pl-1",
    attrs: {
      "size": "lg",
      "icon": ['fas', 'check-circle']
    }
  }) : _c('font-awesome-icon', {
    staticClass: "pl-1",
    attrs: {
      "size": "lg",
      "icon": ['fas', 'times-circle']
    }
  })], 1), _c('span', {
    staticClass: "row col py-0"
  }, [_c('b', [_vm._v("Paid Training Resources:")]), _vm._v(" "), (_vm$item13 = _vm.item) !== null && _vm$item13 !== void 0 && _vm$item13.toolPaidTraining ? _c('font-awesome-icon', {
    staticClass: "pl-1",
    attrs: {
      "size": "lg",
      "icon": ['fas', 'check-circle']
    }
  }) : _c('font-awesome-icon', {
    staticClass: "pl-1",
    attrs: {
      "size": "lg",
      "icon": ['fas', 'times-circle']
    }
  })], 1), _c('span', {
    staticClass: "row col py-0"
  }, [_c('b', [_vm._v("Website:")]), _vm._v(" "), _c('a', {
    attrs: {
      "target": "_blank",
      "href": (_vm$item14 = _vm.item) === null || _vm$item14 === void 0 ? void 0 : _vm$item14.toolWebsite
    }
  }, [_vm._v(_vm._s((_vm$item15 = _vm.item) === null || _vm$item15 === void 0 ? void 0 : _vm$item15.toolWebsite))])])])], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12",
      "lg": "6"
    }
  }, [_c('p', {
    staticClass: "mb-0 title"
  }, [_c('b', [_vm._v("Description")])]), _c('span', {
    domProps: {
      "innerHTML": _vm._s((_vm$item16 = _vm.item) === null || _vm$item16 === void 0 ? void 0 : _vm$item16.review)
    }
  })]), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6",
      "lg": "3"
    }
  }, [_c('p', {
    staticClass: "mb-0 title"
  }, [_c('b', [_vm._v("Related Certifications")])]), ((_vm$item17 = _vm.item) === null || _vm$item17 === void 0 ? void 0 : (_vm$item17$toolRelate = _vm$item17.toolRelatedCertifications) === null || _vm$item17$toolRelate === void 0 ? void 0 : _vm$item17$toolRelate.certification.length) > 0 ? _c('b-list-group', _vm._l((_vm$item18 = _vm.item) === null || _vm$item18 === void 0 ? void 0 : (_vm$item18$toolRelate = _vm$item18.toolRelatedCertifications) === null || _vm$item18$toolRelate === void 0 ? void 0 : _vm$item18$toolRelate.certification, function (cert) {
    return _c('b-list-group-item', {
      key: cert
    }, [_vm._v(_vm._s(cert))]);
  }), 1) : _c('b-list-group', [_c('b-list-group-item', [_vm._v("None")])], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6",
      "lg": "3"
    }
  }, [_c('p', {
    staticClass: "mb-0 title"
  }, [_c('b', [_vm._v("Known Clients")])]), ((_vm$item19 = _vm.item) === null || _vm$item19 === void 0 ? void 0 : (_vm$item19$toolKnownC = _vm$item19.toolKnownClients) === null || _vm$item19$toolKnownC === void 0 ? void 0 : _vm$item19$toolKnownC.knownClient.length) > 0 ? _c('b-list-group', _vm._l((_vm$item20 = _vm.item) === null || _vm$item20 === void 0 ? void 0 : (_vm$item20$toolKnownC = _vm$item20.toolKnownClients) === null || _vm$item20$toolKnownC === void 0 ? void 0 : _vm$item20$toolKnownC.knownClient, function (cert) {
    return _c('b-list-group-item', {
      key: cert
    }, [_vm._v(_vm._s(cert))]);
  }), 1) : _c('b-list-group', [_c('b-list-group-item', [_vm._v("None")])], 1)], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12",
      "lg": "6",
      "order": "1",
      "order-lg": "0"
    }
  }, [_c('p', {
    staticClass: "mb-0 title"
  }, [_c('b', [_vm._v("Comments")])]), _c('b-form-input', {
    staticClass: "mb-2",
    attrs: {
      "id": "comment-search",
      "placeholder": "Search Comments..."
    },
    model: {
      value: _vm.searchQuery,
      callback: function ($$v) {
        _vm.searchQuery = $$v;
      },
      expression: "searchQuery"
    }
  }), _c('div', [_vm.displayeditems.length > 0 ? _c('b-list-group', _vm._l(_vm.displayeditems, function (comment) {
    return _c('b-list-group-item', {
      key: comment.id
    }, [_c('p', {
      staticClass: "my-0 font-weight-bold"
    }, [_vm._v(" " + _vm._s(comment.name) + " ")]), _c('p', {
      staticClass: "my-0"
    }, [_vm._v(" " + _vm._s(comment.commentBody) + " ")]), _c('p', {
      staticClass: "my-0 text-muted comment-date"
    }, [_vm._v(" " + _vm._s(new Date(comment.commentDate).toLocaleString()) + " ")])]);
  }), 1) : _vm._e(), _vm.displayeditems.length === 0 && _vm.searchQuery ? _c('p', {
    staticClass: "pb-2"
  }, [_vm._v(" No comments match the search criteria... ")]) : _vm._e(), _vm.displayeditems.length === 0 && !_vm.searchQuery ? _c('p', {
    staticClass: "pb-2"
  }, [_vm._v(" No comments for this tool yet... ")]) : _vm._e()], 1), _c('b-pagination', {
    staticClass: "mt-3",
    attrs: {
      "id": "blog-pagination",
      "total-rows": _vm.totalRows,
      "per-page": _vm.perPage,
      "align": "center"
    },
    on: {
      "page-click": _vm.pageClicked
    },
    model: {
      value: _vm.currentPage,
      callback: function ($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "lg": "6",
      "order": "0",
      "order-lg": "1"
    }
  }, [_c('p', {
    staticClass: "mb-0 title"
  }, [_c('b', [_vm._v("Add New Comment")])]), _c('b-form-textarea', {
    attrs: {
      "id": "textarea",
      "placeholder": "Enter your comments here...",
      "rows": "5",
      "max-rows": "5"
    },
    model: {
      value: _vm.addComment,
      callback: function ($$v) {
        _vm.addComment = $$v;
      },
      expression: "addComment"
    }
  }), _vm.commentPosted ? _c('b-alert', {
    staticClass: "mt-2 mb-0",
    attrs: {
      "show": ""
    }
  }, [_vm._v("Your comment has been submitted. The comment will only be visible after moderation and approval from Project Spectrum. ")]) : _vm._e(), _c('b-overlay', {
    staticClass: "d-inline-block",
    attrs: {
      "show": _vm.commentPosting,
      "rounded": "",
      "opacity": "0.6",
      "spinner-small": "",
      "spinner-variant": "primary"
    },
    on: {
      "hidden": _vm.onHidden
    }
  }, [_c('b-button', {
    ref: "button",
    staticClass: "mt-2",
    attrs: {
      "disabled": _vm.commentPosting || _vm.addComment.length === 0,
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.postToolComment(_vm.addComment, _vm.id);
      }
    }
  }, [_vm._v(" Post Comment ")])], 1)], 1)], 1)], 1)])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "title-section"
  }, [_c('h1', {
    staticClass: "title text-center text-white"
  }, [_vm._v("Tool Details")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('li', {
    staticClass: "text-white"
  }, [_c('i', {
    staticClass: "mdi mdi-home theme-light-color pr-2"
  }), _c('a', {
    attrs: {
      "href": "/#/"
    }
  }, [_vm._v("Home")])]);

}]

export { render, staticRenderFns }