var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "main-content"
  }, [_vm._m(0), _c('section', {
    staticClass: "section"
  }, [_c('mdb-container', [_c('mdb-row', [_c('mdb-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('h4', {
    staticClass: "my-4"
  }, [_vm._v("YouTube Iframe")]), _c('div', {
    staticClass: "embed-responsive embed-responsive-16by9"
  }, [_c('iframe', {
    staticClass: "embed-responsive-item",
    attrs: {
      "src": "https://www.youtube.com/embed/v64KOxKVLVg",
      "allowfullscreen": ""
    }
  })])]), _c('mdb-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('h4', {
    staticClass: "my-4"
  }, [_vm._v("Vimeo Iframe")]), _c('div', {
    staticClass: "embed-responsive embed-responsive-16by9"
  }, [_c('iframe', {
    staticClass: "embed-responsive-item",
    attrs: {
      "src": "https://player.vimeo.com/video/137857207",
      "allowfullscreen": ""
    }
  })])])], 1)], 1)], 1)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "inner-header-bg"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "section-breadcrumb"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12 justify-content-center align-self-center pb-2"
  }, [_c('div', {
    staticClass: "breadcrumb-content"
  }, [_c('div', {
    staticClass: "title-section"
  }, [_c('h1', {
    staticClass: "title text-center text-white"
  }, [_vm._v(" Training Videos and Courses ")])]), _c('div', {
    staticClass: "breadcrumb-nav"
  }, [_c('ul', {
    staticClass: "breadcrumb-link text-center list-unstyled mb-0"
  }, [_c('li', {
    staticClass: "text-white"
  }, [_c('i', {
    staticClass: "mdi mdi-home theme-light-color pr-2"
  }), _c('a', {
    attrs: {
      "href": "/#/"
    }
  }, [_vm._v("Home")])]), _c('li', {
    staticClass: "theme-light-color active"
  }, [_vm._v(" Training Videos and Courses ")])])])])])])])])])]);

}]

export { render, staticRenderFns }