var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('footer', {
    staticClass: "text-white footer"
  }, [_c('div', {
    staticClass: "container"
  }, [_vm._m(0), _c('nav', [_c('div', {
    staticClass: "menu-footer-menu-container mt-4"
  }, [_c('ul', {
    staticClass: "menu list-unstyled"
  }, [_c('li', {
    staticClass: "menu-item"
  }, [_c('router-link', {
    attrs: {
      "to": "/"
    }
  }, [_vm._v("Home")])], 1), _c('li', {
    staticClass: "menu-item"
  }, [_c('router-link', {
    attrs: {
      "to": "/leadership"
    }
  }, [_vm._v("Leadership")])], 1), _c('li', {
    staticClass: "menu-item"
  }, [_c('router-link', {
    attrs: {
      "to": "/feedback"
    }
  }, [_vm._v("Feedback")])], 1), _c('li', {
    staticClass: "menu-item"
  }, [_c('router-link', {
    attrs: {
      "to": "/contact"
    }
  }, [_vm._v("Contact Us")])], 1), _c('li', {
    staticClass: "menu-item"
  }, [_c('a', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal.modal-privacy",
      modifiers: {
        "modal-privacy": true
      }
    }]
  }, [_c('span', {
    staticClass: "line"
  }, [_vm._v("Privacy")])])])])])]), _vm._m(1)])]), _c('b-modal', {
    attrs: {
      "id": "modal-privacy",
      "title": "Privacy Policy",
      "size": "xl",
      "ok-only": ""
    }
  }, [_c('div', [_c('p', [_vm._v("This privacy notice discloses the privacy practices for the APEX Accelerators website and applies to information collected on the APEX website including, without limitation, registration and login information, and information collected by the APEX internal applications. It will notify you of the following:")]), _c('p', [_vm._v("1. The personally identifiable information that is collected from you through the website, how it is used and with whom it may be shared.")]), _c('p', [_vm._v("2. The choices that are available to you regarding the use of your data.")]), _c('p', [_vm._v("3. The security procedures that are in place to protect the misuse of your information.")]), _c('p', [_vm._v("4. The method you can employ to correct any inaccuracies in your information. ")]), _c('p', [_c('b', [_vm._v("Information Collection ")])]), _c('p', [_vm._v("We are the sole owners of the information collected on this site. We only collect and have access to information that you voluntarily give us by filling out the online forms, via email, or other direct contact from you. ")]), _c('p', [_c('b', [_vm._v("Information Use and Disclosure ")])]), _c('p', [_vm._v("Information that you voluntarily give us may be used by us, or disclosed to third parties, such as a U.S. federal government agency. In general, we will deidentify data disclosed to third parties by removing information such as your business name and contact details. ")]), _c('p', [_c('b', [_vm._v("Your Access to and Control Over Information ")])]), _c('p', [_vm._v("Unless you ask us not to, we may contact you via email or phone in the future with updates on the APEX Accelerators program, to inform you about new products, services, or changes to this privacy policy. ")]), _c('p', [_vm._v("You may opt out of any future contacts from us at any time. You can do so at any time by contacting us via the "), _c('a', {
    attrs: {
      "href": "mailto:info@apexaccelerators.us"
    }
  }, [_vm._v("info@apexaccelerators.us")]), _vm._v(" email address provided on our website to: ")]), _c('p', [_vm._v("1. Inquire about any personal data we have collected or stored. ")]), _c('p', [_vm._v("2. Change/correct any personal data we have collected or stored.")]), _c('p', [_vm._v("3. Delete any personal data we have collected or stored. ")]), _c('p', [_vm._v("4. Express any concern you have about our use of your personal data.")]), _c('p', [_c('b', [_vm._v("Security")])]), _c('p', [_c('span', [_vm._v("We take precautions to protect your information. When you submit sensitive information via the website, your information is protected in transit, in use, and at rest (stored).")])]), _c('p', [_c('span', [_vm._v("Wherever information is collected through the APEX Accelerators website, such information is encrypted and transmitted using the latest cryptographic algorithms for data transport. ")])]), _c('p', [_c('span', [_vm._v("While we use encryption to protect sensitive information transmitted online, we also protect it while it is being used and at rest (i.e. being stored). ")])]), _c('p', [_c('span', [_vm._v("Only employees who need the information to perform a specific job are granted access to any personally identifiable information. The computers/servers in which we store personally identifiable information are kept in a secure environment. ")])]), _c('p', [_c('span', [_vm._v("We reserve the right to revise this Privacy Policy at any time and for any reason. Any revisions will be effective immediately upon posting the revised Privacy Policy on the APEX Accelerators website and you hereby waive any right to receive specific notice of any such revision.")])])])]), _vm._m(2)], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a', {
    staticClass: "footer-logo",
    attrs: {
      "href": "#top",
      "title": ""
    }
  }, [_c('img', {
    staticClass: "img-fluid",
    attrs: {
      "src": require("../assets/images/logo/apex_logo_dark.png"),
      "alt": ""
    }
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "copyright mt-4"
  }, [_c('p', [_vm._v("© 2024 APEX Accelerators")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a', {
    staticClass: "back_top",
    attrs: {
      "href": "#"
    }
  }, [_c('i', {
    staticClass: "mdi mdi-chevron-up"
  })]);

}]

export { render, staticRenderFns }