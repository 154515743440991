var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    ref: "my-modal",
    attrs: {
      "size": "md",
      "title": "Welcome",
      "ok-title": "ok",
      "ok-variant": "primary",
      "cancel-variant": "primary",
      "no-close-on-backdrop": "",
      "no-close-on-esc": "",
      "no-close-on-x": "",
      "hide-header-close": "",
      "busy": _vm.busy
    }
  }, [_c('p', {
    staticClass: "my-2"
  }, [_vm._v(" We are happy to welcome you as the newest organization within Project Spectrum community! As an organization admin, you will be able to leverage the plethora of tools and services offered by Project Spectrum, including: Cyber Readiness Checks, advisory services, and assistance with every step of your customized cybersecurity journey. Additionally, you will have the ability to invite new members of your organization and manage them accordingly to gain insights into their individual training progress. Please feel free to take advantage of all of the resources at your disposal as well. We offer Cyber Readiness Checks for your organization, personalized cybersecurity training, virtual labs, informational webinars, Cyber Circuits Sessions (hear from industry experts), white papers, blogs, compliance tool reviews, and much more! ")])]);

}
var staticRenderFns = []

export { render, staticRenderFns }