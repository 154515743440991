<template>
  <div class="main-content">
    <!-- BradCrumb header Start-->
    <section class="inner-header-bg">
      <div class="container">
        <!-- Breadcrumb Start-->
        <div class="section-breadcrumb">
          <div class="container">
            <div class="row">
              <div
                class="col-md-12 justify-content-center align-self-center pb-2"
              >
                <div class="breadcrumb-content">
                  <div class="title-section">
                    <h1 class="title text-center text-white">Online Courses</h1>
                  </div>
                  <div class="breadcrumb-nav">
                    <ul class="breadcrumb-link text-center list-unstyled mb-0">
                      <li class="text-white">
                        <i class="mdi mdi-home theme-light-color pr-2"></i>
                        <a href="/#/">Home</a>
                      </li>
                      <li class="theme-light-color active">Online Courses</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Breadcrumb End -->
      </div>
    </section>
    <!-- BradCrumb header End -->
    <!-- Blog Grid Section Start -->
    <section class="section container">
      <p v-if="isLoggedIn">
        A primary goal of the Project Spectrum Team is to provide comprehensive
        training courses for our partners. To that end, our training curriculum
        is a living and ever-evolving platform that keeps pace with policy,
        technology, and the tradecraft of offensive cyber actors. We aim to
        increase the cyber knowledge base of all users to raise the overall
        level of cybersecurity hygiene of every partner. Login to our training
        platform to view our current offerings and be sure to check back often
        as we regularly develop new material to help us remain one-step ahead of
        our adversaries in cyberspace.
      </p>
      <div v-if="!isLoggedIn" class="my-2 text-left">
        <p>A primary goal of the Project Spectrum Team is to provide
        state-of-the-art training courses for our partners. To that end, our
        training curriculum is a living and ever-evolving platform that keeps
        pace with policy, technology, and the tradecraft of offensive cyber
        actors. We aim to increase the cyber knowledge base of all users to
        raise the overall level of cybersecurity hygiene of every partner.
        <router-link :to="{ name: 'login' }"><b>Login</b> </router-link>
        or
        <router-link :to="{ name: 'signup' }"><b>Register </b></router-link> to
        our training platform to view our current offerings and be sure to check
        back often as we regularly develop new material to help us remain
        one-step ahead of our adversaries in cyberspace. </p>
        <br />

        <div class="login-register-btn-container mt-4">
          <router-link :to="{ name: 'login' }"
            ><b-button class="btn theme-btn rounded login-reg-btn"
              >Login</b-button
            ></router-link
          >
          <router-link :to="{ name: 'signup' }"
            ><b-button
              variant="none"
              class="btn theme-btn rounded login-reg-btn"
              >Register</b-button
            ></router-link
          >
        </div>
      </div>
      <!-- Encite Courses -->
      <div v-if="isLoggedIn">
        <div>
          <p>
            To access Project Spectrum’s online courses, you must log into the
            Encite learning management platform. Because you are a registered
            Project Spectrum user, we have automatically generated your login
            credentials, which you can see below.
          </p>
          <p>
            Clicking the course link will take you to the Encite login screen.
            <b>
              You will need to copy/paste the system-generated password and log
              into the learning management platform.</b
            >
          </p>
          <p>
            If you have issues or concerns, please contact us at
            <a href="mailto:outreach@projectspectrum.io"
              >outreach@projectspectrum.io</a
            >
            for assistance.
          </p>
          <div class="p-3 px-4 my-2 mb-4 border border-secondary rounded">
            <div><b>Username</b> : {{ username }}</div>
            <div>
              <b>Password</b> :
              {{
                showPassword ? password : '*********************************'
              }}
            </div>
            <b-form-checkbox v-model="showPassword">
              Show Password
            </b-form-checkbox>
            <b-alert
              class="mt-2 mb-1"
              :show="enciteCredentialError"
              variant="danger"
              >Issue retrieving Encite credentials. Contact us at
              <a href="mailto:outreach@projectspectrum.io"
                >outreach@projectspectrum.io</a
              >
              for assistance.
            </b-alert>
          </div>
        </div>
        <div class="pb-4">
          <b-form-input
            v-model="searchQuery"
            placeholder="Search Courses..."
          ></b-form-input>
        </div>
        <p class="pb-2" v-if="displayedItems.length === 0 && searchQuery">
          No items match the search criteria...
        </p>
        <p class="pb-2" v-if="displayedItems.length === 0 && !searchQuery">
          Check back soon for online courses...
        </p>
        <div class="blog-post-grid">
          <div class="row">
            <!-- Start Blog Item -->
            <div
              class="col-md-6 col-lg-4 mb-5"
              v-for="item in displayedItems"
              :key="item.id"
            >
              <div class="blog-item border h-100">
                <a :href="item.link" class="blog-post-image">
                  <img :src="item.image" class="cover" />
                </a>
                <div class="blog-post-info">
                  <a class="text-dark" :href="item.link">
                    <h4 class="blog-post-title">
                      {{ item.name }}
                    </h4>
                    <p class="limit-lines-course">{{ item.description }}</p>
                  </a>
                  <a class="blog-read-more" :href="item.link">
                    <i class="mdi mdi-link-variant"></i>
                  </a>
                </div>
              </div>
            </div>
            <!-- End Blog Item -->
          </div>
        </div>
        <div>
          <b-pagination
            id="blog-pagination"
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="center"
            @page-click="pageClicked"
          ></b-pagination>
        </div>
      </div>
    </section>
    <!-- Blog Grid Section End -->
  </div>
</template>
<script>
import axios from 'axios';
import { BPagination, BFormInput } from 'bootstrap-vue';
import { isLoggedIn, handleApiError } from '../utils/authentication';
export default {
  name: 'encite-courses',
  components: {
    BPagination,
    BFormInput,
  },
  props: {
    query: String,
  },
  data() {
    return {
      isLoggedIn: isLoggedIn(),
      username: 'Loading...',
      password: 'Loading...',
      enciteCredentialError: false,
      showPassword: false,
      searchQuery: this.query,
      items: [],
      itemCount: 0,
      currentPage: 1,
      perPage: 9,
    };
  },
  computed: {
    itemsFromSearchQuery() {
      if (this.searchQuery) {
        return this.items.filter((item) => {
          return this.searchQuery
            .toLowerCase()
            .split(' ')
            .every(
              (v) =>
                item.name.toLowerCase().includes(v) ||
                item.description.toLowerCase().includes(v)
            );
        });
      } else {
        return this.items;
      }
    },
    displayedItems() {
      return this.itemsFromSearchQuery.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
      );
    },
    totalRows() {
      return this.itemsFromSearchQuery.length;
    },
  },
  mounted: async function () {
    this.init();
    if (this.isLoggedIn) {
      this.getEnciteCredentials(); // async
      this.getBlogPosts(); // async
    }
  },
  methods: {
    init() {
      /* The footer height no longer needs to be set */
      // this.initFooterHeight();
    },
    initFooterHeight() {
      var footerHeight = $('footer').outerHeight();
      $('.main-content').css('margin-bottom', footerHeight);
    },
    async getEnciteCredentials() {
      /*  API call to server: GET /api/encite/account  */
      axios
        .get('/api/encite/account')
        .then((response) => {
          this.enciteCredentialError = false;
          this.username = response.data.username;
          this.password = response.data.password;
        })
        .catch((error) => {
          this.enciteCredentialError = true;
          handleApiError(error, this.$router);
          console.error(error);
        });
    },
    async getBlogPosts() {
      /**
       * Courses are currently Hardcoded
       */
      this.items = [
        {
          name: 'CUI for Contractors',
          image: require('../assets/images/encite/encite-course-1.jpeg'),
          description:
            'In this course we will cover Controlled Unclassified Information and the essential knowledge required for Defense Industrial Base Companies to properly safeguard, disseminate, and destroy CUI. You will learn the history of Controlled Unclassified Information and how it is currently protected by U.S. laws, regulations, and policies. This course contains three modules and takes approximately 67 minutes to complete.',
          link: 'https://encite.io/mod/scorm/view.php?id=199',
        },
        {
          name: 'CMMC Level 1 Course',
          image: require('../assets/images/encite/encite-course-1.jpeg'),
          description:
            'In this course, we will cover CMMC Level 1 preparedness. You will learn what your organization should do to safeguard Federal Contract Information (FCI) and how to perform basic cyber hygiene. This course contains three modules and takes approx. 58 minutes to complete.',
          link: 'https://encite.io/mod/scorm/view.php?id=200',
        },
        {
          name: 'Systems Security Plan Fundamentals',
          image: require('../assets/images/encite/encite-course-1.jpeg'),
          description:
            'This course is designed to familiarize learners with the SSP concepts that will allow them to achieve Federal compliance. Students will be introduced to some of the fundamentals of the process for developing an SSP. This course has three modules and will take approximately 45 minutes to complete.',
          link: 'https://encite.io/mod/scorm/view.php?id=201',
        },
		{
          name: 'Plan of Action and Milestones (POA&M)',
          image: require('../assets/images/encite/encite-course-1.jpeg'),
          description:
            'This course is designed to familiarize learners with the POA&M concepts that will allow them to achieve Federal compliance. Students will be introduced to some of the fundamentals of the process for developing a POA&M.',
          link: 'https://encite.io/mod/scorm/view.php?id=203',
        },
      ];
      this.itemCount = this.items.length;
      //console.log(this.items);
    },
    pageClicked() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
  },
};
</script>
<style scoped>
.inner-header-bg {
  background-image: url('~@/assets/images/headers/courses.png');
  background-position: center center;
}
#blog-pagination li {
  margin: 0px;
}
.limit-lines-course {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
}
.cover {
  object-fit: cover;
  height: 225px;
  width: 100%;
}
</style>
