<template>
  <div class="main-content">
    <!-- BradCrumb header Start-->
    <section class="inner-header-bg">
      <div class="container">
        <!-- Breadcrumb Start-->
        <div class="section-breadcrumb contact-breadcrumb">
          <div class="container">
            <div class="row">
              <div
                class="col-md-12 justify-content-center align-self-center pb-2"
              >
                <div class="breadcrumb-content">
                  <div class="title-section">
                    <h1 class="title text-center text-white">Feedback: Introduction to Project Spectrum</h1>
                  </div>
                  <div class="breadcrumb-nav">
                    <ul class="breadcrumb-link text-center list-unstyled mb-0">
                      <li class="text-white">
                        <i class="mdi mdi-home theme-light-color pr-2"></i>
                        <a href="/#/">Home</a>
                      </li>
                      <li class="theme-light-color active">Training feedback</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Breadcrumb End -->
      </div>
    </section>
    <!-- BradCrumb header End -->
    <!-- Body Area Start-->
    <section class="section pt-5">
      <div class="container" style="min-height: 700px">
        <!-- surveySet - used to track when survey is ready to render -->
        <!-- <h5>PTAP Feedback</h5> -->
        <p v-if="captionVisible">Thank you for attending an APEX Accelerators Training! We value your input so that we can make our training effective and valuable. Please complete the questions below to let us know how we did.</p>
        <div id="surveyContainer" class="row surveyDiv">
          <!-- If you want to hide survey, comment the lines below -->
          <survey :survey="survey"></survey>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import * as SurveyVue from "survey-vue";
import "survey-vue/survey.min.css";
import axios from "axios";
var Survey = SurveyVue.Survey;
export default {
  components: {
    Survey,
  },
  data() {
    return {
      survey: {},
      captionVisible: true
    };
  },
  mounted() {
    this.setSurvey();
  },
  methods: {
    init() {
      /* The footer height no longer needs to be set */
      this.initFooterHeight();
    },
    setSurvey() {
      var surveyJSON = {
        title: "Survey",
        logoPosition: "right",
        pages: [
          {
            name: "page1",
            elements: [
              {
                type: "radiogroup",
                name: "materialPresented",
                title: "Was the material presented relevant to your current work?",
                choices: [
                  {
                    value: "Yes",
                    text: "Yes",
                  },
                  {
                    value: "Somewhat",
                    text: "Somewhat",
                  },
                  {
                    value: "Not at all",
                    text: "Not at all",
                  },
                ],
                isRequired: true,
              },
              {
                type: "radiogroup",
                name: "speakerKnowledgeable",
                title: "Was the speaker knowledgeable on the training topic?",
                choices: [
                  {
                    value: "Yes",
                    text: "Yes",
                  },
                  {
                    value: "Somewhat",
                    text: "Somewhat",
                  },
                  {
                    value: "Not at all",
                    text: "Not at all",
                  },
                ],
                isRequired: true,
              },
              {
                type: "radiogroup",
                name: "contentUnderstanding",
                title: "Do you feel you have a solid understanding of the content because of the training?",
                choices: [
                  {
                    value: "Yes",
                    text: "Yes",
                  },
                  {
                    value: "Somewhat",
                    text: "Somewhat",
                  },
                  {
                    value: "Not at all",
                    text: "Not at all",
                  },
                ],
                isRequired: true,
              },
              {
                type: "radiogroup",
                name: "understandableSpeaker",
                title: "Was the speaker easy to understand?",
                choices: [
                  {
                    value: "Yes",
                    text: "Yes",
                  },
                  {
                    value: "Somewhat",
                    text: "Somewhat",
                  },
                  {
                    value: "Not at all",
                    text: "Not at all",
                  },
                ],
                isRequired: true,
              },
              {
                type: "radiogroup",
                name: "engaingSpeaker",
                title: "Did the speaker present the information in an interesting, engaging way?",
                choices: [
                  {
                    value: "Yes",
                    text: "Yes",
                  },
                  {
                    value: "Somewhat",
                    text: "Somewhat",
                  },
                  {
                    value: "Not at all",
                    text: "Not at all",
                  },
                ],
                isRequired: true,
              },
              {
                type: "radiogroup",
                name: "trainingPace",
                title: "Did the training proceed at a comfortable pace?",
                choices: [
                  {
                    value: "Too fast",
                    text: "Too Fast",
                  },
                  {
                    value: "Just Right",
                    text: "Just Right",
                  },
                  {
                    value: "Too Slow",
                    text: "Too Slow",
                  },
                ],
                isRequired: true,
              },
              {
                type: "radiogroup",
                name: "webinarQuestions",
                title: "If you attended the live webinar, were you able to get all your questions answered during the training?",
                choices: [
                  {
                    value: "I did not attend the live webinar.",
                    text: "I did not attend the live webinar.",
                  },
                  {
                    value: "Yes",
                    text: "Yes",
                  },
                  {
                    value: "No",
                    text: "No",
                  },
                ],
                isRequired: true,
              },
              {
                type: "comment",
                name: "explorationWish",
                title: "What do you wish the trainer would have explored more?",
              },
              {
                type: "comment",
                name: "otherComments",
                title: "What other comments about the training would you like to share?",
              },
            ],
          },
        ],
        completedHtml: "<h3>Thank you for submitting your feedback.</h3>",
      };
      
      var survey = new SurveyVue.Model(surveyJSON);
      this.survey = survey;
      survey.onComplete.add(this.sendDataToServer);
    },
    sendDataToServer(survey) {
      //send Ajax request to your web server
      //alert("The results are: " + JSON.stringify(survey.data));
      axios.post("/api/survey/ps-introduction-feedback", survey.data).then((response) => {
        this.captionVisible = false;
      });
    },
  },
};
</script>

<style>
.sv_main {
    font-family: Rajdhani,sans-serif !important;
}

</style>