<template>
  <div class="main-content">
    <!-- BradCrumb header Start-->
    <section class="inner-header-bg-partner">
      <div class="container">
        <!-- Breadcrumb Start-->
        <div class="section-breadcrumb">
          <div class="container">
            <div class="row">
              <div
                class="col-md-12 justify-content-center align-self-center pb-2"
              >
                <div class="breadcrumb-content">
                  <div class="title-section">
                    <h1 class="title text-center text-white">
                      Project Spectrum
                    </h1>
                  </div>
                  <div class="breadcrumb-nav">
                    <ul class="breadcrumb-link text-center list-unstyled mb-0">
                      <li class="text-white">
                        <i class="mdi mdi-home theme-light-color pr-2"></i>
                        <a href="/#/">Home</a>
                      </li>
                      <li class="theme-light-color active">
                        Project Spectrum
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Breadcrumb End -->
      </div>
    </section>
    <!-- BradCrumb header End -->
    <!-- Body Area Start-->
    <section class="section">
      <div class="container">
        <div class="row justify-content-center">
          <!-- /* Project Spectrum – A Comprehensive Resource for Small Businesses */ -->
          <div class="col-12 py-2">
            <div class="mt-4 text-lg-left text-center">
              <div class="mt-4 text-lg-left text-center">
                <div class="section-title">
                  <span>PROJECT SPECTRUM</span>
                  <h2>
                    Project Spectrum – A Comprehensive Resource for Small
                    Businesses
                  </h2>
                </div>
              </div>
              <p>
                To assist small businesses achieve the cybersecurity maturity
                levels needed to become or remain part of the defense supply
                chain, the DoD Office of Small Business Programs (OSBP)
                developed a comprehensive platform to provide the tools and
                training needed to increase cybersecurity awareness and maintain
                compliance in accordance with DoD contracting requirements.

                <br /><br />
                Project Spectrum offers heightened, free-to-the-user
                cybersecurity awareness tools and training resources. This
                platform is an extremely valuable asset for small businesses
                that may be particularly susceptible to cyber threats due to
                funding and other resource limitations.

                <br /><br />
                The Project Spectrum platform provides up-to-date cybersecurity
                compliance and policy best practices and educates users on
                relevant topics that can affect business risk management. Their
                team of cyber advisors and outreach specialists share the tools
                and information small businesses need to thwart exploitation of
                various vulnerabilities like phishing, malware, and other
                cybersecurity threats. <br /><br />
                If your small business wants to become part of the Federal
                supply chain, Project Spectrum can help you implement or bolster
                your cybersecurity hygiene and compliance processes and
                programs.
                <br /><br /><strong>
                  Learn more about Project Spectrum and CMMC accreditation,
                  or complete a self-assessment of your company’s cyber
                  readiness.
                </strong>
              </p>
                <a href="https://www.projectspectrum.io">
            <b-button class="btn theme-btn rounded login-reg-btn"
              >Learn More</b-button
            ></a>
            </div>
		  </div>
		</div>
       </div>
    </section>
    <!-- Body Area End-->
  </div>
</template>
<script>
export default {
  name: 'projectspectrum',
  mounted() {
    this.init();
  },
  methods: {
    init() {
      /* The footer height no longer needs to be set */
      // this.initFooterHeight();
    },
    initFooterHeight() {
      var footerHeight = $('footer').outerHeight();
      $('.main-content').css('margin-bottom', footerHeight);
    },
  },
};
</script>
<style scoped>
.singup-button-desktop {
  background-color: #096cce;
  border-radius: 5px;
  border: none;
  color: white;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin: -5px;
  cursor: pointer;
}
.sba_tooltip {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: #008cba;
}
.centered-text {
  color: white;
  font-size: min(1.75vw, 1.5em);
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  padding: 0.25em;
  line-height: min(2.0vw, 1.25em);;
}
.sba_tooltip:hover {
  opacity: 1;
}
.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-padding {
  padding-right: 0;
  padding-left: 0;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

@media only screen and (min-width: 992px) {
  .arrow-height {
    height: 8em;
  }
}

@media only screen and (min-width: 576px) and (max-width: 991px) {
  .arrow-height {
    height: 4em;
  }
}

@media only screen and (max-width: 575px) {
  .arrow-height {
    height: 3em;
  }
}
</style>
