var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "main-content"
  }, [_vm._m(0), _c('section', {
    staticClass: "section"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('p', [_vm._v(" What’s in the news? Project Spectrum keeps an eye on the latest cybersecurity tech trends, emerging technologies, compliance standards, cyber threats, and much more! Check out the latest news from around the web. ")]), _c('div', {
    staticClass: "pb-4"
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Search News Articles..."
    },
    model: {
      value: _vm.searchQuery,
      callback: function ($$v) {
        _vm.searchQuery = $$v;
      },
      expression: "searchQuery"
    }
  })], 1), _vm.displayedNewsItems.length === 0 && _vm.searchQuery ? _c('p', {
    staticClass: "pb-2"
  }, [_vm._v(" No items match the search criteria... ")]) : _vm._e(), _vm.displayedNewsItems.length === 0 && !_vm.searchQuery ? _c('p', {
    staticClass: "pb-2"
  }, [_vm._v(" Check back soon for news posts... ")]) : _vm._e(), _c('div', {
    staticClass: "blog-post-grid"
  }, [_c('div', {
    staticClass: "row"
  }, _vm._l(_vm.displayedNewsItems, function (item) {
    return _c('div', {
      key: item.id,
      staticClass: "col-md-6 col-lg-4 mb-5"
    }, [_c('div', {
      staticClass: "blog-item border h-100"
    }, [_c('router-link', {
      staticClass: "blog-post-image",
      attrs: {
        "to": {
          name: 'newsdetail',
          query: {
            id: item.id
          }
        }
      }
    }, [_c('img', {
      staticClass: "cover",
      attrs: {
        "src": item.article.largeImageURL,
        "alt": item.photoDescription
      }
    })]), _c('div', {
      staticClass: "blog-post-info"
    }, [_c('router-link', {
      staticClass: "text-dark",
      attrs: {
        "to": {
          name: 'newsdetail',
          query: {
            id: item.id
          }
        }
      }
    }, [_c('h4', {
      staticClass: "blog-post-title"
    }, [_vm._v(" " + _vm._s(item.article.title) + " ")]), _c('p', {
      staticClass: "limit-lines-3"
    }, [_vm._v(_vm._s(item.article.headLine))]), _c('div', {
      staticClass: "post-event-bottom d-flex align-items-center"
    }, [_c('a', {
      staticClass: "blog-info-meta text-dark"
    }, [_vm._v(_vm._s(_vm.getFormattedDate(item.article.date)))]), _c('a', {
      staticClass: "blog-category text-dark"
    }, [_vm._v("News")])])]), _c('router-link', {
      staticClass: "blog-read-more",
      attrs: {
        "to": {
          name: 'newsdetail',
          query: {
            id: item.id
          }
        }
      }
    }, [_c('i', {
      staticClass: "mdi mdi-arrow-right"
    })])], 1)], 1)]);
  }), 0)]), _c('div', [_c('b-pagination', {
    attrs: {
      "id": "news-pagination",
      "total-rows": _vm.totalRows,
      "per-page": _vm.perPage,
      "align": "center"
    },
    on: {
      "page-click": _vm.pageClicked
    },
    model: {
      value: _vm.currentPage,
      callback: function ($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "inner-header-bg-techHub"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "section-breadcrumb"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12 justify-content-center align-self-center pb-2"
  }, [_c('div', {
    staticClass: "breadcrumb-content"
  }, [_c('div', {
    staticClass: "title-section"
  }, [_c('h1', {
    staticClass: "title text-center text-white"
  }, [_vm._v("Latest News")])]), _c('div', {
    staticClass: "breadcrumb-nav"
  }, [_c('ul', {
    staticClass: "breadcrumb-link text-center list-unstyled mb-0"
  }, [_c('li', {
    staticClass: "text-white"
  }, [_c('i', {
    staticClass: "mdi mdi-home theme-light-color pr-2"
  }), _c('a', {
    attrs: {
      "href": "/#/"
    }
  }, [_vm._v("Home")])]), _c('li', {
    staticClass: "theme-light-color active"
  }, [_vm._v("Latest News")])])])])])])])])])]);

}]

export { render, staticRenderFns }