<template>
  <div class="main-content">
    <!-- BradCrumb header Start-->
    <section class="inner-header-bg">
      <div class="container">
        <!-- Breadcrumb Start-->
        <div class="section-breadcrumb">
          <div class="container">
            <div class="row">
              <div
                class="col-md-12 justify-content-center align-self-center pb-2"
              >
                <div class="breadcrumb-content">
                  <div class="title-section">
                    <h1 class="title text-center text-white">Tool Details</h1>
                  </div>
                  <div class="breadcrumb-nav">
                    <ul class="breadcrumb-link text-center list-unstyled mb-0">
                      <li class="text-white">
                        <i class="mdi mdi-home theme-light-color pr-2"></i>
                        <a href="/#/">Home</a>
                      </li>
                      <li class="text-white">
                        <router-link :to="{ name: 'tool-list' }">
                          <a href="#">Tool Reviews</a>
                        </router-link>
                      </li>
                      <li class="theme-light-color active">Tool Details</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Breadcrumb End -->
      </div>
    </section>
    <!-- BradCrumb header End -->
    <!-- Blog Details Start-->
    <section class="section section-blog">
      <div class="container">
        <article>
          <!-- Row - Logo, Name, Creator, Share Options -->
          <b-row>
            <b-col sm="12" md="4" order-md="1">
              <img
                :src="item?.mainImage"
                :alt="item?.photoDescription"
                class="img-fluid img-thumbnail border-0 tool-logo"
            /></b-col>
            <b-col
              sm="12"
              md="8"
              class="text-center text-md-left align-items-center"
            >
              <div>
                <h1 class="mb-0 pb-0">
                  {{ item?.title }}
                </h1>
                <p class="blog-post-title my-0">
                  {{ item?.toolCreatedBy }}
                </p>
                <b-badge pill variant="primary" class="font-weight-normal">{{
                  item?.toolCategory
                }}</b-badge>
                <SocialShareBookmark
                  class="justify-content-center justify-content-md-start"
                  id="social-share"
                  :title="title"
                  :description="description"
                  type="tool"
                /></div
            ></b-col>
          </b-row>
          <b-row class="my-2 descriptor">
            <b-col xs="12" sm="6" md="4">
              <span class="row col py-0 mt-0 d-flex text-center"
                ><b>Price Range:</b>&nbsp;{{ item?.toolPriceRange }}</span
              >
              <span class="row col py-0"
                ><b>Creator:</b>&nbsp;{{ item?.toolCreatedBy }}</span
              >
              <span class="row col py-0"
                ><b>Founded In:</b>&nbsp;{{
                  item?.toolCompanyFoundingDate
                }}</span
              >
            </b-col>
            <b-col xs="12" sm="6" md="4">
              <span class="row col py-0 mt-0"
                ><b>Cost Model:</b>&nbsp;{{ item?.toolCostModel }}</span
              >
              <span class="row col py-0"
                ><b>Ease of Use:</b>&nbsp;{{ item?.toolEaseOfUse }}</span
              >
              <span class="row col py-0"
                ><b>Typical Users:</b>&nbsp;{{ item?.toolTypicalUsers }}</span
              >
            </b-col>
            <b-col xs="12" sm="6" md="4">
              <span class="row col py-0 mt-0"
                ><b>Free Training Resources:</b>&nbsp;
                <font-awesome-icon
                  v-if="item?.toolFreeTrainingResourcesAvailable"
                  class="pl-1"
                  size="lg"
                  :icon="['fas', 'check-circle']"
                />
                <font-awesome-icon
                  v-else
                  class="pl-1"
                  size="lg"
                  :icon="['fas', 'times-circle']"
                />
              </span>
              <span class="row col py-0"
                ><b>Paid Training Resources:</b>&nbsp;<font-awesome-icon
                  v-if="item?.toolPaidTraining"
                  class="pl-1"
                  size="lg"
                  :icon="['fas', 'check-circle']" />
                <font-awesome-icon
                  v-else
                  class="pl-1"
                  size="lg"
                  :icon="['fas', 'times-circle']"
              /></span>
              <span class="row col py-0"
                ><b>Website:</b>&nbsp;<a
                  target="_blank"
                  :href="item?.toolWebsite"
                  >{{ item?.toolWebsite }}</a
                ></span
              >
            </b-col>
          </b-row>
          <!-- Row - Description -->
          <b-row>
            <b-col sm="12" lg="6"
              ><p class="mb-0 title"><b>Description</b></p>
              <span v-html="item?.review"></span></b-col
            >
            <b-col sm="12" md="6" lg="3">
              <p class="mb-0 title"><b>Related Certifications</b></p>
              <b-list-group
                v-if="item?.toolRelatedCertifications?.certification.length > 0"
              >
                <b-list-group-item
                  v-for="cert in item?.toolRelatedCertifications?.certification"
                  :key="cert"
                  >{{ cert }}</b-list-group-item
                >
              </b-list-group>
              <b-list-group v-else>
                <b-list-group-item>None</b-list-group-item>
              </b-list-group>
            </b-col>
            <b-col sm="12" md="6" lg="3"
              ><p class="mb-0 title"><b>Known Clients</b></p>
              <b-list-group
                v-if="item?.toolKnownClients?.knownClient.length > 0"
              >
                <b-list-group-item
                  v-for="cert in item?.toolKnownClients?.knownClient"
                  :key="cert"
                  >{{ cert }}</b-list-group-item
                >
              </b-list-group>
              <b-list-group v-else>
                <b-list-group-item>None</b-list-group-item>
              </b-list-group>
            </b-col>
          </b-row>
        </article>
        <b-row>
          <b-col sm="12" lg="6" order="1" order-lg="0"
            ><p class="mb-0 title"><b>Comments</b></p>
            <b-form-input
              id="comment-search"
              v-model="searchQuery"
              placeholder="Search Comments..."
              class="mb-2"
            ></b-form-input>
            <div>
              <b-list-group v-if="displayeditems.length > 0">
                <b-list-group-item
                  v-for="comment in displayeditems"
                  :key="comment.id"
                  ><p class="my-0 font-weight-bold">
                    {{ comment.name }}
                  </p>
                  <p class="my-0">
                    {{ comment.commentBody }}
                  </p>
                  <p class="my-0 text-muted comment-date">
                    {{ new Date(comment.commentDate).toLocaleString() }}
                  </p></b-list-group-item
                >
              </b-list-group>
              <p class="pb-2" v-if="displayeditems.length === 0 && searchQuery">
                No comments match the search criteria...
              </p>
              <p
                class="pb-2"
                v-if="displayeditems.length === 0 && !searchQuery"
              >
                No comments for this tool yet...
              </p>
            </div>
            <!-- End Comments -->
            <b-pagination
              class="mt-3"
              id="blog-pagination"
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="center"
              @page-click="pageClicked"
            ></b-pagination>
          </b-col>
          <b-col sm="12" lg="6" order="0" order-lg="1"
            ><p class="mb-0 title"><b>Add New Comment</b></p>
            <b-form-textarea
              id="textarea"
              v-model="addComment"
              placeholder="Enter your comments here..."
              rows="5"
              max-rows="5"
            ></b-form-textarea>
            <b-alert v-if="commentPosted" show class="mt-2 mb-0"
              >Your comment has been submitted. The comment will only be visible
              after moderation and approval from Project Spectrum.
            </b-alert>
            <b-overlay
              :show="commentPosting"
              rounded
              opacity="0.6"
              spinner-small
              spinner-variant="primary"
              class="d-inline-block"
              @hidden="onHidden"
            >
              <b-button
                ref="button"
                :disabled="commentPosting || addComment.length === 0"
                variant="primary"
                @click="postToolComment(addComment, id)"
                class="mt-2"
              >
                Post Comment
              </b-button>
            </b-overlay>
            <!-- <b-button
              variant="primary"
              :disabled="addComment.length === 0"
              class="mt-2"
            >
              Post Comment
            </b-button> -->
          </b-col>
        </b-row>
      </div>
    </section>
    <!-- Blog Details Section End-->
  </div>
</template>
<script>
import { BFormInput } from 'bootstrap-vue';
import axios from 'axios';
import SocialShareBookmark from '../social-share-bookmark.vue';
import { handleApiError } from '../../utils/authentication';
export default {
  name: 'tool-detail',
  components: {
    BFormInput,
    SocialShareBookmark,
  },
  props: {
    id: String,
  },
  data() {
    return {
      isLoading: false,
      query: '',
      item: {},
      comments: [],
      addComment: '',
      linkCopyText: 'Click to copy url link',
      commentPosted: false,
      commentPosting: false,
      // Pagination
      searchQuery: '',
      commentCount: 0,
      currentPage: 1,
      perPage: 3,
    };
  },
  async created() {
    if (this.id) {
      await this.getResource(this.id);
      await this.getToolComments(this.id);
    } else {
      this.$router.push({ path: 'cyber-circuits' });
    }
  },
  mounted() {
    this.init();
  },
  computed: {
    title() {
      return this.item?.title || 'Title';
    },
    description() {
      return this.item?.description || 'Description';
    },
    headline() {
      return this.item?.headLine || 'HeadLine';
    },
    itemsFromSearchQuery() {
      if (this.searchQuery) {
        return this.comments.filter((item) => {
          return this.searchQuery
            .toLowerCase()
            .split(' ')
            .every(
              (v) =>
                item.commentBody.toLowerCase().includes(v) ||
                item.commentDate.toLowerCase().includes(v) ||
                item.name.toLowerCase().includes(v)
            );
        });
      } else {
        return this.comments;
      }
    },
    displayeditems() {
      return this.itemsFromSearchQuery.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
      );
    },
    totalRows() {
      return this.itemsFromSearchQuery.length;
    },
  },
  methods: {
    init() {
      /* The footer height no longer needs to be set */
      // this.initFooterHeight();
    },
    initFooterHeight() {
      var footerHeight = $('footer').outerHeight();
      $('.main-content').css('margin-bottom', footerHeight);
    },
    async getResource(id) {
      /*  API call to server: GET /api/resources/blogs */
      axios
        .get(`/api/resources/toolReviews/${id}`)
        .then((response) => {
          if (response.data.Items.length > 0) {
            /** Does an item exist for the query id */
            this.item = response.data.Items[0];
            //console.log('Tool', response.data);
          } else {
            /** If no item exists with the id, redirect to list*/
            this.$router.push({ path: 'tool-list' });
          }
        })
        .catch((error) => {
          /** On error, redirect back to list */
          console.error(error);
          this.$router.push({ path: 'tool-list' });
        });
    },
    async getToolComments(id) {
      /*  API call to server: GET /api/toolcomments/approved/:reviewId */
      axios
        .get(`/api/toolcomments/approved/${id}`)
        .then((response) => {
          //console.log('Tool Comments', response.data);
          if (response.data.length >= 0) {
            /** Does an item exist for the query id */
            this.comments = response.data;
          } else {
            /** If no item exists with the id, redirect to list*/
            this.$router.push({ path: 'tool-list' });
          }
        })
        .catch((error) => {
          /** On error, redirect back to list */
          handleApiError(error, this.$router);
        });
    },
    async postToolComment(comment, id) {
      this.commentPosting = true;
      /*  API call to server: GET /api/toolcomments/approved/:reviewId */
      axios
        .post(`/api/toolcomments`, {
          commentBody: comment,
          reviewId: id,
        })
        .then((response) => {
          //console.log('Post Comment Response', response.data);
          this.commentPosted = true;
        })
        .catch((error) => {
          /** On error, redirect back to list */
          handleApiError(error, this.$router);
        })
        .finally(() => {
          this.addComment = '';
          this.commentPosting = false;
        });
    },
    pageClicked() {
      // Do not scroll on this page
      // window.scrollTo({top: 0, behavior: 'smooth'})
    },
    onHidden() {
      // Return focus to the button once hidden
      this.$refs.button.focus();
    },
  },
};
</script>
<style scoped>
.centered-link {
  display: flex;
  justify-content: center;
  align-items: center;
}
.comment-date {
  font-size: 14px;
}
#comment-search {
  height: 50px;
}
.title {
  font-size: 20px;
}
.descriptor {
  font-size: 17px;
}
#social-share {
  margin-left: -0.25rem;
}
.tool-logo {
  height: 100%;
  width: 100%;
  object-fit: contain;
  max-height: 175px;
}
</style>
