var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-12"
  }, [_vm._m(0), _c('h2', {
    staticClass: "pt-2 pb-2"
  }, [_vm._v("FIND AN APEX ACCELERATOR NEAR YOU ")]), _c('p', [_vm._v("Enter your business address, city, or ZIP code in the search box to find the location that serves you.")]), _c('p', [_vm._v("If you are a Native American business, or if you wish to explore the potential of selling to Tribal governments, we invite you to contact the Native American Accelerator that serves your area. "), _c('a', {
    attrs: {
      "href": "#NativeAmerican"
    },
    on: {
      "click": _vm.scrollToElement
    }
  }, [_vm._v("Click here")]), _vm._v(" for the Native American APEX Accelerators map for contact information.")]), _c('div', {
    staticClass: "searchBarWrapper"
  }, [_c('div', {
    staticClass: "row no-gutters"
  }, [_c('div', {
    staticClass: "col-lg-10 col-xs-8 col-md-8"
  }, [_c('div', {
    staticClass: "searchBarInputWrapper"
  }, [_c('div', {
    staticStyle: {
      "width": "100%"
    }
  }, [_c('GmapAutocomplete', {
    ref: "autocomplete",
    attrs: {
      "placeholder": "Enter address, city or ZIP code"
    },
    on: {
      "place_changed": _vm.setZoom
    }
  })], 1)])]), _c('div', {
    staticClass: "col-lg-2 col-xs-4 col-md-4"
  }, [_c('div', {
    staticClass: "searchIcon",
    on: {
      "click": _vm.searchButtonClicked
    }
  }, [_c('div', {
    staticClass: "searchIconLabel"
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": ['fas', 'search']
    }
  }), _c('span', [_vm._v("SEARCH")])], 1)])])])])])]), _c('GmapMap', {
    ref: "mapRef",
    staticStyle: {
      "width": "100%",
      "height": "420px"
    },
    attrs: {
      "center": _vm.center,
      "zoom": _vm.mapZoom
    }
  }, [_vm._l(_vm.markers, function (m, index) {
    return _c('GmapMarker', {
      key: index,
      attrs: {
        "clickable": true,
        "draggable": false,
        "position": m.position,
        "icon": {
          url: require('../assets/images/icon-image/marker.png'),
          // size: {width: 60, height: 90, f: 'px', b: 'px',},
          // scaledSize: {width: 30, height: 45, f: 'px', b: 'px',},
          size: {
            width: 35,
            height: 50,
            f: 'px',
            b: 'px'
          },
          scaledSize: {
            width: 35,
            height: 50,
            f: 'px',
            b: 'px'
          }
        }
      },
      on: {
        "click": function ($event) {
          return _vm.openInfoWindowTemplate(index);
        }
      }
    });
  }), _c('gmap-info-window', {
    attrs: {
      "options": {
        maxWidth: 300,
        pixelOffset: {
          width: 0,
          height: -35
        }
      },
      "position": _vm.infoWindow.position,
      "opened": _vm.infoWindow.open
    },
    on: {
      "closeclick": function ($event) {
        _vm.infoWindow.open = false;
      }
    }
  }, [_c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.infoWindow.template)
    }
  })])], 2), _c('div', {
    attrs: {
      "id": "NativeAmerican"
    }
  }), _c('h3', {
    staticClass: "mt-5 pt-5 map-subtitle"
  }, [_vm._v("NATIVE AMERICAN APEX ACCELERATORS")]), _c('p', [_vm._v("There are six specific APEX Accelerators designated to serve Native-owned businesses doing business with federal, state, local and tribal governments. These Accelerators bring expert knowledge of laws and certifications specific to Native business and an understanding of the cultures and communities to help Native and non-Native firms be successful.")]), _c('p', {
    staticClass: "mb-5"
  }, [_vm._v("For assistance, please contact the Native American APEX Accelerator that serves your area.")]), _c('img', {
    staticClass: "img-fluid",
    attrs: {
      "src": require("../assets/images//native-american/Native_APEX_Map.png")
    }
  })], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "about-title section-title"
  }, [_c('span', [_vm._v(" LOCATIONS")])]);

}]

export { render, staticRenderFns }