<template>
  <b-modal
    size="md"
    ref="my-modal"
    title="Migrate Account"
    @ok="handleChooseOrganization"
    @cancel="handleChooseIndividual"
    ok-title="Organization Member"
    cancel-title="Individual User"
    ok-variant="primary"
    cancel-variant="primary"
    no-close-on-backdrop
    no-close-on-esc
    no-close-on-x
    hide-header-close
    :busy="busy"
  >
    <p class="my-2">
      Do you want to continue as a Organization Member for
      <b>{{ organization }}</b
      >, or become an <b>Individual User</b>?
    </p>
    <b-alert variant="danger" show v-if="apiErrorMessage"
      >Error migrating account. Please reach out to the Project Spectrum Support
      Team
      <a href="mailto:support@projectspectrum.io">support@projectspectrum.io</a
      >.</b-alert
    >
  </b-modal>
</template>
<script>
import axios from 'axios';
export default {
  name: 'migrate-modal',
  props: ['organization'],
  data() {
    return {
      apiErrorMessage: false,
      busy: false,
      memberMigrateSuccess: false,
      memberSuccessMessage: ''
    };
  },
  methods: {
    show() {
      this.$refs['my-modal'].show();
    },
    hide() {
      this.$refs['my-modal'].hide();
    },
    async handleChooseOrganization(bvModalEvt) {
      // Prevent Modal from closing on button press
      bvModalEvt.preventDefault();
      await this.migrateOrganization();
    },
    async handleChooseIndividual(bvModalEvt) {
      bvModalEvt.preventDefault();
      await this.migrateIndividual();
    },
    async migrateOrganization() {
      this.busy = true;
      //console.log('Migrating to OrganizationMember');
      axios
        .post(`/api/user/migrate/group`, {
          user_group: 'OrganizationMember',
        })
        .then((response) => {
          //console.log('Migrate to Organization Member Response', response);
          this.apiErrorMessage = false;
          this.$emit('account-migrated');
          this.memberMigrateSuccess = true;
          this.memberSuccessMessage = "We are happy to welcome you as the newest member of the Project Spectrum community! As an individual organizational member, please take advantage of all of the resources at your disposal, including personalized cybersecurity training, virtual labs, informational webinars, Cyber Circuits Sessions (hear from industry experts), white papers, blogs, compliance tool reviews, and the ability to see your organizations security posture at a glance."
          //this.hide();
        })
        .catch((error) => {
          this.apiErrorMessage = true;
          handleApiError(error, this.$router);
        })
        .finally(() => {
          this.busy = false;
        });
    },
    async migrateIndividual() {
      this.busy = true;
      //console.log('Migrating to Individual');
      axios
        .post(`/api/user/migrate/group`, {
          user_group: 'Individual',
        })
        .then((response) => {
          //console.log('Migrate to Individual Response', response);
          this.apiErrorMessage = false;
          this.$emit('account-migrated'); 
          this.memberMigrateSuccess = true;
          this.memberSuccessMessage = "We are happy to welcome you as the newest member of the Project Spectrum community! As an individual member, please take advantage of all of the resources at your disposal, including personalized cybersecurity training, informational webinars, Cyber Circuits Sessions (hear from industry experts), white papers, blogs and much more!"
          //this.hide();
        })
        .catch((error) => {
          this.apiErrorMessage = true;
          handleApiError(error, this.$router);
        })
        .finally(() => {
          this.busy = false;
        });
    },
  },
};
</script>
<style scoped>
.modal-header .close {
  display: none !important;
}
</style>
