import { render, staticRenderFns } from "./speaker-request.vue?vue&type=template&id=96bb9ca2&scoped=true&"
var script = {}
import style0 from "./speaker-request.vue?vue&type=style&index=0&id=96bb9ca2&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "96bb9ca2",
  null
  
)

export default component.exports