<template>
  <div class="main-content bg-light">
    <!-- BradCrumb header Start-->
    <section class="inner-header-bg-partner">
      <div class="container">
        <!-- Breadcrumb Start-->
        <div class="section-breadcrumb">
          <div class="container">
            <div class="row">
              <div
                class="col-md-12 justify-content-center align-self-center pb-2"
              >
                <div class="breadcrumb-content">
                  <div class="title-section">
                    <h1 class="title text-center text-white">
                      MPP Pilot Register
                    </h1>
                  </div>
                  <div class="breadcrumb-nav">
                    <ul class="breadcrumb-link text-center list-unstyled mb-0">
                      <li class="text-white">
                        <i class="mdi mdi-home theme-light-color pr-2"></i>
                        <a href="/#/">Home</a>
                      </li>
                      <li class="theme-light-color active">
                        <router-link to="pilot-program">MPP Pilot</router-link>
                      </li>
                      <li class="theme-light-color active">Register</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Breadcrumb End -->
      </div>
    </section>
    <!-- BradCrumb header End -->
    <!-- Register Area Start-->
    <section class="login-form section bg-light">
      <div class="container">
        <div class="login-container">
          <div class="row justify-content-center">
            <!-- Show success message when the user submits the form successfully -->
            <div v-if="submissionSuccessful">
              <b-alert show
                ><h5>
                  Thank you for applying to the Cybersecurity for Defense
                  Industrial Base MPP Pilot Program.
                </h5>
                <p>
                  Your application is now saved. You will receive a notification
                  regarding the status of your application. Please check the
                  <router-link :to="{ name: 'pilot-program' }"
                    >Pilot Program
                  </router-link>
                  instructions for your next step, completing the readiness
                  check.
                </p></b-alert
              >
            </div>

            <!-- Show login/signup prompt is the user is not logged in, or does not have MFA enabled -->
            <div v-if="!isLoggedIn || !isEnabledMFA">
              <b-alert show
                >Only participants in the Mentor-Protege Program (MPP) can
                register for Pilot Program. If there are any questions, please
                contact us at
                <a href="mailto:outreach@projectspectrum.iom"
                  >outreach@projectspectrum.io</a
                >
                .<br /><br />
                Please
                <router-link :to="{ name: 'login' }">Login </router-link>
                or
                <router-link :to="{ name: 'signup' }">Sign Up </router-link>for
                a free Project Spectrum account. Make sure you have
                <b>Multifactor Authentication (MFA) enabled</b> on your
                <router-link :to="{ name: 'my-account' }"
                  >Account Settings </router-link
                >.</b-alert
              >
            </div>

            <!-- Show MPP question is the user is logged in with MFA -->
            <div
              v-if="isLoggedIn && isEnabledMFA && !submissionSuccessful"
              class="mt-1 w-100"
            >
              <form-wizard
                @onComplete="onComplete"
                @onCannotCompleteFromErrors="onCannotCompleteFromErrors"
                :customButtonLabel="customButtonLabel"
                :errorMessage="errorMessage"
                :successMessage="successMessage"
                :hideSubmitButton="!formData.mpp"
                class="width-override form-floating"
              >
                <tab-content
                  title="See If You Qualify for the Cybersecurity for Defense Industrial Base MPP Pilot Program"
                  :selected="true"
                >
                  <!-- Is your company a participant in the Mentor Protégé Program (MPP)? -->
                  <b-form-group
                    label="Is your company a participant in the Mentor Protégé Program (MPP)?"
                  >
                    <b-form-radio v-model="formData.mpp" :value="true"
                      >Yes</b-form-radio
                    >
                    <b-form-radio v-model="formData.mpp" :value="false"
                      >No</b-form-radio
                    >
                  </b-form-group>

                  <div v-if="formData.mpp !== null && !formData.mpp">
                    <b-alert show
                      >Only participants in the Mentor-Protege Program (MPP) can
                      register for Pilot Program. If there are any questions,
                      please contact us at
                      <a href="mailto:outreach@projectspectrum.iom"
                        >outreach@projectspectrum.io</a
                      >
                      .</b-alert
                    >
                  </div>

                  <!-- Form - Shown only if MPP -->
                  <div v-if="formData.mpp">
                    <h5 class="py-4">Submitter Details</h5>
                    <b-row>
                      <!-- First Name -->
                      <b-col class="py-1" sm="12" md="6">
                        <div class="form-label-group">
                          <label for="firstName">First Name*</label>
                          <input
                            type="text"
                            id="firstName"
                            class="form-control"
                            :class="hasError('firstName') ? 'is-invalid' : ''"
                            placeholder="First Name"
                            v-model="formData.firstName"
                          />
                          <div
                            v-if="hasError('firstName')"
                            class="invalid-feedback"
                          >
                            <div
                              class="error"
                              v-if="!$v.formData.firstName.required"
                            >
                              Please provide your first name
                            </div>
                          </div>
                        </div>
                      </b-col>
                      <!-- Last Name -->
                      <b-col class="py-1" sm="12" md="6">
                        <div class="form-group">
                          <label for="lastName">Last Name*</label>
                          <input
                            type="text"
                            class="form-control"
                            :class="hasError('lastName') ? 'is-invalid' : ''"
                            placeholder="Last Name"
                            v-model="formData.lastName"
                          />
                          <div
                            v-if="hasError('lastName')"
                            class="invalid-feedback"
                          >
                            <div
                              class="error"
                              v-if="!$v.formData.lastName.required"
                            >
                              Please provide your last name
                            </div>
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                    <hr class="col-sm-12" />
                    <h5 class="py-4">Company Information</h5>
                    <!--Do you want to enter your company's CAGE code or DUNS number and look up some of the required fields?-->
                    <b-form-group
                      class="font-italic"
                      label="Do you want to enter your company's CAGE code or DUNS number and look up some of the required fields?"
                    >
                      <b-form-radio
                        v-model="formData.useCompanyCodeLookup"
                        :value="true"
                        >Yes</b-form-radio
                      >
                      <b-form-radio
                        v-model="formData.useCompanyCodeLookup"
                        :value="false"
                        >No</b-form-radio
                      >
                    </b-form-group>

                    <!-- Company Lookup Code -->
                    <div
                      class="mb-4 p-4 border border-secondary rounded"
                      v-if="shouldShowCompanyLookup"
                    >
                      <label for="companyCodeLookup"
                        >Company's CAGE code or DUNS number *</label
                      >
                      <b-row>
                        <b-col class="py-1" sm="12" md="6">
                          <div class="form-label-group">
                            <input
                              type="text"
                              id="companyCodeLookup"
                              class="form-control"
                              :class="
                                hasError('companyCodeLookup')
                                  ? 'is-invalid'
                                  : ''
                              "
                              v-model="formData.companyCodeLookup"
                            />
                            <div
                              v-if="hasError('companyCodeLookup')"
                              class="invalid-feedback"
                            >
                              <div
                                class="error"
                                v-if="!$v.formData.companyCodeLookup.required"
                              >
                                Please provide your company's CAGE code or DUNS
                                number.
                              </div>
                            </div>
                            <small
                              id="passwordHelpBlock"
                              class="form-text text-muted"
                            >
                              5 character alpha-numeric CAGE code, or 9
                              character numeric DUNS number
                            </small>
                          </div>
                        </b-col>
                        <b-col class="py-1" sm="12" md="6">
                          <b-button
                            @click="onLookupCageCodeOrDuns"
                            variant="btn w-100 rounded centered-link theme-btn-secondary"
                            >Lookup Code</b-button
                          >
                          <b-alert
                            variant="success"
                            v-if="companyLookupSuccessMessage"
                            class="p-2 px-3 my-1"
                            show
                            >{{ companyLookupSuccessMessage }}</b-alert
                          >
                          <b-alert
                            variant="danger"
                            v-if="companyLookupErrorMessage"
                            class="p-2 px-3 my-1"
                            show
                            >{{ companyLookupErrorMessage }}</b-alert
                          >
                        </b-col>
                      </b-row>
                    </div>
                    <b-row>
                      <!-- DUNS Number -->
                      <b-col class="py-1" sm="12" md="6">
                        <div class="form-group">
                          <label for="DUNSNumber">Company DUNS Number</label>
                          <input
                            type="text"
                            class="form-control"
                            :class="hasError('DUNSNumber') ? 'is-invalid' : ''"
                            placeholder="DUNS Number"
                            v-model="formData.DUNSNumber"
                          />
                          <small
                            id="passwordHelpBlock"
                            class="form-text text-muted"
                            v-if="!hasError('DUNSNumber')"
                          >
                            9 character numeric identifier
                          </small>
                          <div
                            v-if="hasError('DUNSNumber')"
                            class="invalid-feedback"
                          >
                            <div
                              class="error"
                              v-if="!$v.formData.DUNSNumber.required"
                            >
                              Enter your company's 9 character numeric DUNS
                              Number
                            </div>
                          </div>
                        </div>
                      </b-col>
                      <!-- CAGE Code -->
                      <b-col class="py-1" sm="12" md="6">
                        <div class="form-group">
                          <label for="CAGECode">Company CAGE Code</label>
                          <input
                            type="text"
                            class="form-control"
                            :class="hasError('CAGECode') ? 'is-invalid' : ''"
                            placeholder="CAGE code"
                            v-model="formData.CAGECode"
                          />
                          <small
                            id="passwordHelpBlock"
                            class="form-text text-muted"
                            v-if="!hasError('CAGECode')"
                          >
                            5 character alpha-numeric identifier
                          </small>
                          <div
                            v-if="hasError('CAGECode')"
                            class="invalid-feedback"
                          >
                            <div
                              class="error"
                              v-if="!$v.formData.CAGECode.required"
                            >
                              Enter your company's 5 character alpha-numeric
                              CAGE Code
                            </div>
                          </div>
                        </div>
                      </b-col>
                      <!-- NAICS Codes -->
                      <b-col class="py-1" sm="12" md="12">
                        <div class="form-group">
                          <label for="NAICSCodes">NAICS Codes</label>
                          <input
                            type="text"
                            class="form-control"
                            :class="hasError('NAICSCodes') ? 'is-invalid' : ''"
                            placeholder="NAICS codes (511120,...)"
                            v-model="formData.NAICSCodes"
                          />
                          <small
                            id="passwordHelpBlock"
                            class="form-text text-muted"
                            v-if="!hasError('NAICSCodes')"
                          >
                            Numerics separated by space or comma.
                          </small>
                          <div
                            v-if="hasError('NAICSCodes')"
                            class="invalid-feedback"
                          >
                            <div
                              class="error"
                              v-if="!$v.formData.NAICSCodes.required"
                            >
                              Enter your company's numeric NAICS codes,
                              separated by spaces or commas (#,#,#...)
                            </div>
                          </div>
                        </div>
                      </b-col>

                      <!-- Street Address 1 -->
                      <b-col class="py-1" sm="12" md="6">
                        <div class="form-group">
                          <label for="streetaddress1">Street Address 1*</label>
                          <input
                            type="text"
                            class="form-control"
                            :class="
                              hasError('streetaddress1') ? 'is-invalid' : ''
                            "
                            placeholder="Address"
                            v-model="formData.streetaddress1"
                          />
                          <small
                            id="passwordHelpBlock"
                            class="form-text text-muted"
                            v-if="!hasError('streetaddress1')"
                          >
                            Street address, P.O. box, company name
                          </small>
                          <div
                            v-if="hasError('streetaddress1')"
                            class="invalid-feedback"
                          >
                            <div
                              class="error"
                              v-if="!$v.formData.streetaddress1.required"
                            >
                              Please provide organization street address
                            </div>
                          </div>
                        </div>
                      </b-col>
                      <!-- Street Address 2 -->
                      <b-col class="py-1" sm="12" md="6">
                        <div class="form-group">
                          <label for="streetaddress2">Street Address 2</label>
                          <input
                            type="text"
                            class="form-control"
                            :class="
                              hasError('streetaddress2') ? 'is-invalid' : ''
                            "
                            placeholder="Address Continued..."
                            v-model="formData.streetaddress2"
                          />
                          <small
                            id="passwordHelpBlock"
                            class="form-text text-muted"
                            v-if="!hasError('streetaddress2')"
                          >
                            Apartment, suite, unit, building, floor, etc.
                          </small>
                        </div>
                      </b-col>
                      <!-- City -->
                      <b-col class="py-1" sm="12" md="6">
                        <div class="form-group">
                          <label for="city">City*</label>
                          <input
                            type="text"
                            class="form-control"
                            :class="hasError('city') ? 'is-invalid' : ''"
                            placeholder="City"
                            v-model="formData.city"
                          />
                          <div v-if="hasError('city')" class="invalid-feedback">
                            <div
                              class="error"
                              v-if="!$v.formData.city.required"
                            >
                              Please provide organization city
                            </div>
                          </div>
                        </div>
                      </b-col>
                      <!-- State -->
                      <b-col class="py-1" sm="12" md="6">
                        <div class="form-group">
                          <label for="state">State*</label>
                          <select
                            :class="hasError('state') ? 'is-invalid' : ''"
                            class="form-control"
                            v-model="formData.state"
                          >
                            <option value="">Select State</option>
                            <option value="AL">Alabama</option>
                            <option value="AK">Alaska</option>
                            <option value="AZ">Arizona</option>
                            <option value="AR">Arkansas</option>
                            <option value="CA">California</option>
                            <option value="CO">Colorado</option>
                            <option value="CT">Connecticut</option>
                            <option value="DE">Delaware</option>
                            <option value="DC">District Of Columbia</option>
                            <option value="FL">Florida</option>
                            <option value="GA">Georgia</option>
                            <option value="HI">Hawaii</option>
                            <option value="ID">Idaho</option>
                            <option value="IL">Illinois</option>
                            <option value="IN">Indiana</option>
                            <option value="IA">Iowa</option>
                            <option value="KS">Kansas</option>
                            <option value="KY">Kentucky</option>
                            <option value="LA">Louisiana</option>
                            <option value="ME">Maine</option>
                            <option value="MD">Maryland</option>
                            <option value="MA">Massachusetts</option>
                            <option value="MI">Michigan</option>
                            <option value="MN">Minnesota</option>
                            <option value="MS">Mississippi</option>
                            <option value="MO">Missouri</option>
                            <option value="MT">Montana</option>
                            <option value="NE">Nebraska</option>
                            <option value="NV">Nevada</option>
                            <option value="NH">New Hampshire</option>
                            <option value="NJ">New Jersey</option>
                            <option value="NM">New Mexico</option>
                            <option value="NY">New York</option>
                            <option value="NC">North Carolina</option>
                            <option value="ND">North Dakota</option>
                            <option value="OH">Ohio</option>
                            <option value="OK">Oklahoma</option>
                            <option value="OR">Oregon</option>
                            <option value="PA">Pennsylvania</option>
                            <option value="RI">Rhode Island</option>
                            <option value="SC">South Carolina</option>
                            <option value="SD">South Dakota</option>
                            <option value="TN">Tennessee</option>
                            <option value="TX">Texas</option>
                            <option value="UT">Utah</option>
                            <option value="VT">Vermont</option>
                            <option value="VA">Virginia</option>
                            <option value="WA">Washington</option>
                            <option value="WV">West Virginia</option>
                            <option value="WI">Wisconsin</option>
                            <option value="WY">Wyoming</option>
                          </select>
                          <div
                            v-if="hasError('state')"
                            class="invalid-feedback"
                          >
                            <div
                              class="error"
                              v-if="!$v.formData.state.required"
                            >
                              Please provide organization state
                            </div>
                          </div>
                        </div>
                      </b-col>
                      <!-- Zip Code -->
                      <b-col class="py-1" sm="12" md="6">
                        <div class="form-group">
                          <label for="zipCode">Zip Code*</label>
                          <input
                            type="number"
                            class="form-control"
                            :class="hasError('zipCode') ? 'is-invalid' : ''"
                            placeholder="Zip Code"
                            v-model="formData.zipCode"
                          />
                          <div
                            v-if="hasError('zipCode')"
                            class="invalid-feedback"
                          >
                            <div
                              class="error"
                              v-if="!$v.formData.zipCode.required"
                            >
                              Please provide your organiztions zip code
                            </div>
                          </div>
                        </div>
                      </b-col>
                      <!-- Country -->
                      <b-col class="py-1" sm="12" md="6">
                        <div class="form-group">
                          <label for="country">Country</label>
                          <input
                            type="text"
                            class="form-control"
                            :class="hasError('country') ? 'is-invalid' : ''"
                            placeholder="Country"
                            v-model="formData.country"
                          />
                        </div>
                      </b-col>

                      <!-- Organization Type -->
                      <hr class="col-sm-12" />
                      <h5 class="p-0 py-4 col-sm-12">Company Details</h5>

                      <b-row>
                        <!-- Please provide the name of your mentor. -->
                        <b-col class="py-1" sm="12" md="6">
                          <div class="form-label-group">
                            <label for="mentorCompany"
                              >Name of your MPP mentor*</label
                            >
                            <input
                              type="text"
                              id="mentorCompany"
                              class="form-control"
                              :class="
                                hasError('mentorCompany') ? 'is-invalid' : ''
                              "
                              placeholder="Mentor Name"
                              v-model="formData.mentorCompany"
                            />
                            <div
                              v-if="hasError('mentorCompany')"
                              class="invalid-feedback"
                            >
                              <div
                                class="error"
                                v-if="!$v.formData.mentorCompany.required"
                              >
                                Please provide the name of your MPP mentor
                              </div>
                            </div>
                          </div>
                        </b-col>
                        <!-- Please indicate your contract number for Mentor-Protégé Program (MPP). -->
                        <b-col class="py-1" sm="12" md="6">
                          <div class="form-label-group">
                            <label for="contractNumber"
                              >Mentor-Protégé Program (MPP) contract
                              number*</label
                            >
                            <input
                              type="text"
                              id="contractNumber"
                              class="form-control"
                              :class="
                                hasError('contractNumber') ? 'is-invalid' : ''
                              "
                              placeholder="MPP Contract Number"
                              v-model="formData.contractNumber"
                            />
                            <small class="text-muted"
                              >You can get the contract number from your
                              mentor.</small
                            >
                            <div
                              v-if="hasError('contractNumber')"
                              class="invalid-feedback"
                            >
                              <div
                                class="error"
                                v-if="!$v.formData.contractNumber.required"
                              >
                                Please provide your MPP contract number
                              </div>
                            </div>
                          </div>
                        </b-col>
                        <!-- Agreement Start Date -->
                        <b-col class="py-3" sm="12" md="6">
                          <div class="form-label-group">
                            <label for="mppStartDate"
                              >Choose your
                              <b>agreement term start date</b>*</label
                            >
                            <b-calendar
                              class="col-sm-12"
                              id="mppStartDate"
                              v-model="formData.mppStartDate"
                              :class="
                                hasError('mppStartDate') ? 'is-invalid' : ''
                              "
                              :min="minStartDate"
                              :max="maxStartDate"
                              locale="en"
                              block
                            ></b-calendar>

                            <small
                              class="text-muted"
                              v-if="!hasError('mppStartDate')"
                              >Start date must be between
                              {{ minStartDate.toLocaleDateString() }} and today
                              {{ maxStartDate.toLocaleDateString() }}.</small
                            >
                            <div
                              v-if="hasError('mppStartDate')"
                              class="invalid-feedback"
                            >
                              <div
                                class="error"
                                v-if="!$v.formData.mppStartDate.required"
                              >
                                Please provide your agreement start date,
                                between
                                {{ minStartDate.toLocaleDateString() }} and
                                today
                                {{ maxStartDate.toLocaleDateString() }}
                              </div>
                            </div>
                          </div>
                        </b-col>

                        <!-- Agreement End Date -->
                        <b-col class="py-3" sm="12" md="6">
                          <div class="form-label-group">
                            <label for="mppEndDate"
                              >Choose your
                              <b>agreement term end date</b>*</label
                            >
                            <b-calendar
                              class="col-sm-12"
                              id="mppEndDate"
                              v-model="formData.mppEndDate"
                              :class="
                                hasError('mppEndDate') ? 'is-invalid' : ''
                              "
                              :min="
                                formData.mppStartDate
                                  ? formData.mppStartDate
                                  : minEndDate
                              "
                              :max="maxEndDate"
                              locale="en"
                              block
                            ></b-calendar>

                            <small
                              class="text-muted"
                              v-if="!hasError('mppEndDate')"
                              >Your end date should be between
                              {{ minEndDate.toLocaleDateString() }} and
                              {{ maxEndDate.toLocaleDateString() }}</small
                            >
                            <div
                              v-if="hasError('mppEndDate')"
                              class="invalid-feedback"
                            >
                              <div
                                class="error"
                                v-if="!$v.formData.mppEndDate.required"
                              >
                                Please provide your agreement end date, between
                                {{
                                  (formData.mppStartDate
                                    ? new Date(formData.mppStartDate)
                                    : minEndDate
                                  ).toLocaleDateString('en-US', {
                                    timeZone: 'UTC',
                                  })
                                }}
                                and
                                {{ maxEndDate.toLocaleDateString() }}
                              </div>
                              <div
                                class="error"
                                v-if="!$v.formData.mppEndDate.minValue"
                              >
                                End date cannot be before start date. Please
                                provide your agreement end date, between
                                {{
                                  (formData.mppStartDate
                                    ? new Date(formData.mppStartDate)
                                    : minEndDate
                                  ).toLocaleDateString('en-US', {
                                    timeZone: 'UTC',
                                  })
                                }}
                                and
                                {{ maxEndDate.toLocaleDateString() }}
                              </div>
                            </div>
                          </div>
                        </b-col>
                        <!-- Who is your DOD Sponsoring agency?s -->
                        <b-col class="py-1" sm="12" md="6">
                          <div class="form-group">
                            <label for="govtAgency"
                              >DoD Sponsoring agency*</label
                            >
                            <select
                              id="govtAgency"
                              :class="
                                hasError('govtAgency') ? 'is-invalid' : ''
                              "
                              class="form-control"
                              v-model="formData.govtAgency"
                            >
                              <option value="">
                                Select DoD Sponsoring Agency...
                              </option>
                              <option value="Army">Army</option>
                              <option value="Navy">Navy</option>
                              <option value="Air Force">Air Force</option>
                              <option value="NGA">NGA</option>
                              <option value="DIA">DIA</option>
                              <option value="MDA">MDA</option>
                              <option value="DTRA">DTRA</option>
                              <option value="DCMA">DCMA</option>
                              <option value="OSD">OSD</option>
                            </select>
                            <div
                              v-if="hasError('govtAgency')"
                              class="invalid-feedback"
                            >
                              <div
                                class="error"
                                v-if="!$v.formData.govtAgency.required"
                              >
                                Please provide your DoD sponsoring agency
                              </div>
                            </div>
                          </div>
                        </b-col>
                        <!-- How many years has your company been in business? -->
                        <b-col class="py-1" sm="12" md="6">
                          <div class="form-label-group">
                            <label for="yearsInBusiness"
                              >Number of years your company has been in
                              business*</label
                            >
                            <input
                              type="text"
                              id="yearsInBusiness"
                              class="form-control"
                              :class="
                                hasError('yearsInBusiness') ? 'is-invalid' : ''
                              "
                              placeholder="Years In Buissiness"
                              v-model="formData.yearsInBusiness"
                            />
                            <div
                              v-if="hasError('yearsInBusiness')"
                              class="invalid-feedback"
                            >
                              <div
                                class="error"
                                v-if="!$v.formData.yearsInBusiness.required"
                              >
                                Please provide the # of years your company has
                                been in buissiness
                              </div>
                            </div>
                          </div>
                        </b-col>
                        <!--Company Size -->
                        <b-col class="py-1" sm="12" md="6">
                          <div class="form-group">
                            <label for="numberOfEmployees">Company Size</label>
                            <select
                              id="numberOfEmployees"
                              :class="
                                hasError('numberOfEmployees')
                                  ? 'is-invalid'
                                  : ''
                              "
                              class="form-control"
                              v-model="formData.numberOfEmployees"
                            >
                              <option value="">Select Company Size...</option>
                              <option value="1-10">1-10</option>
                              <option value="11-50">11-50</option>
                              <option value="51-500">51-500</option>
                              <option value=" 501-2,000">501-2,000</option>
                              <option value="2,001-10,000">2,001-10,000</option>
                              <option value=">10,001">&gt;10,001</option>
                            </select>
                            <div
                              v-if="hasError('numberOfEmployees')"
                              class="invalid-feedback"
                            >
                              <div
                                class="error"
                                v-if="!$v.formData.numberOfEmployees.required"
                              >
                                Please provide your company size
                              </div>
                            </div>
                          </div>
                        </b-col>

                        <!--Is your company a Manufacturing Extension Partnership (MEP) participant?-->
                        <b-col sm="12">
                          <b-form-group
                            label="Is your company a Manufacturing Extension Partnership (MEP) participant?*"
                          >
                            <b-form-radio-group
                              id="mep"
                              name="mep"
                              v-model="formData.mep"
                              :class="hasError('mep') ? 'is-invalid' : ''"
                            >
                              <b-form-radio v-model="formData.mep" :value="true"
                                >Yes</b-form-radio
                              >
                              <b-form-radio
                                v-model="formData.mep"
                                :value="false"
                                >No</b-form-radio
                              >
                            </b-form-radio-group>

                            <div
                              v-if="hasError('mep')"
                              class="invalid-feedback"
                            >
                              <div
                                class="error"
                                v-if="!$v.formData.mep.required"
                              >
                                Please answer if your company is a Manufacturing
                                Extension Partnership (MEP) participant
                              </div>
                            </div>
                          </b-form-group>
                        </b-col>

                        <!-- Referral Source -->
                        <b-col class="py-1" sm="12">
                          <div class="form-label-group">
                            <label for="referralSource">Referral Source*</label>
                            <b-form-textarea
                              id="referralSource"
                              rows="3"
                              max-rows="8"
                              :class="
                                hasError('referralSource') ? 'is-invalid' : ''
                              "
                              placeholder="Referral source..."
                              v-model="formData.referralSource"
                            />
                            <div
                              v-if="hasError('referralSource')"
                              class="invalid-feedback"
                            >
                              <div
                                class="error"
                                v-if="!$v.formData.referralSource.required"
                              >
                                Please provide your referral source
                              </div>
                            </div>
                          </div>
                        </b-col>

                        <!-- Is your company a women-owned business? -->
                        <b-col sm="12">
                          <b-form-group
                            label="Is your company a women-owned business?*"
                          >
                            <b-form-radio-group
                              id="womenOwned"
                              name="womenOwned"
                              v-model="formData.womenOwned"
                              :class="
                                hasError('womenOwned') ? 'is-invalid' : ''
                              "
                            >
                              <b-form-radio
                                v-model="formData.womenOwned"
                                :value="true"
                                >Yes</b-form-radio
                              >
                              <b-form-radio
                                v-model="formData.womenOwned"
                                :value="false"
                                >No</b-form-radio
                              >
                            </b-form-radio-group>

                            <div
                              v-if="hasError('womenOwned')"
                              class="invalid-feedback"
                            >
                              <div
                                class="error"
                                v-if="!$v.formData.womenOwned.required"
                              >
                                Please answer if your company is a women-owned
                                buissiness
                              </div>
                            </div>
                          </b-form-group>
                        </b-col>

                        <!-- Is your company a veteran-owned business?-->
                        <b-col sm="12">
                          <b-form-group
                            label="Is your company a veteran-owned business?*"
                          >
                            <b-form-radio-group
                              id="veteranOwned"
                              name="veteranOwned"
                              v-model="formData.veteranOwned"
                              :class="
                                hasError('veteranOwned') ? 'is-invalid' : ''
                              "
                            >
                              <b-form-radio
                                v-model="formData.veteranOwned"
                                :value="true"
                                >Yes</b-form-radio
                              >
                              <b-form-radio
                                v-model="formData.veteranOwned"
                                :value="false"
                                >No</b-form-radio
                              >
                            </b-form-radio-group>

                            <div
                              v-if="hasError('veteranOwned')"
                              class="invalid-feedback"
                            >
                              <div
                                class="error"
                                v-if="!$v.formData.veteranOwned.required"
                              >
                                Please answer if your company is a veteran-owned
                                buissiness
                              </div>
                            </div>
                          </b-form-group>
                        </b-col>

                        <!-- Is your company a service disabled veteran-owned business?-->
                        <b-col sm="12">
                          <b-form-group
                            label="Is your company a service disabled veteran-owned business?*"
                          >
                            <b-form-radio-group
                              id="serviceDisabledveteranOwned"
                              name="serviceDisabledveteranOwned"
                              v-model="formData.serviceDisabledveteranOwned"
                              :class="
                                hasError('serviceDisabledveteranOwned')
                                  ? 'is-invalid'
                                  : ''
                              "
                            >
                              <b-form-radio
                                v-model="formData.serviceDisabledveteranOwned"
                                :value="true"
                                >Yes</b-form-radio
                              >
                              <b-form-radio
                                v-model="formData.serviceDisabledveteranOwned"
                                :value="false"
                                >No</b-form-radio
                              >
                            </b-form-radio-group>

                            <div
                              v-if="hasError('serviceDisabledveteranOwned')"
                              class="invalid-feedback"
                            >
                              <div
                                class="error"
                                v-if="
                                  !$v.formData.serviceDisabledveteranOwned
                                    .required
                                "
                              >
                                Please answer if your company is a service
                                disabled veteran-owned business
                              </div>
                            </div>
                          </b-form-group>
                        </b-col>

                        <!-- Is your company a HUBZone business?-->
                        <b-col sm="12">
                          <b-form-group
                            label="Is your company a HUBZone business?*"
                          >
                            <b-form-radio-group
                              id="hubzoneBusiness"
                              name="hubzoneBusiness"
                              v-model="formData.hubzoneBusiness"
                              :class="
                                hasError('hubzoneBusiness') ? 'is-invalid' : ''
                              "
                            >
                              <b-form-radio
                                v-model="formData.hubzoneBusiness"
                                :value="true"
                                >Yes</b-form-radio
                              >
                              <b-form-radio
                                v-model="formData.hubzoneBusiness"
                                :value="false"
                                >No</b-form-radio
                              >
                            </b-form-radio-group>

                            <div
                              v-if="hasError('hubzoneBusiness')"
                              class="invalid-feedback"
                            >
                              <div
                                class="error"
                                v-if="!$v.formData.hubzoneBusiness.required"
                              >
                                Please answer if your company is a HUBZone
                                business
                              </div>
                            </div>
                          </b-form-group>
                        </b-col>

                        <!-- Is your company a small business?-->
                        <b-col sm="12">
                          <b-form-group
                            label="Is your company a small business?*"
                          >
                            <b-form-radio-group
                              id="smallBusiness"
                              name="smallBusiness"
                              v-model="formData.smallBusiness"
                              :class="
                                hasError('smallBusiness') ? 'is-invalid' : ''
                              "
                            >
                              <b-form-radio
                                v-model="formData.smallBusiness"
                                :value="true"
                                >Yes</b-form-radio
                              >
                              <b-form-radio
                                v-model="formData.smallBusiness"
                                :value="false"
                                >No</b-form-radio
                              >
                            </b-form-radio-group>

                            <div
                              v-if="hasError('smallBusiness')"
                              class="invalid-feedback"
                            >
                              <div
                                class="error"
                                v-if="!$v.formData.smallBusiness.required"
                              >
                                Please answer if your company is a small
                                business
                              </div>
                            </div>
                          </b-form-group>
                        </b-col>

                        <!-- Is your company an Indian Economic Enterprise?-->
                        <b-col sm="12">
                          <b-form-group
                            label="Is your company an Indian Economic Enterprise?*"
                          >
                            <b-form-radio-group
                              id="indianEconomicEnterprise"
                              name="indianEconomicEnterprise"
                              v-model="formData.indianEconomicEnterprise"
                              :class="
                                hasError('indianEconomicEnterprise')
                                  ? 'is-invalid'
                                  : ''
                              "
                            >
                              <b-form-radio
                                v-model="formData.indianEconomicEnterprise"
                                :value="true"
                                >Yes</b-form-radio
                              >
                              <b-form-radio
                                v-model="formData.indianEconomicEnterprise"
                                :value="false"
                                >No</b-form-radio
                              >
                            </b-form-radio-group>

                            <div
                              v-if="hasError('indianEconomicEnterprise')"
                              class="invalid-feedback"
                            >
                              <div
                                class="error"
                                v-if="
                                  !$v.formData.indianEconomicEnterprise.required
                                "
                              >
                                Please answer if your company is an Indian
                                Economic Enterprise
                              </div>
                            </div>
                          </b-form-group>
                        </b-col>

                        <!-- Does your company have DoD sub-contract(s)?-->
                        <b-col sm="12">
                          <b-form-group
                            label="Does your company have DoD sub-contract(s)?*"
                          >
                            <b-form-radio-group
                              id="dodSub"
                              name="dodSub"
                              v-model="formData.dodSub"
                              :class="hasError('dodSub') ? 'is-invalid' : ''"
                            >
                              <b-form-radio
                                v-model="formData.dodSub"
                                :value="true"
                                >Yes</b-form-radio
                              >
                              <b-form-radio
                                v-model="formData.dodSub"
                                :value="false"
                                >No</b-form-radio
                              >
                            </b-form-radio-group>

                            <div
                              v-if="hasError('dodSub')"
                              class="invalid-feedback"
                            >
                              <div
                                class="error"
                                v-if="!$v.formData.dodSub.required"
                              >
                                Please answer if your company has DoD
                                sub-contract(s)?
                              </div>
                            </div>
                          </b-form-group>
                        </b-col>

                        <!-- Does your company have DoD prime-contract(s)?-->
                        <b-col sm="12">
                          <b-form-group
                            label="Does your company have DoD prime-contract(s)?*"
                          >
                            <b-form-radio-group
                              id="dodPrime"
                              name="dodPrime"
                              v-model="formData.dodPrime"
                              :class="hasError('dodPrime') ? 'is-invalid' : ''"
                            >
                              <b-form-radio
                                v-model="formData.dodPrime"
                                :value="true"
                                >Yes</b-form-radio
                              >
                              <b-form-radio
                                v-model="formData.dodPrime"
                                :value="false"
                                >No</b-form-radio
                              >
                            </b-form-radio-group>

                            <div
                              v-if="hasError('dodPrime')"
                              class="invalid-feedback"
                            >
                              <div
                                class="error"
                                v-if="!$v.formData.dodPrime.required"
                              >
                                Please answer if your company has DoD
                                prime-contract(s)?
                              </div>
                            </div>
                          </b-form-group>
                        </b-col>

                        <!-- Does your company have civilian sub-contract(s)?-->
                        <b-col sm="12">
                          <b-form-group
                            label="Does your company have civilian sub-contract(s)?*"
                          >
                            <b-form-radio-group
                              id="civilianSub"
                              name="civilianSub"
                              v-model="formData.civilianSub"
                              :class="
                                hasError('civilianSub') ? 'is-invalid' : ''
                              "
                            >
                              <b-form-radio
                                v-model="formData.civilianSub"
                                :value="true"
                                >Yes</b-form-radio
                              >
                              <b-form-radio
                                v-model="formData.civilianSub"
                                :value="false"
                                >No</b-form-radio
                              >
                            </b-form-radio-group>

                            <div
                              v-if="hasError('civilianSub')"
                              class="invalid-feedback"
                            >
                              <div
                                class="error"
                                v-if="!$v.formData.civilianSub.required"
                              >
                                Please answer if your company has civilian
                                sub-contract(s)?
                              </div>
                            </div>
                          </b-form-group>
                        </b-col>

                        <!-- What is your company's service base? -->
                        <b-col sm="12">
                          <b-form-group
                            label="What is your company's service base?*"
                          >
                            <b-form-radio-group
                              id="serviceBase"
                              name="serviceBase"
                              v-model="formData.serviceBase"
                              :class="
                                hasError('serviceBase') ? 'is-invalid' : ''
                              "
                            >
                              <b-form-radio
                                v-model="formData.serviceBase"
                                value="manufacturing"
                                >Manufacturing</b-form-radio
                              >
                              <b-form-radio
                                v-model="formData.serviceBase"
                                value="researchAndDevelopment"
                                >Research and Development</b-form-radio
                              >
                              <b-form-radio
                                v-model="formData.serviceBase"
                                value="knowledgeBased"
                                >Knowledge Based</b-form-radio
                              >
                              <b-form-radio
                                v-model="formData.serviceBase"
                                value="other"
                                >Other</b-form-radio
                              >
                            </b-form-radio-group>

                            <div
                              v-if="hasError('serviceBase')"
                              class="invalid-feedback"
                            >
                              <div
                                class="error"
                                v-if="!$v.formData.serviceBase.required"
                              >
                                Please provide your company's service base
                              </div>
                            </div>
                          </b-form-group>
                        </b-col>

                        <!-- What type and/or level of compliance are you hoping to achieve? -->
                        <b-col sm="12">
                          <b-form-group
                            label="What type and/or level of compliance are you hoping to achieve?*"
                          >
                            <b-form-radio-group
                              id="levelOfCompliancy"
                              name="levelOfCompliancy"
                              v-model="formData.levelOfCompliancy"
                              :class="
                                hasError('levelOfCompliancy')
                                  ? 'is-invalid'
                                  : ''
                              "
                            >
                              <b-form-radio
                                v-model="formData.levelOfCompliancy"
                                value="NIST 800-171"
                                >NIST 800-171</b-form-radio
                              >
                              <b-form-radio
                                v-model="formData.levelOfCompliancy"
                                value="CMMC Level 1"
                                >CMMC Level 1</b-form-radio
                              >
                              <b-form-radio
                                v-model="formData.levelOfCompliancy"
                                value="CMMC Level 2"
                                >CMMC Level 2</b-form-radio
                              ><b-form-radio
                                v-model="formData.levelOfCompliancy"
                                value="CMMC Level 3"
                                >CMMC Level 3</b-form-radio
                              ><b-form-radio
                                v-model="formData.levelOfCompliancy"
                                value="N/A"
                                >N/A</b-form-radio
                              >
                              <b-form-radio
                                v-model="formData.levelOfCompliancy"
                                value="Other"
                                >Other</b-form-radio
                              >
                            </b-form-radio-group>

                            <div
                              v-if="hasError('levelOfCompliancy')"
                              class="invalid-feedback"
                            >
                              <div
                                class="error"
                                v-if="!$v.formData.levelOfCompliancy.required"
                              >
                                Please provide what type and/or level of
                                compliance are you hoping to achieve
                              </div>
                            </div>
                          </b-form-group>
                        </b-col>

                        <!-- Description -->
                        <b-col class="py-1" sm="12">
                          <div class="form-label-group">
                            <label for="levelOfCompliancyDescription"
                              >Description*</label
                            >
                            <b-form-textarea
                              id="levelOfCompliancyDescription"
                              rows="3"
                              max-rows="8"
                              :class="
                                hasError('levelOfCompliancyDescription')
                                  ? 'is-invalid'
                                  : ''
                              "
                              placeholder="Description..."
                              v-model="formData.levelOfCompliancyDescription"
                            />
                            <div
                              v-if="hasError('levelOfCompliancyDescription')"
                              class="invalid-feedback"
                            >
                              <div
                                class="error"
                                v-if="
                                  !$v.formData.levelOfCompliancyDescription
                                    .required
                                "
                              >
                                Please provide your levelOfCompliancyDescription
                              </div>
                            </div>
                          </div>
                        </b-col>

                        <!-- Have you set aside resources to prioritize cyber compliance?-->
                        <b-col sm="12">
                          <b-form-group
                            label="Have you set aside resources to prioritize cyber compliance?*"
                          >
                            <b-form-radio-group
                              id="resourcesForCyber"
                              name="resourcesForCyber"
                              v-model="formData.resourcesForCyber"
                              :class="
                                hasError('resourcesForCyber')
                                  ? 'is-invalid'
                                  : ''
                              "
                            >
                              <b-form-radio
                                v-model="formData.resourcesForCyber"
                                :value="true"
                                >Yes</b-form-radio
                              >
                              <b-form-radio
                                v-model="formData.resourcesForCyber"
                                :value="false"
                                >No</b-form-radio
                              >
                            </b-form-radio-group>

                            <div
                              v-if="hasError('resourcesForCyber')"
                              class="invalid-feedback"
                            >
                              <div
                                class="error"
                                v-if="!$v.formData.resourcesForCyber.required"
                              >
                                Please answer if your company has set aside
                                resources to prioritize cyber compliance
                              </div>
                            </div>
                          </b-form-group>
                        </b-col>

                        <!-- Person responsible for NIST 800-171 compliance in your company -->
                        <b-col class="py-1" sm="12">
                          <div class="form-label-group">
                            <label for="compliancePerson"
                              >Person responsible for NIST 800-171 compliance in
                              your company*</label
                            >
                            <b-form-textarea
                              id="compliancePerson"
                              rows="3"
                              max-rows="8"
                              :class="
                                hasError('compliancePerson') ? 'is-invalid' : ''
                              "
                              placeholder="Person responsible for NIST 800-171 complianc..."
                              v-model="formData.compliancePerson"
                            />
                            <div
                              v-if="hasError('compliancePerson')"
                              class="invalid-feedback"
                            >
                              <div
                                class="error"
                                v-if="!$v.formData.compliancePerson.required"
                              >
                                Please provide the person responsible for NIST
                                800-171 compliance in your company
                              </div>
                            </div>
                          </div>
                        </b-col>

                        <!-- Does your organization have the resources to interface with a coach and work on solutions?-->
                        <b-col sm="12">
                          <b-form-group
                            label="Does your organization have the resources to interface with a coach and work on solutions?*"
                          >
                            <b-form-radio-group
                              id="resourcesForCoach"
                              name="resourcesForCoach"
                              v-model="formData.resourcesForCoach"
                              :class="
                                hasError('resourcesForCoach')
                                  ? 'is-invalid'
                                  : ''
                              "
                            >
                              <b-form-radio
                                v-model="formData.resourcesForCoach"
                                :value="true"
                                >Yes</b-form-radio
                              >
                              <b-form-radio
                                v-model="formData.resourcesForCoach"
                                :value="false"
                                >No</b-form-radio
                              >
                            </b-form-radio-group>

                            <div
                              v-if="hasError('resourcesForCoach')"
                              class="invalid-feedback"
                            >
                              <div
                                class="error"
                                v-if="!$v.formData.resourcesForCoach.required"
                              >
                                Please answer if your organization has the
                                resources to interface with a coach and work on
                                solutions
                              </div>
                            </div>
                          </b-form-group>
                        </b-col>

                        <!-- Current software operating systems and major version numbers: (List all) -->
                        <b-col class="py-1" sm="12">
                          <div class="form-label-group">
                            <label for="operatingSystems"
                              >Current software operating systems and major
                              version numbers (List all)*</label
                            >
                            <b-form-textarea
                              id="operatingSystems"
                              rows="3"
                              max-rows="8"
                              :class="
                                hasError('operatingSystems') ? 'is-invalid' : ''
                              "
                              placeholder="Current operating systems and major version numbers..."
                              v-model="formData.operatingSystems"
                            />
                            <div
                              v-if="hasError('operatingSystems')"
                              class="invalid-feedback"
                            >
                              <div
                                class="error"
                                v-if="!$v.formData.operatingSystems.required"
                              >
                                Please list all current software operating
                                systems and major version numbers
                              </div>
                            </div>
                          </div>
                        </b-col>

                        <!-- What are the number of physical locations where you have information systems present? -->
                        <b-col class="py-3" sm="12">
                          <div class="form-label-group">
                            <label for="locationsAndNetwork"
                              >What are the number of physical locations where
                              you have information systems present?*</label
                            >
                            <b-form-textarea
                              id="locationsAndNetwork"
                              rows="3"
                              max-rows="8"
                              :class="
                                hasError('locationsAndNetwork')
                                  ? 'is-invalid'
                                  : ''
                              "
                              placeholder="Number of physical locations where you have information systems present?..."
                              v-model="formData.locationsAndNetwork"
                            />
                            <div
                              v-if="hasError('locationsAndNetwork')"
                              class="invalid-feedback"
                            >
                              <div
                                class="error"
                                v-if="!$v.formData.locationsAndNetwork.required"
                              >
                                Please give the number of physical locations
                                where you have information systems present?
                              </div>
                            </div>
                          </div>
                        </b-col>
                        <!-- End -->
                      </b-row>
                    </b-row>
                  </div>
                </tab-content>
              </form-wizard>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Register Area End-->
  </div>
</template>
<script>
import ValidationHelper from '../vue-step-wizard/ValidationHelper.vue';
import TabContent from '../vue-step-wizard/TabContent.vue';
import FormWizard from '../vue-step-wizard/FormWizard.vue';
import {
  handleApiError,
  isPhoneNumberVerified,
  getUserEmail,
  storeAuthTokens,
  isLoggedIn,
  isEnabledMFA,
} from '../../utils/authentication';
import axios from 'axios';

import {
  required,
  requiredIf,
  email,
  numeric,
  minLength,
  sameAs,
} from 'vuelidate/lib/validators';

//component code
export default {
  name: 'my-account',
  components: {
    FormWizard,
    TabContent,
  },
  mixins: [ValidationHelper],
  mounted() {
    this.init();
  },
  data() {
    return {
      minStartDate: new Date(2010, 0, 1),
      maxStartDate: new Date(Date.now()),
      minEndDate: new Date(2019, 9, 1),
      maxEndDate: new Date(2030, 11, 31),
      errorMessage: '',
      successMessage: '',
      submissionSuccessful: false,
      customButtonLabel: 'Submit Application',
      companyLookupSuccessMessage: '',
      companyLookupErrorMessage: '',
      formData: {
        // Initial Input
        mpp: null,
        useCompanyCodeLookup: null,
        companyCodeLookup: '',
        // Submission Details
        firstName: '',
        lastName: '',
        // Company Information
        CAGECode: '',
        NAICSCodes: '',
        DUNSNumber: '',
        streetaddress1: '',
        streetaddress2: '',
        city: '',
        state: '',
        zipCode: '',
        country: '',
        // Company Details
        mentorCompany: '',
        contractNumber: '',
        mppStartDate: '',
        mppEndDate: '',
        govtAgency: '',
        yearsInBusiness: '',
        numberOfEmployees: '',
        mep: '',
        referralSource: '',
        womenOwned: '',
        veteranOwned: '',
        serviceDisabledveteranOwned: '',
        hubzoneBusiness: '',
        smallBusiness: '',
        indianEconomicEnterprise: '',
        dodSub: '',
        dodPrime: '',
        civilianSub: '',
        serviceBase: '',
        levelOfCompliancy: '',
        levelOfCompliancyDescription: '',
        resourcesForCyber: '',
        compliancePerson: '',
        resourcesForCoach: '',
        operatingSystems: '',
        locationsAndNetwork: '',
      },
      validationRules: [
        {
          // Initial Input
          mpp: { required },
          useCompanyCodeLookup: { required },
          companyCodeLookup: {},
          // Submission Details
          firstName: { required },
          lastName: { required },
          // Company Information
          CAGECode: { required },
          NAICSCodes: { required },
          DUNSNumber: { required },
          streetaddress1: { required },
          streetaddress2: {},
          city: { required },
          state: { required },
          zipCode: { required },
          country: {},
          // Company Details
          mentorCompany: { required },
          contractNumber: { required },
          mppStartDate: { required },
          mppEndDate: {
            required,
            minValue: (value) => {
              if (!mppStartDate || !mppEndDate) {
                return true;
              }
              return (
                new Date(value).valueOf() >=
                new Date(this.formData.mppStartDate).valueOf()
              );
            },
          },
          govtAgency: { required },
          yearsInBusiness: { required },
          numberOfEmployees: { required },
          mep: { required },
          referralSource: { required },
          womenOwned: { required },
          veteranOwned: { required },
          serviceDisabledveteranOwned: { required },
          hubzoneBusiness: { required },
          smallBusiness: { required },
          indianEconomicEnterprise: { required },
          dodSub: { required },
          dodPrime: { required },
          civilianSub: { required },
          serviceBase: { required },
          levelOfCompliancy: { required },
          levelOfCompliancyDescription: { required },
          resourcesForCyber: { required },
          compliancePerson: { required },
          resourcesForCoach: { required },
          operatingSystems: { required },
          locationsAndNetwork: { required },
        },
      ],
    };
  },
  beforeMount() {
    // this.getAccountData();
  },
  computed: {
    shouldShowCompanyLookup() {
      return this.formData.useCompanyCodeLookup;
    },
    isLoggedIn() {
      return isLoggedIn();
    },
    isEnabledMFA() {
      return isEnabledMFA();
    },
  },
  methods: {
    init() {
      /* The footer height no longer needs to be set */
      // this.initFooterHeight();
    },
    initFooterHeight() {
      var footerHeight = $('footer').outerHeight();
      $('.main-content').css('margin-bottom', footerHeight);
      // $('.main-content').css('min-height', '100vh');
    },
    onCannotCompleteFromErrors() {
      this.errorMessage = 'Please correct all form errors before submitting';
      this.successMessage = '';
    },
    onLookupCageCodeOrDuns() {
      this.companyLookupSuccessMessage = '';
      this.companyLookupErrorMessage = '';
      /*console.log(
        'Company Lookup Code',
        this.formData.companyCodeLookup.trim()
      );*/
      if (this.formData.companyCodeLookup.trim().length == 5) {
        /** CAGE Code */
        axios
          .get(`/api/sam/cageCode/${this.formData.companyCodeLookup.trim()}`)
          .then((response) => {
            //console.log('Company Lookup', response);
            if (response.data) {
              this.companyLookupSuccessMessage =
                'Company Information populated';
            } else {
              this.companyLookupErrorMessage =
                'Could not find a company for this code';
            }
            /** Map response json to the form data */
            this.formData.streetaddress1 =
              response.data.electronicBusinessAddress.address1;
            this.formData.streetaddress2 =
              response.data.electronicBusinessAddress.address2;
            this.formData.city = response.data.electronicBusinessAddress.city;
            this.formData.state = response.data.electronicBusinessAddress.state;
            this.formData.zipCode = response.data.electronicBusinessAddress.zip;
            this.formData.country =
              response.data.electronicBusinessAddress.countryCode;
            this.formData.CAGECode = response.data.cageCode;
            this.formData.DUNSNumber = response.data.duns;
            this.formData.NAICSCodes = response.data.primaryNaics;
          })
          .catch((error) => {
            console.error(error);
          });
      } else if (this.formData.companyCodeLookup.trim().length == 9) {
        /** DUNS Number */
        axios
          .get(`/api/sam/duns/${this.formData.companyCodeLookup.trim()}`)
          .then((response) => {
            //console.log('Company Lookup', response);
            if (response.data) {
              this.companyLookupSuccessMessage =
                'Company Information populated';
            } else {
              this.companyLookupErrorMessage =
                'Could not find a company for this code';
            }
            /** Map response json to the form data */
            this.formData.streetaddress1 =
              response.data.electronicBusinessAddress.address1;
            this.formData.streetaddress2 =
              response.data.electronicBusinessAddress.address2;
            this.formData.city = response.data.electronicBusinessAddress.city;
            this.formData.state = response.data.electronicBusinessAddress.state;
            this.formData.zipCode = response.data.electronicBusinessAddress.zip;
            this.formData.country =
              response.data.electronicBusinessAddress.countryCode;
            this.formData.CAGECode = response.data.cageCode;
            this.formData.DUNSNumber = response.data.duns;
            this.formData.NAICSCodes = response.data.primaryNaics;
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        console.warn(
          'CAGE or DUNS of bad length passed',
          this.formData.companyCode
        );
        this.companyLookupErrorMessage =
          'Must be 5 (CAGE) or 9 (DUNS) character code';
      }
    },
    onComplete() {
      //console.log('OnComplete');
      this.errorMessage = '';
      this.successMessage = '';
      const body = this.formData;
      body.name = `${this.formData.firstName} ${this.formData.lastName}`;

      //console.log('Submit', body);

      axios
        .post('/api/pilot/', body)
        .then((response) => {
          //console.log('User Data Updated', response);
          /** Account updated seccessfully */
          this.errorMessage = '';
          this.successMessage = 'Submission Successful';
          this.submissionSuccessful = true;
        })
        .catch((error) => {
          this.successMessage = '';
          /** Error creating account */
          this.errorMessage = error.response.data.message;
          console.error(error.response.data.message);
        });
    },
  },
};
</script>
<style scoped>
.form-control {
  height: 45px;
}

hr {
  margin-top: 2rem;
  padding: 0px;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.b-calendar {
  height: auto !important;
  font-size: inherit !important;
}
</style>
