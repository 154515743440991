var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "main-content"
  }, [_vm._m(0), _c('section', {
    staticClass: "section"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('p', [_vm._v(" Project Spectrum provides short, digestible, in-house produced videos that give you a quick primer on important cybersecurity issues. ")]), _c('div', {
    staticClass: "pb-4"
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Search Training Videos..."
    },
    model: {
      value: _vm.searchQuery,
      callback: function ($$v) {
        _vm.searchQuery = $$v;
      },
      expression: "searchQuery"
    }
  })], 1), _vm.displayedItems.length === 0 && _vm.searchQuery ? _c('p', {
    staticClass: "pb-2"
  }, [_vm._v(" No items match the search criteria... ")]) : _vm._e(), _vm.displayedItems.length === 0 && !_vm.searchQuery ? _c('p', {
    staticClass: "pb-2"
  }, [_vm._v(" Check back soon for more courses and training videos... ")]) : _vm._e(), _c('div', {
    staticClass: "blog-post-grid"
  }, [_c('div', {
    staticClass: "row"
  }, _vm._l(_vm.displayedItems, function (item) {
    return _c('div', {
      key: item.id,
      staticClass: "col-md-6 col-lg-4 mb-5"
    }, [_c('div', {
      staticClass: "blog-item border h-100"
    }, [_c('a', {
      staticClass: "blog-post-image",
      attrs: {
        "href": _vm.itemHref(item),
        "target": _vm.itemTarget(item)
      }
    }, [_c('img', {
      staticClass: "cover",
      attrs: {
        "src": item.trainingVideoThumbnailLink,
        "alt": item.photoDescription
      }
    })]), _c('div', {
      staticClass: "blog-post-info d-flex align-items-start flex-column"
    }, [_c('h4', {
      staticClass: "blog-post-title"
    }, [_vm._v(" " + _vm._s(item.title) + " ")]), _c('div', {
      staticClass: "w-100"
    }, [_c('p', {
      staticClass: "truncate"
    }, [_vm._v(_vm._s(item.source))])]), _c('div', {
      staticClass: "post-event-bottom d-flex align-items-center mt-auto"
    }, [_c('a', {
      staticClass: "blog-info-meta text-dark"
    }, [_c('i', {
      staticClass: "mdi mdi-calendar-outline pr-1"
    }), _vm._v(_vm._s(_vm.getFormattedDate(item.date)))]), _c('a', {
      staticClass: "blog-category text-dark"
    }, [_c('i', {
      staticClass: "mdi mdi-clock-outline pr-1"
    }), _vm._v(_vm._s(item.duration))])]), !item.isPageBookmarked ? _c('div', {
      staticClass: "float-right-bottom"
    }, [_c('font-awesome-icon', {
      attrs: {
        "id": "tooltip-bookmark",
        "icon": ['far', 'bookmark']
      },
      on: {
        "click": function ($event) {
          return _vm.onBookmarkPage(item);
        }
      }
    }), _c('b-tooltip', {
      attrs: {
        "target": "tooltip-bookmark",
        "variant": "primary"
      }
    }, [_vm._v("Click to bookmark video")])], 1) : _c('div', {
      staticClass: "float-right-bottom"
    }, [_c('font-awesome-icon', {
      attrs: {
        "id": "tooltip-bookmark",
        "icon": ['fas', 'bookmark']
      }
    }), _c('b-tooltip', {
      attrs: {
        "target": "tooltip-bookmark",
        "variant": "success"
      }
    }, [_vm._v("Bookmarked!")])], 1), _c('a', {
      staticClass: "blog-read-more",
      attrs: {
        "href": _vm.itemHref(item),
        "target": _vm.itemTarget(item)
      }
    }, [_c('i', {
      staticClass: "mdi",
      class: _vm.isLocked(item) ? 'mdi-lock' : 'mdi-play'
    })])])])]);
  }), 0)]), _c('div', [_c('b-pagination', {
    attrs: {
      "id": "blog-pagination",
      "total-rows": _vm.totalRows,
      "per-page": _vm.perPage,
      "align": "center"
    },
    on: {
      "page-click": _vm.pageClicked
    },
    model: {
      value: _vm.currentPage,
      callback: function ($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)])]), _c('b-modal', {
    ref: "my-modal",
    attrs: {
      "size": "lg",
      "hide-footer": "",
      "title": _vm.modalMessage
    }
  }, [_c('p', {
    staticClass: "my-4"
  }, [_vm._v(" Please "), _c('router-link', {
    attrs: {
      "to": {
        name: 'login',
        query: {
          redirect: _vm.getRedirectRoute
        }
      }
    }
  }, [_c('a', [_vm._v("LOGIN")])]), _vm._v(" or "), _c('router-link', {
    attrs: {
      "to": {
        name: 'signup'
      }
    }
  }, [_c('a', [_vm._v("SIGN UP")])]), _vm._v(" for project spectrum. ")], 1)])], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "inner-header-bg"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "section-breadcrumb"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12 justify-content-center align-self-center pb-2"
  }, [_c('div', {
    staticClass: "breadcrumb-content"
  }, [_c('div', {
    staticClass: "title-section"
  }, [_c('h1', {
    staticClass: "title text-center text-white"
  }, [_vm._v(" Training Videos ")])]), _c('div', {
    staticClass: "breadcrumb-nav"
  }, [_c('ul', {
    staticClass: "breadcrumb-link text-center list-unstyled mb-0"
  }, [_c('li', {
    staticClass: "text-white"
  }, [_c('i', {
    staticClass: "mdi mdi-home theme-light-color pr-2"
  }), _c('a', {
    attrs: {
      "href": "/#/"
    }
  }, [_vm._v("Home")])]), _c('li', {
    staticClass: "theme-light-color active"
  }, [_vm._v("Training Videos")])])])])])])])])])]);

}]

export { render, staticRenderFns }