var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "main-content"
  }, [_vm._m(0), _c('section', {
    staticClass: "section"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('p', [_vm._v(" Policies serve as the framework governing interactions across government and between the public and private sector. Standardizing the methodology for specific interactions and transactions between government and industry provides a universal framework by which all organizations doing business with the government must operate. Effective policies inform decision-makers on critical items such as resource allocation, staffing, and business development. ")]), _c('p', [_vm._v(" The Project Spectrum team works diligently to stay in lockstep with the government as it develops policies – particularly those that effect our primary customer set – small- and medium-sized businesses in the DIB. Project Spectrum is a tremendous resource when it comes to learning the latest about emerging policies. Below you can find a listing of relevant policy discussions and updates. ")]), _c('h3', {
    staticClass: "pb-2"
  }, [_vm._v("Policies")]), _c('b-table', {
    staticClass: "mt-3",
    attrs: {
      "id": "table-cyber-circuits",
      "fields": _vm.policies.fields,
      "items": _vm.policyData,
      "sortable": "true",
      "responsive": "",
      "small": "",
      "borderless": "",
      "no-border-collapse": "",
      "hover": ""
    },
    scopedSlots: _vm._u([{
      key: "table-busy",
      fn: function () {
        return [_c('div', {
          staticClass: "text-center text-secondary my-2"
        }, [_c('b-spinner', {
          staticClass: "align-middle"
        }), _c('strong', {
          staticClass: "pl-2"
        }, [_vm._v("Loading...")])], 1)];
      },
      proxy: true
    }, {
      key: "cell(title)",
      fn: function (data) {
        return [_c('div', {
          staticClass: "image-and-tool d-flex"
        }, [_c('p', {
          staticClass: "clipped-text m-0 font-weight-bold"
        }, [_vm._v(" " + _vm._s(data.item.title) + " ")])])];
      }
    }, {
      key: "cell(links)",
      fn: function (data) {
        return [_c('div', {
          staticClass: "image-and-tool d-flex"
        }, [_c('a', {
          attrs: {
            "href": data.item.link,
            "target": "_blank"
          }
        }, [_c('b-button', {
          staticClass: "btn-more theme-btn-secondary",
          attrs: {
            "variant": "primary",
            "size": "sm"
          }
        }, [_vm._v("View Policy")])], 1)])];
      }
    }])
  })], 1)])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "inner-header-bg-policy"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "section-breadcrumb"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12 justify-content-center align-self-center pb-2"
  }, [_c('div', {
    staticClass: "breadcrumb-content"
  }, [_c('div', {
    staticClass: "title-section"
  }, [_c('h1', {
    staticClass: "title text-center text-white"
  }, [_vm._v("Policy Corner")])]), _c('div', {
    staticClass: "breadcrumb-nav"
  }, [_c('ul', {
    staticClass: "breadcrumb-link text-center list-unstyled mb-0"
  }, [_c('li', {
    staticClass: "text-white"
  }, [_c('i', {
    staticClass: "mdi mdi-home theme-light-color pr-2"
  }), _c('a', {
    attrs: {
      "href": "/#/"
    }
  }, [_vm._v("Home")])]), _c('li', {
    staticClass: "theme-light-color active"
  }, [_vm._v("Policy Corner")])])])])])])])])])]);

}]

export { render, staticRenderFns }