var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm._m(0);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "main-content"
  }, [_c('section', {
    staticClass: "inner-header-bg"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "section-breadcrumb"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12 justify-content-center align-self-center pb-2"
  }, [_c('div', {
    staticClass: "breadcrumb-content"
  }, [_c('div', {
    staticClass: "title-section"
  }, [_c('h1', {
    staticClass: "title text-center text-white"
  }, [_vm._v("Dashboard")])]), _c('div', {
    staticClass: "breadcrumb-nav"
  }, [_c('ul', {
    staticClass: "breadcrumb-link text-center list-unstyled mb-0"
  }, [_c('li', {
    staticClass: "text-white"
  }, [_c('i', {
    staticClass: "mdi mdi-home theme-light-color pr-2"
  }), _c('a', {
    attrs: {
      "href": "/#/"
    }
  }, [_vm._v("Home")])]), _c('li', {
    staticClass: "theme-light-color active"
  }, [_vm._v("Services")])])])])])])])])])]), _c('section', {
    staticClass: "section services-inner"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-7"
  }, [_c('div', {
    staticClass: "section-title"
  }, [_c('span', [_vm._v("40 + YEAR OF EXPERIENCE")]), _c('h2', [_vm._v("Our Services & Skills")])]), _c('div', {
    staticClass: "progress-bar-block",
    attrs: {
      "id": "progress-bar"
    }
  }, [_c('div', {
    staticClass: "bar-main-container azure",
    attrs: {
      "id": "bar-1"
    }
  }, [_c('div', {
    staticClass: "wrap"
  }, [_c('div', {
    staticClass: "bar-container"
  }, [_c('div', {
    staticClass: "bar"
  })]), _c('label', {
    staticClass: "bar-label mb-0"
  }, [_vm._v("Development")]), _c('div', {
    staticClass: "bar-percentage",
    attrs: {
      "data-percentage": "46"
    }
  })])]), _c('div', {
    staticClass: "bar-main-container emerald",
    attrs: {
      "id": "bar-2"
    }
  }, [_c('div', {
    staticClass: "wrap"
  }, [_c('div', {
    staticClass: "bar-container"
  }, [_c('div', {
    staticClass: "bar"
  })]), _c('label', {
    staticClass: "bar-label mb-0"
  }, [_vm._v("Customer")]), _c('div', {
    staticClass: "bar-percentage",
    attrs: {
      "data-percentage": "94"
    }
  })])]), _c('div', {
    staticClass: "bar-main-container violet",
    attrs: {
      "id": "bar-3"
    }
  }, [_c('div', {
    staticClass: "wrap"
  }, [_c('div', {
    staticClass: "bar-container"
  }, [_c('div', {
    staticClass: "bar"
  })]), _c('label', {
    staticClass: "bar-label mb-0"
  }, [_vm._v("Projects")]), _c('div', {
    staticClass: "bar-percentage",
    attrs: {
      "data-percentage": "47"
    }
  })])]), _c('div', {
    staticClass: "bar-main-container yellow",
    attrs: {
      "id": "bar-4"
    }
  }, [_c('div', {
    staticClass: "wrap"
  }, [_c('div', {
    staticClass: "bar-container"
  }, [_c('div', {
    staticClass: "bar"
  })]), _c('label', {
    staticClass: "bar-label mb-0"
  }, [_vm._v("Users Worldwide")]), _c('div', {
    staticClass: "bar-percentage",
    attrs: {
      "data-percentage": "85"
    }
  })])])])]), _c('div', {
    staticClass: "col-lg-5"
  }, [_c('div', {
    staticClass: "mt-lg-0 mt-5"
  }, [_c('img', {
    staticClass: "img-fluid w-100",
    attrs: {
      "src": "assets/images/services/services-02.jpg",
      "alt": ""
    }
  })])])])])]), _c('section', {
    staticClass: "section parallax-bg",
    attrs: {
      "id": "services"
    }
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "section-title text-center z-index9"
  }, [_c('span', [_vm._v("OUR SERVICES")]), _c('h2', {
    staticClass: "text-white"
  }, [_vm._v("Our Best Service")])]), _c('div', {
    staticClass: "service-boxes-area"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-4 col-md-6 col-sm-6"
  }, [_c('div', {
    staticClass: "single-service-box mt-30 p-4 bg-white wow fadeIn",
    attrs: {
      "data-wow-delay": "0.2s",
      "data-wow-duration": "1s"
    }
  }, [_c('div', {
    staticClass: "icon"
  }, [_c('i', {
    staticClass: "mdi mdi-desktop-mac-dashboard"
  })]), _c('h3', [_vm._v("Clean Design")]), _c('p', [_vm._v(" Lorem ipsum dolor sit amet, adipiscing consectetur gravida elit ")])])]), _c('div', {
    staticClass: "col-lg-4 col-md-6 col-sm-6"
  }, [_c('div', {
    staticClass: "single-service-box mt-30 p-4 bg-white wow fadeIn",
    attrs: {
      "data-wow-delay": "0.4s",
      "data-wow-duration": "1s"
    }
  }, [_c('div', {
    staticClass: "icon"
  }, [_c('i', {
    staticClass: "mdi mdi-responsive"
  })]), _c('h3', [_vm._v("Responsive Design")]), _c('p', [_vm._v(" Lorem ipsum dolor sit amet, adipiscing consectetur gravida elit ")])])]), _c('div', {
    staticClass: "col-lg-4 col-md-6 col-sm-6"
  }, [_c('div', {
    staticClass: "single-service-box mt-30 p-4 bg-white wow fadeIn",
    attrs: {
      "data-wow-delay": "0.6s",
      "data-wow-duration": "1s"
    }
  }, [_c('div', {
    staticClass: "icon"
  }, [_c('i', {
    staticClass: "mdi mdi-rocket"
  })]), _c('h3', [_vm._v("Fast Boot")]), _c('p', [_vm._v(" Lorem ipsum dolor sit amet, adipiscing consectetur gravida elit ")])])]), _c('div', {
    staticClass: "col-lg-4 col-md-6 col-sm-6"
  }, [_c('div', {
    staticClass: "single-service-box mt-30 p-4 bg-white wow fadeIn",
    attrs: {
      "data-wow-delay": "0.8s",
      "data-wow-duration": "1s"
    }
  }, [_c('div', {
    staticClass: "icon"
  }, [_c('i', {
    staticClass: "mdi mdi-chat-processing-outline"
  })]), _c('h3', [_vm._v("Chatting Service")]), _c('p', [_vm._v(" Lorem ipsum dolor sit amet, adipiscing consectetur gravida elit ")])])]), _c('div', {
    staticClass: "col-lg-4 col-md-6 col-sm-6"
  }, [_c('div', {
    staticClass: "single-service-box mt-30 p-4 bg-white wow fadeIn",
    attrs: {
      "data-wow-delay": "1s",
      "data-wow-duration": "1s"
    }
  }, [_c('div', {
    staticClass: "icon"
  }, [_c('i', {
    staticClass: "mdi mdi-chart-bar"
  })]), _c('h3', [_vm._v("Analysis")]), _c('p', [_vm._v(" Lorem ipsum dolor sit amet, adipiscing consectetur gravida elit ")])])]), _c('div', {
    staticClass: "col-lg-4 col-md-6 col-sm-6"
  }, [_c('div', {
    staticClass: "single-service-box mt-30 p-4 bg-white wow fadeIn",
    attrs: {
      "data-wow-delay": "1.2s",
      "data-wow-duration": "1s"
    }
  }, [_c('div', {
    staticClass: "icon"
  }, [_c('i', {
    staticClass: "mdi mdi-headphones-settings"
  })]), _c('h3', [_vm._v("Quick Support")]), _c('p', [_vm._v(" Lorem ipsum dolor sit amet, adipiscing consectetur gravida elit ")])])])])])])]), _c('section', {
    staticClass: "section"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-8"
  }, [_c('div', [_c('img', {
    staticClass: "img-fluid w-100",
    attrs: {
      "src": "assets/images/services/services-03.jpg",
      "alt": ""
    }
  })])]), _c('div', {
    staticClass: "col-lg-4"
  }, [_c('div', {
    staticClass: "services-info-box"
  }, [_c('div', {
    staticClass: "section-title"
  }, [_c('h2', {
    staticClass: "title position-relative pb-2 mb-3"
  }, [_vm._v(" Other Services ")])]), _c('ul', {
    staticClass: "list-unstyled mb-0"
  }, [_c('li', [_c('a', {
    staticClass: "text-dark",
    attrs: {
      "href": "#"
    }
  }, [_vm._v(" App Design ")])]), _c('li', [_c('a', {
    staticClass: "text-dark",
    attrs: {
      "href": "#"
    }
  }, [_vm._v(" UI / UX Design ")])]), _c('li', [_c('a', {
    staticClass: "text-dark",
    attrs: {
      "href": "#"
    }
  }, [_vm._v(" Development ")])]), _c('li', [_c('a', {
    staticClass: "text-dark",
    attrs: {
      "href": "#"
    }
  }, [_vm._v(" Web Design ")])]), _c('li', [_c('a', {
    staticClass: "text-dark",
    attrs: {
      "href": "#"
    }
  }, [_vm._v(" Analysis ")])])])])]), _c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "mt-4 text-lg-left text-center"
  }, [_c('h2', [_vm._v("Creative Business Solutions")]), _c('p', [_vm._v(" Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. simplify sustainable applications whereas adaptive schemas. Competently brand performance based content and. ")])])])])])])]);

}]

export { render, staticRenderFns }