<template>
  <div class="main-content">
    <!-- BradCrumb header Start-->
    <section class="inner-header-bg-policy">
      <div class="container">
        <!-- Breadcrumb Start-->
        <div class="section-breadcrumb">
          <div class="container">
            <div class="row">
              <div
                class="col-md-12 justify-content-center align-self-center pb-2"
              >
                <div class="breadcrumb-content">
                  <div class="title-section">
                    <h1 class="title text-center text-white">Policy Corner</h1>
                  </div>
                  <div class="breadcrumb-nav">
                    <ul class="breadcrumb-link text-center list-unstyled mb-0">
                      <li class="text-white">
                        <i class="mdi mdi-home theme-light-color pr-2"></i>
                        <a href="/#/">Home</a>
                      </li>
                      <li class="theme-light-color active">Policy Corner</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Breadcrumb End -->
      </div>
    </section>
    <!-- BradCrumb header End -->
    <!-- Cyber Circuits Grid Section Start -->
    <section class="section">
      <div class="container">
	   <p>
       Policies serve as the framework governing interactions across government and between the public and private sector. Standardizing the methodology for specific interactions and transactions between government and industry provides a universal framework by which all organizations doing business with the government must operate. Effective policies inform decision-makers on critical items such as resource allocation, staffing, and business development. 
	   </p>
	   <p>
	    The Project Spectrum team works diligently to stay in lockstep with the government as it develops policies – particularly those that effect our primary customer set – small- and medium-sized businesses in the DIB. Project Spectrum is a tremendous resource when it comes to learning the latest about emerging policies. Below you can find a listing of relevant policy discussions and updates.
        </p>
        <!-- Policies -->
        <h3 class="pb-2">Policies</h3>
        
        <b-table
          id="table-cyber-circuits"
          :fields="policies.fields"
          :items="policyData"
          sortable="true"
          class="mt-3"
          responsive
          small
          borderless
          no-border-collapse
          hover
        >
          <!-- Busy Spinner -->
          <template #table-busy>
            <div class="text-center text-secondary my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong class="pl-2">Loading...</strong>
            </div>
          </template>
          <!-- Main Column with Image -->
          <template #cell(title)="data">
            <div class="image-and-tool d-flex">
              <p class="clipped-text m-0 font-weight-bold">
                {{ data.item.title }}
              </p>
            </div>
          </template>
          <!-- See More Button -->
          <template #cell(links)="data">
            <div class="image-and-tool d-flex">
              <a :href= "data.item.link" target="_blank" >
                <b-button
                  class="btn-more theme-btn-secondary"
                  variant="primary"
                  size="sm"
                  >View Policy</b-button
                >
              </a>
            </div>
          </template>
        </b-table>
        <!-- End Past Cyber Circuits -->
      </div>
    </section>
    <!-- Cyber Circuits Grid Section End -->
  </div>
</template>
<script>
import axios from 'axios';
import { BPagination, BFormInput } from 'bootstrap-vue';
export default {
  name: 'policies',
  components: {
    BPagination,
    BFormInput,
  },
  props: {
    query: String,
  },
  data() {
    return {
      policies: {
        fields: [
          {
            key: 'title',
            label: 'Category',
            tdClass: 'align-middle',
            sortable: true,
          },
          {
            key: 'policyDate',
            label: 'Date',
            tdClass: 'align-middle',
            sortable: true,
          },
          {
            key: 'description',
            label: 'Policy Highlight',
            tdClass: 'align-middle',
            sortable: true,
          },
           {
            key: 'links',
            label: 'Links',
            tdClass: 'align-middle',
          },
        ]
      },
      policyData:[]
    };
  },
    beforeMount(){
      this.getPolicyData()
    },
    methods:{
         getFormattedDate(dateString){
            const options = { year: 'numeric', month: 'short', day: 'numeric', timeZone: 'UTC' };
            return new Date(dateString).toLocaleDateString(undefined, options)
        },
        getPolicyData(){
           axios
            .get('/api/resources/policies')
            .then((response) => {
              this.policyData = response.data;
            })
            .catch((error) => {
              console.error(error);
            });
        }
    }
}
</script>
<style scoped>
.inner-header-bg {
  background-image: url('~@/assets/images/headers/events.png');
  background-position: center center;
}
#blog-pagination li {
  margin: 0px;
}

.cover {
  object-fit: cover;
  height: 225px;
  width: 100%;
}

.tool-icon {
  object-fit: cover;
  height: 50px;
  width: 80px;
  margin-right: 10px;
}

.image-and-tool {
  max-width: 325px;
  align-items: center;
}

.btn-more {
  width: 100px;
  height: auto;
  padding: 0.25rem 0.5rem;
}
</style>
