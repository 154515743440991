<template>
  <div class="main-content">
    <!-- BradCrumb header Start-->
    <section class="inner-header-bg-partner">
      <div class="container">
        <!-- Breadcrumb Start-->
        <div class="section-breadcrumb">
          <div class="container">
            <div class="row">
              <div
                class="col-md-12 justify-content-center align-self-center pb-2"
              >
                <div class="breadcrumb-content">
                  <div class="title-section">
                    <h1 class="title text-center text-white">
                      Small Business Administration
                    </h1>
                  </div>
                  <div class="breadcrumb-nav">
                    <ul class="breadcrumb-link text-center list-unstyled mb-0">
                      <li class="text-white">
                        <i class="mdi mdi-home theme-light-color pr-2"></i>
                        <a href="/#/">Home</a>
                      </li>
                      <li class="theme-light-color active">
                        Small Business Administration
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Breadcrumb End -->
      </div>
    </section>
    <!-- BradCrumb header End -->
    <!-- Body Area Start-->
    <section class="section">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 py-4">
            <div class="section-title">
              <span>IMPORTANCE</span>
              <h2>The Importance of Cybersecurity for Small Businesses</h2>
            </div>
            <div class="mt-4 text-lg-left">
              <blockquote class="blockquote my-4">
                <span
                  >If you want to do business with the Federal Government, you
                  need to prove you can protect your systems and data</span
                >
              </blockquote>
              <p>
                Cyber criminals consistently target businesses with the intent
                of weakening our nation’s supply chain, threatening our national
                security, and endangering the American way of life. Cyberattacks
                can be very costly for a business when you factor in ransom
                costs, business downtime, system restoration, and reputation
                damage.
                <br /><br />
                If you want to do business with any Federal Government agency,
                you need to know that your company’s systems and data are
                secure. Specifically, you need to demonstrate the ability to
                safeguard Federal Contract Information (FCI) and Controlled
                Unclassified Information (CUI) in order to secure a government
                contract.
              </p>
            </div>
          </div>
          <hr class="col-sm-12" />
          <!-- /* CMMC Level 1 Compliance Journey for Small Business */ -->
          <div class="col-12 py-4">
            <div class="section-title">
              <span>COMPLIANCE</span>
              <h2>CMMC Level 1 Compliance Journey for Small Business</h2>
            </div>
            <div class="row pt-5">
              <div class="col-1 no-gutters no-padding">
                <div class="row no-gutters no-padding">
                  <div class="col-12 no-padding">
                    <img
                      class="img-fluid arrow-height"
                      style="width: 100%; margin-top: 55%"
                      src="https://assets.projectspectrum.io/sba/sba_left_arrow.png"
                    />
                  </div>
                </div>
              </div>
              <div class="col-10 no-gutters no-padding">
                <div class="row no-gutters no-padding">
                  <div class="col-2 no-gutters no-padding sba_hover">
                    <a href="https://www.projectspectrum.io/#/signup" target="_blank"
                      ><img
                        class="img-fluid"
                        src="https://assets.projectspectrum.io/sba/sba_register.png"
                        id="sba_register"
                      />
                      <div class="sba_tooltip">
                        <p class="centered-text">
                          Register for project spectrum
                        </p>
                        <a href="https://www.projectspectrum.io/#/signup" target="_blank"></a>
                      </div>
                    </a>
                  </div>
                  <div class="col-2 no-gutters no-padding sba_hover">
                    <a href="https://www.projectspectrum.io/#/events" target="_blank">
                      <img
                        class="img-fluid"
                        src="https://assets.projectspectrum.io/sba/sba_webinar.png"
                        id="sba_webinar"
                      />
                      <div class="sba_tooltip">
                        <p class="centered-text">
                          Participate in CMMC Level 1 webinar
                        </p>
                        <a href="https://www.projectspectrum.io/#/events" target="_blank"></a>
                      </div>
                    </a>
                  </div>
                  <div class="col-2 no-gutters no-padding sba_hover">
                    <a href="https://www.projectspectrum.io/#/training-videos" target="_blank">
                      <img
                        class="img-fluid"
                        src="https://assets.projectspectrum.io/sba/sba_videos.png"
                        id="sba_videos"
                      />
                      <div class="sba_tooltip">
                        <p class="centered-text">
                          Watch training videos for CMMC Level 1 domains
                        </p>
                        <a href="https://www.projectspectrum.io/#/training-videos" target="_blank"></a>
                      </div>
                    </a>
                  </div>
                  <div class="col-2 no-gutters no-padding sba_hover">
                    <a href="https://www.projectspectrum.io/#/courses-encite" target="_blank"
                      ><img
                        class="img-fluid"
                        src="https://assets.projectspectrum.io/sba/sba_courses.png"
                        id="sba_courses"
                      />
                      <div class="sba_tooltip">
                        <p class="centered-text">
                          Complete CMMC Level 1 Online Course
                        </p>
                        <a href="https://www.projectspectrum.io/#/courses-encite" target="_blank"></a>
                      </div>
                    </a>
                  </div>
                  <div class="col-2 no-gutters no-padding sba_hover">
                    <a href="https://www.projectspectrum.io/#/cyber-readiness-check" target="_blank">
                      <img
                        class="img-fluid"
                        src="https://assets.projectspectrum.io/sba/sba_readiness_hover.png"
                        id="sba_readiness"
                      />
                      <div class="sba_tooltip">
                        <p class="centered-text">
                          Take the CMMC Level 1 Readiness Check
                        </p>
                        <a href="https://www.projectspectrum.io/#/cyber-readiness-check" target="_blank"></a>
                      </div>
                    </a>
                  </div>
                  <div class="col-2 no-gutters no-padding sba_hover">
                    <a href="https://cmmcab.org/osc-lp/" target="_blank">
                      <img
                        class="img-fluid"
                        src="https://assets.projectspectrum.io/sba/sba_evaluation_hover.png"
                        id="sba_evaluation"
                      />
                      <div class="sba_tooltip">
                        <p class="centered-text">
                          CMMC Level 1 preparedness for CMMC evaluation
                        </p>
                        <a
                          href="https://cmmcab.org/osc-lp/"
                          target="_blank"
                        ></a>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-1 no-gutters no-padding">
                <div class="row no-gutters no-padding">
                  <div class="col-12 no-padding">
                    <img
                      class="img-fluid arrow-height"
                      style="width: 100%; margin-top: 55%"
                      src="https://assets.projectspectrum.io/sba/sba_right_arrow.png"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr class="col-sm-12 mt-5" />
          <!-- /* Implementing the U.S. Department of Defense (DoD) ... */ -->
          <div class="col-12 py-4">
            <div class="mt-4 text-lg-left text-center">
              <div class="section-title">
                <span>IMPLEMENTATION</span>
                <h2>
                  Implementing the U.S. Department of Defense (DoD)
                  Cybersecurity Maturity Model Certification (CMMC)
                </h2>
              </div>
              <p>
                Previously, Defense Federal Acquisition Regulation Supplement
                (DFARS) regulations required all DoD contractors to self-certify
                their cybersecurity readiness using guidelines from the National
                Institute of Standards and Technology (NIST) Special Publication
                (SP) 800-171. NIST SP 800-171 provides businesses with
                recommended requirements for protecting defense-relevant
                information and reporting any cyber incidents. <br /><br />
                DoD unveiled its Cybersecurity Maturity Model
                Certification (CMMC) framework, which built upon NIST SP 800-171
                requirements to provide a new set of cybersecurity standards and
                best practices. CMMC maps controls and processes across three
                cybersecurity maturity levels ranging from basic to advanced.
                <br /><br />
                The CMMC level required for a business to win a DoD contract is
                explained in each request for proposal. While CMMC accreditation
                requirements are currently almost exclusively limited to DoD
                contracts, the cyber hygiene best practices and standards
                contained within the CMMC framework is applicable to all Federal
                Agencies.
              </p>
            </div>
          </div>
          <hr class="col-12" />
          <div class="col-12 py-2">
            <div><strong>SBA Resource Partners</strong></div>
            <a
              href="https://www.sba.gov/brand/assets/sba/resource-partners/SBA-ResourcePartnerNetwork-508_MCS0090.pdf"
              >https://www.sba.gov/brand/assets/sba/resource-partners/SBA-ResourcePartnerNetwork-508_MCS0090.pdf</a
            >
          </div>
        </div>
      </div>
    </section>
    <!-- Body Area End-->
  </div>
</template>
<script>
export default {
  name: 'placeholder',
  mounted() {
    this.init();
  },
  methods: {
    init() {
      /* The footer height no longer needs to be set */
      // this.initFooterHeight();
    },
    initFooterHeight() {
      var footerHeight = $('footer').outerHeight();
      $('.main-content').css('margin-bottom', footerHeight);
    },
  },
};
</script>
<style scoped>
.singup-button-desktop {
  background-color: #096cce;
  border-radius: 5px;
  border: none;
  color: white;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin: -5px;
  cursor: pointer;
}
.sba_tooltip {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: #008cba;
}
.centered-text {
  color: white;
  font-size: min(1.75vw, 1.5em);
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  padding: 0.25em;
  line-height: min(2.0vw, 1.25em);;
}
.sba_tooltip:hover {
  opacity: 1;
}
.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-padding {
  padding-right: 0;
  padding-left: 0;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

@media only screen and (min-width: 992px) {
  .arrow-height {
    height: 8em;
  }
}

@media only screen and (min-width: 576px) and (max-width: 991px) {
  .arrow-height {
    height: 4em;
  }
}

@media only screen and (max-width: 575px) {
  .arrow-height {
    height: 3em;
  }
}
</style>
