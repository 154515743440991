var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "main-content"
  }, [_vm._m(0), _c('section', {
    staticClass: "section portfolio-section",
    attrs: {
      "id": "portfolio"
    }
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "col-12 py-4"
  }, [_vm._m(1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "6",
      "lg": "7"
    }
  }, [_c('p', [_vm._v(" The lowest level of security controls a government contractor must have in place to earn a Cybersecurity Maturity Model Certification. All DIB contractors and subcontractors require at least CMMC Level 1 compliance to do business with the DoD. Level 1 demonstrates that an organization has the basic security controls in place to adequately protect FCI, which is not intended for public release. Level 1 has 17 practices that qualified government contractors should meet. ")])]), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "5"
    }
  }, [_c('img', {
    staticClass: "img-fluid cmmc-image",
    attrs: {
      "src": require("../../assets/images/cyberimages/level1.png"),
      "alt": ""
    }
  })])], 1)], 1), _c('div', {
    staticClass: "col-12 py-4"
  }, [_vm._m(2), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "6",
      "lg": "7"
    }
  }, [_c('p', [_vm._v(" Level 2 is the transitional phase between basic security measures to protect FCI and the measures required for sound protection of CUI. This is the bridge between baseline requirements and the authorization to handle sensitive data. Reaching this level indicates that a contractor is working towards good cyber hygiene while continuing to establish the processes needed to protect the CUI. To obtain this level, contractors must demonstrate they have 72 specific security practices in place. ")])]), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "5"
    }
  }, [_c('img', {
    staticClass: "img-fluid cmmc-image",
    attrs: {
      "src": require("../../assets/images/cyberimages/level2.png"),
      "alt": ""
    }
  })])], 1)], 1), _c('div', {
    staticClass: "col-12 py-4"
  }, [_vm._m(3), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "6",
      "lg": "7"
    }
  }, [_c('p', [_vm._v(" Organizations that have reached CMMC Level 3 have the basic security controls in place needed to protect sensitive data. Level 3 requires that an organization establish, maintain, and resource a plan demonstrating the management of activities for practice implementation. The plan may include information for missions, goals, project plans , resources, required training, and involvement of relevant stakeholders. Building on the security requirements of Levels 1 and 2, obtaining Level 3 indicates a contractor has put 130 cybersecurity requirements in place. ")])]), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "5"
    }
  }, [_c('img', {
    staticClass: "img-fluid cmmc-image",
    attrs: {
      "src": require("../../assets/images/cyberimages/level3.png"),
      "alt": ""
    }
  })])], 1)], 1)])]), _c('section', {
    staticClass: "section parallax-bg mb-5"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "z-index9 wow fadeIn",
    attrs: {
      "data-wow-duration": "1s"
    }
  }, [_c('h2', {
    staticClass: "text-white text-center"
  }, [_vm._v(" Check your CMMC Compliance Level ")]), _c('p', {
    staticStyle: {
      "color": "white",
      "text-align": "center"
    }
  }, [_vm._v(" Take our cyber readiness assessment to determine how prepared your business is to deal with cyber-attacks. ")]), _c('div', {
    staticClass: "d-flex justify-content-center mt-4"
  }, [_c('b-button', {
    staticClass: "outline-theme-btn",
    attrs: {
      "pill": "",
      "variant": "outline-light"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.push('cyber-readiness-check');
      }
    }
  }, [_vm._v("Take Readiness Check")])], 1)])])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "inner-header-bg-partner"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "section-breadcrumb"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12 justify-content-center align-self-center pb-2"
  }, [_c('div', {
    staticClass: "breadcrumb-content"
  }, [_c('div', {
    staticClass: "title-section"
  }, [_c('h1', {
    staticClass: "title text-center text-white"
  }, [_vm._v("About CMMC")])]), _c('div', {
    staticClass: "breadcrumb-nav"
  }, [_c('ul', {
    staticClass: "breadcrumb-link text-center list-unstyled mb-0"
  }, [_c('li', {
    staticClass: "text-white"
  }, [_c('i', {
    staticClass: "mdi mdi-home theme-light-color pr-2"
  }), _c('a', {
    attrs: {
      "href": "#"
    }
  }, [_vm._v("Home")])]), _c('li', {
    staticClass: "theme-light-color active"
  }, [_vm._v("About CMMC")])])])])])])])])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "section-title"
  }, [_c('span', [_vm._v("CMMC Level 1 ")]), _c('h2', [_vm._v("Basic Cybersecurity Practices")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "section-title"
  }, [_c('span', [_vm._v("CMMC Level 2 ")]), _c('h2', [_vm._v("Intermediate Cybersecurity Practices")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "section-title"
  }, [_c('span', [_vm._v("CMMC Level 3 ")]), _c('h2', [_vm._v("Advanced Cybersecurity Practices")])]);

}]

export { render, staticRenderFns }