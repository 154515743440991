<template>
  <div class="main-content">
    <!-- BradCrumb header Start-->
    <section class="inner-header-bg">
      <div class="container">
        <!-- Breadcrumb Start-->
        <div class="section-breadcrumb">
          <div class="container">
            <div class="row">
              <div
                class="col-md-12 justify-content-center align-self-center pb-2"
              >
                <div class="breadcrumb-content">
                  <div class="title-section">
                    <h1 class="title text-center text-white">Useful Tools</h1>
                  </div>
                  <div class="breadcrumb-nav">
                    <ul class="breadcrumb-link text-center list-unstyled mb-0">
                      <li class="text-white">
                        <i class="mdi mdi-home theme-light-color pr-2"></i>
                        <a href="/#/">Home</a>
                      </li>
                      <li class="theme-light-color active">Useful Tools</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Breadcrumb End -->
      </div>
    </section>
    <!-- BradCrumb header End -->
    <!-- Blog Grid Section Start -->
    <section class="section">
      <div class="container px-xs-1 px-md-5">
        <p>
          The Project Spectrum Useful Tools section offers an independent,
          third-party assessment of various cybersecurity-related platforms
          prepared by our cyber advisors. These reviews are vendor-agnostic and
          should not be interpreted as an endorsement for any product or
          platform. If you have questions or concerns, please contact our
          <a href="mailto:outreach@projectspectrum.io"
              >Outreach Team</a
            > for assistance.
        </p>
      </div>
      <div v-if="!isLoggedIn" class="my-3 container text-center">
        Tool Listings and Reviews are available.<br />Please
        <router-link :to="{ name: 'login' }">Login </router-link>
        or <router-link :to="{ name: 'signup' }">Register </router-link> to view
        the list of tools.<br />
        <br />
        <div class="login-register-btn-container mt-2">
          <router-link :to="{ name: 'login' }"
            ><b-button class="btn theme-btn rounded login-reg-btn"
              >Login</b-button
            ></router-link
          >
          <router-link :to="{ name: 'signup' }"
            ><b-button
              variant="none"
              class="btn theme-btn rounded login-reg-btn"
              >Register</b-button
            ></router-link
          >
        </div>
      </div>
      <div v-if="isLoggedIn" class="container-fluid px-xs-1 px-md-5">
        <b-row>
          <b-col lg="4" xl="3">
            <h5>Search and Filter</h5>
            <div class="p-3 px-4 my-2 mb-4 border border-secondary rounded">
              <!-- Search by Name -->
              <b-form-group label="Search by Name:">
                <b-form-input
                  v-model="searchQuery"
                  placeholder="Type to search..."
                  trim
                ></b-form-input
              ></b-form-group>
              <!-- Search by Catagory -->
              <b-form-group label="Catagories:">
                <b-form-select
                  v-model="searchCategory"
                  :options="optionsCategories"
                ></b-form-select>
              </b-form-group>
              <!-- Search by Tool Created By -->
              <b-form-group label="Tool Created By:">
                <b-form-select
                  v-model="searchCreatedBy"
                  :options="optionsCreatedBy"
                ></b-form-select>
              </b-form-group>
              <!-- Resource Type -->
              <b-form-group label="Training Resource Type:">
                <b-form-checkbox-group v-model="searchPaidFree">
                  <b-form-checkbox value="paid"> Paid </b-form-checkbox>
                  <b-form-checkbox value="free"> Free </b-form-checkbox>
                </b-form-checkbox-group>
              </b-form-group>
              <!-- Search by Price Range -->
              <b-form-group label="Price Range:">
                <b-form-select
                  v-model="searchPriceRange"
                  :options="optionsPriceRanges"
                ></b-form-select>
              </b-form-group>
            </div>
          </b-col>

          <b-col lg="8" xl="9">
            <div>
              <h5>Products</h5>
              <p>
                The Project Spectrum Tools Review section offers an independent,
                third-party assessment of various cybersecurity-related
                platforms prepared by our cyber advisors. These reviews are
                vendor-agnostic and should not be interpreted as an endorsement
                for any product or platform. If you have questions or concerns,
                please contact our
                <a href="mailto:outreach@projectspectrum.io">Outreach Team</a>
                for assistance.
              </p>
              <b-form-group
                id="fieldset-1"
                description="Select up to 5 tools to compare."
                label-for="input-1"
                valid-feedback="Thank you!"
              >
                <router-link
                  :to="{ name: 'tool-compare', query: { ids: compareArray } }"
                >
                  <b-button
                    id="input-1"
                    key=""
                    class="btn-compare-selected"
                    variant="secondary"
                    :disabled="compareArray.length === 0"
                    size="sm"
                    >Compare Products</b-button
                  >
                </router-link>
              </b-form-group>
            </div>
            <p class="pb-2" v-if="items.length === 0">
              Check back soon for tool reviews...
            </p>
            <p class="pb-2" v-if="displayedItems === 0 && items.length !== 0">
              No items match the search criteria...
            </p>
            <!-- Tool Table -->
            <b-table
              id="tool-table"
              :fields="fields"
              :items="displayedItems"
              :busy="isLoading"
              responsive
              sortable="true"
              class="mt-3"
              outlined
              hover
            >
              <!-- Busy Spinner -->
              <template #table-busy>
                <div class="text-center text-secondary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong class="pl-2">Loading...</strong>
                </div>
              </template>
              <!-- Main Column with Image -->
              <template #cell(title)="data">
                <div class="image-and-tool d-flex">
                  <div class="image-container">
                    <b-img
                      :src="data.item.mainImage"
                      class="tool-icon"
                      rounded
                      alt="Rounded image"
                    ></b-img>
                  </div>
                  <p class="clipped-text m-0">{{ data.item.title }}</p>
                </div>
              </template>
              <!-- See More Button -->
              <template #cell(more)="data">
                <div class="image-and-tool d-flex">
                  <router-link
                    :to="{ name: 'tool-details', query: { id: data.item.id } }"
                  >
                    <b-button class="btn-more" variant="primary" size="sm"
                      >Read More</b-button
                    >
                  </router-link>
                </div>
              </template>
              <!-- See More Button -->
              <template #cell(compare)="data">
                <div class="image-and-tool d-flex">
                  <b-button
                    @click="moreButtonSelection(data.item.id)"
                    class="btn-compare"
                    :variant="
                      compareArray.includes(data.item.id)
                        ? 'secondary'
                        : 'outline-secondary'
                    "
                    size="sm"
                    >Compare</b-button
                  >
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <div>
          <b-pagination
            id="blog-pagination"
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="center"
            @page-click="pageClicked"
          ></b-pagination>
        </div>
      </div>
    </section>
    <!-- Blog Grid Section End -->
  </div>
</template>
<script>
import axios from 'axios';
import { BPagination, BFormInput } from 'bootstrap-vue';
import { isLoggedIn, handleApiError } from '../../utils/authentication';
export default {
  name: 'tool-reviews',
  components: {
    BPagination,
    BFormInput,
  },
  props: {
    query: String,
  },
  data() {
    return {
      isLoading: false,
      items: [],
      itemCount: 0,
      currentPage: 1,
      perPage: 6,
      // Search
      searchQuery: this.query,
      searchCategory: null,
      searchCreatedBy: null,
      searchPriceRange: null,
      optionsCategories: [{ value: null, text: 'Search All' }],
      optionsCreatedBy: [{ value: null, text: 'Search All' }],
      optionsPriceRanges: [{ value: null, text: 'Search All' }],
      searchPaidFree: ['paid', 'free'],
      // Compare
      compareArray: [],
      // Table
      fields: [
        // A virtual column that doesn't exist in items
        {
          key: 'title',
          label: 'Tool',
          tdClass: 'align-middle',
          sortable: true,
        },
        {
          key: 'toolCreatedBy',
          label: 'Creator',
          tdClass: 'align-middle',
          sortable: true,
        },
        {
          key: 'toolCategory',
          label: 'Category',
          tdClass: 'align-middle',
          sortable: true,
        },
        {
          key: 'toolPriceRange',
          label: 'Price',
          tdClass: 'align-middle',
          sortable: true,
        },
        {
          key: 'more',
          label: 'Details',
          tdClass: 'align-middle',
        },
        {
          key: 'compare',
          label: 'Compare',
          tdClass: 'align-middle',
        },
      ],
    };
  },
  computed: {
    isLoggedIn() {
      return isLoggedIn();
    },
    itemsFromSearchQuery() {
      var filteredItems = this.items;
      // Name Search (Titlte and Creator)
      if (this.searchQuery) {
        filteredItems = filteredItems.filter((item) => {
          return this.searchQuery
            .toLowerCase()
            .split(' ')
            .every(
              (v) =>
                item.title?.toLowerCase().includes(v) ||
                item.toolCreatedBy?.toLowerCase().includes(v)
            );
        });
      }
      // Catagory Search
      if (this.searchCategory) {
        filteredItems = filteredItems.filter((item) => {
          return this.searchCategory.includes(item.toolCategory);
        });
      }
      // Creator Search
      if (this.searchCreatedBy) {
        filteredItems = filteredItems.filter((item) => {
          return this.searchCreatedBy.includes(item.toolCreatedBy);
        });
      }
      // Price Range Search
      if (this.searchPriceRange) {
        filteredItems = filteredItems.filter((item) => {
          return this.searchPriceRange.includes(item.toolPriceRange);
        });
      }
      // Paid/Free Search
      if (this.searchPaidFree) {
        filteredItems = filteredItems.filter((item) => {
          if (
            this.searchPaidFree.includes('paid') &&
            item.toolPriceRange.toLowerCase() !== 'free'
          ) {
            return true;
          }
          if (
            this.searchPaidFree.includes('free') &&
            item.toolPriceRange.toLowerCase() === 'free'
          ) {
            return true;
          }
          return false;
        });
      }
      return filteredItems;
    },
    displayedItems() {
      return this.itemsFromSearchQuery.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
      );
    },
    totalRows() {
      return this.itemsFromSearchQuery.length;
    },
  },
  mounted: async function () {
    this.init();
    if (this.isLoggedIn) {
      this.getToolReviews(); // async
    }
  },
  methods: {
    init() {
      /* The footer height no longer needs to be set */
      // this.initFooterHeight();
    },
    initFooterHeight() {
      var footerHeight = $('footer').outerHeight();
      $('.main-content').css('margin-bottom', footerHeight);
    },

    async getToolReviews() {
      /*  API call to server: GET /api/resources/blogs  */
      this.isLoading = true;
      axios
        .get('/api/resources/toolReviews')
        .then(async (response) => {
          //console.log('Useful Tools', response);
          this.items = response.data.Items;
          this.populateSearchCatagories();
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    async populateSearchCatagories() {
      // Populate Search Options
      const uniqueCatagories = [
        ...new Set(this.items.map((item) => item.toolCategory)),
      ];
      const uniqueCreatedBy = [
        ...new Set(this.items.map((item) => item.toolCreatedBy)),
      ];
      const uniquePrice = [
        ...new Set(this.items.map((item) => item.toolPriceRange)),
      ];

      uniqueCatagories.sort();
      uniqueCreatedBy.sort();
      uniquePrice.sort();

      this.optionsCategories = await Promise.all(
        uniqueCatagories.map(async (item) => {
          return { value: item, text: item };
        })
      );
      this.optionsCreatedBy = await Promise.all(
        uniqueCreatedBy.map(async (item) => {
          return { value: item, text: item };
        })
      );
      this.optionsPriceRanges = await Promise.all(
        uniquePrice.map(async (item) => {
          return { value: item, text: item };
        })
      );

      this.optionsCategories.unshift({ value: null, text: 'Search All' });
      this.optionsCreatedBy.unshift({ value: null, text: 'Search All' });
      this.optionsPriceRanges.unshift({ value: null, text: 'Search All' });

      //console.log('Categories', this.optionsCategories);
    },
    pageClicked() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      // document.getElementById('tool-table').scrollIntoView();
    },
    moreButtonSelection(id) {
      if (this.compareArray.includes(id)) {
        this.compareArray.splice(this.compareArray.indexOf(id), 1);
      } else {
        this.compareArray.push(id);
      }
    },
  },
};
</script>
<style scoped>
.inner-header-bg {
  background-image: url('~@/assets/images/headers/courses.png');
  background-position: center center;
}
#blog-pagination li {
  margin: 0px;
}

.tool-icon {
  object-fit: contain;
  height: 40px;
  width: 70px;
  margin-right: 10px;
}

.image-and-tool {
  max-width: 325px;
  align-items: center;
}

.clipped-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.form-control {
  height: auto;
}

.btn-compare-selected {
  height: auto;
  padding: 0.5rem 1.5rem;
}

.btn-more {
  width: 100px;
  height: auto;
  padding: 0.25rem 0.5rem;
}

.btn-compare {
  width: 100px;
  height: auto;
  padding: 0.25rem 0.5rem;
}

@media (hover: none) {
  .btn-outline-secondary.btn-compare:hover {
    color: #6c757d;
    border-color: #6c757d;
    background: white;
  }
}
</style>
