<template>
  <b-modal
    size="lg"
    ref="my-modal"
    :title="title"
    @ok="handleAccept"
    @cancel="handleDecline"
    ok-title="Accept"
    cancel-title="Decline"
    ok-variant="success"
    cancel-variant="danger"
    no-close-on-backdrop
    no-close-on-esc
    :busy="busy"
  >
    <p class="my-2">You have invitations from the following organizations:</p>
    <li
      v-for="item in organizations"
      :key="item"
      class="font-weight-bold px-2 py-0"
    >
      {{ item }}
    </li>
    <p class="my-2">
      Do you want to accept and become a part of
      <b>{{ currentInvitation?.Organization }}</b
      >?
    </p>
  </b-modal>
</template>
<script>
import axios from 'axios';
import { isLoggedIn, handleApiError, getUserEmail, setUser, storeAuthTokens} from '../utils/authentication';
export default {
  name: 'invitation-modal',
  data() {
    return {
      invitations: [],
      busy: false,
    };
  },
  mounted() {
    this.getInvitations();
  },
  computed: {
    title() {
      return this.invitations.length === 1
        ? 'Pending Invitation'
        : `${this.invitations.length} Pending Invitations`;
    },
    organizations() {
      const orgs = [];
      for (let i = 0; i < this.invitations.length; i++) {
        orgs.push(this.invitations[i].Organization);
      }
      return orgs;
    },
    currentInvitation() {
      return this.invitations[0];
    },
  },
  methods: {
    show() {
      this.$refs['my-modal'].show();
    },
    hide() {
      this.$refs['my-modal'].hide();
    },
    getInvitations() {
      this.busy = true;
      axios
        .get(`/api/user/`)
        .then((response) => {
          
          /** Set User in Store */
          setUser(response.data);
          if (response.data.invitations.length > 0) {
            /*console.log(
              'Pending Invitations',
              response.data.invitations.length
            );*/
            this.invitations = response.data.invitations;
            this.show();
          }else{
            this.hide();
          }
        })
        .catch((error) => {
          handleApiError(error.response, this.$router);
        })
        .finally(() => {
          this.busy = false;
        });
    },
    async handleAccept(bvModalEvt) {
      // Prevent Modal from closing on button press
      bvModalEvt.preventDefault();
      // Accept current invitaiton, and decline all others
      for (let i = 0; i < this.invitations.length; i++) {
        if (i == 0) {
          await this.acceptInvitation(this.invitations[i]);
        } else {
          await this.declineInvitation(this.invitations[i]);
        }
      }
      this.$emit('invite-accepted')
      this.hide();
    },
    async handleDecline(bvModalEvt) {
      bvModalEvt.preventDefault();
      // Decline current invitation, and ask about next invitaiton
      await this.declineInvitation(this.currentInvitation);
      this.getInvitations();
    },
    async acceptInvitation(invitation) {
      /*  API call to server: POST /api/organization/invitation/accept */
      //console.log('Accepting Invitation', invitation);
      axios
        .post(`/api/organization/invitation/accept`, {
          invitationID: invitation.invitationID,
          accepted: true,
        })
        .then((response) => {
          //console.log('Accept Invitation Response', response);
          this.refreshSession()
        })
        .catch((error) => {
          handleApiError(error, this.$router);
        });
    },
    async declineInvitation(invitation) {
      /*  API call to server: POST /api/organization/invitation/accept */
      //console.log('Declining Invitation', invitation);
      axios
        .post(`/api/organization/invitation/accept`, {
          invitationID: invitation.invitationID,
          accepted: false,
        })
        .then((response) => {
          console.log('Decline Invitation Response', response);
        })
        .catch((error) => {
          handleApiError(error, this.$router);
        });
    },
    getAccountData(){
       axios.get(`/api/user/`).then((response) => {
              setUser(response.data);
            });
    },
    refreshSession() {
        this.busy = true;
        axios
          .post('/api/user/session/refresh', {
            email: getUserEmail(),
          })
          .then((response) => {
            //console.log('Session Refreshed', response);
            storeAuthTokens(response.data);
            this.getAccountData();
          })
          .catch((error) => {
            handleApiError(error, this.$router);
            this.error = error.response.data.message;
          })
          .finally(() => {
            this.busy = false;
          });
      },
  },
};
</script>
<style scoped></style>
