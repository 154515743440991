var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm._m(0);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "main-content"
  }, [_c('section', {
    staticClass: "inner-header-bg"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "section-breadcrumb"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12 justify-content-center align-self-center pb-2"
  }, [_c('div', {
    staticClass: "breadcrumb-content"
  }, [_c('div', {
    staticClass: "title-section"
  }, [_c('h1', {
    staticClass: "title text-center text-white"
  }, [_vm._v("What We Do")])]), _c('div', {
    staticClass: "breadcrumb-nav"
  }, [_c('ul', {
    staticClass: "breadcrumb-link text-center list-unstyled mb-0"
  }, [_c('li', {
    staticClass: "text-white"
  }, [_c('i', {
    staticClass: "mdi mdi-home theme-light-color pr-2"
  }), _c('a', {
    attrs: {
      "href": "/#/"
    }
  }, [_vm._v("Home")])]), _c('li', {
    staticClass: "theme-light-color active"
  }, [_vm._v("What We Do")])])])])])])])])])]), _c('section', {
    staticClass: "section about-bottom"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row align-items-center"
  }, [_c('div', {
    staticClass: "col-md-7 col-sm-12"
  }, [_c('div', {
    staticClass: "section-title"
  }, [_c('span', [_vm._v("ABOUT US")]), _c('h2', [_vm._v("Creative Solutions to Improve your Business")])]), _c('p', {
    staticClass: "about-text mt-4"
  }, [_vm._v(" Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. ")]), _c('div', {
    staticClass: "mt-4"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-sm-6"
  }, [_c('div', {
    staticClass: "about-list"
  }, [_c('div', {
    staticClass: "about_series"
  }, [_c('h4', {
    staticClass: "font-20 theme-light-color"
  }, [_vm._v("01.")])]), _c('div', {
    staticClass: "about_desc section-title"
  }, [_c('h5', {
    staticClass: "font-20"
  }, [_vm._v("Creative Strategy")]), _c('p', [_vm._v("Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.")])])])]), _c('div', {
    staticClass: "col-sm-6"
  }, [_c('div', {
    staticClass: "about-list"
  }, [_c('div', {
    staticClass: "about_series"
  }, [_c('h4', {
    staticClass: "font-20 theme-light-color"
  }, [_vm._v("02.")])]), _c('div', {
    staticClass: "about_desc section-title"
  }, [_c('h5', {
    staticClass: "font-20"
  }, [_vm._v("Digital Solutions")]), _c('p', [_vm._v("Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.")])])])])])])]), _c('div', {
    staticClass: "col-md-5 col-sm-12"
  }, [_c('div', {
    staticClass: "about-inner-img mt-lg-0 mt-4"
  }, [_c('img', {
    staticClass: "img-fluid w-100",
    attrs: {
      "src": "assets/images/aboutus/about-01.jpg",
      "alt": ""
    }
  })])])])])]), _c('section', {
    staticClass: "section pt-0 swiper-container testimonial-section",
    attrs: {
      "id": "testimonial"
    }
  }, [_c('div', {
    staticClass: "testimonial-header-area bg-image",
    attrs: {
      "data-image-src": "assets/images/testi/testi-bg.jpg"
    }
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "text-center section-title"
  }, [_c('span', [_vm._v("OUR CLIENTS")]), _c('h2', [_vm._v("What they says about us")])])])]), _c('div', {
    staticClass: "testimonial-content-area wow fadeIn",
    attrs: {
      "data-wow-duration": "1s"
    }
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "client-swiper-slider mt-4"
  }, [_c('div', {
    staticClass: "client-container"
  }, [_c('div', {
    staticClass: "swiper-wrapper"
  }, [_c('div', {
    staticClass: "swiper-slide"
  }, [_c('div', {
    staticClass: "testimonial-item bg-white"
  }, [_c('h6', {
    staticClass: "client-title font-20"
  }, [_vm._v("Rex Watson")]), _c('span', {
    staticClass: "client-designation"
  }, [_vm._v("Everline, Product Manager")]), _c('figure', {
    staticClass: "client-thumb"
  }, [_c('img', {
    staticClass: "img-fluid rounded-circle",
    attrs: {
      "src": "assets/images/testi/client.jpg",
      "alt": "Client"
    }
  })]), _c('p', {
    staticClass: "mb-0"
  }, [_vm._v("\"Amazing fast and reliable customer support! The team of developers are willing to go mile for customer service! Thanks!\"")])])]), _c('div', {
    staticClass: "swiper-slide"
  }, [_c('div', {
    staticClass: "testimonial-item bg-white"
  }, [_c('h6', {
    staticClass: "client-title font-20"
  }, [_vm._v("Rex Watson")]), _c('span', {
    staticClass: "client-designation"
  }, [_vm._v("Everline, Product Manager")]), _c('figure', {
    staticClass: "client-thumb"
  }, [_c('img', {
    staticClass: "img-fluid rounded-circle",
    attrs: {
      "src": "assets/images/testi/client.jpg",
      "alt": "Client"
    }
  })]), _c('p', {
    staticClass: "mb-0"
  }, [_vm._v("\"Amazing fast and reliable customer support! The team of developers are willing to go mile for customer service! Thanks!\"")])])]), _c('div', {
    staticClass: "swiper-slide"
  }, [_c('div', {
    staticClass: "testimonial-item bg-white"
  }, [_c('h6', {
    staticClass: "client-title font-20"
  }, [_vm._v("Rex Watson")]), _c('span', {
    staticClass: "client-designation"
  }, [_vm._v("Everline, Product Manager")]), _c('figure', {
    staticClass: "client-thumb"
  }, [_c('img', {
    staticClass: "img-fluid rounded-circle",
    attrs: {
      "src": "assets/images/testi/client.jpg",
      "alt": "Client"
    }
  })]), _c('p', {
    staticClass: "mb-0"
  }, [_vm._v("\"Amazing fast and reliable customer support! The team of developers are willing to go mile for customer service! Thanks!\"")])])]), _c('div', {
    staticClass: "swiper-slide"
  }, [_c('div', {
    staticClass: "testimonial-item bg-white"
  }, [_c('h6', {
    staticClass: "client-title font-20"
  }, [_vm._v("Rex Watson")]), _c('span', {
    staticClass: "client-designation"
  }, [_vm._v("Everline, Product Manager")]), _c('figure', {
    staticClass: "client-thumb"
  }, [_c('img', {
    staticClass: "img-fluid rounded-circle",
    attrs: {
      "src": "assets/images/testi/client.jpg",
      "alt": "Client"
    }
  })]), _c('p', {
    staticClass: "mb-0"
  }, [_vm._v("\"Amazing fast and reliable customer support! The team of developers are willing to go mile for customer service! Thanks!\"")])])])]), _c('div', {
    staticClass: "swiper-pagination"
  })])])])])])])]), _c('section', {
    staticClass: "section fun-facts bg-light"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-3 col-6"
  }, [_c('div', {
    staticClass: "d-flex align-items-center facts-item"
  }, [_c('div', {
    staticClass: "icon border-right"
  }, [_c('span', {
    staticClass: "theme-light-color mdi mdi-cube-outline"
  })]), _c('div', {
    staticClass: "funfacts-items"
  }, [_c('div', [_c('span', {
    staticClass: "funfact-number",
    attrs: {
      "id": "number1"
    }
  }, [_vm._v("69")]), _c('span', {
    staticClass: "plus-icon"
  }, [_vm._v("+")])]), _c('p', {
    staticClass: "name"
  }, [_vm._v("Projects")])])])]), _c('div', {
    staticClass: "col-md-3 col-6"
  }, [_c('div', {
    staticClass: "d-flex align-items-center facts-item"
  }, [_c('div', {
    staticClass: "icon border-right"
  }, [_c('span', {
    staticClass: "theme-light-color mdi mdi-trophy-outline"
  })]), _c('div', {
    staticClass: "funfacts-items"
  }, [_c('div', [_c('span', {
    staticClass: "funfact-number",
    attrs: {
      "id": "number2"
    }
  }, [_vm._v("45")]), _c('span', {
    staticClass: "plus-icon"
  }, [_vm._v("+")])]), _c('p', {
    staticClass: "name"
  }, [_vm._v("Awards Winning")])])])]), _c('div', {
    staticClass: "col-md-3 col-6"
  }, [_c('div', {
    staticClass: "d-flex align-items-center facts-item"
  }, [_c('div', {
    staticClass: "icon border-right"
  }, [_c('span', {
    staticClass: "theme-light-color mdi mdi-account-group-outline"
  })]), _c('div', {
    staticClass: "funfacts-items"
  }, [_c('div', [_c('span', {
    staticClass: "funfact-number",
    attrs: {
      "id": "number3"
    }
  }, [_vm._v("212")]), _c('span', {
    staticClass: "plus-icon"
  }, [_vm._v("+")])]), _c('p', {
    staticClass: "name"
  }, [_vm._v("Satisfied clients")])])])]), _c('div', {
    staticClass: "col-md-3 col-6"
  }, [_c('div', {
    staticClass: "d-flex align-items-center facts-item"
  }, [_c('div', {
    staticClass: "icon border-right"
  }, [_c('span', {
    staticClass: "theme-light-color mdi mdi-trending-up"
  })]), _c('div', {
    staticClass: "funfacts-items"
  }, [_c('div', [_c('span', {
    staticClass: "funfact-number",
    attrs: {
      "id": "number4"
    }
  }, [_vm._v("64")]), _c('span', {
    staticClass: "plus-icon"
  }, [_vm._v("+")])]), _c('p', {
    staticClass: "name"
  }, [_vm._v("Industry Expertise")])])])])])])]), _c('section', {
    staticClass: "section"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "text-center section-title"
  }, [_c('span', [_vm._v("FAQ's")]), _c('h2', [_vm._v("Frequently Ask Questions")])]), _c('div', {
    staticClass: "row mt-4"
  }, [_c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "accordion accordion-faq wow fadeInUp",
    attrs: {
      "id": "accordion",
      "data-wow-duration": "1s"
    }
  }, [_c('div', {
    staticClass: "card bg-white"
  }, [_c('a', {
    staticClass: "card-header text-dark bg-white py-4 collapsed",
    attrs: {
      "data-toggle": "collapse",
      "href": "#collapseOne"
    }
  }, [_c('span', {
    staticClass: "card-title"
  }, [_c('strong', [_vm._v(" How do I get access to a theme?")])])]), _c('div', {
    staticClass: "card-body collapse",
    attrs: {
      "id": "collapseOne",
      "data-parent": "#accordion"
    }
  }, [_c('p', {
    staticClass: "m-0"
  }, [_vm._v(" Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. simplify sustainable applications whereas adaptive schemas. ")])])]), _c('div', {
    staticClass: "card bg-white"
  }, [_c('a', {
    staticClass: "card-header text-dark bg-white py-4 collapsed",
    attrs: {
      "data-toggle": "collapse",
      "data-parent": "#accordion",
      "href": "#collapseTwo"
    }
  }, [_c('span', {
    staticClass: "card-title"
  }, [_c('strong', [_vm._v(" How do I get access to a theme?")])])]), _c('div', {
    staticClass: "card-body collapse",
    attrs: {
      "id": "collapseTwo",
      "data-parent": "#accordion"
    }
  }, [_c('p', {
    staticClass: "m-0"
  }, [_vm._v(" Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. simplify sustainable applications whereas adaptive schemas. ")])])]), _c('div', {
    staticClass: "card bg-white"
  }, [_c('a', {
    staticClass: "card-header text-dark bg-white py-4 collapsed",
    attrs: {
      "data-toggle": "collapse",
      "data-parent": "#accordion",
      "href": "#collapseThree"
    }
  }, [_c('span', {
    staticClass: "card-title"
  }, [_c('strong', [_vm._v(" How do I get access to a theme?")])])]), _c('div', {
    staticClass: "card-body collapse pt-0",
    attrs: {
      "id": "collapseThree",
      "data-parent": "#accordion"
    }
  }, [_c('p', {
    staticClass: "m-0"
  }, [_vm._v(" Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. simplify sustainable applications whereas adaptive schemas. ")])])])])]), _c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "accordion accordion-faq wow fadeInUp",
    attrs: {
      "id": "accordion1",
      "data-wow-duration": "1s"
    }
  }, [_c('div', {
    staticClass: "card bg-white"
  }, [_c('a', {
    staticClass: "card-header text-dark bg-white py-4 collapsed",
    attrs: {
      "data-toggle": "collapse",
      "data-parent": "#accordion1",
      "href": "#collapseFour"
    }
  }, [_c('span', {
    staticClass: "card-title"
  }, [_c('strong', [_vm._v(" How do I get access to a theme?")])])]), _c('div', {
    staticClass: "card-body collapse",
    attrs: {
      "id": "collapseFour",
      "data-parent": "#accordion1"
    }
  }, [_c('p', {
    staticClass: "m-0"
  }, [_vm._v(" Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. simplify sustainable applications whereas adaptive schemas. ")])])]), _c('div', {
    staticClass: "card bg-white"
  }, [_c('a', {
    staticClass: "card-header text-dark bg-white py-4 collapsed",
    attrs: {
      "data-toggle": "collapse",
      "data-parent": "#accordion1",
      "href": "#collapseFive"
    }
  }, [_c('span', {
    staticClass: "card-title"
  }, [_c('strong', [_vm._v(" How do I get access to a theme?")])])]), _c('div', {
    staticClass: "card-body collapse",
    attrs: {
      "id": "collapseFive",
      "data-parent": "#accordion1"
    }
  }, [_c('p', {
    staticClass: "m-0"
  }, [_vm._v(" Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. simplify sustainable applications whereas adaptive schemas. ")])])]), _c('div', {
    staticClass: "card bg-white"
  }, [_c('a', {
    staticClass: "card-header text-dark bg-white py-4 collapsed",
    attrs: {
      "data-toggle": "collapse",
      "data-parent": "#accordion1",
      "href": "#collapseSix"
    }
  }, [_c('span', {
    staticClass: "card-title"
  }, [_c('strong', [_vm._v(" How do I get access to a theme?")])])]), _c('div', {
    staticClass: "card-body collapse",
    attrs: {
      "id": "collapseSix",
      "data-parent": "#accordion1"
    }
  }, [_c('p', {
    staticClass: "m-0"
  }, [_vm._v(" Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. simplify sustainable applications whereas adaptive schemas. ")])])])])])])])])]);

}]

export { render, staticRenderFns }