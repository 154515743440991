<template>
  <div>
    <ul>
      <li v-for="(item, index) in items" :key="index" style="list-style:none">
        <div v-if="item.State"><strong>State:</strong> {{ item.State }}</div>
        <div v-if="item.Name"><strong>Name:</strong> {{ item.Name }}</div>
        <div v-if="item['Address Line 1']">
          <strong>Address Line 1:</strong> {{ item["Address Line 1"] }}
        </div>
        <div v-if="item['Address Line 2']">
          <strong>Address Line 2:</strong> {{ item["Address Line 2"] }}
        </div>
        <div v-if="item.City"><strong>City:</strong> {{ item.City }}</div>
        <div v-if="item['Postal Code']">
          <strong>Postal Code:</strong> {{ item["Postal Code"] }}
        </div>
        <div v-if="item['Phone Number']">
          <strong>Phone Number: </strong>
          <a :href="'tel:' + item['Phone Number']">{{
            item["Phone Number"]
          }}</a>
        </div>
        <div v-if="item.Extension">
          <strong>Extension: </strong> {{ item.Extension }}
        </div>
        <div v-if="item['email for references']">
          <strong>Email: </strong>
          <a :href="'mailto:' + item['email for references']">{{
            item["email for references"]
          }}</a>
        </div>
        <div v-if="item.website">
          <strong>Website: </strong>
          <a :href="item.website" target="_blank">{{ item.website }}</a>
        </div>
        <div v-if="item['Organization Service Area']">
          <strong>Organization Service Area: </strong>
          <span v-if="item['Organization Service Area']" class="countyCell">
            {{ (item['Organization Service Area']).toLowerCase() }}
          </span>
        </div>
        <hr v-if="index < items.length - 1" />
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "CustomListComponent",
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped>
/*Add style later*/

  .countyCell {
    display: inline;
   text-transform: capitalize !important;
  }

</style>
