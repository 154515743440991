var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm._m(0);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "main-content"
  }, [_c('section', {
    staticClass: "inner-header-bg-partner"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "section-breadcrumb"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12 justify-content-center align-self-center pb-2"
  }, [_c('div', {
    staticClass: "breadcrumb-content"
  }, [_c('div', {
    staticClass: "title-section"
  }, [_c('h1', {
    staticClass: "title text-center text-white"
  }, [_vm._v("What We Do")])]), _c('div', {
    staticClass: "breadcrumb-nav"
  }, [_c('ul', {
    staticClass: "breadcrumb-link text-center list-unstyled mb-0"
  }, [_c('li', {
    staticClass: "text-white"
  }, [_c('i', {
    staticClass: "mdi mdi-home theme-light-color pr-2"
  }), _c('a', {
    attrs: {
      "href": "#"
    }
  }, [_vm._v("Home")])]), _c('li', {
    staticClass: "theme-light-color active"
  }, [_vm._v("What We Do")])])])])])])])])])]), _c('section', {
    staticClass: "section portfolio-section",
    attrs: {
      "id": "portfolio"
    }
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "col-12 py-4"
  }, [_c('div', [_c('img', {
    staticClass: "img-fluid w-100",
    attrs: {
      "src": require("../../assets/images/aboutus.jpg"),
      "alt": ""
    }
  })]), _c('br'), _vm._v(" "), _c('br'), _c('div', [_c('p', [_vm._v(" The APEX Accelerators, formerly known as the Procurement Technical Assistance Program (PTAP), was authorized by Congress in 1985 to expand the number of businesses capable of participating in government contracts . The National Defense Authorization Act (NDAA) for the FY 2020 ordered the PTAP to move to Under Secretary of Defense for Acquisition and Sustainment (USD(A&S)); and the DoD Office of Small Business Programs (OSBP) began to manage and operate PTAP with a new name, APEX Accelerators, effective FY 2023. The APEX Accelerators program focuses on building a strong, sustainable, and resilient U.S. supply chains by assisting a wide range of businesses that pursue and perform under contracts with the DoD, other federal agencies, state and local governments and with government prime contractors. ")])]), _c('div', {
    staticClass: "col-12"
  }, [_c('h2', [_vm._v("APEX Accelerators help businesses")]), _c('blockquote', {
    staticClass: "blockquote"
  }, [_c('p', [_c('ul', [_c('li', [_vm._v("Complete registration with a wide range of databases necessary for them to participate in the government marketplace (e.g., SAM).")]), _c('li', [_vm._v("Identify which agencies and offices may need their products or services and how connect with buying agencies and offices.")]), _c('li', [_vm._v("Determine whether they are ready for government opportunities and how to position themselves to succeed.")]), _c('li', [_vm._v("Navigate solicitations and potential funding opportunities.")]), _c('li', [_vm._v("Receive notifications of government contract opportunities on a regular basis.")]), _c('li', [_vm._v("Network with buying officers, prime contractors, and other businesses.")]), _c('li', [_vm._v("Resolve performance issues and prepare for audit, only if the service is needed, after receiving an award.")])])])]), _c('p', [_vm._v(" Today, APEX Accelerators are placing an emphasis on helping underserved businesses that are eligible for certain government solicitations designed for the promotion of diversity, inclusion, and equity, and guiding them throughout the entire process. APEX Accelerators will continue increasing the number of Defense Industrial Base (DIB)-ready and Government Industrial Base (GIB, including local and state governments)-ready businesses including: "), _c('ul', [_c('li', [_vm._v("Disadvantaged Businesses (SDBs) ")]), _c('li', [_vm._v("Historically Under-utilized Business Zones (HUBZones) ")]), _c('li', [_vm._v("Service-Disabled Veteran-Owned Businesses (SDVOSBs) ")]), _c('li', [_vm._v("Women-Owned Small Businesses (WOSBs), and ")]), _c('li', [_vm._v("Other underserved small businesses ")])])])])])])])]);

}]

export { render, staticRenderFns }