<template>
  <div class="main-content">
    <!-- BradCrumb header Start-->
    <section class="inner-header-bg">
      <div class="container">
        <div class="section-breadcrumb">
          <div class="row">
            <div class="col-md-12 justify-content-center align-self-center pb-2">
              <div class="breadcrumb-small-content"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- BradCrumb header End -->
    <!-- Body Area Start-->
    <section class="section pt-0">
      <div class="container" v-if="isLoggedIn" style="min-height: 700px">
        <div v-if="showModal">
            <transition name="modal">
              <div class="modal-mask">
                <div class="modal-wrapper">
                  <div class="modal-dialog" role="document">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h6 class="modal-title">{{ this.titleText }}</h6>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true" @click="showModal = false">&times;</span>
                        </button>
                      </div>
                      <div class="modal-body">
                        <!--
                        <div style="position: relative; padding-top: 56.25%;"><iframe src="https://customer-vqtiuw508mb7y2yt.cloudflarestream.com/f51ffa427ced0bf4d02e63be7250a97d/iframe?autoplay=true&poster=https%3A%2F%2Fcustomer-vqtiuw508mb7y2yt.cloudflarestream.com%2Ff51ffa427ced0bf4d02e63be7250a97d%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600&startTime=1m55s" style="border: none; position: absolute; top: 0; left: 0; height: 100%; width: 100%;" allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;" allowfullscreen="true"></iframe></div>
                         -->
                         <div style="position: relative; padding-top: 56.25%;"><iframe :src=videoURL style="border: none; position: absolute; top: 0; left: 0; height: 100%; width: 100%;" allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;" allowfullscreen="true"></iframe></div>
                        </div>
                      <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" @click="showModal = false">Close</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
          </div>
        <!-- surveySet - used to track when survey is ready to render -->
        <div v-if="surveySet" id="surveyContainer" class="row surveyDiv">
          <!-- If you want to hide survey, comment the lines below -->
          <survey :survey="survey"></survey>
        </div>
        <div class="pt-4 mt-4" v-show="isLoading">
          <b-spinner label="Loading..."></b-spinner>
        </div> 
        
          <!-- <button @click="showModal = true">Click</button>
          <button id="show-modal" @click="showModal = true">Show Modal</button> -->
        <div v-show="isSurveyComplete && completeButtonClicked" id="surveyComplete" class="mt-4 pt-4">
          <h5>Your Assessment is complete.</h5>
          <div class="row">
            <div class="pa-5 col-md-12">
              <!--  <v-app id="cyber"> -->
              <div class="pt-4" v-show="isNIST">
                <v-col cols="9">
                  <v-card elevation="4">
                    <v-card-text>
                     {{completeText}}
                      Your score: <strong> {{ score }}/110 </strong>
                    </v-card-text>
                  </v-card>
                </v-col>
              </div>
              <v-col cols="9">
                <center>
                  <v-progress-circular v-show="isNIST" :rotate="180" :size="300" :width="60" :value="score"
                    color="blue-grey">
                    <h5>
                      <strong>{{ score }}/110</strong>
                    </h5>
                  </v-progress-circular>
                </center>
              </v-col>
              <div class="pt-4" v-show="!isNIST">
                <v-col cols="9">
                  <v-card elevation="4">
                    <v-card-text>
                      {{completeText}}
                      Your score: <strong> {{ score }}% </strong>
                    </v-card-text>
                  </v-card>
                </v-col>
              </div>
              <v-col cols="9">
                <center>
                  <v-progress-circular v-show="!isNIST" :rotate="180" :size="300" :width="60" :value="score"
                    color="blue-grey">
                    <h6>{{ score }}%</h6>
                  </v-progress-circular>
                </center>
              </v-col>

              <div class="row mt-5 text-center">
                <div class="col-md-4 text-center">
                  <button type="button" @click="printAction" class="btn theme-btn rounded w-100">
                    Print Answers
                  </button>
                </div>
                <div class="col-md-4 text-center">
                  <download-csv class="" :data="surveyCSV" name="CyberReadiness.csv">
                    <button type="button" class="btn theme-btn rounded w-100">
                      Export to CSV
                    </button>
                  </download-csv>
                </div>
              </div>
              <div class="row mt-5">
                <div class="col-md-4 text-center">
                  <a href="/dashboard" class="btn theme-btn rounded w-100">
                    Go to Dashboard
                  </a>
                </div>
                <div class="col-md-4 text-center">
                   <button @click="reloadPage" type="button" class="btn theme-btn rounded w-100">
                      Back to Cyber Readiness Check
                    </button>
                </div>
              </div>
              <div id="printDiv"></div>
              <!-- </v-app> -->
            </div>
          </div>
        </div>
        <div v-show="isSurveyPartial && completeButtonClicked" id="surveyPartial" class="mt-4 pt-4">
          <h5>Your responses are saved.</h5>
          <div class="row">
            <div class="pa-5 col-md-8">
              <p class="pt-4"><strong>Progress: </strong></p>
              <v-app>
              <v-col cols="9">
                <center>
                  <v-progress-linear :value="progress" color="cyan" height="25">
                    <template v-slot:default="">
                      <strong>{{ progress }}%</strong>
                    </template>
                  </v-progress-linear>
                </center>
                <div class="row mt-4 pt-4">
                  <div class="col-md-6 text-center">
                    <a href="/dashboard"  variant="link"
                        class="btn theme-btn w-100 rounded centered-link">
                      Go to Dashboard
                    </a>
                  </div>
                  <div class="col-md-6 text-center">
				            <button @click="reloadPage" type="button" class="btn theme-btn rounded w-100">
                      Back to Cyber Readiness Check
                    </button>
                  </div>
                </div>
              </v-col>
              </v-app>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!isLoggedIn" class="container my-5 text-center">
        <br />Please
        <router-link :to="{ name: 'login' }">Login </router-link>
        or <router-link :to="{ name: 'signup' }">Register </router-link> as
        organization to take the Cyber Readiness Check.<br />
        <br />
      </div>
    </section>
  </div>
</template>

<script>
import * as SurveyVue from "survey-vue";
import axios from "axios";
import { isLoggedIn } from "../../utils/authentication";
var Survey = SurveyVue.Survey;
let _this;
var questionNameTitleMap = {}; // this is used in view print assessment
var questionTotalCount = 0;
var subQuestionList = [];
export default {
  components: {
    Survey,
  },
  data() {
    return {
      survey: {},
      surveySet: false,
      isSurveyComplete: false,
      isSurveyPartial: false,
      completeButtonClicked: false,
      progress: 0,
      score: 0,
      isNIST: false,
      schema: {},
      surveyCSV: [],
      isLoading: false,
      completeText: '',
      showModal: false,
      videoURL: '',
      titleText:''
    };
  },
  props: {
    toolKey: {
      type: String,
    },
  },
  created() {
    this.getActiveAssessmentSchema(this.$route.params.toolKey);
	//console.log("Debug: " + this.$route.params.toolKey)
    _this = this;
  },
  computed: {
    isLoggedIn() {
      return isLoggedIn();
    },
  },
  mounted() {
    this.init();
    // Add a native event listener to the Vue element.
     this.$el.addEventListener("click", this.onHtmlClick)
  },
  methods: {
    init() {
      /* The footer height no longer needs to be set */
      // this.initFooterHeight();
    },
    reloadPage() {
      window.location.reload();
    },
    onHtmlClick(event){
      // Check to make sure this is from our v-html because
      // we don't want to handle clicks from other things in
      // the Vue
      if (!event.target.classList.contains("survey-video"))
        return;
      let  videoDiv = event.target.getElementsByClassName('videoLink')
      this.videoURL = videoDiv[0].innerHTML.toString().replaceAll("&amp;", "&");
      let titleTextDiv =  event.target.getElementsByClassName('titleText');
      this.titleText = titleTextDiv[0].innerHTML;
      event.stopPropagation();
      event.preventDefault();
      this.showModal = true;
    },
    geturl() {
      return "/#/cyber-readiness-check-tool/"+this.$route.params.toolKey
    },
    initFooterHeight() {
      var footerHeight = $("footer").outerHeight();
      $(".main-content").css("margin-bottom", footerHeight);
    },
    printAction() {
      var surveyAnswersDivText = document.getElementById("printDiv").outerHTML;
      var surveyAnswersWindow = window.open(
        "",
        "_blank",
        "width=800,height=800"
      );
      surveyAnswersWindow.focus();
      var doc = surveyAnswersWindow.document;
      doc.open();
      doc.write(surveyAnswersDivText);
      doc.close();
    },
    async getActiveAssessmentSchema(tool) {
      /*  API call to assessment/schema */
      axios
        .get("/api/assessment/schema/" + tool)
        .then((response) => {
          this.schema = response.data.schema;
          this.buildQuestionNameTitleMap(this.schema);
          var json = response.data.schema;
          SurveyVue.StylesManager.applyTheme("winterstone");
          SurveyVue.Serializer.addProperty("question", "tooltip");
          SurveyVue.Serializer.addProperty("question", "videoLink");
          //initialize surveyvue
          var survey = new SurveyVue.Model(json);
          //TOOLTIP for questions
          survey.onAfterRenderQuestion.add(function(survey, options) {
            //Return if there is no description to show in popup
            var header = options.htmlElement.querySelector("h5");
            var headerSpan = header.querySelector(".sv-string-viewer");
            if(options.question.tooltip != null && options.question.tooltip != '') {
              //header.title = options.question.tooltip;
              var bulb = document.createElement("i");
              bulb.className = "rightArrow";
              var anchor = document.createElement("div");
              anchor.innerText = "Tooltip";
              var span = document.createElement("span");
              span.innerText = options.question.tooltip;
              span.className = "right";
              var i = document.createElement("i");
              span.appendChild(i);
              anchor.className = "survey-tooltip";
              anchor.appendChild(span);
              var br = document.createElement("br");
              headerSpan.appendChild(br);
              headerSpan.appendChild(bulb);
              headerSpan.appendChild(anchor);
            }
            if(options.question.videoLink != null && options.question.videoLink != '') {
              var bulb = document.createElement("span");
              bulb.className = "leftLine";
              bulb.innerText = "|";
              var anchor = document.createElement("div");
              anchor.innerText = "Explainer Video";
              var span = document.createElement("span");
              //span.innerText = options.question.tooltip;
              span.className = "right";
              var i = document.createElement("i");
              span.appendChild(i);
              anchor.className = "survey-video";
              anchor.appendChild(span);
              var br = document.createElement("br");
              // headerSpan.appendChild(br);
              headerSpan.appendChild(bulb);
              headerSpan.appendChild(anchor);
              var videoLinkDiv = document.createElement("div")
              videoLinkDiv.innerText = options.question.videoLink;
              videoLinkDiv.className = "videoLink";
              var titleTextDiv = document.createElement("div")
              titleTextDiv.innerText = survey.currentPage.name + " " + options.question.name;
              titleTextDiv.className = "titleText";
              anchor.appendChild(videoLinkDiv);
              anchor.appendChild(titleTextDiv);
              videoLinkDiv.style.display='none';
              titleTextDiv.style.display='none'
              //anchor.title = options.question.survey-tooltip;
            }
          });
          var surveyValueChanged = function(sender, options) {
            var el = document.getElementById(options.name);
            if (el) {
              el.value = options.value;
            }
          };
          survey.onValueChanged.add(surveyValueChanged);
          //Adding - comment section to questions -- get all visible questions
          // var questions = survey.getAllQuestions(true);
          // for (var i = questions.length - 1; i >= 0; i--) {
          //   var q = questions[i];
          //   //Make the current question read-only. We need to make enableIf property empty and set readOnly property to true
          //   // q.enableIf = "";
          //   // q.readOnly = true;
          //   //get question index in the container
          //   var index = q.parent.elements.indexOf(q);
          //   //Insert the comment question after the question
          //   var commentQuestion = q.parent.addNewQuestion("comment", "panel-" + q.name, index + 1);
          //   //Hide the location
          //   commentQuestion.titleLocation = "hidden";
          //   //Set comment the place holder
          //   commentQuestion.placeHolder = "Please comment about \"" + q.title + "\"";
          // }
          this.loadState(survey);
          //ON Survey complete
          survey.onComplete.add(function(survey) {
            _this.isLoading = true;
            _this.completeButtonClicked = true;
            _this.saveState(survey);
          });
          //ON partial complete
          survey.onPartialSend.add(function(survey) {
            _this.saveState(survey);
          });
          survey.showCompletedPage = false;
          this.survey = survey;
          this.surveySet = true;
          return {
            survey: this.survey,
          };
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async loadState(survey) {
      //read user response from the dynamodb table and load it to local state.
      var res = {};
      axios
        .post("/api/assessment/user/response/", {
          network_uuid: this.$store.getters.getUser.network_uuid,
          assessmentKey: this.$route.params.toolKey,
        })
        .then((response) => {
          if (response.data.Count != 0) {
            res = response.data.Items[0].answers;
            survey.currentPageNo = 0;
            // if (res.progress == 100) {
            //   //completed survey, show goto page directly
            //   //setupPageSelector(survey, index);
            //   survey.currentPageNo = 0;
            // } else {
            //   //Set the loaded data into the survey.
            //   if (res.currentPageNo) {
            //     survey.currentPageNo = res.currentPageNo;
            //   }
            // }
          } else {
            res = {
              currentPageNo: 0,
            };
          }
          if (res.data && this.$route.params.toolKey === res.key) {
            survey.data = res.data;
          }
        });
    },
    async saveState(survey) {
      var unordered = survey.data;
      survey.data = Object.keys(unordered)
        .sort()
        .reduce((obj, key) => {
          obj[key] = unordered[key];
          return obj;
        }, {});
      var res = {
        currentPageNo: survey.currentPageNo,
        data: survey.data,
      };
      var yesCount = 0;
      var noCount = 0;
      var answerLaterCount = 0;
      var notApplicableCount = 0;

      $.each(survey.data, function(questionName, choice) {
        //Q1: [object Object]
        if (typeof choice == "object") {
          //$.each(choice, function (n, choiceItem) {
          $.each(choice, function(choiceQuestion, choiceAnswer) {
            // Check if a choiceQuestion exists in the subQuestionList. array.
            if (
              subQuestionList.hasOwnProperty(choiceQuestion) &&
              subQuestionList[choiceQuestion] == questionName
            ) {
              var a = choiceAnswer.answer;
              if (Number.isInteger(a)) {
                if (a > 0) {
                  yesCount++;
                } else if (a == 0) {
                  noCount++;
                } else if (a == -1) {
                  notApplicableCount++;
                } else {
                  answerLaterCount++;
                }
              } else {
                answerLaterCount++;
              }
            }
          });
        }
      });
      var length = 0;
      survey.getAllQuestions().forEach(function(question) {
        if (question.isVisible) {
          length += 1;
        }
      });
      var count = 0;
      survey.getAllQuestions().forEach(function(question) {
        if (!question.isEmpty() && question.isVisible) {
          count += 1;
        }
      });
      var SurveyAnswersObj = {};
      SurveyAnswersObj = res;
      SurveyAnswersObj.email = this.$store.getters.getUser.email;
      SurveyAnswersObj.network_uuid = this.$store.getters.getUser.network_uuid;
      SurveyAnswersObj.key = this.$route.params.toolKey;
      
      if (count == length && answerLaterCount == 0) {
        //assessment complete

        // if user is only reviewing a completed assessment, and leaves assessment, we still need to calculate score
        SurveyAnswersObj.progress = 100;
        this.progress = 100;
        axios
          .post("/api/assessment/complete/", SurveyAnswersObj)
          .then((response) => {
            if (_this.completeButtonClicked) {
              //complete button clicked, so proceed to build print and export data.
              //PRINT DIV
              var div = document.getElementById("printDiv");
              div.innerHTML = "";
              div.innerHTML +=
                "<input type='button' value='Print Answers' style='float: right' onClick='window.print()' />";
              div.innerHTML +=
                "<p> <strong>" + this.schema.title + "</strong></p> ";
              div.innerHTML +=
                "<p> <strong>Date:</strong> " +
                this.getFormattedDate(new Date()) +
                "</p>";

              //EXPORT DATA
              let data = [];
              data.push(["Assessment", this.schema.title]);
              data.push(["Date", this.getFormattedDate(new Date())]);

              if (this.$route.params.toolKey === "NEWNIST800171") {
                this.isNIST = true;
                this.score = response.data.nistScore;
                //export data
                data.push(["Score", response.data.nistScore]);
                div.innerHTML +=
                  "<p> <strong>Score:</strong>" +
                  response.data.nistScore +
                  "/110 </p>";
                this.completeText = "NIST 800-171 provides agencies with recommended security requirements for protecting the  confidentiality of CUI and applies to all components of nonfederal systems and organizations that process, store, and/or transmit CUI.";
              } else {
                if (this.$route.params.toolKey === "NEWCMMCLEVEL1") {
                  this.completeText = "CMMC Level 1 focuses on the protection of FCI and consists of only practices that correspond to the basic safeguarding requirements specified in 48 CFR 52.204-21, commonly referred to as the FAR Clause.";
                }
                else {
                  this.completeText = "CMMC Level 2 focuses on the protection of CUI and encompasses the 110 security requirements specified in NIST SP 800-171 Rev 2.";
                }
                this.score = response.data.score;
                //export data
                data.push(["Score", response.data.score + "%"]);
                div.innerHTML +=
                  "<p> <strong>Score:</strong>" +
                  response.data.score +
                  "% </p>";
              }
              //EXPORT DATA
              data.push([""]);
              data.push([""]);
              data.push([
                "Control",
                "Primary Questions",
                "Sub Questions",
                "Answers",
              ]);
              $.each(survey.data, function(primary_question, value) {
                //building print page results
                if (questionNameTitleMap[primary_question]) {
                  div.innerHTML +=
                    "<br/><hr/><br/><p style='font-weight:bold'>" +
                    primary_question +
                    ". " +
                    questionNameTitleMap[primary_question] +
                    "</p>";
                }

                $.each(value, function(sub_question, answer) {
                  //PRINT DIV
                  div.innerHTML +=
                    "<p>" + sub_question + ":"
                    + (answer["answer"] == 1
                      ? "Yes"
                      : answer["answer"] == 0
                      ? "No"
                      : "Not Applicable") + "</p>";
                  //EXPORT
                  var object = [
                    primary_question,
                    questionNameTitleMap[primary_question],
                    sub_question,
                    answer["answer"] == 1
                      ? "Yes"
                      : answer["answer"] == 0
                      ? "No"
                      : "Not Applicable",
                  ];
                  data.push(object);
                });
              });
              this.surveyCSV = data;
              this.isSurveyComplete = true;
              this.isSurveyPartial = false;
              this.isLoading = false;
              // var div = document.getElementById("surveyComplete");
              // div.innerHTML = "";
              // div.innerHTML += "<p>Your cyber readiness check is complete!</p>";
              // div.innerHTML +=
              //   "<p> <strong>Date:</strong> " + moment().format("LL") + "</p>";
              // if (this.$route.params.toolKey === "NEWNIST800171") {
              //   div.innerHTML +=
              //     "<p> <strong>Score:</strong>" +
              //     response.data.nistScore +
              //     "/110" +
              //     "</p>";
              // } else {
              //   div.innerHTML +=
              //     "<p> <strong>Score:</strong>" +
              //     response.data.score +
              //     "%" +
              //     "</p>";
              // }
            }
          });
      } else {
        //assessment not complete - save partial information
        SurveyAnswersObj.progress = Math.floor(
          ((yesCount + noCount + notApplicableCount) / questionTotalCount) * 100
        );
        this.progress = SurveyAnswersObj.progress;
        axios
          .post("/api/assessment/partial/", SurveyAnswersObj)
          .then((response) => {
            this.isLoading = false;
            this.isSurveyPartial = true;
            this.isSurveyComplete = false;
          });
      }
    },
    buildQuestionNameTitleMap(schema) {
      questionTotalCount = 0;
      for (var i = 0; i < schema.pages.length; i++) {
        //console.log(schema.pages[i].title);
        var elements = schema.pages[i].elements;
        for (var j = 0; j < elements.length; j++) {
          questionNameTitleMap[elements[j].name] = elements[j].title; // setting the map
          questionTotalCount = questionTotalCount + elements[j].rows.length; // we calculate questionCount , so we can track progress % when user saves a uncompleted assessment
          // we have to track individual sub questions, so we can load the correct user response data  from DB. Incase the schema questions changed, we have to lose all unwanted response data saved.
          elements[j].rows.forEach(function(row) {
            //console.log(row);
            subQuestionList[row] = elements[j].name;
          });
        }
        if (i == schema.pages.length - 1) {
          //console.log("calculated questionTotalCount", questionTotalCount);
          return;
        }
      }
    },
    getFormattedDate(date) {
      var year = date.getFullYear();
      var month = (1 + date.getMonth()).toString();
      month = month.length > 1 ? month : "0" + month;
      var day = date.getDate().toString();
      day = day.length > 1 ? day : "0" + day;
      return month + "/" + day + "/" + year;
    },
  },
};
</script>
