import store from '../store';
import axios from 'axios';
import jwt_decode from 'jwt-decode';

var refreshIntervalID = null;

export function isLoggedIn() {
  return !!store.getters.getAccessToken;
}

export function handleApiError(error, router) {
  /** If given and API error, check to see if the token expired or is invalid. */
  const errorObject = error.response ? error.response : error;
  if (errorObject.status == 401) {
    /** Authorization error - Clear Tokens and send to Login screen */
    clearAuthTokens();
    if (router) {
      console.warn('Redirecting to Login Page');
      router.push({ path: 'login' });
    }
  }
  /** Print Error to console for debugging */
  console.error('API Error', errorObject);
}

/**
 * Stores the header tokens and MFA status from the login or session refresh
 * responce data, into the vuex store
 * @param {object} auth Data returned from login or refresh from user API
 */
export function storeAuthTokens(auth) {
  console.log("Storing Authentication Tokens in VueX Store")
  store.commit('setAccessToken', auth.accessToken);
  store.commit('setIdToken', auth.idToken);
  store.commit('setMFA', auth.MFA);
  store.commit('setRefreshToken', auth.refreshToken);
  store.commit('setLoggedIn', true)
  setHeaderTokensFromStore();
  enableAutoRefresh();
}

/**
 * Sets an pdate interval to refresh the user's session.
 * Checks if auto-refresh is already enabled and if the user is lLogged-in
 */
export function checkIfSessionStillActive() {
  if (isLoggedIn()) {
    axios
      .get(`/api/user/`)
      .then((response) => {
        /** Set User in Store */
        setUser(response.data);
        console.log('User Session still active');
      })
      .catch((error) => {
        console.log('User Session no longer active');
        handleApiError(error, null);
      });
  }
}

/**
 * Sets an pdate interval to refresh the user's session.
 * Checks if auto-refresh is already enabled and if the user is lLogged-in
 */
export function enableAutoRefresh() {
  if (!refreshIntervalID && isLoggedIn()) {
    const refreshInterval = 1000 * 60 * 50;
    refreshIntervalID = setInterval(refreshSession, refreshInterval);
    console.log(
      `Enabling Auto-Refresh of session (${refreshInterval / 1000 / 60} min)`
    );
  }
}

/**
 * Clears the Session refresh interval
 */
function disableAutoRefresh() {
  if (refreshIntervalID) {
    console.log(`Disabling Auto-Refresh of session`);
    clearInterval(refreshIntervalID);
    refreshIntervalID = null;
  }
}

/**
 * Calls the API to refresh the User's session and store the new header tokens
 */
function refreshSession() {
  axios
    .post('/api/user/session/refresh', {
      email: getUserEmail(),
    })
    .then((response) => {
      console.log('Auto-Refreshed Session', response);
      storeAuthTokens(response.data);
    })
    .catch((error) => {
      //handleApiError(error.response);
      handleApiError(error, null);
    });
}

export function clearAuthTokens() {
  console.warn('Clearing Auth Tokens');
  store.commit('setAccessToken', null);
  store.commit('setIdToken', null);
  store.commit('setMFA', null);
  store.commit('setRefreshToken', null);
  store.commit('setLoggedIn', false)
  clearHeaderTokens();
  disableAutoRefresh();
}

function getDecodedIdToken() {
  var token = store.getters.getIdToken;
  var decoded = null
  if (token != null){
  console.log('JWT', jwt_decode(token));
  decoded = jwt_decode(token);
  }
  return decoded;
}

export function isPhoneNumberVerified() {
  return getDecodedIdToken().phone_number_verified;
}

export function getUserEmail() {
  return getDecodedIdToken().email;
}

function getUserRoles() {
  var userRoles = [];
  if(getDecodedIdToken() != null){
    userRoles = getDecodedIdToken()['cognito:groups'] || [];
  }
  else 
  {
	userRoles = []
  }
  console.log('User Roles', userRoles);
  return userRoles;
}

export function isOrganizationMember() {
  return getUserRoles().includes('OrganizationMember');
}

export function isOrganizationAdmin() {
  return getUserRoles().includes('OrganizationAdmin');
}


export function isInOrganization() {
  return isOrganizationAdmin() || isOrganizationMember();
}

export function isEnabledMFA() {
  return store.getters.getMFA;
}

export function setHeaderTokensFromStore() {
  const accessToken = store.getters.getAccessToken;
  const idToken = store.getters.getIdToken;
  const refreshToken = store.getters.getRefreshToken;
  if (idToken) setHeaderToken('x-id-token', idToken);
  if (accessToken) setHeaderToken('x-access-token', accessToken);
  if (refreshToken) setHeaderToken('x-refresh-token', refreshToken);
}

export function clearHeaderTokens() {
  clearHeaderToken('x-id-token');
  clearHeaderToken('x-access-token');
  clearHeaderToken('x-refresh-token');
}

export function clearHeaderToken(field) {
  delete axios.defaults.headers.common[field];
}

export function setHeaderToken(field, token) {
  // console.log("Header Token", field, token)
  axios.defaults.headers.common[field] = token;
}

export function setUser(user) {
  /** Store User Object */
  store.commit('setUser', user);
  console.log('Set User', user);
  /** Store Account Required Fields */
  setAreAccountRequiredFieldsFilled(areAccountRequiredFieldsFilled(user));
  console.log(
    'All User Required Fields Set',
    getAreAccountRequiredFieldsFilled()
  );
  if(user.migratedAdmin){
    store.commit('setMigratedAdmin', user.migratedAdmin);
  }
}

function areAccountRequiredFieldsFilled(user) {
  /** The member option param indicates that the user must  */
  if (user.memberOption) {
    return false;
  }
  /** Depending on the  */
  if (user.user_group === 'OrganizationAdmin') {
    return (
      !!user.name &&
      !!user.email &&
      !!user.forumScreenName &&
      !!user.organization &&
      !!user.organizationType &&
      !!user.organizationType.length &&
      !!user.address1 &&
      !!user.city &&
      !!user.country &&
      !!user.state &&
      !!user.zipcode
    );
  } else if (user.user_group === 'Individual') {
    return (
      !!user.name &&
      !!user.email &&
      !!user.forumScreenName &&
      !!user.address1 &&
      !!user.city &&
      !!user.country &&
      !!user.state &&
      !!user.zipcode
    );
  } else {
    return !!user.name && !!user.email && !!user.forumScreenName;
  }
}

export function setAreAccountRequiredFieldsFilled(requiredFieldsSet) {
  store.commit('setAreAccountRequiredFieldsFilled', requiredFieldsSet);
}

export function getAreAccountRequiredFieldsFilled() {
  return store.getters.getAreAccountRequiredFieldsFilled;
}
export function getMigratedAdmin() {
  return store.getters.getMigratedAdmin;
}
export function setMigratedAdmin() {
  store.commit('setMigratedAdmin', false);
}
export function clearUser() {
  store.commit('setUser', null);
}
