<template>
  <div class="main-content">
    <!-- BradCrumb header Start-->
    <section class="inner-header-bg">
      <div class="container">
        <!-- Breadcrumb Start-->
        <div class="section-breadcrumb">
          <div class="container">
            <div class="row">
              <div
                class="col-md-12 justify-content-center align-self-center pb-2"
              >
                <div class="breadcrumb-content">
                  <div class="title-section">
                    <h1 class="title text-center text-white">Events</h1>
                  </div>
                  <div class="breadcrumb-nav">
                    <ul class="breadcrumb-link text-center list-unstyled mb-0">
                      <li class="text-white">
                        <i class="mdi mdi-home theme-light-color pr-2"></i>
                        <a href="/#/">Home</a>
                      </li>
                      <li class="theme-light-color active">Events</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Breadcrumb End -->
      </div>
    </section>
    <!-- BradCrumb header End -->
    <!-- Cyber Circuits Grid Section Start -->
    <section class="section">
      <div class="container">
        <div class="text-center events-caption-area">
          <img alt="group" class="img-fluid" src="../../assets/images/events/eventsgroupphoto.jpg" />
          <p>Image captured on 29 March 2023 at DoD Mentor Protégé Summit 2023 (March 27 – 30 in Orlando, FL)</p>
          <div class="bottom-color-separator"></div>
          <div class="caption-lines">
            <p>Khalil Mack (center, Director of APEX Accelerators) with his panelists.</p>
            <p>From left: Lonye Ford (CEO, Arlo Solutions), Arlene Wube (President & COO, Arlo Solutions), </p>
            <p>Katherine Thompson (Division Chief, U.S. Army Contracting Command), Darryl Thomas (Program </p>
            <p>Manager, Illinois APEX Accelerator), and Don Zavesky (Program Manager, Florida APEX Accelerator). </p>
          </div>
        </div>
        <p>
          Our team's primary goal is to help educate our partners on all events related to the APEX Accelerators.
        </p>
        <div class="pb-4">
          <b-form-input
            v-model="searchQuery"
            placeholder="Search Events..."
          ></b-form-input>
        </div>
        <!--<h3 class="pb-2">Upcoming Webinars & Cyber Circuits</h3>-->
        <p class="pb-2" v-if="displayedItemsUpcoming.length === 0 && !searchQuery">
          Check back soon for upcoming events...
        </p>
        <p class="pb-2" v-if="displayedItemsUpcoming.length === 0 && searchQuery">
          No items match the search criteria...
        </p>
        <div class="blog-post-grid">
          <div class="row">
            <!-- Start Event Items -->
            <div
              class="col-md-6 col-lg-4 mb-5"
              v-for="item in displayedItemsUpcoming"
              :key="item.id"
            >
              <!-- Event Card -->
              <div class="blog-item border h-100" v-if="item.type === 'events'">
                <div v-if="'imageURL' in item">
                  <router-link class="blog-post-image" :to="{ name: 'event-detail', query: { id: item.id } }">
                    <img v-if="'imageURL' in item" :src="item.imageURL" class="cover" />
                    <img v-else src="../../assets/images/services/webinar.jpeg" class="cover" />
                  </router-link>
				        </div>
                <div class="blog-post-info">
                  <router-link class="text-dark" :to="{ name: 'event-detail', query: { id: item.id } }">
                    <h4 class="blog-post-title">{{ item.eventTitle }}</h4>
                    <b-badge
                      v-if="item.registrationRequired"
                      class="mb-3 badge-required"
                      >Registration Required</b-badge
                    >
                    <b-badge
                      v-if="!item.registrationRequired"
                      class="mb-3 badge-not-required"
                      >Learn More</b-badge
                    >
                    <div class="post-event-bottom d-flex align-items-center">
                      <p class="blog-info-meta text-dark">{{
                        getFormattedDate(item.startAt)
                      }}</p>
                      <p class="blog-category text-dark">Event</p>
                    </div>
                  </router-link>
                  <router-link
                    class="blog-read-more"
                    :to="{ name: 'event-detail', query: { id: item.id } }"
                  >
                    <i class="mdi mdi-arrow-right"></i>
                  </router-link>
                </div>
              </div>
              <!-- End Event Card -->
            </div>
            <!-- End Event Items -->
          </div>
        </div>


		<!-- Past Events -->
        <h3 class="pb-2">Past Events</h3>
        <p class="pb-2" v-if="displayedItemsPastEvents.length === 0">
          No items match the search criteria...
        </p>
        <b-table
          id="table-webinar-events"
          :fields="events.fields"
          :items="displayedItemsPastEvents"
          :busy="isLoading"
          responsive
          borderless
          no-border-collapse
          small
          sortable="true"
          class="mt-3 events-table"
          hover
          :tdClass="['events-cell']"
        >
          <!-- Busy Spinner -->
          <template #table-busy>
            <div class="text-center text-secondary my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong class="pl-2">Loading...</strong>
            </div>
          </template>
          <!-- Main Column with Image -->
          <template #cell(eventTitle)="data">
            <div class="image-and-tool d-flex">
			        <div class="image-container">
                <b-img
                  :src="data.item.imageURL"
                  class="tool-icon"
                  rounded
                  alt="Rounded image"
                  v-if="'imageURL' in data.item"
                ></b-img>
                <b-img
                  :src="require('../../assets/images/services/webinar.jpeg')"
                  class="tool-icon"
                  rounded
                  alt="Rounded image"
                  v-else
                ></b-img>
              </div>
              <p class="clipped-text m-0 font-weight-bold">
                {{ data.item.eventTitle }}
              </p>
            </div>
          </template>
        </b-table>
        <div>
          <b-pagination
            id="blog-pagination"
            v-model="events.past.currentPage"
            :total-rows="totalRowsPastEvents"
            :per-page="events.past.perPage"
            align="center"
            @page-click="pageClicked"
          ></b-pagination>
        </div>
      </div>
    </section>
    <!-- Cyber Circuits Grid Section End -->
  </div>
</template>
<script>
import axios from 'axios';
import { BPagination, BFormInput } from 'bootstrap-vue';
export default {
  name: 'cyber-circuits',
  components: {
    BPagination,
    BFormInput,
  },
  props: {
    query: String,
  },
  data() {
    return {
      searchQuery: this.query,
      isLoading: false,
      combined: {
        upcoming: {
          items: [],
          itemCount: 0,
          currentPage: 1,
          perPage: 5,
        },
      },
      events: {
        fields: [
          {
            key: 'eventTitle',
            label: 'Title',
            tdClass: 'align-middle',
            sortable: true,
          },
          {
            key: 'startAt',
            label: 'Start',
            tdClass: 'align-middle',
            sortable: true,
          },
          {
            key: 'endAt',
            label: 'End',
            tdClass: 'align-middle',
            sortable: false,
          }
        ],
        upcoming: {
          items: [],
          itemCount: 0,
        },
        past: {
          items: [],
          itemCount: 0,
          currentPage: 1,
          perPage: 5,
        },
      }
    };
  },
  computed: {
    itemsFromSearchQueryPastEvents() {
      if (this.searchQuery) {
        return this.events.past.items.filter((item) => {
          return this.searchQuery
            .toLowerCase()
            .split(' ')
            .every(
              (v) =>
                item.eventTitle?.toLowerCase().includes(v) ||
                item.description?.toLowerCase().includes(v)
            );
        });
      } else {
        return this.events.past.items;
      }
    },
    itemsFromSearchQueryUpcoming() {
      if (this.searchQuery) {
        return this.combined.upcoming.items.filter((item) => {
          return this.searchQuery
            .toLowerCase()
            .split(' ')
            .every(
              (v) =>
                item.title?.toLowerCase().includes(v) ||
                item.headLine?.toLowerCase().includes(v) ||
                item.eventTitle?.toLowerCase().includes(v) ||
                item.description?.toLowerCase().includes(v)
            );
        });
      } else {
        return this.combined.upcoming.items;
      }
    },
    displayedItemsPastEvents() {
      return this.itemsFromSearchQueryPastEvents.slice(
        (this.events.past.currentPage - 1) * this.events.past.perPage,
        this.events.past.currentPage * this.events.past.perPage
      );
    },
    displayedItemsUpcoming() {
      return this.itemsFromSearchQueryUpcoming.slice(
        (this.combined.upcoming.currentPage - 1) *
          this.combined.upcoming.perPage,
        this.combined.upcoming.currentPage * this.combined.upcoming.perPage
      );
    },
    totalRowsPastEvents() {
      return this.itemsFromSearchQueryPastEvents.length;
    },
    totalRowsUpcoming() {
      return this.itemsFromSearchQueryUpcoming.length;
    },
  },
  mounted: async function () {
    await this.getEvents(); // async
    this.getCombinedUpcomingEvents();
  },
  methods: {
    getCombinedUpcomingEvents() {
      /* Add locale date string to past CC items and sortable date field to both */
      this.events.past.items.forEach(function (element) {
        element.startAt = element.startAt.replace(' 12:00 AM', '');
        element.endAt = element.endAt.replace(' 12:00 AM', '');
        element.date = new Date(element.date).toLocaleDateString('en-US', {timeZone: 'UTC'});
        element.sortBy = new Date(element.startAt);
      });
      /* Label upcoming Event items */
      this.events.upcoming.items.forEach(function (element) {
        element.type = 'events';
        element.sortBy = new Date(element.startAt);
      });
      /* Concatenate upcoming CC and Event items into one list */
      this.combined.upcoming.items = this.events.upcoming.items;
      this.events.past.items = this.events.past.items.sort((a, b) =>
        a.sortBy < b.sortBy ? 1 : -1
      );
      this.combined.upcoming.items = this.combined.upcoming.items.sort((a, b) =>
        a.sortBy > b.sortBy ? 1 : -1
      );
    },
    async getEvents() {
      await axios
        .get('/api/resources/events')
        .then(async (response) => {
          console.log('Events', response.data.Items);
          this.events.upcoming.items = response.data.Items.filter(
            (item) => new Date(item.endAt) > Date.now()
          );
          this.events.upcoming.itemCount = this.events.upcoming.items.length;
          this.events.past.items = response.data.Items.filter(
            (item) => new Date(item.endAt) <= Date.now()
          );
          this.events.past.itemCount = this.events.past.items.length;
        })
        .catch(async (error) => {
          console.error(error);
        });
    },
    pageClicked() {
      // window.scrollTo({ top: 0, behavior: 'smooth' });
    },
  },
};
</script>
<style>
.inner-header-bg {
  background-image: url('~@/assets/images/headers/events.png');
  background-position: center center;
}
#blog-pagination li {
  margin: 0px;
}

.cover {
  object-fit: cover;
  height: 225px;
  width: 100%;
}

.tool-icon {
  object-fit: cover;
  height: 50px;
  width: 80px;
  margin-right: 10px;
}

.image-and-tool {
  max-width: 325px;
  align-items: center;
}

.btn-more {
  width: 100px;
  height: auto;
  padding: 0.25rem 0.5rem;
}

.events-caption-area > img:first-of-type {
  margin-bottom: 20px;
}

.events-caption-area > p:first-of-type {
  font-size: 14px;
  font-weight: 600;
  color: #000000;
  margin: 0;
  margin-bottom: 15px;
}

.bottom-color-separator {
  display: inline-block;
  height: 4px;
  width: 125px;
  background-color: #f9a808;
  margin-bottom: 15px;
}

.caption-lines {
  margin-bottom: 40px;
}
.caption-lines p {
 margin: 0;
}

</style>
