<template>
  <div>
    <!-- Start Footer Area -->
    <footer class="text-white footer">
      <div class="container">
        <a class="footer-logo" href="#top" title="">
          <img
            src="../assets/images/logo/apex_logo_dark.png"
            alt=""
            class="img-fluid"
          />
        </a>
        <!-- <ul class="social-links list-unstyled d-flex justify-content-center">
          <li class="youtube">
            <a
              href="https://www.youtube.com/channel/UCHU-4dUkYInJhpabvW6Ps1g"
              title="Youtube"
              target="_blank"
            >
              <i class="mdi mdi-youtube font-20 text-white"></i>
            </a>
          </li> -->
          <!-- <li class="twitter">
            <a
              href="https://twitter.com/Proj_Spectrum"
              title="Twitter"
              target="_blank"
            >
              <i class="mdi mdi-twitter font-20 text-white"></i>
            </a>
          </li> -->
          <!-- <li class="linkedin">
            <a
              href="https://www.linkedin.com/showcase/project-spectrum/"
              title="Linkedin"
              target="_blank"
            >
              <i class="mdi mdi-linkedin font-20 text-white"></i>
            </a>
          </li>
        </ul> -->
        <nav>
          <div class="menu-footer-menu-container mt-4">
            <ul class="menu list-unstyled">
              <li class="menu-item">
                <router-link to="/">Home</router-link>
              </li>
			  <li class="menu-item">
           		 <router-link to="/leadership">Leadership</router-link>
          	  </li>
              <!-- <li class="menu-item">
                <router-link to="">About</router-link>
              </li>
              <li class="menu-item">
                <router-link to="">Centers</router-link>
              </li> -->
               <li class="menu-item">
                <router-link to="/feedback">Feedback</router-link>
              </li>
			  <li class="menu-item">
                <router-link to="/contact">Contact Us</router-link>
              </li>
              <li class="menu-item">
                <a v-b-modal.modal-privacy>
                  <span class="line">Privacy</span>
                </a>
              </li>
            </ul>
          </div>
        </nav>
        <div class="copyright mt-4">
          <p>&copy; 2024 APEX Accelerators</p>
        </div>
      </div>
    </footer>
    <!-- End Footer Area -->
    <!-- Disclaimer Modal -->
    <!-- Privacy Modal -->
    <b-modal
      id="modal-privacy"
      title="Privacy Policy"
      size="xl"
      ok-only    
      >
      <div>
       
          <p>This privacy notice discloses the privacy practices for the
          APEX Accelerators website and applies to information collected on the APEX 
		  website including, without limitation, registration and login
          information, and information collected by the APEX internal
          applications. It will notify you of the following:</p>

          <p>1. The personally identifiable information that is collected
          from you through the website, how it is used and with whom it may be shared.</p>

          <p>2. The choices that are available to you regarding the use
          of your data.</p>

          <p>3. The security procedures that are in place to protect the
          misuse of your information.</p>

          <p>4. The method you can employ to correct any inaccuracies in your
          information. </p>

          <p><b>Information Collection </b></p>

          <p>We are the sole owners of the information collected on this
          site. We only collect and have access to information that you voluntarily give
          us by filling out the online forms, via email, or other direct contact from
          you. </p>

          <p><b>Information Use and Disclosure </b></p>

          <p>Information that you voluntarily give us may be used by us,
          or disclosed to third parties, such as a U.S. federal government agency. In
          general, we will deidentify data disclosed to third parties by removing
          information such as your business name and contact details. </p>

          <p><b>Your Access to and Control Over Information </b></p>

          <p>Unless you ask us not to, we may contact you via email or
          phone in the future with updates on the APEX Accelerators program, to inform you
          about new products, services, or changes to this privacy policy. </p>

          <p>You may opt out of any future contacts from us at any time.
          You can do so at any time by contacting us via the <a
          href="mailto:info@apexaccelerators.us">info@apexaccelerators.us</a> email
          address provided on our website to: </p>

          <p>1. Inquire about any personal data we have collected or
          stored. </p>

          <p>2. Change/correct any personal data we have collected or
          stored.</p>

          <p>3. Delete any personal data we have collected or stored. </p>

          <p>4. Express any concern you have about our use of your
          personal data.</p>

          <p><b>Security</b> </p>

          <p><span>We take precautions to protect your
          information. When you submit sensitive information via the website, your
          information is protected in transit, in use, and at rest (stored).</span></p>

          <p><span>Wherever information is collected through the
          APEX Accelerators website, such information is encrypted and transmitted using
          the latest cryptographic algorithms for data transport. </span></p>

          <p><span>While we use encryption to protect sensitive
          information transmitted online, we also protect it while it is being used and
          at rest (i.e. being stored). </span></p>

          <p><span>Only employees who need the information to
          perform a specific job are granted access to any personally identifiable
          information. The computers/servers in which we store personally identifiable
          information are kept in a secure environment. </span></p>

          <p><span>We reserve
          the right to revise this Privacy Policy at any time and for any reason. Any
          revisions will be effective immediately upon posting the revised Privacy Policy
          on the APEX Accelerators website and you hereby waive any right to receive
          specific notice of any such revision.</span></p>
      </div>
    </b-modal>
    <!-- Back To Top -->
    <a href="#" class="back_top"> <i class="mdi mdi-chevron-up"></i></a>
  </div>
</template>
<script>
export default {
  name: 'template_footer',
  methods: {},
};
</script>
