<template>
  <div class="main-content">
    <!-- BradCrumb header Start-->
    <section class="inner-header-bg">
      <div class="container">
        <!-- Breadcrumb Start-->
        <div class="section-breadcrumb">
          <div class="container">
            <div class="row">
              <div
                class="col-md-12 justify-content-center align-self-center pb-2"
              >
                <div class="breadcrumb-content">
                  <div class="title-section">
                    <h1 class="title text-center text-white">Event Details</h1>
                  </div>
                  <div class="breadcrumb-nav">
                    <ul class="breadcrumb-link text-center list-unstyled mb-0">
                      <li class="text-white">
                        <i class="mdi mdi-home theme-light-color pr-2"></i>
                        <a href="/#/">Home</a>
                      </li>
                      <li class="text-white">
                        <router-link :to="{ name: 'events' }">
                          <a href="#">Events</a>
                        </router-link>
                      </li>
                      <li class="theme-light-color active">Event Details</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Breadcrumb End -->
      </div>
    </section>
    <!-- BradCrumb header End -->
    <!-- Blog Details Start-->
    <section class="section section-blog">
      <div class="container">
        <div class="row justify-content-md-center">
          <!-- TODO: Hiding catagories until implemented-->
          <div
            class="col-lg-4 order-lg-2 wow fadeIn d-none"
            data-wow-delay="0.1s"
            data-wow-duration="1.5s"
          >
            <aside class="sidebar sidebar-padding border">
              <h6 class="post-news-title search-title">Search</h6>
              <div class="input-group">
                <b-form-input
                  v-model="query"
                  placeholder="Search..."
                ></b-form-input>
                <router-link
                  class="btn btn-search p-0"
                  :to="{ name: 'events', query: { query: query } }"
                >
                  <span class="mdi mdi-magnify"></span>
                </router-link>
              </div>
              <div class="pb-2 mt-5 mb-3">
                <ul class="blog-news list-unstyled">
                  <li class="blog-item">
                    <h6 class="post-news-title">Categories</h6>
                  </li>
                </ul>

                <div class="blog-news-content">
                  <div class="blog-post-list">
                    <ul class="list-unstyled">
                      <li
                        v-for="(item, index) in categories"
                        v-bind:key="index"
                      >
                        <div class="blog-news-info">
                          <router-link
                            :to="{
                              name: 'events',
                              query: { query: item.name },
                            }"
                          >
                            <a href="#" class="text-dark">
                              {{ item.name }}
                              <span class="float-right">{{
                                item.articles
                              }}</span>
                            </a>
                          </router-link>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="pb-2 mb-3">
                <ul class="blog-news list-unstyled">
                  <li class="blog-item">
                    <h6 class="post-news-title">Archives</h6>
                  </li>
                </ul>

                <div class="blog-news-content">
                  <div class="blog-post-list">
                    <ul class="list-unstyled">
                      <li v-for="(item, index) in archives" v-bind:key="index">
                        <div class="blog-news-info">
                          <a href="#" class="text-dark"
                            >{{ item.name }}
                            <span class="float-right">{{ item.articles }}</span>
                          </a>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="tags-post">
                <ul class="blog-news list-unstyled">
                  <li class="blog-item">
                    <h6 class="post-news-title">Tags</h6>
                  </li>
                </ul>

                <div class="blog-news-content">
                  <div class="blog-post-list">
                    <ul class="list-unstyled list-inline mb-0">
                      <li
                        class="list-inline-item"
                        v-for="(item, index) in tags"
                        v-bind:key="index"
                      >
                        <router-link
                          class="blog-news-info text-dark"
                          :to="{
                            name: 'events',
                            query: { query: item.name },
                          }"
                        >
                          {{ item.name }}
                        </router-link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </aside>
          </div>
          <!-- TODO: Making full width until catagories implemented-->
          <div
            class="col-lg-8 wow fadeIn"
            data-wow-delay="0.1s"
            data-wow-duration="1.5s"
          >
            <article class="blog-post-left border">
              <div class="row">
                <div class="col-lg-12">
                  <div class="post-image">
                    <a href="#">
                      <img
                        :src="item?.bannerImage"
                        :alt="item?.photoDescription"
                        class="img-fluid img-thumbnail border-0"
                      />
                    </a>
                  </div>
                  <div class="blog-post-content text-left mt-3">
                    <h4 class="blog-post-title">
                      <a href="#" class="text-left text-dark">
                        {{ item?.eventTitle }}
                      </a>
                    </h4>
                    <div
                      v-if="item?.author"
                      class="post-event d-flex align-items-center mt-3"
                    >
                      <a href="#" class="blog-info-meta">{{ item?.author }}</a>
                    </div>
                    <!-- <div class="post-event d-flex align-items-center my-3">
                      <p class="blog-info-meta">{{
                        formatterStartAndEndDate(item?.startAt, item?.endAt)
                      }}</p>
                      <p class="blog-category" >Event</p>
                      <SocialShareBookmark
                        :title="title"
                        :description="description"
                        type="news"
                      />
                    </div> -->
                  </div>
                  <!-- Logged in, registration required, but NOT registered -->
                  <b-alert
                    show
                    v-if="
                      isLoggedIn && item?.registrationRequired && !isRegistered  && (new Date(item?.startAt) > Date.now())
                    "
                    variant="warning"
                  >
                    You are not registered for this event.
                  </b-alert>
                  <!-- Logged in, registration required, already registered -->
                  <b-alert
                    show
                    v-if="
                      isLoggedIn && item?.registrationRequired && isRegistered
                    "
                    variant="success"
                  >
                    You are registered for this event!.
                  </b-alert>
                  <!-- Logged in, registration not required-->
                  <!-- Not Logged In -->
                  <!-- <b-alert show variant="info" v-if="!isLoggedIn">
                    <router-link
                      :to="{
                        name: 'login',
                        query: { redirect: getRedirectRoute },
                      }"
                    >
                      <a>Login</a>
                    </router-link>
                    or
                    <router-link :to="{ name: 'signup' }">
                      <a>Create Account</a>
                    </router-link>
                    to register for this event.
                  </b-alert> -->
                  <div class="post-content">
                    <span v-html="item?.description"></span>
                  </div>
                  <!-- If Logged In -->
                  <div
                    v-if="isLoggedIn && item?.registrationRequired"
                    class="row"
                  >
                    <div class="col-sm mb-1">
                      <b-button
                        v-if="!isRegistered && (new Date(item?.startAt) > Date.now())"
                        variant="success"
                        class="btn w-100 rounded centered-link"
                        @click="registerForEvent"
                        :disabled="waitingForRegisterResponce"
                      >
                        <b-spinner
                          v-if="waitingForRegisterResponce"
                          small
                        ></b-spinner>
                        <span v-else>Register for Event</span>
                      </b-button>
                      <b-button
                        v-if="isRegistered && (new Date(item?.startAt) > Date.now())"
                        variant="danger"
                        class="btn w-100 rounded centered-link"
                        :disabled="waitingForRegisterResponce"
                        @click="unregisterForEvent"
                      >
                        <b-spinner
                          v-if="waitingForRegisterResponce"
                          small
                        ></b-spinner>
                        <span v-else>Unregister for Event</span>
                      </b-button>
                    </div>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
    <!-- Blog Details Section End-->
  </div>
</template>
<script>
import { BFormInput } from 'bootstrap-vue';
import axios from 'axios';
import { handleApiError, isLoggedIn } from '../../utils/authentication';
import SocialShareBookmark from '../social-share-bookmark.vue';

export default {
  name: 'events-detail',
  components: {
    BFormInput,
    SocialShareBookmark,
  },
  props: {
    id: String,
  },
  data() {
    return {
      isRegistered: false,
      registrationStatus: {},
      waitingForRegisterResponce: false,
      query: '',
      item: {},
      categories: [
        { name: 'Cyber Security', articles: 4 },
        { name: 'Software', articles: 5 },
        { name: 'Manufacturing', articles: 6 },
        { name: 'Learning Tools', articles: 7 },
      ],
      archives: [
        { name: 'February 2020', articles: 4 },
        { name: 'January 2020', articles: 5 },
        { name: 'June 2020', articles: 6 },
        { name: 'May 2020', articles: 7 },
      ],
      tags: [
        { name: 'Development' },
        { name: 'Security' },
        { name: 'Infrastructure' },
        { name: 'Androids' },
        { name: 'Phishing' },
        { name: 'Coronavirus' },
      ],
      linkCopyText: 'Click to copy url link',
    };
  },
  async created() {
    if (this.id) {
      this.item = await this.getResource(this.id);
    } else {
      this.$router.push({ path: 'events' });
    }
  },
  mounted() {
    this.init();
  },
  computed: {
    isLoggedIn() {
      return isLoggedIn();
    },
    getRedirectRoute() {
      return `/event-detail?id=${this.$router.currentRoute.query.id}`;
    },
    title() {
      return this.item?.eventTitle || 'Title';
    },
    description() {
      return this.item?.description || 'HeadLine';
    },
  },
  methods: {
    init() {
      /* The footer height no longer needs to be set */
      // this.initFooterHeight();
    },
    initFooterHeight() {
      var footerHeight = $('footer').outerHeight();
      $('.main-content').css('margin-bottom', footerHeight);
    },
    async getResource(id) {
      /*  API call to server: GET /api/resources/blogs */
      axios
        .get(`/api/resources/events/${id}`)
        .then((response) => {
          if (response.data.Items.length > 0) {
            /** Does an item exist for the query id */
            this.item = response.data.Items[0];
            this.item.bannerImage = this.item.imageURL || require('../../assets/images/services/webinar.jpeg');
            //console.log('Event', this.item);
            if (this.item.registrationRequired && isLoggedIn()) {
              this.getRegistrationStatus(id);
            }
          } else {
            /** If no item exists with the id, redirect to list*/
            this.$router.push({ path: 'events' });
          }
        })
        .catch((error) => {
          /** On error, redirect back to list */
          console.error(error);
          this.$router.push({ path: 'events' });
        });
    },
    async getRegistrationStatus(id) {
      this.waitingForRegisterResponce = true;
      /*  API call to server: GET /api/resources/blogs */
      axios
        .get(`/api/resources/event/status?calendarId=${id}`)
        .then((response) => {
          //console.log('Registration Status', response.data);
          if (response.data.registrantKey && response.data.webinarKey) {
            this.isRegistered = true;
            this.registrationStatus = response.data;
          } else {
            this.isRegistered = false;
          }
        })
        .catch((error) => {
          /** On error, redirect back to list */
          handleApiError(error, null);
        })
        .finally(() => {
          this.waitingForRegisterResponce = false;
        });
    },
    async registerForEvent() {
      this.waitingForRegisterResponce = true;
      axios
        .post(`/api/gotowebinar/register`, {
          eventCalenderId: this.id,
          eventTitle: this.item.eventTitle,
          registrationRequired: this.item.registrationRequired,
          registrationStatus: true,
          webinarKey: this.item.webinarKey,
        })
        .then((response) => {
          //console.log('Register Response', response.data);
          this.getRegistrationStatus(this.id);
        })
        .catch((error) => {
          /** On error, redirect back to list */
          handleApiError(error, null);
        })
        .finally(() => {
          this.waitingForRegisterResponce = false;
        });
    },
    async unregisterForEvent() {
      this.waitingForRegisterResponce = true;
      axios
        .post(`/api/gotowebinar/deRegister`, {
          id: this.registrationStatus.id,
          registrantKey: this.registrationStatus.registrantKey,
          webinarKey: this.registrationStatus.webinarKey,
        })
        .then((response) => {
          //console.log('Un-Register Response', response.data);
          this.getRegistrationStatus(this.id);
        })
        .catch((error) => {
          /** On error, redirect back to list */
          handleApiError(error, null);
        })
        .finally(() => {
          this.waitingForRegisterResponce = false;
        });
    },
    datesAreOnSameDay(first, second) {
      return (
        first.getFullYear() === second.getFullYear() &&
        first.getMonth() === second.getMonth() &&
        first.getDate() === second.getDate()
      );
    },
    formatterStartAndEndDate(start, end) {
      // Are they the same day
      const options = { year: 'numeric', month: 'short', day: 'numeric' };
      const timeOptions = { hour: 'numeric', minute: '2-digit' };
      var startDate = new Date(start);
      var endDate = new Date(end);
      if (this.datesAreOnSameDay(startDate, endDate)) {
        return `${startDate.toLocaleDateString(
          undefined,
          options
        )} ${startDate.toLocaleTimeString(
          undefined,
          timeOptions
        )} - ${endDate.toLocaleTimeString(undefined, timeOptions)}`;
      } else {
        return `${startDate.toLocaleDateString(
          undefined,
          options
        )} ${startDate.toLocaleTimeString(
          undefined,
          timeOptions
        )} - ${endDate.toLocaleDateString(
          undefined,
          options
        )} ${endDate.toLocaleTimeString(undefined, timeOptions)}`;
      }
    },
  },
};
</script>
<style scoped>
.inner-header-bg {
  background-image: url('~@/assets/images/headers/events.png');
  background-position: center center;
}
.centered-link {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
